/* eslint-disable react/jsx-props-no-spreading */
import { Box } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

/**
 * TabPanel component for rendering the content of a tab based on the selected index.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The content to display inside the tab panel.
 * @param {number} props.value - The current selected tab index.
 * @param {number} props.index - The index of this tab panel.
 * @param {number} [props.padding=20] - The padding inside the tab panel.
 * @param {number} [props.marginBottom=0] - The bottom margin of the tab panel.
 * @returns {React.ReactElement} The TabPanel component.
 */
function TabPanel(props) {
  const { children, value, index, padding = 20, marginBottom = 0, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      style={{ position: 'relative', marginBottom }}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box style={{ padding }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  padding: PropTypes.number,
  marginBottom: PropTypes.number,
};

export default TabPanel;
