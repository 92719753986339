import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Input, ListItemText, MenuItem, Select, Typography, Box } from '@material-ui/core';
import { toast } from 'react-toastify';
import useInput from '../../../../hooks/useInput';
import { updateForm } from '../../../../services/unarmed';
import WrapperSave from '../WrapperSave';
import PreviewLabel from './PreviewLabel';
import { FORM_TYPE_VERSION_2 } from '../../../../config/config';

/**
 * Custom Component for Dropdown
 * @param {object} props - Component props
 * @param {object} props.data - Component data
 * @param {string} props.version - Form version
 * @param {Function} props.setPagesValues - Function to update page values
 * @param {number} props.componentIndex - Component index
 * @param {string} props.id - Component ID
 * @param {string|string[]} props.value - Component value
 * @param {string[]} props.customFieldsValues - Custom field values
 * @param {Function} props.setForm - Function to set form state
 * @param {Function} props.setIsRequestingData - Function to set requesting data state
 * @returns {JSX.Element} CUDropdown component
 */
export default function CUDropdown({
  data,
  version,
  setPagesValues,
  componentIndex,
  id,
  // value,
  customFieldsValues,
  setForm,
  setIsRequestingData,
  onSubmitForm,
  withSave = true,
}) {
  const value = customFieldsValues[componentIndex];
  const [dropdownArray, setDropdownArray] = useState(Array.isArray(value) ? value : []);
  const dropdownString = useInput(Array.isArray(value) ? '' : value);

  /**
   * Handles save operation
   */
  const onSave = async () => {
    setIsRequestingData(true);
    try {
      const arrValue = Array.from(customFieldsValues);
      arrValue[componentIndex] = data?.multipleChoice ? dropdownArray : dropdownString.value;
      const json = {
        customFields: arrValue,
      };
      const res = version === FORM_TYPE_VERSION_2 ? await onSubmitForm(id, json) : await updateForm(id, json);
      setForm((prevState) => ({ ...prevState, ...res.data }));
      toast.success('Case Updates have been updated');
      setIsRequestingData(false);
    } catch (error) {
      toast.error(error.message);
      setIsRequestingData(false);
    }
  };

  useEffect(() => {
    if (version === FORM_TYPE_VERSION_2) {
      const arrValue = Array.from(customFieldsValues);
      arrValue[componentIndex] = data?.multipleChoice ? dropdownArray : dropdownString.value;

      setPagesValues(arrValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.multipleChoice, dropdownArray, dropdownString.value]);

  const render = (
    <>
      <PreviewLabel required={data?.required} description={data?.description} question={data?.question} />
      <Select
        multiple={data?.multipleChoice}
        value={data?.multipleChoice ? dropdownArray : dropdownString.value}
        displayEmpty
        input={<Input />}
        onChange={(e) => {
          if (data?.multipleChoice) {
            setDropdownArray(e.target.value);
          } else {
            dropdownString.onChange(e);
          }
        }}
        renderValue={(selected) => {
          if (!selected || (selected && selected.length === 0)) {
            return <Typography style={{ fontSize: 14, color: '#000000DE' }}>Select an Option</Typography>;
          }

          if (typeof data.options[0] === 'object') {
            const selectedOptions = selected.map((_option) => data.options.find((option) => option.value === _option));
            return selectedOptions.map((sOption) => sOption.label).join(', ');
          }

          if (Array.isArray(selected)) {
            return selected.join(', ');
          }

          return selected;
        }}
        style={{ width: '100%' }}
      >
        <MenuItem disabled value="">
          <Typography style={{ fontSize: 14, color: '#000000DE' }}>Select an Option</Typography>
        </MenuItem>
        {data?.options?.map((option, i) => (
          <MenuItem key={i} value={typeof option === 'object' ? option.value : option}>
            <Checkbox
              color="primary"
              checked={data?.multipleChoice ? dropdownArray.includes(option) : dropdownString.value === option}
            />
            <ListItemText primary={typeof option === 'object' ? option.label : option} />
          </MenuItem>
        ))}
      </Select>
    </>
  );

  if (version === FORM_TYPE_VERSION_2 && !withSave)
    return (
      <Box
        style={{ background: '#fff', borderRadius: 5 }}
        minHeight="170px"
        margin="20px 20px 0 0"
        width="100%"
        maxWidth="500px"
        display="flex"
        flexDirection="column"
      >
        <Box flexGrow={1}>{render}</Box>
      </Box>
    );

  return (
    <WrapperSave padding={version === FORM_TYPE_VERSION_2 ? '0px' : '20px'} onSave={onSave}>
      {render}
    </WrapperSave>
  );
}

CUDropdown.propTypes = {
  data: PropTypes.object.isRequired,
  version: PropTypes.string.isRequired,
  setPagesValues: PropTypes.func.isRequired,
  componentIndex: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  // value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  customFieldsValues: PropTypes.array.isRequired,
  setForm: PropTypes.func.isRequired,
  setIsRequestingData: PropTypes.func.isRequired,
  withSave: PropTypes.bool,
  onSubmitForm: PropTypes.func.isRequired,
};
