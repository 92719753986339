import React, { useEffect, useState } from 'react';
import CreateIcon from '@material-ui/icons/Create';
import PublicIcon from '@material-ui/icons/Public';
import {
  Box,
  Button,
  CircularProgress,
  makeStyles,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import DashButton from '../../components/DashButton';
import formatTxt from '../../utils/formatText';
import { saveCube } from '../../services/cube';
import useInput from '../../hooks/useInput';
import { OutLineInput } from '../CaseDetails/UpdateCase';
import ModalPopUp from '../../components/ModaPopupl';
import PopperPortal from '../../components/PopperPortal';

const useStyles = makeStyles(() => ({
  container: {
    padding: 20,
    width: '100%',
    height: '116.1vh',
    overflowY: 'auto',

    '& .makeStyles-root-51': {
      boxShadow: 'unset !important',
    },
  },
  buttonOutlined: {
    letterSpacing: '1px',
    fontSize: 13,
    fontWeight: '500',
    marginRight: 10,
    width: 'fit-content',
    alignSelf: 'flex-end',
    border: '1px solid transparent',
    color: '#4b7bff',
    '&:hover': {
      background: 'transparent',
      border: '1px solid transparent',
    },
    '& .MuiButton-label': {
      fontSize: 13,
    },
    '&:disabled': {
      border: 0,
    },
  },
}));

const downloadOptions = [
  { title: 'PNG', value: 'png' },
  { title: 'CSV', value: 'csv' },
];

export default function ContentHeader({
  cubeQuery,
  report,
  onClearReport,
  onSelectOption,
  reportId,
  prepareReport,
  handleRefetch,
}) {
  const [loading, setLoading] = useState(false);
  const reportName = useInput();
  const reportDescription = useInput();
  const [isEdit, setIsEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (report) {
      reportName.setValue(report.name);
      reportDescription.setValue(report.description);
    }
  }, [report]);

  const handleSave = async () => {
    try {
      setLoading(true);
      await saveCube(report?._id || reportId, {
        query: { ...cubeQuery, ...prepareReport() },
        ...(reportName.value ? { name: reportName.value } : {}),
        ...(reportDescription.value
          ? { description: reportDescription.value }
          : {}),
      });
      toast.success('Report Updated Successfully');
      setIsEdit(false);
      handleRefetch();
    } catch (error) {
      console.log(error);
      toast.error(error.response?.error);
    } finally {
      setLoading(false);
    }
  };

  const handleClearReport = () => {
    onClearReport();
    setOpenDelete(false);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      marginBottom="24px"
      justifyContent="space-between"
    >
      <Box>
        <Box display="flex" alignItems="center">
          {isEdit ? (
            <Box width={250}>
              <OutLineInput
                bordered
                placeholder="Ex. Report Name"
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  'aria-label': 'weight',
                }}
                value={reportName.value || ''}
                onChange={reportName.onChange}
                labelWidth={0}
              />
            </Box>
          ) : (
            <Typography style={{ fontSize: 24, fontWeight: '500' }}>
              {formatTxt(reportName.value || report?.name, 25)}
            </Typography>
          )}

          <CreateIcon
            style={{ marginLeft: 12, cursor: 'pointer' }}
            htmlColor="#6D7385"
            onClick={() => setIsEdit(!isEdit)}
          />
        </Box>
        {isEdit ? (
          <Box width={250}>
            <OutLineInput
              bordered
              placeholder="Ex. Report Name"
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                'aria-label': 'weight',
              }}
              value={reportDescription.value || ''}
              onChange={reportDescription.onChange}
              labelWidth={0}
            />
          </Box>
        ) : (
          <Typography style={{ fontSize: 14 }}>
            {formatTxt(reportDescription.value || report?.description, 100)}
          </Typography>
        )}

        <Box display="flex" alignItems="center">
          <PublicIcon
            htmlColor="#6D7385"
            style={{ marginRight: 6, fontSize: 10 }}
          />
          <Typography
            style={{
              color: '#6D7385',
              fontSize: 12,
              textTransform: 'capitalize',
            }}
          >
            {report?.type?.toLowerCase()}
          </Typography>
        </Box>
      </Box>
      <Box width={300} display="flex">
        <Box position="relative">
          <DashButton marginRight={10} onClick={() => setOpen(true)}>
            Download
          </DashButton>
          <PopperPortal open={open} setOpen={setOpen} left={0} top={43}>
            {downloadOptions.map((option, index) => (
              <MenuItem
                key={index}
                style={{ fontSize: 14 }}
                onClick={() => {
                  onSelectOption(option.value, report?.name);
                  setOpen(false);
                }}
              >
                {option.title}
              </MenuItem>
            ))}
          </PopperPortal>
        </Box>
        <DashButton
          marginRight={10}
          outline
          onClick={() => setOpenDelete(true)}
        >
          Clear Report
        </DashButton>
        <DashButton onClick={handleSave}>
          {loading ? (
            <CircularProgress size={20} style={{ color: '#fff' }} />
          ) : (
            'Save'
          )}
        </DashButton>
      </Box>

      <ModalPopUp open={openDelete} setOpen={setOpenDelete}>
        <Typography style={{ fontSize: 20, fontWeight: '500' }}>
          Are you sure you want to clear this report?
        </Typography>
        <Typography style={{ fontSize: 14 }}>
          This process cannot be undone.
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          marginTop="20px"
        >
          <Button
            variant="outlined"
            className={classes.buttonOutlined}
            onClick={() => setOpenDelete(false)}
          >
            CANCEL
          </Button>
          <Button
            variant="outlined"
            className={classes.buttonOutlined}
            onClick={handleClearReport}
          >
            CONFIRM
          </Button>
        </Box>
      </ModalPopUp>
    </Box>
  );
}
