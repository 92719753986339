import { Avatar, Badge, Box, Divider, Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import moment from 'moment';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import RestoreIcon from '@material-ui/icons/Restore';
const useStyles = makeStyles(() => ({
  largeIcon: {
    fontSize: 49,
  },
  editingContent: {
    position: 'absolute',
    top: '12px',
    right: '12px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  editingMessage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    border: '1px solid #FEB535',
    background: '#FFE4B4',
    width: '106px',
    height: '32px',
    marginRight: '15px',
    fontFamily: 'Roboto',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '26px',
    letterSpacing: '0.46px',
    textTransform: 'uppercase',
  },
  editButton: {
    color: '#848484',
    cursor: 'pointer',
    fontSize: '24px',
    display: 'none',
  },
  deleteButton: {
    color: '#848484',
    cursor: 'pointer',
    fontSize: '24px',
    display: 'none',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    position: 'absolute',
    top: '12px',
    right: '12px',
  },
  timelineItem: {
    fontSize: 14,
    position: 'relative',
    '&:hover': {
      '& $editButton': {
        display: 'block',
      },
      '& $deleteButton': {
        display: 'block',
      },
    },
  },
}));
const TimeLineItemNotes = ({
  archivedDate,
  onRestoreClick,
  createdAt,
  avatar,
  profileImg,
  useDefaultImage = false,
  children,
  unread,
  canEdit,
  canDelete,
  onEditClick,
  onDeleteClick,
  editing,
  onCancelEditingClick,
  updatedAt,
  editedByInfo,
}) => {
  const classes = useStyles();
  const currentTime = moment();
  const getDate = (date) => `${moment(date).format('MM/DD/YYYY')} at ${moment(date).format('hh:mm A')}`;
  const getTooltipDate = (date) => {
    if (moment(date).format('MM-DD-YYYY') === currentTime.format('MM-DD-YYYY')) {
      return `${moment(date).fromNow()}`;
    }
    const days = currentTime.endOf('day').diff(moment(date).endOf('day'), 'days');
    return `${days} Day${days === 1 ? '' : 's'} ago`;
  };
  const getEditedTooltipDate = (date) => {
    let ago;
    if (moment(date).format('MM-DD-YYYY') === currentTime.format('MM-DD-YYYY')) {
      ago = `${moment(date).fromNow()}`;
    } else {
      const days = currentTime.endOf('day').diff(moment(date).endOf('day'), 'days');
      ago = `${days} Day${days === 1 ? '' : 's'} ago`;
    }
    return `Edited ${ago}`;
  };
  return (
    <Box
      display="flex"
      width="100%"
      padding="0px 20px 0 0"
      marginBottom="20px"
      position="relative"
      className={classes.timelineItem}
    >
      {editing && (
        <Box className={classes.editingContent}>
          <Typography className={classes.editingMessage}>Editing</Typography>
          <Typography className={classes.editingCloseButton} onClick={onCancelEditingClick}>
            X
          </Typography>
        </Box>
      )}
      {archivedDate ? (
          <Box className={classes.iconContainer}><RestoreIcon onClick={onRestoreClick} fontSize="small" htmlColor="rgb(109, 115, 133)" /></Box>
        ) : (
          <Box className={classes.iconContainer}>
            {canEdit && !editing && <EditIcon className={classes.editButton} onClick={onEditClick} />}
            {canDelete && !editing && <DeleteIcon className={classes.deleteButton} onClick={onDeleteClick} />}
          </Box>)
      }

      <Box display="flex" alignItems="center">
        {unread && <Badge color="secondary" variant="dot" style={{ marginRight: 20 }} />}
        {useDefaultImage ? (
          <AccountCircleIcon htmlColor="#364F74" style={{ marginRight: 10 }} className={classes.largeIcon} />
        ) : (
          <Avatar
            style={{
              marginRight: 20,
              background: 'darkblue',
              fontSize: 14,
              textTransform: 'uppercase',
            }}
            src={profileImg}
          >
            {avatar}
          </Avatar>
        )}
      </Box>
      <Box style={{ fontSize: 14, minHeight: 60 }} width="100%">
        {children}
        <Box display="flex">
          <Typography style={{ fontSize: 14, marginTop: 6, color: '#cecece' }} title={getTooltipDate(createdAt)}>
            {getDate(createdAt)}
          </Typography>
          {updatedAt && editedByInfo ? (
            <Typography
              style={{ fontSize: 14, marginTop: 6, color: '#cecece', marginLeft: '10px' }}
              title={getEditedTooltipDate(updatedAt)}
            >
              (Edited on {getDate(updatedAt)})
            </Typography>
          ) : null}
        </Box>
        <Divider style={{ marginTop: 5 }} />
      </Box>
    </Box>
  );
};
export default TimeLineItemNotes;
