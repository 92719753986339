export const TINYMCE_SCRIPT_SRC = `${process.env.PUBLIC_URL}/tinymce/tinymce.min.js`;
export const TINYMCE_DEFAULT_CONFIG = {
  menubar: false,
  plugins: 'lists link autolink searchreplace paste contextmenu',
  toolbar:
    'undo redo | bold italic underline strikethrough | link unlink | alignleft aligncenter alignright | bullist numlist',
  paste_as_text: true,
  link_default_protocol: 'https',
  link_assume_external_targets: 'https',
  link_target_list: false,
  link_default_target: '_blank',
  toolbar_mode: 'sliding',
  branding: false,
  elementpath: false,
  contextmenu: false,
  content_style:
    'div, p, li, span, a { font-size: 14px; } .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before { font-size:14px }',
};

export const FILE_TAGS = [
  { text: 'Police Enforcer', color: 'red' },
  { text: 'Photo', color: 'blue' },
];

export const FORM_TYPE_VERSION_2 = 2;
