import React, { useState } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import WrapperComponent from './WrapperComponent';
import Input from '../../components/Input';
import useInput from '../../hooks/useInput';
import Settings from '../utils/Settings';
import { handleChangeCheckBox } from '../utils/utls';
import PreviewLabel from '../utils/Label';
import HideAnswer from '../../components/HideAnswer';

const useStyles = makeStyles(() => ({
  title: {
    fontSize: 14,
    color: '#000000DE',
    marginLeft: 10,
  },
  label: {
    fontSize: 16,
    color: '#000000DE',
    fontWeight: '500',
  },
  dropzoneBox: {
    background: '#4762FA08',
    borderRadius: 4,
    border: '1px dashed #364F7431',
    padding: 34,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: 16,
  },

  dropzoneText: {
    display: 'block',
    fontSize: 12,
    marginTop: 10,
  },
}));

const fileTypesF = [
  { value: false, text: 'JPG' },
  { value: false, text: 'PNG' },
  { value: false, text: 'MP4' },
  { value: false, text: 'MOV' },
  { value: false, text: 'PDF' },
  { value: false, text: 'DOC' },
];

export default function File({
  questionBankComponent,
  id,
  componentId,
  onUpdateJson,
  onDeleteComponent,
  path,
  data,
  componentIndex,
  duplicateComponent,
  refActionModal,
  setModal,
}) {
  const classes = useStyles();

  const [checkQuestion, setCheckQuestion] = useState([
    !!data?.description,
    !!data.requiredMessage,
    data?.fileTypes?.length > 0,
  ]);
  const sizeSelect = useInput(data?.maxFileSize || '');

  const required = useInput(!!data?.required, false, false, true);
  const descriptionQuestion = useInput(data?.description || '');
  const requiredQuestionMessage = useInput(data?.requiredMessage || '');
  const question = useInput(data?.question || '');
  const [loading, setLoading] = React.useState(false);

  const [fileType, setFileType] = useState(
    fileTypesF.map((fl) => {
      if (data?.fileTypes?.includes(fl.text)) {
        return { ...fl, value: true };
      }

      return fl;
    })
  );

  const handleSaveComponent = () => {
    const dataJson = {
      question: question.value,
      ...(descriptionQuestion.value ? { description: descriptionQuestion.value } : {}),
      ...(requiredQuestionMessage.value ? { requiredMessage: requiredQuestionMessage.value } : {}),
      ...(checkQuestion[2]
        ? {
            fileTypes: fileType.filter((ft) => ft.value).map((filetype) => filetype.text),
          }
        : {}),
      ...(sizeSelect.value ? { maxFileSize: +sizeSelect.value } : {}),
      required: required.value,
    };

    onUpdateJson(dataJson);
  };
  const saveOnTimeOut = () => {
    setLoading(true);
    setTimeout(() => {
      handleSaveComponent();
      setLoading(false);
    }, 500);
  };

  const renderSettings = () => (
    <Settings
      componentId={componentId}
      componentType="fileUpload"
      questions={['description', 'required', 'file']}
      saveOnTimeOut={saveOnTimeOut}
      checkQuestion={checkQuestion}
      descriptionQuestion={descriptionQuestion}
      requiredQuestion={requiredQuestionMessage}
      setCheckQuestion={setCheckQuestion}
      fileType={fileType}
      onChangeFileType={(e, index) => {
        handleChangeCheckBox(e, index, 'value', fileType, setFileType);
        saveOnTimeOut();
      }}
      sizeSelect={sizeSelect}
    />
  );

  const renderPreview = () => (
    <Box>
      <PreviewLabel question={question.value} qNumber={componentIndex + 1} required={required.value} />

      <Box style={{ marginLeft: 45 }}>
        <HideAnswer marginLeft="-7px" marginTop={0}>
          <Box display="flex" alignItems="center">
            <CloudUploadIcon htmlColor="#00000061" style={{ marginRight: 10 }} />
            <Typography style={{ fontSize: 14, color: '#00000061' }}>File upload</Typography>
          </Box>
        </HideAnswer>
      </Box>
    </Box>
  );

  return (
    <WrapperComponent
      onBlur={(!questionBankComponent && saveOnTimeOut) || function () {}}
      renderSettings={renderSettings}
      componentIndex={componentIndex}
      duplicateComponent={duplicateComponent}
      isQuestionBankComponent={questionBankComponent}
      renderPreview={renderPreview}
      required={required}
      onDeleteComponent={onDeleteComponent}
      id={id}
      componentId={componentId}
      path={path}
      loading={loading}
      dataSaved={data?.question}
      refActionModal={refActionModal}
      setModal={setModal}
    >
      <Box display="flex" alignItems="center">
        <CloudUploadIcon htmlColor="#6D7385" />
        <Typography className={classes.title}>File upload</Typography>
      </Box>
      <Box display="flex" alignItems="center" marginTop="15px">
        <Typography className={classes.label}>Q{componentIndex + 1}</Typography>
        <ArrowRightAltIcon style={{ fontSize: 14, marginRight: 10 }} />
        <Input
          placeholder="Enter name question"
          value={question.value}
          onChange={(e) => {
            question.onChange(e);
            saveOnTimeOut();
          }}
        />
      </Box>
      <Box width="100%" height={130} className={classes.dropzoneBox}>
        <CloudUploadIcon htmlColor="#92AEFE" style={{ fontSize: 44 }} />
        <Typography className={classes.dropzoneText}>
          Drag files here or <span style={{ color: '#2E66FE', fontWeight: '500' }}>browser</span>
        </Typography>
      </Box>
    </WrapperComponent>
  );
}
