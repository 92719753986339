import { useState, useEffect } from 'react';

const useInput = (initialValue = '', submitting, isPassword, isBoolean) => {
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState('');

  useEffect(() => {
    if (!value && submitting) {
      setError('This Field is required!');
    }

    if (
      isPassword &&
      submitting &&
      !value.match(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
      )
    ) {
      setError(
        'Password should be a minimum of eight characters and include at least one uppercase letter, one lowercase letter, one number, and a special character.'
      );
    }
  }, [submitting, value]);

  const onChange = (e) => {
    if (isBoolean) {
      setValue(e.target.checked);
      return;
    }
    setValue(e.target.value);

    if (value !== e.target.value) {
      setError('');
    }
  };

  return {
    value,
    error,
    onChange,
    setError,
    setValue,
  };
};

export default useInput;
