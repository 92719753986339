import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Context } from '../../Context';
import useInput from '../../hooks/useInput';
import InfoIcon from '@material-ui/icons/Info';
import { createUserInvitation, getRoles } from '../../services/unarmed';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 20,
  },
  title: {
    marginTop: 50,
  },
  label: {
    fontSize: 12,
    color: '#aaa',
  },
  button: {
    letterSpacing: '1px',
    fontSize: 13,
    background: '#2E66FE',
    fontWeight: '400',
    width: 'fit-content',
    alignSelf: 'flex-end',
    color: '#fff',
    '&:hover': {
      background: '#4b7bff',
    },
    '& .MuiButton-label': {
      fontSize: 13,
    },
  },

  buttonOutlined: {
    letterSpacing: '1px',
    fontSize: 13,
    fontWeight: '500',
    marginRight: 10,
    width: 'fit-content',
    alignSelf: 'flex-end',
    border: '1px solid transparent',
    color: '#4b7bff',
    '&:hover': {
      background: 'transparent',
      border: '1px solid transparent',
    },
    '& .MuiButton-label': {
      fontSize: 13,
    },
  },

  img: {
    width: 120,
    height: 120,
    borderRadius: '50%',
    objectFit: 'cover',
  },

  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: '#fff',
    boxShadow: theme.shadows[5],
    padding: 30,
    maxWidth: 500,
  },

  alertContainer: {
    height: 'fit-content',
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    background: '#FFFBF3',
    borderLeft: '6px solid #FFC64C',
    borderRadius: 2,
    marginTop: 10,
    marginBottom: 10,
    padding: '10px',
  },
}));

const InviteUser = ({ setOpen, onUpdateUsers, tab, setTab, limitInvites, leftInvites }) => {
  const classes = useStyles();
  const { authUser } = useContext(Context);
  const [roles, setRoles] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const firstName = useInput('', isSubmitting);
  const lastName = useInput('', isSubmitting);
  const phone = useInput('', isSubmitting);
  const email = useInput('', isSubmitting);
  const role = useInput('', isSubmitting);

  useEffect(() => {
    const onGetRoles = async () => {
      try {
        const { data } = await getRoles();
        setRoles(data);
      } catch (error) {
        console.log(error);
      }
    };

    if (authUser) {
      onGetRoles();
    }
  }, [authUser]);

  const onInviteUser = async () => {
    setIsSubmitting(true);
    if (!firstName.value || !lastName.value || !phone.value || !email.value || !role.value) {
      return;
    }

    if (role.value === 'Select an Option') {
      role.setValue('');
      return;
    }

    if (phone.value.length !== 10) {
      phone.setError('Please enter a valid phone number');
      return;
    }
    const data = {
      firstName: firstName.value,
      lastName: lastName.value,
      phone: phone.value,
      email: email.value,
      type: role.value,
    };
    try {
      setLoading(true);
      const user = await createUserInvitation(data);
      if (tab === 1) {
        onUpdateUsers(user.data);
      } else {
        setTab(1);
      }
      setLoading(false);
      toast.success('A new User has been invited');
      setOpen(false);
      console.log("Setting openInviteUser to false");
    } catch (error) {
      const message =
        error?.response?.data?.error?.details?.[0]?.message ||
        error?.response?.data?.message ||
        'Something went wrong, try again later.';

      setLoading(false);

      console.log(message);
      toast.error(message);
    }
  };

  const formatRoleName = (roleName) =>
    roleName.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^\w/, (c) => c.toUpperCase());

  return (
    <>
      <Typography variant="h6">Invite User</Typography>

      <Box className={classes.alertContainer}>
        <InfoIcon htmlColor="#FFC64C" style={{ marginRight: 12, fontSize: 15 }} />
        <Typography style={{ fontSize: 13 }}>
          You can invite a new user to the dashboard by sending an invitation to their email address. You have{' '}
          <span style={{ color: '#4b7bff', fontWeight: '500' }}>
            {leftInvites} of {limitInvites}
          </span>{' '}
          available invitations remaining.
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            id="standard-required1"
            label={
              <Typography>
                {' '}
                First Name <span style={{ color: 'red', fontSize: 12 }}>*</span>
              </Typography>
            }
            style={{ width: '100%' }}
            value={firstName.value}
            onChange={firstName.onChange}
            error={firstName.error}
            helperText={firstName.error}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="standard-required2"
            label={
              <Typography>
                {' '}
                Last Name <span style={{ color: 'red', fontSize: 12 }}>*</span>
              </Typography>
            }
            style={{ width: '100%' }}
            error={lastName.error}
            value={lastName.value}
            onChange={lastName.onChange}
            helperText={lastName.error}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="standard-required3"
            label={
              <Typography>
                {' '}
                Email <span style={{ color: 'red', fontSize: 12 }}>*</span>
              </Typography>
            }
            value={email.value}
            onChange={email.onChange}
            style={{ width: '100%' }}
            error={email.error}
            helperText={email.error}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={phone.value}
            onChange={(e) => {
              if (e.target.value.length === 0) {
                phone.setValue('');
              }
              if (e.target.value.match(/[0-9]$/g)) {
                phone.onChange(e);
              }
            }}
            id="standard-required14"
            label={
              <Typography>
                {' '}
                Phone <span style={{ color: 'red', fontSize: 12 }}>*</span>
              </Typography>
            }
            style={{ width: '100%' }}
            inputProps={{ maxlength: 10 }}
            error={phone.error}
            helperText={phone.error}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl className={classes.formControl} style={{ width: '100%' }}>
            <InputLabel htmlFor="age-native-simple">
              Role <span style={{ color: 'red', fontSize: 12 }}>*</span>
            </InputLabel>
            <Select
              native
              defaultValue="Select an Option"
              value={role.value}
              onChange={role.onChange}
              error={role.error}
              helperText={role.error}
            >
              <option aria-label="None" value={null}>
                Select an Option
              </option>
              {roles.map((roleItem, index) => (
                <option value={roleItem} key={index}>
                  {formatRoleName(roleItem)}
                </option>
              ))}
            </Select>
            <span style={{ color: 'red', fontSize: 12, marginTop: 4 }}>{role.error}</span>
          </FormControl>
        </Grid>
      </Grid>
      <Box display="flex" alignItems="center" justifyContent="flex-end" marginTop="20px">
        <Button
          data-testid="users-invite-button-cancel"
          variant="outlined"
          className={classes.buttonOutlined}
          style={{ color: '#333' }}
          onClick={() => setOpen(false)}
        >
          CANCEL
        </Button>
        <Button
          data-testid="users-invite-button-submit"
          variant="outlined"
          className={classes.buttonOutlined}
          onClick={onInviteUser}
        >
          {loading ? <CircularProgress color="#fff" size="25px" /> : 'INVITE'}
        </Button>
      </Box>
    </>
  );
};

export default InviteUser;
