import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from '@reach/router';

import { downloadImage } from '../../../utils/libs';
import LogIcon from '../../../components/LogIcon';
import TimeLineItem from '../../Activity/TimeLineItem';
import HTMLContent from '../../../components/HTMLContent';
import CarouselModal from '../../../components/CarouselModal';
import Tags from '../../../components/Tags';
import Tag from '../../../components/Tag';
import ArchivedImage from '../../../components/ArchivedImage';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  imgModal: {
    objectFit: 'contain',
    maxWidth: 600,
    maxHeight: 400,
  },
  link: {
    display: 'flex',
    position: 'absolute',
    top: 10,
    right: 80,
    cursor: 'pointer',
    padding: 13,
    zIndex: 999999,
    borderRadius: '50%',
    background: '#494949',
  },
  emphasis: {
    color: '#4b7bff',
    textTransform: 'capitalize',
    fontWeight: '600',
  },
}));

const getActionLabel = (key) => {
  if (key === 'bradyList') {
    return 'Brady list';
  }
  if (key === 'firstName') {
    return 'First name';
  }
  if (key === 'lastName') {
    return 'Last name';
  }
  if (key === 'email') {
    return 'Email';
  }
  if (key === 'raceEthnicity') {
    return 'Race/Ethnicity';
  }
  if (key === 'gender') {
    return 'Gender';
  }
  if (key === 'phoneNumber') {
    return 'Phone number';
  }
  if (key === 'photo') {
    return 'a profile picture';
  }
};

const HistoryLog = ({ data, type, createdAt }) => {
  const classes = useStyles();
  const { user, changes, type: optionType, comment, intervene, meeting, files } = data;

  const [open, setOpen] = React.useState(false);
  const [filesSelected, setFilesSelected] = React.useState({ files: [], selectedFileIndex: 0 });
  const [loadingOpenFiles, setLoadingOpenFiles] = React.useState([]);

  const handleOpen = (filesOp) => {
    setOpen(true);
    const filesToOpen = filesOp.filter((flop) => flop.mimetype.includes('image') || flop.mimetype.includes('video'));
    setFilesSelected({ files: filesToOpen, selectedFileIndex: 0 });
    setLoadingOpenFiles(
      filesToOpen.map(() => ({
        loading: false,
      }))
    );
  };

  const handleClickEvents = (fl) => ({
    onDownload: () => downloadImage(fl.download_url, fl.name),
    onClick: () => handleOpen(files),
  });

  const stripHTML = (str) => {
    if (!str) return '';
    return str.replace(/<\/?[^>]+(>|$)/g, '');
  };

  const renderText = () => {
    if (type === 'OFFICER_ATTACHMENT_TAGS_ADDED') {
      return (
        <span>
          <strong>{user?.email}</strong> has added {data?.tags?.length > 1 ? 'tags' : 'tag'}{' '}
          {data?.tags?.map((tag) => (
            <Tag bg={tag?.backgroundColor} color={tag?.textColor} key={tag?._id} text={tag?.name} />
          ))}{' '}
          to attachment: <span className={classes.emphasis}>{data?.file?.name}</span> to officer:{' '}
          <Link to={`/officer/${data?.officer?._id}`}>
            <span className={classes.emphasis}>
              {data?.officer?.firstName} {data?.officer?.lastName}
            </span>
          </Link>
        </span>
      );
    }

    if (type === 'OFFICER_COMMENT_OR_ATTACHMENT_TAGS_ADDED') {
      return (
        <span>
          <strong>{user?.email}</strong> has added {data?.tags?.length > 1 ? 'tags' : 'tag'}{' '}
          {data?.tags?.map((tag) => (
            <Tag bg={tag?.backgroundColor} color={tag?.textColor} key={tag?._id} text={tag?.name} />
          ))}{' '}
          to attachment: <span className={classes.emphasis}>{data?.file?.name}</span> to{' '}
          {optionType === 'INTERVENE' ? 'Intervene' : 'Monitor'}:
          <HTMLContent content={comment} />
        </span>
      );
    }

    if (type === 'OFFICER_ATTACHMENT_TAGS_DELETE') {
      return (
        <span>
          <strong>{user?.email}</strong> removed {data?.tags?.length > 1 ? 'tags' : 'tag'}{' '}
          {data?.tags?.map((tag) => (
            <Tag bg={tag?.backgroundColor} color={tag?.textColor} key={tag?._id} text={tag?.name} />
          ))}{' '}
          from attachment: <span className={classes.emphasis}>{data?.file?.name}</span> from officer:{' '}
          <Link to={`/officer/${data?.officer?._id}`}>
            <span className={classes.emphasis}>
              {data?.officer?.firstName} {data?.officer?.lastName}
            </span>
          </Link>
        </span>
      );
    }

    if (type === 'OFFICER_UPDATED') {
      return changes?.map((change, index) => (
        <Box key={index}>
          {!change?.oldValue && typeof change?.oldValue !== 'boolean' ? (
            <>
              <strong>{user.email}</strong> has added <strong>{getActionLabel(change?.fieldName)}</strong> to officer{' '}
              <span className={classes.emphasis}>{change?.newValue}</span>{' '}
            </>
          ) : (
            <>
              <strong>{user.email}</strong> has changed <strong>{getActionLabel(change?.fieldName)}</strong> to officer{' '}
              from <span className={classes.emphasis}>{change.oldValue.toString()}</span> to{' '}
              <span className={classes.emphasis}>{change.newValue.toString()}</span>{' '}
            </>
          )}
        </Box>
      ));
    }

    if (type === 'OFFICER_COMMENT_OR_ATTACHMENT' || type === 'OFFICER_ATTACHMENT') {
      const hasFiles = files.length > 0;
      const commentText = comment ? (
        <>
          {' made a '}
          <span className={classes.emphasis}>new comment</span>
        </>
      ) : null;
      const filesText = hasFiles ? (
        <>
          <span className={classes.emphasis}>
            {' Uploaded '} {files.length}
          </span>{' '}
          file(s)
        </>
      ) : null;
      const optionText = optionType ? (
        <>
          {' to '}
          {optionType === 'INTERVENE' ? 'Intervene' : 'Monitor'}{' '}
          <span className={classes.emphasis}>
            {optionType === 'INTERVENE' && !meeting ? intervene : meeting ? `${meeting} months meeting` : null}
          </span>
        </>
      ) : null;
      return (
        <>
          <strong>{user.email}</strong>
          {commentText}
          {comment && hasFiles ? ' and ' : null}
          {filesText}
          {optionText}
          {comment ? (
            <>
              <br />
              <HTMLContent content={comment} />
            </>
          ) : null}

          {hasFiles ? (
            <>
              <br />
              <Box display="flex" flexDirection="column">
                {files.map((file, index) => {
                  const showPreview = !file?.archivedDate;
                  return (
                    <div>
                      <Box display="flex" alignItems="center" justifyContent="flex-start">
                        <PreviewImg
                          key={file._id}
                          style={
                            !file.name.includes('.pdf')
                              ? { height: 100, margin: 6 }
                              : { minHeight: 35, width: 'fit-content' }
                          }
                        >
                          {showPreview ? (
                            <LogIcon fl={file} handleClickEvents={handleClickEvents(file)} index={index} />
                          ) : (
                            <ArchivedImage />
                          )}
                        </PreviewImg>
                        <Typography style={{ fontSize: 14 }}>{file.name}</Typography>
                      </Box>
                      <Tags
                        autoUpdateTags={false}
                        fileId={file?._id}
                        defaultTags={file?.tags || []}
                        allTags={[]}
                        tagsRecently={[]}
                      />
                    </div>
                  );
                })}
              </Box>
            </>
          ) : null}
        </>
      );
    }

    if (type === 'OFFICER_ATTACHMENT_ARCHIVED') {
      return (
        <>
          <strong>{user?.email}</strong> has archived the file{' '}
          <span className={classes.emphasis}>{stripHTML(data?.comment?.comment)}</span> on the officer with Badge Number:{' '}
          <span className={classes.emphasis}>{data?.officer?.badgeNumber}</span>.
        </>
      );
    }

    if (type === 'OFFICER_NOTE_UNARCHIVED') {
      return (
        <>
          <strong>{user?.email}</strong> has unarchived the note{' '}
          <span className={classes.emphasis}>{stripHTML(data?.comment?.comment)}</span> on the officer with Badge Number:{' '}
          <span className={classes.emphasis}>{data?.officer?.badgeNumber}</span>.
        </>
      );
    }

    if (type === 'OFFICER_NOTE_ARCHIVED') {
      return (
        <>
          <strong>{user?.email}</strong> has archived the note{' '}
          <span className={classes.emphasis}>{data?.file?.name}</span> on the officer with Badge Number:{' '}
          <span className={classes.emphasis}>{data?.officer?.badgeNumber}</span>.
        </>
      );
    }

    if (type === 'OFFICER_ATTACHMENT_UNARCHIVED') {
      return (
        <>
          <strong>{user?.email}</strong> has unarchived the file{' '}
          <span className={classes.emphasis}>{data?.file?.name}</span> on the officer with Badge Number:{' '}
          <span className={classes.emphasis}>{data?.officer?.badgeNumber}</span>.
        </>
      );
    }
  };

  return (
    <>
      <TimeLineItem
        createdAt={createdAt}
        profileImg={user?.photo?.public_url}
        avatar={`${user?.email[0]?.toUpperCase() || 'US'} ${user?.email[1]?.toUpperCase() || ''} `}
      >
        {renderText()}
      </TimeLineItem>
      <CarouselModal
        open={open}
        setOpen={setOpen}
        filesSelected={filesSelected}
        setLoadingOpenFiles={setLoadingOpenFiles}
        loadingOpenFiles={loadingOpenFiles}
      />
    </>
  );
};

HistoryLog.propTypes = {
  data: PropTypes.shape({
    tags: PropTypes.array,
    file: PropTypes.object,
    officer: PropTypes.object,
    user: PropTypes.string.isRequired,
    changes: PropTypes.arrayOf(PropTypes.string.isRequired),
    type: PropTypes.string.isRequired,
    comment: PropTypes.string,
    intervene: PropTypes.bool,
    meeting: PropTypes.string,
    files: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        size: PropTypes.number.isRequired,
      })
    ),
  }).isRequired,
  type: PropTypes.string.isRequired,
  createdAt: PropTypes.instanceOf(Date).isRequired,
};

export const PreviewImg = styled.div`
  height: 100px;
  position: relative;

  overflow: hidden;
  margin-right: 10px;
  & div:hover {
    opacity: 1;
  }
`;

export default HistoryLog;
