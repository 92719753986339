import { Box, Button, CircularProgress, makeStyles, Step, StepLabel, Stepper, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import * as firebase from 'firebase';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import MfaStep1 from './MfaStep1';
import MfaStep2 from './MfaStep2';
import MfaStep3 from './MfaStep3';
import MfaVerifyEmail from './MfaVerifyEmail';
import { Context } from '../../Context';
import useInput from '../../hooks/useInput';

const recap = {
  recaptchaVerifier: undefined,
};

function recaptchaVerifierInvisible() {
  // [START auth_phone_recaptcha_verifier_invisible]
  recap.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
    size: 'invisible',
  });
  // [END auth_phone_recaptcha_verifier_invisible]
}

function recaptchaRender() {
  /** @type {firebase.auth.RecaptchaVerifier} */
  const { recaptchaVerifier } = recap;

  // [START auth_phone_recaptcha_render]
  recaptchaVerifier.render().then((widgetId) => {
    recap.recaptchaWidgetId = widgetId;
  });
  // [END auth_phone_recaptcha_render]
}

const useStyles = makeStyles(() => ({
  stepper: {
    background: 'transparent',
    width: '100%',
    padding: '24px 0',
  },
}));

const steps = ['Verify Email Address', 'Phone number', 'Verify', 'Summary'];

const Mfa = ({ shouldShowSkip }) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [verificationID, setVerificationID] = useState(null);
  const [code, setCode] = useState();
  const [loading, setLoading] = useState(false);
  const user = firebase.auth().currentUser;
  const { myUser: me, removeAuthToLogin, removeAuth } = useContext(Context);
  const phone = useInput();
  const history = useHistory();

  useEffect(() => {
    recaptchaVerifierInvisible();
    recaptchaRender();
  }, []);

  useEffect(() => {
    if (user && user.emailVerified) {
      setActiveStep(1);
    }
    if (user && user.multiFactor.enrolledFactors.length > 0) {
      setActiveStep(3);
    }
  }, [user]);

  useEffect(() => {
    if (me) {
      phone.setValue(me.phoneNumber);
    }
  }, [me]);

  const onNextStep = () => {
    if (phone.value.replace(/[^A-Z0-9]+/gi, '').length === 0) {
      toast.error('Please enter your phone number ');
      return;
    }

    if (phone.value.replace(/[^A-Z0-9]+/gi, '').length !== 10) {
      toast.error('Please enter a valid phone number');
      return;
    }
    if (activeStep === 1) {
      // onReAuth();

      setLoading(true);
      user.multiFactor
        .getSession()
        .then((multiFactorSession) => {
          const phoneInfoOptions = {
            phoneNumber: `+1${phone.value.replace(/[^A-Z0-9]+/gi, '')}`,
            session: multiFactorSession,
          };

          const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
          return phoneAuthProvider
            .verifyPhoneNumber(phoneInfoOptions, recap.recaptchaVerifier)
            .then(function (verificationId) {
              // verificationId will be needed for enrollment completion.
              setLoading(false);
              toast.success('SMS sent Successfully');
              setVerificationID(verificationId);
              setActiveStep(activeStep + 1);
            })
            .catch((error) => {
              if (error.code === 'auth/unsupported-first-factor') {
                removeAuthToLogin();
                history.push('/login');
              }

              if (error.code === 'auth/requires-recent-login') {
                removeAuthToLogin();
                history.push('/login');
              }
              setLoading(false);
            });
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error.message);
        });
    }
  };

  const onResendCode = async () => {
    try {
      setLoading(true);
      user.multiFactor.getSession().then((multiFactorSession) => {
        const phoneInfoOptions = {
          phoneNumber: `+1${phone.value.replace(/[^A-Z0-9]+/gi, '')}`,
          session: multiFactorSession,
        };

        const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
        return phoneAuthProvider
          .verifyPhoneNumber(phoneInfoOptions, recap.recaptchaVerifier)
          .then(function (verificationId) {
            // verificationId will be needed for enrollment completion.
            setLoading(false);

            setVerificationID(verificationId);
          });
      });
      toast.success('SMS re-sent Successfully');
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  const onVerifyCode = async () => {
    if (!code) {
      toast.error('Please fill the code sent to your phone number.');
      return;
    }

    try {
      setLoading(true);
      const cred = await firebase.auth.PhoneAuthProvider.credential(verificationID, code);

      const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
      user.multiFactor
        .enroll(multiFactorAssertion, 'My personal phone number')
        .then(() => {
          window.location.reload();
          setTimeout(() => {
            setLoading(false);
            setActiveStep(activeStep + 1);
          }, 2000);
        })
        .catch((error) => {
          if (error.code === 'auth/invalid-verification-code') {
            toast.error('Upps! Wrong code');
          }
          setLoading(false);
        });
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  };

  function onHandler() {
    if (activeStep === 1) {
      onNextStep();
    } else if (activeStep === 2) {
      onVerifyCode();
    } else if (activeStep === 3) {
      history.push('/');
    }
  }

  return (
    <>
      <Box
        height="116.1vh"
        width="100%"
        display="flex"
        style={{ background: '#FAFBFF' }}
        justifyContent="center"
        alignItems="center"
      >
        <Box
          width="100%"
          maxWidth="650px"
          height="374px"
          style={{ background: '#fff' }}
          padding="32px"
          borderRadius="4px"
          boxShadow="0 3px 6px  #0000000D"
        >
          <Box display="flex" flexDirection="column" height="100%">
            <Box flexGrow="1">
              <Typography style={{ color: '#000000DE', fontSize: 22 }}>Setup Two Factor Authentication</Typography>
              {activeStep < 3 && (
                <Steps activeStep={activeStep} className={classes.stepper} typeFormColor="#2E66FE">
                  {steps?.map((status) => (
                    <Step key={status}>
                      <StepLabel className={classes.stepLabel}>{status}</StepLabel>
                    </Step>
                  ))}
                </Steps>
              )}
              {activeStep === 0 && <MfaVerifyEmail />}
              {activeStep === 1 && <MfaStep1 phone={phone} />}
              {activeStep === 2 && (
                <MfaStep2 phoneNumber={phone.value} code={code} setCode={setCode} resendCode={onResendCode} />
              )}
              {activeStep === 3 && <MfaStep3 />}
            </Box>

            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Box>
                {!shouldShowSkip && (
                  <Button
                    data-testid="mfa-button-skip"
                    style={{
                      color: '#2E66FE',
                      fontSize: 14,
                      fontWeight: '500',
                      textTransform: 'capitalize',
                    }}
                    onClick={() => history.push('/cases')}
                  >
                    Skip <ArrowRightAltIcon style={{ fontSize: 10 }} htmlColor="#2E66FE" />
                  </Button>
                )}
              </Box>
              <Box display="flex" alignItems="center">
                {activeStep === 2 && (
                  <Button
                    data-testid="mfa-button-back"
                    variant="outlined"
                    onClick={() => {
                      setActiveStep(activeStep - 1);
                    }}
                    style={{
                      borderColor: '#2F4F77',
                      color: '#2F4F77',
                      marginRight: 10,
                    }}
                  >
                    Back
                  </Button>
                )}

                {activeStep > 0 && (
                  <Button
                    data-testid="mfa-button-next"
                    variant="contained"
                    onClick={onHandler}
                    style={{ background: '#2F4F77', color: '#fff' }}
                    disabled={loading}
                  >
                    {loading ? <CircularProgress size="20px" style={{ color: '#fff' }} /> : 'Next'}
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box position="absolute" style={{ bottom: 100, right: 20 }}>
        <Button
          data-testid="mfa-button-logout"
          variant="contained"
          onClick={removeAuth}
          style={{ background: '#2F4F77', color: '#fff' }}
          disabled={loading}
        >
          Log out
        </Button>
      </Box>
      <div id="recaptcha-container" />
    </>
  );
};

const Steps = styled(Stepper)`
  .MuiStepIcon-root {
    border: 1px solid #ccc;
    border-radius: 50%;
    color: transparent;
    .MuiStepIcon-text {
      fill: #000000ad;
    }
  }
  .MuiStepIcon-root.MuiStepIcon-active {
    border: 1px solid #4b7bff;
    border-radius: 50%;
    background-color: #4b7bff !important;
    color: #4b7bff !important;
    .MuiStepIcon-text {
      fill: #fff;
    }
  }

  /* .MuiStepIcon-root.MuiStepIcon-active {
    color: #fff !important;
    .MuiStepIcon-text {
      fill: #000;
    }
  }
  .MuiStepIcon-root.MuiStepIcon-completed {
    color: #4b7bff !important;
  }
  .MuiStepLabel-label {
    color: #fff !important;
  } */

  ${(props) =>
    props.typeFormColor &&
    `
    .MuiStepIcon-root.MuiStepIcon-completed {
      color: ${props.typeFormColor} !important;
      border: 0;
      .MuiStepLabel-labelContainer.MuiStepLabel-label {
        color: #000000AD;
      }
    }
    .MuiStepLabel-label.MuiStepLabel-completed {
      color: #000000AD;
      font-weight: 500;
    }
  `}

  .MuiStepLabel-label {
    color: #00000099 !important;
  }

  width: unset;
  margin: auto;
  @media screen and (min-width: 600px) {
    width: 100%;
  }
`;

Mfa.propTypes = {
  shouldShowSkip: PropTypes.bool,
};

export default Mfa;
