import { Typography } from '@material-ui/core';
import React from 'react';
import ModalButtons from './components/ModalButtons';

export default function DeletePopup({ handleCancel, handleSubmit, loading }) {
  return (
    <>
      <Typography style={{ fontSize: 20, fontWeight: '500' }}>
        Are you sure you want to delete?
      </Typography>
      <Typography variant="body2">
        Projects well be archived for 7 years before being permanently deleted.{' '}
      </Typography>
      <ModalButtons
        handleCancel={handleCancel}
        handleSubmit={handleSubmit}
        isValid={() => false}
        loading={loading}
        buttonText="Delete"
      />
    </>
  );
}
