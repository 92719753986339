import React, { Fragment } from 'react';
import styled from 'styled-components';

export default function OutLineTable({
  columns = [],
  rows = [],
  renderRows,
  withTr = true,
}) {
  return (
    <Table>
      <TrD>
        {columns.map((column, index) => (
          <Th key={index} style={column.styles}>
            {column.name}
          </Th>
        ))}
      </TrD>
      {withTr
        ? rows.map((row, index) => (
            <TrD key={index}>{renderRows(row, index)}</TrD>
          ))
        : rows.map((row, index) => (
            <Fragment key={index}>{renderRows(row, index)}</Fragment>
          ))}
    </Table>
  );
}

const Table = styled.table`
  border-collapse: separate;
  border-spacing: 0 10px;
  width: 100%;
`;

const Th = styled.th`
  text-align: left;

  fontsize: 14px;
  color: #000000de;
  fontweight: 500;
`;

const TrD = styled.tr`
  margin-bottom: 15px;
`;
