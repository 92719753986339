/* eslint-disable no-nested-ternary */
import { Box, makeStyles, Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Context } from '../Context';
import { downloadImage } from '../utils/libs';
import LogIcon from './LogIcon';
import TimeLineItem from '../pages/Activity/TimeLineItem';
import HTMLContent from './HTMLContent';
import CaseTaskUpdateItem from '../pages/Activity/CaseTaskUpdateItem';
import Tags from './Tags';
import CarouselModal from './CarouselModal';
import Tag from './Tag';
import { getAboutUsValue } from '../pages/CaseDetails/AditionalDetails';
import ArchivedImage from './ArchivedImage';

const getStatusTitle = (status) => {
  if (status) {
    return status;
  }
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  imgModal: {
    objectFit: 'contain',
    maxWidth: 600,
    maxHeight: 400,
  },
  link: {
    display: 'flex',
    position: 'absolute',
    top: 10,
    right: 80,
    cursor: 'pointer',
    padding: 13,
    zIndex: 999999,
    borderRadius: '50%',
    background: '#494949',
  },
  emphasis: {
    color: '#4b7bff',
    textTransform: 'capitalize',
    fontWeight: '600',
  },
}));

const UserInfo = ({ user }) => (user ? <strong>{user?.email}</strong> : 'A user');

UserInfo.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string.isRequired,
  }),
};

export const LogCaseChange = ({ change }) => {
  let newValue = 'not defined';
  let oldValue = 'not defined';

  if (Array.isArray(change.newValue)) {
    if (change.newValue[0]) {
      if (Array.isArray(change.newValue[0])) {
        if (typeof change.newValue[0][0] === 'object' && !Array.isArray(change.newValue[0][0])) {
          if (change.newValue[0][0] !== null) {
            newValue =
              change.newValue[0]?.map((element, index) => {
                if (element === null) {
                  return newValue;
                }
                const keys = Object.keys(element);

                return (
                  <div style={{ marginTop: '5px', fontWeight: 'normal' }}>
                    Officer #{index + 1}:
                    {keys.map((key) => (
                      <div key={key}>
                        <b>{key}:</b> {element[key]}
                      </div>
                    ))}
                  </div>
                );
              }) || newValue;

            oldValue =
              change.oldValue[0]?.map((element, index) => {
                //Is a plain object
                const keys = Object.keys(element);

                return (
                  <div style={{ marginTop: '5px', fontWeight: 'normal' }}>
                    Officer #{index + 1}:
                    {keys.map((key) => (
                      <div key={key}>
                        <b>{key}:</b> {element[key]}
                      </div>
                    ))}
                  </div>
                );
              }) || oldValue;

            return (
              <>
                from <b>{oldValue}</b> to <b>{newValue}</b>
              </>
            );
          }
        } else {
          if (change.fieldName.includes('How did you hear about us?')) {
            newValue = change.newValue[0].map((value) => getAboutUsValue(value)).join(', ');
          } else {
            newValue = change.newValue[0].join(', ');
          }
        }
      } else if (typeof change.newValue[0] === 'object') {
        const keys = Object.keys(change.newValue[0]);

        newValue = (
          <div style={{ marginTop: '5px', fontWeight: 'normal' }}>
            {keys.map((key) => (
              <div key={key}>
                <b>{key}:</b> {change.newValue[0][key]}
              </div>
            ))}
          </div>
        );
      } else {
        newValue = change.newValue?.join(', ') || newValue;
      }
    }
  } else if (change.newValue && typeof change.newValue === 'object') {

    const keys = Object.keys(change.newValue);

    newValue = (
      <div style={{ marginTop: '5px', fontWeight: 'normal' }}>
        {keys.map((key) => (
          <div key={key}>
            <b>{key}:</b> {change.newValue[key]}
          </div>
        ))}
      </div>
    );
  } else {
    newValue = change.newValue || newValue;
  }

  if (Array.isArray(change.oldValue)) {
    if (change.oldValue[0]) {
      if (Array.isArray(change.oldValue[0])) {
        if (change.fieldName.includes('How did you hear about us?')) {
          oldValue = change.oldValue[0].map((value) => getAboutUsValue(value)).join(', ');
        } else {
          oldValue = change.oldValue[0].join(', ');
        }
      } else if (typeof change.oldValue[0] === 'object') {
        const keys = Object.keys(change.oldValue[0]);

        oldValue = (
          <div style={{ marginTop: '5px', fontWeight: 'normal' }}>
            {keys.map((key) => (
              <div key={key}>
                <b>{key}:</b> {change.oldValue[0][key]}
              </div>
            ))}
          </div>
        );
      } else {
        oldValue = change.oldValue.join(', ');
      }
    }
  } else if (change.oldValue && typeof change.oldValue === 'object') {
    const keys = Object.keys(change.oldValue);

    oldValue = (
      <div style={{ marginTop: '5px', fontWeight: 'normal' }}>
        {keys.map((key) => (
          <div key={key}>
            <b>{key}:</b> {change.oldValue[key]}
          </div>
        ))}
      </div>
    );
  } else if (typeof change.newValue === 'boolean' || typeof change.oldValue === 'boolean') {
    newValue = change.newValue === true ? 'Yes' : change.newValue === false ? 'No' : 'not defined';
    oldValue = change.oldValue === true ? 'Yes' : change.oldValue === false ? 'No' : 'not defined';
  } else {
    oldValue = change.oldValue ?? oldValue;
  }

  return (
    <>
      from <b>{oldValue}</b> to <b>{newValue}</b>
    </>
  );

  return null;
};

LogCaseChange.propTypes = {
  change: PropTypes.object.isRequired,
};

const Log = ({ _id, unread, data, type, createdAt, updatedAt, editedByInfo }) => {
  const classes = useStyles();
  const {
    assignments,
    changes,
    files,
    comment,
    officers,
    user,
    form,
    officer,
    newOrganization,
    newForm,
    oldOrganization,
    oldComment,
    newComment,
  } = data;
  const { organization } = useContext(Context);

  const [open, setOpen] = React.useState(false);

  const [filesSelected, setFilesSelected] = React.useState({ files: [], selectedFileIndex: 0 });
  const [loadingOpenFiles, setLoadingOpenFiles] = React.useState([]);

  const stripHTML = (str) => {
    if (!str) return '';
    return str.replace(/<\/?[^>]+(>|$)/g, '');
  };

  const convertValueToHTML = (value) => {
    if (Array.isArray(value)) {
      if (value.length) {
        return <span className={classes.emphasis}>{value.join(', ')}</span>;
      } else {
        return 'an empty value';
      }
    }
    if (typeof value === 'boolean') {
      return <span className={classes.emphasis}>{value ? 'Yes' : 'No'}</span>;
    }
    if (value === undefined || value === null || value === '') {
      return 'an empty value';
    }
    return <span className={classes.emphasis}>{value}</span>;
  };

  const handleOpen = (filesOp) => {
    setOpen(true);
    const filesToOpen = filesOp.filter((flop) => flop.mimetype.includes('image') || flop.mimetype.includes('video'));
    setFilesSelected({ files: filesToOpen, selectedFileIndex: 0 });
    setLoadingOpenFiles(
      filesToOpen.map(() => ({
        loading: false,
      }))
    );
  };

  const handleClickEvents = (fl) => ({
    onDownload: () => downloadImage(fl.download_url, fl.name),
    onClick: () => handleOpen(files),
  });

  const renderText = () => {
    if (type === 'CASE_DELETE') {
      return (
        <span className={classes.texxt}>
          <UserInfo user={user} /> deleted case
        </span>
      );
    }

    if (type === 'CASE_CONTACT_ASSIGNED') {
      return (
        <span className={classes.texxt}>
          <UserInfo user={user} /> has assigned contact{' '}
          <Link to={`/contacts/${data?.contact?._id}`}>
            <span style={{ color: '#2E66FE', fontWeight: '500' }}>{data?.contact?.email}</span>
          </Link>
        </span>
      );
    }

    if (type === 'CASE_CONTACT_REMOVE') {
      return (
        <span className={classes.texxt}>
          <UserInfo user={user} /> has removed contact{' '}
          <span style={{ color: '#2E66FE', fontWeight: '500' }}>{data?.contact?.email}</span>
        </span>
      );
    }

    if (type === 'CASE_EDIT_DATA') {
      return (
        <span className={classes.texxt}>
          <UserInfo user={user} /> edited case detail:{' '}
          <span style={{ color: '#2E66FE', fontWeight: '500' }}>{form?.code}</span>
          <br />
          {data?.changes?.length > 0 ? (
            <>
              {data?.changes
                // ?.filter((change) => change?.newValue)
                ?.map((change) => (
                  <div key={change?.fieldName}>
                    <span>
                      changed <b>{change?.fieldName}</b> <LogCaseChange change={change} />
                    </span>
                  </div>
                ))}
            </>
          ) : (
            <>Without changes</>
          )}
        </span>
      );
    }

    if (type === 'CASE_REFERRAL_DESTINATION') {
      return (
        <span className={classes.texxt}>
          This case was transferred from organization{' '}
          <span style={{ color: '#2E66FE', fontWeight: '500' }}>{oldOrganization?.name}</span> to organization{' '}
          <span style={{ color: '#2E66FE', fontWeight: '500' }}>{organization?.name}</span> with a new tracking number:{' '}
          <span style={{ color: '#2E66FE', fontWeight: '500' }}>{form?.code}</span>
        </span>
      );
    }
    if (type === 'CASE_REFERRAL_ORIGIN') {
      return (
        <span className={classes.texxt}>
          <UserInfo user={user} /> transfer case{' '}
          <span style={{ color: '#2E66FE', fontWeight: '500' }}>{form?.code}</span> to organization{' '}
          <span style={{ color: '#2E66FE', fontWeight: '500' }}>{newOrganization?.name}</span> with a new tracking
          number: <span style={{ color: '#2E66FE', fontWeight: '500' }}>{newForm?.code}</span>
        </span>
      );
    }

    if (type === 'CASE_ATTACHMENT_TAGS_ADDED') {
      return (
        <span className={classes.texxt}>
          <UserInfo user={user} /> has added {data?.tags?.length > 1 ? 'tags' : 'tag'}{' '}
          {data?.tags?.map((tag) => (
            <Tag bg={tag?.backgroundColor} color={tag?.textColor} key={tag?._id} text={tag?.name} />
          ))}{' '}
          to attachment: <span style={{ color: '#2E66FE', fontWeight: '500' }}>{data?.file?.name}</span>
        </span>
      );
    }

    if (type === 'CASE_ATTACHMENT_TAGS_DELETED') {
      return (
        <span className={classes.texxt}>
          <UserInfo user={user} /> has removed {data?.tags?.length > 1 ? 'tags' : 'tag'}{' '}
          {data?.tags?.map((tag) => (
            <Tag bg={tag?.backgroundColor} color={tag?.textColor} key={tag?._id} text={tag?.name} />
          ))}{' '}
          from attachment: <span style={{ color: '#2E66FE', fontWeight: '500' }}>{data?.file?.name}</span>
        </span>
      );
    }

    if (type === 'CASE_TASK_ATTACHMENT_TAGS_ADDED') {
      return (
        <span className={classes.texxt}>
          <UserInfo user={user} /> has added {data?.tags?.length > 1 ? 'tags' : 'tag'}{' '}
          {data?.tags?.map((tag) => (
            <Tag bg={tag?.backgroundColor} color={tag?.textColor} key={tag?._id} text={tag?.name} />
          ))}{' '}
          task attachment: <span style={{ color: '#2E66FE', fontWeight: '500' }}>{data?.file?.name}</span>
        </span>
      );
    }

    if (type === 'CASE_TASK_ATTACHMENT_TAGS_DELETED') {
      return (
        <span className={classes.texxt}>
          <UserInfo user={user} /> has removed {data?.tags?.length > 1 ? 'tags' : 'tag'}{' '}
          {data?.tags?.map((tag) => (
            <Tag bg={tag?.backgroundColor} color={tag?.textColor} key={tag?._id} text={tag?.name} />
          ))}{' '}
          from task attachment: <span style={{ color: '#2E66FE', fontWeight: '500' }}>{data?.file?.name}</span>
        </span>
      );
    }

    if (
      type === 'CASE_TASK_UPDATED' ||
      type === 'CASE_TASK_ASSIGNMENT_ADD' ||
      type === 'CASE_TASK_ASSIGNMENT_REMOVE' ||
      type === 'CASE_TASK_ATTACHMENT_ADDED' ||
      type === 'CASE_TASK_ATTACHMENT_DELETED'
    ) {
      return <CaseTaskUpdateItem item={{ _id, unread, type, data, createdAt }} user={user} />;
    }

    if (type === 'CASE_NOTE_UNARCHIVED') {
      return (
        <span className={classes.texxt}>
          <UserInfo user={user} /> has unarchived the note{' '}
          <span style={{ color: '#2E66FE', fontWeight: '500' }}>{stripHTML(data?.comment?.comment)}</span> on the case with tracking
          number{' '}
          <span style={{ color: '#2E66FE', fontWeight: '500' }}>{data?.form?.code}</span>
        </span>
      );
    }

    if (type === 'CASE_ARCHIVE_NOTE') {
      return (
        <span className={classes.texxt}>
          <UserInfo user={user} /> has archived the note{' '}
          <span style={{ color: '#2E66FE', fontWeight: '500' }}>{stripHTML(data?.comment?.comment)}</span> on the case with tracking
          number{' '}
          <span style={{ color: '#2E66FE', fontWeight: '500' }}>{data?.form?.code}</span>

        </span>
      );
    }

    if (type === 'CASE_VIEW') {
      return (
        <span className={classes.texxt}>
          <UserInfo user={user} /> viewed case:{' '}
          <span style={{ color: '#2E66FE', fontWeight: '500' }}>{form?.code}</span>
        </span>
      );
    }

    if (type === 'CASE_VIEW') {
      return (
        <span className={classes.texxt}>
          <UserInfo user={user} /> viewed case:{' '}
          <span style={{ color: '#2E66FE', fontWeight: '500' }}>{form?.code}</span>
        </span>
      );
    }

    if (type === 'CASE_ARCHIVED') {
      return (
        <span className={classes.texxt}>
          <UserInfo user={user} /> archived case:{' '}
          <span style={{ color: '#2E66FE', fontWeight: '500' }}>{form?.code}</span>
        </span>
      );
    }
    if (type === 'CASE_UNARCHIVED') {
      return (
        <span className={classes.texxt}>
          <UserInfo user={user} /> restored case:{' '}
          <span style={{ color: '#2E66FE', fontWeight: '500' }}>{form?.code}</span>
        </span>
      );
    }

    if (type === 'CASE_COMMENT_OR_ATTACHMENT') {
      const hasFiles = files.length > 0;
      const hasComment = !!comment;
      const hasBoth = hasFiles && hasComment;
      return (
        <>
          <UserInfo user={user} />{' '}
          {hasComment && (
            <>
              made a <span className={classes.emphasis}>new comment</span>
            </>
          )}
          {hasBoth && ' and '}
          {hasFiles && (
            <>
              <span className={classes.emphasis}>uploaded {files.length}</span> file(s)
            </>
          )}{' '}
          to the case
          {hasComment && (
            <>
              <HTMLContent content={comment} />
            </>
          )}
          {hasFiles && (
            <>
              <Box display="flex" flexDirection="column">
                {files.map((file, index) => {
                  const showPreview = !file?.archivedDate;
                  return (
                    <>
                      <Box display="flex" alignItems="center" justifyContent="flex-start">
                        <PreviewImg
                          key={file._id}
                          style={
                            !file.name.includes('.pdf')
                              ? { minHeight: 100, margin: 6 }
                              : { minHeight: 35, width: 'fit-content' }
                          }
                        >
                          {showPreview ? (
                            <LogIcon fl={file} handleClickEvents={handleClickEvents(file)} index={index} />
                          ) : (
                            <ArchivedImage />
                          )}
                        </PreviewImg>
                        <Typography style={{ fontSize: 14 }}>{file.name}</Typography>
                      </Box>
                      <Tags autoUpdateTags={false} fileId={file?._id} defaultTags={file?.tags || []} />
                    </>
                  );
                })}
              </Box>
            </>
          )}
        </>
      );
    }

    if (type === 'CASE_COMMENT_EDIT') {
      return (
        <>
          <UserInfo user={user} /> edited a comment in the case
          <br />
          <br />
          <span style={{ fontWeight: 'bold' }}>{' before:'}</span>
          <HTMLContent content={oldComment} />
          <span style={{ fontWeight: 'bold' }}>{' after:'}</span>
          <HTMLContent content={newComment} />
        </>
      );
    }
    if (type === 'CASE_ASSIGNMENT_ADD') {
      return (
        <>
          <UserInfo user={user} /> has assigned the case to{' '}
          <span className={classes.emphasis}>
            {assignments?.[0]?.firstName} {assignments?.[0]?.lastName}
          </span>{' '}
        </>
      );
    }
    if (type === 'CASE_OFFICER_ADD') {
      return (
        <>
          <UserInfo user={user} /> has added Officer{' '}
          <span className={classes.emphasis}>
            {officers?.[0]?.firstName} {officers?.[0]?.lastName}
          </span>{' '}
          with Badge Number: <span className={classes.emphasis}>{officers?.[0]?.badgeNumber}</span>
        </>
      );
    }
    if (type === 'CASE_OFFICER_REMOVE') {
      return (
        <>
          <UserInfo user={user} /> has removed Officer{' '}
          <span className={classes.emphasis}>
            {officers?.[0]?.firstName} {officers?.[0]?.lastName}
          </span>{' '}
          with Badge Number: <span className={classes.emphasis}>{officers?.[0]?.badgeNumber}</span>
        </>
      );
    }
    if (type === 'IMPORT_CASE') {
      return (
        <>
          <UserInfo user={user} /> imported this case{' '}
        </>
      );
    }
    if (type === 'CASE_ALLEGATION_ROW_ADD') {
      return (
        <>
          <UserInfo user={user} /> has added a new Allegation row to Officer{' '}
          <span className={classes.emphasis}>
            {officer?.firstName} {officer?.lastName}
          </span>{' '}
          with Badge Number: <span className={classes.emphasis}>{officer?.badgeNumber}</span>
        </>
      );
    }
    if (type === 'CASE_ALLEGATION_ROW_DELETE') {
      return (
        <>
          <UserInfo user={user} /> has removed an Allegation row to Officer{' '}
          <span className={classes.emphasis}>
            {officer?.firstName} {officer?.lastName}
          </span>{' '}
          with Badge Number: <span className={classes.emphasis}>{officer?.badgeNumber}</span>
        </>
      );
    }
    if (type === 'CASE_ALLEGATION_ROW_UPDATE') {
      return changes?.map((change, index) => {
        if (change?.fieldName === 'allegation') {
          return (
            <Box key={index}>
              {!change?.oldValue ? (
                <>
                  <UserInfo user={user} /> has added <span className={classes.emphasis}>{change.newValue}</span> to
                  Allegation for Officer{' '}
                  <span className={classes.emphasis}>
                    {officer?.firstName} {officer?.lastName}
                  </span>{' '}
                  with Badge Number: <span className={classes.emphasis}>{officer?.badgeNumber}</span>
                </>
              ) : (
                <>
                  <UserInfo user={user} /> has changed the Allegation from{' '}
                  <span className={classes.emphasis}>{change.oldValue}</span> to{' '}
                  <span className={classes.emphasis}>{change.newValue}</span>
                  for Officer{' '}
                  <span className={classes.emphasis}>
                    {officer?.firstName} {officer?.lastName}
                  </span>{' '}
                  with Badge Number: <span className={classes.emphasis}>{officer?.badgeNumber}</span>
                </>
              )}
            </Box>
          );
        }
        if (change?.fieldName === 'description') {
          return (
            <Box key={index}>
              {!change?.oldValue ? (
                <>
                  <UserInfo user={user} /> has added a Description for Officer{' '}
                  <span className={classes.emphasis}>
                    {officer?.firstName} {officer?.lastName}
                  </span>{' '}
                  with Badge Number: <span className={classes.emphasis}>{officer?.badgeNumber}</span>
                  <HTMLContent content={change.newValue} />
                </>
              ) : (
                <>
                  <UserInfo user={user} /> has changed the Description for Officer{' '}
                  <span className={classes.emphasis}>
                    {officer?.firstName} {officer?.lastName}
                  </span>{' '}
                  with Badge Number: <span className={classes.emphasis}>{officer?.badgeNumber}</span>
                  {' from:'}
                  <HTMLContent content={change.oldValue} />
                  {' to:'}
                  <HTMLContent content={change.newValue} />
                </>
              )}
            </Box>
          );
        }
        if (change?.fieldName === 'disposition') {
          return (
            <Box key={index}>
              {!change?.oldValue ? (
                <>
                  <UserInfo user={user} /> has added <span className={classes.emphasis}>{change.newValue}</span> to
                  Disposition for Officer{' '}
                  <span className={classes.emphasis}>
                    {officer?.firstName} {officer?.lastName}
                  </span>{' '}
                  with Badge Number: <span className={classes.emphasis}>{officer?.badgeNumber}</span>
                </>
              ) : (
                <>
                  <UserInfo user={user} /> has changed the Disposition from{' '}
                  <span className={classes.emphasis}>{change.oldValue}</span> to{' '}
                  <span className={classes.emphasis}>{change.newValue}</span> for Officer{' '}
                  <span className={classes.emphasis}>
                    {officer?.firstName} {officer?.lastName}
                  </span>{' '}
                  with Badge Number: <span className={classes.emphasis}>{officer?.badgeNumber}</span>
                </>
              )}
            </Box>
          );
        }
        if (change?.fieldName === 'discipline') {
          return (
            <Box key={index}>
              {!change?.oldValue ? (
                <>
                  <UserInfo user={user} /> has added <span className={classes.emphasis}>{change.newValue}</span> to
                  Discipline for Officer{' '}
                  <span className={classes.emphasis}>
                    {officer?.firstName} {officer?.lastName}
                  </span>{' '}
                  with Badge Number: <span className={classes.emphasis}>{officer?.badgeNumber}</span>
                </>
              ) : (
                <>
                  <UserInfo user={user} /> has changed the Discipline from{' '}
                  <span className={classes.emphasis}>{change.oldValue}</span> to{' '}
                  <span className={classes.emphasis}>{change.newValue}</span>
                  to Officer{' '}
                  <span className={classes.emphasis}>
                    {officer?.firstName} {officer?.lastName}
                  </span>{' '}
                  with Badge Number: <span className={classes.emphasis}>{officer?.badgeNumber}</span>
                </>
              )}
            </Box>
          );
        }
        if (change?.fieldName === 'reason') {
          return (
            <Box key={index}>
              {!change?.oldValue ? (
                <>
                  <UserInfo user={user} /> has added <span className={classes.emphasis}>{change.newValue}</span> to
                  Reason for Officer{' '}
                  <span className={classes.emphasis}>
                    {officer?.firstName} {officer?.lastName}
                  </span>{' '}
                  with Badge Number: <span className={classes.emphasis}>{officer?.badgeNumber}</span>
                </>
              ) : (
                <>
                  <UserInfo user={user} /> has changed the Reason from{' '}
                  <span className={classes.emphasis}>{change.oldValue}</span> to{' '}
                  <span className={classes.emphasis}>{change.newValue}</span> to Officer{' '}
                  <span className={classes.emphasis}>
                    {officer?.firstName} {officer?.lastName}
                  </span>{' '}
                  with Badge Number: <span className={classes.emphasis}>{officer?.badgeNumber}</span>
                </>
              )}
            </Box>
          );
        }

        if (change?.fieldName === 'status') {
          return (
            <Box key={index}>
              {!change?.oldValue ? (
                <>
                  <UserInfo user={user} /> has added <span className={classes.emphasis}>{change.newValue}</span> in
                  Status to Officer{' '}
                  <span className={classes.emphasis}>
                    {officer?.firstName} {officer?.lastName}
                  </span>{' '}
                  with Badge Number: <span className={classes.emphasis}>{officer?.badgeNumber}</span>
                </>
              ) : (
                <>
                  <UserInfo user={user} /> has changed the Allegation's Status from{' '}
                  <span className={classes.emphasis}>{change.oldValue}</span> to{' '}
                  <span className={classes.emphasis}>{change.newValue}</span> for Officer{' '}
                  <span className={classes.emphasis}>
                    {officer?.firstName} {officer?.lastName}
                  </span>{' '}
                  with Badge Number: <span className={classes.emphasis}>{officer?.badgeNumber}</span>
                </>
              )}
            </Box>
          );
        }

        return null;
      });
    }
    if (type === 'CASE_UPDATE') {
      return changes?.map((change, index) => {
        if (change?.fieldName === 'status') {
          return (
            <Box key={index}>
              <UserInfo user={user} /> has changed the case status from{' '}
              <span className={classes.emphasis}>{getStatusTitle(changes[0]?.oldValue)}</span> to{' '}
              <span className={classes.emphasis}>{getStatusTitle(changes[0]?.newValue)}</span>
            </Box>
          );
        }

        if (change?.fieldName === 'type') {
          return (
            <Box key={index}>
              <UserInfo user={user} /> has changed the case type{' '}
              <span className={classes.emphasis}>{getStatusTitle(changes[0]?.oldValue)}</span> to{' '}
              <span className={classes.emphasis}>{getStatusTitle(changes[0]?.newValue)}</span>
            </Box>
          );
        }

        if (change?.fieldName === 'caseNumber') {
          return (
            <Box key={index}>
              <>
                <UserInfo user={user} /> has changed the case number
                {change.oldValue ? (
                  <>
                    {' from '}
                    {convertValueToHTML(change.oldValue)}
                  </>
                ) : null}{' '}
                to {convertValueToHTML(change.newValue)}
              </>
            </Box>
          );
        }
        if (change?.fieldName === 'internalAffairsCaseNumber') {
          change.fieldName = 'Internal Affairs Case Number';
        }
        if (change?.fieldName.length > 0) {
          return (
            <Box key={index}>
              {!change?.oldValue || change?.oldValue?.length === 0 ? (
                <>
                  <UserInfo user={user} /> has added {convertValueToHTML(change.newValue)} to{' '}
                  <span style={{ textTransform: 'capitalize' }}>{change?.fieldName}</span>{' '}
                </>
              ) : (
                <>
                  <UserInfo user={user} /> has changed the{' '}
                  <span style={{ textTransform: 'capitalize' }}>{change?.fieldName}</span> from{' '}
                  {convertValueToHTML(change.oldValue)} to {convertValueToHTML(change.newValue)}
                </>
              )}
            </Box>
          );
        }

        return null;
      });
    }
    if (type === 'CASE_ASSIGNMENT_REMOVE') {
      return (
        <>
          <UserInfo user={user} /> has removed{' '}
          <span className={classes.emphasis}>
            {assignments?.[0]?.firstName} {assignments?.[0]?.lastName}
          </span>{' '}
          from the case
        </>
      );
    }

    if (type === 'CASE_LINKED') {
      return (
        <>
          <UserInfo user={user} /> The case with tracking number{' '}
          <Link to={`/case/${data?.form?.code}`}>
            <span style={{ color: '#2E66FE', fontWeight: '500' }}>{data?.form?.code}</span>
          </Link>{' '}
          has been linked to the case with tracking number{' '}
          <Link to={`/case/${data?.otherForm?.code}`}>
            <span style={{ color: '#2E66FE', fontWeight: '500' }}>{data?.otherForm?.code}</span>
          </Link>
          .
        </>
      );
    }

    if (type === 'CASE_UNLINKED') {
      return (
        <>
          <UserInfo user={user} /> The case with tracking number{' '}
          <Link to={`/case/${data?.form?.code}`}>
            <span style={{ color: '#2E66FE', fontWeight: '500' }}>{data?.form?.code}</span>
          </Link>{' '}
          has been unlinked from the case with tracking number
          <Link to={`/case/${data?.otherForm?.code}`}>
            <span style={{ color: '#2E66FE', fontWeight: '500' }}>{data?.otherForm?.code}</span>
          </Link>
          .
        </>
      );
    }

    if (type === 'CASE_ATTACHMENT_ARCHIVED') {
      return (
        <>
          <UserInfo user={user} /> has archived the file{' '}
          <span style={{ color: '#2E66FE', fontWeight: '500' }}>{data?.file?.name}</span> on the case with tracking
          number{' '}
          <Link to={`/case/${data?.otherForm?.code}`}>
            <span style={{ color: '#2E66FE', fontWeight: '500' }}>{data?.form?.code}</span>
          </Link>
          .
        </>
      );
    }

    if (type === 'CASE_ATTACHMENT_UNARCHIVED') {
      return (
        <>
          <UserInfo user={user} /> has unarchived the file{' '}
          <span style={{ color: '#2E66FE', fontWeight: '500' }}>{data?.file?.name}</span> on the case with tracking
          number{' '}
          <Link to={`/case/${data?.otherForm?.code}`}>
            <span style={{ color: '#2E66FE', fontWeight: '500' }}>{data?.form?.code}</span>
          </Link>
          .
        </>
      );
    }

    return null;
  };

  return (
    <>
      <TimeLineItem
        createdAt={createdAt}
        profileImg={user?.photo?.public_url}
        avatar={`${user?.email[0]?.toUpperCase() || oldOrganization?.prefix} ${user?.email[1]?.toUpperCase() || ''} `}
        useDefaultImage={!user}
        updatedAt={updatedAt}
        editedByInfo={editedByInfo}
      >
        {renderText()}
      </TimeLineItem>
      <CarouselModal
        open={open}
        setOpen={setOpen}
        filesSelected={filesSelected}
        setLoadingOpenFiles={setLoadingOpenFiles}
        loadingOpenFiles={loadingOpenFiles}
      />
    </>
  );
};

Log.propTypes = {
  _id: PropTypes.string.isRequired,
  unread: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    file: PropTypes.object,
    assignments: PropTypes.array,
    changes: PropTypes.array,
    files: PropTypes.array,
    comment: PropTypes.string,
    allegationRow: PropTypes.string,
    officers: PropTypes.array,
    user: PropTypes.string,
    form: PropTypes.string,
    officer: PropTypes.string,
    oldForm: PropTypes.string,
    newOrganization: PropTypes.string,
    newForm: PropTypes.string,
    oldOrganization: PropTypes.string,
    oldComment: PropTypes.string,
    newComment: PropTypes.string,
    tags: PropTypes.array,
    contact: PropTypes.object,
  }).isRequired,
  type: PropTypes.string.isRequired,
  createdAt: PropTypes.instanceOf(Date).isRequired,
  updatedAt: PropTypes.instanceOf(Date).isRequired,
  editedByInfo: PropTypes.string,
};

export const PreviewImg = styled.div`
  height: 100px;
  position: relative;
  overflow: hidden;
  margin-right: 10px;

  & div:hover {
    opacity: 1;
  }
`;

export default Log;
