import { InputBase, NativeSelect, withStyles } from '@material-ui/core';
import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const BootstrapInputFillled = withStyles((theme) => ({
  root: {
    width: '100%',

    height: 40,
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#f8f8f9',
    border: '1px solid #f8f8f9',
    fontSize: 13,
    height: 20,
    padding: '7px 26px 7px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#f8f8f9',
      backgroundColor: '#f8f8f9',
    },
  },
}))(InputBase);

const BootstrapInputOutlined = withStyles((theme) => ({
  root: {
    width: '100%',
    background: '#fff',
    height: 40,
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: 'transparent',
    border: '1px solid rgba(0,0,0,0.23)',
    fontSize: 13,

    height: 20,
    padding: '7px 26px 7px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: 'rgba(0,0,0,0.23)',
    },
  },
}))(InputBase);

const BootstrapInputBorderLess = withStyles((theme) => ({
  root: {
    width: '100%',

    height: 40,
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: 'transparent',
    // border: '1px solid rgba(0,0,0,0.23)',
    fontSize: 14,
    height: 20,
    padding: '7px 26px 7px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: 'transparent',
      backgroundColor: 'transparent',
    },
  },
}))(InputBase);

const SelectBox = ({
  id,
  value,
  onChange,
  options,
  outlined,
  dataTestId,
  withColors = null,
  allowPlaceHolder = true,
  showDefaultValue = true,
  placeholderText,
  disabled,
  hidePlaceholderWhenSelectedValue,
}) => {
  const BootstrapInputColors = withStyles((theme) => ({
    root: {
      width: '100%',

      height: 40,

      '& svg': {
        color: `${withColors.color} !important`,
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: withColors?.bg ? withColors?.bg : 'transparent',
      border: withColors?.border ? `1px solid ${withColors?.border}` : '1px solid rgba(0,0,0,0.23)',
      color: withColors.color,
      fontSize: 14,
      fontWeight: '500',
      height: 20,
      padding: '7px 26px 7px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: ['Roboto'].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: withColors.focus ? withColors.focus : 'transparent',
        backgroundColor: withColors.focus ? withColors.focus : 'transparent',
      },
    },
  }))(InputBase);

  const renderInputComponent = () => {
    if (withColors) {
      return <BootstrapInputColors />;
    }
    if (typeof outlined === 'string') {
      return <BootstrapInputBorderLess />;
    }
    if (outlined) {
      return <BootstrapInputOutlined />;
    }
    return <BootstrapInputFillled />;
  };

  return (
    <NativeSelect
      id={id}
      input={renderInputComponent()}
      onChange={onChange}
      value={value}
      style={{ textTransform: 'capitalize' }}
      defaultValue={showDefaultValue ? `Select an Option` : null}
      IconComponent={ExpandMoreIcon}
      placeholder="translate To"
      inputProps={{ 'aria-label': 'age', 'data-testid': dataTestId }}
    >
      {allowPlaceHolder && (
        <>{hidePlaceholderWhenSelectedValue && value ? null : <option value="0">Select an Option</option>}</>
      )}

      {placeholderText && (
        <option value="" disabled>
          {placeholderText}
        </option>
      )}

      {Array.isArray(options)
        ? options.map((option, index) => (
            <option value={option} key={index} style={{ textTransform: 'capitalize', color: '#333' }}>
              {option}
            </option>
          ))
        : options.obj.map((option, index) => (
            <option
              value={option?.value}
              key={index}
              disabled={option?.disabled}
              style={{ textTransform: 'capitalize', color: '#333' }}
            >
              {option?.text}
            </option>
          ))}
    </NativeSelect>
  );
};

export default SelectBox;
