import { useState, useCallback, useEffect } from 'react';

import { fetchTags } from '../services/unarmed';

export const useTags = (allowSearchEmpty = true) => {
  const [search, setSearch] = useState(undefined);
  const [tagsRecently, setTagsRecently] = useState([]);
  const [loading, setLoading] = useState(true);

  const onGetTagsRecently = useCallback(() => {
    const params = {
      sortOrder: 'asc',
      sortBy: search ? 'name' : 'count',
      limit: 20,
      page: 1,
      ...(search ? { search } : {}),
    };
    const makeCall = search ? true : allowSearchEmpty;

    if (makeCall) {
      setLoading(true);
      fetchTags(params).then((res) => {
        setTagsRecently(res.data);
        setLoading(false);
      });
    }
  }, [search, allowSearchEmpty]);

  useEffect(() => {
    onGetTagsRecently();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return [tagsRecently, setSearch, loading];
};
