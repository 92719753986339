import React, { useState } from 'react';
import { Box, Checkbox, makeStyles, Typography } from '@material-ui/core';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import DeleteIcon from '@material-ui/icons/Delete';
import WrapperComponent from './WrapperComponent';
import Input from '../../components/Input';
import DnDArea from '../../components/DnDArea';
import DnDItem from '../../components/DnDItem';
import { onAddDnDItem, onChangeDnDValue, onRemoveDnDItem } from '../utils/utls';
import AddMore from '../../components/AddMore';
import useInput from '../../hooks/useInput';
import Settings from '../utils/Settings';
import PreviewLabel from '../utils/Label';

import HideAnswer from '../../components/HideAnswer';
import { mapOptionsToState } from './Dropdown';

const useStyles = makeStyles(() => ({
  title: {
    fontSize: 14,
    color: '#000000DE',
    marginLeft: 10,
  },
  label: {
    fontSize: 16,
    color: '#000000DE',
    fontWeight: '500',
  },

  subLabel: {
    fontSize: 14,
    color: '#000000DE',
    fontWeight: '500',
  },

  settingsQuestions: {
    fontSize: 14,
    minWidth: 160,
  },
}));

export default function MultipleChoice({
  type,
  questionBankComponent,
  id,
  componentId,
  onUpdateJson,
  onDeleteComponent,
  path,
  data,
  includeRequired,
  onChangeDataInput,
  componentIndex,
  duplicateComponent,
  refActionModal,
  setModal,
}) {
  const classes = useStyles();
  const [answers, setAnswers] = useState(mapOptionsToState(data.options));
  const responseValidation = useInput(data?.responseValidationNumber || '');
  const selectTypeResponseValidation = useInput(data?.responseValidationOption || '');
  const [checkQuestion, setCheckQuestion] = useState([
    !!data?.description,
    !!data.requiredMessage,
    !!data?.responseValidation,
  ]);
  const required = useInput(!!data?.required, false, false, true);
  const descriptionQuestion = useInput(data?.description || '');
  const requiredQuestionMessage = useInput(data?.requiredMessage || '');
  const question = useInput(data?.question || '');
  const [loading, setLoading] = React.useState(false);

  const handleSaveComponent = () => {
    const dataJson = {
      ...(descriptionQuestion.value ? { description: descriptionQuestion.value } : {}),
      ...(requiredQuestionMessage.value ? { requiredMessage: requiredQuestionMessage.value } : {}),
      ...(checkQuestion[2] ? { responseValidationOption: selectTypeResponseValidation.value } : {}),
      ...(checkQuestion[2] ? { responseValidationNumber: +responseValidation.value } : {}),
      ...(data.dataReportLink ? { dataReportLink: data.dataReportLink } : {}),
      question: question.value,
      responseValidation: checkQuestion[2],
      required: required.value,
      options: answers.map((answer) => answer.value),
    };

    onUpdateJson(dataJson);
  };

  const renderer = (moveItem) =>
    answers.map((answer, index) => (
      <DnDItem key={index} moveItem={moveItem} index={index}>
        <Box width="100%" maxWidth={434} display="flex" alignItems="center" marginBotom="10px">
          <Typography className={classes.subLabel}>A{index + 1}</Typography>
          <Checkbox
            checked={false}
            disabled
            color="default"
            inputProps={{ 'aria-label': 'checkbox with default color' }}
          />
          <Input
            placeholder={`Enter option ${index + 1}`}
            value={answer.value}
            onChange={(e) => {
              onChangeDnDValue(e, setAnswers, index);
              saveOnTimeOut();
            }}
          />
          <DeleteIcon
            htmlColor="#6D7385"
            style={{ marginLeft: 20, cursor: 'pointer' }}
            onClick={() => {
              onRemoveDnDItem(answers, setAnswers, index);
              saveOnTimeOut();
            }}
          />
        </Box>
      </DnDItem>
    ));
  const saveOnTimeOut = () => {
    setLoading(true);
    setTimeout(() => {
      handleSaveComponent();
      setLoading(false);
    }, 500);
  };
  const renderSettings = () => (
    <Settings
      componentId={componentId}
      componentType="multipleChoices"
      defaultDataReportLink={data.dataReportLink}
      onSelectDataReportLink={(dataReportLink) => onUpdateJson({ ...data, dataReportLink })}
      saveOnTimeOut={saveOnTimeOut}
      questions={['description', 'required', 'response validation']}
      checkQuestion={checkQuestion}
      descriptionQuestion={descriptionQuestion}
      requiredQuestion={requiredQuestionMessage}
      setCheckQuestion={setCheckQuestion}
      responseValidation={responseValidation}
      responseTypeValidation={selectTypeResponseValidation}
    />
  );

  const renderPreview = () => (
    <Box>
      <PreviewLabel question={question.value} qNumber={componentIndex + 1} required={required.value} />
      <Box marginLeft="45px">
        <HideAnswer marginLeft="-7px" marginTop={0}>
          {answers.map((answer, index) => (
            <Box display="flex" alignItems="center" key={index} marginBottom="10px">
              <Checkbox
                color="default"
                disabled
                inputProps={{ 'aria-label': 'checkbox with default color' }}
                style={{ padding: 0, marginRight: 5 }}
              />
              <Typography style={{ color: '#00000061' }}>{answer.value}</Typography>
            </Box>
          ))}
        </HideAnswer>
      </Box>
    </Box>
  );

  return (
    <WrapperComponent
      onBlur={(!questionBankComponent && saveOnTimeOut) || function () {}}
      renderSettings={renderSettings}
      componentIndex={componentIndex}
      duplicateComponent={duplicateComponent}
      isQuestionBankComponent={questionBankComponent}
      renderPreview={renderPreview}
      required={required}
      includeRequired={includeRequired}
      onDeleteComponent={onDeleteComponent}
      id={id}
      componentId={componentId}
      path={path}
      loading={loading}
      handleSaveComponent={handleSaveComponent}
      dataSaved={data?.question}
      refActionModal={refActionModal}
      setModal={setModal}
    >
      <Box display="flex" alignItems="center">
        <Checkbox
          checked
          color="default"
          inputProps={{ 'aria-label': 'checkbox with default color' }}
          style={{ padding: 0 }}
        />
        <Typography className={classes.title}>Multiple Choice</Typography>
      </Box>
      <Box display="flex" alignItems="center" marginTop="15px">
        <Typography className={classes.label}>Q{componentIndex + 1}</Typography>
        <ArrowRightAltIcon style={{ fontSize: 14, marginRight: 10 }} />
        <Input
          placeholder="Enter name question"
          value={question.value}
          onChange={(e) => {
            question.onChange(e);
            saveOnTimeOut();
          }}
        />
      </Box>
      <Box marginLeft="20px" marginTop="30px">
        {/* <DndProvider backend={HTML5Backend}> */}
        <DnDArea selectedItems={answers} setSelectedItems={setAnswers} renderer={renderer} />
        {/* </DndProvider> */}
        <AddMore
          dataTestId="forms-multiple-choice-button-add-more"
          onClick={() => onAddDnDItem(answers, setAnswers, 'radio', '')}
        />
      </Box>
    </WrapperComponent>
  );
}
