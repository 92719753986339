import {
  Box,
  Button,
  ClickAwayListener,
  Grow,
  ListItemIcon,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@material-ui/core';
import React, { useState } from 'react';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));

const MenuTable = ({
  options = [],
  allowChildren = false,
  buttonPadding = true,
  children,
  onSelect,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = React.useRef(null);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  return (
    <Box style={{ minWidth: 150 }}>
      <Button
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        style={{ padding: !buttonPadding ? 0 : '6px 8px' }}
      >
        {allowChildren ? children : <MoreHorizIcon />}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: 9 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  {options.map((option, index) => {
                    if (typeof option === 'string') {
                      return (
                        <MenuItem
                          key={index}
                          style={{ fontSize: 14, minWidth: 145 }}
                          onClick={() => {
                            handleToggle();
                            setTimeout(() => {
                              onSelect(option);
                            }, 500);
                          }}
                        >
                          {option}
                        </MenuItem>
                      );
                    }

                    return (
                      <MenuItem
                        key={index}
                        style={{ fontSize: 14 }}
                        onClick={() => option.onClick(setOpen)}
                      >
                        <ListItemIcon>
                          <option.Icon
                            fontSize="small"
                            style={{ minWidth: 35 }}
                          />
                        </ListItemIcon>
                        {option.text}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};

export default MenuTable;
