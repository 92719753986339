/* eslint-disable no-nested-ternary */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-unused-expressions  */
import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, CircularProgress, Typography, makeStyles } from '@material-ui/core';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import shortid from 'shortid';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import QuestionBank from './QuestionBank';
import Pages from './Pages';
import PageDetails from './PageDetails';
import { getFormType, getQuestionBank, onSaveFormType } from '../../services/unarmed';
import CaseUpdate from './CaseUpdate';
import ModalPopUp from '../../components/ModaPopupl';
import CaseStatus from './CaseStatus';
import FormTasks from './FormTasks';
import { FORM_TYPE_VERSION_2 } from '../../config/config';
import OfficerUpdate from './OfficerUpdate';

const useStyles = makeStyles(() => ({
  buttonOutlined: {
    letterSpacing: '1px',
    fontSize: 13,
    fontWeight: '500',
    marginRight: 10,
    width: 'fit-content',
    alignSelf: 'flex-end',
    border: '1px solid transparent',
    color: '#4b7bff',
    '&:hover': {
      background: 'transparent',
      border: '1px solid transparent',
    },
    '& .MuiButton-label': {
      fontSize: 13,
    },
  },
}));

const ignoreExtraProps = (pages) => {
  const pagesFormatted = pages.map((page) => ({
    name: page.name,
    sections: page.sections.map((section) => ({
      name: section.name,
      components: section.components.map((component) => {
        const data = {
          data: component.data || {},
          type: component.type,
          ...(component.uniqueId ? { uniqueId: component.uniqueId } : {}),
        };
        if (component.type === 'demographics') {
          const demographicsComps = {};
          const dataReportLink = {
            ...(component?.data?.dataReportLink ? { dataReportLink: component?.data?.dataReportLink } : {}),
          };
          component.components.forEach((comp) => {
            if (comp.type === 'dateOfBirth') {
              demographicsComps.birthdate = { ...comp.data, ...dataReportLink };
            } else {
              demographicsComps[comp.type] = { ...comp.data, ...dataReportLink };
            }
          });

          delete demographicsComps.dataReportLink;

          data.data = demographicsComps;
          return data;
        }

        return data;
      }),
    })),
  }));
  return {
    pages: pagesFormatted,
  };
};

const getDemographicName = (type) => {
  switch (type) {
    case 'gender':
      return 'Gender';
    case 'sexualOrientation':
      return 'Sexual Orientation';
    case 'dateOfBirth':
      return 'Date of Birth';
    case 'raceEthnicity':
      return 'Race/Ethnicity';
    default:
      return '';
  }
};

export default function FormProfile() {
  const { id } = useParams();
  const classes = useStyles();
  const refActionModal = useRef(null);
  const [isSomeQuesRequired, setSomeQuesRequired] = useState(false);
  const [loading, setLoading] = useState('idle');
  const [pages, setPages] = useState([]);
  const [loadingForm, setLoadingForm] = useState('idle');
  const [selectedPage, setSelectedPage] = useState(0);
  const [formInfo, setFormInfo] = useState(null);
  const [selected, setSelected] = useState({ name: 'Standard' });
  const [open, setOpen] = useState(false);
  const [questionsBank, setQuestionsBank] = useState([]);

  const [value, setValue] = useState(0);

  /**
   * @description This useEffect is used to get the question bank from endpoint and format the data to be used in the form
   */
  useEffect(() => {
    const onGetQuestionBank = async () => {
      setLoadingForm('pending');
      try {
        const response = await getQuestionBank();
        const questions = response.data?.map((res) => ({
          ...res,
          components: res.components.map((item) => ({
            ...item,
            idQuestion: res._id,
          })),
          toggleOptions: false,
          isVisible: true,
        }));

        setQuestionsBank(questions);
      } catch (err) {
        console.log('onGetQuestionBank.error', err.response);
      }
    };
    if (id) {
      onGetQuestionBank();
    }
  }, [id]);

  const handleVisibleQuestion = (idQuestion, visibleOption) => {
    const arrCopy = [...questionsBank];
    const newArr = arrCopy.map((res) => {
      if (idQuestion === res._id) {
        return {
          ...res,
          isVisible: visibleOption,
        };
      }
      return res;
    });
    setQuestionsBank(newArr);
  };

  const handleInitVisibleQuestion = (questionBankExists, typeAction) => {
    const arrCopy = [...questionsBank];
    const newArr = arrCopy.map((res) => {
      if (questionBankExists.some((item) => item.idQuestion === res._id)) {
        return {
          ...res,
          isVisible: typeAction === 'reset',
        };
      }
      return res;
    });
    setQuestionsBank(newArr);
  };

  /**
   * @description This useEffect is used to get the form type from endpoint and re-organize the question bank components from the rest of the components
   */
  useEffect(() => {
    const onGetFormTypes = async () => {
      try {
        const { data } = await getFormType(id);

        const newPages = data.pages.map((page) => ({
          ...page,
          sections: page.sections.map((section) => {
            const questionBankComponents = section?.components?.map((component, index) => {
              if (component.type === 'questionBank') {
                const questionBankComponent = questionsBank.find((q) => q.type === component.data.type);
                return {
                  components: questionBankComponent.components,
                  index,
                  id: shortid.generate(),
                  uniqueId: component.uniqueId,
                  componentType: component.data.type,
                };
              }

              return { ...component, id: shortid.generate() };
            });

            const componentsCopy = section.components.slice();
            questionBankComponents.forEach((q) => {
              if (q?.hasOwnProperty('index')) {
                const questionBankComponent = {
                  type: 'questionBank',
                  idQuestion: q.components[0].idQuestion,
                  data: {
                    type: q.componentType,
                  },
                  id: shortid.generate(),
                  uniqueId: q.uniqueId,
                  components: q.components.map((component, index) => ({
                    ...component,
                    id: `${shortid.generate()}-${index}`,

                    questionBankComponent: true,
                  })),
                };

                componentsCopy.splice(q.index, 1, questionBankComponent);
              }
            });
            return {
              ...section,
              id: shortid.generate(),
              components: componentsCopy.map((comps) => {
                if (comps.type === 'demographics') {
                  const demographicskeys = Object.keys(comps.data).sort();
                  const demographicsComps = demographicskeys.map((key) => {
                    if (key === 'birthdate') {
                      return {
                        type: 'dateOfBirth',
                        data: comps.data[key],
                        name: getDemographicName(key),
                        id: shortid.generate(),
                        shouldRenderToggle: true,
                      };
                    }
                    return {
                      type: key,
                      data: comps.data[key],
                      name: getDemographicName(key),
                      id: shortid.generate(),
                      shouldRenderToggle: true,
                    };
                  });
                  return {
                    data: {},
                    type: 'demographics',
                    uniqueId: comps.uniqueId,
                    components: demographicsComps,
                    id: shortid.generate(),
                  };
                }

                if (comps.type !== 'questionBank') {
                  return { ...comps, id: shortid.generate() };
                }

                return comps;
              }),
            };
          }),
        }));

        const questionBankAdded = [];

        newPages.forEach((item) => {
          item.sections.forEach((res) => {
            const arrComponents = res.components.filter((component) => component.type === 'questionBank');
            if (arrComponents.length > 0) {
              questionBankAdded.push(...arrComponents);
            }
          });
        });

        if (questionBankAdded.length > 0) {
          handleInitVisibleQuestion(questionBankAdded, 'added');
        }
        if (data.pages.length === 0) {
          setPages([
            {
              label: 'Page Title',
              name: 'Page Title',
              sections: [
                {
                  name: 'Section Name',
                  id: shortid.generate(),
                  components: [],
                },
              ],
            },
          ]);
        } else {
          setPages(newPages);
        }

        if (data.version === FORM_TYPE_VERSION_2) {
          setFormInfo({
            ...data,
            customFields: data.customFields.map((page) => ({
              ...page,
              sections: [
                {
                  ...page.sections?.[0],
                  components: page.sections?.[0]?.components.map((component) => ({
                    ...component,
                    id: shortid.generate(),
                  })),
                },
              ],
              pageId: shortid.generate(),
            })),
            officersCustomFields: data.officersCustomFields.map((page) => ({
              ...page,
              sections: [
                {
                  ...page.sections?.[0],
                  components: page.sections?.[0]?.components.map((component) => ({
                    ...component,
                    id: shortid.generate(),
                  })),
                },
              ],
              pageId: shortid.generate(),
            })),
          });
        } else {
          setFormInfo({
            ...data,
            customFields: data.customFields.map((component) => ({ ...component, id: shortid.generate() })),
            officersCustomFields: data.officersCustomFields.map((component) => ({
              ...component,
              id: shortid.generate(),
            })),
          });
        }

        setLoadingForm('resolved');
      } catch (error) {
        setLoadingForm('rejected');
        toast.error(error.message);
      }
    };

    if (id && questionsBank.length) {
      onGetFormTypes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, questionsBank.length]);

  /**
   * @description This function is used to handle the change of the tabs
   */
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleToggleOptions = (idQuestion) => {
    const arrCopy = [...questionsBank];
    const newArr = arrCopy.map((res) => {
      if (idQuestion === res._id) {
        return {
          ...res,
          toggleOptions: !res.toggleOptions,
        };
      }
      return res;
    });
    setQuestionsBank(newArr);
  };

  const handleSaveForm = async (dataTop) => {
    const pagesToSend = ignoreExtraProps(pages);
    const pagesNotName = pagesToSend?.pages.some((res) => !res.name);
    const sectionNotName = [];

    const _pages = pagesToSend?.pages?.map((page) => ({
      ...page,
      sections: page.sections.map((section) => ({
        ...section,
        components: section.components.map((component) => {
          if (typeof component.data !== 'string' && component.data?.dataReportLink?._id) {
            return {
              ...component,
              data: {
                ...component.data,
                dataReportLink: component.data.dataReportLink._id,
              },
            };
          }

          if (typeof component.data !== 'string' && component.data) {
            const updatedData = Object.fromEntries(
              Object.entries(component.data).map(([key, value]) => {
                if (value?.dataReportLink?._id) {
                  const dataReportLinkId = value.dataReportLink._id;

                  return [key, { ...value, ...(dataReportLinkId ? { dataReportLink: dataReportLinkId } : {}) }];
                }
                return [key, value];
              })
            );
            return {
              ...component,
              data: {
                ...updatedData,
              },
            };
          }

          return component;
        }),
      })),
    }));

    _pages.forEach((item) => {
      const sectionItem = item.sections.find((res) => !res.name);
      if (sectionItem) {
        sectionNotName.push(sectionItem);
      }
    });
    if (!pagesNotName && sectionNotName.length === 0) {
      try {
        setLoading('pending');
        await onSaveFormType(
          {
            ...dataTop,
            pages: _pages,
          },
          id
        );
        setLoading('resolved');
        toast.success('Form Successfully updated!');
      } catch (error) {
        const errorMessage = error?.response?.data?.error?.details[0]?.message;
        toast.error(errorMessage);
        setLoading('rejected');
      }
    } else {
      if (pagesNotName) {
        toast.error('You cannot save a page without a name');
      }
      if (sectionNotName.length > 0) {
        toast.error('You cannot save a section without a name');
      }
    }
  };

  const quesRequired = questionsBank.some((res) => res.isVisible && res.required);

  return (
    <Box display="flex">
      {loadingForm === 'pending' && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          width="100%"
          height="calc(116.1vh - 64px)"
        >
          <Typography variant="h6" style={{ marginBottom: 10 }}>
            Loading Form
          </Typography>
          <CircularProgress size={20} color="#000" />
        </Box>
      )}
      <ModalPopUp setOpen={setOpen} open={open}>
        <Typography style={{ fontSize: 20, fontWeight: '500' }}>
          Are you sure you want to delete this {refActionModal?.current?.nameAction ?? ''}?
        </Typography>
        <Box display="flex" alignItems="center" justifyContent="flex-end" marginTop="20px">
          <Button variant="outlined" className={classes.buttonOutlined} onClick={() => setOpen(false)}>
            CANCEL
          </Button>
          <Button
            variant="outlined"
            className={classes.buttonOutlined}
            onClick={() => {
              if (refActionModal && refActionModal.current) {
                refActionModal.current.actionPress();
              }
              setOpen(false);
            }}
          >
            DELETE
          </Button>
        </Box>
      </ModalPopUp>
      {pages.length > 0 && (
        <DndProvider backend={HTML5Backend}>
          <Pages
            id={id}
            formInfo={formInfo}
            setFormInfo={setFormInfo}
            refActionModal={refActionModal}
            setOpen={setOpen}
            value={value}
            handleChange={handleChange}
            pages={pages}
            setPages={setPages}
            selectedPage={selectedPage}
            handleInitVisibleQuestion={handleInitVisibleQuestion}
            onSelectPage={setSelectedPage}
            selected={selected}
            isDefaultForm={formInfo?.defaultForm}
            setSelected={setSelected}
          />
          {value === 0 && (
            <>
              <PageDetails
                key={selectedPage}
                refActionModal={refActionModal}
                setOpen={setOpen}
                formInfo={formInfo}
                questionsRequired={quesRequired}
                page={{ ...pages[selectedPage], pageIndex: selectedPage }}
                setPages={setPages}
                pages={pages}
                handleInitVisibleQuestion={handleInitVisibleQuestion}
                handleVisibleQuestion={handleVisibleQuestion}
                setSomeQuesRequired={setSomeQuesRequired}
                handleSaveForm={handleSaveForm}
                loading={loading}
                isDefaultForm={formInfo?.defaultForm}
              />
              <QuestionBank
                questionRequiredActive={isSomeQuesRequired}
                handleToggleOptions={handleToggleOptions}
                questionsBank={questionsBank?.filter((question) => question?.label !== 'Demographics')}
                setQuestionsBank={setQuestionsBank}
                isDefaultForm={formInfo?.defaultForm}
              />
            </>
          )}
          {value === 1 && (
            <CaseUpdate
              selected={selected}
              id={id}
              formInfo={formInfo}
              isDefaultForm={formInfo?.defaultForm}
              setFormInfo={setFormInfo}
            />
          )}
          {value === 2 && (
            <>
              <OfficerUpdate
                selected={selected}
                id={id}
                formInfo={formInfo}
                isDefaultForm={formInfo?.defaultForm}
                setFormInfo={setFormInfo}
              />
            </>
          )}
          {value === 3 && (
            <FormTasks
              id={id}
              formInfo={formInfo}
              setFormInfo={setFormInfo}
              onSaveForm={handleSaveForm}
              isDefaultForm={formInfo?.defaultForm}
            />
          )}
          {value === 4 && (
            <CaseStatus id={id} formInfo={formInfo} isDefaultForm={formInfo?.defaultForm} setFormInfo={setFormInfo} />
          )}
        </DndProvider>
      )}
    </Box>
  );
}
