import { Box, Button, CircularProgress, Divider, makeStyles, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import DatePickerMUI from '../../components/DatePickerMUi';
import SelectBox from '../../components/SelectBox';
import { getActivity } from '../../services/unarmed';
import { OutLineInput } from '../CaseDetails/UpdateCase';
import { onGetDateLabels } from './Activity';

const useStyles = makeStyles(() => ({
  button: {
    background: ' #1F63FF',
    textTransform: 'capitalize',
    color: '#fff',
    '&:hover': {
      background: '#1F63FF',
    },
    '& .MuiButton-label': {
      fontSize: 13,
    },
  },
}));

const ActivityFilter = ({
  onClick,
  code,
  module,
  typeOptions = [],
  from,
  to,
  setFrom,
  setIsFiltering,
  setTo,
  limit,
  before,
  setItems,
  onClearFilters,
  lastId,
  setLastId,
  setHasNextPage,
}) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  const onFilter = async () => {
    try {
      setLoading(true);
      const res = await getActivity({
        module: module.value,
        startDate: from,
        endDate: to,
        formCode: code.value,
        limit,
      });

      if (res.length > 0) {
        setHasNextPage(true);
      } else {
        setHasNextPage(false);
      }
      // if (typeof lastId === 'string' && lastId !== res[res.length - 1]?._id) {
      setLastId(res[res.length - 1]?._id);
      // }
      const dataFormattedByDate = {};
      res.forEach((itm) => {
        onGetDateLabels(itm, dataFormattedByDate);
      });

      setItems(dataFormattedByDate);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      console.log(error);
    }
  };
  return (
    <Box
      boxShadow="0 4px 8px rgba(0,0,0,.1)"
      style={{ background: '#fff' }}
      padding="10px 20px"
      borderRadius="5px"
      marginTop="20px"
      onKeyPress={(e) => {
        const keycode = e.keyCode ? e.keyCode : e.which;
        if (parseInt(keycode) === 13) {
          onFilter();
        }
      }}
    >
      <Box display="flex" flexWrap="wrap">
        <Box minWidth="100px" maxWidth="200px" width="100%" marginRight="20px" marginTop="10px">
          <Typography style={{ display: 'block', fontSize: 13, fontWeight: 500 }}>Modules</Typography>
          <SelectBox
            dataTestId="activity-filter-select-module"
            id="type"
            options={typeOptions}
            value={module.value || null}
            onChange={module.onChange}
            outlined
          />
        </Box>
        <Box minWidth="100px" maxWidth="200px" width="100%" marginRight="20px" marginTop="10px">
          <Typography
            style={{
              display: 'block',
              fontSize: 13,
              fontWeight: 500,
              marginBottom: 5,
            }}
          >
            Tracking Number
          </Typography>
          <OutLineInput
            bordered
            placeholder="Tracking number"
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              'aria-label': 'weight',
              'data-testid': 'activity-filter-input-tracking-number',
            }}
            value={code.value || ''}
            onChange={code.onChange}
            labelWidth={0}
          />
        </Box>
        <Box minWidth="100px" maxWidth="350px" width="100%" marginRight="20px" marginTop="10px">
          <Typography
            style={{
              display: 'block',
              fontSize: 13,
              fontWeight: 500,
              marginBottom: 4,
            }}
          >
            Activity Date
          </Typography>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <DatePickerMUI
              dataTestId="activity-filter-datepicker-from"
              disableFuture
              label="From"
              date={from}
              propWidth="66%"
              compareDate={to}
              setdate={setFrom}
            />
            <span style={{ width: 15 }}></span>
            <DatePickerMUI
              dataTestId="activity-filter-datepicker-to"
              disableFuture
              label="To"
              date={to}
              compareDate={from}
              propWidth="66%"
              setdate={setTo}
              type="end"
            />
          </Box>
        </Box>
      </Box>

      <Divider style={{ display: 'block', width: '100%', margin: '15px 0' }} />
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          <Typography
            style={{
              fontSize: 13,
              fontWeight: '500',
              cursor: 'pointer',
              color: '#1F63FF',
            }}
            data-testid="activity-filter-button-clear-all-filters"
            onClick={onClearFilters}
          >
            Clear All Filters{' '}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Button data-testid="activity-filter-button-cancel" onClick={onClick} style={{ textTransform: 'capitalize' }}>
            Cancel
          </Button>
          <Button data-testid="activity-filter-button-apply" className={classes.button} onClick={onFilter}>
            {loading ? <CircularProgress color="#fff" size="25px" /> : 'Apply'}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ActivityFilter;
