import React, { useEffect, useState } from 'react';
import { Box, Divider, makeStyles, Typography } from '@material-ui/core';
import styled from 'styled-components';
import QuestionBankComponent from './QuestionBankComponent';
import { getQuestionBank } from '../../services/unarmed';

const CntRequired = styled.div`
  display: flex;
  width: 100%;
  border-radius: 4px;
  background-color: #fdefef;
  height: auto;
  min-height: 50px;
  max-height: auto;
  cursor: pointer;
  margin-bottom: 10px;
`;

const CntText = styled.div`
  display: flex;
  width: 98%;
  padding-right: 10px;
  align-items: center;
`;

const TextRequired = styled.p`
  margin: 0px;
  padding: 0px;
  font-size: 14px;
`;

const LineBorder = styled.div`
  width: 2%;
  min-height: 50px;
  max-height: auto;
  background-color: #fc0000;
  opacity: 1;
  border-start-start-radius: 4px;
  border-end-start-radius: 4px;
  margin-right: 5px;
`;

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 340,
    minWidth: 340,
    borderLeft: '1px solid #6D738526',
    height: 'calc(116.1vh - 64px)',
    overflowY: 'auto',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
}));

export default function QuestionBank({
  questionRequiredActive,
  questionsBank,
  handleToggleOptions,
  isDefaultForm,
}) {
  const classes = useStyles();
  const questionsVisible = questionsBank.filter((res) => res.isVisible);
  return (
    <Box className={classes.container}>
      <Box padding="12px 24px">
        <Typography
          style={{
            fontSize: 16,
            fontWeight: '600',
          }}
        >
          Question Bank
        </Typography>
      </Box>
      <Divider />
      <Box padding="12px 24px" className={classes.body}>
        <Typography
          style={{
            fontSize: 14,
            fontWeight: '400',
            color: '#000000AD',
          }}
        >
          Drag a question to form to add
        </Typography>
        <Box marginTop={1}>
          {questionRequiredActive ? (
            <CntRequired>
              <LineBorder />
              <CntText>
                <TextRequired>
                  You haven’t added the required questions yet.
                </TextRequired>
              </CntText>
            </CntRequired>
          ) : null}
          {questionsVisible.map((res) => (
            <QuestionBankComponent
              questionRequiredActive={questionRequiredActive}
              data={res}
              key={res._id}
              isDefaultForm={isDefaultForm}
              handleToggleOptions={() => handleToggleOptions(res._id)}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
}
