import React, { createContext, useState, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

const DataReportLinkContext = createContext();

const DataReportLinkProvider = ({ children }) => {
  const [componentsChanged, setComponentsChanged] = useState({});

  const value = useMemo(
    () => ({
      componentsChanged,
      setComponentsChanged,
    }),
    [componentsChanged]
  );

  return <DataReportLinkContext.Provider value={value}>{children}</DataReportLinkContext.Provider>;
};

DataReportLinkProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useDataReportLinkProvider = () => {
  const { componentsChanged, setComponentsChanged } = useContext(DataReportLinkContext);

  return { componentsChanged, setComponentsChanged };
};

export default DataReportLinkProvider;
