import { FormControl, FormHelperText, makeStyles, MenuItem, Select } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() => ({
  select: {
    border: '1px solid #0000001f',
    background: '#fff',
    padding: '2.5px 10px',
    borderRadius: 4,
    '&.MuiInput-underline:hover:not(.Mui-disabled):before': {
      border: 0,
    },
    '&.MuiInput-underline:before': {
      border: 0,
    },
    '&:focus-visible': {
      outline: '#0000001f',
    },

    '&.MuiInput-underline:after': {
      border: 0,
    },
  },

  placeHolder: {
    color: '#00000040',
    fontSize: 14,
    fontStyle: 'normal',
  },

  item: {
    fontSize: 14,
  },
}));

export default function SelectEngine({ options = [], onChange, value, width = 120 }) {
  const classes = useStyles();
  return (
    <FormControl sx={{ m: 1, minWidth: 120 }} style={{ width }}>
      <Select
        className={classes.select}
        displayEmpty
        renderValue={(selected) => {
          if (!selected) {
            return <em className={classes.placeHolder}>Select an Option</em>;
          }

          return options.find((option) => option.value === selected)?.text;
        }}
        value={value}
        onChange={onChange}
      >
        <MenuItem disabled value="">
          <em className={classes.placeHolder} style={{ color: '#000000b0' }}>
            Select an Option
          </em>
        </MenuItem>
        {options.map((option, index) => (
          <MenuItem value={option.value} key={index}>
            {option.text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
