import React from 'react';
import CUDropdown from './Components/CUDropdown';
import CULongText from './Components/CULongText';
import CUMultipleChoice from './Components/CUMultipleChoices';
import CUShortText from './Components/CUShortText';
import CUSingleChoice from './Components/CUSingleChoice';
import CUYesNo from './Components/CUYesNo';
import CUTitle from './Components/CUTitle';
import CUSubtitle from './Components/CUSubtitle';
import CUDate from './Components/CUDate';

const onAddPropsToComponent = ({ Component, componentEnhanced, imagesPreview }) => {
  componentEnhanced.component = Component;
  componentEnhanced.imagesPreview = imagesPreview;
};

export const cuGetConfig = ({ componentsFromServer = [] }) =>
  componentsFromServer.map((component) => {
    const componentEnhanced = { ...component };
    switch (component.type) {
      case 'title':
        onAddPropsToComponent({ componentEnhanced, Component: CUTitle });
        break;
      case 'subtitle':
        onAddPropsToComponent({ componentEnhanced, Component: CUSubtitle });
        break;
      case 'singleChoice':
        onAddPropsToComponent({ componentEnhanced, Component: CUSingleChoice });
        break;
      case 'multipleChoices':
        onAddPropsToComponent({
          componentEnhanced,
          Component: CUMultipleChoice,
        });
        break;
      case 'dropdown':
        onAddPropsToComponent({ componentEnhanced, Component: CUDropdown });
        break;

      case 'date':
        onAddPropsToComponent({ componentEnhanced, Component: CUDate });
        break;

      case 'shortText':
        onAddPropsToComponent({ componentEnhanced, Component: CUShortText });
        break;
      case 'longText':
        onAddPropsToComponent({ componentEnhanced, Component: CULongText });
        break;

      case 'yesNo':
        onAddPropsToComponent({ componentEnhanced, Component: CUYesNo });
        break;

      default:
        return <div>Unsupported Component</div>;
    }

    return componentEnhanced;
  });
