import { Box, Button, ListItemIcon, MenuItem, SvgIcon } from '@material-ui/core';
import React, { useState } from 'react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import PieChartIcon from '@material-ui/icons/PieChart';
import BarChartIcon from '@material-ui/icons/BarChart';
import GridOnIcon from '@material-ui/icons/GridOn';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import PropTypes from 'prop-types';

import PopperPortal from '../../../components/PopperPortal';

const AreaIcon = () => (
  <SvgIcon htmlColor="#6D7385">
    <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24">
      <rect fill="none" height="24" width="24" />
      <path d="M3,13v7h18v-1.5l-9-7L8,17L3,13z M3,7l4,3l5-7l5,4h4v8.97l-9.4-7.31l-3.98,5.48L3,10.44V7z" />
    </svg>
  </SvgIcon>
);

export const chartsItems = [
  {
    text: 'Line',
    Icon: ShowChartIcon,
  },
  {
    text: 'Area',
    Icon: AreaIcon,
  },
  {
    text: 'Pie',
    Icon: PieChartIcon,
  },
  {
    text: 'Bar',
    Icon: BarChartIcon,
  },
  {
    text: 'Table',
    Icon: GridOnIcon,
  },
  {
    text: 'Number',
    Icon: InfoOutlinedIcon,
  },
];

export default function ContentChartsButton({ selectedChart, setSelected }) {
  const [open, setOpen] = useState(false);

  return (
    <Box position="relative">
      <Button
        variant="contained"
        style={{
          background: '#f9fafb',
          padding: '6px 12px',
          height: '100%',
          cursor: 'pointer',
          boxShadow: 'none',
          color: '#6D7385',
          textTransform: 'capitalize',
          marginRight: 15,
          fontSize: 13,
          borderColor: '#d1d5db',
          borderRadius: '0px',
          border: '1px solid #d1d5db',
        }}
        onClick={() => setOpen(!open)} // Toggle open state
      >
        <selectedChart.Icon fontSize="small" style={{ minWidth: 35 }} />
        {selectedChart.text}
        <ArrowDropDownIcon htmlColor="#6D7385" />
      </Button>

      <PopperPortal open={open} setOpen={setOpen} left={0} top={43} >
      {chartsItems.map((chart, index) => (
        <MenuItem
          key={index}
          style={{ fontSize: 14 }}
          onClick={() => {
            setSelected(chart);
            setOpen(false); // Close the dropdown after selection
          }}
        >
          <ListItemIcon style={{ minWidth: 30 }}>
            <chart.Icon fontSize="small" />
          </ListItemIcon>
          {chart.text}
        </MenuItem>
      ))}
    </PopperPortal>

    </Box>
  );
}

ContentChartsButton.propTypes = {
  selectedChart: PropTypes.object.isRequired, // Make sure it is an object
  setSelected: PropTypes.func.isRequired,
};
