/* eslint-disable no-nested-ternary */
import { Avatar, Box, Button, CircularProgress, Typography, makeStyles } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DeleteIcon from '@material-ui/icons/Delete';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import AudiotrackIcon from '@material-ui/icons/Audiotrack';
import { toast } from 'react-toastify';
import { Editor } from '@tinymce/tinymce-react';
import PropTypes from 'prop-types';

import useOrganization from '../../hooks/useOrganization';
import useInput from '../../hooks/useInput';
import { Context } from '../../Context';
import CircularProgressWithLabel from '../../components/CircularProgressWithLabel';
import { TINYMCE_DEFAULT_CONFIG, TINYMCE_SCRIPT_SRC } from '../../config/config';
import ModalUploadFile from '../../components/ModalUploadFile';
import Tags from '../../components/Tags';
import { BoxContainer } from '../../styles/GlobalStyles';

const useStyles = makeStyles((theme) => ({
  formSection: {
    fontSize: 14,
    color: '#3f51b5',
    fontWeight: '500',
    margin: '24px 0',
  },
  containerTitle: {
    padding: '10px 20px',
    borderBottom: '1px solid #e2e2e2',
  },
  resaltText: {
    color: '#4b7bff',
    fontWeight: 600,
    fontSize: 14,
  },
  label: {
    fontWeight: '500',
    fontSize: 14,
    margin: '10px 0 10px',
  },
  button: {
    background: ' #4b7bff',
    textTransform: 'capitalize',
    color: '#fff',
    '&:hover': {
      background: '#4b7bff',
    },
    '& .MuiButton-label': {
      fontSize: 14,
    },
    marginTop: 10,
    marginBottom: 20,
  },

  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: '#F9FAFF',
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: 20,
  },
  editingContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'end',
    margin: '24px 0px 8px 24px',
  },
  editingMessage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    border: '1px solid #FEB535',
    background: '#FFE4B4',
    width: '106px',
    height: '32px',
    marginRight: '15px',
    fontFamily: 'Roboto',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '26px',
    letterSpacing: '0.46px',
    textTransform: 'uppercase',
  },
}));

const CaseComment = ({
  callBack,
  organizationId,
  containerStyles = {
    background: '#fff',
    borderRadius: 5,
    boxShadow: '0 3px 6px #00000005',
    marginLeft: '20px',
  },
  setIsRequestingData = () => {},
  editingLog,
}) => {
  const classes = useStyles();
  const { authUser } = useContext(Context);
  const comment = useInput('');
  const [loading, setLoading] = useState(false);
  const { organization } = useOrganization();
  const [open, setOpen] = React.useState(false);
  const [loadingPercentage, setLoadingPercentage] = useState(0);
  const [files, setFiles] = useState([]);

  const handleOpen = () => {
    setOpen(true);
  };

  const onDeleteFile = (file) => {
    setFiles(files.filter((_file) => _file?._id !== file?._id));
  };

  const resetValues = () => {
    setFiles([]);
    comment.setValue('');
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!editingLog && !comment.value && files.length === 0) {
      toast.error('Please Add a file(s) or a comment');
      return;
    }

    try {
      const filesData = files.map((file) => ({
        file: file.hashId,
        tags: file?.tags?.map((tag) => tag?._id) || [],
      }));

      const canceled = e?.nativeEvent?.submitter?.name === 'cancelbutton';
      if (canceled) {
        await callBack(null, null, resetValues, canceled);
      } else {
        setLoading(true);
        await callBack(filesData, comment.value, resetValues, canceled);
        setIsRequestingData((prevState) => !prevState);
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.error?.details[0].message ||
          error?.response?.data?.message ||
          'An error occurred while sending note'
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    comment.onChange({ target: { value: editingLog?.comment || '' } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingLog?.comment]);

  return (
    <BoxContainer style={containerStyles}>
      {editingLog && (
        <Box className={classes.editingContent} pt={2}>
          <Typography className={classes.editingMessage}>Editing</Typography>
        </Box>
      )}
      <form onSubmit={onSubmit} noValidate autoComplete="off" className={classes.form}>
        <Box className={classes.containerTitle}>
          <Typography variant="body2" style={{ fontWeight: '500', fontSize: 16, color: '#575A66' }}>
            Add Notes
          </Typography>
        </Box>
        <Box display="flex" width="100%" padding="10px 20px">
          <Avatar style={{ marginRight: 24, background: 'darkblue', fontSize: 14 }}>
            {authUser?.email[0].toUpperCase()}
            {authUser?.email[1].toUpperCase()}
          </Avatar>
          <Box style={{ fontSize: 12 }} width="100%" height="30vh">
            <Editor
              tinymceScriptSrc={TINYMCE_SCRIPT_SRC}
              init={{
                ...TINYMCE_DEFAULT_CONFIG,
                placeholder: '',
                width: '100%',
                height: '100%',
                resize: false,
              }}
              value={comment.value}
              onEditorChange={(newValue) => {
                comment.onChange({ target: { value: newValue } });
              }}
            />
          </Box>
        </Box>
        {!editingLog && (
          <Box display="flex" padding="0 20px" marginLeft="60px">
            {files.map((image, i) => {
              if (image.loading) {
                return (
                  <PreviewImg key={image?._id}>
                    <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                      {loadingPercentage > 0 && loadingPercentage < 100 && (
                        <CircularProgressWithLabel value={loadingPercentage} />
                      )}
                    </Box>
                  </PreviewImg>
                );
              }
              return (
                <div key={image?._id}>
                  <PreviewImg>
                    {image?.mimetype?.includes('video') && (
                      <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                        <VideoLibraryIcon htmlColor="#333" style={{ fontSize: 60 }} />
                      </Box>
                    )}

                    {image?.mimetype?.includes('audio') && (
                      <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                        <AudiotrackIcon htmlColor="#333" style={{ fontSize: 60 }} />
                      </Box>
                    )}

                    {image.url.includes('.pdf') && (
                      <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                        <PictureAsPdfIcon htmlColor="#333" style={{ fontSize: 60 }} />
                      </Box>
                    )}

                    {image?.mimetype?.split('/')[0] === 'image' && (
                      <img
                        src={image.url}
                        alt="404"
                        key={i}
                        style={{
                          width: 100,
                          height: 100,
                          objectFit: 'contain',
                          marginRight: 10,
                        }}
                      />
                    )}

                    {!image?.mimetype?.includes('image') &&
                      !image?.mimetype?.includes('audio') &&
                      !image?.mimetype?.includes('video') && (
                        <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                          <InsertDriveFileIcon htmlColor="#333" style={{ fontSize: 60 }} />
                        </Box>
                      )}
                    <BackDrop>
                      <DeleteIcon
                        data-testid={`case-details-notes-button-delete-attachments-${i}`}
                        fontSize="large"
                        htmlColor="#fff"
                        style={{ alignSelf: 'center', cursor: 'pointer' }}
                        className={classes.deleteIcon}
                        onClick={() => onDeleteFile(i)}
                      />
                    </BackDrop>
                  </PreviewImg>
                  <Tags fileId={image?._id} defaultTags={image?.tags || []} />
                </div>
              );
            })}
          </Box>
        )}
        <Box display="flex" justifyContent="space-between" alignItems="center" padding="0 16px">
          <Box display="flex" alignItems="center">
            {!editingLog && organization?.features?.general?.attachments && (
              <>
                <AttachFileIcon
                  htmlColor="#4b7bff"
                  fontSize="medium"
                  data-testid="case-details-notes-button-attachments-icon"
                  style={{ marginRight: 10, cursor: 'pointer' }}
                  onClick={handleOpen}
                />
                <button
                  data-testid="case-details-notes-button-attachments"
                  type="button"
                  style={{ color: '#4b7bff', cursor: 'pointer', border: 0, background: 'transparent' }}
                  onClick={handleOpen}
                >
                  {' '}
                  Attachments
                </button>
              </>
            )}
          </Box>
          <Box>
            {editingLog && (
              <Button
                data-testid="case-details-notes-button-cancel"
                variant="contained"
                className={classes.button}
                style={{ marginRight: '10px' }}
                type="submbit"
                disabled={loading}
                name="cancelbutton"
              >
                Cancel
              </Button>
            )}
            <Button
              data-testid="case-details-notes-button-submit"
              variant="contained"
              className={classes.button}
              type="submbit"
              disabled={loading || editingLog?.comment === comment.value}
            >
              {loading ? <CircularProgress color="#fff" size="25px" /> : editingLog ? 'Save' : 'Send update'}
            </Button>
          </Box>
        </Box>
      </form>
      <ModalUploadFile
        open={open}
        onConfirm={(_, _files) => {
          setFiles(_files);
          setOpen(false);
        }}
        onClose={() => setOpen(false)}
        organizationId={organizationId}
      />
    </BoxContainer>
  );
};

CaseComment.propTypes = {
  organizationId: PropTypes.string.isRequired,
  callBack: PropTypes.func.isRequired,
  containerStyles: PropTypes.shape({
    background: PropTypes.string,
    borderRadius: PropTypes.number,
    boxShadow: PropTypes.string,
    marginLeft: PropTypes.string,
  }),
  setIsRequestingData: PropTypes.func,
  editingLog: PropTypes.bool,
};

export const BackDrop = styled.div`
  background: rgba(0, 0, 0, 0.4);
  width: 100px;
  height: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  display: flex;
  align-content: center;
  justify-content: center;
  transition: all 0.3s ease;
`;

export const PreviewImg = styled.div`
  width: 100px;
  height: 100px;
  position: relative;

  overflow: hidden;
  margin-right: 16px;
  & div:hover {
    opacity: 1;
  }
`;

export default CaseComment;
