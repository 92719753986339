import { Typography, Button, Box, CircularProgress, makeStyles } from '@material-ui/core';
import { DndProvider } from 'react-dnd';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { toast } from 'react-toastify';

import DnDArea from '../../components/DnDArea';
import DnDItem from '../../components/DnDItem';
import { FormCardLite } from '../../components/FormCard';
import { getFormColor } from '../../utils';
import { updateFormTypesOrder } from '../../services/unarmed';

const useStyles = makeStyles((theme) => ({
  buttonOutlined: {
    letterSpacing: '1px',
    fontSize: 13,
    fontWeight: '500',
    marginRight: 10,
    width: 'fit-content',
    alignSelf: 'flex-end',
    border: 0,
    color: '#4b7bff',
    '&:hover': {
      background: 'transparent',
      border: 0,
    },
    '& .MuiButton-label': {
      fontSize: 13,
    },
    '&:disabled': {
      border: 0,
    },
  },
  paper: {
    backgroundColor: '#fff',
    boxShadow: theme.shadows[5],
    padding: 24,
    maxWidth: 700,
    maxHeight: '90vh',
    outline: 0,
    overflowY: 'scroll',
    borderRadius: 4,
  },
}));

/**
 * SortForms component for arranging forms in a specific order.
 * @param {Object} props - Component props.
 * @param {Array} props.defaultLiveForms - Default live forms to be sorted.
 * @param {Function} props.onClose - Function to handle cancel action.
 * @returns {JSX.Element} SortForms component.
 */
const SortForms = ({ defaultLiveForms, onClose, onSaved }) => {
  const classes = useStyles();

  // State variables
  const [liveForms, setLiveForms] = useState([...defaultLiveForms]);
  const [loading, setLoading] = useState(false);

  /**
   * Handler for saving the sorted forms.
   * @param {Array} liveForms - Sorted live forms.
   * @returns {void}
   */
  const handleOnSaveSortForms = async (_liveForms) => {
    const ids = _liveForms.map((liveForm) => liveForm?._id);

    let response;

    setLoading(true);

    try {
      response = await updateFormTypesOrder(ids);
    } catch (error) {
      toast.error(error?.response?.data?.error?.details?.[0]?.message || error?.response?.data?.message);
    }

    if (response) {
      onSaved();
      onClose();
      toast.success('Forms order updated successfully!');
    }

    setLoading(false);
  };

  /**
   * Renderer function for mapping liveForms to DnDItem components.
   * @param {Function} moveItem - Function to handle item movement.
   * @returns {JSX.Element[]} Array of DnDItem components.
   */
  const renderer = (moveItem) =>
    liveForms.map((form, index) => (
      <DnDItem key={index} moveItem={moveItem} index={index}>
        <FormCardLite
          key={index}
          type="lite"
          colorBg={getFormColor(form.type)}
          typeForm={form.type}
          logEventTrack="make_compliment"
          routeUrl={`/form-dashboard/${form._id}`}
          name={form.name}
          description={form.description}
          pages={form.pages}
          buttonName={form.type}
        />
      </DnDItem>
    ));

  return (
    <div className={classes.paper}>
      <Typography style={{ fontSize: 20, fontWeight: '500' }}>Sort Forms</Typography>
      <Typography style={{ fontSize: 14, marginTop: 16, marginBottom: 16 }}>
        Arrange the forms in the order you would like them to be shown on the Portal
      </Typography>
      <DndProvider backend={HTML5Backend}>
        <DnDArea selectedItems={liveForms} setSelectedItems={setLiveForms} renderer={renderer} />
      </DndProvider>
      <Box display="flex" alignItems="center" justifyContent="flex-end" marginTop="20px">
        <Button
          data-testid="sort-forms-button-cancel"
          variant="outlined"
          className={classes.buttonOutlined}
          style={{ color: '#1F63FF' }}
          onClick={onClose}
        >
          CANCEL
        </Button>
        <Button
          data-testid="sort-forms-button-save"
          variant="outlined"
          className={classes.buttonOutlined}
          onClick={() => handleOnSaveSortForms(liveForms)}
          disabled={loading}
        >
          {loading ? <CircularProgress color="#4b7bff" size="25px" /> : 'Save'}
        </Button>
      </Box>
    </div>
  );
};

// PropTypes validation
SortForms.propTypes = {
  defaultLiveForms: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onSaved: PropTypes.func.isRequired,
};

export default SortForms;
