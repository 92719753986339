import {
  Box,
  CircularProgress,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import styled from 'styled-components';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { toast } from 'react-toastify';

import { onGetFaqs, onUpdateFaqs } from '../../services/unarmed';

import DashButton from '../../components/DashButton';
import DnDArea from '../../components/DnDArea';
import Question from './components/Question';

const useStyles = makeStyles(() => ({
  container: {
    padding: 20,
  },
  title: {
    marginTop: 50,
  },
  questionContainer: {
    width: '100%',
    maxWidth: 760,
    padding: 28,
    minHeight: 195,
    position: 'relative',
    borderRadius: 8,
    boxShadow: '0px 3px 6px #0000000D',
    backgroundColor: '#fff',
    marginTop: 20,
  },
}));

const FAQ = ({ organization }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [loadingfaqs, setLoadingfaqs] = useState(false);

  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    const onGetQuestions = async (orgId) => {
      setLoadingfaqs(true);
      try {
        const { data } = await onGetFaqs(orgId);
        setQuestions(data);
      } catch (error) {
        console.log(error);
      }
      setLoadingfaqs(false);
    };
    if (organization?._id) {
      onGetQuestions(organization?._id);
    }
  }, [organization]);

  const addQuestion = () => {
    setQuestions([...questions, { question: '', answer: '' }]);
  };

  const removeQuestion = (index) => {
    setQuestions(questions.filter((question, i) => i !== index));
  };

  const onSave = async () => {
    setLoading(true);
    try {
      await onUpdateFaqs(questions);
      toast.success('FAQs updated successfully');
    } catch (error) {
      toast.error('Error updating FAQs');
    }
    setLoading(false);
  };

  const renderer = (moveItem) =>
    questions.map((question, index) => (
      <Question
        questionIndex={index}
        moveItem={moveItem}
        key={index}
        question={question}
        setQuestions={setQuestions}
        removeQuestion={removeQuestion}
      />
    ));

  return (
    <>
      <PageWrapper className={classes.container}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          maxWidth="760px"
        >
          <Box>
            {/* <Typography variant="h5">FAQ </Typography> */}
            <Typography style={{ fontSize: 14, color: '#747474' }}>
              Edit FAQ sections that will be displayed on the portal.{' '}
              {/* <a
                href={`https://${organization?.subdomain}.siviltech.com/faq`}
                target="_blank"
                rel="noreferrer"
                style={{ color: '#1F63FF' }}
              >
                FAQ preview
              </a> */}
            </Typography>
          </Box>
          <DashButton onClick={onSave}>
            {loading === 'pending' ? (
              <CircularProgress color="#fff" size="20px" />
            ) : (
              'Save'
            )}{' '}
          </DashButton>
        </Box>
        <Box className={classes.questionContainer}>
          {loadingfaqs ? (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              height={195}
            >
              <Typography style={{ marginBottom: 10 }}>Loading FAQs</Typography>
              <CircularProgress color="#1F63FF" size="20px" />
            </Box>
          ) : (
            <>
              <DndProvider backend={HTML5Backend}>
                <DnDArea
                  selectedItems={questions}
                  setSelectedItems={setQuestions}
                  renderer={renderer}
                />
              </DndProvider>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                marginTop="20px"
              >
                <DashButton onClick={addQuestion}>ADD QUESTION</DashButton>
              </Box>
            </>
          )}
        </Box>
      </PageWrapper>
    </>
  );
};

const PageWrapper = styled.div`
  width: 100%;
`;

export default FAQ;
