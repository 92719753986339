import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import TitleIcon from '@material-ui/icons/Title';
import RadioButtonIcon from '@material-ui/icons/RadioButtonChecked';
import CheckboxIcon from '@material-ui/icons/CheckBox';
import ShortTextIcon from '@material-ui/icons/ShortText';
import LongTextIcon from '@material-ui/icons/Notes';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import { toast } from 'react-toastify';
import shortid from 'shortid';
import { ReactComponent as CalendarIcon } from '../../assets/icons/today_black_24dp.svg';
import { ReactComponent as YesNoIcon } from '../../assets/icons/rule_black_24dp.svg';
import AddComponent from '../../components/AddComponent';
import { renderHeder } from './Standard';
import { getConfig } from '../../formEngine/config/Config';
import { Renderer } from '../../formEngine/config/Renderer';
import { onSaveFormType } from '../../services/unarmed';
import { ContainerPageDetail } from './PageDetails';
import ModalPopUp from '../../components/ModaPopupl';
import Alert from '../../components/Alert';
import { FORM_TYPE_VERSION_2 } from '../../config/config';

/**
 * Rendered Components Array
 * @returns {object[]} Array of rendered components
 */
const renderComponentsArr = () => [
  {
    name: 'Title',
    Icon: TitleIcon,
    data: {
      text: '',
    },
    type: 'title',
  },
  {
    name: 'Subtitle',
    Icon: TitleIcon,
    data: {
      text: '',
    },
    type: 'subtitle',
  },
  {
    name: 'Single Choice',
    divider: true,
    Icon: RadioButtonIcon,
    data: {
      question: '',
      description: '',
      required: false,
    },
    id: shortid.generate(),
    type: 'singleChoice',
  },
  {
    name: 'Multiple Choices',
    Icon: CheckboxIcon,
    data: {
      question: '',
      description: '',
      required: false,
    },
    id: shortid.generate(),
    type: 'multipleChoices',
  },
  {
    name: 'Dropdown',
    Icon: ArrowDropDownIcon,
    data: {
      question: '',
      description: '',
      required: false,
    },
    id: shortid.generate(),
    type: 'dropdown',
  },
  {
    name: 'Yes/No',
    Icon: YesNoIcon,
    data: {
      question: '',
      description: '',
      required: false,
    },
    id: shortid.generate(),
    type: 'yesNo',
  },
  {
    name: 'Date',
    Icon: CalendarIcon,
    data: {
      question: '',
      description: '',
      required: false,
    },
    id: shortid.generate(),
    type: 'date',
  },
  {
    name: 'Short Text',
    divider: true,
    Icon: ShortTextIcon,
    data: {
      question: '',
      description: '',
      required: false,
    },
    id: shortid.generate(),
    type: 'shortText',
  },
  {
    name: 'Long Text',
    Icon: LongTextIcon,
    data: {
      question: '',
      description: '',
      required: false,
    },
    id: shortid.generate(),
    type: 'longText',
  },
];

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    padding: 28,
    minHeight: 195,
    position: 'relative',
    borderRadius: 8,
    boxShadow: '0px 3px 6px #0000000D',
    backgroundColor: '#fff',
    marginTop: 35,
  },

  altText: {
    fontSize: 14,
    color: '#000000AD',
  },
  dndContainer: {
    display: 'flex',
    alignItems: 'center',
    transition: 'ease all .3s',
  },
  buttonOutlined: {
    letterSpacing: '1px',
    fontSize: 13,
    fontWeight: '500',
    marginRight: 10,
    width: 'fit-content',
    alignSelf: 'flex-end',
    border: '1px solid transparent',
    color: '#4b7bff',
    '&:hover': {
      background: 'transparent',
      border: '1px solid transparent',
    },
    '& .MuiButton-label': {
      fontSize: 13,
    },
  },
}));

/**
 * Ignore Extra Props
 * @param {object[]} layout - Layout object
 * @returns {object} CustomOfficer fields object
 */
export const ignoreExtraProps = (_version, layout) => {
  const officersCustomFields =
    layout?.[0]?.components?.map((component) => {
      const componentCopy = { ...component };

      if (_version === FORM_TYPE_VERSION_2) {
        delete componentCopy.id;
        delete componentCopy.uniqueId;
      }

      return {
        data: {
          ...componentCopy.data,
          ...(componentCopy.data.dataReportLink
            ? {
                dataReportLink:
                  typeof componentCopy.data.dataReportLink === 'string'
                    ? componentCopy.data.dataReportLink
                    : componentCopy.data.dataReportLink._id,
              }
            : {}),
          question: componentCopy?.data?.question?.trim(),
          options: componentCopy?.data?.options?.map((option) => option.trim()),
        },
        type: componentCopy.type,
      };
    }) || [];

  return {
    officersCustomFields,
  };
};

export const pagesIgnoreExtraProps = (pages) =>
  pages.map((page) => {
    const pageCopy = { ...page };

    delete pageCopy.pageId;

    return pageCopy;
  });

const fixDataReportLinkID = (component) => ({
  ...component,
  data: {
    ...component?.data,
    ...(component?.data?.dataReportLink
      ? {
          dataReportLink:
            typeof component?.data?.dataReportLink === 'string'
              ? component?.data?.dataReportLink
              : component?.data?.dataReportLink?._id,
        }
      : {}),
  },
});

/**
 * CustomOfficer Form Component
 * @param {object} props - Component props
 * @param {object[]} props.components - Array of components
 * @param {object} props.formInfo - Form information object
 * @param {object} props.selected - Selected component object
 * @param {Function} props.handleSelectComponent - Function to handle component selection
 * @param {Function} props.handleDrop - Function to handle component drop
 * @param {string} props.path - Path string
 * @param {Function} props.handleDeleteComponent - Function to handle component deletion
 * @param {Function} props.onUpdateJson - Function to update JSON
 * @param {string} props.id - Component ID
 * @param {object[]} props.layout - Layout object
 * @param {boolean} props.isDefaultForm - Flag indicating if the form is default
 * @param {Function} props.handleDuplicateComponent - Function to handle component duplication
 * @returns {JSX.Element} CustomOfficer Form component
 */
export default function CustomOfficer({
  components,
  formInfo,
  selected,
  handleSelectComponent,
  handleDrop,
  path,
  handleDeleteComponent,
  onUpdateJson,
  id,
  layout,
  isDefaultForm,
  handleDuplicateComponent,
  layouts,
  showHeaders,
  label,
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const refActionModal = React.useRef(null);
  const [openDelete, setOpenDelete] = useState(false);

  const config = useMemo(
    () =>
      getConfig({
        componentsFromServer: components,
        deleteComponent: handleDeleteComponent,
        onUpdateJson,
        refActionModal,
        setModal: setOpenDelete,
        includeRequired: false,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [components]
  );

  /**
   * Handles saving form
   */
  const onSave = useCallback(async () => {
    let pageExists = false;

    if (formInfo?.version === FORM_TYPE_VERSION_2) {
      pageExists =
        formInfo?.officersCustomFields &&
        !!formInfo?.officersCustomFields?.find((page) => page.pageId === selected.pageId);
    }

    try {
      setLoading(true);
      await onSaveFormType(
        formInfo?.version === FORM_TYPE_VERSION_2
          ? {
              officersCustomFields: pagesIgnoreExtraProps([
                ...formInfo.officersCustomFields.map((page) => {
                  if (page.pageId === selected.pageId) {
                    return {
                      ...page,
                      name: page?.name,
                      sections: [
                        {
                          name: page?.sections?.[0]?.name || 'Section name',
                          components:
                            ignoreExtraProps(formInfo.version, layout)?.officersCustomFields.map(fixDataReportLinkID) ||
                            [],
                        },
                      ],
                    };
                  }

                  // get the layouts edited from the other pages!
                  const pageLayout = layouts[page?.pageId];

                  return {
                    ...page,
                    sections: [
                      {
                        name: page?.sections?.[0]?.name || 'Section name',
                        components: ignoreExtraProps(
                          formInfo.version,
                          pageLayout || page?.sections
                        )?.officersCustomFields.map(fixDataReportLinkID),
                      },
                    ],
                  };
                }),
                ...(!pageExists
                  ? [
                      {
                        name: selected?.name,
                        active: true,
                        sections: [
                          {
                            name: selected?.sections?.[0]?.name || 'Section name',
                            components:
                              ignoreExtraProps(formInfo.version, layout)?.officersCustomFields.map(
                                fixDataReportLinkID
                              ) || [],
                          },
                        ],
                      },
                    ]
                  : []),
              ]),
            }
          : ignoreExtraProps(formInfo?.version, layouts[0]),
        id
      );
      toast.success('CustomOfficer components Added successfully');
      setLoading(false);
    } catch (error) {
      toast.error(
        error.response?.data?.error?.details?.[0]?.message || error.response?.data?.message || 'Something went wrong'
      );
      setLoading(false);
    }
  }, [id, layout, formInfo, selected, layouts]);

  return (
    <ContainerPageDetail
      style={{ width: '100%', height: 'calc(109vh - 64px)', ...(showHeaders && { overflowY: 'auto' }) }}
    >
      <ModalPopUp setOpen={setOpenDelete} open={openDelete}>
        <Typography style={{ fontSize: 20, fontWeight: '500' }}>
          Are you sure you want to delete this {refActionModal?.current?.nameAction ?? ''}?
        </Typography>
        <Box display="flex" alignItems="center" justifyContent="flex-end" marginTop="20px">
          <Button variant="outlined" className={classes.buttonOutlined} onClick={() => setOpenDelete(false)}>
            CANCEL
          </Button>
          <Button
            variant="outlined"
            className={classes.buttonOutlined}
            onClick={() => {
              if (refActionModal && refActionModal.current) {
                refActionModal.current.actionPress();
              }
              setOpenDelete(false);
            }}
          >
            DELETE
          </Button>
        </Box>
      </ModalPopUp>
      {showHeaders ? (
        <Box padding="30px 20px 20px" width="100%">
          {renderHeder({
            title: selected?.name,
            onClick: onSave,
            loading,
            isDefaultForm,
          })}
          {isDefaultForm && (
            <Alert status="info">
              <Typography style={{ fontSize: 14 }}>
                Default forms cannot be deleted or updated. Duplicate this form to edit.
              </Typography>
            </Alert>
          )}
          {formInfo?.version === FORM_TYPE_VERSION_2 && (
            <Alert status="info">
              <Typography
                style={{ fontSize: 14 }}
              >{`Empty sections will not be shown in the ${label} Updates tab`}</Typography>
            </Alert>
          )}
          <Box className={classes.container}>
            {isDefaultForm && (
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  zIndex: 1,
                  background: 'rgba(0,0,0,0.01)',
                }}
              />
            )}
            <Renderer
              config={config}
              handleDrop={handleDrop}
              path={path}
              handleDeleteComponent={handleDeleteComponent}
              duplicateComponent={handleDuplicateComponent}
            />
            <AddComponent
              open={open}
              setOpen={setOpen}
              renderComponentsArr={renderComponentsArr}
              handleSelectComponent={handleSelectComponent}
            />
          </Box>
        </Box>
      ) : (
        <>
          {isDefaultForm && (
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: 1,
                background: 'rgba(0,0,0,0.01)',
              }}
            />
          )}
          <Renderer
            config={config}
            handleDrop={handleDrop}
            path={path}
            handleDeleteComponent={handleDeleteComponent}
            duplicateComponent={handleDuplicateComponent}
          />
          <AddComponent
            open={open}
            setOpen={setOpen}
            renderComponentsArr={renderComponentsArr}
            handleSelectComponent={handleSelectComponent}
          />
        </>
      )}
    </ContainerPageDetail>
  );
}

CustomOfficer.propTypes = {
  components: PropTypes.array.isRequired,
  formInfo: PropTypes.object.isRequired,
  selected: PropTypes.object.isRequired,
  handleSelectComponent: PropTypes.func.isRequired,
  handleDrop: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  handleDeleteComponent: PropTypes.func.isRequired,
  onUpdateJson: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  layout: PropTypes.array.isRequired,
  isDefaultForm: PropTypes.bool.isRequired,
  handleDuplicateComponent: PropTypes.func.isRequired,
  layouts: PropTypes.func.isRequired,
};
