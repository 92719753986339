import { Backdrop, Box, Button, CircularProgress, Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Editor } from '@tinymce/tinymce-react';
import { useHistory } from 'react-router-dom';

import { AllegationOptions, DisciplineOptions, DispositionOptions } from '../pages/CaseDetails/UpdateCase';
import SelectOptions from './SelectOptions';
import { addRowOfficer, deleteAnAllegationRow, unassingOfficer, updateOfficerCustomFields, updateOfficerRow } from '../services/unarmed';
import { TINYMCE_DEFAULT_CONFIG, TINYMCE_SCRIPT_SRC } from '../config/config';
import HTMLContent from './HTMLContent';
import CasePersonInfo from './CasePersonInfo';
import CaseUpdateSection from './CaseUpdateSection';
import MultiSelectBox from './MultiSelectBox';
import UpdateRenderer from '../pages/CaseDetails/UpdateCaseComponents/UpdateRenderer';
import { CustomFieldsAllegations } from './CustomFieldsAllegations';

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: 13,
    color: '#333',
    fontWeight: 500,
    marginBottom: 8,
  },
  button: {
    background: ' #3B70FE',
    color: '#fff',
    '&:hover': {
      background: '#3B70FE',
    },
    '& .MuiButton-label': {
      fontSize: 14,
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export const convertStatusToLowerCase = (status) => {
  switch (status) {
    case 'RECEIVED':
      return 'RECEIVED';
    case 'IN_PROGRESS':
      return 'IN_PROGRESS';
    case 'IN PROGRESS':
      return 'IN PROGRESS';
    case 'CLOSED':
      return 'CLOSED';
    default:
      return status?.toLowerCase();
  }
};

const OfficerAdded = ({ officer, id, setIsRefetch, setIsRequestingData, officersOption, formId, setForm }) => {

  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [editRow, setEditRow] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingDeleteRow, setLoadingDeleteRow] = useState(false);
  const [loadingAddRow, setLoadingAddRow] = useState(false);
  const [loadingDeleteOfficer, setLoadingDeleteOfficer] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [openDeleteOfficer, setOpenDeleteOfficer] = React.useState(false);
  const [deleteState, setDeleteState] = useState({
    id: '',
    index: 0,
  });
  const [officersAllegationOptions, setOfficersAllegationOptions] = useState(officer.officersAllegationOptions || [])
  const [allegation, setAllegation] = useState(officer?.allegationsOfficers || []);
  const [disposition, setDisposition] = useState(officer?.dispositionsOfficers || []);
  const [discipline, setDiscipline] = useState(officer?.disciplineOfficers || []);
  const [reason, setReason] = useState(officer?.reasonOfficers || []);
  const [status, setStatus] = useState(officer?.statusOfficers || []);
  const [loadingState, setLoadingState] = useState(null);

  const history = useHistory();

  useEffect(
    () => () => {
      localStorage.removeItem('allegationRow');
    },
    []
  );

  const handleOpen = (id1, index) => {
    setDeleteState({
      id: id1,
      index,
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (officer?.allegations?.length > 0) {
      console.log({officer})
      setRows(
        officer?.allegations?.map((allegation) => ({
          _id: allegation._id,
          officersCustomFields: allegation?.officersCustomFields || [],
          status: allegation.status || null,
          allegation: allegation.allegation || null,
          disposition: allegation.disposition || null,
          discipline: allegation.discipline || null,
          description: allegation.description || null,
          reason: allegation.reason || null,
        }))
      );
    }
  }, [officer]);

  const onUpdateForm = async (index) => {
    const rowsInSesion = JSON.parse(localStorage.getItem('allegationRow'));
    const row = rowsInSesion[index];
    const { officersCustomFields, ...rowWithoutOfficersCustomFields } = row;
    try {
      setLoading(true);
      setLoadingState('updateForm')
      setIsRequestingData(true);
      await updateOfficerRow(id, {
        ...rowWithoutOfficersCustomFields,
        officerId: officer?.officer?._id,
      ...(officer?.officersAllegationOptions?.allegation?.visible && allegation?.length && { allegationsOfficers: allegation }),
      ...(officer?.officersAllegationOptions?.disposition?.visible && disposition?.length && { dispositionsOfficers: disposition }),
      ...(officer?.officersAllegationOptions?.discipline?.visible && discipline?.length && { disciplineOfficers: discipline }),
      ...(officer?.officersAllegationOptions?.reason?.visible && reason?.length && { reasonOfficers: reason }),
      ...(officer?.officersAllegationOptions?.status?.visible && status?.length && { statusOfficers: status }),
      });
      setLoadingState('');
      setIsRefetch();
      setLoading(false);
      setEditRow(null);
      setIsRequestingData(false);
      toast.success('An Allegation row has been updated Successfully');
    } catch (error) {
      setLoadingState('');
      toast.error(error?.response?.data?.error?.details[0]?.message);
      toast.error(error?.response?.data?.message);
      setLoading(false);
      console.log(error);
    }
  };

  const onChangeItem = (e, rowIndex, prop) => {
    const rowCopy = [...rows];
    rowCopy[rowIndex][prop] = e.target.value;
    localStorage.setItem('allegationRow', JSON.stringify(rowCopy));

    setRows(rowCopy);
  };

  const onDeleteRow = async () => {
    try {
      setLoadingDeleteRow(true);
      setIsRequestingData(true);
      await deleteAnAllegationRow(id, {
        _id: deleteState.id,
        delete: true,
        officerId: officer?.officer?._id,
      });
      const rowsCopy = [...rows];
      rowsCopy.splice(deleteState.index, 1);
      setIsRefetch();
      setRows(rowsCopy);
      setLoadingDeleteRow(false);
      setEditRow(null);
      setIsRequestingData(false);
      setOpen(false);
      toast.success('The Allegation row has been removed Successfully');
    } catch (error) {
      toast.error(error?.response?.data?.error?.details[0]?.message);
      toast.error(error?.response?.data?.message);
      setLoadingDeleteRow(false);
      console.log(error);
    }
  };

  const onAddAllegationRow = async () => {
    try {
      setLoadingAddRow(true);
      setIsRequestingData(true);
      await addRowOfficer(id, officer?.officer?._id);
      setLoadingAddRow(false);
      setIsRefetch();
      setIsRequestingData(false);
      toast.success('A new Allegation row has been added Successfully');
    } catch (error) {
      toast.error(error?.response?.data?.error?.details[0]?.message);
      toast.error(error?.response?.data?.message);
      setLoadingAddRow(false);
      console.log(error);
    }
  };
  const onDeleteOfficer = async () => {
    try {
      setLoadingDeleteOfficer(true);
      setIsRequestingData(true);
      await unassingOfficer(id, officer?.officer?._id);
      setLoadingDeleteOfficer(false);
      setIsRefetch();
      setIsRequestingData(false);
      toast.success('An Officer has been deleted Successfully');
      setOpenDeleteOfficer(false);
    } catch (error) {
      toast.error(error?.response?.data?.error?.details[0]?.message);
      toast.error(error?.response?.data?.message);
      setLoadingDeleteOfficer(false);
      console.log(error);
    }
  };

  const renderSelectOptions = (index, row) => {
    if (index === 0) {
      return {
        label: 'Reason',
        options: [],
        value: row.reason,
      };
    }
    if (index === 1) {
      return {
        label: 'Allegation',
        options: AllegationOptions,
        value: row.allegation,
      };
    }
    if (index === 2) {
      return {
        label: 'Disposition',
        options: DispositionOptions,
        value: row.disposition,
      };
    }
    if (index === 3) {
      return {
        label: 'Discipline',
        options: DisciplineOptions,
        value: row.discipline,
      };
    }
    if (index === 4) {
      return {
        label: 'Status',
        options: ['RECEIVED', 'IN_PROGRESS', 'CLOSED'],
        value: row.status,
      };
    }
  };

  const handleOnClick = () => {
    history.push(`/officer/${officer?.officer?._id}`);
  };

  const handleOnClickOpenInNewWindow = () => {
    window.open(`/officer/${officer?.officer?._id}`, '_blank');
  };

  return (
    <Box>
      <CasePersonInfo
        data={[
          {
            label: 'Badge/Serial Number',
            value: officer?.officer?.badgeNumber || 'Not specified',
            valueRaw: officer?.officer?.badgeNumber,
          },
          {
            label: 'Officer First Name',
            value: officer?.officer?.firstName || 'Not specified',
            valueRaw: officer?.officer?.firstName,
          },
          {
            label: 'Officer Last Name',
            value: officer?.officer?.lastName || 'Not specified',
            valueRaw: officer?.officer?.lastName,
          },
        ]}
        onClickColumn={handleOnClick}
        onClickColumnInNewWindow={handleOnClickOpenInNewWindow}
        onClickDelete={() => setOpenDeleteOfficer(true)}
      />
      {rows.length > 0 &&
        rows.map((row, i) => (
          <Box
            key={i}
            padding="20px 50px 10px 55px"
            {...(i !== rows.length - 1 ? { boxSizing: 'border-box', borderBottom: '1px solid #e2e2e2' } : {})}
          >
            <Box display="flex" alignItems="center" flexWrap="wrap">
              {editRow === i ? (
                Object.keys(row)
                  .slice(0)
                  .map((_, index) =>
                    officersOption[renderSelectOptions(index, row)?.label?.toLowerCase()]?.visible ? (
                      <>
                        <SelectOptions
                          key={index}
                          parseStatus={convertStatusToLowerCase}
                          optionsSelect={officersOption[renderSelectOptions(index, row)?.label?.toLowerCase()]?.values}
                          id={`select-native${i}`}
                          value={renderSelectOptions(index, row)?.value}
                          label={renderSelectOptions(index, row)?.label}
                          onChangeSelect={(e) =>
                            onChangeItem(e, i, renderSelectOptions(index, row)?.label.toLowerCase())
                          }
                        />
                      </>
                    ) : null
                  )
              ) : (
                <Box display="flex" alignItems="flex-start" flexWrap="wrap">
                  {Object.keys(row)
                    .slice(2)
                    .map((_, index) =>
                      officersOption[renderSelectOptions(index, row)?.label?.toLowerCase()]?.visible ? (
                        <Box marginRight="20px" minWidth="120px" maxWidth="120px" key={index}>
                          <Typography variant="body2" className={classes.label} style={{ color: '#585858' }}>
                            {renderSelectOptions(index, row)?.label}
                          </Typography>

                          <Typography
                            varindexant="subtitle"
                            style={{
                              fontWeight: '500',
                              fontSize: 14,
                              textTransform: 'capitalize',
                            }}
                          >
                            {convertStatusToLowerCase(renderSelectOptions(index, row)?.value) || (
                              <Box style={{ height: 20.8 }} />
                            )}
                          </Typography>
                        </Box>
                      ) : null
                    )}
                </Box>
              )}
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                  style: { background: 'rgba(0, 0, 0, 0.3)' },
                }}
              >
                <Fade in={open}>
                  <div className={classes.paper}>
                    <h2 id="transition-modal-title">Are you sure you want to delete this row?</h2>
                    <Box display="flex" justifyContent="flex-end" alignItems="center" padding="20px 0 5px">
                      <Button
                        id={`botonBorrar${i}`}
                        variant="contained"
                        className={classes.button}
                        onClick={() => onDeleteRow()}
                      >
                        {loadingDeleteRow ? <CircularProgress color="#fff" size="25px" /> : 'Delete'}
                      </Button>
                    </Box>
                  </div>
                </Fade>
              </Modal>
            </Box>
            <Box>
              <Typography variant="body2" className={classes.label} style={{ color: '#585858' }}>
                Description
              </Typography>

              {editRow === i ? (
                <Editor
                  tinymceScriptSrc={TINYMCE_SCRIPT_SRC}
                  init={{
                    ...TINYMCE_DEFAULT_CONFIG,
                    height: 200,
                    placeholder: 'Enter a description',
                  }}
                  value={rows[i].description}
                  onEditorChange={(newValue) => onChangeItem({ target: { value: newValue } }, i, 'description')}
                />
              ) : (
                <HTMLContent content={rows[i].description} />
              )}
            </Box>
            {editRow !== i ? (
              <Box display="flex" alignItems="center" marginRight="20px" minHeight="47px">
                <Typography
                  style={{
                    color: '#3B70FE',
                    fontSize: 12,
                    fontWeight: 500,
                    cursor: 'pointer',
                  }}
                  onClick={() => setEditRow(i)}
                >
                  Edit
                </Typography>
                <Typography
                  style={{
                    color: '#3B70FE',
                    fontSize: 12,
                    fontWeight: 500,
                    margin: '0 15px',
                  }}
                >
                  |
                </Typography>
                <Typography
                  style={{
                    color: '#3B70FE',
                    fontSize: 12,
                    fontWeight: 500,
                    cursor: 'pointer',
                  }}
                  // onClick={() => onDeleteRow(i)}
                  onClick={() => handleOpen(row._id, i)}
                >
                  Delete
                </Typography>
              </Box>
            ) : null}
            <Box padding="0 0 10px 20px">
              {editRow !== null && editRow === i && (
                <Box>
                  <Box display="flex" justifyContent="flex-end" mt="30px" alignItems="center">
                    <Button
                      color="secondary"
                      style={{ color: 'red' }}
                      onClick={() => {
                        setRows(
                          officer?.allegations?.map((allegation) => ({
                            _id: allegation._id,
                            status: allegation.status || null,
                            allegation: allegation.allegation || null,
                            disposition: allegation.disposition || null,
                            discipline: allegation.discipline || null,
                            description: allegation.description || null,
                            reason: allegation.reason || null,
                          }))
                        );
                        setEditRow(null);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      className={classes.button}
                      style={{ marginLeft: 20 }}
                      onClick={() => onUpdateForm(i)}
                    >
                      {loading ? <CircularProgress color="#fff" size="25px" /> : 'Save'}
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
            <CustomFieldsAllegations
              officerId={officer?.officer?._id}
              allegation={row}
              id={id}
              setLoading={setLoading}
              setLoadingState={setLoadingState}
              setIsRequestingData={setIsRequestingData}
              setIsRefetch={setIsRefetch}
              setEditRow={setEditRow}
              loading={loading}
              loadingState={loadingState}
              row={row}
              setForm={setForm}
              CircularProgress={CircularProgress}
            />
          </Box>
        ))}
      <Box padding="0px 0 10px 55px">
        {editRow === null && (
          <Box display="flex" alignItems="center" style={{ cursor: 'pointer' }}>
            {loadingAddRow ? (
              <CircularProgress color="#3B70FE" size="25px" />
            ) : (
              <>
                <AddCircleOutlineIcon htmlColor="#3B70FE" style={{ cursor: 'pointer', marginRight: 10 }} />{' '}
                <Typography style={{ color: '#3B70FE', fontSize: 13 }} onClick={onAddAllegationRow}>
                  Add an Allegation
                </Typography>
              </>
            )}
          </Box>
        )}
        <Divider style={{ margin: '15px 0' }} />
      </Box>
      <Modal
        aria-labelledby="transition-modal-title1"
        aria-describedby="transition-modal-description1"
        className={classes.modal}
        open={openDeleteOfficer}
        onClose={() => setOpenDeleteOfficer(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          style: { background: 'rgba(0, 0, 0, 0.3)' },
        }}
      >
        <Fade in={openDeleteOfficer}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title1">Are you sure you want to remove this Officer from the case?</h2>
            <Box display="flex" justifyContent="flex-end" alignItems="center" padding="20px 0 5px">
              <Button variant="contained" className={classes.button} onClick={() => onDeleteOfficer()}>
                {loadingDeleteOfficer ? <CircularProgress color="#fff" size="25px" /> : 'Remove'}
              </Button>
            </Box>
          </div>
        </Fade>
      </Modal>

    </Box>
  );
};

export default OfficerAdded;
