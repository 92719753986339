import React, { useCallback, useEffect, useState } from 'react';
import { Avatar, Box, InputBase, makeStyles, Typography } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import CloseIcon from '@material-ui/icons/Close';
import profileImg from '../assets/profile.png';
import ConfirmDialog from './ConfirmDialog';
import UpgradeModal from './UpgradeModal';
import useOrganization from '../hooks/useOrganization';
import { getUsersSearch } from '../services/unarmed';

const useStyles = makeStyles((theme) => ({
  assignedAvatar: {
    width: 40,
    height: 40,
    border: '1px dashed #585858',
    background: '#F8F8F9',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
  userItem: {
    padding: 10,
    cursor: 'pointer',
  },

  usersContainer: {
    position: 'absolute',
    width: 300,
    marginTop: 20,
    background: '#fff',
    zIndex: 4,
  },

  backdrop: {
    zIndex: 1,
    position: 'absolute',
    height: '116.1vh',
    width: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  required: {
    color: 'red',
    fontSize: 16,
  },
  closeIcon: {
    position: 'absolute',
    top: ' -5px',
    right: ' 4px',
    zIndex: 1,
    cursor: 'pointer',
    transition: 'ease all .3s',
    fontSize: '13px',
    background: '#364F74',
    borderRadius: '50%',
    color: '#fff',
    padding: 3,
    opacity: 0,
  },

  containerAvatar: {
    '&:hover svg': {
      opacity: 1,
    },
  },
}));

const AssignUser = ({
  assignedUsers,
  setAssignedUsers,
  defaultUsers = [],
  onAssignUser,
  onDeleteUser,
  loadingRemoveUser,
  alignX = 200,
  alignKey = 'right',
  addMoreUser = true,
  textAlign = 'right',
  flexWrap = 'nowrap',
  required,
}) => {
  const classes = useStyles();
  const [showUser, setShowUsers] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [usersFiltered, setUsersFiltered] = useState([...defaultUsers]);
  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const { organization } = useOrganization();
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (assignedUsers.length) {
      const filteredUsers = defaultUsers.filter(Boolean).filter((user) => {
        const isAssigned = assignedUsers.filter(Boolean).find((assignedUser) => assignedUser._id === user._id);
        return !isAssigned;
      });

      setUsersFiltered(filteredUsers);
    } else {
      setUsersFiltered(defaultUsers);
    }
  }, [defaultUsers, assignedUsers]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [showUpgradeModal]);

  const onConfirm = async () => {
    await onDeleteUser(selectedUser.user, selectedUser.index);
    setOpen(false);
    setSelectedUser(null);
  };

  const onClose = () => {
    setOpen(false);
    setSelectedUser(null);
  };

  const onOpen = (user, i) => {
    setOpen(true);
    setSelectedUser({ user, index: i });
  };

  const handleShowUsers = (index) => {
    const users = defaultUsers || [];
    const filteredUsers = users.filter((user) => {
      const isAssigned = assignedUsers.filter(Boolean).find((assignedUser) => assignedUser._id === user._id);
      return !isAssigned;
    });
    setUsersFiltered(filteredUsers);

    if (!organization?.features?.dashboard?.cases?.assignedTo) {
      setShowUpgradeModal(true);
    } else {
      setShowUsers(true);
      setCurrentIndex(index);
    }
  };

  const onFilterUsers = useCallback(() => {
    if (search === '') {
      const users = defaultUsers || [];
      const filteredUsers = users.filter((user) => {
        const isAssigned = assignedUsers.filter(Boolean).find((assignedUser) => assignedUser._id === user._id);
        return !isAssigned;
      });
      setUsersFiltered(filteredUsers);
      return;
    }

    if (search?.length >= 3) {
      getUsersSearch(100, 'name', 'asc', search).then((res) => {
        const users = res?.data || [];
        const filteredUsers = users.filter((user) => {
          const isAssigned = assignedUsers.filter(Boolean).find((assignedUser) => assignedUser._id === user._id);
          return !isAssigned;
        });

        setUsersFiltered(filteredUsers);
      });
    }
  }, [search, defaultUsers, assignedUsers]);

  const onSelectUser = (user) => {
    const usersArr = [...assignedUsers];

    usersArr[currentIndex] = user;

    onAssignUser(usersArr, () => {
      if (addMoreUser) {
        setAssignedUsers([...usersArr, {}]);
      } else {
        setAssignedUsers([...usersArr]);
      }
    });
    setShowUsers(false);
  };

  const doneTypingInterval = 750;
  let interval;

  return (
    <Box>
      <UpgradeModal open={showUpgradeModal} callback={() => setShowUpgradeModal(false)} />
      <Typography variant="body2" style={{ fontWeight: 600, marginBottom: 5, textAlign: textAlign }}>
        Assigned To {required && <span className={classes.required}>*</span>}
      </Typography>
      <ConfirmDialog
        open={open}
        onConfirm={onConfirm}
        title="Remove user"
        description="Are you sure you want to remove this user?"
        onClose={onClose}
        loading={loadingRemoveUser}
      />
      <Box display="flex" flexWrap={flexWrap} style={{ rowGap: "4px" }}>
        {assignedUsers.filter(Boolean).map((user, i) => {
          const userKeys = Object.keys(user);
          if (userKeys.length > 0) {
            return (
              <Box
                position="relative"
                className={classes.containerAvatar}
                key={i}
                title={user?.firstName ? `${user?.firstName} ${user?.lastName}` : user?.email || 'Not specified'}
              >
                <Avatar
                  onClick={() => handleShowUsers(0)}
                  style={{
                    marginRight: 10,
                    cursor: 'pointer',
                    height: 40,
                    width: 40,
                  }}
                  src={user?.photo?.url || user?.profile_img || profileImg}
                />
                <CloseIcon htmlColor="#333" className={classes.closeIcon} onClick={() => onOpen(user, i)} />
              </Box>
            );
          }

          return (
            <div
              className={classes.assignedAvatar}
              onClick={() => handleShowUsers(i)}
              style={{ marginRight: 7 }}
              key={i}
            >
              <AddRoundedIcon htmlColor="#585858" fontSize="small" />
            </div>
          );
        })}
      </Box>
      {showUser && (
        <>
          <div className={classes.backdrop} onClick={() => setShowUsers(false)} />
          <Box boxShadow={1} className={classes.usersContainer} style={{ [alignKey]: alignX }}>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon htmlColor="#585858" />
              </div>
              <InputBase
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                onChange={(e) => {
                  if (e.target.value.length <= 70) {
                    setSearch(e.target.value);
                  }
                }}
                onKeyUp={() => {
                  clearTimeout(interval);

                  interval = setTimeout(() => {
                    onFilterUsers();
                  }, doneTypingInterval);
                }}
                onKeyDown={() => {
                  clearTimeout(interval);
                }}
                inputProps={{ 'aria-label': 'search' }}
              />
            </div>
            <Box maxHeight="300px" style={{ overflowY: 'auto' }}>
              {usersFiltered.length > 0 ? (
                <>
                  {usersFiltered.map((user, i) => (
                    <Box
                      display="flex"
                      alignItems="center"
                      className={classes.userItem}
                      key={i}
                      onClick={() => onSelectUser(user, i)}
                    >
                      <Avatar src={user?.photo?.url || user?.profile_img || profileImg} style={{ marginRight: 10 }} />
                      <Box>
                        <Typography variant="body1" style={{ fontSize: 13 }}>
                          {user?.firstName ? `${user?.firstName} ${user?.lastName} ` : `${user?.username || ''}`}
                        </Typography>
                        <Typography variant="body1" style={{ fontSize: 13 }}>
                          {user.email}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </>
              ) : (
                <Box display="flex" justifyContent="center" padding={1}>
                  <Typography>No users found.</Typography>
                </Box>
              )}
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default AssignUser;
