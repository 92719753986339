import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { Box, Divider, makeStyles } from '@material-ui/core';

import AddMore from '../../components/AddMore';
import DnDArea from '../../components/DnDArea';
import SelectBox from '../../components/SelectBox';
import TabPanel from '../../components/TabPanel';
import { onRemoveDnDItem } from '../../formEngine/utils/utls';
import CaseUpdateBar from './CaseUpdateBar';
import Page from './Page';
import OfficerUpdateBar from './OfficerUpdateBar';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 340,
    minWidth: 340,
    borderRight: '1px solid #6D738526',
    height: 'calc(116.1vh - 64px)',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
}));

const SelectType = {
  obj: [
    {
      text: 'Pages',
      value: 0,
    },
    {
      text: 'Case Updates',
      value: 1,
    },
    {
      text: 'Officers',
      value: 2,
    },
    {
      text: 'Tasks',
      value: 3,
    },
    {
      text: 'Case Status',
      value: 4,
    },
  ],
};

/**
 * Component for rendering pages and case updates
 * @param {object} props - Component props
 * @param {string} props.id - Component ID
 * @param {object[]} props.pages - Array of pages
 * @param {Function} props.setPages - Function to set pages
 * @param {number} props.selectedPage - Index of selected page
 * @param {Function} props.onSelectPage - Function to select a page
 * @param {number} props.value - Value for selection
 * @param {Function} props.handleChange - Function to handle change
 * @param {number} props.selected - Selected value
 * @param {Function} props.setSelected - Function to set selected value
 * @param {Function} props.handleInitVisibleQuestion - Function to initialize visible questions
 * @param {object} props.refActionModal - Reference to action modal
 * @param {Function} props.setOpen - Function to set open state
 * @param {boolean} props.isDefaultForm - Indicates if it's a default form
 * @param {object} props.formInfo - Information about the form
 * @returns {JSX.Element} Pages component
 */
const Pages = ({
  id,
  pages = [],
  setPages,
  selectedPage,
  onSelectPage,
  value,
  handleChange,
  selected,
  setSelected,
  handleInitVisibleQuestion,
  refActionModal,
  setOpen,
  isDefaultForm,
  formInfo,
  setFormInfo,
}) => {
  const classes = useStyles();

  const handleResetQuestionBank = (pageIx) => {
    const questionBankExists = [];
    pages[pageIx].sections.forEach((item) => {
      // check components type questionBank
      item.components
        .filter((res) => res.type === 'questionBank')
        .forEach((res) => {
          if (res.idQuestion) {
            questionBankExists.push({ idQuestion: res.idQuestion });
          }
        });
    });

    handleInitVisibleQuestion(questionBankExists, 'reset');
  };

  const renderer = (moveItem) =>
    pages.map((page, index) => (
      <Page
        isDefaultForm={isDefaultForm}
        page={page}
        key={index}
        index={index}
        moveItem={moveItem}
        setPages={setPages}
        selected={selectedPage === index}
        onSelectPage={() => onSelectPage(index)}
        disablePageOptions={pages.length === 1}
        handleDeletePage={() => {
          setOpen(true);
          refActionModal.current = {
            nameAction: 'page',
            actionPress: () => {
              onRemoveDnDItem(pages, setPages, index);
              handleResetQuestionBank(index);
              if (pages.length - 1 === selectedPage) {
                onSelectPage(selectedPage - 1);
              }
            },
          };
        }}
      />
    ));

  const onAddNewPages = () => {
    setPages((prevState) => {
      const newPage = {
        label: 'Page Title',
        name: 'Page Title',
        sections: [{ name: 'Section Name', id: shortid.generate(), components: [] }],
      };
      return [...prevState, newPage];
    });
  };

  const onMoveComponent = useCallback(
    (item, index) => {
      const indexes = item?.path?.split('-')?.map((_index) => parseInt(_index));
      const _page = formInfo?.customFields?.[indexes[0]];
      let component = _page?.sections?.[0].components?.[indexes[1]];
      let indexPage = formInfo?.customFields?.[index];

      if (!component) {
        component = selected?.sections?.[0].components?.[indexes[1]];
      }

      if (component && selected.pageIndex !== index) {
        indexPage = {
          ...indexPage,
          sections: [
            {
              name: 'Section Name',
              components: [...indexPage?.sections?.[0]?.components, component],
            },
          ],
        };

        setSelected({ ...indexPage, pageIndex: index, moveComponents: { item, index } });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formInfo, formInfo?.customFields?.length, selected]
  );

  return (
    <Box className={classes.container}>
      <Box>
        <SelectBox
          allowPlaceHolder={false}
          options={SelectType}
          value={value}
          onChange={(event) => {
            handleChange(event, parseInt(event.target.value));
          }}
        />
      </Box>
      <Divider />
      <Box className={classes.body}>
        <TabPanel value={value} index={0} marginBottom={0}>
          <DnDArea selectedItems={pages} setSelectedItems={setPages} renderer={renderer} />
          {!isDefaultForm && (
            <AddMore
              dataTestId="forms-button-add-new-page"
              text="Add new page"
              marginLeft="5px"
              onClick={onAddNewPages}
            />
          )}
        </TabPanel>
        <TabPanel value={value} index={1} marginBottom={0} padding={0}>
          <CaseUpdateBar
            id={id}
            caseUpdates={formInfo?.caseUpdates}
            version={formInfo?.version}
            selected={selected}
            setSelected={setSelected}
            onMoveComponent={onMoveComponent}
            defaultPages={formInfo?.customFields}
            refActionModal={refActionModal}
            setOpen={setOpen}
            onChangeStandardCheckbox={(checked) => {
              setFormInfo((_formInfo) => {
                const updatedCaseUpdates = Object.fromEntries(
                  Object.entries(_formInfo.caseUpdates).map(([key, value]) => [
                    key,
                    {
                      ...value,
                      visible: checked ? value.visible : false,
                    },
                  ])
                );

                return {
                  ..._formInfo,
                  caseUpdates: {
                    ...updatedCaseUpdates,
                    active: checked,
                  },
                };
              });
            }}
            onChangePages={(sections) => {
              setFormInfo((_formInfo) => ({
                ..._formInfo,
                customFields: sections,
              }));
            }}
          />
        </TabPanel>
        <TabPanel value={value} index={2} marginBottom={0} padding={0}>
          <OfficerUpdateBar
            id={id}
            officerUpdates={formInfo?.officersAllegationOptions}
            version={formInfo?.version}
            selected={selected}
            setSelected={setSelected}
            onMoveComponent={onMoveComponent}
            defaultPages={formInfo?.officersCustomFields}
            refActionModal={refActionModal}
            setOpen={setOpen}
            onChangeStandardCheckbox={(checked) => {
              setFormInfo((_formInfo) => {
                const updatedOfficerAllegationOptions = Object.fromEntries(
                  Object.entries(_formInfo?.officersAllegationOptions).map(([key, value]) => [
                    key,
                    {
                      ...value,
                      visible: checked ? value.visible : false,
                    },
                  ])
                );

                return {
                  ..._formInfo,
                  officersAllegationOptions: {
                    ...updatedOfficerAllegationOptions,
                    active: checked,
                  },
                };
              });
            }}
            onChangePages={(sections) => {
              setFormInfo((_formInfo) => ({
                ..._formInfo,
                officersCustomFields: sections,
              }));
            }}
          />
        </TabPanel>
      </Box>
    </Box>
  );
};

Pages.propTypes = {
  id: PropTypes.string.isRequired,
  pages: PropTypes.arrayOf(PropTypes.object),
  setPages: PropTypes.func.isRequired,
  selectedPage: PropTypes.number.isRequired,
  onSelectPage: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired,
  selected: PropTypes.number.isRequired,
  setSelected: PropTypes.func.isRequired,
  handleInitVisibleQuestion: PropTypes.func.isRequired,
  refActionModal: PropTypes.object.isRequired,
  setOpen: PropTypes.func.isRequired,
  isDefaultForm: PropTypes.bool.isRequired,
  formInfo: PropTypes.shape({
    caseUpdates: PropTypes.object,
    officersAllegationOptions: PropTypes.object,
  }),
  setFormInfo: PropTypes.func.isRequired,
};

export default Pages;
