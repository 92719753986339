import React, { useEffect, useState } from 'react';
import {
  Modal,
  Paper,
  Fade,
  Typography,
  makeStyles,
  Box,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { changeUserRole, getRoles } from '../services/unarmed';
import { axiosCatchError } from '../utils';

const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: theme.shadows[5],
    padding: 30,
    width: 600,
    alignSelf: 'center',
    borderRadius: 4,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  formControl: {
    minWidth: 300,
  },
}));

const ModalUserRole = ({ userId, open, onClose, onRoleChanged }) => {
  const [roles, setRoles] = useState([]);
  const [role, setRole] = useState();
  const [loading, setLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const classes = useStyles();

  const onGetRoles = async () => {
    try {
      const { data } = await getRoles();
      setRoles(data);
    } catch (error) {
      axiosCatchError(error);
    }
  };

  const handleOnChangeRole = () => {
    if (role === 'admin' && !showConfirmation) {
      setShowConfirmation(true);
    } else {
      setLoading(true);
      changeUserRole(userId, role)
        .then(() => {
          toast.success('User role changed successfully!');
          onClose();
          onRoleChanged();
          setLoading(false);
          setShowConfirmation(false);
        })
        .catch((error) => {
          setLoading(false);
          axiosCatchError(error);
        });
    }
  };

  useEffect(() => {
    onGetRoles();
  }, []);

  return (
    <Modal open={open} onClose={onClose} className={classes.modal}>
      <Fade in={open}>
        <Paper className={classes.paper} style={{ backgroundColor: '#fff' }}>
          {showConfirmation ? (
            <>
              <Typography style={{ fontSize: 20, fontWeight: '500' }}>Change role to Admin</Typography>
              <Typography variant="body2" style={{ marginTop: 16 }}>
                If you switch to the role Admin, you will not be able to change this user's role to Investigator or
                Guest. Are you sure you want to proceed?
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="h4">Roles</Typography>
              <Typography>Assign Roles</Typography>
              <Box marginTop="16px" marginBottom="32px">
                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label">Role</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                  >
                    {roles.map((_role) => (
                      <MenuItem value={_role}>{_role}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </>
          )}
          <Box display="flex" justifyContent="flex-end">
            <Button
              variant="text"
              color="primary"
              style={{ marginRight: 16 }}
              onClick={() => {
                if (showConfirmation) {
                  setShowConfirmation(false);
                } else {
                  onClose();
                }
              }}
            >
              CANCEL
            </Button>
            <Button disabled={loading} variant="text" color="primary" onClick={handleOnChangeRole}>
              {showConfirmation ? 'Accept' : 'Save'}
            </Button>
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
};

ModalUserRole.propTypes = {
  open: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onRoleChanged: PropTypes.func.isRequired,
};

export default ModalUserRole;
