export const matchfieldOptions = [
  {
    value: 'civilian addressLine1',
    text: 'Civilian Address Line 1',
    beKey: 'civilian.addressLine1',
    disabled: false,
  },
  {
    value: 'type',
    text: 'Type',
    beKey: 'type',
    disabled: false,
  },
  {
    value: 'civilian addressLine2',
    text: 'Civilian Address Line 2',
    beKey: 'civilian.addressLine2',
    disabled: false,
  },
  {
    value: 'civilian email',
    text: 'Civilian Email',
    beKey: 'civilian.email',
    disabled: false,
  },
  {
    value: 'civilian firstName',
    text: 'Civilian First Name',
    beKey: 'civilian.firstName',
    disabled: false,
  },
  {
    value: 'civilian lastName',
    text: 'Civilian Last Name',
    beKey: 'civilian.lastName',
    disabled: false,
  },
  {
    value: 'civilian phone',
    text: 'Civilian Phone',
    beKey: 'civilian.phone',
    disabled: false,
  },
  {
    value: 'howDidYouHearAboutUs',
    text: 'How Did You Hear About Us?',
    beKey: 'howDidYouHearAboutUs',
    disabled: false,
  },
  {
    value: 'statement',
    text: 'Statement',
    beKey: 'statement',
    disabled: false,
  },
  {
    value: 'whatBestDescribesYou',
    text: 'What Best Describes You?',
    beKey: 'whatBestDescribesYou',
    disabled: false,
  },
  {
    value: 'caseNumber',
    text: 'Case Number',
    beKey: 'caseNumber',
    disabled: false,
  },
  {
    value: 'internalAffairsCaseNumber',
    text: 'Internal Affairs Case Number',
    beKey: 'internalAffairsCaseNumber',
    disabled: false,
  },
  {
    value: 'code',
    text: 'Tracking Number',
    beKey: 'code',
    disabled: false,
  },
  {
    value: 'civilian.city',
    text: 'Civilian City',
    beKey: 'civilian.city',
    disabled: false,
  },
  {
    value: 'civilian.state',
    text: 'Civilian State',
    beKey: 'civilian.state',
    disabled: false,
  },
  {
    value: 'location.address',
    text: 'Location Address',
    beKey: 'location.address',
    disabled: false,
  },
  {
    value: 'location.city',
    text: 'Location City',
    beKey: 'location.city',
    disabled: false,
  },
  {
    value: 'location.state',
    text: 'Location State',
    beKey: 'location.state',
    disabled: false,
  },
  {
    value: 'employee.firstName',
    text: 'Employee First Name',
    beKey: 'employee.firstName',
    disabled: false,
  },
  {
    value: 'employee.lastName',
    text: 'Employee Last Name',
    beKey: 'employee.lastName',
    disabled: false,
  },
  {
    value: 'employee.id',
    text: 'Employee ID',
    beKey: 'employee.id',
    disabled: false,
  },
  {
    value: 'others.firstName',
    text: 'Others First Name',
    beKey: 'others.firstName',
    disabled: false,
  },
  {
    value: 'others.lastName',
    text: 'Others Last Name',
    beKey: 'others.lastName',
    disabled: false,
  },
  {
    value: 'others.id',
    text: 'Others ID',
    beKey: 'others.id',
    disabled: false,
  },
  {
    value: 'status',
    text: 'Status',
    beKey: 'status',
    disabled: false,
  },
  {
    value: 'source',
    text: 'Source',
    beKey: 'source',
    disabled: false,
  },
  {
    value: 'reporter',
    text: 'Reporter',
    beKey: 'reporter',
    disabled: false,
  },
  {
    value: 'allegations',
    text: 'Allegations',
    beKey: 'allegations',
    disabled: false,
  },
  {
    value: 'dispositions',
    text: 'Dispositions',
    beKey: 'dispositions',
    disabled: false,
  },
  {
    value: 'discipline',
    text: 'Discipline',
    beKey: 'discipline',
    disabled: false,
  },
  {
    value: 'investigator',
    text: 'Investigator',
    beKey: 'investigator',
    disabled: false,
  },
  {
    value: 'subtype',
    text: 'Subtype',
    beKey: 'subtype',
    disabled: false,
  },
  {
    value: 'demographic.race',
    text: 'Demographic Race',
    beKey: 'demographic.race',
    disabled: false,
  },
  {
    value: 'demographic.primaryLanguage',
    text: 'Demographic Primary Language',
    beKey: 'demographic.primaryLanguage',
    disabled: false,
  },
  {
    value: 'links',
    text: 'Links',
    beKey: 'links',
    disabled: false,
  },
  {
    value: 'files.url',
    text: 'Files URL',
    beKey: 'files.url',
    disabled: false,
  },
  {
    value: 'whyYouWantToThank',
    text: 'Why You Want to Thank',
    beKey: 'whyYouWantToThank',
    disabled: false,
  },
  {
    value: 'officersInfo.firstName',
    text: 'Officers Info First Name',
    beKey: 'officersInfo.firstName',
    disabled: false,
  },
  {
    value: 'officersInfo.lastName',
    text: 'Officers Info Last Name',
    beKey: 'officersInfo.lastName',
    disabled: false,
  },
  {
    value: 'officersInfo.badgeNumber',
    text: 'Officers Info Badge Number',
    beKey: 'officersInfo.badgeNumber',
    disabled: false,
  },
  {
    value: 'assigned.firstName',
    text: 'Assigned First Name',
    beKey: 'assigned.firstName',
    disabled: false,
  },
  {
    value: 'assigned.lastName',
    text: 'Assigned Last Name',
    beKey: 'assigned.lastName',
    disabled: false,
  },
  {
    value: 'assigned.email',
    text: 'Assigned Email',
    beKey: 'assigned.email',
    disabled: false,
  },
  {
    value: 'civilian.city.id',
    text: 'Civilian City ID',
    beKey: 'civilian.city.id',
    disabled: false,
  },
  {
    value: 'civilian.city.name',
    text: 'Civilian City Name',
    beKey: 'civilian.city.name',
    disabled: false,
  },
  {
    value: 'civilian.state.id',
    text: 'Civilian State ID',
    beKey: 'civilian.state.id',
    disabled: false,
  },
  {
    value: 'civilian.state.name',
    text: 'Civilian State Name',
    beKey: 'civilian.state.name',
    disabled: false,
  },
  {
    value: 'location.city.id',
    text: 'Location City ID',
    beKey: 'location.city.id',
    disabled: false,
  },
  {
    value: 'location.city.name',
    text: 'Location City Name',
    beKey: 'location.city.name',
    disabled: false,
  },
  {
    value: 'location.state.id',
    text: 'Location State ID',
    beKey: 'location.state.id',
    disabled: false,
  },
  {
    value: 'location.state.name',
    text: 'Location State Name',
    beKey: 'location.state.name',
    disabled: false,
  },
];

export const arrayOptions = [
  'gender',
  'raceEthnicity',
  'sexualOrientation',
  'howDidYouHearAboutUs',
  'links',
  'assignedUsers',
  'assignedOfficers',
  'allegations',
  'dispositions',
  'diciplines',
];

export const raceEthnicityOptions = [
  'Black or African American',
  'Hispanic or Latinx',
  'Asian',
  'Native Hawaiian or Pacific Islander',
  'American Indian or Alaskan Native',
  'Middle Eastern or South Asian',
  'Multiracial',
  'White',
  'Not Listed',
  'Prefer not to answer',
];

export const pronounsOptions = [
  'Feminine: She, her, hers',
  'Masculine: He, him, his',
  'Gender Neutral: They, them, their',
  'Gender Neutral: Ze, zir, zirs',
  'Gender Neutral: Ze, hir, hirs',
];

export const genderOptions = [
  'Male',
  'Female',
  'Trans Male',
  'Trans Female',
  'Genderqueer / Gender Non-binary',
  'Not Listed',
  'Prefer not to answer',
];

export const subtypeOptions = ['Police', 'Housing', 'Employment', 'Other'];

export const officerStatus = ['Received', 'In Progress', 'Closed'];
export const disiplinesOptions = [
  'Counseled',
  'Letter of Reprimand',
  'Suspension',
  'Demotion',
  'Dismissal',
  'Resignation',
  'No Further Action',
  'Other',
];
export const dispositionOptions = ['Use of Force', 'Not Sustained', 'Unfounded', 'Improper Conduct', 'Policy Failure'];
export const allegationOptions = [
  'Use of Force',
  'Abuse of Authority',
  'Discourtesy',
  'Offensive Language',
  'Lack of Service',
];

export const ReasonOptions = ['Option 1', 'Option 2', 'Option 3'];
