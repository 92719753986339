import React, { useState,useEffect,useRef } from 'react';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Box, Popper, Typography, makeStyles } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import schemaIcon from '../assets/schema.png';

const useStyles = makeStyles({
  item: {
    height: 45,
    background: '#fff',
    borderRadius: 4,
    border: '1px solid #7070701F',
    padding: '15px 16px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  label: {
    fontSize: 14,
    color: '#00000061',
  },
  selectedItem: {
    color: '#000000DE',
    fontSize: 14,
    fontWeight: '400',
  },
  img: {
    width: 16,
    height: 16,
    marginRight: 5,
  },
  hoverItem: {
    cursor: 'pointer',
    borderRadius: 4,
    '&:hover': {
      transition: 'ease .3s all',
      background: '#f2f2f2',
    },
  },
  inputRoot: {
    flexWrap: 'nowrap',
    overflowY: 'hidden',
    overflowX: 'hidden',
    border: '1px solid rgba(0, 0, 0, 0.23)',
     maxHeight:'43px',
    scrollbarWidth: 'thin',
    '& .MuiOutlinedInput-notchedOutline': {
      border: '0 !important',

    },
  },
  inputRootClicked: {
    flexWrap: 'nowrap',
    overflowY: 'hidden',
    overflowX: 'auto',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    scrollbarWidth: 'thin',
     maxHeight:'43px',
    '&:hover': {
      overflowX: 'unset',
      maxHeight:'43px'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: '0 !important',

    },
    '&::-webkit-scrollbar': {
      height: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#b0b0b0',
      borderRadius: '4px',
    },
  },
});

export default function FixedTags({ defaultTags = [], options = [], showAll, onChange, toggleShowAll, type }) {
  const classes = useStyles();
  const extraTagsCount = defaultTags.length - (showAll ? defaultTags.length : 2);
  const [showAlltag, setShowAlltag] = useState(false);
  const containerRef = useRef(null);
  const handleAddClick = () => {
  };

  const handleMouseEnter = () => {
  };

  const handleMouseLeave = () => {
  };

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setShowAlltag(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClick = () => {
    setShowAlltag(true);
    toggleShowAll();
  };

  const filteredOptions = options.filter(option =>
    !option.category.includes('FormsDynamicCube') && !option.field.includes('FormsDynamicCube')
  );

  function camelToSpaced(str) {
    return str.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/^./, (str) => str.toUpperCase());
  }

  function formatChildrenString(input) {
    const parts = input.split('.');

    if (parts.length < 2 || !parts[1] || !parts[0]) {
      return null;
    }

    if (parts[1].includes("FormsDynamicCube") || parts[0].includes("FormsDynamicCube")) {
      return null
    }

    let prefix = parts[0];
    let field = parts[1];
    if (parts.length === 3) {
      prefix = parts[0];
      field = parts[2];
    }

    if (type === 'Measure') {
      prefix = parts[1];
    }

    if (!prefix || !field || prefix === 'undefined' || field === 'undefined') {
      return null;
    }

    let formattedField = camelToSpaced(field);

    if (formattedField.toLowerCase().includes(prefix.toLowerCase())) {
      formattedField = formattedField.replace(new RegExp(prefix, 'i'), '').trim();
    }

    return `${prefix} - ${formattedField}`;
  }

  return (
    <Box
      sx={{ width: '100%' }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      <Autocomplete
        multiple
        id="fixed-tags-demo"
        value={defaultTags}
        size="small"
        ref={containerRef}
        disableClearable={true}
        popupIcon={null}
        limitTags={showAll || showAlltag ? undefined : 1}
        onChange={(event, newValue) => {
          const validTags = newValue.filter(option => option.category && option.field);
          const updatedTags = validTags.map(tag => ({ ...tag, selected: true }));

          if (onChange) {
            onChange(updatedTags);
          }
        }}
        options={filteredOptions}
        getOptionLabel={(option) => option.label || option.value || option.toString() || option.name}
        groupBy={(option) => option.category}
        classes={{
          inputRoot: showAlltag ? classes.inputRootClicked : classes.inputRoot,
        }}

        renderOption={(option, { selected }) => {
          const formattedString = formatChildrenString(`${option.category}.${option.field}`);
          if (!formattedString) {
            return null;
          }

        return (
          <Box
            key={option.field}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              backgroundColor: 'transparent',
              border: 'none',
              cursor: 'pointer',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              fontSize: 12,
              '&:hover': {
                backgroundColor: '#f2f2f2',
              },
            }}
          >
            <Typography
              style={{
                fontSize: 12,
                paddingBottom: 12,
                wordWrap: 'break-word',
                whiteSpace: 'normal',
                overflow: 'visible',
              }}
            >
              {formattedString}
            </Typography>
          </Box>
        );
      }}
      PopperComponent={(props) => (
        <Popper {...props} style={{ maxHeight: 'none', maxWidth: '300px' }} placement="bottom-start" />
      )}
      renderGroup={(params) => (
        !(params.group.includes('Forms') && !params.group.includes('FormsReferral')) && (
          <div key={params.key}>
            <Box display="flex" alignItems="center" paddingBottom="10px" paddingTop="10px" paddingLeft="10px">
              <img className={classes.img} src={schemaIcon} alt="Schema Icon" />
              <Typography style={{ fontSize: 12 }}>{params.group.replace('-', '')}</Typography>
            </Box>
            <Box style={{ paddingLeft: '17px', paddingRight: '10px', paddingBottom: '10px' }}>
              {params.children}
            </Box>
          </div>
        )
      )}

      renderTags={(tagValue, getTagProps) => {
        const validTags = tagValue.filter(option => formatChildrenString(`${option.category}.${option.field}`) !== null);
        const visibleTags = showAll || showAlltag ? validTags : validTags.slice(0, 2);

        return (
          <>
            {visibleTags.map((option, index) => (
              <Chip
                label={formatChildrenString(`${option.category}.${option.field}`)}
                {...getTagProps({ index })}
              />
            ))}
            {extraTagsCount > 0 && !showAlltag && validTags.length > 0 && (
              <span
                style={{
                  whiteSpace: 'nowrap',
                  fontSize: '16px',
                  marginRight: '4px',
                  color: '#000',
                }}
              >
                {`+${extraTagsCount}`}
              </span>
            )}
          </>
        );
      }}

      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          fullWidth

        />
      )}
      />
    </Box>
  );
}