import { Box, Button, CircularProgress, Divider, makeStyles, Typography } from '@material-ui/core';
import set from 'lodash.set';
import get from 'lodash.get';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { onImport } from '../../../services/unarmed';
import MatchFieldRow from '../MatchFieldRow';

const time = [
  { text: 'Night - 12:00am - 5:59am', value: 1 },
  { text: 'Morning - 6:00am - 11:59am', value: 2 },
  { text: 'Afternoon - 12:00pm - 5:59pm', value: 3 },
  { text: 'Evening - 6:00pm - 11:59pm', value: 4 },
];

const useStyles = makeStyles(() => ({
  button: {
    background: ' #4b7bff',
    textTransform: 'capitalize',
    color: '#fff',
    '&:hover': {
      background: '#4b7bff',
    },
    '& .MuiButton-label': {
      fontSize: 14,
    },
    marginTop: 10,
    marginBottom: 20,
  },
  outlined: {
    background: '#fff',
    color: '#4b7bff',
    border: '1px solid #4b7bff',
    '&:hover': {
      background: 'transparent',
    },
    marginTop: 10,
    marginBottom: 20,
    textTransform: 'capitalize',
  },
}));

export const getKeyImported = (module) => {
  switch (module) {
    case 'Cases':
      return 'forms';
    case 'Officers':
      return 'officers';
    case 'Users':
      return 'users';

    default:
      throw new Error('Unssoported module');
  }
};

const Step2 = ({
  rows,
  columns,
  setStep,
  setCsv,
  prepareForms,
  setCsvErrors,
  setOrderedHeaders,
  setRowSelected,
  rowValues,
  selectOptions,
  setSelectOptions,
  onResetMatchFieldOptions,
  selectedModule,
  onResetRowSelected,
  formTypeId,
}) => {
  const classes = useStyles();

  const [loading, setLoading] = useState('idle');

  const [confirmRows, setConfirmRows] = useState({});
  const [confirmAll, setConfirmAll] = useState(false);

  useEffect(
    () => () => {
      onResetRowSelected({});
    },
    []
  );

  const getRequireOptions = () => {
    const requireOptions = selectOptions.filter((option) => option.required);
    return requireOptions;
  };

  const checkRowValues = (field) => {
    const rowKeys = Object.keys(rows[0]);

    const columnCount = rowKeys.reduce((acum, currentValue) => ({ ...acum, [currentValue]: 0 }), {});

    rows.forEach((row) => {
      rowKeys.forEach((key) => {
        if (row[key]) {
          columnCount[key] += 1;
        }
      });
    });

    return { rowItems: columnCount[field], totalItems: rows.length };
  };

  const onAddRowToJson = (field, selectedOption, type, forms) => {
    const data = [...forms];
    rows.forEach((row, index) => {
      if (row[field]) {
        const getTypeItem = () => {
          if (type === 'array') {
            return row[field].split(',');
          }
          if (type === 'boolean') {
            return row[field] === 'yes';
          }

          //  if (type === 'date') {
          //     return moment(row[field]).format();
          //   }

          return row[field]?.trim();
        };
        switch (selectedOption) {
          case 'location.time': {
            const finded = time.find((tm) => tm.text.toLowerCase().includes(row[field]?.toLowerCase()));
            if (finded) {
              set(data[index], selectedOption, finded.value);
            } else {
              set(data[index], selectedOption, row[field]?.trim());
            }

            break;
          }

          // case 'createdAt': {
          //   const date = moment(row[field]).format();
          //   set(data[index], selectedOption, date);
          //   break;
          // }

          case 'fileAnonymously': {
            set(
              data[index],
              selectedOption,
              row[field].toLowerCase() === 'yes' ? true : row[field].toLowerCase() === 'no' ? false : row[field]?.trim()
            );
            break;
          }

          default:
            set(data[index], selectedOption, getTypeItem());
            break;
        }

        if (!data[index]?.row) {
          data[index] = { ...data[index], row: index + 1 };
        }
      } else {
        set(data[index], selectedOption, undefined);
      }
    });

    return data;
  };

  const onUploadForms = () => {
    if (columns.length !== Object.keys(confirmRows || {}).length) {
      return;
    }
    setLoading('pending');

    const confirmedRows = Object.keys(confirmRows);

    let forms = rows.map(() => ({}));

    confirmedRows.forEach((rowIndex) => {
      const test = onAddRowToJson(
        confirmRows[rowIndex].field,
        confirmRows[rowIndex].selected,
        confirmRows[rowIndex].type,
        forms
      );

      forms = [...test];
    });

    const errors = getRequireOptions().filter((option) => {
      const value = get(forms[0], option.value);
      if (value === undefined || value === null) {
        return option;
      }
    });

    if (errors.length > 0) {
      const error = errors.map((err) => (
        <Typography key={err.beKey} variant="body1">
          * {err.text}
        </Typography>
      ));

      toast.error(
        <Box>
          {' '}
          <Typography variant="body1">
            {errors.length > 1 ? 'These Fields are required' : 'This Field is required'}{' '}
          </Typography>
          {error}
        </Box>
      );

      setLoading('rejected');

      return;
    }

    const orderedHeaders = confirmedRows.reduce(
      (accum, current) => {
        accum[current] = confirmRows[current];
        return accum;
      },

      new Array(confirmedRows.length)
    );

    setOrderedHeaders(orderedHeaders);
    prepareForms(forms);

    const data = {
      validateOnly: true,
      ...(selectedModule === 'Cases' ? { formTypeId } : {}),
      [getKeyImported(selectedModule)]: forms,
    };

    onImport(data, selectedModule)
      .then(() => {
        setCsvErrors(null);
        setStep(2);
        setLoading('resolved');
      })
      .catch((err) => {
        const err422 = err?.response?.data?.error?.details?.[0]?.message;

        if (err422) {
          toast.error(err422);
        } else {
          setCsvErrors(err?.response?.data?.errors);
          setStep(2);
        }
        setLoading('rejected');
      });
  };
  const onCheckIfIsConfirmed = () => {
    if (columns.length !== Object.keys(confirmRows || {}).length) {
      return true;
    }
    return false;
  };

  return (
    <Box width="100%">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        style={{ margin: '43px 0 18px' }}
      >
        <Typography
          style={{
            fontSize: 14,
            fontWeight: '500',
            paddingLeft: 20,
          }}
        >
          Importing {rows.length} rows
        </Typography>
        <Box display="flex" alignItems="center">
          <Button
            variant="outlined"
            style={{ marginRight: 20 }}
            className={classes.outlined}
            onClick={() => {
              setStep(0);
              onResetMatchFieldOptions();
              setCsv(null);
            }}
          >
            Previous Step
          </Button>

          <Button
            variant="contained"
            className={classes.button}
            onClick={onUploadForms}
            disabled={onCheckIfIsConfirmed()}
          >
            {loading === 'pending' ? <CircularProgress color="#fff" size="25px" /> : 'Continue'}
          </Button>
        </Box>
      </Box>

      <Box width="100%" paddingLeft="20px" display="flex" marginBottom="10px">
        <Typography
          style={{
            fontSize: 14,
            fontWeight: 'bold',
            width: 315,
          }}
        >
          CSV Field
        </Typography>
        <Typography
          style={{
            fontSize: 14,
            fontWeight: 'bold',
            width: 253,
          }}
        >
          Cases Field
        </Typography>
        <Typography
          style={{
            fontSize: 14,
            fontWeight: 'bold',
            width: '100%',
            color: 'blue',
            textAlign: 'right',
            cursor: 'pointer',
          }}
          onClick={() => setConfirmAll(true)}
        >
          Confirm All Mapping
        </Typography>
      </Box>
      <Divider />

      <Box width="100%" paddingLeft="20px" marginTop="22px">
        {columns.map((column, index) => (
          <MatchFieldRow
            field={column.name}
            setRowSelected={setRowSelected}
            key={index}
            indexRow={index + 1}
            setConfirmRows={setConfirmRows}
            confirmRows={confirmRows}
            checkRowValues={checkRowValues}
            options={selectOptions}
            setSelectOptions={setSelectOptions}
            onAddRowToJson={onAddRowToJson}
            rowValues={rowValues}
            confirmAll={confirmAll}
            setConfirmAll={setConfirmAll}
          />
        ))}
      </Box>
      <Box marginBottom="75px" width="100%" />
    </Box>
  );
};

export default Step2;
