import React from 'react';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { Box, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  label: {
    fontSize: 16,
    color: '#000000DE',
    fontWeight: '500',
  },
}));

export default function PreviewLabel({ question, required, qNumber }) {
  const classes = useStyles();
  return (
    <Box display="flex" alignItems="center" marginBottom="15px">
      <Typography className={classes.label}>Q{qNumber}</Typography>
      <ArrowRightAltIcon style={{ fontSize: 14, marginRight: 10 }} />
      <Typography className={classes.label}>{question}</Typography>
      {required && <span style={{ color: 'red', fontSize: 12 }}>*</span>}
    </Box>
  );
}
