import { MenuItem, Select, Tooltip } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

/**
 * SelectClearable component allows selecting an option from a dropdown
 * and provides a clear button to reset the selection.
 *
 * @param {Object} props - Component properties.
 * @param {string} props.value - The currently selected value.
 * @param {Function} props.onChange - Callback function to handle value change.
 * @param {Function} props.setValue - Callback function to set the value.
 * @param {string} props.dataTestId - The data-testid attribute for testing.
 * @param {Array} props.options - Array of options to display in the dropdown.
 * @param {string} props.options[].name - The name of the option.
 */
const SelectClearable = ({ value, onChange, setValue, dataTestId, options }) => (
  <Container>
    <Select
      inputProps={{
        'data-testid': dataTestId,
      }}
      displayEmpty
      value={value}
      onChange={onChange}
      fullWidth
    >
      <MenuItem selected disabled value="">
        Select An Option
      </MenuItem>
      {options.map((option, optionIndex) => (
        <MenuItem key={optionIndex} value={option.name}>
          {option.name}
        </MenuItem>
      ))}
    </Select>
    <ContainerIcon onClick={() => setValue('')}>
      <Tooltip title="Clear field">
        <HighlightOffIcon fontSize="small" htmlColor="#757575" />
      </Tooltip>
    </ContainerIcon>
  </Container>
);

SelectClearable.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  dataTestId: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
};

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const ContainerIcon = styled.div`
  position: absolute;
  width: 17px;
  height: 20px;
  right: 32px;
  z-index: 999;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  transition: all 0.25s ease;

  &:hover {
    opacity: 0.7;
  }
`;

export default SelectClearable;
