import React, { useRef } from 'react';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { Box } from '@material-ui/core';
import { useDrag, useDrop } from 'react-dnd';
import PropTypes from 'prop-types';

import { COMPONENT } from '../formEngine/utils/constants';

/**
 * DnDItem - A draggable and droppable item for use in a drag and drop context.
 * @param {Object} props - The props object containing various configuration options.
 * @param {number} props.index - The index of the item.
 * @param {Function} props.moveItem - Function to move the item.
 * @param {ReactNode} props.children - The child components.
 * @param {Function} props.onMoveComponent - Function to handle moving the component.
 * @param {string} props.dragType - The type of drag operation. Defaults to 'ITEM'.
 * @param {boolean} props.noIndicator - Flag indicating whether to show drag and drop indicators.
 * @param {boolean} props.marginBottomItem - Flag indicating whether to apply bottom margin to the item.
 */
export default function DnDItem({
  index,
  moveItem,
  children,
  onMoveComponent,
  dragType = 'ITEM',
  noIndicator = false,
  marginBottomItem = false,
}) {
  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: dragType,
    // drop(item, monitor) {
    drop(item) {
      if (dragType === COMPONENT && item.isCustomComponent) {
        onMoveComponent(item, index);
        return;
      }

      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      // const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // const clientOffset = monitor.getClientOffset();
      // const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      // if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      //   return;
      // }

      // if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      //   return;
      // }

      moveItem(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: dragType,
    item: { type: dragType, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drag(drop(ref));

  const opacity = isDragging ? 0.5 : 1;
  return (
    <Box
      display="flex"
      alignItems="center"
      ref={ref}
      isDraggin={isDragging}
      style={{ opacity }}
      marginBottom={marginBottomItem || '10px'}
      position="relative"
    >
      {!noIndicator ? (
        <DragIndicatorIcon htmlColor="#6D7385" style={{ marginRight: 7, cursor: isDragging ? 'grabbing' : 'grab' }} />
      ) : null}
      {children}
    </Box>
  );
}

DnDItem.propTypes = {
  index: PropTypes.number.isRequired,
  moveItem: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  onMoveComponent: PropTypes.func.isRequired,
  dragType: PropTypes.string,
  noIndicator: PropTypes.bool,
  marginBottomItem: PropTypes.bool,
};
