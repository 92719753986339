// import { isEqual, uniq } from 'lodash';
import isEqual from 'lodash.isequal';
import uniq from 'lodash.uniq';

export const getChangesDiff = (oldData, newData) => {
  const data = uniq([...Object.keys(oldData), ...Object.keys(newData)]);

  for (const key of data) {
    if (!isEqual(oldData[key], newData[key])) {
      return key;
    }
  }

  return null;
};

const getProjectSubTypes = (types, currentType) => {
  const subTypes = types.find((type) => type.type === currentType);
  return subTypes?.subTypes;
};

export function convertHMS(value) {
  if (!value) {
    return '00:00:00';
  }
  const sec = parseInt(value, 10);
  let hours = Math.floor(sec / 3600);
  let minutes = Math.floor((sec - hours * 3600) / 60);
  let seconds = sec - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = `0${hours}`;
  }
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  if (seconds < 10) {
    seconds = `0${seconds}`;
  }
  return `${hours}:${minutes}:${seconds}`;
}

export const formatOverviewFeatureItemToSave = (items) =>
  items.map((item) => {
    const features = {};
    item.overviewFeatures.forEach((feature) => {
      features[feature.key] = {
        visible: feature.visible,
        requiredValue: feature.required,
      };
    });
    features.name = item.actionName;
    return features;
  });

export const formatOverviewFeatureItemToRender = (projectFeatures) =>
  projectFeatures?.map((ft) => {
    const feature = {};
    feature._id = ft?._id;
    feature.actionName = ft.name;
    feature.overviewFeatures = [
      {
        label: 'Times:',
        visible: ft?.times?.visible,
        required: ft?.times?.requiredValue,
        key: 'times',
      },
      {
        label: 'Notes:',
        visible: ft?.notes?.visible,
        required: ft?.notes?.requiredValue,
        key: 'notes',
      },
      {
        label: 'Assigned To:',
        visible: ft?.assignedTo?.visible,
        required: ft?.assignedTo?.requiredValue,
        key: 'assignedTo',
      },
      {
        label: 'Attachments:',
        visible: ft?.attachments?.visible,
        required: ft?.attachments?.requiredValue,
        key: 'attachments',
      },
    ];
    return feature;
  });
export default getProjectSubTypes;
