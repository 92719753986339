import React, { useState } from 'react';
import {
  Box,
  makeStyles,
  Radio,
  TextField,
  Typography,
} from '@material-ui/core';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import TranslateIcon from '@material-ui/icons/Translate';

import WrapperComponent from './WrapperComponent';
import Input from '../../components/Input';
import useInput from '../../hooks/useInput';
import Settings from '../utils/Settings';
import PreviewLabel from '../utils/Label';
import HideAnswer from '../../components/HideAnswer';

const useStyles = makeStyles(() => ({
  title: {
    fontSize: 14,
    color: '#000000DE',
    marginLeft: 10,
  },
  label: {
    fontSize: 16,
    color: '#000000DE',
    fontWeight: '500',
  },
}));

export default function PrimaryLanguage({
  questionBankComponent,
  id,
  onUpdateJson,
  onDeleteComponent,
  path,
  data,
  onChangeDataInput,
  componentIndex,
  duplicateComponent,
  refActionModal,
  setModal,
}) {
  const classes = useStyles();
  const [checkQuestion, setCheckQuestion] = useState([
    data?.description ? true : false,
    data?.requiredMessage ? true : false,
  ]);

  const required = useInput(data?.required ? true : false, false, false, true);
  const descriptionQuestion = useInput(data?.description || '');
  const requiredQuestionMessage = useInput(data?.requiredMessage || '');
  const question = useInput(data?.question || '');
  const renderSettings = () => (
    <Settings
      questions={['description', 'required']}
      checkQuestion={checkQuestion}
      descriptionQuestion={descriptionQuestion}
      requiredQuestion={requiredQuestionMessage}
      setCheckQuestion={setCheckQuestion}
    />
  );

  const handleSaveComponent = () => {
    const dataJson = {
      question: question.value,
      ...(descriptionQuestion.value
        ? { description: descriptionQuestion.value }
        : {}),
      required: required.value,
    };

    onUpdateJson(dataJson);
  };

  const renderPreview = () => (
    <Box>
      <PreviewLabel
        question="Is English your primary language?"
        qNumber={componentIndex + 1}
        required={required.value}
      />
      <Box marginLeft="45px">
        <HideAnswer marginLeft={'-7px'} marginTop={0}>
          <Box
            display="flex"
            alignItems="center"
            marginBottom="10px"
            marginTop="10px"
          >
            <Radio
              disabled
              color="default"
              name="radio-button-demo"
              inputProps={{ 'aria-label': 'D' }}
              style={{ padding: 0, marginRight: 7 }}
            />
            <Typography style={{ color: '#00000061' }}>Yes</Typography>
          </Box>
          <Box display="flex" alignItems="center" marginBottom="10px">
            <Radio
              disabled
              color="default"
              checked
              name="radio-button-demo"
              inputProps={{ 'aria-label': 'D' }}
              style={{ padding: 0, marginRight: 7 }}
            />
            <Typography style={{ color: '#00000061' }}>No</Typography>
          </Box>
          <TextField
            id="standard-basic"
            variant="standard"
            disabled
            placeholder="If not please enter primary language"
            style={{ maxWidth: 325, width: '100%' }}
          />
        </HideAnswer>
      </Box>
    </Box>
  );
  return (
    <WrapperComponent
      renderSettings={renderSettings}
      componentIndex={componentIndex}
      duplicateComponent={duplicateComponent}
      isQuestionBankComponent={questionBankComponent}
      renderPreview={renderPreview}
      required={required}
      onDeleteComponent={onDeleteComponent}
      id={id}
      path={path}
      handleSaveComponent={handleSaveComponent}
      dataSaved={data?.question}
      refActionModal={refActionModal}
      setModal={setModal}
    >
      <Box display="flex" alignItems="center">
        <TranslateIcon htmlColor="#6D7385" />
        <Typography className={classes.title}>Primary Language</Typography>
      </Box>
      <Box display="flex" alignItems="center" marginTop="15px">
        <Typography className={classes.label}>Q{componentIndex + 1}</Typography>
        <ArrowRightAltIcon style={{ fontSize: 14, marginRight: 10 }} />
        <Input
          placeholder="Enter name question"
          value={question.value}
          onChange={question.onChange}
        />
      </Box>
    </WrapperComponent>
  );
}
