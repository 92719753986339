import { makeStyles } from '@material-ui/core';
import React from 'react';
import LoginForm from './LoginForm';

const useStyles = makeStyles(() => ({
  login: {
    display: 'flex',
    width: '100%',
    height: '116.1vh',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#FAFBFF',
  },
  card: {
    width: 500,
    padding: 30,
  },
}));

const Login = () => {
  const classes = useStyles();

  return (
    <div className={classes.login}>
      <LoginForm />
      <div id="recaptcha" />
    </div>
  );
};

export default Login;
