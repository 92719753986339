import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import TitleIcon from '@material-ui/icons/Title';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import Input from '../../components/Input';
import useInput from '../../hooks/useInput';
import HideAnswer from '../../components/HideAnswer';
import WrapperComponent from './WrapperComponent';
import PreviewLabel from '../utils/Label';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    borderRadius: 5,
    border: '1px solid #2E66FE33',
  },
  header: {
    background: '#fff',
    borderRadius: '5px 5px 0 0',
    borderBottom: '1px solid #2E66FE1A',
  },
  body: {
    background: '#F9FAFD80',
    borderRadius: 5,
  },
  button: {
    background: '#1F63FF',
    textTransform: 'capitalize',
    cursor: 'pointer',
    minWidth: 36,
    boxShadow: 'unset',
    color: '#fff',
    padding: '6px 16px',
    '&:hover': {
      background: '#1F63FF',
      boxShadow: 'unset',
    },
  },
  title: {
    fontSize: 14,
    color: '#000000DE',
    marginLeft: 10,
  },
  label: {
    fontSize: 16,
    color: '#000000DE',
    fontWeight: '500',
  },
}));

export default function SubTitle({
  onDeleteComponent,
  duplicateComponent,
  onUpdateJson,
  id,
  path,
  componentIndex,
  data,
  refActionModal,
  setModal,
}) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const inputTitle = useInput(data?.text || '');

  const handleSaveComponent = () => {
    const dataJson = {
      text: inputTitle.value,
    };

    onUpdateJson(dataJson);
  };

  const renderPreview = () => (
    <Box>
      <PreviewLabel question={inputTitle.value} qNumber={componentIndex + 1} />
      <HideAnswer marginLeft={'-7px'} marginTop={0}>
        <Box marginLeft="42px">
          <Box display="flex">
            <TitleIcon htmlColor="#6D7385" />
            <Typography className={classes.labelAnswer}>Sub Title</Typography>
          </Box>
        </Box>
      </HideAnswer>
    </Box>
  );

  return (
    <WrapperComponent
      onBlur={() => {
        setLoading(true);
        setTimeout(() => {
          handleSaveComponent();
          setLoading(false);
        }, 500);
      }}
      renderPreview={renderPreview}
      onDeleteComponent={onDeleteComponent}
      duplicateComponent={duplicateComponent}
      componentIndex={componentIndex}
      id={id}
      path={path}
      loading={loading}
      includeRequired={false}
      dataSaved={data?.text}
      refActionModal={refActionModal}
      setModal={setModal}
    >
      <Box display="flex" alignItems="center">
        <TitleIcon htmlColor="#6D7385" />
        <Typography className={classes.title}>Sub Title</Typography>
      </Box>
      <Box display="flex" alignItems="center" marginTop="15px">
        <Typography className={classes.label}>Q{componentIndex + 1}</Typography>
        <ArrowRightAltIcon style={{ fontSize: 14, marginRight: 10 }} />
        <Input
          placeholder="Enter Sub Title"
          value={inputTitle.value}
          onChange={(e) => {
            inputTitle.onChange(e);
            setLoading(true);
            setTimeout(() => {
              handleSaveComponent();
              setLoading(false);
            }, 500);
          }}
        />
      </Box>
    </WrapperComponent>
  );
}
