import { Box, makeStyles, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import useOrganization from '../../hooks/useOrganization';
import useProjectTypes from '../../hooks/useProjectTypes';
import { onCreateProject } from '../../services/unarmed';
import ModalButtons from './components/ModalButtons';
import OutlineSelect from './components/OutlineSelect';

const useStyles = makeStyles(() => ({
  input: {
    marginBottom: 20,
  },
  description: {
    marginTop: 20,
  },
}));

export default function CreateProject({ handleCancel, handleUpdateProjects }) {
  const classes = useStyles();
  const [loading, setLoading] = useState();
  const { organization } = useOrganization();

  const { register, handleSubmit, control, watch } = useForm();

  const [typesOptions, subTypeOptions] = useProjectTypes(organization?.project?.types, watch('projectType'));

  const isFormEmpty = () => {
    if (!watch('projectType') || !watch('name') || !watch('description') || !watch('number') || !watch('subType')) {
      return true;
    }
    return false;
  };

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const response = await onCreateProject(data);
      toast.success('Project created successfully');
      handleCancel();
      handleUpdateProjects(response.data);
    } catch (error) {
      toast.error(
        error.response?.data?.error?.details?.[0]?.message ||
          error.response?.data?.message ||
          'Something went wrong creating the project'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Typography style={{ fontSize: 20, fontWeight: '500' }}>Create Project</Typography>
      <Typography variant="body2" className={classes.description}>
        Please enter the name and description in the fields below.
      </Typography>
      <Box marginTop={3}>
        <OutlineSelect
          label="Type"
          register={register}
          registerKey="projectType"
          registerOptions={{ required: true }}
          options={organization?.project?.types?.map((types) => types.type)}
        />
        <OutlineSelect
          label="Subtype"
          register={register}
          registerKey="subType"
          options={subTypeOptions}
          registerOptions={{ required: true }}
        />
        <Controller
          name="number"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              className={classes.input}
              label={
                <Typography>
                  {' '}
                  Number <span style={{ color: 'red', fontSize: 12 }}>*</span>
                </Typography>
              }
              inputProps={{ maxLength: 7 }}
              style={{ width: '100%' }}
            />
          )}
        />
        <Controller
          name="name"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              className={classes.input}
              label={
                <Typography>
                  {' '}
                  Name <span style={{ color: 'red', fontSize: 12 }}>*</span>
                </Typography>
              }
              style={{ width: '100%' }}
            />
          )}
        />
        <Controller
          name="description"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              className={classes.input}
              label={
                <Typography>
                  {' '}
                  Description <span style={{ color: 'red', fontSize: 12 }}>*</span>
                </Typography>
              }
              style={{ width: '100%' }}
            />
          )}
        />
      </Box>
      <ModalButtons
        handleCancel={handleCancel}
        handleSubmit={handleSubmit(onSubmit)}
        isValid={isFormEmpty}
        loading={loading}
      />
    </>
  );
}
