import { useState } from 'react';
import { getNotificationCount } from '../services/unarmed';

const useNotifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);

  const getNotificationsCount = async () => {
    try {
      const { data } = await getNotificationCount();
      setNotificationCount(data.count);
    } catch (error) {
      console.log(error);
    }
  };

  const resetNotificationsCount = () => {
    setNotificationCount(0);
  };

  return {
    notifications,
    getNotificationsCount,
    notificationCount,
    resetNotificationsCount,
  };
};

export default useNotifications;
