import { Box, Checkbox, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import styled from 'styled-components';

const useStyles = makeStyles(() => ({
  itemComponent: {
    width: '100%',
    height: 420,
  },
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',

    background: '#fff',
    padding: '20px 30px 20px',
    // boxShadow: '0px 3px 6px #00000012',
    borderRadius: 5,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  headerTitle: {
    minWidth: 170,
    fontWeight: '500',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 20,
  },
  itemContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    margin: '40px auto 10px',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 15,
  },
  names: {
    fontWeight: '500',

    minWidth: 250,
  },
  actionContainer: {
    marginLeft: 80,
  },
  columnText: {
    fontWeight: '500',
  },
}));

export default function OverviewItem({ overviewFeatures, actionName, setTemplate, onChangeActionFeatures }) {
  const classes = useStyles();

  const item = () => (
    <Box display="flex" alignItems="center" width="100%">
      <Box className={classes.container}>
        <Box className={classes.itemComponent}>
          <Box className={classes.header}>
            <Typography className={classes.headerTitle}>
              A <ArrowRightAltIcon style={{ fontSize: 14, marginRight: 10 }} /> Action name:
            </Typography>
            <OutLineInput
              placeholder="Action name"
              bordered
              value={actionName}
              onChange={(e) => {
                const { value } = e.target;
                setTemplate((prev) => ({
                  ...prev,
                  actionName: value,
                }));
              }}
            />
          </Box>
          <Box className={classes.itemContainer}>
            <Box className={classes.item}>
              <Typography className={classes.names} />
              <Typography className={classes.columnText}>Visible</Typography>
              <Typography className={[classes.actionContainer, classes.columnText]}>Required</Typography>
            </Box>
            {overviewFeatures?.map((overviewFeature, actionIndex) => (
              <Box className={classes.item} key={`actionProp-${actionIndex}`}>
                <Typography className={classes.names}>{overviewFeature.label}</Typography>
                <Checkbox
                  color="primary"
                  checked={overviewFeature.visible}
                  onChange={(e) => onChangeActionFeatures(e, overviewFeature.label, 'visible')}
                />
                <Checkbox
                  color="primary"
                  checked={overviewFeature.required}
                  style={{ marginLeft: 95 }}
                  disabled={!overviewFeature?.visible}
                  onChange={(e) => onChangeActionFeatures(e, overviewFeature.label, 'required')}
                />
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );

  return item();
}

const OutLineInput = styled('input')`
  background-color: #f8f8f9;
  height: 35px;
  font-size: 14px !important;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 0;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  outline: 0;
  &::placeholder {
    color: #00000061;
  }
  ${(props) =>
    props.bordered &&
    `
    border: 1px solid rgba(0, 0, 0, 0.23);
    background: transparent;
  `}
`;
