import { Box, Divider, makeStyles, MenuItem, Typography } from '@material-ui/core';
import React, { Fragment } from 'react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import shortid from 'shortid';
import PopperPortal from './PopperPortal';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    padding: 28,
    minHeight: 195,
    borderRadius: 8,
    boxShadow: '0px 3px 6px #0000000D',
    backgroundColor: '#fff',
  },
  ButtonText: {
    background: '#2E66FE 0% 0% no-repeat padding-box',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    padding: '10px 20px',
    color: '#fff',
    fontSize: 14,
    fontWeight: '500',
    width: 152,
    borderRight: '1px solid #fff',
    textAlign: 'center',
    cursor: 'pointer',
  },
  dropdownButton: {
    background: '#2E66FE 0% 0% no-repeat padding-box',
    width: 32,
    padding: '10px 20px',
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 40,
    cursor: 'pointer',
    position: 'relative',
  },
  altText: {
    fontSize: 14,
    color: '#000000AD',
  },
  dndContainer: {
    display: 'flex',
    alignItems: 'center',
    transition: 'ease all .3s',
  },
}));

export default function AddComponent({ open, setOpen, renderComponentsArr, handleSelectComponent }) {
  const classes = useStyles();
  return (
    <Box display="flex" alignItems="center" justifyContent="center" margin="34px 0">
      <Box display="flex" alignItems="center" onClick={() => setOpen(!open)}>
        <Box className={classes.ButtonText}>ADD QUESTION</Box>
        <Box className={classes.dropdownButton}>
          <ArrowDropDownIcon htmlColor="#fff" />
          <PopperPortal open={open} setOpen={setOpen} left="0px" top="45px">
            <Box width={249} borderRadius={4} style={{ background: '#fff' }} boxShadow="0px 3px 6px #0000000D">
              {renderComponentsArr().map((component, idx) => (
                <Fragment key={idx}>
                  {component.divider && <Divider style={{ margin: '8px 0' }} />}
                  <MenuItem
                    key={idx}
                    onClick={() =>
                      handleSelectComponent({
                        name: component.name,
                        type: component.type,
                        id: shortid.generate(),
                        data: component.data,
                      })
                    }
                  >
                    <Box display="flex" alignItems="center">
                      <component.Icon fill="#6D7385" htmlColor="#6D7385" style={{ fontSize: 15 }} />
                      <Typography style={{ marginLeft: 10, fontSize: 14 }}>{component.name}</Typography>
                    </Box>
                  </MenuItem>
                </Fragment>
              ))}
            </Box>
          </PopperPortal>
        </Box>
      </Box>
    </Box>
  );
}
