import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import QuestionComponent from './QuestionComponent';
import AnswerComponent from './AnswerComponent';
import DnDItem from '../../../components/DnDItem';
import { onChangeDnDValue } from '../../../formEngine/utils/utls';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    borderRadius: 5,
    border: '1px solid #2E66FE33',
  },
  header: {
    background: '#fff',
    borderRadius: '5px 5px 0 0',
    borderBottom: '1px solid #2E66FE1A',
    padding: '10px 20px',
  },
  body: {
    background: '#F9FAFD80',
    borderRadius: 5,
    padding: '10px 20px',
  },
  button: {
    background: '#1F63FF',
    textTransform: 'capitalize',
    cursor: 'pointer',
    minWidth: 36,
    boxShadow: 'unset',
    color: '#fff',
    padding: '6px 16px',
    '&:hover': {
      background: '#1F63FF',
      boxShadow: 'unset',
    },
  },
  dndContainer: {
    display: 'flex',
    alignItems: 'center',
    transition: 'ease all .3s',
  },
}));

const Question = ({
  questionIndex,
  moveItem,
  question,
  setQuestions,
  removeQuestion,
  labelQuestion,
  placeholder = 'Question',
  labelAnswer,
  limitQuestion,
  limitAnswer,
  stylesQuestion,
  keyQuestion = 'question',
  keyAnswer = 'answer',
  questionUpperCase = false,
}) => {
  const classes = useStyles();

  return (
    <DnDItem key={questionIndex} moveItem={moveItem} index={questionIndex}>
      <Box style={{ width: '100%' }} display="flex" alignItems="center">
        <Box className={classes.container}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            className={classes.header}
          >
            <Typography>
              {placeholder} {questionIndex + 1}
            </Typography>
            <Box displayP="flex" alignItems="center">
              <DeleteIcon
                htmlColor="#6D7385"
                style={{ marginLeft: 15, cursor: 'pointer' }}
                onClick={() => removeQuestion(questionIndex)}
              />
            </Box>
          </Box>
          <Box className={classes.body}>
            <Box>
              <QuestionComponent
                value={question?.[keyQuestion]}
                onChange={(e) =>
                  onChangeDnDValue(
                    e,
                    setQuestions,
                    questionIndex,
                    keyQuestion,
                    questionUpperCase
                  )
                }
                label={labelQuestion}
                limit={limitQuestion}
                styles={stylesQuestion}
              />
              <AnswerComponent
                value={question?.[keyAnswer]}
                onChange={(e) =>
                  onChangeDnDValue(e, setQuestions, questionIndex, keyAnswer)
                }
                labelAnswer={labelAnswer}
                limit={limitAnswer}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </DnDItem>
  );
};

export default Question;
