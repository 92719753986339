import { Box, CircularProgress, Divider, makeStyles, Typography } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import DatePickerMUI from '../../components/DatePickerMUi';
import DragArea from '../../components/DragArea';
import DropdownSelectableItem from '../../components/DropdownItem';
import ReportDnDItem from '../../components/ReportDnDItem';
import { onAddDnDItem } from '../../formEngine/utils/utls';
import ReportItem from '../../components/ReportItem';
import SelectBox from '../../components/SelectBox';
import ReportFilterItem from '../../components/ReportFilterItem';
import DashButton from '../../components/DashButton';

export const dataRangeOptions = [
  'Custom',
  'All time',
  'Today',
  'Yesterday',
  'This week',
  'This month',
  'This quarter',
  'This year',
  'Last 7 days',
  'Last 30 days',
  'Last week',
  'Last month',
  'Last quarter',
  'Last year',
];

export const dayOption = ['w/o grouping', 'hour', 'day', 'week', 'month', 'year'];

const generalOperators = [
  { label: 'equals', value: 'equals' },
  { label: 'is not set', value: 'notSet' },
  { label: 'is set', value: 'set' },
  { label: 'does not equal', value: 'notEquals' },
];
export const operators = {
  number: [
    ...generalOperators,
    { label: '<', value: 'lt' },
    { label: '<=', value: 'lte' },
    { label: '>=', value: 'gte' },
    { label: '>', value: 'gt' },
  ],
  string: [
    { label: 'does not contain', value: 'notContains' },
    { label: 'contains', value: 'contains' },
    ...generalOperators,
  ],
  boolean: [...generalOperators],
  time: [
    { label: 'does not contain', value: 'notContains' },
    { label: 'contains', value: 'contains' },
    { label: 'In date range', value: 'inDateRange' },
    { label: 'Not in date range', value: 'notInDateRange' },
    { label: 'Before date', value: 'beforeDate' },
    { label: 'After date', value: 'afterDate' },
    ...generalOperators,
  ],
};

export const OutLineInput = styled('input')`
  background-color: #f8f8f9;
  height: 35px;
  font-size: 14px !important;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 0;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  outline: 0;
  &::placeholder {
    color: #00000061;
  }
  ${(props) =>
    props.bordered &&
    `
    border: 1px solid rgba(0, 0, 0, 0.23);
    background: transparent;
  `}
`;

export const searchFilterOperator = (operator, type) => {
  if (!type || !operator) return null;
  const operatorsByType = operators[type];
  const finedOperator = operatorsByType.find((opt) => opt.value === operator);
  return finedOperator;
};

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 370,
    borderRight: '1px solid #6D738526',
    height: 'calc(116.1vh - 64px)',
    overflowY: 'scroll',
  },
  sectionTitle: {
    padding: '16px 18px',
    fontSize: 16,
    fontWeight: '500',
  },
  contentContainer: {
    padding: '16px 18px',
  },
  descriptionText: {
    fontSize: 12,
    color: '#000000AD',
  },
  addMore: {
    color: '#2E66FE',
    fontSize: 14,
    fontWeight: '500',
    marginTop: 24,
    cursor: 'pointer',
    marginLeft: 10,
  },

  documentationLink: {
    color: '#000000AD',
    fontWeight: '500',
    textDecoration: 'underline',
    cursor: 'pointer',
  },

  hoverItem: {
    cursor: 'pointer',
    borderRadius: 4,
    '& :hover': {
      transition: 'ease .3s all',
      background: '#f2f2f2',
    },
  },
}));

export default function ReportPanel({
  dimensions,
  setDimensions,
  measures,
  setMeasures,
  dimensionsTime,
  setDimensionsTime,
  daterange,
  setDaterange,
  granularity,
  setGranularity,
  from,
  setFrom,
  to,
  setTo,
  cubes,
  filters,
  setFilters,
  addFilter,
  onResetFilter,
  buildQuery,
  loading,
}) {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography className={classes.sectionTitle}>Dimensions</Typography>
      <Divider />
      <Box className={classes.contentContainer}>
        <Typography className={classes.descriptionText}>
          Dimensions is used to provide discrete categorical information. These values can be of any type - string, date
          time or numeric.
        </Typography>
        <DndProvider backend={HTML5Backend}>
          <DragArea
            selectedItems={dimensions}
            setSelectedItems={setDimensions}
            ComponentToRender={ReportDnDItem}
            componentProps={{
              cubes,
              type: 'dimensions',
              setItems: setDimensions,
            }}
          />
        </DndProvider>

        <Typography
          data-testid="report-details-button-add-new-dimension"
          className={classes.addMore}
          onClick={() => onAddDnDItem(dimensions, setDimensions, 'dimension')}
        >
          + Add new dimension
        </Typography>
      </Box>
      <Typography className={classes.sectionTitle}>Measures</Typography>
      <Divider />
      <Box className={classes.contentContainer}>
        <Typography className={classes.descriptionText}>
          Measure is used to identify the measure using the specified data member and summary type.
        </Typography>
        <DndProvider backend={HTML5Backend}>
          <DragArea
            selectedItems={measures}
            setSelectedItems={setMeasures}
            ComponentToRender={ReportDnDItem}
            componentProps={{
              cubes,
              type: 'measures',
              setItems: setMeasures,
            }}
          />
        </DndProvider>

        <Typography
          data-testid="report-details-button-add-new-measure"
          className={classes.addMore}
          onClick={() => onAddDnDItem(measures, setMeasures, 'measure')}
        >
          + Add new measure
        </Typography>
      </Box>
      <Typography className={classes.sectionTitle}>Times</Typography>
      <Divider />
      <Box className={classes.contentContainer} display="flex" flexDirection="column">
        <Box>
          <ReportItem
            label={dimensionsTime.label}
            cubes={cubes}
            value={dimensionsTime.value}
            setItems={setDimensionsTime}
          />

          {dimensionsTime.value && (
            <Box display="flex" alignItems="center">
              <Typography style={{ fontSize: 12, color: '#000000AD', marginRight: 12 }}>For</Typography>
              <DropdownSelectableItem
                value={daterange}
                options={dataRangeOptions}
                setItems={setDaterange}
                widthItem="100%"
              />
            </Box>
          )}

          {dimensionsTime.value && daterange === 'Custom' && (
            <Box display="flex" alignItems="center" justifyContent="space-between" marginBottom="10px">
              <DatePickerMUI
                label="From"
                date={from}
                propWidth={100}
                type="start"
                setdate={setFrom}
                compareDate={to}
                setCompareDate={setTo}
                allowEndBeforeStart
              />
              <span style={{ width: 15 }} />
              <DatePickerMUI
                label="To"
                date={to}
                propWidth={100}
                type="end"
                setdate={setTo}
                compareDate={from}
                setCompareDate={setFrom}
                allowEndBeforeStart
              />
            </Box>
          )}

          {dimensionsTime.value && (
            <Box display="flex" alignItems="center">
              <Typography style={{ fontSize: 12, color: '#000000AD', marginRight: 12 }}>By</Typography>

              <DropdownSelectableItem
                value={granularity}
                options={dayOption}
                setItems={setGranularity}
                widthItem="100%"
              />
            </Box>
          )}
        </Box>
      </Box>
      <Typography className={classes.sectionTitle}>Filters</Typography>
      <Divider />
      <Box className={classes.contentContainer} display="flex" flexDirection="column">
        <Box paddingBottom="150px">
          {filters.map((filter, index) => (
            <Box key={index} marginBottom="10px">
              <ReportFilterItem
                label="Filter by"
                cubes={cubes}
                value={filter.member}
                setItems={(value) => {
                  setFilters(index, 'member', value);
                }}
                onResetFilter={() => {
                  onResetFilter(index);
                }}
              />
              {filter?.member && (
                <Box paddingLeft="20px">
                  <ReportFilterItem
                    label="Operator"
                    value={filter.operator}
                    operators={operators[filter?.member?.type]}
                    setItems={(value) => {
                      setFilters(index, 'operator', value);
                      setFilters(index, 'values', null);
                    }}
                    showRemove={false}
                    withSearchInput={false}
                  />

                  {filter?.operator?.label?.includes('date') && (
                    <Box display="flex" alignItems="center" justifyContent="space-between" marginBottom="10px">
                      {filter?.operator?.value === 'afterDate' && (
                        <DatePickerMUI
                          disableFuture
                          label="Date"
                          date={filter.values || null}
                          propWidth="100%"
                          setdate={(date) => setFilters(index, 'values', date)}
                          propPadding={5}
                        />
                      )}

                      {filter?.operator?.value === 'beforeDate' && (
                        <DatePickerMUI
                          disableFuture
                          label="Date"
                          date={filter.values || null}
                          propWidth="100%"
                          setdate={(date) => setFilters(index, 'values', date)}
                          propPadding={5}
                        />
                      )}
                      {filter?.operator?.value === 'inDateRange' && (
                        <>
                          <DatePickerMUI
                            label="From"
                            date={Array.isArray(filter.values) ? filter.values?.[0] : filter.values || null}
                            propWidth={100}
                            setdate={(date) => {
                              const arrayValues = [...(filter.values || [])];
                              arrayValues[0] = date;
                              setFilters(index, 'values', arrayValues);
                            }}
                            compareDate={to}
                            setCompareDate={setTo}
                            allowEndBeforeStart
                          />
                          <span style={{ width: 15 }} />
                          <DatePickerMUI
                            label="To"
                            date={Array.isArray(filter.values) ? filter.values?.[1] : null}
                            propWidth={100}
                            type="end"
                            compareDate={from}
                            setCompareDate={setFrom}
                            setdate={(date) => {
                              const arrayValues = [...(filter.values || [])];
                              arrayValues[1] = date;
                              setFilters(index, 'values', arrayValues);
                            }}
                            allowEndBeforeStart
                          />
                        </>
                      )}

                      {filter?.operator?.value === 'notInDateRange' && (
                        <>
                          <DatePickerMUI
                            label="From"
                            date={filter.values?.[0]}
                            propWidth={100}
                            setdate={(date) => {
                              const arrayValues = [...(filter.values || [])];
                              arrayValues[0] = date;
                              setFilters(index, 'values', arrayValues);
                            }}
                            compareDate={to}
                            setCompareDate={setTo}
                            allowEndBeforeStart
                          />
                          <span style={{ width: 15 }} />
                          <DatePickerMUI
                            label="To"
                            date={filter.values?.[1]}
                            propWidth={100}
                            type="end"
                            compareDate={from}
                            setCompareDate={setFrom}
                            setdate={(date) => {
                              const arrayValues = [...(filter.values || [])];
                              arrayValues[1] = date;
                              setFilters(index, 'values', arrayValues);
                            }}
                            allowEndBeforeStart
                          />
                        </>
                      )}
                    </Box>
                  )}

                  {filter?.operator &&
                    !filter?.operator?.label?.includes('date') &&
                    filter?.member?.type !== 'boolean' && (
                      <OutLineInput
                        // bordered
                        placeholder="Value"
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          'aria-label': 'weight',
                        }}
                        style={{
                          height: 45,
                          background: '#fff',
                          padding: '15px 16px',
                          border: '1px solid #7070701F',
                          fontSize: 16,
                        }}
                        value={filter.values || ''}
                        onChange={(e) => setFilters(index, 'values', e.target.value)}
                        labelWidth={0}
                      />
                    )}
                  {filter?.operator && filter?.member?.type === 'boolean' && (
                    <Box bgcolor="#fff" border="1px solid #7070701F" borderRadius="5px">
                      <SelectBox
                        options={{
                          obj: [
                            { text: 'Select an Option', value: '' },
                            { text: 'Yes', value: 1 },
                            { text: 'No', value: 0 },
                          ],
                        }}
                        outlined="string"
                        value={filter.values || ''}
                        onChange={(e) => setFilters(index, 'values', e.target.value)}
                        allowPlaceHolder={false}
                      />
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          ))}
          <Typography
            data-testid="report-details-button-add-new-filter"
            className={classes.addMore}
            onClick={addFilter}
          >
            + Add new filter
          </Typography>

          <DashButton
            dataTestId="report-details-button-run-report"
            marginTop={20}
            onClick={buildQuery}
            disabled={loading}
          >
            {loading ? <CircularProgress size={20} style={{ color: '#fff' }} /> : 'Run Report'}
          </DashButton>
        </Box>
        <Box display="flex" paddingBottom="70px">
          <HelpIcon htmlColor="#2E66FE" fontSize="large" style={{ marginRight: 8 }} />
          <Typography className={classes.descriptionText}>
            Need help? See our{' '}
            <Link to="/data-docs">
              <span className={classes.documentationLink}>Documentation</span>
            </Link>{' '}
            for more information.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

ReportPanel.propTypes = {
  dimensions: PropTypes.array.isRequired,
  setDimensions: PropTypes.func.isRequired,
  measures: PropTypes.array.isRequired,
  setMeasures: PropTypes.func.isRequired,
  dimensionsTime: PropTypes.array.isRequired,
  setDimensionsTime: PropTypes.func.isRequired,
  daterange: PropTypes.object.isRequired,
  setDaterange: PropTypes.func.isRequired,
  granularity: PropTypes.string.isRequired,
  setGranularity: PropTypes.func.isRequired,
  from: PropTypes.string.isRequired,
  setFrom: PropTypes.func.isRequired,
  to: PropTypes.string.isRequired,
  setTo: PropTypes.func.isRequired,
  cubes: PropTypes.array.isRequired,
  filters: PropTypes.array.isRequired,
  setFilters: PropTypes.func.isRequired,
  addFilter: PropTypes.func.isRequired,
  onResetFilter: PropTypes.func.isRequired,
  buildQuery: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};
