import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import { Box, Checkbox, CircularProgress, Typography, useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    boxShadow: '0 3px 6px #00000005',
  },
  paper: {
    width: '100%',
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  paddingR: {
    paddingRight: '0px !important',
  },
  selectPaper: {
    left: 'auto !important',
    top: '740px !important',
    right: '160px !important',
  },
}));

function EnhancedTableHeadItem({ column, setSortBy, setSort, sort, tab, disableHeaderArrows = false }) {
  const matches = useMediaQuery('(max-width:1440px)');
  const classes = useStyles();
  const sortType = () => {
    switch (column) {
      case 'Type': {
        if (tab === 'projectType') {
          return 'projectType';
        }
        return 'type';
      }

      case 'Reporter': {
        return 'reporter';
      }

      case 'Badge Serial Number': {
        return 'badgeNumber';
      }

      case 'Source': {
        return 'source';
      }

      case 'Dispositions': {
        return 'dispositions';
      }

      case 'Discipline': {
        return 'discipline';
      }

      case 'Allegations': {
        return 'allegations';
      }

      case 'Investigator': {
        return 'investigator';
      }

      case 'Disposition': {
        return 'disposition';
      }

      case 'Internal Affairs Case Number': {
        return 'internalAffairsCaseNumber';
      }

      case 'Case Type': {
        return 'type';
      }
      case 'Tracking Number': {
        return 'code';
      }
      case 'Name': {
        if (tab === 'forms') {
          return 'adminName';
        }
        return 'name';
      }
      case 'Display Name': {
        return 'name';
      }

      case 'Officer Name': {
        if (tab === 'officer') {
          return 'firstName';
        }
        return 'name';
      }

      case 'Allegation': {
        return 'allegation';
      }

      case 'Owner Name': {
        return 'ownerName';
      }

      case 'Description': {
        return 'description';
      }
      case 'Report Name': {
        return 'name';
      }

      case 'Assigned To': {
        return 'assigned';
      }

      case 'Status': {
        if (tab === 'forms') {
          return 'published';
        }
        return 'status';
      }

      case 'First Name': {
        return 'firstName';
      }
      case 'Last Name': {
        return 'lastName';
      }
      case 'Email': {
        return 'email';
      }
      case 'Role': {
        if (tab === 1) {
          return 'type';
        }
        return 'role';
      }

      case 'Phone': {
        if (tab === 1) {
          return 'phone';
        }
        return 'phoneNumber';
      }

      case 'Phone Number': {
        return 'phoneNumber';
      }

      case 'Officer Phone': {
        return 'phoneNumber';
      }

      case 'Badge/Serial Number': {
        return 'badgeNumber';
      }
      case 'Officer Name': {
        return 'firstName';
      }
      case 'Officer Last Name': {
        return 'lastName';
      }

      case 'Total Cases': {
        return 'totalCases';
      }

      case 'Total Compliments': {
        return 'complimentsCount';
      }
      case 'Total Complaints': {
        return 'complaintsCount';
      }
      case 'Form Name': {
        return 'name';
      }

      case 'Created By': {
        return 'createdBy';
      }

      case 'Subtype': {
        return 'subtype';
      }

      case 'Number': {
        return 'number';
      }

      case 'Case Number': {
        return 'caseNumber';
      }

      case 'Case Type': {
        return 'type';
      }

      case 'Flag': {
        return 'flagged';
      }

      default: {
        return 'createdAt';
      }
    }
  };

  const renderHeader = (width = 180) => (
    <TableCell
      align="left"
      padding="normal"
      style={{ width, paddingRight: '0px !important' }}
      className={classes.paddingR}
      onClick={() => {
        if (sort && column !== 'Actions') {
          setSort(sort === 'desc' ? 'asc' : 'desc');
          setSortBy(sortType());
        }
      }}
    >
      {disableHeaderArrows ? (
        <Typography style={{ fontSize: '0.875rem', fontWeight: 500, padding: '10px 0' }}>{column}</Typography>
      ) : (
        <TableSortLabel direction={sort}>{column}</TableSortLabel>
      )}
    </TableCell>
  );

  if (column === 'Tracking Number' && matches) {
    return renderHeader();
  }
  if (column === 'Badge/Serial Number' && matches) {
    return renderHeader();
  }
  if (column === 'Allegation' && matches) {
    return renderHeader(160);
  }
  if (column === 'Name' && matches) {
    return renderHeader(220);
  }
  if (column === 'Created Date' && matches) {
    return renderHeader(180);
  }

  return (
    <TableCell
      align="left"
      padding="normal"
      onClick={() => {
        if (sort && column !== 'Actions') {
          setSort(sort === 'desc' ? 'asc' : 'desc');
          setSortBy(sortType());
        }
      }}
    >
      {disableHeaderArrows ? (
        <Typography style={{ fontSize: '0.875rem', fontWeight: 500, padding: '10px 0' }}>{column}</Typography>
      ) : (
        <TableSortLabel direction={sort}>{column}</TableSortLabel>
      )}
    </TableCell>
  );
}

function EnhancedTableHead({
  rows,
  showCheckBox,
  numSelected,
  onSelectAllClick,
  rowCount,
  checked,
  setSortBy,
  sort,
  setSort,
  tab,
  disableHeaderArrows,
}) {
  return (
    <TableHead>
      <TableRow>
        {showCheckBox && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={checked}
              onChange={onSelectAllClick}
              color="primary"
              inputProps={{ 'aria-label': 'select all desserts' }}
            />
          </TableCell>
        )}
        {rows.map((column, i) => (
          <EnhancedTableHeadItem
            column={column}
            key={i}
            tab={tab}
            sort={sort}
            setSort={setSort}
            setSortBy={setSortBy}
            disableHeaderArrows={disableHeaderArrows}
          />
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function EnhancedTable({
  rows = [],
  containerStyles,
  sort,
  setSort,
  setSortBy,
  tab,
  columns,
  rowRender,
  loading,
  count,
  page,
  setPage,
  rowsPerPage,
  handleChangeRowsPerPage,
  showCheckBox,
  selected = [],
  setSelected,
  checked,
  setChecked,
  mt = 20,
  paperMarginBottom = '16px',
  pagination = true,
  disableHeaderArrows,
  path,
  rowsPerPageOptions = [10, 15, 20],
}) {
  const classes = useStyles();

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    setChecked && setChecked(false);
  };

  const handleSelectAllClick = (event) => {
    setChecked && setChecked(!checked);

    if (event.target.checked) {
      const newSelecteds = rows.map((n) => (path ? n?.[path]?._id : n._id));

      setSelected([...selected, ...newSelecteds]);
    }

    if (!event.target.checked && page !== 1) {
      const toRemove = selected.slice((page - 1) * rowsPerPage, rowsPerPage * page);
      const items = selected.filter((item) => !toRemove.includes(item));

      setSelected(items);
    }

    if (page === 1 && !event.target.checked) {
      setSelected([]);
    }
  };

  const handleClick = !setSelected
    ? () => true
    : (event, code) => {
        const selectedIndex = selected.indexOf(code);
        let newSelected = [];

        if (selectedIndex === -1) {
          newSelected = newSelected.concat(selected, code);
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
          newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        setSelected(newSelected);
        return event.target.type !== 'checkbox';
      };

  return (
    <div className={classes.root} style={{ marginTop: mt, ...containerStyles }}>
      <Paper className={classes.paper} style={{ marginBottom: paperMarginBottom }}>
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
        {loading ? (
          <Box width="100%" height="200px" display="flex" alignItems="center" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : (
          <TableContainer style={{ overflowX: 'auto' }}>
            <Table className={classes.table} aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
              <EnhancedTableHead
                sort={sort}
                setSort={setSort}
                classes={classes}
                rowCount={rowsPerPage}
                tab={tab}
                rows={columns}
                setSortBy={setSortBy}
                numSelected={selected.length}
                onSelectAllClick={handleSelectAllClick}
                showCheckBox={showCheckBox}
                checked={checked}
                disableHeaderArrows={disableHeaderArrows}
              />

              <TableBody>
                {rows.map((row, index) => rowRender(row, index, handleClick))}
                {/* .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) */}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {pagination && (
          <TablePagination
            SelectProps={{
              inputProps: {
                className: 'CustomSelectTablePagination',
              },
              native: true,
            }}
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page - 1}
            onPageChange={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
      </Paper>
    </div>
  );
}
