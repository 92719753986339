import React from 'react';
import { Box, makeStyles, Typography, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import upArrow from '../assets/upArrow.png';

const useStyles = makeStyles(() => ({
  button: {
    background: ' #2E66FE',
    textTransform: 'capitalize',
    color: '#fff',
    padding: '10px 14px',
    borderRadius: '4px',
    '&:hover': {
      background: '#2E66FE',
    },
    '& .MuiButton-label': {
      fontSize: 14,
    },
    marginTop: 10,
    marginBottom: 20,
    fontSize: 14,
    fontWeight: '500',
    cursor: 'pointer',
  },
  labelError: {
    fontSize: 12,
    color: 'red',
  },

  circle: {
    width: 76,
    height: 76,
    borderRadius: '50%',
    border: '3px solid #FEB535',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 25,
    flexDirection: 'column',
  },
}));

/**
 * Component to display an upgrade popup.
 * @param {Object} props - Component props.
 * @param {string} [props.title='Upgrade Required!'] - Title of the upgrade popup.
 * @param {string} [props.text] - Additional text to display in the popup.
 * @param {string} [props.buttonText] - Additional button text to display in the popup.
 * @param {string} [props.withText] - Boolean value to display text.
 * @returns {JSX.Element} UpgradeTabPopup component.
 */
const UpgradeTabPopup = ({
  title = 'Upgrade Required!',
  text,
  buttonText = 'UPGRADE',
  withText = true,
  icon,
  useAnchor = true,
  disabledButtton,
  onClickButton,
}) => {
  const classes = useStyles();
  // eslint-disable-next-line no-unused-vars
  // const [showUpgrade, setShowUpgrade] = useState(true);

  return (
    <Box
      style={{
        background: '#fcfcfcd9',
        visibility: 'visible',
      }}
      position="absolute"
      top="0"
      left="0"
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="100%"
      zIndex={3}
    >
      <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
        {icon ? (
          <>
            <Box marginY={1}>{icon}</Box>
          </>
        ) : (
          <Box className={classes.circle}>
            <img src={upArrow} style={{ width: 30 }} alt="arrow-up" />
            <Box width="15px" height="5px" style={{ background: '#FEB535' }} />
          </Box>
        )}
        <Typography style={{ fontSize: 20, fontWeight: '500', marginBottom: 10 }} align="center">
          {title}
        </Typography>
        {withText && (
          <Typography
            style={{
              fontSize: 14,
              width: '100%',
              maxWidth: 570,
              marginBottom: 5,
            }}
            align="center"
          >
            {!text ? (
              <>
                This feature is not included in your current Sivil subscription. Please upgrade your account to access
                additional tools. Contact us at <a href="mailto:hello@sivilco.com">hello@sivilco.com</a>.
              </>
            ) : (
              <>{text}</>
            )}
          </Typography>
        )}
        {useAnchor ? (
          <a href="mailto:hello@sivilco.com" target="_blank" without rel="noreferrer" className={classes.button}>
            {buttonText}
          </a>
        ) : (
          <Button type="button" onClick={onClickButton} className={classes.button} disabled={disabledButtton}>
            {buttonText}
          </Button>
        )}
      </Box>
    </Box>
  );
};

// PropTypes validation for UpgradeTabPopup
UpgradeTabPopup.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  buttonText: PropTypes.string,
  withText: PropTypes.bool,
  icon: PropTypes.node,
  useAnchor: PropTypes.bool,
  disabledButtton: PropTypes.bool,
  onClickButton: PropTypes.func,
};

export default UpgradeTabPopup;
