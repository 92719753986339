import React from 'react';
import { TableRow, TableCell, Checkbox, Box, Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import moment from 'moment';

const EnhancedTableRow = ({
  row,
  index,
  goToIntendedLocation,
  typeClickPath,
  isSelected,
  myUser,
  classes,
  handleSelectRow,
}) => {
  const isItemSelected = isSelected(row._id);
  const labelId = `enhanced-table-checkbox-${index}`;

  return (
    <TableRow
      hover
      aria-checked={isItemSelected}
      selected={isItemSelected}
      role="checkbox"
      tabIndex={-1}
      key={index}
      className={classes.row}
      style={{ cursor: 'pointer', height: 50 }}
    >
      <TableCell padding="checkbox" onClick={(e) => handleSelectRow(row._id)}>
        {myUser?.role !== 'admin' && myUser?._id !== row?.owner?._id ? (
          <Box display="flex" alignItems="center" justifyContent="center">
            <Tooltip title="You cannot delete this report because you are not the owner.">
              <InfoIcon htmlColor="#4B7BFF" />
            </Tooltip>
          </Box>
        ) : (
          <Checkbox checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} color="primary" />
        )}
      </TableCell>

      <TableCell component="th" scope="row" align="left" onClick={() => goToIntendedLocation(row)}>
        {row?.name}
      </TableCell>

      <TableCell component="th" scope="row" align="left" onClick={() => goToIntendedLocation(row)}>
        {row?.description}
      </TableCell>

      <TableCell component="th" scope="row" align="left" onClick={() => goToIntendedLocation(row)}>
        {moment(row.createdAt).format('MMMM DD, YYYY')}
      </TableCell>

      <TableCell align="left" onClick={() => typeClickPath(row)}>
        {`${row?.owner?.firstName || 'System'} ${row?.owner?.lastName || ''}`}
      </TableCell>

      <TableCell align="left" onClick={() => goToIntendedLocation(row)} style={{ textTransform: 'capitalize' }}>
        {row?.type?.toLowerCase()}
      </TableCell>
    </TableRow>
  );
};

export default EnhancedTableRow;
