import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, CircularProgress } from '@material-ui/core';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import DashButton from '../../components/DashButton';
import ModalPopUp from '../../components/ModaPopupl';
import EnhancedTable from '../../components/Table';
import { Context } from '../../Context';
import { deleteReport, duplicateReport, getReports } from '../../services/unarmed';
import CreateReport from '../Data/CreateReport';
import EnhancedTableRow from '../../components/EnhancedTableRow';
import './styles.css';
import { TYPE_USER_TABS } from '../../utils/constants';
import ExploreProfile from './ExploreProfile/ExploreProfile';

const reportColumns = ['Report Name', 'Description', 'Created Date', 'Owner Name', 'Type'];

export default function ReportsTableTab({
  setTabSelected,
  tabSelected,
  setActiveTab,
  setActiveTabName,
  selectedReportId,
  setSelectedReportId,
}) {
  const [loading, setLoading] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selected, setSelected] = React.useState([]);
  const [checkedReports, setCheckedReports] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const { myUser } = React.useContext(Context);
  const [sortBy, setSortBy] = React.useState('createdAt');
  const [sort, setSort] = React.useState('desc');
  const [reports, setReports] = React.useState({ data: [], headers: {} });
  const [loadingDeleteReport, setLoadingDeleteReport] = React.useState(false);
  const history = useHistory();
  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Fetch reports
  const onGetReports = async () => {
    try {
      setLoading(true);
      const { data, headers } = await getReports(page, rowsPerPage, sort, sortBy);

      setReports({ data, headers });
    } catch (error) {
      console.error('Error fetching reports:', error);
      toast.error('Failed to fetch reports');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    onGetReports();
  }, [page, rowsPerPage, sort, sortBy]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(1);
  };

  const handleReportSelection = (reportId) => {
    setSelectedReportId(reportId);
    setTabSelected((prevTab) => ({
      ...prevTab,
      displayObjectId: reportId,
    }));

    setActiveTab(TYPE_USER_TABS.EXPLORE);
    setActiveTabName('Explore');
  };

  const handleSelectRow = (id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const renderRow = (row, index) => (
    <EnhancedTableRow
      key={row._id}
      row={row}
      index={index}
      goToIntendedLocation={() => handleReportSelection(row._id)} // Function to navigate and switch to Explore tab
      typeClickPath={() => {}}
      isSelected={isSelected}
      myUser={myUser}
      classes={{ row: 'row' }}
      handleSelectRow={handleSelectRow}
    />
  );

  const onDeleteReport = async () => {
    let ids = '';
    for (const id of selected) {
      ids += `ids[]=${id}&`;
    }

    try {
      setLoadingDeleteReport(true);
      await deleteReport(ids.slice(0, -1)); // Remove the trailing "&"
      await onGetReports();
      setLoadingDeleteReport(false);
      setSelected([]);
      setOpenDelete(false);
      toast.success('Report(s) deleted successfully');
    } catch (error) {
      toast.error(error?.response?.data?.message || 'Failed to delete reports');
      setLoadingDeleteReport(false);
    }
  };

  const handleReportCreate = (newReport) => {
    setReports((prevReports) => ({
      data: [newReport, ...(prevReports?.data || [])],
      headers: prevReports?.headers || {},
    }));
  };

  const handleClickDuplicate = async () => {
    try {
      setLoading(true);
      const { data } = await duplicateReport({ reportIds: selected });

      await onGetReports();

      toast.success(data.message);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between" marginTop="30px">
        <Typography style={{ fontWeight: '500', fontSize: 14 }}>
          All Data ({reports.headers['x-pagination-totalitems'] || 0})
        </Typography>
        <Box display="flex" alignItems="center">
          <DashButton
            data-testid="data-button-delete"
            onClick={() => setOpenDelete(true)}
            disabled={selected.length === 0}
            marginRight="10px"
          >
            Delete
          </DashButton>
          <DashButton
            data-testid="data-button-duplicate"
            onClick={() => handleClickDuplicate()}
            disabled={selected.length === 0}
            marginRight="10px"
          >
            Duplicate
          </DashButton>
          <DashButton data-testid="data-button-create-report" onClick={() => setOpen(true)} marginRight="10px">
            Create Report
          </DashButton>
        </Box>
      </Box>

      <Box display="flex" justifyContent="space-between" marginBottom="0">
        <EnhancedTable
          sort={sort}
          setSort={setSort}
          columns={reportColumns}
          rows={reports.data}
          showCheckBox
          setSortBy={setSortBy}
          rowRender={renderRow}
          setChecked={setCheckedReports}
          checked={checkedReports}
          loading={loading}
          page={page}
          tab="reports"
          setPage={setPage}
          onPageChange={handlePageChange}
          selected={selected}
          setSelected={setSelected}
          count={Number(reports.headers['x-pagination-totalitems']) || 0}
          rowsPerPage={rowsPerPage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
        {tabSelected === TYPE_USER_TABS.EXPLORE && <ExploreProfile id={selectedReportId} />}
      </Box>

      {loading && (
        <Box display="flex" justifyContent="center" marginTop={2}>
          <CircularProgress />
        </Box>
      )}

      <ModalPopUp open={open} setOpen={setOpen}>
        <CreateReport setReports={setReports} setOpen={setOpen}/>
      </ModalPopUp>

      <ModalPopUp open={openDelete} setOpen={setOpenDelete}>
        <Typography style={{ fontSize: 20, fontWeight: 600, textAlign: 'center' }}>
          Are you sure you want to delete?
        </Typography>
        <Typography style={{ fontSize: 14, textAlign: 'center', marginBottom: '20px' }} variant="body2">
          This action can't be undone!
        </Typography>
        <Box display="flex" alignItems="center" justifyContent="center" marginTop="20px">
          <Button
            style={{ color: '#4b7bff' }}
            onClick={() => setOpenDelete(false)}
          >
            CANCEL
          </Button>
          <Button
            style={{ color: '#4b7bff' }}
            onClick={onDeleteReport}
          >
            {loadingDeleteReport ? <CircularProgress color="#fff" size="25px" /> : 'DELETE'}
          </Button>
        </Box>
      </ModalPopUp>
    </>
  );
}
