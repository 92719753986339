import React from 'react';
import { Typography } from '@material-ui/core';

import CaseDetailTask from './CaseDetailTask';
import Card from '../../components/Card';
import { BoxLeftContainer } from '../../styles/GlobalStyles';

/**
 * CaseTasks
 *
 * List of tasks for case.
 *
 * @param {array} users
 * @param {object} form
 * @param {array} tasks
 * @param {function} updateTasks
 */
const CaseTasks = ({ users, form, tasks, updateTasks }) => (
  <BoxLeftContainer>
    <Card title="Tasks">
      {tasks?.length > 0 ? (
        <>
          {tasks.map((task, taskKey) => (
            <CaseDetailTask
              key={taskKey}
              users={users}
              form={form}
              task={task}
              taskKey={taskKey}
              updateTasks={updateTasks}
            />
          ))}
        </>
      ) : (
        <>
          <Typography style={{ textAlign: 'center', fontWeight: 'bold' }}>No tasks added to this case</Typography>
        </>
      )}
    </Card>
  </BoxLeftContainer>
);

export default CaseTasks;
