import React, { useState } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import WrapperComponent from './WrapperComponent';
import Input from '../../components/Input';
import useInput from '../../hooks/useInput';
import Settings from '../utils/Settings';
import PreviewLabel from '../utils/Label';
import HideAnswer from '../../components/HideAnswer';

const useStyles = makeStyles(() => ({
  title: {
    fontSize: 14,
    color: '#000000DE',
    marginLeft: 10,
  },
  label: {
    fontSize: 16,
    color: '#000000DE',
    fontWeight: '500',
  },
  labelAnswer: {
    fontSize: 16,
    color: '#6D7385',
    fontWeight: '500',
    paddingLeft: '10px',
  },
}));

export default function Hour({
  questionBankComponent,
  id,
  componentId,
  onUpdateJson,
  onDeleteComponent,
  path,
  data,
  componentIndex,
  onChangeDataInput,
  duplicateComponent,
  refActionModal,
  setModal,
}) {
  const classes = useStyles();
  const [checkQuestion, setCheckQuestion] = useState([!!data?.description, !!data.requiredMessage]);
  const question = useInput(data?.question || '');
  const required = useInput(!!data?.required, false, false, true);
  const descriptionQuestion = useInput(data?.description || '');
  const requiredQuestionMessage = useInput(data?.requiredMessage || '');
  const [loading, setLoading] = React.useState(false);

  const [hideShowMessage, setHideShowMessage] = React.useState('Hide Answer');

  const [showAnswerBool, setShowAnswerBool] = React.useState(true);
  const handleShowAnswerBool = (state) => {
    setShowAnswerBool((state) => !state);
    // console.log(hideShowMessage)
  };

  const handleSaveComponent = () => {
    const dataJson = {
      question: question.value,
      ...(descriptionQuestion.value ? { description: descriptionQuestion.value } : {}),
      ...(requiredQuestionMessage.value ? { requiredMessage: requiredQuestionMessage.value } : {}),
      ...(data.dataReportLink ? { dataReportLink: data.dataReportLink } : {}),
      required: required.value,
    };

    onUpdateJson(dataJson);
  };
  const saveOnTimeOut = () => {
    setLoading(true);
    setTimeout(() => {
      handleSaveComponent();
      setLoading(false);
    }, 500);
  };
  const renderSettings = () => (
    <Settings
      componentId={componentId}
      componentType="hour"
      defaultDataReportLink={data.dataReportLink}
      onSelectDataReportLink={(dataReportLink) => onUpdateJson({ ...data, dataReportLink })}
      saveOnTimeOut={saveOnTimeOut}
      questions={['description', 'required']}
      checkQuestion={checkQuestion}
      descriptionQuestion={descriptionQuestion}
      requiredQuestion={requiredQuestionMessage}
      setCheckQuestion={setCheckQuestion}
    />
  );

  const renderPreview = () => (
    <Box>
      <PreviewLabel question={question.value} qNumber={componentIndex + 1} required={required.value} />
      <HideAnswer onClick={handleShowAnswerBool} marginLeft="35px" message={hideShowMessage} />
      <Box marginLeft="42px">
        {showAnswerBool && (
          <Box display="flex">
            <AccessTimeIcon fill="#6D7385" />
            <Typography className={classes.labelAnswer}>Hour</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );

  return (
    <WrapperComponent
      onBlur={(!questionBankComponent && saveOnTimeOut) || function () {}}
      renderSettings={renderSettings}
      componentIndex={componentIndex}
      duplicateComponent={duplicateComponent}
      isQuestionBankComponent={questionBankComponent}
      required={required}
      renderPreview={renderPreview}
      onDeleteComponent={onDeleteComponent}
      id={id}
      componentId={componentId}
      path={path}
      loading={loading}
      dataSaved={data?.question}
      refActionModal={refActionModal}
      setModal={setModal}
    >
      <Box display="flex" alignItems="center">
        <AccessTimeIcon htmlColor="#6D7385" />
        <Typography className={classes.title}>Hour</Typography>
      </Box>
      <Box display="flex" alignItems="center" marginTop="15px">
        <Typography className={classes.label}>Q{componentIndex + 1}</Typography>
        <ArrowRightAltIcon style={{ fontSize: 14, marginRight: 10 }} />
        <Input
          placeholder="Enter name question or label"
          value={question.value}
          onChange={(e) => {
            question.onChange(e);
            saveOnTimeOut();
          }}
        />
      </Box>
    </WrapperComponent>
  );
}
