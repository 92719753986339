import { Box, CircularProgress, Divider, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';

import AddMore from '../../components/AddMore';
import DashButton from '../../components/DashButton';
import { onUpdateOrganization } from '../../services/unarmed';
import { OutLineInputBox } from '../../styles/GlobalStyles';
import TypeItem from '../ProjectsTypes/TypeItem';
import EISItem from './components/EISItem';
import MonitorItem from './components/MonitorItem';

const useStyles = makeStyles(() => ({
  container: {
    padding: 20,
    width: '100%',
  },
  typesContainer: {
    width: '100%',
    padding: 30,
    minHeight: 195,
    position: 'relative',
    borderRadius: 8,
    boxShadow: '0px 3px 6px #0000000D',
    backgroundColor: '#fff',
    marginTop: 20,
  },
  title: {
    fontSize: 16,
    marginBottom: 15,
    fontWeight: '500',
  },
  label: {
    fontWeight: '400',
    fontSize: 14,
    color: '#333',
  },
  types: {
    width: 450,
    marginLeft: 50,
  },
}));

export default function OfficerEISTab({ organization, setRefetch }) {
  const classes = useStyles();
  const [monitorList, setMonitorList] = React.useState([]);
  const [interveneList, setInterveneList] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [riskLevel, setRiskLevel] = React.useState({
    evaluationTime: 0,
    high: 0,
    medium: 0,
    low: 0,
  });

  useEffect(() => {
    if (organization?.EIS) {
      setMonitorList(organization?.EIS?.monitorMeetingList || []);
      setInterveneList(organization?.EIS?.interveneChecklist || []);
      setRiskLevel(
        organization?.EIS?.riskLevel || {
          evaluationTime: 0,
          high: 0,
          medium: 0,
          low: 0,
        }
      );
    }

    return () => null;
  }, []);

  const handleSave = async () => {
    setLoading(true);
    try {
      console.log( 3)
      await onUpdateOrganization(organization?._id, {
        EIS: {
          riskLevel,
          interveneChecklist: interveneList,
          monitorMeetingList: monitorList.map((item) => +item),
        },
      });
      setRefetch((prevState) => !prevState);
      toast.success('EIS saved successfully');
    } catch (err) {
      toast.error(err.response?.data?.error?.details[0]?.message || 'Something went Wrong');
    } finally {
      setLoading(false);
    }
  };

  const handleRiskLevelChange = (event, key, condition) => {
    const { value } = event.target;
    if (condition && +value >= condition) {
      return;
    }

    setRiskLevel({ ...riskLevel, [key]: value });
  };

  const handleChange = (event, index, options, setOptions) => {
    const { value } = event.target;
    const optionsCopy = [...options];
    optionsCopy[index] = value;
    setOptions(optionsCopy);
  };

  const handleDelete = (index, options, setOptions) => {
    const optionsCopy = [...options];
    optionsCopy.splice(index, 1);
    setOptions(optionsCopy);
  };

  const handleAdd = (options, setOptions) => {
    const optionsCopy = [...options];
    optionsCopy.push('');
    setOptions(optionsCopy);
  };

  return (
    <Box className={classes.container}>
      <Box display="flex" justifyContent="flex-end" alignItems="center" width="100%">
        <DashButton onClick={handleSave}>
          {loading ? <CircularProgress color="#fff" size="20px" /> : 'Save'}{' '}
        </DashButton>
      </Box>
      <Box className={classes.typesContainer}>
        <Typography className={classes.title}>Early Intervention System</Typography>
        <Divider style={{ marginBottom: 20 }} />
        <Box className={classes.types}>
          <Box display="flex" alignItems="center" style={{ gap: 20 }}>
            <Typography className={classes.label}>Evaluation Time</Typography>
            <OutLineInputBox
              onChange={(e) => handleRiskLevelChange(e, 'evaluationTime')}
              value={riskLevel.evaluationTime}
              placeholder="0"
              type="number"
              min={0}
              style={{ width: 40, textAlign: 'center' }}
            />
            <Typography className={classes.label}>Months</Typography>
          </Box>
          <Box marginTop={2}>
            <EISItem
              color="#FF4242"
              priorityLabel="High"
              onChange={(e) => handleRiskLevelChange(e, 'high')}
              value={riskLevel.high}
            />
            <EISItem
              color="#fca249"
              priorityLabel="Medium"
              conditionNumber={riskLevel.high}
              onChange={(e) => handleRiskLevelChange(e, 'medium', riskLevel.high)}
              value={riskLevel.medium}
            />
            <EISItem
              color="#FEB535"
              priorityLabel="Low"
              conditionNumber={riskLevel.medium}
              onChange={(e) => handleRiskLevelChange(e, 'low', riskLevel.medium)}
              value={riskLevel.low}
            />
          </Box>
        </Box>
        <Typography className={classes.title} style={{ marginTop: 30 }}>
          Intervene Checklist
        </Typography>
        <Divider style={{ marginBottom: 20 }} />
        <Box className={classes.types}>
          {interveneList.map((intervene, index) => (
            <Box key={`intervene-${index}`} marginBottom={1}>
              <TypeItem
                placeholder={`Intervene ${intervene + 1}`}
                value={intervene}
                onChange={(e) => handleChange(e, index, interveneList, setInterveneList)}
                onClick={() => handleDelete(index, interveneList, setInterveneList)}
              />
            </Box>
          ))}
          <Box display="flex" alignItems="center" justifyContent="flex-start">
            <AddMore
              text="Add new option"
              marginLeft="0"
              marginTop="0"
              onClick={() => handleAdd(interveneList, setInterveneList)}
            />
          </Box>
        </Box>
        <Typography className={classes.title} style={{ marginTop: 30 }}>
          Monitor Meetings
        </Typography>
        <Divider style={{ marginBottom: 20 }} />
        <Box className={classes.types}>
          {monitorList.map((monitor, index) => (
            <Box key={`intervene-${index}`} marginBottom={1}>
              <MonitorItem
                placeholder={`Intervene ${monitor + 1}`}
                value={monitor}
                onChange={(e) => handleChange(e, index, monitorList, setMonitorList)}
                handleDelete={() => handleDelete(index, monitorList, setMonitorList)}
              />
            </Box>
          ))}
          <Box display="flex" alignItems="center" justifyContent="flex-start">
            <AddMore
              text="Add new option"
              marginLeft="0"
              marginTop="0"
              onClick={() => handleAdd(monitorList, setMonitorList)}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
