import { Box, Button, CircularProgress, Typography, Grid } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import OfficerAdded from '../../components/OfficerAdded';
import { getOfficerOptions, updateForm } from '../../services/unarmed';
import Card from '../../components/Card';
import UpdateRenderer from './UpdateCaseComponents/UpdateRenderer';
import { BoxContainer } from '../../styles/GlobalStyles';
import { FORM_TYPE_VERSION_2 } from '../../config/config';
import CaseUpdateSection from '../../components/CaseUpdateSection';
import MultiSelectBox from '../../components/MultiSelectBox';
/**
 * Officers component to display officers related to a case.
 * @param {Object} props - Component props.
 * @param {Array} props.officers - Array of officers.
 * @param {string} props.id - Case ID.
 * @param {Function} props.setIsRefetch - Function to set refetch status.
 * @param {Function} props.setIsRequestingData - Function to set requesting data status.
 * @param {Object} props.officersOption - Options related to officers.
 * @returns {JSX.Element} Officers component.
 */
const Officers = ({
  officersAllegationOptions,
  officers,
  id,
  form,
  setIsRefetch,
  setIsRequestingData,
  officersOption,
  setForm,
  formType,
}) => {
  const [loadingOfficers, setLoadingOfficers] = React.useState(true);
  const [officerOptions, setOfficerOptions] = React.useState({
    reason: {
      visible: true,
      values: [],
    },
    allegation: {
      visible: true,
      values: ['USE OF FORCE', 'ABUSE OF AUTHORITY', 'DISCOURTESY', 'OFFENSIVE LANGUAGE', 'LACK OF SERVICE'],
    },
    disposition: {
      visible: true,
      values: ['SUSTAINED', 'NOT SUSTAINED', 'UNFOUNDED', 'IMPROPER CONDUCT', 'POLICY FAILURE'],
    },
    discipline: {
      visible: true,
      values: [
        'COUNSELED',
        'LETTER OF REPRIMAND',
        'SUSPENSION',
        'DEMOTION',
        'DISMISSAL',
        'RESIGNATION',
        'NO FURTHER ACTION',
        'OTHER',
      ],
    },
    status: {
      visible: true,
      values: ['RECEIVED', 'IN_PROGRESS', 'CLOSED'],
    },
  });
  const [contents, setContents] = useState([]);
  const [loading, setLoading] = useState(false);


  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Check if any of the officersAllegationOptions fields are visible
  const checkStandardVisible = () => {
    const keys = Object.keys(formType?.officersAllegationOptions || {});

    for (const key of keys) {
      if (formType?.officersAllegationOptions?.[key]?.visible) {
        return true;
      }
    }

    return false;
  };

  const getOfficersOptions = async () => {
    try {
      const { data } = await getOfficerOptions();
      const { reasons, allegations, disposition, disciplines, statuses } = data;

      const officerOptionsCopy = { ...officerOptions };
      officerOptionsCopy.reason.values = reasons;
      officerOptionsCopy.allegation.values = allegations;
      officerOptionsCopy.disposition.values = disposition;
      officerOptionsCopy.discipline.values = disciplines;
      officerOptionsCopy.status.values = statuses;

      setOfficerOptions(officerOptionsCopy);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingOfficers(false);
    }
  };

  React.useEffect(() => {
    getOfficersOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSaveFormUpdate = async ({ requestData, setLoadingState }) => {
    try {
      setLoading(true);
      setLoadingState();
      console.log('AQUI: onSaveFormUpdate', requestData);
      await updateForm(id, requestData);
      toast.success('The form has been updated successfully');
    } catch (error) {
      toast.error(error?.response?.data?.message);
      toast.error(error?.response?.data?.error?.details[0]?.message);
    } finally {
      setLoading(false);
      setLoadingState('');
      setIsRequestingData(false);
    }
  };

  const standardVisible = useMemo(() => checkStandardVisible(), [formType]);

  useEffect(() => {
    if (formType && formType.version === FORM_TYPE_VERSION_2) {
      const newContents = formType?.customFields.map((page, pageIndex) => {
        const pageName = page?.name?.toLowerCase().replace(/ /g, '-');
        const itemId = `custom-section-${pageIndex}-${pageName}`;

        return {
          active: page.active,
          name: page.name,
          value: itemId,
          components: page?.sections[0]?.components?.length || 0,
        };
      });

      if (standardVisible) {
        setContents([
          {
            name: 'Standard',
            value: 'standard',
            active: true
          },
          ...newContents,
        ]);
      } else if (newContents.length > 0) {
        const firstSection = newContents[0];

        setContents(newContents);
      }
    }
  }, []);


  return (
    <Card title="Officers">
      {officers.length === 0 && (
        <Typography style={{ textAlign: 'center', fontWeight: 'bold' }}>No officers added to this case</Typography>
      )}
      {loadingOfficers && (
        <Box display="flex" justifyContent="center" marginY={1}>
          <CircularProgress />
        </Box>
      )}
      
      {!loadingOfficers &&
        officers.map((officer, i) => (
          <OfficerAdded
            setForm={setForm}
            formId={form._id}
            setIsRequestingData={setIsRequestingData}
            key={i}
            officer={officer}
            id={id}
            setIsRefetch={setIsRefetch}
            officersOption={officersOption || officerOptions}
          />
        ))}
    </Card>
  );
};

const StyledList = styled.ul`
  list-style: inside;
`;

// PropTypes validation
Officers.propTypes = {
  officers: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
  setIsRefetch: PropTypes.func.isRequired,
  setIsRequestingData: PropTypes.func.isRequired,
  officersOption: PropTypes.object,
};

export default Officers;
