import { Box, CircularProgress, Typography } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import useNearScreen from '../../../hooks/userNearScreen';
import { getOfficerLogs, onArchiveNoteOfficer, onUnArchiveNoteOfficer } from '../../../services/unarmed';
import Log from './Log';

const sortByCreatedAt = (data, order = 'desc') => {
  return data.sort((x, y) => {
    const dateX = new Date(x.createdAt).getTime();
    const dateY = new Date(y.createdAt).getTime();
    return order === 'asc' ? dateX - dateY : dateY - dateX;
  });
};

const useOfficerLogs = (officerId, type, shouldRefetch, sortOrder) => {
  const [logs, setLogs] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [lastId, setLastId] = useState('');
  const [hasNextPage, setHasNextPage] = useState(true);

  useEffect(() => {
    const fetchLogs = async () => {
      setLoading(true);
      try {
        const { data } = await getOfficerLogs(officerId, type);
        const sortedData = sortByCreatedAt(data, sortOrder);
        setLogs(sortedData);
        setLastId(sortedData[sortedData.length - 1]?._id);
      } catch (error) {
        toast.error(error?.message);
      } finally {
        setLoading(false);
      }
    };
    if (officerId) {
      fetchLogs();
    }
  }, [officerId, shouldRefetch, type, sortOrder]);

  const getMoreLogs = async () => {
    try {
      const { data } = await getOfficerLogs(officerId, type, 15, lastId);
      if (!data.length) {
        setHasNextPage(false);
        return;
      }

      if (lastId && typeof lastId === 'string' && lastId !== data[data.length - 1]?._id) {
        const sortedData = sortByCreatedAt([...logs, ...data], sortOrder);
        setLogs(sortedData);
        setLastId(sortedData[sortedData.length - 1]?._id);
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };

  return { logs, loading, getMoreLogs, lastId, hasNextPage, setLogs };
};

export default function OfficerLogs({ officerId, type, shouldRefetch, sortOrder, archivedOnly }) {
  const { logs, loading, getMoreLogs, lastId, hasNextPage, setLogs } = useOfficerLogs(officerId, type, shouldRefetch, sortOrder);
  const [notesShow, setNotesShow] = useState([]);
  const externalRef = useRef();

  useEffect(() => {
    if(archivedOnly) {
      const newLogs = logs.filter(log => log.hasOwnProperty('archivedDate') && log.archivedDate !== null);
      setNotesShow(newLogs)
    }else{
      const newLogsWithoutArchivedDate = logs.filter(log => !log.hasOwnProperty('archivedDate'));
      setNotesShow(newLogsWithoutArchivedDate)
    }
  }, [logs, archivedOnly]);

  return (
    <Box
      style={{ background: '#fff', borderRadius: 5 }}
      boxShadow="0 3px 6px #00000005"
      width="100%"
      height="fit-content"
      padding="10px"
    >
      {loading && (
        <Box display="flex" alignItems="center" justifyContent="center">
          <CircularProgress color="#333" size="30px" style={{ marginLeft: 10 }} />
        </Box>
      )}
      {notesShow.length === 0 && !loading && (
        <Typography variant="body2" style={{ fontWeight: '500', fontSize: 14, textAlign: 'center' }}>
          There is not any {type} notes added yet
        </Typography>
      )}
      {notesShow.map(({ createdAt, files = [], comment, user, userInfo, meeting, intervene, archivedDate, _id }, index) => (
        <Log
          key={createdAt + index}
          createdAt={createdAt}
          archivedDate={archivedDate}
          files={files}
          handleDeleteNote={async() => {
            await onArchiveNoteOfficer(officerId, _id)
            const { data } = await getOfficerLogs(officerId, type);
            const sortedData = sortByCreatedAt(data, sortOrder);
            setLogs(sortedData);
          }}
          handleRestoreClick={async() => {
            await onUnArchiveNoteOfficer(officerId, _id)
            const { data } = await getOfficerLogs(officerId, type);
            const sortedData = sortByCreatedAt(data, sortOrder);
            setLogs(sortedData);
          }}
          comment={comment}
          user={user}
          userInfo={userInfo}
          meeting={meeting}
          intervene={intervene}
        />
      ))}
      <div id="bottom" ref={externalRef} />
    </Box>
  );
}

OfficerLogs.propTypes = {
  officerId: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  shouldRefetch: PropTypes.bool.isRequired,
  sortOrder: PropTypes.string.isRequired,
};
