import { Box, CircularProgress, Typography, makeStyles } from '@material-ui/core';
import React from 'react';

import LogoHome from './LogoHome';

const useStyles = makeStyles(() => ({
  content: {
    height: '116.1vh',
    width: 'calc(100vw + 14%)',
    background: '#F9FAFD',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logoOrganization: {
    width: 300,
    maxHeight: '100px',
    objectFit: 'contain',
    padding: 10,
    alignSelf: 'center',
  },
}));

const LoadingScreen = () => {
  const classes = useStyles();

  return (
    <Box className={classes.content}>
      <LogoHome width={300} />
    </Box>
  );
};

export const BoxLoading = () => (
  <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" height="500px">
    <CircularProgress />
    <Typography variant="h6" style={{ marginTop: 32 }}>
      Loading...
    </Typography>
  </Box>
);

export default LoadingScreen;
