import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  contactAttr: {
    fontSize: 12,
    color: '#a1a1a1',
  },
  contactAttrText: {
    fontSize: 14,
    color: '#000',
  },
}));

const UserAttr = ({ title, value }) => {
  const classes = useStyles();

  return (
    <Box mb={1}>
      <Typography className={classes.contactAttr}>{title}</Typography>
      <Typography className={classes.contactAttrText}>{value || 'Not Specified'}</Typography>
    </Box>
  );
};

UserAttr.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
};

export default UserAttr;
