import {
  Box,
  makeStyles,
  TableRow,
  Typography,
  TableCell,
  Checkbox,
  Button,
  CircularProgress,
  Tooltip,
} from '@material-ui/core';
import moment from 'moment';
import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import InfoIcon from '@material-ui/icons/Info';
import DashButton from '../../components/DashButton';
import ModalPopUp from '../../components/ModaPopupl';
import EnhancedTable from '../../components/Table';
import UpgradeModal from '../../components/UpgradeModal';
import { Context } from '../../Context';
import useOrganization from '../../hooks/useOrganization';
import { deleteReport, duplicateReport, getReports } from '../../services/unarmed';
import CreateReport from './CreateReport';

const useStyles = makeStyles(() => ({
  container: {
    padding: 20,
    width: '100%',

    '& .makeStyles-root-51': {
      boxShadow: 'unset !important',
    },
  },
  buttonOutlined: {
    letterSpacing: '1px',
    fontSize: 13,
    fontWeight: '500',
    marginRight: 10,
    width: 'fit-content',
    alignSelf: 'flex-end',
    border: '1px solid transparent',
    color: '#4b7bff',
    '&:hover': {
      background: 'transparent',
      border: '1px solid transparent',
    },
    '& .MuiButton-label': {
      fontSize: 13,
    },
    '&:disabled': {
      border: 0,
    },
  },
}));

const columns = ['Report Name', 'Description', 'Created Date', 'Owner Name', 'Type'];

export default function Data() {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selected, setSelected] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const { authUser, setActive, myUser } = React.useContext(Context);
  const [sortBy, setSortBy] = React.useState('createdAt');
  const [sort, setSort] = React.useState('desc');
  const [checked, setChecked] = React.useState(false);
  const [reports, setReports] = React.useState([]);
  const [loadingDeleteReport, setLoadingDeleteReport] = React.useState(false);
  const history = useHistory();
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const { organization } = useOrganization();

  useEffect(() => {
    setActive('/data');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickDuplicate = async () => {
    try {
      setLoading(true);
      const { data } = await duplicateReport({ reportIds: selected });

      await onGetReports();

      toast.success(data.message);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  const onGetReports = async () => {
    try {
      setLoading(true);
      const { data, headers } = await getReports(page, rowsPerPage, sort, sortBy);

      setReports({ data, headers });
      setLoading(false);
    } catch (error) {
      setLoading(false);

      toast.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    if (authUser) {
      onGetReports();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser, page, rowsPerPage, sort, sortBy]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(1);
  };

  const gotToOfficerProfile = useCallback((e, row, handleClick) => {
    const go = handleClick(e, row._id);

    if (go) {
      history.push(`/data/${row._id}`);
      setActive('/data');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDeleteReport = async () => {
    let ids = '';
    for (const id of selected) {
      const index = selected.indexOf(id);
      if (index + 1 === selected.length) {
        ids += `ids[]=${id}`;
      } else {
        ids += `ids[]=${id}&`;
      }
    }

    try {
      setLoadingDeleteReport(true);

      await deleteReport(ids);
      await onGetReports();

      setLoadingDeleteReport(false);
      setSelected([]);
      setOpenDelete(false);
      toast.success('Report(s) deleted Successfully');
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setLoadingDeleteReport(false);
    }
  };

  const renderRow = (row, index, handleClick) => {
    const isItemSelected = isSelected(row._id);
    const labelId = `enhanced-table-checkbox-${index}`;
    return (
      <TableRow
        hover
        aria-checked={isItemSelected}
        selected={isItemSelected}
        role="checkbox"
        tabIndex={-1}
        key={index}
        className={classes.row}
        style={{ cursor: 'pointer', height: 50 }}
      >
        <TableCell padding="checkbox" onClick={(e) => gotToOfficerProfile(e, row, handleClick)}>
          {myUser?.role !== 'admin' && myUser?._id !== row?.owner?._id ? (
            <Box display="flex" alignItems="center" justifyContent="center">
              <Tooltip title="You can not delete this report because you are not the owner.">
                <InfoIcon htmlColor="#4B7BFF" />
              </Tooltip>
            </Box>
          ) : (
            <Checkbox checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} color="primary" />
          )}
        </TableCell>
        <TableCell component="th" scope="row" align="left" onClick={(e) => gotToOfficerProfile(e, row, handleClick)}>
          {row?.name}
        </TableCell>
        <TableCell component="th" scope="row" align="left" onClick={(e) => gotToOfficerProfile(e, row, handleClick)}>
          {row?.description}
        </TableCell>
        <TableCell component="th" scope="row" align="left" onClick={(e) => gotToOfficerProfile(e, row, handleClick)}>
          {moment(row.createdAt).format('MMMM DD, YYYY')}
        </TableCell>

        <TableCell align="left" onClick={(e) => gotToOfficerProfile(e, row, handleClick)}>
          {`${row?.owner?.firstName || 'System'} ${row?.owner?.lastName || ''}`}
        </TableCell>
        <TableCell
          align="left"
          onClick={(e) => gotToOfficerProfile(e, row, handleClick)}
          style={{ textTransform: 'capitalize' }}
        >
          {row?.type?.toLowerCase()}
        </TableCell>
      </TableRow>
    );
  };

  return (
    <>
      {organization && <UpgradeModal open={!organization?.features?.dashboard?.data?.enabled} />}
      <Box
        className={classes.container}
        style={
          !organization?.features?.dashboard?.data?.enabled
            ? {
                filter: 'blur(6px)',
                height: '70vh',
                overflow: 'hidden',
              }
            : {}
        }
      >
        <Typography variant="h5">Data</Typography>
        <Box display="flex" alignItems="center" justifyContent="space-between" marginTop="30px">
          <Typography style={{ fontWeight: '500', fontSize: 14 }}>
            All Data ({reports?.headers?.['x-pagination-totalitems'] || 0})
          </Typography>
          <Box display="flex" alignItems="center">
            <DashButton
              data-testid="data-button-delete"
              onClick={() => setOpenDelete(true)}
              disabled={selected.length === 0}
              marginRight="10px"
            >
              Delete
            </DashButton>
            <DashButton
              data-testid="data-button-duplicate"
              onClick={() => handleClickDuplicate()}
              disabled={selected.length === 0}
              marginRight="10px"
            >
              Duplicate
            </DashButton>
            <DashButton data-testid="data-button-create-report" onClick={() => setOpen(true)} marginRight="10px">
              Create Report
            </DashButton>
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between" marginBottom="0">
          <EnhancedTable
            sort={sort}
            setSort={setSort}
            columns={columns}
            rows={reports.data}
            showCheckBox
            setSortBy={setSortBy}
            rowRender={renderRow}
            setChecked={setChecked}
            checked={checked}
            loading={loading}
            page={page}
            tab="officer"
            setPage={setPage}
            selected={selected}
            setSelected={setSelected}
            count={reports?.headers?.['x-pagination-totalitems']}
            rowsPerPage={rowsPerPage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Box>
        <ModalPopUp open={open} setOpen={setOpen}>
          <CreateReport setReports={setReports} setOpen={setOpen} />
        </ModalPopUp>
        <ModalPopUp open={openDelete} setOpen={setOpenDelete}>
          <Typography style={{ fontSize: 20, fontWeight: '500' }}>Are you sure you want to delete?</Typography>
          <Typography variant="body2">This action can't be undone!</Typography>
          <Box display="flex" alignItems="center" justifyContent="flex-end" marginTop="20px">
            <Button variant="outlined" className={classes.buttonOutlined} onClick={() => setOpenDelete(false)}>
              CANCEL
            </Button>
            <Button variant="outlined" className={classes.buttonOutlined} onClick={onDeleteReport}>
              {loadingDeleteReport ? <CircularProgress color="#fff" size="25px" /> : 'DELETE'}
            </Button>
          </Box>
        </ModalPopUp>
      </Box>
    </>
  );
}
