import axios from 'axios';
import { toast } from 'react-toastify';

import { unarmedWithToken } from '../services/axios';
import { logEvent } from '../services/firebase';
import { getDocumentTitle } from '.';

export async function downloadImage(imageSrc, filename) {
  return axios({
    url: imageSrc,
    method: 'GET',
    params: { download: true },
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('authToken')}`,
    },
  })
    .then(({ data }) => {
      const { url } = data;
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((err) => toast.error(err.message));
}

export async function downloadContactImages(id) {
  return unarmedWithToken().get(`/contacts/${id}/attachments?download=true`, { responseType: 'arraybuffer' });
}

export async function downloadContactImage(id, fileId, filename) {
  return unarmedWithToken()
    .get(`/contacts/${id}/attachments/${fileId}?download=true`)
    .then(({ data }) => {
      const { url } = data;
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((err) => toast.error(err.message));
}

export const updateUrlWithParams = (eventParams, urlParams) => {
  let url = window.location.href.includes('?') ? window.location.href.split('?')[0] : window.location.href;

  url += (url.includes('?') ? '&' : '?') + new URLSearchParams(urlParams).toString();

  let pageTitle = 'Sivil';

  if (eventParams.organization_name) {
    pageTitle = getDocumentTitle(eventParams.organization_name);
  }

  logEvent('page_view', {
    page_title: pageTitle,
    page_location: url,
    page_path: window.location.pathname,
    ...eventParams,
  });

  window.history.replaceState(null, '', url);
};
