import React from 'react';
import styled from 'styled-components';

export default function Input({
  value,
  onChange,
  style,
  disabled,
  placeholder,
  type = 'text',
  limit,
}) {
  return (
    <InputEngine
      style={style}
      value={value}
      type={type}
      onChange={onChange}
      disabled={disabled}
      placeholder={placeholder}
      maxLength={limit}
    />
  );
}

const InputEngine = styled.input`
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #0000001f;
  width: 100%;
  font-size: 14px;
  padding: 11px;
  ::placeholder {
    color: #00000040;
    font-size: 14;
  }
  outline: #0000001f;
`;
