import React from 'react';
import Dropdown from '../../formEngine/components/Dropdown';
import File from '../../formEngine/components/FIle';
import LongText from '../../formEngine/components/LongText';
import MultipleChoice from '../../formEngine/components/MultipleChoice';
import ShortText from '../../formEngine/components/ShortText';
import SingleChoice from '../../formEngine/components/SingleChoice';
import YesNo from '../../formEngine/components/YesNo';
import URL from '../../formEngine/components/URL';
import Title from '../../formEngine/components/Title';
import Subtitle from '../../formEngine/components/Subtitle';
import ContactInfo from '../../formEngine/components/ContactInfo';
import AddressInformation from '../../formEngine/components/AddressInformation';
import Hour from '../../formEngine/components/Hour';
import Date from '../../formEngine/components/Date';
import Phone from '../../formEngine/components/Phone';
import Email from '../../formEngine/components/Email';

export default function FormEngine() {
  return (
    <>
      <Email />
      <Phone />
      <ContactInfo />
      <File />
      <SingleChoice />
      <MultipleChoice />
      <Dropdown />
      <ShortText />
      <LongText />
      <Title />
      <Subtitle />
      <YesNo />
      <Date />
      <Hour />
      <URL />
      <AddressInformation />
    </>
  );
}
