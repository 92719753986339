/* eslint-disable no-nested-ternary */
import {
  Box,
  Checkbox,
  makeStyles,
  TableCell,
  TableRow,
  Typography,
  Fade,
  Backdrop,
  Modal,
  Tooltip,
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import InfoIcon from '@material-ui/icons/Info';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import EnhancedTable from '../../components/Table';
import UpgradeModal from '../../components/UpgradeModal';
import useOrganization from '../../hooks/useOrganization';
import { getAvailableFormType, getFormTypes } from '../../services/unarmed';
import DashButton from '../../components/DashButton';
import CreateForm from './CreateForm';
import DeleteForms from './DeleteForms';
import RenderTableOptions from './RenderTableOptions';
import UpdateStatusFormPopup from './UpdateStatusFormPopup';
import formatTxt from '../../utils/formatText';
import UpdateStatusFormPortal from './UpdateStatusFormPortal';
import SortForms from './SortForms';
import { sortOrderForms } from '../../utils';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 20,
  },
  title: {
    marginTop: 50,
  },
  button: {
    background: ' #1F63FF',
    textTransform: 'capitalize',
    marginRight: 10,
    color: '#fff',
    height: 36,
    width: 30,
    '&:hover': {
      background: '#1F63FF',
    },
    '& .MuiButton-label': {
      fontSize: 13,
    },
    '&:disabled': {
      background: '#1f63ff61',
      color: '#fff',
    },
  },
  button2: {
    background: ' #1F63FF',
    textTransform: 'capitalize',
    color: '#fff',
    '&:hover': {
      background: '#1F63FF',
    },
    '& .MuiButton-label': {
      fontSize: 14,
    },
  },
  buttonOutlined: {
    letterSpacing: '1px',
    fontSize: 13,
    fontWeight: '500',
    marginRight: 10,
    width: 'fit-content',
    alignSelf: 'flex-end',
    border: '1px solid transparent',
    color: '#4b7bff',
    '&:hover': {
      background: 'transparent',
      border: '1px solid transparent',
    },
    '& .MuiButton-label': {
      fontSize: 13,
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: '#fff',
    boxShadow: theme.shadows[5],
    padding: 30,
    maxWidth: 600,
    minWidth: 400,
    outline: 0,
  },
}));

const columns = ['Name', 'Display Name', 'Type', 'Created By', 'Created Date', 'Status', 'Portal Status', 'Actions'];

const Forms = () => {
  const classes = useStyles();
  const [forms, setForms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selected, setSelected] = React.useState([]);
  const [checked, setChecked] = useState(false);
  const { organization } = useOrganization();
  const [status, setStatus] = useState([]);
  const [availableFormDashboard, setAvailableFormDashboard] = useState([]);
  const [open, setOpenModal] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const history = useHistory();
  const [sortBy, setSortBy] = useState('createdAt');
  const [sort, setSort] = useState('asc');
  const [showOpenSortForms, setShowOpenSortForms] = useState(false);
  const [availableFormsTypes, setAvailableFormTypes] = useState([]);

  const onGetAvailableFormsTypes = useCallback(async () => {
    try {
      const { data } = await getAvailableFormType(organization?._id);
      setAvailableFormTypes(sortOrderForms(data));
    } catch (error) {
      toast.error(error.message);
    }
  }, [organization]);

  const onUpdatePortalStatusCallBack = (index, value) => {
    const statusCopy = [...availableFormDashboard];
    statusCopy[index] = value;
    setAvailableFormDashboard(statusCopy);
    onGetAvailableFormsTypes();
  };

  const onGetFormTypes = () => {
    setLoading(true);
    getFormTypes(page, rowsPerPage, sort, sortBy)
      .then(({ data, headers }) => {
        setForms({ data, headers });
        const stats = data.map((dt) => dt?.published);
        const dashboardForm = data.map((dt) => dt?.portalStatus);
        setAvailableFormDashboard(dashboardForm);
        setStatus(stats);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    onGetFormTypes();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, sortBy, sort]);

  useEffect(() => {
    if (organization) {
      onGetAvailableFormsTypes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(1);
  };

  const goToCaseDetails = (e, row, handleClick) => {
    const go = handleClick(e, row._id);
    if (go) {
      history.push(`/form/${row._id}`);
    }
  };

  const handleOnClickSortForms = () => {
    setShowOpenSortForms(true);
  };

  function toTitleCase(str) {
    return str.replace(
      /\w\S*/g,
      text => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
    );
  }

  const renderRow = (row, index, handleClick) => {
    const isItemSelected = isSelected(row._id);
    const labelId = `enhanced-table-checkbox-${index}`;

    return (
      <TableRow
        hover
        aria-checked={isItemSelected}
        selected={isItemSelected}
        role="checkbox"
        tabIndex={-1}
        key={index}
        className={classes.row}
        style={{
          cursor: 'pointer',
          height: 50,
          maxHeight: 50,
          background: '#fff',
        }}
      >
        <TableCell component="th" scope="row" align="left" onClick={(e) => goToCaseDetails(e, row, handleClick)}>
          {row.defaultForm ? (
            <Box display="flex" alignItems="center" justifyContent="center">
              <Tooltip title="This is a default form and cannot be deleted">
                <InfoIcon htmlColor="#4B7BFF" />
              </Tooltip>
            </Box>
          ) : (
            <Checkbox checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} color="primary" />
          )}
        </TableCell>

        <TableCell component="th" scope="row" align="left" onClick={(e) => goToCaseDetails(e, row, handleClick)}>
          {formatTxt(row?.adminName, 13)}
        </TableCell>
        <TableCell component="th" scope="row" align="left" onClick={(e) => goToCaseDetails(e, row, handleClick)}>
          {formatTxt(row?.name, 13)}
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          align="left"
          onClick={(e) => goToCaseDetails(e, row, handleClick)}
          style={{
            fontWeight: '500',
            color:
              row?.type?.toLowerCase() === 'complaint'
                ? '#FF4242'
                : row?.type?.toLowerCase() === 'compliment'
                ? '#008858'
                : '#2E66FE',
          }}
        >
          {row?.typeDisplayText ? row?.typeDisplayText : toTitleCase(row?.type)}
        </TableCell>
        <TableCell component="th" scope="row" align="left" onClick={(e) => goToCaseDetails(e, row, handleClick)}>
          {row?.createdByData?.firstName} {row?.createdByData?.lastName}
        </TableCell>

        <TableCell component="th" scope="row" align="left" onClick={(e) => goToCaseDetails(e, row, handleClick)}>
          {moment(row.createdAt).format('MMMM DD, YYYY')}
        </TableCell>
        <TableCell component="th" scope="row" align="left">
          <UpdateStatusFormPopup
            index={index}
            row={row}
            setForms={setForms}
            setStatus={setStatus}
            status={status}
            forms={forms}
            callback={onUpdatePortalStatusCallBack}
          />
        </TableCell>
        <TableCell component="th" scope="row" align="left">
          <UpdateStatusFormPortal
            index={index}
            row={row}
            setForms={setForms}
            setStatus={setAvailableFormDashboard}
            status={availableFormDashboard}
            forms={forms}
            statusKey="portalStatus"
            callback={onGetAvailableFormsTypes}
          />
        </TableCell>
        <TableCell component="th" align="justify">
          <RenderTableOptions id={row?._id} refreshCallBack={onGetFormTypes} />
        </TableCell>
      </TableRow>
    );
  };

  return (
    <>
      {organization && <UpgradeModal open={!organization?.features?.dashboard?.forms?.enabled} />}
      <PageWrapper
        className={classes.container}
        style={
          !organization?.features?.dashboard?.forms?.enabled
            ? {
                filter: 'blur(6px)',
                height: '70vh',
                overflow: 'hidden',
              }
            : {}
        }
      >
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h5">Forms </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="flex-end">
          <Typography className={classes.title} variant="subtitle2" id="tableTitle" component="div">
            All Forms ({forms?.headers?.['x-pagination-totalitems'] || 0})
          </Typography>
          <Box display="flex">
            <DashButton
              data-testid="forms-button-delete-forms"
              onClick={() => setOpenModal(true)}
              disabled={selected.length === 0}
              marginRight="10px"
            >
              Delete
            </DashButton>
            <DashButton data-testid="forms-button-sort-forms" marginRight="10px" onClick={handleOnClickSortForms}>
              Sort Forms
            </DashButton>
            <DashButton data-testid="forms-button-create-form" onClick={() => setOpenCreate(true)} marginRight="10px">
              Create Form
            </DashButton>
          </Box>
        </Box>
        <Box marginBottom="0">
          <EnhancedTable
            columns={columns}
            rows={forms.data}
            showCheckBox
            rowRender={renderRow}
            setChecked={setChecked}
            checked={checked}
            loading={loading}
            setSort={setSort}
            sort={sort}
            tab="forms"
            setSortBy={setSortBy}
            page={page}
            setPage={setPage}
            selected={selected}
            setSelected={setSelected}
            count={forms?.headers?.['x-pagination-totalitems']}
            rowsPerPage={rowsPerPage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Box>
      </PageWrapper>
      <Modal
        aria-labelledby="transition-modal-title1"
        aria-describedby="transition-modal-description1"
        open={showOpenSortForms}
        className={classes.modal}
        onClose={() => setShowOpenSortForms(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showOpenSortForms}>
          <SortForms
            onClose={() => setShowOpenSortForms(false)}
            defaultLiveForms={sortOrderForms(availableFormsTypes)}
            onSaved={() => onGetAvailableFormsTypes()}
          />
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title1"
        aria-describedby="transition-modal-description1"
        open={openCreate}
        className={classes.modal}
        onClose={() => setOpenCreate(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openCreate}>
          <CreateForm refreshCallback={onGetFormTypes} setOpen={setOpenCreate} formTypes={organization?.formTypes} />
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title1"
        aria-describedby="transition-modal-description1"
        open={open}
        className={classes.modal}
        onClose={() => setOpenModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <DeleteForms
            setOpen={setOpenModal}
            selected={selected}
            setSelected={setSelected}
            refreshCallBack={onGetFormTypes}
          />
        </Fade>
      </Modal>
    </>
  );
};

const PageWrapper = styled.div`
  width: 100%;
`;

export default Forms;
