import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, LinearProgress, makeStyles, Paper, Typography } from '@material-ui/core';

/**
 * Custom styles for the DashboardCard component.
 */
const useStyles = makeStyles(() => ({
  paper: {
    padding: '10px 20px',
    width: 280,
    height: 140,
    marginRight: 20,
    cursor: 'pointer',
  },
  iconContainer: {
    width: 50,
    height: 50,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    width: 20,
    height: 20,
  },
  textLink: {
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

/**
 * DashboardCard component for displaying card with an icon, text, and optional progress bar.
 * @param {Object} props - The props of the component.
 * @param {string} props.icon - The URL of the icon image.
 * @param {string} props.color - The background color of the icon container.
 * @param {string} props.cardText - The text to display on the card.
 * @param {React.ElementType} [props.IconComponent] - The custom icon component to render.
 * @param {string} props.iconColor - The color of the icon.
 * @param {string} props.value - The value to display on the card.
 * @param {string} props.buttonText - The text for the button.
 * @param {Function} props.onClickButton - The function to handle button click.
 * @param {boolean} props.showProgressBar - Whether to show the progress bar.
 * @param {number} props.progressBarValue - The value of the progress bar.
 * @param {Object} props.styleContainer - The inline style for the container.
 * @param {string} props.barColor - The color of the progress bar.
 * @param {boolean} props.showChildren - Whether to show children elements.
 * @param {string} props.dataTestId - The data test ID for testing.
 * @param {React.ReactNode} [props.children] - The optional children elements.
 * @returns {JSX.Element} DashboardCard component.
 */
const DashboardCard = ({
  icon,
  color,
  cardText,
  IconComponent,
  iconColor,
  value,
  buttonText,
  onClickButton,
  showProgressBar,
  progressBarValue,
  styleContainer,
  barColor = 'primary',
  showChildren,
  dataTestId,
  children,
}) => {
  const classes = useStyles();
  return (
    <Paper data-testid={dataTestId} className={classes.paper} onClick={onClickButton} style={styleContainer}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography style={{ color: '#000000AD', fontSize: 16, fontWeight: '500' }}>{cardText}</Typography>
        <div className={classes.iconContainer} style={{ background: color }}>
          {IconComponent ? (
            <IconComponent htmlColor={iconColor} />
          ) : (
            <div
              className={classes.icon}
              style={{
                background: `url(${icon})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
              }}
            />
          )}
        </div>
      </Box>
      <Typography variant="h5" style={{ color: '#000000AD' }}>
        {value}
      </Typography>
      {showProgressBar && (
        <Box width="100%" mr={1} marginTop="3px">
          <LinearProgress variant="determinate" value={progressBarValue} color={barColor} style={{ marginBottom: 5 }} />
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography
              style={{
                color: '#00000061',
                fontSize: 12,
                marginTop: 3,
                fontWeight: '500',
              }}
            >
              {progressBarValue || 0}%
            </Typography>
            {showChildren && children}
          </Box>
        </Box>
      )}
      {!showProgressBar && <Divider />}
      {buttonText && (
        <Typography
          variant="subtitle2"
          className={classes.textLink}
          style={{
            color: '#000000AD',
            paddingTop: 7,
            fontSize: 13,
            marginTop: 5,
            display: 'inline-block',
          }}
          onClick={onClickButton}
        >
          {buttonText}
        </Typography>
      )}
    </Paper>
  );
};

// PropTypes
DashboardCard.propTypes = {
  icon: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  cardText: PropTypes.string.isRequired,
  IconComponent: PropTypes.elementType,
  iconColor: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  onClickButton: PropTypes.func.isRequired,
  showProgressBar: PropTypes.bool.isRequired,
  progressBarValue: PropTypes.number.isRequired,
  styleContainer: PropTypes.object,
  barColor: PropTypes.string,
  showChildren: PropTypes.bool,
  dataTestId: PropTypes.string,
  children: PropTypes.node,
};

export default DashboardCard;
