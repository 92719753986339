import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, CircularProgress } from '@material-ui/core';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import DashButton from '../../components/DashButton';
import ModalPopUp from '../../components/ModaPopupl';
import EnhancedTable from '../../components/Table';
import { Context } from '../../Context';
import { createUserTabs, deleteDashboard, getDashboards } from '../../services/unarmed';
import CreateDashboard from './CreateDashboard';
import EnhancedTableRow from '../../components/EnhancedTableRow';
import './styles.css';
import { TYPE_USER_TABS, DISPLAY_OBJECT_TYPES } from '../../utils/constants';

const dashboardColumns = ['Dashboard Name', 'Description', 'Created Date', 'Owner Name', 'Type'];

export default function DashboardsTableTab({ setUserTabs, userTabs, setActiveTab, setActiveTabName, setTabSelected }) {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selected, setSelected] = useState([]);
  const [checkedDashboards, setCheckedDashboards] = React.useState(false);
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const { myUser } = React.useContext(Context);
  const [sortBy, setSortBy] = useState('createdAt');
  const [sort, setSort] = useState('desc');
  const [dashboards, setDashboards] = useState([]);
  const [loadingDeleteDashboard, setLoadingDeleteDashboard] = useState(false);
  const history = useHistory();
  const isSelected = (name) => selected.indexOf(name) !== -1;

  const onGetDashboards = async () => {
    try {
      setLoading(true);
      const { data, headers } = await getDashboards(page, rowsPerPage, sort, sortBy);
      setDashboards({ data, headers });
    } catch (error) {
      toast.error('Failed to fetch dashboards');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    onGetDashboards();
  }, [page, rowsPerPage, sort, sortBy]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleSelectRow = (id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleCreateUserDashboardTab = async (dashboard) => {
    const index = userTabs.findIndex((tab) => dashboard.name.includes(tab.name));
    if (index !== -1) {
      setTabSelected({
        ...userTabs[index],
        displayObjectId: dashboard._id,
      });
      setActiveTabName(userTabs[index].name);
      setActiveTab(TYPE_USER_TABS.DASHBOARD);
    } else {
      const lengthTabs = userTabs.length + 1;
      const userTabsPayload = {
        userTabs: [
          {
            name: dashboard.name,
            type: TYPE_USER_TABS.DASHBOARD,
            position: String(lengthTabs),
            displayObjectType: DISPLAY_OBJECT_TYPES.DASHBOARD,
            displayObjectId: dashboard._id,
            moduleName: 'INSIGHT',
          },
        ],
      };

      try {
        const response = await createUserTabs(userTabsPayload);
        if (response.status === 200) {
          const newTab = {
            ...response?.data?.userTabs[0],
            displayObjectId: dashboard._id,
          };
          setUserTabs((itemsPrev) => [...itemsPrev, newTab]);
          setActiveTabName(newTab.name);
          setActiveTab(TYPE_USER_TABS.DASHBOARD);
          setTabSelected(newTab);
        }
      } catch (error) {
        console.error('API error while creating tab:', error);
      }
    }
  };

  const renderRow = (row, index) => (
    <EnhancedTableRow
      row={row}
      index={index}
      goToIntendedLocation={handleCreateUserDashboardTab}
      typeClickPath={() => {}}
      isSelected={isSelected}
      myUser={myUser}
      classes={{ row: 'row' }}
      handleSelectRow={handleSelectRow}
    />
  );

  const onDeleteDashboard = async () => {
    if (selected.length === 0) {
      toast.error('No dashboards selected');
      return;
    }

    try {
      setLoadingDeleteDashboard(true);

      const ids = selected.map((id) => id);
      const response = await deleteDashboard({ ids });

      if (response.status === 200 || response.status === 207) {
        setLoadingDeleteDashboard(false);
        await onGetDashboards();
        setSelected([]);
        setOpenDelete(false);
        toast.success('Dashboard(s) deleted Successfully');
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || 'An error occurred while deleting dashboards');
      setLoadingDeleteDashboard(false);
    }
  };

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between" marginTop="30px">
        <Typography style={{ fontWeight: '500', fontSize: 14 }}>
          All Data ({dashboards?.headers?.['x-pagination-totalitems'] || 0})
        </Typography>
        <Box display="flex" alignItems="center">
          <DashButton
            data-testid="data-button-delete"
            onClick={() => setOpenDelete(true)}
            disabled={selected.length === 0}
            marginRight="10px"
          >
            Delete
          </DashButton>
          <DashButton data-testid="data-button-create-report" onClick={() => setOpen(true)} marginRight="10px">
            Create Dashboard
          </DashButton>
        </Box>
      </Box>

      <Box display="flex" justifyContent="space-between" marginBottom="0">
        <EnhancedTable
          sort={sort}
          setSort={setSort}
          columns={dashboardColumns}
          rows={dashboards?.data?.dashboards}
          showCheckBox
          setSortBy={setSortBy}
          rowRender={renderRow}
          setChecked={setCheckedDashboards}
          checked={checkedDashboards}
          loading={loading}
          page={page}
          tab="dashboards"
          setPage={setPage}
          selected={selected}
          setSelected={setSelected}
          count={dashboards?.headers?.['x-pagination-totalitems']}
          rowsPerPage={rowsPerPage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Box>

      {loading && (
        <Box display="flex" justifyContent="center" marginTop={2}>
          <CircularProgress />
        </Box>
      )}

      <ModalPopUp open={open} setOpen={setOpen}>
        <CreateDashboard setDashboards={setDashboards} setOpen={setOpen} />
      </ModalPopUp>

      <ModalPopUp open={openDelete} setOpen={setOpenDelete}>
        <Typography style={{ fontSize: 20, fontWeight: 600, textAlign: 'center' }}>
          Are you sure you want to delete?
        </Typography>
        <Typography style={{ fontSize: 14, textAlign: 'center', marginBottom: '20px' }} variant="body2">
          This action can't be undone!
        </Typography>
        <Box display="flex" alignItems="center" justifyContent="center" marginTop="20px">
          <Button
            style={{ color: '#4b7bff' }}
            onClick={() => setOpenDelete(false)}
          >
            CANCEL
          </Button>
          <Button
            style={{ color: '#4b7bff' }}
            onClick={onDeleteDashboard}
          >
            {loadingDeleteDashboard ? <CircularProgress color="#fff" size="25px" /> : 'DELETE'}
          </Button>
        </Box>
      </ModalPopUp>
    </>
  );
}
