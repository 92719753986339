import { Box, Button, ListItemIcon, MenuItem, Typography, SvgIcon } from '@material-ui/core';
import React, { useState } from 'react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import PieChartIcon from '@material-ui/icons/PieChart';
import BarChartIcon from '@material-ui/icons/BarChart';
import GridOnIcon from '@material-ui/icons/GridOn';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import PropTypes from 'prop-types';

import PopperPortal from '../../../components/PopperPortal';

const AreaIcon = () => (
  <SvgIcon htmlColor="#fff">
    <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24">
      <rect fill="none" height="24" width="24" />
      <path d="M3,13v7h18v-1.5l-9-7L8,17L3,13z M3,7l4,3l5-7l5,4h4v8.97l-9.4-7.31l-3.98,5.48L3,10.44V7z" fill="#fff" />
    </svg>
  </SvgIcon>
);

export const chartsItems = [
  {
    text: 'Line',
    Icon: ShowChartIcon,
  },
  {
    text: 'Area',
    Icon: AreaIcon,
  },
  {
    text: 'Pie',
    Icon: PieChartIcon,
  },
  {
    text: 'Bar',
    Icon: BarChartIcon,
  },
  {
    text: 'Table',
    Icon: GridOnIcon,
  },
  {
    text: 'Number',
    Icon: InfoOutlinedIcon,
  },
];

export default function ContentCharts({ children, selectedChart, setSelected }) {
  const [open, setOpen] = useState(false);

  return (
    <Box
      width="100%"
      height="100%"
      maxHeight={700}
      borderRadius="4px"
      style={{ background: '#fff' }}
      border="1px solid #7070701F"
      padding="24px"
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography style={{ fontWeight: '500', color: '#fff', background: '#fff'  }}>
          {' '}
          {selectedChart.text === 'Table' ? 'Table View' : 'Chart View'}
        </Typography>
        <Box position="relative">
          <Button
            variant="contained"
            style={{
              background: '#fff',
              padding: 13,
              height: 40,
              display: 'none',
              cursor: 'pointer',
              boxShadow: 'none',
              color: '#fff',
              textTransform: 'capitalize',
              fontSize: 13,
            }}
            onClick={() => setOpen(true)}
          >
            <selectedChart.Icon fontSize="small" style={{ minWidth: 35, color: '#fff' }} />

            {selectedChart.text}
            <ArrowDropDownIcon htmlColor="#fff" />
          </Button>
          <PopperPortal open={open} setOpen={setOpen} left={0} top={43}>
            {chartsItems.map((chart, index) => (
              <MenuItem
                key={index}
                style={{ fontSize: 14 }}
                onClick={() => {
                  setSelected(chart);
                  setOpen(false);
                }}
              >
                <ListItemIcon style={{ minWidth: 30 }}>
                  <chart.Icon fontSize="small" style={{ color: '#fff' }} />
                </ListItemIcon>
                {chart.text}
              </MenuItem>
            ))}
          </PopperPortal>
        </Box>
      </Box>
      {children}
    </Box>
  );
}

ContentCharts.propTypes = {
  children: PropTypes.node.isRequired,
  selectedChart: PropTypes.string.isRequired,
  setSelected: PropTypes.func.isRequired,
};
