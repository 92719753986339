import { Grid, LinearProgress, Box } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Skeleton } from 'antd';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import ClearIcon from '@material-ui/icons/Delete';

import Tags from './Tags';
import ArchivedImage from './ArchivedImage';

export function bytesToMB(bytes) {
  return (bytes / (1024 * 1024)).toFixed(2);
}

const Attachment = ({
  loading,
  fileId,
  defaultTags,
  name,
  size,
  url,
  mimetype,
  withAddBtn = true,
  canRemoveTag = false,
  rightCreateTag = -235,
  positionCreateTag = 'top',
  sizeCreateTag = 'large',
  onClickDelete,
  onClickPreview,
  onClickAddTag,
  onClickRemoveTag,
  columnImageSize = 2,
  columnTextSize = 10,
  archived,
}) => {
  const sizeInMb = bytesToMB(size);

  return (
    <Container>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={10}>
          <Grid container alignItems="center">
            <Grid item xs={columnImageSize} onClick={onClickPreview}>
              <Box display="flex" justifyContent="flex-start">
                {loading ? (
                  <Skeleton.Image style={{ width: 71, height: 66, borderRadius: 2 }} />
                ) : (
                  <>
                    {archived ? (
                      <Box>
                        <ArchivedImage width={71} height={66} />
                      </Box>
                    ) : null}
                    {!archived && mimetype.includes('video') && (
                      <WrapIcon>
                        <VideoLibraryIcon htmlColor="#333" style={{ fontSize: 60 }} />
                      </WrapIcon>
                    )}
                    {!archived && mimetype.includes('pdf') && (
                      <WrapIcon>
                        <PictureAsPdfIcon htmlColor="#333" style={{ fontSize: 60 }} />
                      </WrapIcon>
                    )}
                    {!archived && mimetype.includes('image') && <Image src={url} />}
                  </>
                )}
              </Box>
            </Grid>
            <Grid item xs={columnTextSize}>
              {loading ? (
                <LinearProgress />
              ) : (
                <Box display="flex" justifyContent="flex-start" flexDirection="column">
                  <Name>{name}</Name>
                  {sizeInMb > 0 && <Size>{sizeInMb} mb</Size>}
                  <Tags
                    fileId={fileId}
                    sizeCreateTag={sizeCreateTag}
                    rightCreateTag={rightCreateTag}
                    positionCreateTag={positionCreateTag}
                    defaultTags={defaultTags}
                    withAddBtn={withAddBtn}
                    canRemove={canRemoveTag}
                    onClickAddTag={onClickAddTag}
                    onClickRemove={onClickRemoveTag}
                  />
                </Box>
              )}
            </Grid>
          </Grid>
        </Grid>
        {onClickDelete && (
          <Grid item xs={2}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <ClearIcon
                htmlColor="#707070"
                fontSize="medium"
                style={{ alignSelf: 'center', cursor: 'pointer' }}
                onClick={onClickDelete}
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

Attachment.propTypes = {
  loading: PropTypes.bool,
  fileId: PropTypes.number,
  defaultTags: PropTypes.arrayOf(PropTypes.string).isRequired,
  name: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
  mimetype: PropTypes.string.isRequired,
  withAddBtn: PropTypes.bool,
  canRemoveTag: PropTypes.bool,
  sizeCreateTag: PropTypes.string.isRequired,
  rightCreateTag: PropTypes.number,
  positionCreateTag: PropTypes.string.isRequired,
  onClickPreview: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  onClickAddTag: PropTypes.func.isRequired,
  onClickRemoveTag: PropTypes.func.isRequired,
  columnImageSize: PropTypes.number,
  columnTextSize: PropTypes.number,
};

const Container = styled.div`
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(217, 217, 217, 1);
`;

const Image = styled.img`
  width: 71px;
  height: 66px;
  border-radius: 4px;
  object-fit: contain;
  cursor: pointer;
`;

const WrapIcon = styled.div`
  width: 71px;
  height: 66px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const Name = styled.p`
  font-size: 14px;
  width: 300px;
  color: #000000de;
  margin-bottom: 3px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
`;

const Size = styled.p`
  font-size: 12px;
  color: #000000ad;
  text-align: left;
`;

export default Attachment;
