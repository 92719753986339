import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Typography, Divider, Checkbox } from '@material-ui/core';
import shortid from 'shortid';
import { toast } from 'react-toastify';

import DashButton from '../../components/DashButton';
import { useStylesProjectTypesSettings } from '../ProjectsTypes/ProjectTypes';
import TypeItem from '../ProjectsTypes/TypeItem';
import AddMore from '../../components/AddMore';
import { onUpdateOrganization } from '../../services/unarmed';

const overviewTemplate = {
  actionName: 'Template Name',
  overviewFeatures: [
    {
      label: 'Type and Subtype:',
      visible: false,
      required: false,
      key: 'typeSubtype',
    },
    {
      label: 'Due date:',
      visible: false,
      required: false,
      key: 'times',
    },
    {
      label: 'Notes:',
      visible: false,
      required: false,
      key: 'notes',
    },
    {
      label: 'Assigned To:',
      visible: false,
      required: false,
      key: 'assignedTo',
    },
    {
      label: 'Attachments:',
      visible: false,
      required: false,
      key: 'attachments',
    },
  ],
};

/**
 * TasksTypes
 *
 * task type settings.
 *
 * @param {object} organization
 * @param {function} setRefetch
 */
const TasksTypes = ({ organization, setRefetch }) => {
  const [loading, setLoading] = useState(false);
  const [types, setTypes] = useState([]);

  const [template, setTemplate] = useState(overviewTemplate);

  const classes = useStylesProjectTypesSettings();

  /**
   * @description useEffect to set types and template from organization data.
   */
  useEffect(() => {
    if (organization && organization?.task) {
      setTypes(
        organization?.task?.types?.map((type) => ({
          ...type,
          id: shortid.generate(),
          subTypes: type?.subTypes?.map((subType) => ({
            subtype: subType,
            id: shortid.generate(),
          })),
        }))
      );
      setTemplate({
        overviewFeatures: [
          {
            label: 'Type And Subtype:',
            visible: organization?.task?.settings?.typeSubtype?.visible || false,
            required: organization?.task?.settings?.typeSubtype?.required || false,
            key: 'typeSubtype',
          },
          {
            label: 'Due Date:',
            visible: organization?.task?.settings?.times?.visible || false,
            required: organization?.task?.settings?.times?.required || false,
            key: 'times',
          },
          {
            label: 'Notes:',
            visible: organization?.task?.settings?.notes?.visible || false,
            required: organization?.task?.settings?.notes?.required || false,
            key: 'notes',
          },
          {
            label: 'Assigned To:',
            visible: organization?.task?.settings?.assignedTo?.visible || false,
            required: organization?.task?.settings?.assignedTo?.required || false,
            key: 'assignedTo',
          },
          {
            label: 'Attachments:',
            visible: organization?.task?.settings?.attachments?.visible || false,
            required: organization?.task?.settings?.attachments?.required || false,
            key: 'attachments',
          },
        ],
      });
    }
  }, [organization]);

  /**
   * @description function to save the task types and settings.
   */
  const handleSave = async () => {
    setLoading(true);
    try {
      const data = {
        task: {
          types: types.map((type) => ({
            type: type.type,
            subTypes: type.subTypes.map((subType) => subType.subtype),
          })),
          settings: {
            typeSubtype: {
              required: template?.overviewFeatures?.[0].required,
              visible: template?.overviewFeatures?.[0].visible,
            },
            times: {
              required: template?.overviewFeatures?.[1].required,
              visible: template?.overviewFeatures?.[1].visible,
            },
            notes: {
              required: template?.overviewFeatures?.[2].required,
              visible: template?.overviewFeatures?.[2].visible,
            },
            assignedTo: {
              required: template?.overviewFeatures?.[3].required,
              visible: template?.overviewFeatures?.[3].visible,
            },
            attachments: {
              required: template?.overviewFeatures?.[4].required,
              visible: template?.overviewFeatures?.[4].visible,
            },
          },
        },
      };
      await onUpdateOrganization(organization?._id, data);
      setRefetch(true);
      toast.success('Updated task types successfully');
    } catch (error) {
      console.dir(error);
      toast.error(
        error?.response?.data?.error?.details?.[0]?.message ||
          error?.response?.data?.message ||
          'Cannot update task types'
      );
    } finally {
      setLoading(false);
    }
  };

  /**
   * @description function to handle the changes on types and sub-types.
   * @param {object} event
   * @param {string} action
   * @param {number} typeIndex
   * @param {number} subTypeIndex
   * @param {object} state
   * @param {function} setState
   */
  const handleChange = (event, action, typeIndex, subTypeIndex, state, setState) => {
    const { value } = event.target;
    const typesCopy = [...state];
    if (action === 'type') {
      typesCopy[typeIndex].type = value;
      setState(typesCopy);
    } else if (action === 'subType') {
      const subTypeCopy = [...typesCopy[typeIndex].subTypes];
      subTypeCopy[subTypeIndex].subtype = value;
      setState(typesCopy);
    }
  };

  /**
   * @description function to handle delete type and sub-type.
   * @param {object} action
   * @param {number} typeIndex
   * @param {number} subTypeIndex
   * @param {object} state
   * @param {function} setState
   */
  const handleDelete = (action, typeIndex, subTypeIndex, state, setState) => {
    switch (action) {
      case 'type': {
        const typesCopy = [...state];
        typesCopy.splice(typeIndex, 1);
        setState(typesCopy);
        break;
      }
      case 'SubType': {
        const typesCopy = [...state];
        const subTypesCopy = [...typesCopy[typeIndex].subTypes];
        subTypesCopy.splice(subTypeIndex, 1);
        typesCopy[typeIndex].subTypes = subTypesCopy;
        setState(typesCopy);
        break;
      }
      default: {
        const typesCopy = [...state];
        typesCopy.splice(typeIndex, 1);
        setState(typesCopy);
      }
    }
  };

  /**
   * @description function to handle add type and sub-types.
   * @param {string} action
   * @param {number} index
   * @param {object} state
   * @param {function} setState
   */
  const handleAdd = (action, index, state, setState) => {
    switch (action) {
      case 'type': {
        const newType = {
          type: '',
          id: shortid.generate(),
          subTypes: [],
        };
        setState([...state, newType]);
        break;
      }
      case 'SubType': {
        const newSubtype = {
          id: shortid.generate(),
          subType: '',
        };
        const typesCopy = [...state];
        const subTypes = [...typesCopy[index].subTypes];
        typesCopy[index].subTypes = [...subTypes, newSubtype];
        setState(typesCopy);
        break;
      }
      default: {
        const newType = {
          type: '',
          id: shortid.generate(),
          subTypes: [],
        };
        setState([...state, newType]);
        break;
      }
    }
  };

  /**
   * @description function to handle change features.
   * @param {object} e
   * @param {object} prop
   * @param {object} subProp
   */
  const onChangeFeatures = (e, prop, subProp) => {
    const { checked } = e.target;
    const features = [...template.overviewFeatures];
    const feature = { ...features.find((ft) => ft.label === prop) };
    const featureIndex = features.findIndex((ft) => ft.label === prop);
    if (subProp === 'visible' && !checked) {
      feature[subProp] = checked;
      feature.required = false;
    } else {
      feature[subProp] = checked;
    }

    features[featureIndex] = feature;

    setTemplate((prevState) => ({
      ...prevState,
      overviewFeatures: features,
    }));
  };

  return (
    <>
      <Box className={classes.container}>
        <Box display="flex" justifyContent="flex-end" alignItems="center" width="100%">
          <DashButton onClick={handleSave}>
            {loading ? <CircularProgress color="#fff" size="20px" /> : 'Save'}{' '}
          </DashButton>
        </Box>
        <Box className={classes.typesContainer}>
          <Typography className={classes.title}>Task Types</Typography>
          <Divider style={{ marginBottom: 20 }} />
          <Box className={classes.types}>
            {types.map((type, typeIndex) => (
              <Box key={type.id} display="flex" flexDirection="column" alignItems="flex-end" marginBottom={2}>
                <TypeItem
                  placeholder={`Type ${typeIndex + 1}`}
                  value={type.type}
                  onChange={(e) => handleChange(e, 'type', typeIndex, null, types, setTypes)}
                  onClick={() => handleDelete('type', typeIndex, null, types, setTypes)}
                />
                <Box marginTop={type.subTypes?.length ? 2 : 1} width={400} alignSelf="flex-end" marginBottom={1}>
                  {type.subTypes?.map((subtype, subTypeIndex) => (
                    <TypeItem
                      placeholder={`Subtype ${subTypeIndex + 1}`}
                      key={subtype.id}
                      value={subtype.subtype}
                      onChange={(e) => handleChange(e, 'subType', typeIndex, subTypeIndex, types, setTypes)}
                      marginBottom={2}
                      onClick={() => handleDelete('SubType', typeIndex, subTypeIndex, types, setTypes)}
                    />
                  ))}
                  <AddMore
                    text="New Subtype"
                    marginLeft={type.subTypes?.length ? '0' : '-50px'}
                    marginTop="0"
                    onClick={() => handleAdd('SubType', typeIndex, types, setTypes)}
                  />
                </Box>
              </Box>
            ))}
            <Divider style={{ marginBottom: 10 }} />
            <Box display="flex" alignItems="center" justifyContent="center">
              <AddMore
                text="New Type"
                marginLeft="0"
                marginTop="0"
                onClick={() => handleAdd('type', null, types, setTypes)}
              />
            </Box>
          </Box>
        </Box>
        <Box className={classes.typesContainer}>
          <Typography className={classes.title}>Task Settings</Typography>
          <Divider style={{ marginBottom: 20 }} />
          <Box className={classes.types}>
            <Box className={classes.itemContainer}>
              <Box className={classes.item}>
                <Typography className={classes.names} />
                <Typography className={classes.columnText}>Visible</Typography>
                <Typography className={[classes.actionContainer, classes.columnText]}>Required</Typography>
              </Box>
              {template?.overviewFeatures?.map((item, actionIndex) => (
                <Box className={classes.item} key={`actionProp-${actionIndex}`}>
                  <Typography className={classes.names}>{item.label}</Typography>
                  <Checkbox
                    color="primary"
                    checked={item.visible}
                    onChange={(e) => onChangeFeatures(e, item.label, 'visible')}
                  />
                  <Checkbox
                    color="primary"
                    checked={item.required}
                    style={{ marginLeft: 95 }}
                    disabled={!item?.visible}
                    onChange={(e) => onChangeFeatures(e, item.label, 'required')}
                  />
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default TasksTypes;
