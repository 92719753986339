import React from 'react';

import { Badge, Button } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';

const FilterButton = ({ count, dataTestId, onClick, openFilter, marginRight, marginLeft }) => (
  <div style={{ marginRight, marginLeft }}>
    <Badge badgeContent={count} color="secondary">
      <Button
        data-testid={dataTestId}
        variant="contained"
        style={{
          background: openFilter ? '#fff' : '#1F63FF',
          border: openFilter ? '1px solid #1F63FF' : 'unset',
          cursor: 'pointer',
          minWidth: 36,
          maxWidth: 36,
          height: 36,
          padding: 6,
        }}
        onClick={onClick}
      >
        <FilterListIcon htmlColor={openFilter ? '#1F63FF' : '#fff'} />
      </Button>
    </Badge>
  </div>
);

export default FilterButton;
