import { useState, useEffect } from 'react';

/**
 * Custom hook to manage the status of building schema for an organization.
 *
 * @param {Object} organization - The organization object containing schema details.
 * @param {string} organization.buildingSchemaEndDate - The end date of the building schema process as a string.
 *
 * @returns {Array} - An array with a single boolean value indicating if cubes have been built.
 */
const useBuildingSchemaStatus = (organization) => {
  const [cubesBuilded, setCubesBuilded] = useState(false);
  const [buildingSchemaEndDate, setBuildingSchemaEndDate] = useState(null);

  useEffect(() => {
    if (organization && organization.buildingSchemaEndDate) {
      setBuildingSchemaEndDate(new Date(organization.buildingSchemaEndDate));
    } else {
      setCubesBuilded(true);
    }
  }, [organization]);

  useEffect(() => {
    let timeoutId;

    if (buildingSchemaEndDate) {
      const timeUntilBuildingSchemaEnds = buildingSchemaEndDate.getTime() - Date.now();

      console.log(timeUntilBuildingSchemaEnds);

      if (timeUntilBuildingSchemaEnds > 0) {
        timeoutId = setTimeout(() => {
          setCubesBuilded(true);
        }, timeUntilBuildingSchemaEnds);
      } else {
        setCubesBuilded(true);
      }
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [buildingSchemaEndDate]);

  return [cubesBuilded];
};

export default useBuildingSchemaStatus;
