import React, { useState, useEffect } from 'react';
import { Box, FormControl, InputLabel, Select, Typography, makeStyles, Grid, TextField, MenuItem } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import FilterNoneIcon from '@material-ui/icons/FilterNone';

import { useStylesStaticWrapper } from './StaticWrapper';
import SelectBox from '../../../components/SelectBox';
import useOrganization from '../../../hooks/useOrganization';
import AssignUser from '../../../components/AssignUser';
import Input from '../../../components/Input';

const useStyles = makeStyles(() => ({
  title: {
    fontSize: 14,
    color: '#000000DE',
  },
  label: {
    fontSize: 16,
    color: '#000000DE',
    fontWeight: '500',
  },
}));

/**
 * FormTaskBoxEdit
 *
 * Component for each task on the form type.
 *
 * @param {object} task
 * @param {number} componentIndex
 * @param {function} onClickDelete
 * @param {function} onChange
 * @param {function} onClickDuplicate
 */
const FormTaskBoxEdit = ({ task, users, componentIndex, onClickDelete, onChange, onClickDuplicate }) => {
  const [name, setName] = useState(task?.name);
  const [typeId, setTypeId] = useState(task?.values?.type);
  const [subTypeId, setSubTypeId] = useState(task?.values?.subType);
  const [subTypes, setSubTypes] = useState([]);
  const [dueDays, setDueDays] = useState(task?.values?.dueDays);
  const [assignedUsers, setAssignedUsers] = useState(task?.values?.assignedTo || []);

  const { organization } = useOrganization();
  const classesWrapper = useStylesStaticWrapper();
  const classes = useStyles();

  const showTypeAndSubtype = task?.settings
    ? task?.settings?.typeSubtype?.visible
    : organization?.task?.settings?.typeSubtype?.visible;

  const availableTypeSubtypes = task?.availableTypeSubtype ? task.availableTypeSubtype : organization?.task?.types;

  /**
   * @description useEffect for set data from task.
   */
  useEffect(() => {
    if (task?.name !== name) {
      setName(task?.name);
      setTypeId(task?.values?.type);
      setSubTypeId(task?.values?.subType);
      setDueDays(task?.values?.dueDays || 0);
      setAssignedUsers(task?.values?.assignedTo || []);
    }
  }, [task]);

  /**
   * @description useEffect for handle on change from task fields.
   */
  useEffect(() => {
    onChange(componentIndex, {
      name,
      values: {
        type: typeId,
        ...(subTypeId !== '' ? { subType: subTypeId } : { subType: null }),
        dueDays,
        assignedTo: assignedUsers.map((user) => user?._id),
      },
    });
  }, [name, typeId, subTypeId, dueDays, assignedUsers]);

  /**
   * @description useEffect for set sub-types from task availableTypeSubtypes.
   */
  useEffect(() => {
    if (typeId) {
      const type = availableTypeSubtypes?.find((type) => type.type === typeId);

      if (type) {
        setSubTypes(type.subTypes);

        if (type?.subTypes?.indexOf(subTypeId) === -1) {
          setSubTypeId('');
        }
      }
    }
  }, [typeId, subTypeId, availableTypeSubtypes]);

  const onDeleteUser = async (user) => {
    const newUsers = assignedUsers.filter((_user) => _user?._id !== user?._id);

    setAssignedUsers(newUsers);
  };

  const onAssignUser = async (usersArr) => {
    setAssignedUsers(usersArr);
  };

  const onChangeDueDays = (value) => {
    setDueDays(value);
  }

  return (
    <div className={classesWrapper.dndContainer} style={{ marginBottom: 16, width: '100%' }}>
      <Box style={{ padding: '0 21px', width: '100%' }}>
        <Box className={classesWrapper.container}>
          <Box
            sx={{ padding: '11px 21px' }}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            className={classesWrapper.header}
          >
            <Box>Task {componentIndex + 1}</Box>
            <Box display="flex" alignItems="end">
              <FilterNoneIcon
                onClick={onClickDuplicate}
                style={{ fontSize: 16, color: '#6D7385', cursor: 'pointer', marginRight: 8 }}
              />
              <DeleteIcon
                onClick={() => onClickDelete && onClickDelete(componentIndex)}
                style={{ fontSize: 19, color: '#6D7385', cursor: 'pointer' }}
              />
            </Box>
          </Box>
          <Box className={classesWrapper.body}>
            <Grid container style={{ padding: 21 }}>
              <Grid item xs={2}>
                <Box display="flex" alignItems="center" mt={1}>
                  <Typography className={classes.label}>T</Typography>
                  <ArrowRightAltIcon style={{ fontSize: 14, marginLeft: 14 }} />
                </Box>
              </Grid>
              <Grid item xs={10}>
                <Input
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Enter Task name"
                  style={{ width: '100%', marginBottom: 16 }}
                  value={name}
                />
                {showTypeAndSubtype && (
                  <Grid container spacing={2} style={{ marginBottom: 8 }}>
                    <Grid item xs={4}>
                      <SelectBox
                        id="type"
                        placeholderText="Select Type"
                        options={{
                          obj:
                            availableTypeSubtypes?.map((type) => ({
                              text: type.type,
                              value: type.type,
                            })) || [],
                        }}
                        value={typeId || ''}
                        onChange={(e) => setTypeId(e.target.value)}
                        outlined
                        allowPlaceHolder={false}
                        showDefaultValue=""
                        withColors={{
                          color: '#2E66FE',
                          bg: '#2E66FE1A',
                          focus: '#2E66FE1A',
                        }}
                      />
                    </Grid>
                    <Grid item xs={2} />
                    <Grid item xs={4}>
                      <SelectBox
                        id="type"
                        placeholderText="Select Subtype"
                        options={{
                          obj:
                            subTypes?.map((subType) => ({
                              text: subType,
                              value: subType,
                            })) || [],
                        }}
                        value={subTypeId || ''}
                        onChange={(e) => setSubTypeId(e.target.value)}
                        outlined
                        allowPlaceHolder={false}
                        showDefaultValue=""
                        withColors={{
                          color: '#2E66FE',
                          bg: '#2E66FE1A',
                          focus: '#2E66FE1A',
                        }}
                      />
                    </Grid>
                    <Grid item xs={2} />
                  </Grid>
                )}
                <Grid item xs={10} style={{ marginBottom: 16 }}>
                  <Box display="flex" style={{ width: '100%', gap: 4 }}>
                    <FormControl style={{ width: '100%' }}>
                      <InputLabel id="label-input-id-due-date" >Due Date</InputLabel>
                      <Select
                        labelId="label-input-id-due-date"
                        label="Due Date"
                        style={{ width: '100%' }}
                        value={dueDays}
                        onChange={(e) => onChangeDueDays(e.target.value)}
                      >
                        <MenuItem value={0}>None</MenuItem>
                        <MenuItem value={1}>1 Day</MenuItem>
                        <MenuItem value={3}>3 Days</MenuItem>
                        <MenuItem value={5}>5 Days</MenuItem>
                        <MenuItem value={7}>7 Days</MenuItem>
                        <MenuItem value={10}>10 Days</MenuItem>
                        <MenuItem value={15}>15 Days</MenuItem>
                        <MenuItem value={30}>30 Days</MenuItem>
                        <MenuItem value={45}>45 Days</MenuItem>
                        <MenuItem value={60}>60 Days</MenuItem>
                        <MenuItem value={90}>90 Days</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={12} style={{ marginBottom: 8 }}>
                  <Box display="flex" position="relative">
                    <AssignUser
                      assignedUsers={assignedUsers.length > 0 ? [...assignedUsers, {}] : [{}]}
                      defaultUsers={users}
                      setAssignedUsers={setAssignedUsers}
                      onAssignUser={onAssignUser}
                      loadingRemoveUser={false}
                      onDeleteUser={onDeleteUser}
                      alignX={0}
                      alignKey="left"
                      textAlign="left"
                      flexWrap="wrap"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default FormTaskBoxEdit;
