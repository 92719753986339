/* eslint-disable no-nested-ternary */
import { Box } from '@material-ui/core';
import { ResultSet } from '@cubejs-client/core';
import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { fetchCube, getCubes, saveCube } from '../../services/cube';
import ReportContent from './ReportContent';
import ReportPanel, { searchFilterOperator } from './ReportPanel';
import { formatCubeJsTableColumns } from '../../utils';
import { searchFilterItem } from '../../components/ReportFilterItem';
import useOrganization from '../../hooks/useOrganization';

export const dataRangeOptions = [
  'Custom',
  'All time',
  'Today',
  'Yesterday',
  'This week',
  'This month',
  'This quarter',
  'This year',
  'Last 7 days',
  'Last 30 days',
  'Last 60 days',
  'Last week',
  'Last month',
  'Last quarter',
  'Last year',
];

export const dayOption = ['w/o grouping', 'minute', 'hour', 'day', 'week', 'month', 'year'];

const getFilters = (filters) => {
  const fitersMapped = filters.map((filter) => ({
    ...(filter.member ? { member: filter?.member?.name } : {}),
    ...(filter?.operator ? { operator: filter?.operator?.value } : {}),
    ...(filter.values
      ? {
          values: Array.isArray(filter?.values) ? filter?.values : [filter?.values],
        }
      : {}),
  }));
  return fitersMapped.filter((filter) => Object.keys(filter).length > 0);
};

export default function ReportProfile() {
  const [dimensions, setDimensions] = useState([
    { label: 'Add dimension', value: null, id: 'dimension1', order: 1 },
    { label: 'Add dimension', value: null, id: 'dimension2', order: 2 },
    { label: 'Add dimension', value: null, id: 'dimension3', order: 3 },
  ]);

  const [measures, setMeasures] = useState([
    { label: 'Add measure', value: null, id: 'measure1', order: 1 },
    { label: 'Add measure', value: null, id: 'measure2', order: 2 },
    { label: 'Add measure', value: null, id: 'measure3', order: 3 },
  ]);

  const [filters, setFilters] = useState([{ member: null, operator: null, values: null }]);

  const [dimensionsTime, setDimensionsTime] = useState({
    label: 'Add time dimension',
    value: null,
  });
  const { id } = useParams();
  const { organization } = useOrganization();

  const [daterange, setDaterange] = useState(dataRangeOptions[0]);
  const [granularity, setGranularity] = useState(dayOption[4]);

  const [loading, setLoading] = useState(false);

  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);

  const [reports, setReports] = useState(null);

  const [cubeQuery, setCubeQuery] = useState({
    measures: [],
    timeDimensions: [],
    order: [],
    dimensions: [],
    filters: [],
    limit: 2500,
  });

  const [report, setReport] = useState(null);
  const [cubes, setCubes] = useState([]);

  const onGetCubes = () => {
    getCubes()
      .then(({ data }) => {
        setCubes(data?.cubes);
      })
      .catch((err) => console.log(err));
  };

  const onGetCube = useCallback(() => {
    if (id) {
      setLoading(true);
      fetchCube(id)
        .then(({ data }) => {
          setReport(data);
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false));
    }
  }, [id]);

  useEffect(() => {
    if (organization && !organization?.buildingSchemaEndDate) {
      onGetCubes();
    }
  }, [organization]);

  useEffect(() => {
    if (organization && !organization?.buildingSchemaEndDate) {
      onGetCube();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, organization]);

  useEffect(() => {
    if (cubes.length && report) {
      const query = report?.query;

      if (!query) {
        return;
      }

      const QueryDimensions = query?.dimensions;
      const Querymeasures = query?.measures;
      const QueryTimeDimensions = query?.timeDimensions?.[0];

      const getFetchQuery = (arrQuery, key) => {
        const dd = arrQuery?.map((qd) => {
          const splited = qd.split('.')[0];

          const colected = [];
          cubes.forEach((cb) => {
            if (cb.name === splited) {
              cb[key].forEach((itemSh) => {
                if (itemSh.name === qd) {
                  colected.push(itemSh);
                }
              });
            }
          });

          return colected[0];
        });

        return dd;
      };
      const getFetchTimeQuery = () => {
        const splited = QueryTimeDimensions?.dimension?.split('.')[0];

        const colected = [];
        cubes.forEach((cb) => {
          if (cb.name === splited) {
            cb.dimensions.forEach((itemSh) => {
              if (itemSh.name === QueryTimeDimensions?.dimension) {
                colected.push(itemSh);
              }
            });
          }
        });

        return colected[0];
      };
      const dimensionsFetched = getFetchQuery(QueryDimensions, 'dimensions');
      const measuresFetched = getFetchQuery(Querymeasures, 'measures');
      const timeDimensionFetched = getFetchTimeQuery();

      const filtersFetched = query?.filters?.map((filter) => {
        const member = searchFilterItem(filter?.member, cubes);
        const operator = searchFilterOperator(filter?.operator, member?.type);

        return {
          member,
          operator,
          values: filter?.values?.length > 1 ? filter?.values : filter?.values?.[0] || null,
        };
      });

      let dimensionsItems = [];
      let measuresItems = [];
      let timeDimensionsItems = null;

      if (dimensionsFetched?.length) {
        const dimensionsFetchedMapped = dimensionsFetched.map((dimension, index) => ({
          label: 'Add dimension',
          id: `dimension${index + 1}`,
          order: index + 1,
          value: dimension,
        }));
        dimensionsItems = dimensionsFetchedMapped.filter((dimension) => dimension.value);
        setDimensions(dimensionsFetchedMapped);
      }

      if (filtersFetched?.length) {
        setFilters(filtersFetched);
      }
      if (measuresFetched?.length) {
        const measuresFetchedMapped = measuresFetched.map((measure, index) => ({
          label: 'Add measure',
          value: measure,
          id: `measure${index + 1}`,
          order: index + 1,
        }));
        measuresItems = measuresFetchedMapped.filter((measure) => measure.value);
        setMeasures(measuresFetchedMapped);
      }

      if (timeDimensionFetched) {
        const dimensionTimeObj = {
          ...dimensionsTime,
          value: timeDimensionFetched,
        };

        setDimensionsTime(dimensionTimeObj);
        timeDimensionsItems = dimensionTimeObj.value;
        setGranularity(QueryTimeDimensions?.granularity);
        if (typeof QueryTimeDimensions?.dateRange === 'string') {
          setDaterange(QueryTimeDimensions?.dateRange);
        } else {
          setFrom(QueryTimeDimensions?.dateRange?.[0]);
          setTo(QueryTimeDimensions?.dateRange?.[1]);
        }
      }

      setReports({
        data: report?.resultSet ? ResultSet.deserialize(report?.resultSet)?.tablePivot() : null,
        columns: report?.resultSet
          ? ResultSet.deserialize(report?.resultSet)
              ?.tableColumns()
              ?.map((column) => ({
                ...column,
                title: formatCubeJsTableColumns(column.title, column.key),
              }))
          : null,

        resultSet: report?.resultSet ? ResultSet.deserialize(report?.resultSet) : null,
      });

      const currentDimensions = dimensionsItems.map((dimItem) => dimItem.value.name);

      const currentMeasures = measuresItems.map((meaItem) => meaItem.value.name);

      const currentTimeDimensions = [];

      if (timeDimensionsItems) {
        currentTimeDimensions.push({
          ...(timeDimensionsItems && { dimension: timeDimensionsItems?.name }),
          ...(QueryTimeDimensions?.granularity === 'w/o grouping'
            ? {}
            : { granularity: QueryTimeDimensions?.granularity?.toLowerCase() }),
          ...(QueryTimeDimensions?.dateRange === 'All time'
            ? {}
            : QueryTimeDimensions?.dateRange === 'Custom'
            ? from && to
              ? {
                  dateRange: [moment(from).format('YYYY-MM-DD'), moment(to).format('YYYY-MM-DD')],
                }
              : {}
            : {
                dateRange: QueryTimeDimensions?.dateRange,
              }),
        });
      }

      if (currentDimensions.length > 0 || currentMeasures.length > 0 || timeDimensionsItems) {
        setCubeQuery({
          ...cubeQuery,
          dimensions: currentDimensions,
          measures: currentMeasures,
          timeDimensions: currentTimeDimensions,
          filters: getFilters(filtersFetched),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cubes, report]);

  const onResetFilter = (index) => {
    setFilters((prevState) => {
      const newFilters = [...prevState];
      newFilters[index] = {
        member: null,
        operator: null,
        values: null,
      };
      return newFilters;
    });
  };

  const onSetFilter = (index, prop, value) => {
    const newFilters = [...filters];
    newFilters[index][prop] = value;
    setFilters(newFilters);
  };

  const addFilter = () => {
    setFilters([
      ...filters,
      {
        member: null,
        operator: null,
        values: null,
      },
    ]);
  };

  const onClearReport = () => {
    setCubeQuery({
      measures: [],
      timeDimensions: [],
      order: [],
      dimensions: [],
      filters: [],
      limit: 2500,
    });
    setReports(null);
    setDimensions([
      { label: 'Add dimension', value: null, id: 'dimension1', order: 1 },
      { label: 'Add dimension', value: null, id: 'dimension2', order: 2 },
      { label: 'Add dimension', value: null, id: 'dimension3', order: 3 },
    ]);
    setMeasures([
      { label: 'Add measure', value: null, id: 'measure1', order: 1 },
      { label: 'Add measure', value: null, id: 'measure2', order: 2 },
      { label: 'Add measure', value: null, id: 'measure3', order: 3 },
    ]);

    setDimensionsTime({
      label: 'Add time dimension',
      value: null,
    });
    setFilters([{ member: null, operator: null, values: null }]);
  };

  const buildQuery = () => {
    const dimensionsItems = dimensions.filter((dimension) => dimension.value);
    const measuresItems = measures.filter((measure) => measure.value);
    const timeDimensionsItems = dimensionsTime.value;

    console.log('Filtered dimensions:', dimensionsItems);
    const currentDimensions = dimensionsItems.map((dimItem) => dimItem.value.name);

    const currentMeasures = measuresItems.map((meaItem) => meaItem.value.name);

    const currentTimeDimensions = [];

    if (timeDimensionsItems) {
      currentTimeDimensions.push({
        ...(timeDimensionsItems && { dimension: timeDimensionsItems?.name }),
        ...(granularity === 'w/o grouping' ? {} : { granularity: granularity?.toLowerCase() }),
        ...(daterange === 'All time'
          ? {}
          : daterange === 'Custom'
          ? from && to
            ? {
                dateRange: [moment(from).format('YYYY-MM-DD'), moment(to).format('YYYY-MM-DD')],
              }
            : {}
          : {
              dateRange: daterange,
            }),
      });
    }

    if (currentDimensions.length > 0 || currentMeasures.length > 0 || timeDimensionsItems) {
      setCubeQuery({
        ...cubeQuery,
        dimensions: currentDimensions,
        measures: currentMeasures,
        timeDimensions: currentTimeDimensions,
        filters: getFilters(filters),
      });
      setLoading(true);

      console.log('cubequery:', cubeQuery);

      saveCube(id, {
        query: {
          ...cubeQuery,
          dimensions: currentDimensions,
          measures: currentMeasures,
          timeDimensions: currentTimeDimensions,
          filters: getFilters(filters),
        },
        preview: true,
      })
        .then(({ data }) => {
          setReports({
            data: ResultSet.deserialize(data?.resultSet)?.tablePivot(),
            columns: ResultSet.deserialize(data?.resultSet)
              ?.tableColumns()
              ?.map((column) => ({
                ...column,
                title: formatCubeJsTableColumns(column.title, column.key),
              })),

            resultSet: ResultSet.deserialize(data?.resultSet),
          });
        })
        .catch((err) => {
          if (err === 'Network Error') {
            toast.error('Something went wrong with the Report, please try again later');
          }
        })
        .finally(() => setLoading(false));
    }
  };

  const prepareReport = () => {
    const dimensionsItems = dimensions.filter((dimension) => dimension.value);
    const measuresItems = measures.filter((measure) => measure.value);
    const timeDimensionsItems = dimensionsTime.value;

    const currentDimensions = dimensionsItems.map((dimItem) => dimItem.value.name);

    const currentMeasures = measuresItems.map((meaItem) => meaItem.value.name);

    const currentTimeDimensions = [];

    if (timeDimensionsItems) {
      currentTimeDimensions.push({
        ...(timeDimensionsItems && { dimension: timeDimensionsItems?.name }),
        ...(granularity === 'w/o grouping' ? {} : { granularity: granularity?.toLowerCase() }),
        ...(daterange === 'All time'
          ? {}
          : daterange === 'Custom'
          ? from && to
            ? {
                dateRange: [moment(from).format('YYYY-MM-DD'), moment(to).format('YYYY-MM-DD')],
              }
            : {}
          : {
              dateRange: daterange,
            }),
      });
    }
    return {
      dimensions: currentDimensions,
      measures: currentMeasures,
      timeDimensions: currentTimeDimensions,
      filters: getFilters(filters),
    };
  };

  return (
    <Box display="flex">
      <>
        <ReportPanel
          dimensions={dimensions}
          setDimensions={setDimensions}
          measures={measures}
          setMeasures={setMeasures}
          setDimensionsTime={setDimensionsTime}
          dimensionsTime={dimensionsTime}
          daterange={daterange}
          setDaterange={setDaterange}
          granularity={granularity}
          setGranularity={setGranularity}
          from={from}
          setFrom={setFrom}
          setTo={setTo}
          to={to}
          cubes={cubes}
          filters={filters}
          setFilters={onSetFilter}
          addFilter={addFilter}
          onResetFilter={onResetFilter}
          buildQuery={buildQuery}
          loading={loading}
        />

        <ReportContent
          resultSet={reports?.resultSet ? reports?.resultSet : null}
          reports={reports}
          report={report}
          handleRefetch={() => {
            onGetCube();
            onGetCubes();
          }}
          reportId={id}
          cubeQuery={cubeQuery}
          prepareReport={prepareReport}
          onClearReport={onClearReport}
          loading={loading}
        />
      </>
    </Box>
  );
}
