import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import { getOfficerActivity } from '../../services/unarmed';
import HistoryLog from './components/HistoryLog';

export default function HistoryTab({ id }) {
  const [logs, setLogs] = useState([]);
  useEffect(() => {
    const getHistory = async () => {
      try {
        const { data } = await getOfficerActivity(id);
        setLogs(data);
      } catch (error) {
        toast.error(error?.message);
      }
    };
    if (id) {
      getHistory();
    }
  }, [id]);

  return (
    <Box
      style={{ background: '#fff', borderRadius: 5 }}
      boxShadow="0 3px 6px #00000005"
      marginTop={2}
      width="100%"
      height="fit-content"
      padding="10px"
    >
      {logs.map((log, index) => (
        <HistoryLog key={index} data={log.data} type={log.type} createdAt={log.createdAt} />
      ))}
    </Box>
  );
}

HistoryTab.propTypes = {
  id: PropTypes.number.isRequired,
};
