import { Box, CircularProgress, makeStyles, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { Waypoint } from 'react-waypoint';
import FileDownload from 'js-file-download';
import { toast } from 'react-toastify';
import UpgradeModal from '../../components/UpgradeModal';
import useOrganization from '../../hooks/useOrganization';
import TimeLine from './TImeLine';
import { Context } from '../../Context';
import ActivityFilter from './ActivityFilter';
import useInput from '../../hooks/useInput';
import { getActivity, onGetActivityToExport } from '../../services/unarmed';
import useNearScreen from '../../hooks/userNearScreen';
import FilterButton from '../../components/FilterButton';
import DashButton from '../../components/DashButton';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 20,
    width: '100%',

    '& .makeStyles-root-51': {
      boxShadow: 'unset !important',
    },
  },
  button: {
    background: ' #1F63FF',
    textTransform: 'capitalize',
    color: '#fff',
    '&:hover': {
      background: '#1F63FF',
    },
    '& .MuiButton-label': {
      fontSize: 14,
    },
    '&:disabled': {
      background: '#1f63ff61',
      color: '#fff',
    },
  },
  buttonOutlined: {
    letterSpacing: '1px',
    fontSize: 13,
    fontWeight: '500',
    marginRight: 10,
    width: 'fit-content',
    alignSelf: 'flex-end',
    border: '1px solid transparent',
    color: '#1F63FF',
    '&:hover': {
      background: 'transparent',
      border: '1px solid transparent',
    },
    '& .MuiButton-label': {
      fontSize: 13,
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: '#fff',
    boxShadow: theme.shadows[5],
    padding: 30,
    maxWidth: 445,
    outline: 0,
  },
}));

const options = [
  // {
  //   text: 'All',
  //   value: 'all',
  // },
  {
    text: 'Allegations',
    value: 'allegations',
  },
  {
    text: 'Cases',
    value: 'cases',
  },
  {
    text: 'Import',
    value: 'import',
  },
  {
    text: 'Officers',
    value: 'officers',
  },
  // { text: 'Usage', value: 'usage' },
  {
    text: 'Users',
    value: 'users',
  },
];

export const onGetDateLabels = (itm, setterData) => {
  if (moment(itm.createdAt).format('MM-DD-YYYY') === moment().format('MM-DD-YYYY')) {
    if (setterData.Today?.length > 0) {
      setterData.Today = [...setterData.Today, itm];
    } else {
      setterData.Today = [itm];
    }
  } else if (moment(itm.createdAt).format('MM-DD-YYYY') === moment().subtract(1, 'days').format('MM-DD-YYYY')) {
    if (setterData.Yesterday?.length > 0) {
      setterData.Yesterday = [...setterData.Yesterday, itm];
    } else {
      setterData.Yesterday = [itm];
    }
  } else {
    const dd = moment(itm.createdAt).format('MM-DD-YYYY').toString();

    if (setterData[dd]?.length > 0) {
      setterData[dd] = [...setterData[dd], itm];
    } else {
      setterData[dd] = [itm];
    }
  }
};

const Activity = () => {
  const classes = useStyles();
  const { organization } = useOrganization();
  const { setActive } = useContext(Context);
  const [openFilter, setOpenFilter] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(true);
  const code = useInput('');
  const module = useInput('');
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [lastId, setLastId] = useState('');
  const [items, setItems] = useState({});

  const [loading, setLoading] = useState('idle');
  const externalRef = useRef();
  const { isNearScreen } = useNearScreen({
    externalRef: loading === 'pending' ? null : externalRef,
    once: false,
  });

  useEffect(() => {
    setActive('/activity');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoading('pending');
    getActivity({ limit: 10, startDate: from, endDate: to })
      .then((res) => {
        const dataFormattedByDate = {};
        res.forEach((itm) => {
          onGetDateLabels(itm, dataFormattedByDate);
        });

        setItems(dataFormattedByDate);
        setLastId(res[res.length - 1]?._id);
        setLoading('resolved');
      })
      .catch((err) => {
        setLoading('rejected');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClearFilters = () => {
    module.setValue('0');
    setTo(null);
    setFrom(null);
    setLastId('');
    code.setValue('');
    setHasNextPage(true);
    getActivity({ limit: 10 })
      .then((res) => {
        const dataFormattedByDate = {};
        res.forEach((itm) => {
          onGetDateLabels(itm, dataFormattedByDate);
        });

        setItems(dataFormattedByDate);

        setLastId(res[res.length - 1]?._id);
        setLoading('resolved');
      })
      .catch((err) => {
        setLoading('rejected');
      });
  };

  const getMoreActivities = () => {
    setLoading('pending');
    getActivity({
      limit: 15,
      before: lastId,
      startDate: from,
      endDate: to,
      formCode: code.value,
      module: module.value,
    })
      .then((res) => {
        if (res.length === 0) {
          setHasNextPage(false);
          return;
        }
        const dataFormattedByDate = {};
        res.forEach((itm) => {
          onGetDateLabels(itm, dataFormattedByDate);
        });
        if (typeof lastId === 'string' && lastId !== res[res.length - 1]?._id) {
          const dataKeys = Object.keys(dataFormattedByDate);
          const itemsCopy = { ...items };
          dataKeys.forEach((dataKey) => {
            if (itemsCopy[dataKey]) {
              itemsCopy[dataKey] = [...itemsCopy[dataKey], ...dataFormattedByDate[dataKey]];
            } else {
              itemsCopy[dataKey] = dataFormattedByDate[dataKey];
            }
          });
          setLastId(res[res.length - 1]?._id);
          // setItems((prevState) => [...prevState, ...res]);
          setItems(itemsCopy);
        } else {
          setLastId(false);
        }
        setLoading('resolved');
      })
      .catch((err) => {
        setLoading('rejected');
      });
  };

  const getActivityToExport = () => {
    if (!from || !to) {
      toast.error('Please select a date range in the filter to generate a CSV file.');
      return;
    }
    if (moment(from).diff(to, 'days') < -180) {
      toast.error('The max interval dates between activities are 6 months.');
      return;
    }
    onGetActivityToExport({
      before: lastId,
      startDate: from,
      endDate: to,
      formCode: code.value,
      module: module.value,
    })
      .then((res) => FileDownload(res, 'activities.csv'))
      .catch((err) => toast.error(err.message));
  };

  return (
    <>
      {organization && <UpgradeModal open={!organization?.features?.dashboard?.activity?.enabled} />}
      <Box
        className={classes.container}
        style={
          !organization?.features?.dashboard?.activity?.enabled
            ? {
                filter: 'blur(6px)',
                height: '70vh',
                overflow: 'hidden',
              }
            : {}
        }
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h5">Activity</Typography>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <FilterButton
            dataTestId="activity-button-filter"
            openFilter={openFilter}
            onClick={() => setOpenFilter(!openFilter)}
            marginRight="10px"
          />
          <DashButton data-testid="activity-button-download" onClick={getActivityToExport} marginRight="10px">
            Download
          </DashButton>
        </Box>
        {openFilter && (
          <ActivityFilter
            code={code}
            module={module}
            to={to}
            setTo={setTo}
            from={from}
            setFrom={setFrom}
            before={lastId}
            setHasNextPage={setHasNextPage}
            onClick={() => setOpenFilter(false)}
            limit={10}
            setItems={setItems}
            onClearFilters={onClearFilters}
            typeOptions={{ obj: options }}
            lastId={lastId}
            setLastId={setLastId}
          />
        )}
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          marginBottom="0"
          marginTop={openFilter ? '20px' : '10px'}
        >
          {!Object.keys(items).length && loading === 'resolved' && (
            <Typography align="center" style={{ marginTop: 100, fontSize: 22 }}>
              There is no activity logs for the selected time range
            </Typography>
          )}
          {Object.keys(items)?.map((item, index) => (
            <TimeLine setLastId={setLastId} lastId={lastId} items={items[item]} key={index} title={item} />
          ))}
          <div id="bottom" ref={externalRef} />
          {isNearScreen && hasNextPage && lastId && (
            <Waypoint onEnter={getMoreActivities}>
              <Box display="flex" alignItems="center" justifyContent="center" style={{ marginTop: 24 }}>
                <CircularProgress color="#333" size="30px" style={{ marginLeft: 10 }} />
              </Box>
            </Waypoint>
          )}
        </Box>
      </Box>
    </>
  );
};

export default Activity;
