import React, { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import moment from 'moment';

import TimeLineItem from './TimeLineItem';
import HTMLContent from '../../components/HTMLContent';

const UserInfo = ({ user }) => (user.email ? <strong>{user?.email}</strong> : 'A user');
const HighLight = ({ children }) => <span style={{ color: '#2E66FE', fontWeight: '500' }}>{children} </span>;

const ChangeItem = ({ item, change, user, withCaseLink }) => {
  const [fieldName, setFieldName] = useState('');
  const [oldValue, setOldValue] = useState('');
  const [newValue, setNewValue] = useState('');

  useEffect(() => {
    if (change) {
      setFieldName(change.fieldName);
      setOldValue(change.oldValue);
      setNewValue(change.newValue);
    }
  }, [change]);

  const caseTaskText = (
    <>
      <span>in task </span>
      <HighLight>{item?.data?.task?.name}</HighLight>
      {withCaseLink && (
        <>
          <span>in case </span>
          <Link to={`/case/${item?.data?.form?.code}`}>
            <HighLight>{item?.data?.form?.code}</HighLight>
          </Link>
        </>
      )}
    </>
  );

  const content = (
    <Typography style={{ fontSize: 14 }}>
      {fieldName === 'dueDateTime' && (
        <>
          {!oldValue ? (
            <>
              <UserInfo user={user} />
              <span> has added Due Date </span>
              <HighLight>{moment(newValue).format('YYYY-MM-DD')}</HighLight>
              {caseTaskText}
            </>
          ) : (
            <>
              <UserInfo user={user} />
              <span> has changed Due Date from </span>
              <HighLight>{moment(oldValue).format('YYYY-MM-DD')}</HighLight>
              <span> to </span>
              <HighLight>{moment(newValue).format('YYYY-MM-DD')}</HighLight>
              {caseTaskText}
            </>
          )}
        </>
      )}
      {fieldName === 'notes' && (
        <>
          {!oldValue ? (
            <>
              <UserInfo user={user} />
              <span> has added a note </span>
              {caseTaskText}
              <HTMLContent content={newValue} />
            </>
          ) : (
            <>
              <UserInfo user={user} />
              <span> has changed a note </span>
              {caseTaskText}
              <div>
                <span> from: </span>
              </div>
              <HTMLContent content={oldValue} />
              <div>
                <span> to: </span>
              </div>
              <HTMLContent content={newValue} />
            </>
          )}
        </>
      )}
      {fieldName === 'status' && (
        <>
          {!oldValue ? (
            <>
              <UserInfo user={user} />
              <span> has added Status </span>
              <HighLight>{newValue}</HighLight>
              {caseTaskText}
            </>
          ) : (
            <>
              <UserInfo user={user} />
              <span> has changed Status from </span>
              <HighLight>{oldValue}</HighLight>
              <span> to </span>
              <HighLight>{newValue}</HighLight>
              {caseTaskText}
            </>
          )}
        </>
      )}
      {fieldName === 'type' && (
        <>
          <UserInfo user={user} />
          <span> has changed Type from </span>
          <HighLight>{oldValue}</HighLight>
          <span> to </span>
          <HighLight>{newValue}</HighLight>
          {caseTaskText}
        </>
      )}
      {fieldName === 'subType' && (
        <>
          <UserInfo user={user} />
          <span> has changed Subtype from </span>
          <HighLight>{oldValue}</HighLight>
          <span> to </span>
          <HighLight>{newValue}</HighLight>
          {caseTaskText}
        </>
      )}
      {!change && (
        <>
          {item?.type === 'CASE_TASK_UPDATED' && (
            <>
              <UserInfo user={user} />
              <span> updated task: </span>
              <HighLight>{item?.data?.task?.name} </HighLight>
              {item?.data?.form && (
                <>
                  <span>in case </span>
                  <Link to={`/case/${item?.data?.form?.code}`}>
                    <HighLight>{item?.data?.form?.code}</HighLight>
                  </Link>
                </>
              )}
            </>
          )}
          {item?.type === 'CASE_TASK_ASSIGNMENT_ADD' && (
            <>
              <UserInfo user={user} />
              <span> has assigned new user: </span>
              <HighLight>
                {item?.data?.assignedUser?.firstName} {item?.data?.assignedUser?.lastName}
              </HighLight>
              {caseTaskText}
            </>
          )}
          {item?.type === 'CASE_TASK_ASSIGNMENT_REMOVE' && (
            <>
              <UserInfo user={user} />
              <span> has unassigned new user: </span>
              <HighLight>
                {item?.data?.assignedUser?.firstName} {item?.data?.assignedUser?.lastName}
              </HighLight>
              {caseTaskText}
            </>
          )}
          {item?.type === 'CASE_TASK_ATTACHMENT_ADDED' && (
            <>
              <UserInfo user={user} />
              <span> has added attachment(s) </span>
              {caseTaskText}
            </>
          )}
          {item?.type === 'CASE_TASK_ATTACHMENT_DELETED' && (
            <>
              <UserInfo user={user} />
              <span> has removed attachment(s) </span>
              {caseTaskText}
            </>
          )}
        </>
      )}
    </Typography>
  );

  return content;
};

const CaseTaskUpdateItem = ({ item, user, withTimeLine, withCaseLink }) => {
  if (withTimeLine) {
    return (
      <TimeLineItem
        unread={item?.unread}
        profileImg={item?.data?.user?.photo?.public_url}
        createdAt={item.createdAt}
        key={item?._id}
        avatar={`${item?.data?.user?.firstName[0]}${item?.data?.user?.lastName[0]}`}
      >
        {!item?.data?.changes || item?.data?.changes?.length === 0 ? (
          <ChangeItem item={item} user={user} withCaseLink={withCaseLink} />
        ) : (
          <>
            {item?.data?.changes?.map((change, changeIndex) => (
              <ChangeItem key={changeIndex} item={item} user={user} change={change} withCaseLink={withCaseLink} />
            ))}
          </>
        )}
      </TimeLineItem>
    );
  }

  return (
    <>
      {!item?.data?.changes || item?.data?.changes?.length === 0 ? (
        <ChangeItem item={item} user={user} withCaseLink={withCaseLink} />
      ) : (
        <>
          {item?.data?.changes?.map((change, changeIndex) => (
            <ChangeItem key={changeIndex} item={item} user={user} change={change} withCaseLink={withCaseLink} />
          ))}
        </>
      )}
    </>
  );
};

export default CaseTaskUpdateItem;
