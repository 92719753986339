import { Avatar, Box, makeStyles, TableCell, TableRow, Typography } from '@material-ui/core';
import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import moment from 'moment';
import { Context } from '../../Context';
import useOrganization from '../../hooks/useOrganization';
import EnhancedTable from '../../components/Table';
import formatTxt from '../../utils/formatText';
import { transformStatus } from '../../utils';
import { getCivilianCases } from '../../services/unarmed';

const columns = ['Type', 'Tracking Number', 'Created Date', 'Name', 'Assigned To', 'Status'];

const getStatusTitle = (row) => {
  const currentStatus = row?.availableStatuses?.filter((status) => status.status === row?.status);
  return transformStatus(currentStatus[0]?.status);
};

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 20,
  },
  title: {
    marginTop: 50,
  },
  button: {
    background: ' #1F63FF',
    textTransform: 'capitalize',
    marginRight: 10,
    color: '#fff',
    height: 36,
    width: 30,
    '&:hover': {
      background: '#1F63FF',
    },
    '& .MuiButton-label': {
      fontSize: 13,
    },
    '&:disabled': {
      background: '#1f63ff61',
      color: '#fff',
    },
  },
  button2: {
    background: ' #1F63FF',
    textTransform: 'capitalize',
    color: '#fff',
    '&:hover': {
      background: '#1F63FF',
    },
    '& .MuiButton-label': {
      fontSize: 14,
    },
  },
  buttonOutlined: {
    letterSpacing: '1px',
    fontSize: 13,
    fontWeight: '500',
    marginRight: 10,
    width: 'fit-content',
    alignSelf: 'flex-end',
    border: '1px solid transparent',
    color: '#4b7bff',
    '&:hover': {
      background: 'transparent',
      border: '1px solid transparent',
    },
    '& .MuiButton-label': {
      fontSize: 13,
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: '#fff',
    boxShadow: theme.shadows[5],
    padding: 30,
    maxWidth: 600,
    outline: 0,
  },
}));

export default function OfficerCases({ officerBadgeNumber }) {
  const classes = useStyles();
  const { myUser } = useContext(Context);
  const { organization } = useOrganization();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortBy, setSortBy] = useState('createdAt');
  const [sort, setSort] = useState('desc');
  const [forms, setForms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filteredCaseCount, setFilteredCaseCount] = useState(0);

  const history = useHistory();

  useEffect(() => {
    const getFormsCivilians = async () => {
      try {
        setLoading(true);

        const { data, headers } = await getCivilianCases({
          token: '',
          page,
          limit: rowsPerPage,
          sortBy,
          sort,
          isFiltering: true,
          type: '',
          from: null,
          to: null,
          code: '',
          status: '',
          name: '',
          assignedTo: '',
          badgeNumber: officerBadgeNumber,
          transferredOnly: false,
        });

        setForms({ data, headers });
      } catch (error) {
        console.log(error.message);
      } finally {
        setLoading(false);
      }
    };

    if (officerBadgeNumber) {
      getFormsCivilians();
    }
  }, [page, rowsPerPage, sortBy, sort, officerBadgeNumber]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(1);
  };

  const goToCaseDetails = (e, row, handleClick) => {
    history.push(`/case/${row.code}`);
  };
  const renderRow = (row, index, handleClick) => (
    <TableRow
      hover
      tabIndex={-1}
      key={index}
      className={classes.row}
      style={{
        cursor: 'pointer',
        height: 50,
        maxHeight: 50,
        background: '#fff',
      }}
    >
      <TableCell
        component="th"
        scope="row"
        align="left"
        onClick={(e) => goToCaseDetails(e, row, handleClick)}
        style={{
          color: row.type === 'COMPLAINT' ? '#FF4242' : row.type === 'COMPLIMENT' ? '#008858' : '#2E66FE',
          textTransform: 'capitalize',
          fontWeight: '500',
        }}
      >
        {row.type?.toLowerCase()}
        {row?.archived && <Typography style={{ fontSize: 10, color: '#585858' }}>Archived</Typography>}
      </TableCell>

      <TableCell align="left" onClick={(e) => goToCaseDetails(e, row, handleClick)}>
        {row.code}
      </TableCell>
      <TableCell component="th" scope="row" align="left" onClick={(e) => goToCaseDetails(e, row, handleClick)}>
        {moment(row.createdAt).format('MMMM DD, YYYY')}
      </TableCell>
      <TableCell
        align="left"
        style={{ textTransform: 'capitalize' }}
        onClick={(e) => goToCaseDetails(e, row, handleClick)}
      >
        {row.civilian?.firstName
          ? `${formatTxt(row.civilian?.firstName, 10)} ${formatTxt(row.civilian?.lastName, 10)}`
          : ''}
      </TableCell>

      <TableCell align="left" onClick={(e) => goToCaseDetails(e, row, handleClick)}>
        <Box display="flex" alignItems="center" justifyContent="flex-start">
          {row?.assigned.map((user, i) => {
            if (i === 0) {
              if (user?.photo?.url || user?.profile_img) {
                return (
                  <Box display="flex" alignItems="center">
                    <Avatar
                      src={user?.photo?.url || user?.profile_img}
                      style={{ marginRight: 10, width: 30, height: 30 }}
                    />
                    <Typography variant="subtitle2">{`${formatTxt(user.firstName, 20)} ${formatTxt(
                      user?.lastName,
                      20
                    )}`}</Typography>
                  </Box>
                );
              }

              return (
                <Box display="flex" alignItems="center">
                  <AccountCircleIcon htmlColor="#364F74" fontSize="large" style={{ marginRight: 10 }} />
                  <Typography variant="subtitle2">
                    {' '}
                    {user?.firstName ? `${user?.firstName} ${user?.lastName}` : 'Unassigned'}
                  </Typography>
                </Box>
              );
            }
          })}
          {row?.assigned?.length > 1 && (
            <span style={{ color: 'rgb(75, 123, 255)', marginLeft: 20 }}>(+{row?.assigned?.length - 1})</span>
          )}
        </Box>
      </TableCell>
      <TableCell align="left" onClick={(e) => goToCaseDetails(e, row, handleClick)}>
        <p style={{ fontWeight: '500' }}>{getStatusTitle(row)}</p>
        <p style={{ fontSize: 12, color: '#585858' }}>
          {row?.statusLog && moment(row?.statusLog?.[row?.status]?.datetime).format('MMMM DD, hh:mm a')}
        </p>
      </TableCell>
    </TableRow>
  );
  return (
    <EnhancedTable
      sort={sort}
      setSort={setSort}
      columns={columns}
      rows={forms.data}
      setSortBy={setSortBy}
      rowRender={renderRow}
      loading={loading}
      page={page}
      setPage={setPage}
      count={filteredCaseCount}
      rowsPerPage={rowsPerPage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
    />
  );
}
