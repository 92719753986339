import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Button, CircularProgress, makeStyles, Input } from '@material-ui/core';
import PropTypes from 'prop-types';

import { OutLineInput } from '../pages/CaseDetails/UpdateCase';
import { TextMaskCustom } from '../pages/Officers/OfficersTable';
import { validateEmail } from '../utils/validateForm';
import SelectBox from './SelectBox';
import Card from './Card';

const useStyles = makeStyles(() => ({
  label: {
    fontWeight: '500',
    fontSize: 14,
    margin: '10px 0 10px',
  },
  containerTitle: {
    borderBottom: '1px solid #e2e2e2',
  },
  button: {
    background: ' #3B70FE',
    textTransform: 'capitalize',
    color: '#fff',
    '&:hover': {
      background: '#3B70FE',
    },
    '& .MuiButton-label': {
      fontSize: 14,
    },
    marginTop: 10,
    marginBottom: 20,
  },
}));

const CreateContactForm = ({
  dataTestId,
  firstName,
  lastName,
  type,
  companyName,
  email,
  phoneNumber,
  loading,
  onSubmit,
}) => {
  const [validEmail, setValidEmail] = useState(true);

  const classes = useStyles();

  const onValidateEmail = () => {
    if (email && email.value !== '') {
      setValidEmail(validateEmail(email.value));
    }
  };

  useEffect(() => {
    onValidateEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email?.value]);

  return (
    <Card title="New Contact">
      <Grid container alignItems="center">
        <Grid item xs={6}>
          <Box mr={2}>
            <Typography variant="body2" className={classes.label} style={{ marginTop: 0 }}>
              First name
            </Typography>
            <OutLineInput
              data-testid={`${dataTestId}-input-first-name`}
              placeholder="Enter Contact first name"
              value={firstName.value}
              onChange={firstName.onChange}
              error={firstName.error}
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                'aria-label': 'weight',
              }}
              labelWidth={0}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box>
            <Typography variant="body2" className={classes.label} style={{ marginTop: 0 }}>
              Last name
            </Typography>
            <OutLineInput
              data-testid={`${dataTestId}-input-last-name`}
              placeholder="Enter Contact last name"
              value={lastName.value}
              onChange={lastName.onChange}
              error={lastName.error}
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                'aria-label': 'weight',
              }}
              labelWidth={0}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box mr={2}>
            <Typography variant="body2" className={classes.label}>
              Email <span style={{ fontSize: 12, color: 'red' }}>*</span>
            </Typography>
            <OutLineInput
              data-testid={`${dataTestId}-input-email`}
              placeholder="Enter Contact email"
              value={email.value}
              onChange={email.onChange}
              error={email.error || !validEmail}
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                'aria-label': 'weight',
              }}
              labelWidth={0}
            />
            {!validEmail && (
              <Typography variant="body2" style={{ color: 'red' }}>
                The email is not valid
              </Typography>
            )}
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box>
            <Typography variant="body2" className={classes.label}>
              Phone number
            </Typography>
            <Input
              inputProps={{
                'data-testid': `${dataTestId}-input-phone-number`,
              }}
              value={phoneNumber.value}
              onChange={phoneNumber.onChange}
              autoComplete="off"
              name="textmask"
              style={{ width: '100%' }}
              error={phoneNumber.error}
              placeholder="Enter Contact phone number"
              id="formatted-text-mask-input"
              inputComponent={TextMaskCustom}
              maxLength={14}
              className="InputFilledPhoneNumber"
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box mr={2}>
            <Typography variant="body2" className={classes.label}>
              Type
            </Typography>
            <SelectBox
              dataTestId={`${dataTestId}-select-type`}
              id="type"
              options={['Individual', 'Organization']}
              withColors={{
                bg: '#f8f8f9',
              }}
              value={type.value}
              onChange={type.onChange}
            />
          </Box>
        </Grid>
        {type.value === 'Organization' && (
          <Grid item xs={6}>
            <Box>
              <Typography variant="body2" className={classes.label}>
                Company Name <span style={{ fontSize: 12, color: 'red' }}>*</span>
              </Typography>
              <OutLineInput
                data-testid={`${dataTestId}-input-company-name`}
                placeholder="Enter Contact company name"
                value={companyName.value}
                onChange={companyName.onChange}
                error={companyName.error}
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  'aria-label': 'weight',
                }}
                labelWidth={0}
              />
            </Box>
          </Grid>
        )}
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end" alignItems="center" mt={2}>
            {loading ? (
              <CircularProgress />
            ) : (
              <Button
                data-testid={`${dataTestId}-button-submit`}
                color="primary"
                variant="contained"
                className={classes.button}
                onClick={onSubmit}
              >
                Create Contact
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};

CreateContactForm.propTypes = {
  dataTestId: PropTypes.string,
  type: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  firstName: PropTypes.object.isRequired,
  lastName: PropTypes.object.isRequired,
  email: PropTypes.object.isRequired,
  phoneNumber: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export default CreateContactForm;
