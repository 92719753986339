import { Box, Backdrop, Fade, makeStyles, Modal, Typography, CircularProgress, Button } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { DropzoneArea } from 'material-ui-dropzone';
import CancelIcon from '@material-ui/icons/Cancel';
import BackupIcon from '@material-ui/icons/Backup';
import EditIcon from '@material-ui/icons/Edit';
import { toast } from 'react-toastify';

import { Context } from '../../Context';
import { updateProfile, uploadFormImg } from '../../services/unarmed';
import profileImg from '../../assets/profile.png';
import ProfileInformation from './ProfileInformation';
import useInput from '../../hooks/useInput';
import useOrganization from '../../hooks/useOrganization';
import ModalPopUp from '../../components/ModaPopupl';
import ChangePassordProfile from './ChangePasswordProfile';
import { linkInWithMicrosoft } from '../../services/firebase';
import microsoftLogo from '../../assets/Microsoft_logo.svg.png';

import ProfileConfigNotifications from './ProfileConfigNotifications';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 20,
  },
  title: {
    marginTop: 50,
  },
  label: {
    fontSize: 12,
    color: '#aaa',
  },
  button: {
    letterSpacing: '1px',
    fontSize: 13,
    background: '#2E66FE',
    fontWeight: '400',
    width: 'fit-content',
    alignSelf: 'flex-end',
    color: '#fff',
    '&:hover': {
      background: '#4b7bff',
    },
    '& .MuiButton-label': {
      fontSize: 13,
    },
    textTransform: 'capitalize',
  },

  buttonOutlined: {
    letterSpacing: '1px',
    fontSize: 13,
    background: '#fff',
    fontWeight: '500',
    marginRight: 10,
    width: 'fit-content',
    alignSelf: 'flex-end',
    border: '1px solid #4b7bff ',
    color: '#4b7bff',
    '&:hover': {
      background: '#fff',
      border: '1px solid #4b7bff ',
    },
    '& .MuiButton-label': {
      fontSize: 13,
    },
  },

  img: {
    width: 120,
    height: 120,
    borderRadius: '50%',
    objectFit: 'cover',
  },

  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: '#F9FAFF',
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: 20,
  },
  googleButton: {
    width: '100%',
    margin: '20px 0',
    border: '1px solid #0000001F',
    textTransform: 'none',
    color: '#5E5E5E',
  },
  googleIcon: {
    width: 20,
    height: 20,
    objectFit: 'cover',
    marginRight: 10,
  },
}));

const Profile = () => {
  const [loading, setLoading] = useState(false);
  // const [me, setMe] = useState(null);
  const { authUser, setMyuser, myUser, onGetMe } = useContext(Context);
  const classes = useStyles();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const firstName = useInput('', isSubmitting);
  const lastName = useInput('', isSubmitting);
  const phoneNumber = useInput('', isSubmitting);
  const [open, setOpen] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [image, setImage] = useState('');
  const [hashFile, setHashFile] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [loadingImg, setLoadingImg] = useState(false);
  const [loadingUpdateProfile, setLoadingUpdateProfile] = useState(false);
  const { organization } = useOrganization();
  const [loadingPecetage, setLoadingPercentage] = useState(0);
  const [disableNotification, setDisableNotification] = useState(false);

  useEffect(() => {
    if (authUser && myUser) {
      firstName.setValue(myUser?.firstName);
      lastName.setValue(myUser?.lastName);
      phoneNumber.setValue(myUser?.phoneNumber);
      setDisableNotification(myUser?.settings?.disableAllNotifications);
      setImage(myUser?.photo?.public_url);
    }
  }, [authUser, myUser]);

  useEffect(() => {
    onGetMe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onUpdateProfile = async () => {
    if (!firstName.value || !lastName.value || !phoneNumber.value) {
      setIsSubmitting(true);
      return;
    }
    try {
      setLoadingUpdateProfile(true);
      const data = {
        firstName: firstName.value,
        lastName: lastName.value,
        phoneNumber: phoneNumber.value,
      };

      if (hashFile) {
        data.photo = hashFile;
      }

      const { data: updatedProfile } = await updateProfile(data);
      // setMe(updatedProfile);
      setMyuser(updatedProfile);
      setLoadingUpdateProfile(false);
      setIsEditing(false);
    } catch (error) {
      setLoadingUpdateProfile(false);

      console.log(error);
    }
  };

  const disableEmailNotification = async () => {
    try {
      setDisableNotification(!disableNotification);
      const { data } = await updateProfile({
        settings: {
          disableAllNotifications: !disableNotification,
        },
      });
      setMyuser(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const uploadOnLoad = async (fl) => {
    const imgData = new FormData();
    imgData.append('file', fl);
    imgData.append('organizationId', organization?._id);
    setOpen(false);
    setLoadingImg(true);
    try {
      const img = await uploadFormImg(imgData, setLoadingPercentage);
      setImage(img.publicUrl);
      setHashFile(img.hashId);
      toast.success('Profile image uploaded successfully');
      setTimeout(() => {
        setLoadingImg(false);
      }, 500);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.error.details[0].message);
      setLoadingImg(false);
    }
  };

  const onCancel = () => {
    setIsEditing(false);
    setImage(myUser?.photo?.url);
    setHashFile('');
    firstName.setValue(myUser?.firstName);
    lastName.setValue(myUser?.lastName);
    phoneNumber.setValue(myUser?.phoneNumber);
  };

  const hasMicrosoft = function () {
    return authUser?.providerData.find((provider) => provider.providerId === 'microsoft.com');
  };

  return (
    <PageWrapper className={classes.container}>
      <ModalPopUp open={openModal} setOpen={setOpenModal}>
        <ChangePassordProfile setOpen={setOpenModal} />
      </ModalPopUp>
      <Typography variant="h5">Profile</Typography>
      <Box width="100%" style={{ background: '#fff' }} marginTop="20px">
        <Box
          width="100%"
          display="flex"
          padding="10px 20px"
          alignItems="center"
          borderBottom="1px solid #0000001F"
          justifyContent="space-between"
        >
          <Typography variant="subtile1" style={{ fontWeight: '500' }} />

          <Box display="flex" alignContent="center" justifyContent="flex-end">
            {isEditing ? (
              <Box>
                <Button
                  data-testid="profile-button-cancel"
                  onClick={onCancel}
                  className={classes.buttonOutlined}
                  variant="outlined"
                >
                  Cancel
                </Button>
                <Button data-testid="profile-button-save" onClick={onUpdateProfile} className={classes.button}>
                  {loadingUpdateProfile ? <CircularProgress color="#fff" size="25px" /> : 'Save'}
                </Button>
              </Box>
            ) : (
              <>
                {!hasMicrosoft() && organization?.features?.dashboard?.dashboard?.microsoft_sso && (
                  <Button onClick={linkInWithMicrosoft} className={classes.button} style={{ marginRight: 10 }}>
                    <img src={microsoftLogo} alt="404" className={classes.googleIcon} /> Link Microsoft SSO
                  </Button>
                )}
                <Button
                  data-testid="profile-button-change-password"
                  onClick={() => setOpenModal(true)}
                  className={classes.button}
                  style={{ marginRight: 10 }}
                >
                  Change Password
                </Button>
                <Button
                  data-testid="profile-button-edit-profile"
                  onClick={() => setIsEditing(true)}
                  className={classes.button}
                >
                  edit profile
                </Button>
              </>
            )}
          </Box>
        </Box>
        {loading ? (
          <Box width="100%" height="300px" display="flex" alignItems="center" justifyContent="center">
            <CircularProgress color="#000" size="40px" />
          </Box>
        ) : (
          <>
            <Box
              width="400px"
              minWidth="400px"
              position="relative"
              borderRadius={4}
              padding="20px"
              alignItems="center"
              display="flex"
              justifyContent="flex-start"
              style={{ background: '#fff' }}
            >
              <img
                src={image || profileImg}
                alt="profileImg"
                className={classes.img}
                style={{ cursor: isEditing ? 'pointer' : 'default' }}
              />
              {loadingImg && (
                <Box
                  style={{
                    width: 120,
                    height: 120,
                    borderRadius: '50%',
                    position: 'absolute',
                    zIndex: 1,
                    background: 'rgba(255, 255, 255, .6',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <CircularProgress color="#000" size="20px" />
                </Box>
              )}
              <Box display="flex" justifyContent="flex-start" flexDirection="column" marginLeft="20px">
                <Typography
                  style={{
                    textAlign: 'center',
                    fontSize: 20,
                    fontWeight: '500',
                  }}
                >
                  {`${myUser?.firstName} ${myUser?.lastName}`}
                </Typography>
                <Typography
                  style={{
                    fontSize: 14,
                    textTransform: 'capitalize',
                    color: '#333',
                    marginBottom: 4,
                  }}
                >
                  {`${myUser?.role}`}
                </Typography>
                {isEditing && (
                  <Typography
                    style={{
                      color: '#2E66FE',
                      cursor: 'pointer',
                      fontSize: 12,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                    onClick={() => isEditing && handleOpen()}
                  >
                    <EditIcon htmlColor="#2E66FE" fontSize="small" /> Edit Photo
                  </Typography>
                )}
              </Box>
            </Box>
            <ProfileInformation
              me={myUser}
              onCancel={onCancel}
              setIsEditing={setIsEditing}
              isEditing={isEditing}
              firstName={firstName}
              lastName={lastName}
              phoneNumber={phoneNumber}
              onUpdateProfile={onUpdateProfile}
            />
          </>
        )}
      </Box>
      <ProfileConfigNotifications />
      {isEditing && (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              <Box display="flex" justifyContent="flex-end">
                <CancelIcon style={{ cursor: 'pointer' }} onClick={() => setOpen(false)} />
              </Box>
              <DropzoneArea
                // acceptedFiles={["image/jpeg", "image/png", "image/jpg"]}
                dropzoneClass="default-dropzone-class"
                maxFileSize={5000000}
                onChange={(fl) => {
                  if (fl.length > 0) {
                    uploadOnLoad(fl[fl.length - 1]);
                  }
                }}
                filesLimit={3}
                dropzoneText="Click or drag and drop an image here"
                showPreviewsInDropzone={false}
                Icon={() => <BackupIcon fontSize="large" htmlColor="#A2BAFF" />}
              />
            </div>
          </Fade>
        </Modal>
      )}
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  width: 100%;
`;

export default Profile;
