/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Box, IconButton, TextField, Snackbar, Menu, MenuItem } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import TrashIcon from '../assets/trash.png';
import DragIndicatorTwoToneIcon from '@material-ui/icons/DragIndicatorTwoTone';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import PropTypes from 'prop-types';
import PushPinOutlinedIcon from '../assets/pushOut-pin.png';
import { TYPE_USER_TABS } from '../utils/constants';
import { createDashboard, createView, createUserTabs, deleteView, createFilterConfig } from '../services/unarmed';
import PushPinIcon from '../assets/pin.png';

const ItemType = 'TAB';

const DraggableTab = ({
  tabs,
  tab,
  index,
  setTabs,
  tabSelected,
  setTabSelected,
  setActiveTabName,
  setActiveTab,
  moveTab,
  handleTabClick,
  activeTabName,
  updateNameTab,
  handleEditTab,
  togglePinTab,
  deleteTab,
  onTabAction,
  moduleName,
}) => {
  const [inputValue, setInputValue] = useState(tab.name);
  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: { index },
    canDrag: !['Dashboards', 'Explore', 'Reports', 'Default'].includes(tab.name) || !tab.pinned,
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: ItemType,
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveTab(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  const handleUpdateNameTab = async () => {
    setTabSelected(tab);
    setActiveTab(tab.type);
    setActiveTabName(inputValue);
    if (moduleName === 'INSIGHT') {
      await updateNameTab({ newName: inputValue, tabId: tab.id, dashboardId: tab.displayObjectId });
    } else {
      await updateNameTab({ newName: inputValue, tabId: tab.id, viewId: tab.displayObjectId });
    }
  };

  const handleDeleteTab = async () => {
    setTabs((prevTabs) => prevTabs.filter((tabI) => tabI.id !== tab.id));
    const tabType = moduleName === 'INSIGHT' ? 'DASHBOARDS_LIST' : 'VIEW';
    const indexDashb = tabs.findIndex((tabI) => tabI.type === tabType);

    try {
      const response = await deleteTab(tab.id);

      if (moduleName !== 'INSIGHT') {
        await deleteView(tab.displayObjectId);
      }

      handleMenuClose();
      handleTabClick(indexDashb);
    } catch (error) {
      console.error("Error deleting tab:", error);
      handleMenuClose();
      handleTabClick(indexDashb);
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <div ref={(node) => drag(drop(node))} style={{ opacity: isDragging ? 0.5 : 1 }}>
      {tab.isEditing ? (
        <Box display="flex" alignItems="center">
          <TextField
            autoFocus
            label="Name"
            value={inputValue || ''}
            onChange={(e) => setInputValue(e.target.value)}
            onBlur={handleUpdateNameTab}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleUpdateNameTab();
              }
            }}
            style={{ marginRight: '2px', fontSize: '14px', width: '200px' }}
            inputProps={{ style: { fontSize: '14px', padding: '0px' } }}
          />
          <IconButton onClick={handleUpdateNameTab}>
            <SaveIcon style={{ fontSize: '20px' }} />
          </IconButton>
        </Box>
      ) : (
        <Box
          display="flex"
          alignItems="center"
          style={{
            margin: '2px',
            textDecoration: 'none', // Eliminar subrayado
            textTransform: 'none',
            color: activeTabName === tab.name ? '#2A55FF' : '#6B7280', // Cambiar el color cuando está activo
            fontWeight: activeTabName === tab.name ? '500' : '400',
            backgroundColor: 'transparent', // Eliminar sombreado azul
            fontSize: '14px', // Ajustar el tamaño de fuente si es necesario
            borderRight: '1px solid #e0e0e0', // Línea divisoria más delgada
            paddingRight: '10px', // Ajustar espacio entre el texto y la línea divisoria
            paddingLeft: '10px', // Ajustar espacio entre las líneas divisorias
            position: 'relative',
          }}
        >
          <IconButton
            onClick={() => {
              if (index >= 0 && index < tabs.length) {
                handleTabClick(index);
                onTabAction(tab);
              }
            }}
            onDoubleClick={() => {
              if (tab.deletable) {
                handleEditTab(index, tab.name, true);
              }
            }}
            style={{ display: 'flex', alignItems: 'center', padding: '2px', fontSize: '16px', color: 'inherit' }}
          >
            {tab.name}
            {tab.pinned && (
              <>
                <img src={PushPinIcon} style={{ marginRight: '5px', fontSize: '10px', marginLeft: '5px' }} />
              </>
            )}

            <IconButton
              aria-controls="menu"
              aria-haspopup="true"
              onClick={handleMenuOpen}
              style={{
                color: 'inherit',
                fontSize: '10px',
                marginLeft: '5px', // Reduce el margen entre el tab y los seis puntos
                padding: '0px', // Reducir el padding para acercar el icono al texto
              }}
            >
              <DragIndicatorTwoToneIcon />
            </IconButton>

            {tab.deletable && (
              <Menu id="menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleMenuClose}>
                {moduleName === 'INSIGHT' && (
                  <MenuItem
                    onClick={() => {
                      togglePinTab(index);
                      handleMenuClose();
                    }}
                    style={{ fontSize: '12px' }}
                  >
                    {tab.pinned ? (
                      <>
                        <img src={PushPinIcon} style={{ marginRight: '5px', fontSize: '10px' }} />
                        Unpin
                      </>
                    ) : (
                      <>
                        <img src={PushPinOutlinedIcon} style={{ marginRight: '5px', fontSize: '10px' }} />
                        Pin
                      </>
                    )}
                  </MenuItem>
                )}
                <MenuItem onClick={handleDeleteTab} style={{ color: 'red', fontSize: '12px' }}>
                  {moduleName === 'INSIGHT' ? (
                    <>
                      <CloseIcon style={{ marginRight: '5px', fontSize: '12px' }} /> Close
                    </>
                  ) : (
                    <>
                      <img src={TrashIcon} alt="Delete Icon" style={{ marginRight: '5px', width: '12px', height: '12px' }} /> Delete
                    </>
                  )}
                </MenuItem>
              </Menu>
            )}
          </IconButton>
          {/* Subrayado para el tab activo */}
          {activeTabName === tab.name && (
            <div
              style={{
                position: 'absolute',
                bottom: '-2px',
                left: '0',
                right: '0',
                height: '2px',
                backgroundColor: '#2A55FF',
                color: '#00000',
              }}
            />
          )}
        </Box>
      )}
    </div>
  );
};

// PropTypes for DraggableTab
DraggableTab.propTypes = {
  tab: PropTypes.object.isRequired,
  setTabs: PropTypes.func.isRequired,
  tabSelected: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  moveTab: PropTypes.func.isRequired,
  setActiveTabName: PropTypes.func.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  setTabSelected: PropTypes.func.isRequired,
  handleTabClick: PropTypes.func.isRequired,
  activeTabName: PropTypes.string.isRequired,
  handleEditTab: PropTypes.func.isRequired,
  togglePinTab: PropTypes.func.isRequired,
  deleteTab: PropTypes.func.isRequired,
  onTabAction: PropTypes.func.isRequired,
  moduleName: PropTypes.string.isRequired,
};

const TabsModules = ({
  tabs,
  setTabs,
  activeTabName,
  saveTab,
  tabSelected,
  updateNameTab,
  onTabSelect,
  setActiveTabName,
  setTabSelected,
  activeTab,
  setActiveTab,
  saveTabPositions,
  onTabAction,
  deleteTab,
  moduleName,
}) => {
  const [isAddingNewTab, setIsAddingNewTab] = useState(false);
  const [newTabName, setNewTabName] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [showScrollButtons, setShowScrollButtons] = useState(false);
  const tabContainerRef = useRef(null);
  const tabWrapperRef = useRef(null);

  useEffect(() => {
    if (moduleName === 'INSIGHT') {
      const defaultTab = tabs.find((tab) => tab.name === 'Reports');
      setTabSelected(defaultTab);
      setActiveTabName('Reports');
      setActiveTab(defaultTab?.type);
    } else {
      const defaultTab = tabs.find((tab) => tab.name === 'Default');
      setTabSelected(defaultTab);
      setActiveTabName('Default');
      setActiveTab(defaultTab?.type);
    }
  }, [tabs]);

  useEffect(() => {
    const checkOverflow = () => {
      if (tabWrapperRef.current && tabContainerRef.current) {
        const isOverflowing = tabWrapperRef.current.scrollWidth > tabContainerRef.current.clientWidth;
        setShowScrollButtons(isOverflowing);
      }
    };

    window.addEventListener('resize', checkOverflow);
    checkOverflow();

    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, [tabs]);

  const handleTabClick = (index) => {
    setTabSelected(tabs[index]);
    setActiveTabName(tabs[index].name);
    setActiveTab(tabs[index].type);
    onTabSelect(tabs[index]);
  };

  const handleEditTab = async (index, newName, isEditing) => {
    const updatedTabs = [...tabs];
    const tab = { ...updatedTabs[index] };

    tab.name = newName;
    if (isEditing) {
      tab.isEditing = true;
    } else {
      tab.isEditing = false;
      try {
        await saveTab(tab.id, tab.name);
        setSnackbarMessage('Tab saved successfully.');
        setSnackbarOpen(true);
      } catch (error) {
        setSnackbarMessage('Error saving tab.');
        setSnackbarOpen(true);
      }
    }

    updatedTabs[index] = tab;
    setTabs(updatedTabs);
  };

  const togglePinTab = (index) => {
    const updatedTabs = [...tabs];
    const tabToToggle = updatedTabs[index];

    if (['Reports', 'Explore', 'Dashboards', 'Default'].includes(tabToToggle.name)) {
      return;
    }

    tabToToggle.pinned = !tabToToggle.pinned;

    const pinnedTabs = updatedTabs.filter((tab) => tab.pinned);
    const unpinnedTabs = updatedTabs.filter(
      (tab) => !tab.pinned && !['Reports', 'Explore', 'Dashboards', 'Default'].includes(tab.name)
    );

    setTabs([
      ...updatedTabs.filter((tab) => ['Reports', 'Explore', 'Dashboards', 'Default'].includes(tab.name)),
      ...pinnedTabs,
      ...unpinnedTabs,
    ]);
    saveTabPositions([
      ...updatedTabs.filter((tab) => ['Reports', 'Explore', 'Dashboards', 'Default'].includes(tab.name)),
      ...pinnedTabs,
      ...unpinnedTabs,
    ]);
  };

  const handleSaveNewTab = async () => {
    if (!newTabName.trim()) {
      setSnackbarMessage('Error: The new tab name must be a valid string.');
      setSnackbarOpen(true);
      return;
    }

    if (tabs.some((tab) => tab.name.toLowerCase() === newTabName.toLowerCase())) {
      setSnackbarMessage('Error: A tab with this name already exists.');
      setSnackbarOpen(true);
      return;
    }

    const baseColumnsConfig = {
      CASES: {
        flag: true,
        type: true,
        subtype: false,
        trackingNumber: true,
        caseNumber: false,
        internalAffairsCaseNumber: false,
        createdDate: true,
        name: true,
        assignedTo: true,
        source: false,
        status: true,
        reporter: false,
        investigator: false,
        allegations: false,
        dispositions: false,
        discipline: false,
      },
      ALLEGATIONS: {
        flag: true,
        allegation: true,
        disposition: false,
        discipline: false,
        caseType: true,
        trackingNumber: true,
        createdDate: true,
        badgeSerialNumber: true,
        officerName: true,
        code: false,
        status: true,
      },
      USERS: {
        flag: true,
        firstName: true,
        lastName: true,
        phone: true,
        email: true,
        role: true,
        dateAdded: true,
      },
      OFFICERS: {
        flag: true,
        badgeSerialNumber: true,
        officerName: true,
        officerPhone: true,
        createdDate: false,
      },
      CONTACTS: {
        flag: true,
        name: true,
        pronouns: false,
        type: false,
        companyName: true,
        phoneNumber: true,
        email: true,
        module: true,
        createdAt: true,
      }
    };

    let type = null;
    let displayObjectType = null;
    let displayObjectId = null;

    if (moduleName === 'INSIGHT') {
      const newDashboard = {
        name: newTabName,
        type: 'GLOBAL',
      };

      const responseDashboard = await createDashboard(newDashboard);
      type = TYPE_USER_TABS.DASHBOARD;
      displayObjectType = 'DASHBOARD';
      displayObjectId = responseDashboard.data.dashboard.id;
    } else {
      const newFilterConfig = await createFilterConfig({
        name: newTabName,
        type: moduleName,
      });
      const newView = {
        views: [
          {
            viewName: newTabName,
            moduleName,
            filtersConfigId: newFilterConfig?.data?._id,
            columns: baseColumnsConfig[moduleName] || {}
          },
        ],
      };
      const responseView = await createView(newView);
      type = TYPE_USER_TABS.VIEW;
      displayObjectType = 'VIEW';
      displayObjectId = responseView.data.views[0]._id;
    }

    const newTab = {
      userTabs: [
        {
          name: newTabName,
          type,
          position: String(tabs.length + 1),
          moduleName,
          displayObjectType,
          displayObjectId,
        },
      ],
    };

    const newTabC = await createUserTabs(newTab);
    setTabs([...tabs, newTabC.data.userTabs[0]]);
    setIsAddingNewTab(false);
    setNewTabName('');
    setSnackbarMessage('New tab successfully saved.');
    setSnackbarOpen(true);
  };

  const handleAddNewTabClick = () => {
    setIsAddingNewTab(true);
  };
  // Add comment
  const moveTab = useCallback(
    (fromIndex, toIndex) => {
      if (['Reports', 'Explore', 'Dashboards', 'Default'].includes(tabs[fromIndex].name) || tabs[fromIndex].pinned) {
        return;
      }

      const updatedTabs = [...tabs];
      const [movedTab] = updatedTabs.splice(fromIndex, 1);
      updatedTabs.splice(toIndex, 0, movedTab);

      const fixedTabs = updatedTabs.filter(
        (tab) => ['Reports', 'Explore', 'Dashboards', 'Default'].includes(tab.name) || tab.pinned
      );
      const otherTabs = updatedTabs.filter(
        (tab) => !['Reports', 'Explore', 'Dashboards', 'Default'].includes(tab.name) && !tab.pinned
      );

      setTabs([...fixedTabs, ...otherTabs]);
      saveTabPositions([...fixedTabs, ...otherTabs]);
    },
    [tabs, saveTabPositions]
  );

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const scrollLeft = () => {
    if (tabContainerRef.current) {
      tabContainerRef.current.scrollBy({
        left: -150,
        behavior: 'smooth',
      });
    }
  };

  const scrollRight = () => {
    if (tabContainerRef.current) {
      tabContainerRef.current.scrollBy({
        left: 150,
        behavior: 'smooth',
      });
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Box display="flex" flexDirection="column" style={{ width: '100%' }}>
        <Box display="flex" alignItems="center">
          {showScrollButtons && (
            <IconButton onClick={scrollLeft}>
              <ArrowBackIosIcon style={{ fontSize: '20px' }} />
            </IconButton>
          )}

          <Box
            ref={tabContainerRef}
            display="flex"
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="center"
            style={{
              overflowX: 'auto',
              whiteSpace: 'nowrap',
              borderBottom: '1px solid #ccc',
              scrollbarWidth: 'none',
              msOverflowStyle: 'none',
              width: '100%',
            }}
            className="hide-scrollbar"
          >
            {tabs.map((tab, index) => (
              <DraggableTab
                tabs={tabs}
                setTabs={setTabs}
                tabSelected={tabSelected}
                setActiveTabName={setActiveTabName}
                setActiveTab={setActiveTab}
                setTabSelected={setTabSelected}
                key={tab.id}
                index={index}
                tab={tab}
                moveTab={moveTab}
                handleTabClick={handleTabClick}
                activeTabName={activeTabName}
                handleEditTab={handleEditTab}
                updateNameTab={updateNameTab}
                togglePinTab={togglePinTab}
                onTabAction={onTabAction}
                deleteTab={deleteTab}
                moduleName={moduleName}
              />
            ))}

            {isAddingNewTab ? (
              <Box display="flex" alignItems="center">
                <TextField
                  value={newTabName}
                  onChange={(e) => setNewTabName(e.target.value)}
                  placeholder="New Tab"
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') handleSaveNewTab();
                  }}
                  style={{ marginRight: '2px', fontSize: '14px', width: '200px', height: '40px' }}
                  inputProps={{ style: { fontSize: '14px', height: '40px', padding: '10px' } }}
                />
                <IconButton onClick={handleSaveNewTab} style={{ marginLeft: '5px', padding: '2px' }}>
                  <SaveIcon style={{ fontSize: '20px' }} />
                </IconButton>
              </Box>
            ) : (
              <>
                <IconButton onClick={handleAddNewTabClick} style={{ margin: '5px', padding: '2px' }}>
                  <AddIcon style={{ fontSize: '20px' }} />
                  <span style={{ fontSize: '18px', marginLeft: '5px', color: '#000000' }}>New</span>
                </IconButton>
              </>
            )}
          </Box>

          {showScrollButtons && (
            <IconButton onClick={scrollRight}>
              <ArrowForwardIosIcon style={{ fontSize: '20px' }} />
            </IconButton>
          )}
        </Box>

        <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose} message={snackbarMessage} />
      </Box>
    </DndProvider>
  );
};

TabsModules.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      pinned: PropTypes.bool,
      isEditing: PropTypes.bool,
    })
  ).isRequired,
  saveTab: PropTypes.func.isRequired,
  setTabs: PropTypes.func.isRequired,
  updateNameTab: PropTypes.func.isRequired,
  onTabSelect: PropTypes.func.isRequired,
  saveTabPositions: PropTypes.func.isRequired,
  onTabAction: PropTypes.func.isRequired,
  deleteTab: PropTypes.func.isRequired,
};

export default TabsModules;
