import React, { useCallback, useEffect, useMemo, useState } from 'react';
import shortid from 'shortid';
import PropTypes from 'prop-types';

import { handleMoveWithinParent } from '../../formEngine/utils/helpers';
import OfficerStandard from './OfficerStandard';
import { FORM_TYPE_VERSION_2 } from '../../config/config';
import CustomOfficer from './CustomOfficer';

export default function OfficerUpdate({ selected, id, formInfo, isDefaultForm, setFormInfo }) {
  const [layouts, setLayouts] = useState({});
  const [updatedPage, setUpdatedPage] = useState('');

  const selectedId = useMemo(() => {
    if (selected.name === 'Custom' && selected.pageIndex === 0 && formInfo?.version !== FORM_TYPE_VERSION_2)
      return selected.pageIndex;

    return typeof selected === 'object' ? selected.pageId : selected;
  }, [selected, formInfo?.version]);

  const onSetLayout = useCallback(
    (layout) => {
      if (selectedId !== undefined) {
        setLayouts((_layouts) => ({
          ..._layouts,
          [selectedId]: layout,
        }));
      }
    },
    [selectedId]
  );

  const onGetLayout = useCallback(
    () =>
      selected.moveComponents ? selected.sections : layouts[selectedId] || [{ name: 'Section Name', components: [] }],
    [layouts, selectedId, selected]
  );

  const onUpdateFormInfo = useCallback(
    (layoutCopy) => {
      if (formInfo?.version === FORM_TYPE_VERSION_2) {
        setFormInfo((_formInfo) => ({
          ..._formInfo,
          officersCustomFields: [
            ..._formInfo?.officersCustomFields.map((page) => {
              if (selected.pageId === page.pageId) {
                return {
                  ...page,
                  sections: layoutCopy,
                };
              }

              return page;
            }),
          ],
        }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formInfo]
  );

  useEffect(() => {
    if (formInfo && selected && updatedPage !== selected.pageId) {
      const layoutCopy = Array.from(onGetLayout());
      const selectedComponents = formInfo?.officersCustomFields[selected?.pageIndex]?.sections?.[0]?.components || [];
      const _components = formInfo.version === FORM_TYPE_VERSION_2 ? selectedComponents || [] : formInfo?.officersCustomFields;
      if (_components.length > 0 && layoutCopy?.[0]?.components?.length === 0) {
        layoutCopy[0].components =
          _components?.map((field) => ({
            ...field,
          })) || [];
      }
      setUpdatedPage(selected.pageId);
      onSetLayout(layoutCopy);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formInfo, selected]);

  useEffect(() => {
    if (
      formInfo &&
      selected &&
      selected.name === 'Custom' &&
      selected.pageIndex === 0 &&
      formInfo.version !== FORM_TYPE_VERSION_2
    ) {
      const layoutCopy = Array.from(onGetLayout());
      const _components = formInfo?.officersCustomFields;

      layoutCopy[0].components =
        _components?.map((field) => ({
          ...field,
        })) || [];

      onSetLayout(layoutCopy);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formInfo, selected]);

  const handleSelectComponent = (component) => {
    const newComponents = [...onGetLayout()[0].components, component];
    const layoutCopy = Array.from(onGetLayout());
    layoutCopy[0].components = newComponents;
    onUpdateFormInfo(layoutCopy);
    onSetLayout(layoutCopy);
  };

  const handleDuplicateComponent = (path, index) => {
    const layoutCopy = JSON.parse(JSON.stringify(onGetLayout()));
    const componentsCopy = [...layoutCopy[0].components];
    const component = { ...componentsCopy[index], id: shortid.generate() };

    layoutCopy[0].components.splice(index, 0, component);

    onUpdateFormInfo(layoutCopy);
    onSetLayout(layoutCopy);
  };

  const handleDeleteComponent = (componentIndex) => {
    const layoutCopy = Array.from(onGetLayout());
    const componentsCopy = Array.from(onGetLayout()?.[0]?.components);
    componentsCopy.splice(componentIndex, 1);
    layoutCopy[0].components = componentsCopy;
    onUpdateFormInfo(layoutCopy);
    onSetLayout(layoutCopy);
  };

  const onUpdateJson = (componentIndex, data) => {
    setLayouts((prevState) => {
      const prevStateCopy = [...prevState[selectedId]];
      const componentsCopy = [...prevStateCopy[0].components];
      if (componentsCopy[componentIndex]) {
        componentsCopy[componentIndex].data = data;
      }
      prevStateCopy[0].components = componentsCopy;

      return { ...prevState, [selectedId]: prevStateCopy };
    });
  };

  const handleDrop = useCallback(
    (dropZone, item) => {
      const splitDropZonePath = dropZone.path.split('-');
      const pathToDropZone = splitDropZonePath.slice(0, -1).join('-');
      // move down here since sidebar items dont have path
      const splitItemPath = item.path.split('-');
      const pathToItem = splitItemPath.slice(0, -1).join('-');

      // 2. Pure move (no create)
      if (splitItemPath.length === splitDropZonePath.length) {
        // 2.a. move within parent
        if (pathToItem === pathToDropZone) {
          const _layout = onGetLayout();
          const newLayout = handleMoveWithinParent(_layout, splitDropZonePath, splitItemPath);

          onSetLayout(newLayout);
          onUpdateFormInfo(newLayout);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [layouts]
  );

  const onChangeSelected = useCallback(() => {
    if (selected && selected.name !== 'Standard') {
      const _customFields = [...formInfo?.officersCustomFields];

      if (selected.moveComponents) {
        const indexes = selected.moveComponents.item?.path?.split('-')?.map((_index) => parseInt(_index));
        const page = _customFields[indexes[0]];
        const newSections = [
          {
            ...page?.sections?.[0],
            components: page?.sections?.[0].components?.filter((_, componentIndex) => componentIndex !== indexes[1]),
          },
        ];

        _customFields[indexes[0]] = {
          ...page,
          sections: newSections,
        };

        setLayouts((_layouts) => ({
          ..._layouts,
          [page.pageId]: newSections,
        }));
      }

      _customFields[selected.pageIndex] = {
        active: selected.active,
        name: selected.name,
        pageId: selected.pageId,
        sections: selected.moveComponents ? selected.sections : _customFields[selected.pageIndex]?.sections,
      };

      setFormInfo({ ...formInfo, officersCustomFields: _customFields });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formInfo, selected]);

  useEffect(() => {
    onChangeSelected();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected.pageId]);

  if (selected?.name === 'Standard') {
    return (
      <OfficerStandard
        id={id}
        formInfoUpdate={formInfo?.officersAllegationOptions}
        isDefaultForm={isDefaultForm}
        setFormInfo={setFormInfo}
      />
    );
  }

  return (
    <CustomOfficer
      label="Officer"
      showHeaders
      formInfo={formInfo}
      selected={selected}
      layouts={layouts}
      components={onGetLayout()[0].components}
      isDefaultForm={isDefaultForm}
      path={selected?.pageIndex?.toString() || '0'}
      id={id}
      layout={onGetLayout()}
      handleDuplicateComponent={handleDuplicateComponent}
      handleDrop={handleDrop}
      handleDeleteComponent={handleDeleteComponent}
      handleSelectComponent={handleSelectComponent}
      onUpdateJson={onUpdateJson}
    />
  );
}

OfficerUpdate.propTypes = {
  id: PropTypes.string,
  selected: PropTypes.any,
  formInfo: PropTypes.oneOfType,
  setFormInfo: PropTypes.func,
  isDefaultForm: PropTypes.bool,
};
