/* eslint-disable no-useless-catch */
import { unarmed, unarmedFile, unarmedWithToken, unarmedWithTokenAndDownloadFile } from './axios';

export const uploadOrganizationImg = async (img, token, id) => {
  try {
    const { data } = await unarmedWithToken(token).put(`/organizations/${id}/image`, img);
    return data.public_url;
  } catch (error) {
    console.log(error);
  }
};

export const getOrganizationsByState = async (subdomain) => {
  try {
    const { data, headers } = await unarmed().get(`/organizations/subdomain/${subdomain}`);
    return { headers, data: data[0] };
  } catch (error) {
    console.log(error);
  }
};

export const getFilterByCase = async () => {
  try {
    const { data } = await unarmedWithToken().get(`/forms/filters`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getUsage = async (idOrga) => {
  try {
    const { data } = await unarmedWithToken().get(`/organizations/${idOrga}/usage`);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const resendUserInvitation = async (id) => {
  try {
    const { data } = await unarmedWithToken().post(`/invitations/${id}/resend`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getAvailableFormType = (orgId) => unarmedWithToken().get(`/form-types/available?organizationId=${orgId}`);

const getOptionsEndpoint = (option, formTypeId) => {
  switch (option) {
    case 'Cases':
      return `/forms/import/options?formTypeId=${formTypeId}`;

    case 'Officers':
      return '/officers/import/options';

    case 'Users':
      return '/users/import/options';

    default:
      throw new Error(`Unsupported option on type ${option}`);
  }
};

export const getImportOptions = async (option, formTypeId) => {
  try {
    const { data } = await unarmedWithToken().get(getOptionsEndpoint(option, formTypeId));
    return data;
  } catch (error) {
    throw error;
  }
};

export const createOrganization = (organization, token) => unarmedWithToken(token).post('/organizations', organization);

export const getFormInfo = (id, token) => {
  if (token) {
    return unarmedWithToken(token).get(`/forms/${id}`);
  }

  return unarmed().get(`/forms/${id}`);
};

export const getCivilianCases = ({
  token,
  page,
  limit,
  sortBy,
  sort,
  isFiltering,
  type,
  from,
  to,
  code,
  status,
  name,
  assignedTo,
  source = [],
  reporter = [],
  investigator = [],
  allegation = [],
  disposition = [],
  discipline = [],
  badgeNumber,
  archivedOnly,
  transferredOnly,
  flaggedOnly,
  subType = [],
  contact,
  filtersConfigId,
}) => {
  const params = new URLSearchParams({
    page,
    limit,
    sortBy,
    sortOrder: sort,
  });

  if (isFiltering) {
    if (type && type !== '0') {
      params.append('type', type);
    }

    subType.forEach((item) => {
      if (item.value === 'Not Set') {
        params.append('subtype[]', 'null');
      } else {
        params.append('subtype[]', item);
      }
    });

    if (from) params.append('from', from);
    if (to) params.append('to', to);
    if (code) params.append('code', code);
    if (name) params.append('name', name);
    if (status && status !== '0') {
      params.append('status', status);
    }
    if (assignedTo) {
      params.append('assignedTo', assignedTo);
    }

    source.forEach((item) => {
      if (item.value === 'Not Set') {
        params.append('source[]', 'null');
      } else {
        params.append('source[]', item);
      }
    });

    reporter.forEach((item) => {
      if (item.value === 'Not Set') {
        params.append('reporter[]', 'null');
      } else {
        params.append('reporter[]', item);
      }
    });

    investigator.forEach((item) => {
      if (item.value === 'Not Set') {
        params.append('investigator[]', 'null');
      } else {
        params.append('investigator[]', item);
      }
    });

    allegation.forEach((item) => {
      if (item.value === 'Not Set') {
        params.append('allegations[]', 'null');
      } else {
        params.append('allegations[]', item);
      }
    });

    disposition.forEach((item) => {
      if (item.value === 'Not Set') {
        params.append('dispositions[]', 'null');
      } else {
        params.append('dispositions[]', item);
      }
    });

    discipline.forEach((item) => {
      if (item.value === 'Not Set') {
        params.append('disciplines[]', 'null');
      } else {
        params.append('disciplines[]', item);
      }
    });

    if (badgeNumber) params.append('badgeNumber', badgeNumber);
    if (archivedOnly) params.append('archivedOnly', archivedOnly);
    if (transferredOnly) params.append('transferredOnly', transferredOnly);
    if (flaggedOnly) params.append('flaggedOnly', flaggedOnly);
  }

  if (contact) {
    params.append('contacts[]', contact);
  }

  if (filtersConfigId) {
    params.append('filtersConfigId', filtersConfigId);
  }

  const URL = `/forms?${params.toString()}`;

  return unarmedWithToken(token).get(URL);
};

export const getMe = (token) => unarmedWithToken(token).get('/me');

export const getUsers = ({
  page = null,
  limit = null,
  sortBy = null,
  sort = null,
  from = null,
  to = null,
  name = null,
  role = null,
  email = null,
  phone = null,
  flaggedOnly = null,
  isFiltering = null,
}) => {
  if (!page || !limit) {
    return unarmedWithToken().get(`/users`);
  }

  const URL = `/users?page=${page}&limit=${limit}&sortBy=${sortBy}&sortOrder=${sort}`;

  const config = {};

  if (isFiltering) {
    if (from) {
      config.startDate = from;
    }
    if (to) {
      config.endDate = to;
    }

    if (email) {
      config.email = email;
    }
    if (role && role !== '0') {
      config.role = role;
    }

    if (phone) {
      config.phoneNumber = phone;
    }

    if (name) {
      config.name = name;
    }

    if (flaggedOnly) {
      config.flaggedOnly = flaggedOnly;
    }
  }
  return unarmedWithToken().get(URL, {
    params: { ...config },
  });
};

export const getUsersSearch = (limit, sortBy, sort, value) =>
  unarmedWithToken().get('/users/search', {
    params: {
      limit,
      sortBy,
      value,
      sortOrder: sort,
    },
  });

export const getUsersInvitations = (
  page,
  limit,
  sortBy,
  sort,
  from,
  to,
  name,
  role,
  email,
  phone,
  isFiltering,
  flaggedOnly = false
) => {
  const URL = `/invitations?page=${page}&limit=${limit}&sortBy=${
    sortBy === 'phoneNumber' ? 'phone' : sortBy
  }&sortOrder=${sort}`;

  const config = {};

  if (isFiltering) {
    if (from) {
      config.startDate = from;
    }
    if (to) {
      config.endDate = to;
    }

    if (email) {
      config.email = email;
    }
    if (role && role !== '0') {
      config.type = role;
    }
    if (phone) {
      config.phone = phone;
    }

    if (name) {
      config.name = name;
    }

    if (flaggedOnly) {
      config.flaggedOnly = flaggedOnly;
    }
  }
  return unarmedWithToken().get(URL, {
    params: { ...config },
  });
};

export const getAllegations = ({
  page = 1,
  limit = 10,
  from,
  to,
  sort = 'asc',
  sortBy = 'createdAt',
  isFiltering,
  badge,
  officerName,
  status,
  disposition,
  allegation,
  discipline,
  trackingNumber,
  flaggedOnly = false,
  type,
}) => {
  const URL = `/allegations?page=${page}&limit=${limit}&sortBy=${sortBy}&sortOrder=${sort}`;
  const config = {};
  if (isFiltering) {
    if (from) {
      config.startDate = from;
    }
    if (to) {
      config.endDate = to;
    }
    if (badge) {
      config.badgeNumber = badge;
    }
    if (trackingNumber) {
      config.code = trackingNumber;
    }
    if (officerName) {
      config.name = officerName;
    }

    if (status && status !== '0') {
      config.status = status;
    }
    if (type && type !== '0') {
      config.type = type;
    }
    if (allegation && allegation !== '0') {
      config.allegation = allegation;
    }
    if (disposition && disposition !== '0') {
      config.disposition = disposition;
    }
    if (discipline && discipline !== '0') {
      config.discipline = discipline;
    }

    if (flaggedOnly) {
      config.flaggedOnly = flaggedOnly;
    }
  }
  /* if (filterConfigId) {
    config.filtersConfigId = filterConfigId;
  } */

  return unarmedWithToken().get(URL, {
    params: { ...config },
  });
};

export const getOfficers = ({
  includeTotalCases,
  page,
  limit,
  sort = 'desc',
  sortBy = 'badgeNumber',
  isFiltering,
  flaggedOnly = false,
  name,
  badge,
}) => {
  const config = {};

  if (isFiltering) {
    if (name) {
      config.name = `${name}`;
    }

    if (badge) {
      config.badgeNumber = badge;
    }

    if (flaggedOnly) {
      config.flaggedOnly = flaggedOnly;
    }
  }
  if (includeTotalCases) {
    return unarmedWithToken().get(
      `/officers?page=${page}&limit=${limit}&includeTotalCases=true&sortBy=${sortBy}&sortOrder=${sort}`,
      { params: { ...config } }
    );
  }
  return unarmedWithToken().get(`/officers?page=${page}&limit=${limit}&sortBy=${sortBy}&sortOrder=${sort}`, {
    params: { ...config },
  });
};

export const onUpdateStatus = (token, id, data) => unarmedWithToken(token).put(`/forms/${id}`, data);

export const onUpdateFormTask = (token, id, data) => unarmedWithToken(token).put(`/forms/${id}/task`, data);

export const onUpdateFaqs = (data) => unarmedWithToken().put(`/faq`, data);
export const onGetFaqs = (organizationId) => unarmedWithToken().get(`/faq?organizationId=${organizationId}`);

export const asignUserToForm = (token, id, data) => unarmedWithToken(token).put(`/forms/${id}`, data);

export const deleteAssignedUser = (token, id, data) => unarmedWithToken(token).put(`/forms/${id}`, data);

export const getFormsRange = (token, ids) => unarmedWithToken(token).get(`/forms?${ids}`);

export const getAllegationRange = (token, ids) => unarmedWithToken(token).get(`/allegations?${ids}`);

export const getFormLog = (token, id, page, limit) =>
  unarmedWithToken(token).get(`/forms/${id}/log?limit=${limit}&page=${page}`);

export const updateForm = (id, data) => unarmedWithToken().put(`/forms/${id}`, data);

export const onUpdateFormType = (id, type) =>
  unarmedWithToken().put(`/forms/${id}`, {
    type,
  });

export const onTransferCase = (id, data) => unarmedWithToken().put(`/forms/${id}/transfer`, data);
export const onDuplicateCase = (id, data) => unarmedWithToken().post(`/forms/${id}/duplicate`, data);

export const updateFormComments = (id, token, files, comment) => {
  if (comment) {
    return unarmedWithToken().put(`/forms/${id}/comment`, {
      comment,
      files,
    });
  }
  return unarmedWithToken().put(`/forms/${id}/comment`, {
    files,
  });
};

export const editFormComment = (id, commentId, comment) =>
  unarmedWithToken().put(`/forms/${id}/comment/${commentId}`, {
    comment,
  });

export const createOfficer = (badgeNumber, firstName, lastName, email, phone, gender, race) =>
  unarmedWithToken().post('/officers', {
    badgeNumber,
    firstName,
    lastName,
    ...(email && { email }),
    ...(phone && { phoneNumber: phone }),
    ...(gender && { gender }),
    ...(race && { raceEthnicity: race }),
  });

export const onSearchOfficer = (text) => unarmedWithToken().get(`/officers?text=${text}`);

export const addRowOfficer = (idForm, officerId) =>
  unarmedWithToken().put(`/forms/${idForm}`, { allegationRow: { officerId } });

export const assignOfficer = (id, officerId) =>
  unarmedWithToken().put(`/forms/${id}`, {
    assignOfficers: [officerId],
  });
export const unassingOfficer = (id, officerId) =>
  unarmedWithToken().put(`/forms/${id}`, {
    unassignOfficers: [officerId],
  });

export const updateOfficerRow = (id, rowData) => unarmedWithToken().put(`/forms/${id}`, { allegationRow: rowData });


export const updateOfficerCustomFields = (id, rowData) => unarmedWithToken().put(`/forms/${id}`, { officerCustomFields: rowData });

export const deleteAnAllegationRow = (id, rowData) =>
  unarmedWithToken().put(`/forms/${id}`, { allegationRow: rowData });

export const uploadFormImg = async (img, setLoaderPercentage = () => {}) => {
  const { data } = await unarmedFile().post('/files', img, {
    onUploadProgress: (progressEvent) => {
      setLoaderPercentage(Math.round((progressEvent.loaded * 100) / progressEvent.total));
    },
  });

  return {
    _id: data._id,
    url: data.url,
    name: data.name,
    size: data.size,
    hashId: data.hashid,
    publicUrl: data.public_url,
    previewUrl: data.preview_url,
    mimetype: data.mimetype,
  };
};

export const onSearchGeneral = (text, params) =>
  unarmedWithToken().get(`/search?text=${text}`, {
    params,
  });

export const getForms = () => unarmedWithToken().get(`/forms/count`);
export const getRoles = () => unarmedWithToken().get(`/roles`);
export const updateProfile = (data) => unarmedWithToken().put(`/me`, data);
export const deleteUser = (ids) => unarmedWithToken().delete(`/users?${ids}`);
export const deleteOfficer = (ids) => unarmedWithToken().delete(`/officers?${ids}`);
export const deleteForm = (ids) => unarmedWithToken().delete(`/forms?${ids}`);

export const createUserInvitation = (data) => unarmedWithToken().post('/invitations', data);

export const getSingleUser = (id) => unarmedWithToken().get(`/users/${id}`);
export const getInvitationUser = (id) => unarmedWithToken().get(`/invitations/${id}`);

export const getInvitiation = (query) => unarmed().get(`/invitation${query}`);

export const signupInvtion = (data, isWithSocial, token) => {
  if (isWithSocial) {
    return unarmed(token).post('/signup/invitation', data);
  }
  return unarmed().post('/signup/invitation', data);
};

export const deleteInvitationUser = (id) => unarmedWithToken().delete(`/invitations?${id}`);

export const getOfficer = async (id) => {
  try {
    const { data } = await unarmedWithToken().get(`/officers/${id}`);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getOfficerStatus = (id) => unarmedWithToken().get(`/officers/${id}/stats`);

export const onUpdateOrganization = async (id, data) => {
  try {
    const res = unarmedWithToken().put(`/organizations/${id}`, data);
    return res;
  } catch (error) {
    throw error;
  }
};

const getImportEndpoint = (option) => {
  switch (option) {
    case 'Cases':
      return '/forms/import';

    case 'Officers':
      return '/officers/import';

    case 'Users':
      return '/users/import';

    default:
      throw new Error(`Unsupported option on type ${option}`);
  }
};

export const onImport = async (forms, module) => {
  try {
    const { data } = await unarmedWithToken().post(getImportEndpoint(module), forms);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getActivity = async ({ userId, formCode, formId, module, limit, endDate, before, startDate, project }) => {
  const URL = `/activity`;
  const config = {};

  if (limit) {
    config.limit = limit;
  }

  if (before) {
    config.before = before;
  }

  if (formCode) {
    config.formCode = formCode;
  }

  if (formId) {
    config.formId = formId;
  }

  if (userId) {
    config.userId = userId;
  }

  if (module && module !== '0') {
    config.module = module;
  }

  if (endDate) {
    config.endDate = endDate;
  }

  if (startDate) {
    config.startDate = startDate;
  }

  if (project) {
    config.project = project;
  }

  const { data } = await unarmedWithToken().get(URL, {
    params: { ...config },
  });

  return data;
};

export const onTranslate = async (text, target) => {
  try {
    const { data } = await unarmedWithToken().post('/translate', target ? { text, target } : { text });

    return data;
  } catch (error) {
    throw error;
  }
};

export const onChangeAttachments = async (id, value) => {
  try {
    const { data } = await unarmedWithToken().put(`/organizations/${id}`, {
      features: {
        general: {
          attachments: value,
        },
      },
    });

    return data;
  } catch (error) {
    throw error;
  }
};

export const onGetActivityToExport = async ({ userId, formCode, module, limit, endDate, before, startDate }) => {
  const URL = `/activity/export`;
  const config = {};
  if (limit) {
    config.limit = limit;
  }
  if (before) {
    config.before = before;
  }

  if (formCode) {
    config.formCode = formCode;
  }
  // if (isFiltering) {
  if (userId) {
    config.userId = userId;
  }
  if (module && module !== '0') {
    config.module = module;
  }

  if (endDate) {
    config.endDate = endDate;
  }
  if (startDate) {
    config.startDate = startDate;
  }

  try {
    const { data } = await unarmedWithTokenAndDownloadFile().get(URL, {
      params: { ...config },
    });

    return data;
  } catch (error) {
    throw error;
  }
};

export const onUpdateOfficerProfile = async (id, profileData) => {
  try {
    const { data } = await unarmedWithToken().put(
      `/officers/${id}
    `,
      profileData
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const getOrganizations = (limit) => {
  const URL = `/organizations`;
  const config = {};
  if (limit) {
    config.limit = limit;
  }

  return unarmedWithToken().get(URL, {
    params: { ...config },
  });
};

export const getQuestionBank = () => unarmedWithToken().get(`/questions`);

export const createReport = async (reqData) => unarmedWithToken().post('/adhoc-reports', reqData);
export const duplicateReport = async (reqData) => unarmedWithToken().post('/adhoc-reports/duplicates', reqData);

export const getReports = (page, limit, sort, sortBy) =>
  unarmedWithToken().get(`/adhoc-reports?page=${page}&limit=${limit}&sortBy=${sortBy}&sortOrder=${sort}`);

export const getReportsbyid = (id) => unarmedWithToken().get(`/adhoc-reports/${id}`);

export const deleteReport = (ids) => unarmedWithToken().delete(`/adhoc-reports?${ids}`);
export const deleteReportbyId = (id) => unarmedWithToken().delete(`/adhoc-reports/${id}`);

export const getFormNotesLogs = (id) => unarmedWithToken().get(`/forms/${id}/notes`);
export const getFormTypes = (page, limit, sort, sortBy) =>
  unarmedWithToken().get(`/form-types?page=${page}&limit=${limit}&sortBy=${sortBy}&sortOrder=${sort}`);

export const getFormAttachments = (id, source) => {
  const URL = `/forms/${id}/attachments`;
  const config = {};
  if (source) {
    config.source = source;
  }
  return unarmedWithToken().get(URL, {
    params: { ...config },
  });
};
export const getFormAttachmentsDownload = (id) =>
  unarmedWithToken().get(`/forms/${id}/attachments?download=true`, {
    responseType: 'arraybuffer',
  });

export const updateFormTypes = (id, data) => unarmedWithToken().put(`/form-types/${id}`, data);

export const createFormTypes = (data) => unarmedWithToken().post(`/form-types`, data);

export const createFormType = (form) => unarmedWithToken().post('/forms', form);

export const deleteFormTypes = (ids) => unarmedWithToken().delete(`/form-types?${ids}`);

export const onSaveFormType = (json, id) => unarmedWithToken().put(`/form-types/${id}`, json);

export const getFormType = (id) => unarmedWithToken().get(`/form-types/${id}`);

export const getAvailableForms = (orgId) => unarmed().get(`/form-types/available?organizationId=${orgId}`);

export const changeUserpasswordFromProfile = (data) => unarmedWithToken().put('/me/password', data);

export const getPasswordReset = (email, subdomain) =>
  unarmed().get(`/password/reset`, {
    params: { email, subdomain },
  });

export const onPasswordReset = (data) => unarmed().post('/password/reset', data);

export const onArchive = (id) => unarmedWithToken().post(`/forms/${id}/archive`, {});

export const onUnArchive = (id) => unarmedWithToken().post(`/forms/${id}/unarchive`, {});

export const onArchiveNoteForm = (formId,commentId) => unarmedWithToken().put(`/forms/${formId}/comment/${commentId}/archive`);

export const onUnArchiveNoteForm = (formId,commentId) => unarmedWithToken().put(`/forms/${formId}/comment/${commentId}/unarchive`);


export const onArchiveNoteContact = (contactId,commentId) => unarmedWithToken().put(`/contacts/${contactId}/comment/${commentId}/archive`);

export const onUnArchiveNoteContact = (contactId,commentId) => unarmedWithToken().put(`/contacts/${contactId}/comment/${commentId}/unarchive`);

export const onArchiveNoteProject = (projectId,commentId) => unarmedWithToken().put(`/projects/${projectId}/notes/${commentId}/archive`);

export const onUnArchiveNoteProject = (projectId,commentId) => unarmedWithToken().put(`/projects/${projectId}/notes/${commentId}/unarchive`);

export const onArchiveNoteOfficer = (officerId,commentId) => unarmedWithToken().put(`/officers/${officerId}/comment/${commentId}/archive`);

export const onUnArchiveNoteOfficer = (officerId,commentId) => unarmedWithToken().put(`/officers/${officerId}/comment/${commentId}/unarchive`);

export const onGetProjects = (page, limit, sortBy, sort, contact) => {
  let query = '';
  if (page) {
    query += `?page=${page}`;
  }
  if (limit) {
    query += `&limit=${limit}`;
  }
  if (sortBy) {
    query += `&sortBy=${sortBy}`;
  }
  if (sort) {
    query += `&sortOrder=${sort}`;
  }
  if (contact) {
    query += `&contacts[]=${contact}`;
  }
  return unarmedWithToken().get(`/projects${query}`);
};

export const onCreateProject = (data) => unarmedWithToken().post(`/projects`, data);

export const onArchiveProject = (ids) => unarmedWithToken().delete(`/projects?${ids}`);

export const getProjectInfo = (id) => unarmedWithToken().get(`/projects/${id}`);

export const assignUserToProject = (id, data) => unarmedWithToken().post(`/projects/${id}/assigned-users`, data);

export const updateProject = (id, data) => unarmedWithToken().put(`/projects/${id}`, data);

export const onSearchTemplate = (template) => unarmedWithToken().get(`/project-templates-search?name=${template}`);

export const createProjectTemplate = (template) => unarmedWithToken().post('/project-templates', template);

export const createProjectNote = (id, note) => unarmedWithToken().post(`/projects/${id}/notes`, note);

export const addProjectAttachments = (id, attachments) =>
  unarmedWithToken().post(`/projects/${id}/attachments`, attachments);

export const addRecommendation = (projectId) => unarmedWithToken().post(`/projects/${projectId}/recommendations`);

export const getProjectHistory = (projectId) => unarmedWithToken().get(`/activity?project=${projectId}`);

export const updateAction = (id, actionId, data) => unarmedWithToken().put(`/projects/${id}/actions/${actionId}`, data);

export const updateRecommendation = (projectId, recommendationId, data) =>
  unarmedWithToken().put(`/projects/${projectId}/recommendations/${recommendationId}`, data);
export const deleteRecommendation = (projectId, recommendationId) =>
  unarmedWithToken().delete(`/projects/${projectId}/recommendations/${recommendationId}`);

export const getProjectFileAttachments = (projectId) =>
  unarmedWithToken().get(`/projects/${projectId}/attachments?download=true&base=true&actions=true&notes=true`, {
    responseType: 'arraybuffer',
  });

export const addAction = (id, actionId, data) =>
  unarmedWithToken().put(`/projects/${id}/actions/${actionId}/attachments`, data);

export const getProjectFileAttachmentsToShow = (projectId) =>
  unarmedWithToken().get(`/projects/${projectId}/attachments?base=true&actions=true&notes=true`);

export const getNotificationCount = () => unarmedWithToken().get(`/notifications-count`);

export const getOfficerOptions = () => unarmedWithToken().get(`/officers/options`);

export const getFormOptionsType = () => unarmedWithToken().get(`/forms/options`);

export const getNotification = ({ limit, before }) => {
  const URL = `/notifications`;
  const config = {};

  if (limit) {
    config.limit = limit;
  }
  if (before) {
    config.before = before;
  }
  return unarmedWithToken().get(URL, {
    params: { ...config },
  });
};

export const updateProjectActions = (id, data) => {
  return unarmedWithToken().put(`/projects/${id}/actions`, data);
};

export const addOfficerComments = (id, data) => unarmedWithToken().post(`/officers/${id}/comment`, data);

export const getOfficerLogs = (id, type, limit, before) => {
  const config = {};
  if (limit) {
    config.limit = limit;
  }
  if (before) {
    config.before = before;
  }

  return unarmedWithToken().get(`/officers/${id}/${type}`, {
    params: { ...config },
  });
};

export const getOfficerActivity = (id, limit, before) => {
  const config = {};
  if (limit) {
    config.limit = limit;
  }
  if (before) {
    config.before = before;
  }

  return unarmedWithToken().get(`/officers/${id}/activity`, {
    params: { ...config },
  });
};

export const getOfficerAttachments = (id) => unarmedWithToken().get(`/officers/${id}/attachments`);

export const getOfficerFileAttachments = (id) =>
  unarmedWithToken().get(`/officers/${id}/attachments?download=true`, {
    responseType: 'arraybuffer',
  });

export const saveOfficerAttachments = (id, data) => unarmedWithToken().post(`/officers/${id}/attachments`, data);

export const getFormTypeOptions = (id) => unarmedWithToken().get(`/form-types/${id}/options/autopopulate`);

export const getCaseNumberPreview = (data) => unarmedWithToken().post(`/form-types/autopopulate-preview`, data);

export const updateAttachmentTags = (formID, id, data) =>
  unarmedWithToken().put(`/forms/${formID}/attachments/${id}/tags`, data);

export const updateContactAttachmentTags = (contactId, id, data) =>
  unarmedWithToken().put(`/contacts/${contactId}/attachments/${id}/tags`, data);

export const updateOfficersAttachmentTags = (officerID, id, data) =>
  unarmedWithToken().put(`/officers/${officerID}/attachments/${id}/tags`, data);

export const updateProjectsAttachmentTags = (projectID, id, data) =>
  unarmedWithToken().put(`/projects/${projectID}/attachments/${id}/tags`, data);

export const fetchTags = (params) => unarmedWithToken().get(`/tags/attachments`, { params });

export const createTag = (data) => unarmedWithToken().post('/tags/attachments', data);

/**
 * Update case details by sending a request to the server.
 *
 * @param {string} formId - The ID of the form associated with the case details.
 * @param {Object} details - The updated case details, including pageIndex and page.
 * @returns {Promise} - A Promise that resolves with the server response.
 */
export const updateCaseDetails = (formId, data) => unarmedWithToken().put(`/forms/${formId}/data`, data);

/**
 * Retrieves a list of contacts from the server.
 *
 * @param {Object} params - Optional parameters for customizing the request.
 * @returns {Promise} A Promise that resolves with the response data containing the list of contacts.
 */
export const fetchContacts = (params) => unarmedWithToken().get('/contacts', { params });

/**
 * Creates a new contact on the server.
 *
 * @param {Object} data - The data representing the new contact to be created.
 * @returns {Promise} A Promise that resolves with the response data containing details of the created contact.
 */
export const createContact = (data) => unarmedWithToken().post('/contacts', data);

/**
 * Updates an existing contact on the server.
 *
 * @param {string} id - The unique identifier of the contact to be updated.
 * @param {Object} data - The data representing the updated contact information.
 * @returns {Promise} A Promise that resolves with the response data containing details of the updated contact.
 */
export const updateContact = (id, data) => unarmedWithToken().put(`/contacts/${id}`, data);

/**
 * Deletes one or more contacts from the server.
 *
 * @param {string} ids - Query parameter containing the IDs of the contacts to be deleted.
 * @returns {Promise} A Promise that resolves with the response data indicating the success of the deletion.
 */
export const deleteContacts = (ids) => unarmedWithToken().delete(`/contacts?${ids}`);

/**
 * Retrieves details of a specific contact from the server.
 *
 * @param {string} id - The unique identifier of the contact to be retrieved.
 * @returns {Promise} A Promise that resolves with the response data containing details of the specified contact.
 */
export const fetchContact = (id) => unarmedWithToken().get(`/contacts/${id}`);

export const fetchContactCases = (id) => unarmedWithToken().get(`/forms?contacts[]=${id}`);

export const fetchContactProjects = (id) => unarmedWithToken().get(`/projects?contacts[]=${id}`);

export const fetchContactStats = (id) => unarmedWithToken().get(`/contacts/${id}/stats`);

export const fetchContactNotes = (id) => unarmedWithToken().get(`/contacts/${id}/notes`);

export const createContactComment = (id, data) => unarmedWithToken().post(`/contacts/${id}/comment`, data);

export const editContactComment = (id, commentId, data) =>
  unarmedWithToken().put(`/contacts/${id}/comment/${commentId}`, data);

export const fetchContactAttachments = (id) => unarmedWithToken().get(`/contacts/${id}/attachments`);

export const fetchContactHistory = (contactId) => unarmedWithToken().get(`/activity?contact=${contactId}`);

export const createLinkProjectContact = (projectId, contactId) =>
  unarmedWithToken().post(`/projects/${projectId}/contacts/${contactId}`);

export const deleteLinkProjectContact = (projectId, contactId) =>
  unarmedWithToken().delete(`/projects/${projectId}/contacts/${contactId}`);

export const createLinkCaseContact = (caseId, contactId) =>
  unarmedWithToken().post(`/forms/${caseId}/contacts/${contactId}`);

export const deleteLinkCaseContact = (caseId, contactId) =>
  unarmedWithToken().delete(`/forms/${caseId}/contacts/${contactId}`);

// Cube Buckets

export const fetchCubeBuckets = (params) =>
  unarmedWithToken().get('/cube-buckets', {
    params,
  });

export const fetchCubeBucketsDate = (params) =>
  unarmedWithToken().get('/cube-buckets?type=date', {
    params,
  });

export const createCubeBucket = (data) => unarmedWithToken().post('/cube-buckets', data);

export const updateFormTypesOrder = (ids) => unarmedWithToken().put('/form-types/order', ids);

export const reloadOrganizationCubes = (orgId) => unarmedWithToken().post(`/organizations/${orgId}/reload-cubejs`);

export const changeUserRole = (userId, role) => unarmedWithToken().put(`/users/${userId}/role`, { role });

export const userGetNotificationConfig = () => unarmedWithToken().get('/users/notifications/config');

export const userSaveNotificationConfig = (data) => unarmedWithToken().put('/users/notifications/config', data);

export const linkCase = (caseId, relatedCaseId) =>
  unarmedWithToken().put(`/forms/${caseId}/link`, { form: relatedCaseId });

export const unLinkCase = (caseId, relatedCaseId) =>
  unarmedWithToken().put(`/forms/${caseId}/unlink`, { form: relatedCaseId });

export const getUserTabs = (moduleName) => unarmedWithToken().get(`/user-tabs?moduleName=${moduleName}`);

export const createUserTabs = (data) => unarmedWithToken().post('/user-tabs', data);

export const updateUserTabs = (id, data) => unarmedWithToken().put('/user-tabs', id, data);
export const updateUserTabName = (id, name) => unarmedWithToken().put(`/user-tabs/${id}`, { name });
export const deleteUserTab = (tabId, moduleName) => unarmedWithToken().post(`/user-tabs/${tabId}`, { moduleName });

export const addReportInToDashboard = (data) => unarmedWithToken().post('dashboard-report', data);

export const updateDashboardReportSize = (id, data) =>
  unarmedWithToken().put(`/dashboard-report/${id}`, { size: data });

export const createDashboard = async (data) => unarmedWithToken().post('/dashboard', data);

export const createView = async (data) => unarmedWithToken().post('/views', data);
export const getViews = async () => unarmedWithToken().get('/views');
export const getViewByIdv = async (id) => unarmedWithToken().get(`/views/${id}`);
export const updateViewsById = async (id, data) => {
  try {
    const response = await unarmedWithToken().put(`/views/${id}`, data);
    return response.data;
  } catch (error) {
    console.error('Error updating view:', error.response?.data || error.message);
    throw error;
  }
};

export const deleteView = async (id) => unarmedWithToken().delete(`/views/delete/${id}`);

export const getDashboards = (page, limit, sort, sortBy) =>
  unarmedWithToken().get(`/dashboard?page=${page}&limit=${limit}&sortBy=${sortBy}&sortOrder=${sort}`);

export const getDashboardsExplore = () => unarmedWithToken().get(`/dashboard`);

export const getReportsExplore = (page) => unarmedWithToken().get(`/adhoc-reports?page=${page}`);

export const getDashboardReports = (id) => unarmedWithToken().get(`/dashboard/${id}`);

export const getDashboardbyId = (id) => unarmedWithToken().get(`/dashboard/${id}`);
export const updateDashboard = (id, data) => unarmedWithToken().put(`/dashboard/${id}`, data);
export const deleteDashboard = (ids) => unarmedWithToken().post(`/dashboard/delete`, ids);
export const addDashboardReport = (id) => unarmedWithToken().post(`dashboard-report`, id);
export const deleteDashboardReport = (ids) => unarmedWithToken().post(`/dashboard-report/delete`, ids);

export const updateNotesSortOrder = async (userId, module, updatedSortOrder) => {
  try {
    const response = await unarmedWithToken().put(`/users/${userId}/notes-sort-order`, {
      module,
      updatedSortOrder,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const archiveCaseAttachment = (caseId, attachmentId) =>
  unarmedWithToken().put(`/forms/${caseId}/attachments/${attachmentId}/archive`);

export const unarchiveCaseAttachment = (caseId, attachmentId) =>
  unarmedWithToken().put(`/forms/${caseId}/attachments/${attachmentId}/unarchive`);

export const archiveProjectAttachment = (projectId, attachmentId) =>
  unarmedWithToken().put(`/projects/${projectId}/attachments/${attachmentId}/archive`);

export const unarchiveProjectAttachment = (projectId, attachmentId) =>
  unarmedWithToken().put(`/projects/${projectId}/attachments/${attachmentId}/unarchive`);

export const archiveContactAttachment = (contactId, attachmentId) =>
  unarmedWithToken().put(`/contacts/${contactId}/attachments/${attachmentId}/archive`);

export const unarchiveContactAttachment = (contactId, attachmentId) =>
  unarmedWithToken().put(`/contacts/${contactId}/attachments/${attachmentId}/unarchive`);

export const archiveOfficerAttachment = (officerId, attachmentId) =>
  unarmedWithToken().put(`/officers/${officerId}/attachments/${attachmentId}/archive`);

export const unarchiveOfficerAttachment = (officerId, attachmentId) =>
  unarmedWithToken().put(`/officers/${officerId}/attachments/${attachmentId}/unarchive`);

export const getfiltersConfigById = (id) => unarmedWithToken().get(`/filter-config/${id}`);
export const updateFilterConfigById = (id, data) => unarmedWithToken().put(`/filter-config/${id}`, data);
export const createFilterConfig = (data) => unarmedWithToken().post('/filter-config', data);
export const getFormsFiltersConfig = () => unarmedWithToken().get('/forms/filters-config');

export const getUsersFiltersConfig = () => unarmedWithToken().get('/users/filters-config');

export const getOfficersFiltersConfig = () => unarmedWithToken().get('/officers/filters-config');

export const getAllegationsFiltersConfig = () => unarmedWithToken().get('/allegations/filters-config');

export const getContactsFiltersConfig = () => unarmedWithToken().get('/contacts/filters-config');

export const scheduleAdHocReports = async (reportId, emailConfiguration) =>
  unarmedWithToken().post('/adhoc-reports/schedule', {
    reportId,
    emailConfiguration,
  });

export const uploadReportImage = async (formData, id) => {
  try {
    const response = await unarmedWithToken().put(`/adhoc-reports/${id}/image`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data.reportImage;
  } catch (error) {
    console.error('Error uploading image:', error);
  }
};

export const getViewById = (id) => unarmedWithToken().get(`/views/${id}`);

export const editMultipleOfficers = (ids) => unarmedWithToken().put(`/officers?${ids}`);
export const editMultipleUsers = (ids) => unarmedWithToken().put(`/users?${ids}`);
export const editMultipleForms = (ids) => unarmedWithToken().put(`/forms?${ids}`);
export const editMultipleContacts = (ids) => unarmedWithToken().put(`/contacts?${ids}`);
export const editMultipleAllegations = (ids) => unarmedWithToken().put(`/allegations`, { allegationIds: ids });

export const createNewPermission = (data) => unarmedWithToken().post('/permissions', data);
export const deletePermission = (data) => unarmedWithToken().post('/permissions/delete', data)
