import { Typography, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() => ({
  Title: {
    paddingTop: 15,
    fontWeight: 600,
    fontSize: 14,
  },
}));

export default function CUTitle({ data }) {
  const classes = useStyles();

  return <Typography className={classes.Title}>{data.text}</Typography>;
}
