import { Box, CircularProgress, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { toast } from 'react-toastify';
import Alert from '../../components/Alert';
import DashButton from '../../components/DashButton';
import Dropdown from '../../formEngine/components/Dropdown';
import useInput from '../../hooks/useInput';
import { onSaveFormType } from '../../services/unarmed';
import { ContainerPageDetail } from './PageDetails';

const StatusOptions = ['Recieved', 'In-Progress', 'Closed'];
const ReasonOptions = [
  'Was Incredibly Helpful',
  'Was Incredibly Polite',
  'Demonstrated Extreme Profesionalism',
  'Provided Outstanding Service And Profesionalism',
  'Demostrated Exemplary Level Of Service'
];
const AllegationOptions = [
  'Use of Force',
  'Abuse of Authority',
  'Discourtesy',
  'Offensive Language',
  'Lack of Service',
];
const DispositionOptions = ['Sustained', 'Not Sustained', 'Unfounded', 'Improper Conduct', 'Policy Failure'];

const DisciplineOptions = [
  'Counseled',
  'Letter of Reprimand',
  'Suspension',
  'Demotion',
  'Dismissal',
  'Resignation',
  'No Further Action',
  'Other',
];

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    position: 'relative',
    padding: 28,
    minHeight: 195,
    borderRadius: 8,
    boxShadow: '0px 3px 6px #0000000D',
    backgroundColor: '#fff',
    marginTop: 35,
  },
}));

export const renderHeder = ({ title, onClick, loading, isDefaultForm }) => (
  <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
    <Typography style={{ fontSize: 24, fontWeight: '500' }}>{title}</Typography>

    <DashButton onClick={onClick} disabled={isDefaultForm || loading}>
      {loading ? <CircularProgress color="#fff" size="20px" /> : 'Save'}{' '}
    </DashButton>
  </Box>
);

export default function Standard({ id, formInfoUpdate, isDefaultForm, setFormInfo }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [officersAllegationOptions, setOfficersAllegationOptions] = useState({});
  // const visible1 = useInput(true, false, false, true);
  const reason = useInput(true, false, false, true);
  const allegation = useInput(true, false, false, true);
  const disposition = useInput(true, false, false, true);
  const discipline = useInput(true, false, false, true);
  const status = useInput(true, false, false, true);
  const onUpdateJson = (value) => {
    setOfficersAllegationOptions((prevState) => ({ ...prevState, ...value }));
  };

  useEffect(() => {
    if (formInfoUpdate) {
      reason.setValue(formInfoUpdate?.reason?.visible);
      allegation.setValue(formInfoUpdate?.allegation?.visible);
      disposition.setValue(formInfoUpdate?.disposition?.visible);
      discipline.setValue(formInfoUpdate?.discipline?.visible);
      status.setValue(formInfoUpdate?.status?.visible);
    }
  }, [formInfoUpdate]);

  const onSave = async () => {
    setLoading(true);
    try {
      const data = {
        officersAllegationOptions: {
          active: formInfoUpdate?.active,
          ...officersAllegationOptions,
        },
      };
      const res = await onSaveFormType(data, id);

      setFormInfo((prevState) => ({
        ...prevState,
        officersAllegationOptions: res.data?.officersAllegationOptions,
      }));
      toast.success('Officers has been updated');
    } catch (error) {
      toast.error(error?.response?.data?.error?.details?.[0]?.message);
    }
    setLoading(false);
  };
  return (
    <ContainerPageDetail style={{ width: '100%', height: 'calc(116.1vh - 64px)', overflowY: 'auto' }}>
      <Box padding="30px 20px 20px" width="100%">
        {renderHeder({
          title: 'Standard',
          onClick: onSave,
          loading,
          isDefaultForm,
        })}
        {isDefaultForm && (
          <Alert status="info">
            <Typography style={{ fontSize: 14 }}>
              Default forms cannot be deleted or updated. Duplicate this form to edit.
            </Typography>
          </Alert>
        )}
        <DndProvider backend={HTML5Backend}>
          <Box className={classes.container}>
            {isDefaultForm && (
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  zIndex: 1,
                  background: 'rgba(0,0,0,0.01)',
                }}
              />
            )}
            <Dropdown
              data={{
                question: 'Reason',
                options: formInfoUpdate?.reason?.values || ReasonOptions,
              }}
              componentIndex={0}
              isStaticComponent
              onUpdateJson={onUpdateJson}
              shouldRenderSettings={false}
              allowDnD={false}
              includeRequired={false}
              visible={reason}
            />
            <Dropdown
              data={{
                question: 'Allegation',
                options: formInfoUpdate?.allegation?.values || AllegationOptions,
              }}
              componentIndex={0}
              isStaticComponent
              onUpdateJson={onUpdateJson}
              shouldRenderSettings={false}
              allowDnD={false}
              includeRequired={false}
              visible={allegation}
            />
            <Dropdown
              data={{
                question: 'Disposition',
                options: formInfoUpdate?.disposition?.values || DispositionOptions,
              }}
              componentIndex={0}
              isStaticComponent
              onUpdateJson={onUpdateJson}
              shouldRenderSettings={false}
              allowDnD={false}
              includeRequired={false}
              visible={disposition}
            />
            <Dropdown
              data={{
                question: 'Discipline',
                options: formInfoUpdate?.discipline?.values || DisciplineOptions,
              }}
              componentIndex={0}
              isStaticComponent
              onUpdateJson={onUpdateJson}
              shouldRenderSettings={false}
              allowDnD={false}
              includeRequired={false}
              visible={discipline}
            />
            <Dropdown
              data={{
                question: 'Status',
                options: formInfoUpdate?.status?.values || StatusOptions,
              }}
              componentIndex={0}
              isStaticComponent
              onUpdateJson={onUpdateJson}
              shouldRenderSettings={false}
              allowDnD={false}
              includeRequired={false}
              visible={status}
            />
          </Box>
        </DndProvider>
      </Box>
    </ContainerPageDetail>
  );
}
