import {
  FormControl,
  makeStyles,
  TextField,
  Button,
  Box,
  CircularProgress,
  Typography,
  Grid,
  InputLabel,
  Select,
} from '@material-ui/core';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import useInput from '../../hooks/useInput';
import { createFormTypes } from '../../services/unarmed';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 20,
  },
  title: {
    marginTop: 50,
  },
  button: {
    background: ' #1F63FF',
    textTransform: 'capitalize',
    marginRight: 10,
    color: '#fff',
    height: 36,
    width: 30,
    '&:hover': {
      background: '#1F63FF',
    },
    '& .MuiButton-label': {
      fontSize: 13,
    },
    '&:disabled': {
      background: '#1f63ff61',
      color: '#fff',
    },
  },
  button2: {
    background: ' #1F63FF',
    textTransform: 'capitalize',
    color: '#fff',
    '&:hover': {
      background: '#1F63FF',
    },
    '& .MuiButton-label': {
      fontSize: 14,
    },
  },
  buttonOutlined: {
    letterSpacing: '1px',
    fontSize: 13,
    fontWeight: '500',
    marginRight: 10,
    width: 'fit-content',
    alignSelf: 'flex-end',
    border: 0,
    color: '#4b7bff',
    '&:hover': {
      background: 'transparent',
      border: 0,
    },
    '& .MuiButton-label': {
      fontSize: 13,
    },
    '&:disabled': {
      border: 0,
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: '#fff',
    boxShadow: theme.shadows[5],
    padding: 30,
    maxWidth: 500,
    minWidth: 400,
    outline: 0,
  },
}));

/**
 * CreateForm component to create a new form type.
 *
 * @component
 * @example
 * const refreshCallback = () => {};
 * const setOpen = (open) => {};
 * const formTypes = ['Type1', 'Type2'];
 * return (
 *   <CreateForm refreshCallback={refreshCallback} setOpen={setOpen} formTypes={formTypes} />
 * );
 *
 * @param {Object} props - The component props.
 * @param {function} props.refreshCallback - Callback function to refresh the form types list.
 * @param {function} props.setOpen - Function to control the open state of the parent modal/dialog.
 * @param {Array.<string>} props.formTypes - Array of form type options.
 */
export default function CreateForm({ refreshCallback, setOpen, formTypes }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const formName = useInput('');
  const description = useInput('');
  const type = useInput('');
  const name = useInput('');

  const onCreateFormTypes = async () => {
    if (!type.value || !formName.value || !description.value) {
      return;
    }

    setLoading(true);
    try {
      await createFormTypes({
        type: type.value.replace(/ /g, '_').toUpperCase(),
        typeDisplayText: type.value,
        name: formName.value,
        description: description.value,
        adminName: name.value,
      });
      setOpen(false);
      await refreshCallback();
      toast.success('Form Type was created successfully!');
    } catch (error) {
      setLoading(false);
      toast.error('Something went wrong creating the form type');
    }
  };

  return (
    <div className={classes.paper}>
      <Typography style={{ fontSize: 20, fontWeight: '500' }}>Create Form</Typography>
      <Typography style={{ fontSize: 14, marginTop: 15, marginBottom: 10 }}>
        Please enter the name and description of the form in the fields below.
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            id="standard-required1"
            label={
              <Typography>
                {' '}
                Name <span style={{ color: 'red', fontSize: 12 }}>*</span>
              </Typography>
            }
            style={{ width: '100%' }}
            value={name.value}
            onChange={name.onChange}
            inputProps={{
              'data-testid': 'create-form-input-name',
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="standard-required1"
            label={
              <Typography>
                {' '}
                Display Name <span style={{ color: 'red', fontSize: 12 }}>*</span>
              </Typography>
            }
            style={{ width: '100%' }}
            value={formName.value}
            onChange={formName.onChange}
            inputProps={{
              'data-testid': 'create-form-input-display-name',
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="standard-required1"
            label={
              <Typography>
                {' '}
                Description <span style={{ color: 'red', fontSize: 12 }}>*</span>
              </Typography>
            }
            style={{ width: '100%' }}
            value={description.value}
            onChange={description.onChange}
            inputProps={{
              'data-testid': 'create-form-input-description',
            }}
          />
          <Typography style={{ fontSize: 14 }}>
            This description will be visible on the portal when the form is published.
          </Typography>
        </Grid>
      </Grid>
      <br />
      <Grid item xs={6}>
        <FormControl className={classes.formControl} style={{ width: '100%' }}>
          <InputLabel htmlFor="age-native-simple">
            Type <span style={{ color: 'red', fontSize: 12 }}>*</span>
          </InputLabel>
          <Select
            native
            defaultValue="Select an Option"
            value={type.value}
            onChange={type.onChange}
            error={type.error}
            helperText={type.error}
            inputProps={{
              'data-testid': 'create-form-select-type',
            }}
          >
            <option aria-label="None" value={null}>
              Select an Option
            </option>
            {formTypes.map((_type, index) => (
              <option
                value={Array.isArray(_type) ? _type[1] : _type}
                key={index}
              >
                {Array.isArray(_type) ? _type[1] : _type}
              </option>
            ))}
          </Select>
          <span style={{ color: 'red', fontSize: 12, marginTop: 4 }}>{type.error}</span>
        </FormControl>
      </Grid>
      <br />
      <Box display="flex" alignItems="center" justifyContent="flex-end" marginTop="20px">
        <Button
          variant="outlined"
          className={classes.buttonOutlined}
          style={{ color: '#1F63FF' }}
          onClick={() => setOpen(false)}
        >
          CANCEL
        </Button>
        <Button
          variant="outlined"
          className={classes.buttonOutlined}
          onClick={onCreateFormTypes}
          disabled={loading || !formName.value || !description.value || !type.value}
        >
          {loading ? <CircularProgress color="#4b7bff" size="25px" /> : 'Create'}
        </Button>
      </Box>
    </div>
  );
}

CreateForm.propTypes = {
  refreshCallback: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired,
  formTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
};
