import { Box, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

export default function HideAnswer({
  onClick,
  marginLeft = '40px',
  marginTop = '7px',
  message = 'Hide Answer',
  children,
}) {
  const [show, setShow] = useState(true);
  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        marginLeft={marginLeft}
        width="125px"
        marginTop={marginTop}
        style={{ cursor: 'pointer' }}
        onClick={typeof onClick === 'function' ? onClick : () => setShow(!show)}
      >
        <ArrowDropDownIcon htmlColor="#2E66FE" fontSize="small" />
        <Typography style={{ fontSize: 12, color: '#2E66FE', marginLeft: 7 }}>
          {message}
        </Typography>
      </Box>
      {show && children}
    </>
  );
}
