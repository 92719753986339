import { Box, Typography, CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import FileDownload from 'js-file-download';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import {
  addProjectAttachments,
  archiveProjectAttachment,
  getProjectFileAttachments,
  getProjectFileAttachmentsToShow,
  unarchiveProjectAttachment,
  updateProjectsAttachmentTags,
} from '../../../services/unarmed';
import ImageDownload from '../../../components/ImageDownload';
import { handleOpenPopupFiles } from '../../../utils';
import CarouselModal from '../../../components/CarouselModal';
import ModalUploadFile from '../../../components/ModalUploadFile';
import Tags from '../../../components/Tags';
import TagsFilters from '../../../components/TagsFilters';
import { BoxLeftContainer } from '../../../styles/GlobalStyles';

export default function ProjectAttachments({ id, organizationId, projectName, refetch }) {
  const [attachments, setAttachments] = useState([]);
  const [attachmentsFiltered, setAttachmentsFiltered] = useState([]);
  const [showAttachmentsFiltered, setShowAttachmentsFiltered] = useState(false);
  const [archivedOnly, setArchivedOnly] = useState(false);
  const [tags, setTags] = useState([]);

  const [loading, setLoading] = useState(false);
  const [loadingDownload, setLoadingDonwload] = useState('idle');
  const [open, setOpen] = React.useState(false);
  const [openPopupFiles, setOpenPopupFiles] = React.useState(false);
  const [filesSelected, setFilesSelected] = React.useState({ files: [], selectedFileIndex: 0 });
  const [loadingOpenFiles, setLoadingOpenFiles] = React.useState([]);

  const getProjectAttachments = async () => {
    setLoading(true);
    try {
      const { data } = await getProjectFileAttachmentsToShow(id);
      const _attachments = data.sort((x, y) => new Date(y.createdAt).getTime() - new Date(x.createdAt).getTime());
      setAttachments(_attachments);
      filterAttachments(_attachments, tags, archivedOnly);
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getProjectAttachments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleOpen = () => {
    setOpen(true);
  };

  const onDownloadAll = async () => {
    setLoadingDonwload('pending');
    getProjectFileAttachments(id)
      .then(({ data }) => {
        FileDownload(data, `${projectName}-project-attachments.zip`);
        setLoadingDonwload('resolved');
      })
      .catch((err) => {
        setLoadingDonwload('rejected');
      });
  };

  const onSubmit = async (e, files) => {
    e.preventDefault();

    try {
      const filesData = files.map((file) => ({
        file: file.hashId,
        tags: file?.tags?.map((tag) => tag?._id) || [],
      }));

      setLoading(true);
      await addProjectAttachments(id, { attachments: filesData });
      await getProjectAttachments();

      setOpen(false);
      // setIsRefetch((prevState) => !prevState);
    } catch (error) {
      toast.error(error?.response?.data?.message);
      toast.error(error?.response?.data?.error?.details[0]?.message);
    } finally {
      setLoading(false);
    }
  };

  const filterAttachments = (attachments, tags, archivedOnly) => {
    setAttachmentsFiltered(
      attachments
        .filter((attachment) => {
          if (!tags?.length) {
            return true;
          }
          return attachment.tags.map((tag) => tag?._id || tag).find((tag) => tags.indexOf(tag) !== -1);
        })
        .filter((attachment) => (archivedOnly ? !!attachment.archivedDate : !attachment.archivedDate))
    );
  };

  const attachmentsToShow = showAttachmentsFiltered ? attachmentsFiltered : attachments;

  return (
    <BoxLeftContainer>
      <TagsFilters
        onSubmitFilters={(tags, archivedOnly) => {
          //if (tags.length > 0) {
          setTags(tags);
          setShowAttachmentsFiltered(true);
          //} else {
          //  setShowAttachmentsFiltered(false);
          //}

          filterAttachments(attachments, tags, archivedOnly);
        }}
        archivedOnly={archivedOnly}
        setArchivedOnly={setArchivedOnly}
      />
      <Box style={{ background: '#fff', borderRadius: 5 }} boxShadow="0 3px 6px #00000005" width="100%" padding="20px">
        <CarouselModal
          open={openPopupFiles}
          setOpen={setOpenPopupFiles}
          filesSelected={filesSelected}
          setLoadingOpenFiles={setLoadingOpenFiles}
          loadingOpenFiles={loadingOpenFiles}
        />
        <Box display="flex" alignItems="center" justifyContent="space-between" marginBottom="20px">
          <Box
            data-testid="projects-attachments-button-add-new"
            display="flex"
            onClick={handleOpen}
            style={{ cursor: 'pointer' }}
          >
            <AttachFileIcon fontSize="small" htmlColor="#4b7bff" />{' '}
            <Typography style={{ color: '#4b7bff', fontSize: 14, fontWeight: '500' }}>New attachments</Typography>
          </Box>
          {attachments?.length && !archivedOnly ? (
            <Box display="flex" onClick={onDownloadAll}>
              {loadingDownload === 'pending' && (
                <CircularProgress size={20} style={{ marginRight: '10px', color: '#4b7bff' }} />
              )}
              <Typography
                style={{
                  color: '#4b7bff',
                  fontSize: 14,
                  fontWeight: '500',
                  cursor: 'pointer',
                }}
              >
                Download all
              </Typography>
            </Box>
          ) : null}
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center">
          {loading && <CircularProgress style={{ color: '#4b7bff' }} />}
          {!attachmentsToShow.length && !loading && (
            <Typography style={{ fontWeight: 'bold' }}>No attachments added to this project</Typography>
          )}
        </Box>
        {attachmentsToShow?.map((file, i) => (
          <div key={file?._id}>
            <ImageDownload
              index={i}
              extension={file.name.match(/[^.]+$/)}
              name={file.name}
              size={file.size}
              urlImg={file.download_url}
              onPreviewImage={() => {
                if (file?.mimetype?.includes('pdf')) {
                  const link = document.createElement('a');
                  link.href = file.preview_url;
                  link.setAttribute('target', '_blank');
                  document.body.appendChild(link);
                  link.click();
                } else if (
                  file?.mimetype?.includes('audio') ||
                  file?.mimetype?.includes('video') ||
                  file?.mimetype?.includes('image')
                ) {
                  handleOpenPopupFiles(
                    file.url,
                    attachmentsToShow,
                    setOpenPopupFiles,
                    setFilesSelected,
                    setLoadingOpenFiles
                  );
                }
              }}
              restorable={archivedOnly && (file?.archivableByUser ?? true)}
              deletable={!archivedOnly && (file?.archivableByUser ?? true)}
              downloadable={!archivedOnly}
              entityId={id}
              id={file._id}
              onDeleteFile={async (entityId, id) => {
                await archiveProjectAttachment(entityId, id);
                refetch();
                getProjectAttachments();
              }}
              onRestoreFile={async (entityId, id) => {
                await unarchiveProjectAttachment(entityId, id);
                refetch();
                getProjectAttachments();
              }}
            >
              <Tags
                fileId={file?._id}
                defaultTags={file?.tags || []}
                onClickAddTag={(tag) => {
                  updateProjectsAttachmentTags(id, file._id, {
                    tags: [...(file?.tags?.map((_tag) => _tag?._id) || []), tag?._id],
                  });
                  setAttachments((_attachments) =>
                    _attachments.map((_attachment) => {
                      if (_attachment._id === file._id) {
                        return {
                          ..._attachment,
                          tags: [...(_attachment?.tags || []), tag],
                        };
                      }

                      return { ..._attachment };
                    })
                  );
                }}
                withAddBtn={!archivedOnly}
                canRemove={!archivedOnly}
                onClickRemove={(tag) => {
                  updateProjectsAttachmentTags(id, file._id, {
                    tags: file?.tags.map((_tag) => _tag?._id).filter((_tag) => _tag !== tag?._id),
                  });
                  setAttachments((_attachments) =>
                    _attachments.map((_attachment) => {
                      if (_attachment._id === file._id) {
                        return {
                          ..._attachment,
                          tags: _attachment?.tags?.filter((_tag) => _tag?._id !== tag?._id),
                        };
                      }

                      return { ..._attachment };
                    })
                  );
                }}
              />
            </ImageDownload>
          </div>
        ))}
        <ModalUploadFile
          open={open}
          onConfirm={onSubmit}
          onClose={() => setOpen(false)}
          organizationId={organizationId}
        />
      </Box>
    </BoxLeftContainer>
  );
}

ProjectAttachments.propTypes = {
  id: PropTypes.number.isRequired,
  organizationId: PropTypes.number.isRequired,
  projectName: PropTypes.string.isRequired,
};
