/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

import { useAccordionProvider } from '../provider/AccordionProvider';

/**
 * Custom CSS styles for the AccordionWrap component.
 */
const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    borderRadius: 4,
    overflow: 'hidden',
  },
  header: {
    cursor: 'pointer',
    transition: 'all 0.25s ease',
    backgroundColor: '#fff',

    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, .1)',
    },
  },
  content: {
    width: '100%',
    backgroundColor: '#fff',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
    transition: 'height 0.3s ease-in-out',
    zIndex: 1,
    height: 0,
    padding: '0px 24px',
  },
}));

/**
 * AccordionWrap component.
 * @param {Object} props - Component props.
 * @param {string} props.name - Unique identifier for the accordion.
 * @param {React.ReactElement} props.header - Header element of the accordion.
 * @param {React.ReactElement} props.children - Child elements of the accordion.
 * @returns {React.ReactElement} AccordionWrap component.
 */
const AccordionWrap = ({ dataTestId, id, header, children }) => {
  const { accordions, setAccordions } = useAccordionProvider();
  const classes = useStyles();
  const contentRef = useRef(null);
  const [contentHeight, setContentHeight] = useState(0);

  /**
   * Update content height when children change.
   */
  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    }
  }, [children]);

  return (
    <div className={classes.container}>
      <div
        onClick={() => {
          setAccordions((_accordions) => ({
            ..._accordions,
            [id]: !accordions[id],
          }));
        }}
        className={classes.header}
        data-testid={dataTestId}
      >
        {header}
      </div>
      <div
        ref={contentRef}
        className={`${classes.content} ${accordions[id] && classes.contentVisible}`}
        style={{ height: accordions[id] ? `${contentHeight}px` : 0 }}
      >
        {children}
      </div>
    </div>
  );
};

// Define prop types for AccordionWrap component
AccordionWrap.propTypes = {
  dataTestId: PropTypes.string,
  id: PropTypes.string,
  header: PropTypes.element,
  children: PropTypes.element,
};

export default AccordionWrap;
