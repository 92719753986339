const en = {
  translations: {
    complaint: 'Complaint',
    compliment: 'Compliment',
    inquiry: 'Inquiry',
    referral: 'Referral',
    other: 'Other',
    form: 'Form',
    'Tracking Number': 'Tracking Number',
    Created: 'Created by',
    'Personal Information': 'Personal Information',
    anonymous: 'Anonymous/I do not want to share personal info',
    'Civilian First Name': 'First Name',
    'Civilian Last Name': 'Last Name',
    'Email Address': 'Email Address',
    'Phone Number': 'Phone Number',
    Address: 'Address',
    'Incident Details': 'Incident Details',
    whyWouldYouLike: 'Why would you like to thank this employee?',
    Statement: 'Statement',
    links: 'Incident External Link',
    location: 'Location of Incident',
    date: 'Incident Date',
    time: 'Incident Time',
    officers: 'Police Officers',
    'officer name': "Officer's First Name",
    'officer lastName': "Officer's Last Name",
    'officer badge': 'Badge/Serial Number',
    witness: 'Witness(es) or Others Involved',
    'witness name': "Witness's First Name",
    'witness lastName': "Witness's Last Name",
    'additional information': 'Additional Information',
    how: 'How do you hear about us?',
    whatDescribeYou: 'What best describes you?',
    birth: 'Date of Birth',
    gender: 'Gender',
    sOrientation: 'Sexual Orientation',
    race: 'Race/Ethnicity',
    language: 'Is English your primary language?',
    EventInfo: 'Incident Details',
    eventLocationL: 'Location of Incident',
    eventDate: 'Date of Incident',
    eventTime: 'Time of Incident',
  },
};

export default en;
