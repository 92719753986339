import { Box, makeStyles, MenuItem, Typography } from '@material-ui/core';
import React from 'react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import PopperPortal from '../../../components/PopperPortal';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    padding: 28,
    minHeight: 195,
    position: 'relative',
    borderRadius: 8,
    boxShadow: '0px 3px 6px #0000000D',
    backgroundColor: '#fff',
  },
  ButtonText: {
    background: '#2E66FE 0% 0% no-repeat padding-box',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    padding: '10px 20px',
    color: '#fff',
    fontSize: 14,
    fontWeight: '500',
    width: 152,
    borderRight: '1px solid #fff',
    textAlign: 'center',
  },
  dropdownButton: {
    background: '#2E66FE 0% 0% no-repeat padding-box',
    width: 32,
    padding: '10px 20px',
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 40,
    cursor: 'pointer',
    position: 'relative',
  },
  altText: {
    fontSize: 14,
    color: '#000000AD',
  },
  dndContainer: {
    display: 'flex',
    alignItems: 'center',
    transition: 'ease all .3s',
  },
}));

const tags = [
  {
    text: 'Date',
  },
  {
    text: 'Number',
  },
  {
    text: 'Condition',
  },
  {
    text: 'Text',
  },
];

export default function SelectableButton({ text, onClick, options }) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleSelect = (tag) => {
    if (tag === 'date') {
      onClick({
        tagType: 'date',
        format: options[tag]?.defaultFormat || '',
        separator: options[tag]?.defaultSeparator || '',
      });
    }
    if (tag === 'number') {
      onClick({
        tagType: 'number',
        value: options[tag]?.defaultConsecutive ? 'consecutive_number' : 'random_number' || '',
        length: options[tag]?.defaultLength || '',
      });
    }
    if (tag === 'condition') {
      onClick({
        tagType: 'condition',
        conditions: [
          {
            when: '',
            equals: '',
            value: '',
          },
        ],
      });
    }
    if (tag === 'text') {
      onClick({
        tagType: 'text',
        value: '',
      });
    }
    setOpen(false);
  };
  return (
    <Box margin="34px 0">
      <Box display="flex" alignItems="center" onClick={() => setOpen(!open)} style={{ cursor: 'pointer' }}>
        <Box className={classes.ButtonText}>{text}</Box>
        <Box className={classes.dropdownButton}>
          <ArrowDropDownIcon htmlColor="#fff" />
          <PopperPortal open={open} setOpen={setOpen} left="0px" top="45px">
            <Box width={249} borderRadius={4} style={{ background: '#fff' }} boxShadow="0px 3px 6px #0000000D">
              {tags.map((tag, index) => (
                <MenuItem
                  key={index}
                  onClick={() => {
                    handleSelect(tag.text.toLowerCase());
                  }}
                >
                  <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                    <Typography variant="body1" style={{ fontSize: 14 }}>
                      {tag.text}
                    </Typography>
                  </Box>
                </MenuItem>
              ))}
            </Box>
          </PopperPortal>
        </Box>
      </Box>
    </Box>
  );
}
