/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

export const useCardStyles = makeStyles(() => ({
  container: {
    width: '100%',
    background: '#fff',
    borderRadius: 5,
    overflow: 'hidden',
    height: 'fit-content',
  },
  titleBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 24px',
    height: 44,
    borderBottom: '1px solid #e2e2e2',
  },
  title: {
    fontSize: 16,
    fontWeight: '500',
    color: '#575A66',
    margin: 0,
  },
}));

/**
 * Card component to display a card with a title and content.
 * @param {Object} props - Component props.
 * @param {string} props.title - Title of the card.
 * @param {React.ReactNode} props.children - Children components of the card.
 * @returns {JSX.Element} Card component.
 */
const Card = ({ title, btn = null, paddingContent = '16px 24px', children, ...props }) => {
  const classes = useCardStyles();

  return (
    <Box className={classes.container} boxShadow="0 3px 6px #00000005" mb="20px" {...props}>
      <Box className={classes.titleBox}>
        {typeof title === 'string' ? (
          <Typography className={classes.title} variant="body2">
            {title}
          </Typography>
        ) : (
          title
        )}
        {btn}
      </Box>
      <Box padding={paddingContent}>{children}</Box>
    </Box>
  );
};

// PropTypes validation
Card.propTypes = {
  title: PropTypes.node,
  btn: PropTypes.node,
  paddingContent: PropTypes.string,
  children: PropTypes.node,
};

export default Card;
