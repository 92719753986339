import { Box, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import LogoHome from '../components/LogoHome';

const NotFound = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const time = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => clearTimeout(time);
  }, []);

  return (
    <Box width="100%" height="116.1vh" display="flex" alignItems="center" justifyContent="center">
      {loading ? <LogoHome width={300} /> : <Typography variant="h6">Page Not Found</Typography>}
    </Box>
  );
};

export default NotFound;
