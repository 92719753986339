import React, { useEffect, useState, useContext } from 'react';
import { Box, Typography, Grid, makeStyles, NativeSelect, withStyles, InputBase } from '@material-ui/core';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { toast } from 'react-toastify';
import { Editor } from '@tinymce/tinymce-react';
import moment from 'moment';
import PropTypes from 'prop-types';
import AttachFileIcon from '@material-ui/icons/AttachFile';

import SelectBox from '../../components/SelectBox';
import DashButton from '../../components/DashButton';
import { transformStatus } from '../../utils';
import AssignUser from '../../components/AssignUser';
import { Context } from '../../Context';
import { onUpdateFormTask } from '../../services/unarmed';
import useOrganization from '../../hooks/useOrganization';
import { TINYMCE_DEFAULT_CONFIG, TINYMCE_SCRIPT_SRC } from '../../config/config';
import DateTimePickerMUI from '../../components/DateTimePickerMUi';
import ModalUploadFile from '../../components/ModalUploadFile';
import CarouselModal from '../../components/CarouselModal';
import Attachment from '../../components/Attachment';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    '& svg': {
      color: '#fff !important',
      marginRight: 15,
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #008858',
    background: '#008858 !important',
    color: '#fff',
    paddingRight: '35px !important',
    fontSize: 14,
    minWidth: 112,
    height: 24,
    padding: '10px 0px 10px 0px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: 'Roboto',
    '& option': {
      color: '#333',
    },
    '&:focus': {
      borderRadius: 4,
    },
  },
}))(InputBase);

const useStyles = makeStyles(() => ({
  containerTitle: {
    padding: '10px 20px',
    borderBottom: '1px solid #e2e2e2',
  },
  required: {
    color: 'red',
    fontSize: 16,
  },
  dropzoneBox: {
    background: '#4762FA08',
    borderRadius: 4,
    border: '1px dashed #364F7431',
    padding: 34,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: 16,
    cursor: 'pointer',
  },
  dropzoneText: {
    display: 'block',
    fontSize: 12,
    marginTop: 10,
    textAlign: 'center',
  },
  deleteIcon: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: '35%',
    margin: 'auto',
    cursor: 'pointer',
  },
  viewIcon: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: '35%',
    right: 0,
    margin: 'auto',
    cursor: 'pointer',
  },
  link: {
    display: 'flex',
    position: 'absolute',
    top: 10,
    right: 80,
    cursor: 'pointer',
    padding: 13,
    zIndex: 999999,
    borderRadius: '50%',
    background: '#494949',
  },
}));

/**
 * CaseDetailTask
 *
 * Component for each task on case
 *
 * @param {array} users
 * @param {object} form
 * @param {object} task
 * @param {string} taskKey
 * @param {function} updateTasks
 */
const CaseDetailTask = ({ users, form, task, taskKey, updateTasks }) => {
  const [type, setType] = useState('');
  const [types, setTypes] = useState([]);
  const [availableStatuses, setAvailableStatuses] = useState([]);
  const [subType, setSubType] = useState(null);
  const [subTypes, setSubTypes] = useState([]);
  const [notes, setNotes] = useState('');
  const [dueDate, setDueDate] = useState(new Date().toISOString());
  const [selectedStatus, setSelectedStatus] = useState(task?.values?.status);
  const [assignedUsers, setAssignedUsers] = useState([{}]);
  const classes = useStyles();
  const { authUser } = useContext(Context);
  const [open, setOpen] = useState(false);
  const [openModalUploadFile, setOpenModalUploadFile] = useState(false);
  const [filesSelected, setFilesSelected] = React.useState([]);
  const [loadingOpenFiles, setLoadingOpenFiles] = React.useState([]);
  const [filled, setFilled] = useState(false);
  const [files, setFiles] = useState([]);

  const { organization } = useOrganization();

  const taskSettings = form?.formType?.tasks?.find((_task) => _task._id === task._id)?.settings;

  /**
   * @description function to handle change task data.
   * @param {object} taskData
   */
  const handleOnChangeTask = (taskData) => {
    updateTasks((prevTasks) =>
      prevTasks.map((_task) => {
        if (_task._id === taskData._id) {
          return {
            ..._task,
            ...taskData,
          };
        }

        return _task;
      })
    );
  };

  /**
   * @description function to handle delete user.
   * @param {object} user
   */
  const onDeleteUser = async (user) => {
    const newUsers = assignedUsers.filter((_user) => _user?._id !== user?._id);

    setAssignedUsers(newUsers);
    handleOnChangeTask({
      ...task,
      values: {
        ...task.values,
        assignedTo: newUsers.map((_user) => _user?._id),
      },
    });
  };

  /**
   * @description function to add new user to the array.
   * @param {array} usersArr
   */
  const onAssignUser = async (usersArr) => {
    setAssignedUsers(usersArr);
    handleOnChangeTask({
      ...task,
      values: {
        ...task.values,
        assignedTo: usersArr.map((user) => user?._id),
      },
    });
  };

  /**
   * @description function to handle change status.
   * @param {object} e
   */
  const handleOnChangeStatus = (e) => {
    const { value } = e.target;

    setSelectedStatus(value);
    handleOnChangeTask({
      ...task,
      values: {
        ...task.values,
        status: value,
      },
    });
  };

  /**
   * @description function to handle save case task.
   * @returns null
   */
  const handleOnSave = async () => {
    if (form && task) {
      const data = {
        taskId: task._id,
        status: selectedStatus,
      };

      if (assignedUsers.length === 0 && taskSettings?.assignedTo?.required) {
        return toast.error('Assigned to field is required!');
      }

      if (!type && taskSettings?.typeSubtype?.required) {
        return toast.error('Type field is required!');
      }

      if (!subType && taskSettings?.typeSubtype?.required) {
        return toast.error('Subtype field is required!');
      }

      if (!notes && taskSettings?.notes?.required) {
        return toast.error('Notes field is required!');
      }

      if (!dueDate && taskSettings?.times?.required) {
        return toast.error('Due date field is required!');
      }

      if (files.length === 0 && taskSettings?.attachments?.required) {
        return toast.error('Attachments field is required!');
      }

      if (taskSettings?.typeSubtype?.visible) {
        data.type = type;
        data.subType = subType;
      }

      if (taskSettings?.notes?.visible) {
        data.notes = notes;
      }

      if (taskSettings?.times?.visible && dueDate) {
        data.dueDateTime = dueDate;
      }

      if (taskSettings?.assignedTo?.visible) {
        data.assignedTo = assignedUsers.map((user) => user?._id);
      }

      if (taskSettings?.attachments?.visible) {
        data.attachments = files.map((file) => {
          if (file?._id && !file?.hashId) {
            return { _id: file?._id };
          }

          return {
            file: file?.hashId || file?._id,
            tags: file?.tags?.map((tag) => tag._id) || [],
          };
        });
      }

      try {
        const formResponse = await onUpdateFormTask(authUser.tokenId, form?._id, data);

        updateTasks(formResponse.data.tasks);
        toast.success('Updated task successfully!');
      } catch (error) {
        let message = error?.response?.data?.message;

        if (error?.response?.data?.error?.details?.length > 0) {
          message = error?.response?.data?.error?.details[0]?.message;
        }

        toast.error(message);
      }
    }
  };

  /**
   * @description function to handle open file.
   * @param {string} imgUrl
   * @param {array} filesOp
   */
  const handleOpen = (imgUrl, filesOp) => {
    setOpen(true);
    const filesToOpen = filesOp.filter(
      (flop) => flop.mimetype.includes('image') || flop.mimetype.includes('video') || flop.mimetype.includes('audio')
    );
    const selectedFile = filesToOpen.find((flop) => flop.public_url === imgUrl);
    const selectedFileIndex = filesToOpen.indexOf(selectedFile);
    filesToOpen.splice(selectedFileIndex, 1);
    filesToOpen.unshift(selectedFile);
    setFilesSelected(filesToOpen);
    setLoadingOpenFiles(
      filesToOpen.map(() => ({
        loading: false,
      }))
    );
  };

  const onDeleteFile = (file) => {
    setFiles([...files.filter((_file) => _file?._id !== file?._id)]);
  };

  /**
   * @description useEffect for set data from task.
   */
  useEffect(() => {
    if (task && !filled) {
      const taskData = form?.formType?.tasks?.find((_task) => _task._id === task._id);
      let _users = users.filter((user) => task?.values?.assignedTo?.indexOf(user?._id) !== -1);

      setType(task?.values?.type);
      setSubType(task?.values?.subType);
      setNotes(task?.values?.notes);
      setDueDate(task?.values?.dueDateTime || null);
      setSelectedStatus(task?.values?.status);
      setTypes(taskData?.availableTypeSubtype);
      setAvailableStatuses(taskData?.availableStatuses);
      setFilled(true);

      if (_users.length === 0 && task.values.assignedTo.length > 0) {
        _users = task.values.assignedTo;
      }

      setAssignedUsers(_users);

      if (task?.values?.attachments?.length) {
        setFiles(
          task?.values?.attachments?.map((file) => ({
            _id: file._id,
            url: file.public_url,
            name: file.name,
            size: file.size,
            mimetype: file.mimetype,
            public_url: file.public_url,
            preview_url: file.preview_url,
            download_url: file.download_url,
            tags: file?.tags || [],
            archivedDate: file.archivedDate,
          }))
        );
      } else {
        setFiles([]);
      }
    }
  }, [task, users, form, filled]);

  /**
   * @description useEffect for set sub-types from form type.
   */
  useEffect(() => {
    if (type) {
      const taskData = form?.formType?.tasks?.find((_task) => _task._id === task._id);
      const typeData = taskData?.availableTypeSubtype?.find((_type) => _type.type === type);
      setSubTypes(typeData.subTypes);

      if (typeData?.subTypes?.indexOf(subType) === -1) {
        setSubType(null);
      }
    }
  }, [type, subType, form, task]);

  return (
    <Box style={{ background: '#fff', borderRadius: 5 }} boxShadow="0 3px 6px rgba(0, 0, 0, 0.08)" width="100%" mb={2}>
      <Box className={classes.containerTitle}>
        <Box display="flex" alignItems="center" justifyContent="flex-start" width="100%">
          <Typography variant="body2" style={{ fontWeight: '500', fontSize: 16 }}>
            T{taskKey + 1}
          </Typography>
          <ArrowRightAltIcon style={{ fontSize: 14, marginLeft: 4 }} />
          <Typography variant="body2" style={{ fontWeight: '500', fontSize: 16, marginLeft: 4 }}>
            {task.name}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ p: 4 }}>
        <Grid container spacing={4} alignItems="center">
          {taskSettings?.typeSubtype?.visible && (
            <>
              <Grid item xs={2}>
                <Typography style={{ fontSize: 16, fontWeight: '500', marginBottom: 8 }}>
                  Type {taskSettings?.typeSubtype?.required && <span className={classes.required}>*</span>}
                </Typography>
                <SelectBox
                  id="type"
                  placeholderText="Select Type"
                  options={{
                    obj:
                      types?.map((_type) => ({
                        text: _type.type,
                        value: _type.type,
                      })) || [],
                  }}
                  value={type}
                  onChange={(e) => {
                    const { value } = e.target;

                    setType(value);
                    setSubType(null);
                    handleOnChangeTask({
                      ...task,
                      values: {
                        ...task.values,
                        type: value,
                        subType: null,
                      },
                    });
                  }}
                  outlined
                  allowPlaceHolder={false}
                  showDefaultValue
                  style={{ width: '100%' }}
                  withColors={{
                    color: '#2E66FE',
                    bg: '#2E66FE1A',
                    focus: '#2E66FE1A',
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <Typography style={{ fontSize: 16, fontWeight: '500', marginBottom: 8 }}>
                  Subtype {taskSettings?.typeSubtype?.required && <span className={classes.required}>*</span>}
                </Typography>
                <SelectBox
                  id="type"
                  placeholderText="Select Subtype"
                  options={{
                    obj:
                      subTypes?.map((_subType) => ({
                        text: _subType,
                        value: _subType,
                      })) || [],
                  }}
                  value={subType || ''}
                  onChange={(e) => {
                    const { value } = e.target;

                    setSubType(value);
                    handleOnChangeTask({
                      ...task,
                      values: {
                        ...task.values,
                        subType: value,
                      },
                    });
                  }}
                  outlined
                  allowPlaceHolder={false}
                  showDefaultValue
                  style={{ width: '100%' }}
                  withColors={{
                    color: '#2E66FE',
                    bg: '#2E66FE1A',
                    focus: '#2E66FE1A',
                  }}
                />
              </Grid>
            </>
          )}
          {taskSettings?.times?.visible && (
            <Grid item xs={3}>
              <Typography style={{ fontSize: 16, fontWeight: '500', marginBottom: 8 }}>
                Due Date {taskSettings?.times?.required && <span className={classes.required}>*</span>}
              </Typography>
              <DateTimePickerMUI
                minDate={dueDate || new Date().toISOString()}
                date={dueDate || null}
                setdate={(date) => {
                  setDueDate(date);
                  handleOnChangeTask({
                    ...task,
                    values: {
                      ...task.values,
                      dueDateTime: date,
                    },
                  });
                }}
              />
            </Grid>
          )}
          <Grid container justifyContent="right" alignItems="right" item xs direction="column">
            <Box display="flex" justifyContent="right">
              <NativeSelect
                id="demo-customized-select-native"
                value={selectedStatus}
                onChange={handleOnChangeStatus}
                input={<BootstrapInput />}
                IconComponent={KeyboardArrowDownIcon}
              >
                {availableStatuses?.map((avalaible, i) => (
                  <option
                    key={i}
                    value={avalaible.status}
                    style={{
                      textAlign: 'center',

                      marginRight: 10,
                    }}
                  >
                    {transformStatus(avalaible.status)?.toUpperCase()}
                  </option>
                ))}
              </NativeSelect>
            </Box>
            <Box>
              <Typography variant="body2" style={{ color: '#585858', fontSize: 13, marginTop: 10, textAlign: 'right' }}>
                Last update {moment(task?.updatedAt).format('MMMM DD, YYYY - hh:mm a')}
              </Typography>
            </Box>
          </Grid>
          {taskSettings?.assignedTo?.visible && (
            <Grid item xs={12}>
              <Box display="flex" position="relative">
                <AssignUser
                  assignedUsers={assignedUsers.length > 0 ? [...assignedUsers, {}] : [{}]}
                  defaultUsers={users}
                  form={form}
                  setAssignedUsers={setAssignedUsers}
                  onAssignUser={onAssignUser}
                  loadingRemoveUser={false}
                  onDeleteUser={onDeleteUser}
                  alignX={0}
                  alignKey="left"
                  textAlign="left"
                  flexWrap="wrap"
                  required={taskSettings?.assignedTo?.required}
                />
              </Box>
            </Grid>
          )}
          {taskSettings?.notes?.visible && (
            <Grid item xs={12}>
              <Typography style={{ fontSize: 16, fontWeight: '500', marginBottom: 8 }}>
                Notes {taskSettings?.notes?.required && <span className={classes.required}>*</span>}{' '}
              </Typography>
              <Editor
                tinymceScriptSrc={TINYMCE_SCRIPT_SRC}
                init={{
                  ...TINYMCE_DEFAULT_CONFIG,
                  placeholder: 'Add a note',
                  height: 350,
                }}
                value={notes}
                onEditorChange={(newValue) => {
                  setNotes(newValue);
                  setTimeout(() => {
                    handleOnChangeTask({
                      ...task,
                      values: {
                        ...task.values,
                        notes: newValue,
                      },
                    });
                  }, 1);
                }}
              />
            </Grid>
          )}
          {taskSettings?.attachments?.visible && (
            <Grid item xs={12}>
              <Box display="flex" justifyContent="space-between" marginBottom="8px">
                <Typography style={{ fontSize: 16, fontWeight: '500', marginBottom: 8 }}>
                  Attachments {taskSettings?.attachments?.required && <span className={classes.required}>*</span>}{' '}
                </Typography>
                <Box display="flex" onClick={() => setOpenModalUploadFile(true)} style={{ cursor: 'pointer' }}>
                  <AttachFileIcon fontSize="small" htmlColor="#4b7bff" />{' '}
                  <Typography style={{ color: '#4b7bff', fontSize: 14, fontWeight: '500' }}>New attachments</Typography>
                </Box>
              </Box>
              <Box>
                {files.map((file) => (
                  <Attachment
                    fileId={file?._id}
                    key={file?._id || file?.url}
                    id={file._id}
                    formId={form?._id}
                    defaultTags={file?.tags || []}
                    name={file?.name}
                    size={file?.size || 0}
                    url={file?.url || file?.public_url}
                    loading={file?.loading || false}
                    mimetype={file?.mimetype}
                    withAddBtn={false}
                    rightCreateTag={-235}
                    //onClickDelete={() => onDeleteFile(file)}
                    onClickPreview={() => {
                      if (file.mimetype.includes('pdf')) {
                        const link = document.createElement('a');
                        link.href = file.preview_url;
                        link.setAttribute('target', '_blank');
                        document.body.appendChild(link);
                        link.click();
                        return;
                      }
                      handleOpen(file.public_url, files);
                    }}
                    archived={!!file.archivedDate}
                  />
                ))}
              </Box>
            </Grid>
          )}
          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end">
              <DashButton onClick={handleOnSave} style={{ width: 100 }}>
                Save
              </DashButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <CarouselModal
        open={open}
        setOpen={setOpen}
        filesSelected={{
          length: filesSelected.length,
          selectedFileIndex: 0,
          files:
            filesSelected?.map((file) => ({
              download_url: file.download_url,
              name: file.name,
              mimetype: file.mimetype,
              public_url: file.public_url,
              tags: file?.tags,
              archiveDate: file?.archivedDate,
            })) || [],
        }}
        loadingOpenFiles={loadingOpenFiles}
        setLoadingOpenFiles={setLoadingOpenFiles}
      />
      <ModalUploadFile
        organizationId={organization?._id}
        open={openModalUploadFile}
        onClose={() => setOpenModalUploadFile(false)}
        onConfirm={(_, _files) => {
          setFiles((prevFiles) => [...prevFiles, ..._files]);
          setOpenModalUploadFile(false);
        }}
      />
    </Box>
  );
};

CaseDetailTask.propTypes = {
  users: PropTypes.array.isRequired,
  form: PropTypes.object.isRequired,
  task: PropTypes.object.isRequired,
  taskKey: PropTypes.string.isRequired,
  updateTasks: PropTypes.func.isRequired,
};

export default CaseDetailTask;
