import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Fade,
  FormControlLabel,
  makeStyles,
  Modal,
  Typography,
  useMediaQuery,
  Grid,
  Divider,
} from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';
import React, { useEffect, useState } from 'react';
import CancelIcon from '@material-ui/icons/Cancel';
import BackupIcon from '@material-ui/icons/Backup';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import { onUpdateOfficerProfile, uploadFormImg } from '../../services/unarmed';
import officerProfile from '../../assets/officer.png';
import LabelWithColor from './LabelWithColor';
import ModalPopUp from '../../components/ModaPopupl';
import EditOfficer from './components/EditOfficer';
import UserAttr from '../../components/UserAttr';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 20,
  },
  title: {
    marginTop: 50,
  },
  label: {
    fontSize: 12,
    color: '#aaa',
  },

  button: {
    background: ' #3B70FE',
    textTransform: 'capitalize',
    color: '#fff',
    '&:hover': {
      background: '#3B70FE',
    },
    '& .MuiButton-label': {
      fontSize: 14,
    },
  },

  buttonOutlined: {
    letterSpacing: '1px',
    fontSize: 13,
    background: '#fff',
    fontWeight: '500',
    marginRight: 10,
    width: 'fit-content',
    alignSelf: 'flex-end',
    border: '1px solid #4b7bff ',
    color: '#4b7bff',
    '&:hover': {
      background: '#fff',
      border: '1px solid #4b7bff ',
    },
    '& .MuiButton-label': {
      fontSize: 13,
    },
  },

  img: {
    width: theme.spacing(18),
    height: theme.spacing(18),
    borderRadius: '50%',
    objectFit: 'cover',
  },

  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: '#F9FAFF',
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: 20,
  },
}));

const turnOnRiskLevels = (currentRisk, riskLevel) => {
  if (riskLevel === 'LOW') {
    return currentRisk === 'low' ? 'LOW' : null;
  }
  if (riskLevel === 'MEDIUM') {
    if (currentRisk === 'low') {
      return 'LOW';
    }
    return currentRisk === 'medium' ? 'MEDIUM' : null;
  }
  if (riskLevel === 'HIGH') {
    return 'HIGH';
  }
  return null;
};

/**
 * Officer Information Component
 * @param {Object} props - Component props
 * @param {Object} props.officer - Officer object
 * @param {string} props.firstName - First name of the officer
 * @param {string} props.badge - Badge number of the officer
 * @param {string} props.lastName - Last name of the officer
 * @param {boolean} props.loading - Loading indicator
 * @param {string} props.phone - Phone number of the officer
 * @param {string} props.id - Officer ID
 * @param {Function} props.setOfficer - Function to set officer data
 * @param {string} props.gender - Gender of the officer
 * @param {Function} props.setGender - Function to set officer gender
 * @param {Function} props.setRace - Function to set officer race
 * @param {string} props.race - Race of the officer
 * @param {Function} props.renderCards - Function to render cards
 * @param {string} props.email - Email of the officer
 * @param {Object} props.organization - Organization object
 * @returns {JSX.Element} OfficerInfo component
 */
const OfficerInfo = ({
  officer,
  firstName,
  badge,
  lastName,
  loading,
  phone,
  id,
  setOfficer,
  gender,
  setGender,
  setRace,
  race,
  renderCards,
  email,
  organization,
}) => {
  const classes = useStyles();
  const matches = useMediaQuery('(min-width: 1400px)');
  const [open, setOpen] = React.useState(false);
  const [image, setImage] = useState({});
  const [hashFile, setHashFile] = useState('');
  const [loadingUpdateProfile, setLoadingUpdateProfile] = useState(false);

  const [openEdit, setOpenEdit] = useState(false);
  const [bradyList, setBradyList] = useState(false);
  const [loadingPercentage, setLoadingPercentage] = useState(0);
  const [editingImg, setEditingImg] = useState({});

  useEffect(() => {
    if (officer) {
      setImage(officer?.photo);
      setEditingImg(officer?.photo);
      setBradyList(officer?.bradyList);
    }
  }, [officer]);

  const handleClose = () => {
    setOpen(false);
  };

  const onSave = async () => {
    const profileData = {
      firstName: firstName.value,
      lastName: lastName.value,
      badgeNumber: badge.value,
      phoneNumber: phone.value,
      email: email.value,
      gender,
      raceEthnicity: race,
    };
    if (hashFile) {
      profileData.photo = hashFile;
    }
    setLoadingUpdateProfile(true);
    onUpdateOfficerProfile(id, profileData)
      .then((res) => {
        setOfficer(res);

        toast.success('Officer profile updated Successfully');
        setOpenEdit(false);
      })
      .catch((err) => {
        toast.error(err.response?.data?.error?.details[0]?.message || 'Something went Wrong');
      })
      .finally(() => {
        setLoadingUpdateProfile(false);
      });
  };

  const onUpdateBradyList = async (bl) => {
    const profileData = {
      bradyList: bl,
    };

    onUpdateOfficerProfile(id, profileData)
      .then((res) => {
        setOfficer(res);
        setBradyList(bl);
        toast.success('Officer brady list updated Successfully');
      })
      .catch((err) => {
        toast.error(err.response?.data?.error?.details[0]?.message || 'Something went Wrong');
      })
      .finally(() => {
        setOpenEdit(false);
        setLoadingUpdateProfile(false);
      });
  };

  const uploadOnLoad = async (fl) => {
    const imgData = new FormData();
    imgData.append('file', fl);
    imgData.append('organizationId', organization?._id);
    setOpen(false);
    try {
      const img = await uploadFormImg(imgData, setLoadingPercentage);
      setEditingImg({
        mimetype: img.mimetype,
        public_url: img.publicUrl,
        download_url: img.download_url,
      });
      setHashFile(img.hashId);
    } catch (error) {
      console.log(error);
    }
  };
  const onCancel = () => {
    setImage(officer?.photo || officerProfile);
    setHashFile('');
    firstName.setValue(officer.firstName);
    lastName.setValue(officer.lastName);
    email.setValue(officer.email);
    badge.setValue(officer.badgeNumber);
    phone.setValue(officer.phoneNumber);
    setGender(officer.gender);
    setRace(officer.raceEthnicity);
    setOpenEdit(false);
  };
  return (
    <>
      <Box width="100%" bgcolor="#fff">
        <Box
          width="100%"
          display="flex"
          padding={2}
          alignItems="center"
          borderBottom="1px solid #0000001F"
          justifyContent="space-between"
        >
          <Typography variant="h5">Officer Profile</Typography>
          <Button
            data-testid="officer-profile-button-edit"
            onClick={() => setOpenEdit(true)}
            color="primary"
            variant="contained"
            className={classes.button}
          >
            Edit Profile
          </Button>
        </Box>
        {loading ? (
          <Box width="100%" height="300px" display="flex" alignItems="center" justifyContent="center">
            <CircularProgress color="#000" size="40px" />
          </Box>
        ) : (
          <Box paddingX={4} paddingY={3}>
            <Box width="100%" alignItems="center" display="flex" justifyContent="space-between" height={200}>
              <Box display="flex" alignItems="center">
                <Box mr={2} width="180px">
                  <img src={image?.public_url || officerProfile} alt="profileImg" className={classes.img} />
                </Box>
                <Box display="flex" justifyContent="flex-start" flexDirection="column">
                  <Typography variant="h5" style={{ fontSize: 20, fontWeight: 'bold' }}>
                    {`${officer.firstName} ${officer.lastName}`}
                  </Typography>
                  <UserAttr title="Badge/Serial Number" value={officer.badgeNumber || 'N/A'} />
                  <UserAttr
                    title="Early Intervention System"
                    value={`${officer?.EIS?.complaints} Complaints / ${
                      organization?.EIS?.riskLevel?.evaluationTime || 0
                    } Months`}
                  />
                  <Box display="flex" alignItems="center" style={{ gap: 5 }} minWidth="200px">
                    <LabelWithColor
                      priority="low"
                      label=""
                      selectedPriority={turnOnRiskLevels('low', turnOnRiskLevels('low', officer?.EIS?.riskLevel))}
                    />
                    <LabelWithColor
                      priority="medium"
                      label=""
                      selectedPriority={turnOnRiskLevels('medium', turnOnRiskLevels('medium', officer?.EIS?.riskLevel))}
                    />
                    <LabelWithColor
                      priority="high"
                      label=""
                      selectedPriority={turnOnRiskLevels('high', turnOnRiskLevels('high', officer?.EIS?.riskLevel))}
                    />
                  </Box>
                </Box>
              </Box>
              {matches ? renderCards() : null}
            </Box>
            {matches ? null : renderCards()}
            <Grid container alignItems="center">
              <Grid item xs={12}>
                <Box paddingY={4}>
                  <Divider />
                </Box>
                <Box display="grid" gridTemplateColumns="repeat(auto-fit, minmax(100px, 1fr))" gridGap="8px">
                  <div>
                    <UserAttr
                      title="Phone"
                      value={officer.phoneNumber?.replace(/(\d{3})(\d{3})(\d{4})/g, '($1) $2-$3') || 'N/A'}
                    />
                  </div>
                  <div>
                    <UserAttr title="Email" value={officer.email || 'N/A'} />
                  </div>
                  <div>
                    <UserAttr title="Gender" value={officer?.gender?.join(',') || 'N/A'} />
                  </div>
                  <div>
                    <UserAttr title="Race/Ethnicity" value={officer?.raceEthnicity?.join(',') || 'N/A'} />
                  </div>
                  <div>
                    <Box width="100%" maxWidth="200px" minWidth="200px" marginRight="10px">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={bradyList}
                            onChange={(e) => {
                              onUpdateBradyList(e.target.checked);
                            }}
                            name="checkedB"
                            color="primary"
                          />
                        }
                        label="Brady list"
                      />
                    </Box>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
      <ModalPopUp setOpen={onCancel} open={openEdit}>
        <EditOfficer
          phone={phone}
          image={editingImg || image}
          firstName={firstName}
          lastName={lastName}
          gender={gender}
          race={race}
          setGender={setGender}
          setRace={setRace}
          onSave={onSave}
          loading={loadingUpdateProfile}
          email={email}
          loadingPercentage={loadingPercentage}
          uploadOnLoad={uploadOnLoad}
          onCancel={onCancel}
        />
      </ModalPopUp>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Box display="flex" justifyContent="flex-end">
              <CancelIcon style={{ cursor: 'pointer' }} onClick={() => setOpen(false)} />
            </Box>
            <DropzoneArea
              // acceptedFiles={["image/jpeg", "image/png", "image/jpg"]}
              dropzoneClass="default-dropzone-class"
              maxFileSize={5000000}
              onChange={(fl) => {
                if (fl.length > 0) {
                  uploadOnLoad(fl[fl.length - 1]);
                }
              }}
              filesLimit={3}
              dropzoneText="Click or drag and drop an image here"
              showPreviewsInDropzone={false}
              Icon={() => <BackupIcon fontSize="large" htmlColor="#A2BAFF" />}
            />
          </div>
        </Fade>
      </Modal>
    </>
  );
};

OfficerInfo.propTypes = {
  officer: PropTypes.object,
  firstName: PropTypes.string,
  badge: PropTypes.string,
  lastName: PropTypes.string,
  loading: PropTypes.bool,
  phone: PropTypes.string,
  id: PropTypes.string,
  setOfficer: PropTypes.func,
  gender: PropTypes.string,
  setGender: PropTypes.func,
  setRace: PropTypes.func,
  race: PropTypes.string,
  renderCards: PropTypes.func,
  email: PropTypes.string,
  organization: PropTypes.object,
};

export default OfficerInfo;
