import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

import HTMLContent from './HTMLContent';
import Tags from './Tags';
import { useGlobalStyles } from '../styles/GlobalStyles';
import { PreviewImg } from './Log';
import LogIcon from './LogIcon';
import { UserInfo } from './NoteLogs';
import { Typography } from 'antd';
import ArchivedImage from './ArchivedImage';

const Note = ({ comment, files, user, userInfo, subject = 'case', onClickFile }) => {
  const globalClasses = useGlobalStyles();
  const hasFiles = files.length > 0;
  const hasComment = !!comment;
  const hasBoth = hasFiles && hasComment;
  return (
    <>
      <UserInfo user={user} userInfo={userInfo} />{' '}
      {hasComment && (
        <>
          made a <span className={globalClasses.emphasis}>new comment</span>
        </>
      )}
      {hasBoth && ' and '}
      {hasFiles && (
        <>
          <span className={globalClasses.emphasis}>uploaded {files.length}</span> file(s)
        </>
      )}{' '}
      to the {subject}
      {hasComment && (
        <>
          <br />
          <HTMLContent content={comment} />
        </>
      )}
      {hasFiles && (
        <>
          <Box display="flex" flexDirection="column">
            {files.map((file, index) => {
              const showPreview = !file?.archivedDate;
              return (
                <div key={file._id}>
                  <Box display="flex" alignItems="center" justifyContent="flex-start">
                    <PreviewImg
                      style={
                        !file.name.includes('.pdf')
                          ? { height: 100, margin: 6 }
                          : { minHeight: 35, width: 'fit-content' }
                      }
                    >
                      {showPreview ? (
                        <LogIcon fl={file} handleClickEvents={onClickFile(file)} index={index} />
                      ) : (
                        <ArchivedImage />
                      )}
                    </PreviewImg>
                    <Typography style={{ fontSize: 14 }}>{file.name}</Typography>
                  </Box>
                  <Tags fileId={file?._id} defaultTags={file?.tags || []} />
                </div>
              );
            })}
          </Box>
        </>
      )}
    </>
  );
};

Note.propTypes = {
  files: PropTypes.array,
  comment: PropTypes.string,
  user: PropTypes.object,
  userInfo: PropTypes.object,
};

export default Note;
