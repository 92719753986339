import React from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress } from '@material-ui/core';

/**
 * Button component with optional loading spinner.
 * @param {Object} props - The component props.
 * @param {boolean} props.loading - Indicates if the button is in a loading state.
 * @param {function} props.onClick - Click event handler for the button.
 * @param {string} props.text - Text to display on the button.
 * @param {string} [props.className] - Additional CSS class name for the button.
 * @param {Object} [props.classes] - CSS classes to apply to the button.
 * @param {'default' | 'inherit' | 'primary' | 'secondary'} [props.color] - The color of the button.
 * @param {string} [props.circularColor='#364F74'] - Color of the circular progress spinner.
 * @param {'button' | 'submit' | 'reset'} [props.type='button'] - The type of the button.
 * @param {boolean} [props.disabled] - Indicates if the button is disabled.
 * @param {'text' | 'outlined' | 'contained'} [props.variant='text'] - The variant of the button.
 */
const Btn = ({
  dataTestId,
  loading,
  onClick,
  text,
  className,
  classes,
  color,
  circularColor = '#364F74',
  type = 'button',
  disabled,
  variant = 'text',
}) => (
  <Button
    data-testid={dataTestId}
    onClick={onClick}
    className={className}
    color={color}
    type={type}
    variant={variant}
    disabled={disabled}
    classes={classes}
    style={{ textTransform: 'capitalize' }}
  >
    {text} {loading && <CircularProgress color={circularColor} size="20px" style={{ marginLeft: 10 }} />}
  </Button>
);

Btn.propTypes = {
  dataTestId: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object,
  color: PropTypes.oneOf(['default', 'inherit', 'primary', 'secondary']),
  circularColor: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf(['text', 'outlined', 'contained']),
};

export default Btn;
