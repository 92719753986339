import { Typography, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() => ({
  subTitle: {
    paddingBottom: 10,
    fontSize: 14,
  },
}));

export default function CUSubtitle({ data }) {
  const classes = useStyles();

  return <Typography className={classes.subTitle}>{data.text}</Typography>;
}
