import React, { useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const ArrayTooltip = ({ items }) => {
  const [onHover, setOnHover] = useState(false);

  const remainingItems = items?.slice(1);

  return (
    <Box display="flex" alignItems="center" position="relative">
      <Box
        display="flex"
        alignItems="center"
        onMouseEnter={() => setOnHover(true)}
        onMouseLeave={() => setOnHover(false)}
      >
        <Typography variant="subtitle2">{items[0] || ``}</Typography>

        {items.length > 1 && (
          <Typography variant="body2" style={{ color: 'rgb(75, 123, 255)', marginLeft: 8 }}>
            (+{items.length - 1})
          </Typography>
        )}
      </Box>

      {items.length > 1 && (
        <TooltipContainer visible={onHover}>
          {remainingItems.map((item, index) => (
            <Box
              key={index}
              display="flex"
              alignItems="center"
              mb={index < remainingItems.length - 1 ? 2 : 0}
              style={index < remainingItems.length - 1 ? { paddingBottom: 8, borderBottom: '1px solid #e0e0e0' } : {}}
            >
              <Typography variant="subtitle2">{item}</Typography>
            </Box>
          ))}
        </TooltipContainer>
      )}
    </Box>
  );
};

const TooltipContainer = styled.div`
  position: absolute;
  left: 0;
  top: 30px;
  width: 200px;
  background-color: #fff;
  border-radius: 8px;
  z-index: 99;
  padding: 8px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
              0px 1px 1px 0px rgba(0, 0, 0, 0.14),
              0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
`;

ArrayTooltip.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ArrayTooltip;
