const useCheckAppVersion = (setRequireRefresh) => {
  const onCheckAppVersion = (appVr) => {
    const appVersion = localStorage.getItem('app-dashboard-version');
    if (appVersion) {
      if (appVersion !== appVr) {
        setRequireRefresh(true);
        localStorage.setItem('app-dashboard-version', appVr);
      }
    } else {
      localStorage.setItem('app-dashboard-version', appVr);
    }
  };

  return {
    onCheckAppVersion,
  };
};

export default useCheckAppVersion;
