import { Box, Button, makeStyles, TableCell, TableRow, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import PaginationTable from '../../components/PaginationTable';
import EnhancedTable from '../../components/Table';
import useOrganization from '../../hooks/useOrganization';
import { getCubes } from '../../services/cube';
import { reloadOrganizationCubes } from '../../services/unarmed';
import useBuildingSchemaStatus from '../../hooks/useBuildingSchemaStatus';

const columns = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Dimension Name',
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    label: 'Description',
  },
  { id: 'type', numeric: false, disablePadding: false, label: 'Type' },
];
const Meacolumns = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Measures Name',
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    label: 'Description',
  },
  { id: 'type', numeric: false, disablePadding: false, label: 'Type' },
];

const typesColumn = ['Type Name', 'Description'];

const typeRows = [
  { type: 'GLOBAL', description: 'A report that can be use by all users' },
  {
    type: 'PRIVATE',
    description: 'A report that can be use only by the owner of the report',
  },
];

const useStyles = makeStyles(() => ({
  container: {
    padding: 20,
    width: '100%',

    '& .makeStyles-root-51': {
      boxShadow: 'unset !important',
    },
  },

  sectionTitle: {
    fontSize: 16,
    fontWeight: '500',
    marginBottom: 24,
    marginTop: 24,
    color: '#000000AD',
  },
}));

export default function InsightsDocumentation() {
  const classes = useStyles();

  const [cubes, setCubes] = useState([]);
  const { organization } = useOrganization();

  const [cubesBuilded] = useBuildingSchemaStatus(organization);

  const handleOnReloadCubes = useCallback(() => {
    if (cubesBuilded) {
      reloadOrganizationCubes(organization._id)
        .then(() => {
          toast.success('Organization cubes reloaded successfully!');
        })
        .catch(() => {
          toast.error('An error has occurred reloading the cubes.');
        });
    }
  }, [organization, cubesBuilded]);

  useEffect(() => {
    const onGetCubes = () => {
      getCubes()
        .then(({ data }) => setCubes(data?.cubes))
        .catch((err) => console.log(err));
    };
    onGetCubes();
  }, []);

  const renderRow = (row, index) => (
    <TableRow
      hover
      role="checkbox"
      tabIndex={-1}
      key={index}
      className={classes.row}
      style={{ cursor: 'pointer', height: 55 }}
    >
      <TableCell
        component="th"
        scope="row"
        align="left"
        //   onClick={(e) => goToCaseDetails(e, row, handleClick)}
      >
        {row.title}
      </TableCell>

      <TableCell
        align="left"
        //   onClick={(e) => goToCaseDetails(e, row, handleClick)}
      >
        {row?.description || 'N/A'}
      </TableCell>
      <TableCell
        component="th"
        scope="row"
        align="left"
        //   onClick={(e) => goToCaseDetails(e, row, handleClick)}
      >
        {row.type}
      </TableCell>
    </TableRow>
  );
  const renderRowType = (row, index) => (
    <TableRow
      hover
      role="checkbox"
      tabIndex={-1}
      key={index}
      className={classes.row}
      style={{ cursor: 'pointer', height: 55 }}
    >
      <TableCell component="th" scope="row" align="left">
        {row.type}
      </TableCell>

      <TableCell align="left">{row.description}</TableCell>
    </TableRow>
  );

  return (
    <Box className={classes.container}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h5">Insights Documentation</Typography>
        <Button
          data-testid="data-docs-button-reload-cubes"
          variant="contained"
          color="primary"
          onClick={handleOnReloadCubes}
          disabled={!cubesBuilded}
        >
          Reload Cubes
        </Button>
      </Box>

      <Typography className={classes.sectionTitle}>Types</Typography>
      <EnhancedTable
        disableHeaderArrows
        pagination={false}
        columns={typesColumn}
        rows={typeRows}
        rowRender={renderRowType}
        // loading={loading}
        // count={forms?.headers?.['x-pagination-totalitems']}
      />

      <Typography className={classes.sectionTitle}>Dimensions</Typography>
      <PaginationTable
        columns={columns}
        rows={cubes
          ?.map((cube) =>
            cube.dimensions
              ?.filter((dim) => dim.isVisible)
              .map((dim) => {
                if (dim?.meta?.allowedOrganizations) {
                  if (dim?.meta?.allowedOrganizations?.includes(organization?._id)) {
                    return dim;
                  }
                  return null;
                }

                return dim;
              })
              .filter((dim) => dim)
          )
          .flat()}
        renderRow={renderRow}
      />

      <Typography className={classes.sectionTitle}>Measures</Typography>
      <PaginationTable
        columns={Meacolumns}
        rows={cubes
          ?.map((cube) => cube.measures)
          .map((measure) => {
            if (measure?.meta?.allowedOrganizations) {
              if (measure?.meta?.allowedOrganizations?.includes(organization?._id)) {
                return measure;
              }
              return null;
            }

            return measure;
          })
          .filter((measure) => measure)
          .flat()}
        renderRow={renderRow}
      />

      <Box marginBottom="100px" />
    </Box>
  );
}
