import React, { useState } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import PhoneIcon from '@material-ui/icons/Phone';
import WrapperComponent from './WrapperComponent';
import Input from '../../components/Input';
import useInput from '../../hooks/useInput';
import Settings from '../utils/Settings';
import PreviewLabel from '../utils/Label';
import InputPhone from '../../components/PhoneInput';
import HideAnswer from '../../components/HideAnswer';

const useStyles = makeStyles(() => ({
  title: {
    fontSize: 14,
    color: '#000000DE',
    marginLeft: 10,
  },
  label: {
    fontSize: 16,
    color: '#000000DE',
    fontWeight: '500',
  },
}));

export default function Phone({
  questionBankComponent,
  id,
  componentId,
  onUpdateJson,
  onDeleteComponent,
  path,
  data,
  componentIndex,
  duplicateComponent,
  refActionModal,
  setModal,
}) {
  const classes = useStyles();

  const [checkQuestion, setCheckQuestion] = useState([!!data?.description, !!data.requiredMessage]);

  const required = useInput(!!data?.required, false, false, true);
  const descriptionQuestion = useInput(data?.description || '');
  const requiredQuestionMessage = useInput(data?.requiredMessage || '');
  const question = useInput(data?.question || '');
  const [loading, setLoading] = React.useState(false);

  const handleSaveComponent = () => {
    const dataJson = {
      question: question.value,
      ...(descriptionQuestion.value ? { description: descriptionQuestion.value } : {}),
      ...(requiredQuestionMessage.value ? { requiredMessage: requiredQuestionMessage.value } : {}),
      ...(data.dataReportLink ? { dataReportLink: data.dataReportLink } : {}),
      required: required.value,
    };

    onUpdateJson(dataJson);
  };
  const saveOnTimeOut = () => {
    setLoading(true);
    setTimeout(() => {
      handleSaveComponent();
      setLoading(false);
    }, 500);
  };
  const renderSettings = () => (
    <Settings
      componentId={componentId}
      componentType="phone"
      defaultDataReportLink={data.dataReportLink}
      onSelectDataReportLink={(dataReportLink) => onUpdateJson({ ...data, dataReportLink })}
      saveOnTimeOut={saveOnTimeOut}
      questions={['description', 'required']}
      checkQuestion={checkQuestion}
      descriptionQuestion={descriptionQuestion}
      requiredQuestion={requiredQuestionMessage}
      setCheckQuestion={setCheckQuestion}
    />
  );

  const renderPreview = () => (
    <Box>
      <PreviewLabel question={question.value} qNumber={componentIndex + 1} required={required.value} />
      <Box width={324} marginLeft="45px">
        <HideAnswer marginLeft="-7px" marginTop={0}>
          <InputPhone disabled label="" />
        </HideAnswer>
      </Box>
    </Box>
  );

  return (
    <WrapperComponent
      onBlur={(!questionBankComponent && saveOnTimeOut) || function () {}}
      renderSettings={renderSettings}
      componentIndex={componentIndex}
      duplicateComponent={duplicateComponent}
      loading={loading}
      isQuestionBankComponent={questionBankComponent}
      required={required}
      renderPreview={renderPreview}
      onDeleteComponent={onDeleteComponent}
      id={id}
      componentId={componentId}
      path={path}
      dataSaved={data?.question}
      refActionModal={refActionModal}
      setModal={setModal}
    >
      <Box display="flex" alignItems="center">
        <PhoneIcon htmlColor="#6D7385" />
        <Typography className={classes.title}>Phone</Typography>
      </Box>
      <Box display="flex" alignItems="center" marginTop="15px">
        <Typography className={classes.label}>Q{componentIndex + 1}</Typography>
        <ArrowRightAltIcon style={{ fontSize: 14, marginRight: 10 }} />
        <Input
          placeholder="Enter name question"
          value={question.value}
          onChange={(e) => {
            question.onChange(e);
            saveOnTimeOut();
          }}
        />
      </Box>
    </WrapperComponent>
  );
}
