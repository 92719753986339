import { Box, Button, CircularProgress, makeStyles, Typography, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import useInput from '../../hooks/useInput';
import { OutLineInput } from './UpdateCase';
import { assignOfficer, onSearchOfficer } from '../../services/unarmed';
import Card from '../../components/Card';
import SearchResults from '../../components/SearchResults';
import formatText from '../../utils/formatText';

const useStyles = makeStyles(() => ({
  button: {
    background: ' #3B70FE',
    textTransform: 'capitalize',
    color: '#fff',
    '&:hover': {
      background: '#3B70FE',
    },
    '& .MuiButton-label': {
      fontSize: 14,
    },
    marginTop: 8,
    marginBottom: 16,
  },
  labelError: {
    fontSize: 12,
    color: 'red',
  },
}));

/**
 * Component for searching officers and assigning them to a case.
 * @param {Object} props - The props for the component.
 * @param {string} props.id - The ID of the case.
 * @param {Function} props.setIsRefetch - Function to trigger refetching case data.
 * @param {Function} props.setIsRequestingData - Function to indicate if data is being requested.
 * @returns {JSX.Element} - The SearchOfficer component.
 */
const SearchOfficer = ({ id, setIsRefetch, setIsRequestingData, defaultOfficers }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [loadingAddOfficer, setLoadingAddOfficer] = useState(false);
  const searchOfficer = useInput('');
  const [officers, setOfficers] = useState([]);
  const [searched, setSearched] = useState(false);

  /**
   * Function to search officers based on the input value.
   * @param {Event} e - The event object.
   */
  const searchOfficers = async (e) => {
    e.preventDefault();
    if (!searchOfficer.value) {
      return;
    }
    try {
      setLoading(true);
      setIsRequestingData(true);
      const { data } = await onSearchOfficer(searchOfficer.value);
      setOfficers(data);
      setLoading(false);
      setIsRequestingData(false);
      setSearched(true);
    } catch (error) {
      toast.error(error.response.data.error.details[0].message);
      setLoading(false);
    }
  };

  /**
   * Function to assign an officer to the case.
   * @param {Object} currentOfficer - The officer object to be assigned.
   */
  const onAddOfficer = async (currentOfficer) => {
    try {
      setIsRequestingData(true);
      setLoadingAddOfficer(currentOfficer?._id);
      await assignOfficer(id, currentOfficer?._id);
      toast.success(`Officer assigned Successfully`);
      setIsRequestingData(false);
      setLoadingAddOfficer(false);
      setIsRefetch();
    } catch (error) {
      setLoadingAddOfficer(false);
      toast.error(error?.response?.data?.message);
      toast.error(error?.response?.data?.error?.details[0]?.message);
      console.log(error);
    }
  };

  useEffect(() => {
    if (searchOfficer.value === '' && searched) {
      setSearched(false);
    }
  }, [searchOfficer.value, searched]);

  return (
    <form onSubmit={searchOfficers}>
      <Card title="Search Officer">
        <Box position="relative">
          <OutLineInput
            data-testid="case-details-officers-input-search-officer"
            placeholder="Search by badge number, first or last name"
            value={searchOfficer.value}
            onChange={searchOfficer.onChange}
            error={searchOfficer.error}
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              'aria-label': 'weight',
            }}
            labelWidth={0}
          />
          <SearchIcon htmlColor="#707687" style={{ position: 'absolute', right: 10, top: 5 }} />
        </Box>
        <span className={classes.labelError}>{searchOfficer.error}</span>
        <Grid container>
          <Grid item xs={12}>
            <Box display="flex" alignItems="flex-end" justifyContent="flex-end" flexDirection="column" mt={2}>
              <Button
                data-testid="case-details-officers-button-search-submit"
                variant="contained"
                className={classes.button}
                type="submit"
              >
                {loading ? <CircularProgress color="#fff" size="25px" /> : 'Search'}
              </Button>
              <Box mt={1}>
                <Typography
                  variant="body2"
                  style={{
                    fontWeight: '500',
                    fontSize: 12,
                    color: '#3B70FE',
                    cursor: 'pointer',
                  }}
                  data-testid="case-details-officers-button-search-clear"
                  onClick={() => setOfficers([])}
                >
                  Clear
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <SearchResults
          columns={[
            {
              name: 'Badge Number',
              key: 'badgeNumber',
              xs: 3,
              renderValue: (value) => formatText(value, 16),
            },
            {
              name: 'Officer First Name',
              key: 'firstName',
              xs: 3,
              renderValue: (value) => formatText(value, 16),
            },
            {
              name: 'Officer Last Name',
              key: 'lastName',
              xs: 3,
              renderValue: (value) => formatText(value, 16),
            },
          ]}
          data={officers}
          loading={loadingAddOfficer}
          defaultData={defaultOfficers}
          onClickAdd={(item) => onAddOfficer(item)}
          searched={searchOfficer.value !== '' && searched}
          noResultsText="No officers found yet"
        />
      </Card>
    </form>
  );
};

SearchOfficer.propTypes = {
  id: PropTypes.string.isRequired,
  setIsRefetch: PropTypes.func.isRequired,
  setIsRequestingData: PropTypes.func.isRequired,
  defaultOfficers: PropTypes.array.isRequired,
};

export default SearchOfficer;
