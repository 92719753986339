import { Box, Typography, makeStyles } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { downloadImage } from '../../../utils/libs';
import LogIcon from '../../../components/LogIcon';
import HTMLContent from '../../../components/HTMLContent';
import Tags from '../../../components/Tags';
import CarouselModal from '../../../components/CarouselModal';
import ArchivedImage from '../../../components/ArchivedImage';
import DeleteIcon from '@material-ui/icons/Delete';
import TimeLineItemNotes from '../../Activity/TimeLineItemNotes';
import { CustomModalDelete } from '../../../components/CustomModalDelete';
import { Context } from '../../../Context';
import { getProjectInfo, onArchiveNoteProject, onUnArchiveNoteProject } from '../../../services/unarmed';
import useOrganization from '../../../hooks/useOrganization';

const sortByCreatedAt = (data, order = 'desc') => {
  return data.sort((x, y) => {
    const dateX = new Date(x.createdAt).getTime();
    const dateY = new Date(y.createdAt).getTime();
    return order === 'asc' ? dateX - dateY : dateY - dateX;
  });
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  imgModal: {
    objectFit: 'contain',
    maxWidth: 600,
    maxHeight: 400,
  },
  link: {
    display: 'flex',
    position: 'absolute',
    top: 10,
    right: 80,
    cursor: 'pointer',
    padding: 13,
    zIndex: 999999,
    borderRadius: '50%',
    background: '#494949',
  },
  emphasis: {
    color: '#4b7bff',
    textTransform: 'capitalize',
    fontWeight: '600',
  },
  noteLogContainer: {
    position: 'relative',
    padding: '10px 20px',
    borderBottom: '1px solid #e0e0e0',
    '&:hover $deleteIcon': {
      display: 'block',
    },
  },
  deleteIcon: {
    display: 'none',
    position: 'absolute',
    top: '10px',
    right: '10px',
    fontSize: '24px',
    color: '#848484',
    cursor: 'pointer',
    transition: 'color 0.3s ease',
    '&:hover': {
      color: '#ff4d4d',
    },
  },
}));

function NoteLog({ createdAt, attachments = [], text, user, userInfo, createdBy, handleRestoreClick,
  archivedDate,
  comment,
  handleDeleteNote  }) {
  const { myUser } = useContext(Context);
  const classes = useStyles();
  const { organization } = useOrganization();
  const [openDelete, setOpenDelete] = useState(false)
  const [open, setOpen] = React.useState(false);
  const [filesSelected, setFilesSelected] = React.useState({ files: [], selectedFileIndex: 0 });
  const [loadingOpenFiles, setLoadingOpenFiles] = React.useState([]);

  const handleOpen = (filesOp) => {
    setOpen(true);
    const filesToOpen = filesOp.filter((flop) => flop.mimetype.includes('image') || flop.mimetype.includes('video'));
    setFilesSelected({ files: filesToOpen, selectedFileIndex: 0 });
    setLoadingOpenFiles(filesToOpen.map(() => ({ loading: false })));
  };

  const getCanDelete = (user) => {
    if(myUser.role === 'admin' && organization?.permissions?.[myUser?.role]?.canDeleteNotesProjects !== 0 ){
      return true
    }else if(organization?.permissions?.[myUser?.role]?.canDeleteNotesProjects !== 0 && (myUser._id === user._id)  ){
      return true
    }else{
      return false
    }
  }

  const handleClickEvents = (fl) => ({
    onDownload: () => downloadImage(fl.download_url, fl.name),
    onClick: () => handleOpen(attachments),
  });

  const renderName = (wholeName = false) => {
    if (user && !user?.photo?.public_url && !wholeName) {
      return `${user?.email[0]?.toUpperCase()} ${user?.email[1]?.toUpperCase()}`;
    }
    if (user && wholeName) {
      return user?.email;
    }

    if (!user && userInfo && !wholeName) {
      return `${userInfo?.email[0]?.toUpperCase()} ${userInfo?.email[1]?.toUpperCase()}`;
    }
    if (!user && !userInfo && !wholeName && createdBy) {
      return `${createdBy?.firstName[0]?.toUpperCase()} ${createdBy?.lastName[1]?.toUpperCase()}`;
    }
    if (!user && userInfo && wholeName) {
      return userInfo?.email;
    }
    if (!user && !userInfo && wholeName && createdBy) {
      return `${createdBy?.firstName} ${createdBy?.lastName}`;
    }

    if (!user && !userInfo && !wholeName) {
      return 'DU';
    }
    if (!user && !userInfo && wholeName) {
      return 'Deleted User';
    }
  };

  const renderLog = () => {
    const hasFiles = attachments.length > 0;
    const hasComment = !!text;
    const hasBoth = hasFiles && hasComment;
    return (
      <>
        <strong>{renderName(true)}</strong>{' '}
        {hasComment && (
          <>
            made a <span className={classes.emphasis}>new comment</span>
          </>
        )}
        {hasBoth && ' and '}
        {hasFiles && (
          <>
            <span className={classes.emphasis}>uploaded {attachments.length}</span> file(s)
          </>
        )}{' '}
        to the project
        {hasComment && (
          <>
            <HTMLContent content={text} />
          </>
        )}
        {hasFiles && (
          <>
            <Box display="flex" flexDirection="column">
              {attachments.map((file, index) => {
                const showPreview = !file?.archivedDate;
                return (
                  <div key={file._id}>
                    <Box display="flex" alignItems="center" justifyContent="flex-start">
                      <PreviewImg
                        key={file._id}
                        style={
                          !file.name.includes('.pdf')
                            ? { height: 100, margin: 6 }
                            : { height: 35, width: 'fit-content' }
                        }
                      >
                        {showPreview ? (
                          <LogIcon fl={file} handleClickEvents={handleClickEvents(file)} index={index} />
                        ) : (
                          <ArchivedImage />
                        )}
                      </PreviewImg>
                      <Typography style={{ fontSize: 14 }}>{file.name}</Typography>
                    </Box>
                    <Tags fileId={file?._id} defaultTags={file?.tags || []} />
                  </div>
                );
              })}
            </Box>
          </>
        )}
      </>
    );
  };

  return (
    <Box className={classes.noteLogContainer}>
      <TimeLineItemNotes
         onRestoreClick={handleRestoreClick}
          archivedDate={archivedDate}
          createdAt={createdAt}
          //canEdit={myUser?._id === createdBy?._id}
          canDelete={getCanDelete()}
          //onEditClick={() => onEditClick({ _id, comment })}
          onDeleteClick={() => setOpenDelete(true)}
          editing={false}
          avatar={`${createdBy?.firstName[0]}${createdBy?.lastName[0]}`}
      >
        {renderLog()}

        <CustomModalDelete
          open={openDelete}
          handleClose={() => setOpenDelete(false)}
          fileName={comment}
          onConfirm={handleDeleteNote}
        />
      </TimeLineItemNotes>
    </Box>
  );
}

NoteLog.propTypes = {
  createdAt: PropTypes.instanceOf(Date).isRequired,
  attachments: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      mimetype: PropTypes.string,
      archivedDate: PropTypes.string,
      _id: PropTypes.string.isRequired,
    })
  ),
  text: PropTypes.string.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
    email: PropTypes.string,
    name: PropTypes.string,
    photo: PropTypes.shape({
      public_url: PropTypes.string,
    }),
  }).isRequired,
  userInfo: PropTypes.object,
  createdBy: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
};

export default function ProjectNoteLogs({ noteLogs, sortOrder = 'desc', archivedOnly , project}) {
  const [notes, setNotes] = useState([])
  const [notesShow, setNotesShow] = useState([]);


  useEffect(() => {
    if (noteLogs?.length) {
      setNotes(sortByCreatedAt(noteLogs, sortOrder));
    }
  }, [noteLogs, sortOrder]);

  useEffect(() => {
    if(archivedOnly) {
      const newLogs = notes.filter(log => log.hasOwnProperty('archivedDate') && log.archivedDate !== null);
      setNotesShow(newLogs)
    }else{
      const newLogsWithoutArchivedDate = notes.filter(log => !log.hasOwnProperty('archivedDate'));
      setNotesShow(newLogsWithoutArchivedDate)
    }

  }, [notes, archivedOnly])

  return (
    <Box width="100%">
      {!notesShow?.length ? (
        <Typography style={{ textAlign: 'center', fontWeight: 'bold' }}>No notes added to this project</Typography>
      ) : null}
      {notesShow?.map(({ _id, createdAt, attachments, text, user, userInfo, createdBy, archivedDate }, i) => (
        <NoteLog
          archivedDate={archivedDate}
          comment={text}
          handleDeleteNote={async() => {
            await onArchiveNoteProject(project, _id)
            const { data } = await getProjectInfo(project);
            setNotes(sortByCreatedAt(data.notes, sortOrder));
          }}
          handleRestoreClick={async() => {
            await onUnArchiveNoteProject(project, _id)
            const { data } = await getProjectInfo(project);
            setNotes(sortByCreatedAt(data.notes, sortOrder));
          }}
          key={createdAt}
          createdAt={createdAt}
          attachments={attachments}
          text={text}
          user={user}
          userInfo={userInfo}
          createdBy={createdBy}
        />
      ))}
    </Box>
  );
}

ProjectNoteLogs.propTypes = {
  noteLogs: PropTypes.arrayOf(
    PropTypes.shape({
      createdAt: PropTypes.string.isRequired,
      attachments: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          mimetype: PropTypes.string,
          archivedDate: PropTypes.string,
          _id: PropTypes.string.isRequired,
        })
      ),
      text: PropTypes.string.isRequired,
      user: PropTypes.object,
      userInfo: PropTypes.object,
      createdBy: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
      }).isRequired,
    })
  ).isRequired,
  sortOrder: PropTypes.string,
};

const PreviewImg = styled.div`
  height: 100px;
  position: relative;
  overflow: hidden;
  margin-right: 10px;

  & div:hover {
    opacity: 1;
  }
`;
