import { Box, Switch, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import shortid from 'shortid';

import Page, { PageTransition } from './Page';
import AddMore from '../../components/AddMore';
import DnDArea from '../../components/DnDArea';
import { onRemoveDnDItem } from '../../formEngine/utils/utls';
import { onSaveFormType } from '../../services/unarmed';
import { FORM_TYPE_VERSION_2 } from '../../config/config';
import { COMPONENT } from '../../formEngine/utils/constants';
import { ignoreExtraProps, pagesIgnoreExtraProps } from './Custom';

export default function OfficerUpdateBar({
  id,
  officerUpdates,
  onChangeStandardCheckbox,
  version,
  selected,
  setSelected,
  defaultPages = [],
  refActionModal,
  setOpen,
  onChangePages,
  onMoveComponent,
}) {
  const [pages, setPages] = useState(version === FORM_TYPE_VERSION_2 ? [] : [{ name: 'Custom' }]);
  const [addedDefaultSections, setAddedDefaultSections] = useState(false);

  useEffect(() => {
    if (defaultPages?.length > 0 && version === FORM_TYPE_VERSION_2 && !addedDefaultSections) {
      setPages(() => [...defaultPages]);
      setAddedDefaultSections(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultPages]);

  const handleOnClickNewPage = useCallback(() => {
    const pagesSorted = defaultPages.slice().sort((x, y) => {
      const xName = x.name?.split(' ') || [];
      const yName = y.name?.split(' ') || [];
      const lastCharXName = xName[xName?.length - 1];
      const lastCharYName = yName[yName?.length - 1];
      const digitX = parseInt(lastCharXName);
      const digitY = parseInt(lastCharYName);
      const digitXParsed = !Number.isNaN(digitX) ? digitX : 0;
      const digitYParsed = !Number.isNaN(digitY) ? digitY : 0;

      return digitXParsed - digitYParsed;
    });
    const lastPage = pagesSorted[pagesSorted.length - 1];
    const lastPageName = lastPage?.name?.split(' ');
    const lastPageNumber = lastPageName?.[lastPageName?.length - 1];
    const lastPageNumberParsed = parseInt(lastPageNumber);

    const newPages = [
      ...defaultPages,
      {
        name: `Custom section ${(Number.isNaN(lastPageNumberParsed) ? pagesSorted.length : lastPageNumberParsed) + 1}`,
        pageId: shortid.generate(),
        sections: [
          {
            name: 'Section Name',
            components: [],
          },
        ],
      },
    ];
    onChangePages(newPages);
    setPages(newPages);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultPages]);

  const handleOnDeletePage = useCallback(
    async (page, pageIndex) => {
      let response;

      const _pages = defaultPages.filter((_page) => _page.pageId !== page.pageId);

      try {
        response = await onSaveFormType(
          {
            officersCustomFields: pagesIgnoreExtraProps([
              ..._pages.map((_page) => ({
                ..._page,
                sections: [
                  {
                    ..._page?.sections?.[0],
                    components: ignoreExtraProps(version, _page?.sections).officersCustomFields,
                  },
                ],
              })),
            ]),
          },
          id
        );
      } catch (error) {
        toast.error(error?.response?.data?.message || error?.message);
      }

      if (response) {
        if (_pages.length === 0) {
          setSelected({ name: 'Standard' });
        } else if (_pages.length > 0) {
          const _pageIndex = _pages.length - 1;
          const lastPage = _pageIndex < 0 ? _pages[0] : _pages[_pageIndex];

          if (lastPage) {
            setSelected({
              ...lastPage,
              pageIndex: _pageIndex,
            });
          } else {
            setSelected({ name: 'Standard' });
          }
        }

        onChangePages(_pages);
        onRemoveDnDItem(_pages, setPages, pageIndex);
        toast.success('Section deleted successfully!');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [defaultPages]
  );

  const renderer = (moveItem) =>
    pages.map((page, pageIndex) => (
      <Page
        isDefaultForm={false}
        withToggle
        page={page}
        key={pageIndex}
        dragType={COMPONENT}
        onMoveComponent={onMoveComponent}
        index={pageIndex}
        moveItem={moveItem}
        setPages={setPages}
        onChangePages={onChangePages}
        selected={selected.pageId ? selected.pageId === page.pageId : selected.pageIndex === pageIndex}
        onSelectPage={() => setSelected({ ...page, pageIndex })}
        disablePageOptions={version === 1}
        handleDeletePage={() => {
          setOpen(true);
          refActionModal.current = {
            nameAction: 'section',
            actionPress: async () => {
              handleOnDeletePage(page, pageIndex);
            },
          };
        }}
      />
    ));

  return (
    <>
      <Box padding="20px" marginBottom="50px">
        <Typography style={{ fontSize: 12 }}>Enable sections to be updated on the forms.</Typography>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <LockOutlinedIcon style={{ marginRight: 7 }} />
          <PageTransition
            width="100%"
            maxWidth={434}
            display="flex"
            alignItems="center"
            borderRadius="4px"
            justifyContent="space-between"
            marginBottom="10px"
            selected={selected?.name === 'Standard'}
            style={{ background: '#fff' }}
            onClick={() => setSelected({ name: 'Standard' })}
            marginTop="10px"
          >
            <Typography
              style={{
                fontSize: 14,
                color: 'rgba(0, 0, 0, 0.87',
                fontFamily: 'Roboto',
                padding: '10px 16px',
                width: '100%',
              }}
            >
              Standard
            </Typography>
            <Switch
              color="primary"
              checked={officerUpdates?.active}
              onChange={(e) => onChangeStandardCheckbox(e.target.checked)}
            />
          </PageTransition>
        </Box>
        <DnDArea
          selectedItems={pages}
          setSelectedItems={(_pages) => {
            onChangePages(_pages);
            setPages(_pages);
          }}
          renderer={renderer}
        />
        {version === FORM_TYPE_VERSION_2 && (
          <AddMore
            dataTestId="forms-case-updates-button-add-new-section"
            text="Add new section"
            marginLeft="5px"
            onClick={handleOnClickNewPage}
          />
        )}
      </Box>
    </>
  );
}

OfficerUpdateBar.propTypes = {
  id: PropTypes.string,
  officerUpdates: PropTypes.object,
  onChangeStandardCheckbox: PropTypes.func,
  version: PropTypes.string,
  selected: PropTypes.object,
  setSelected: PropTypes.func,
  defaultPages: PropTypes.array,
  refActionModal: PropTypes.object,
  setOpen: PropTypes.func,
  onChangePages: PropTypes.func.isRequired,
  onMoveComponent: PropTypes.func,
};
