import React, { useState } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import WrapperComponent from './WrapperComponent';
import Input from '../../components/Input';
import useInput from '../../hooks/useInput';
import Settings from '../utils/Settings';
import { ReactComponent as CalendarIcon } from '../../assets/icons/today_black_24dp.svg';
import PreviewLabel from '../utils/Label';
import HideAnswer from '../../components/HideAnswer';

const useStyles = makeStyles(() => ({
  title: {
    fontSize: 14,
    color: '#000000DE',
    marginLeft: 10,
  },
  label: {
    fontSize: 16,
    color: '#000000DE',
    fontWeight: '500',
  },
  labelAnswer: {
    fontSize: 16,
    color: '#6D7385',
    fontWeight: '500',
    paddingLeft: '10px',
  },
}));

export default function DateOfBirth({
  questionBankComponent,
  id,
  componentId,
  onUpdateJson,
  onDeleteComponent,
  path,
  data,
  componentIndex,
  duplicateComponent,
  refActionModal,
  setModal,
  shouldRenderToggle,
  isSubComponent,
}) {
  const classes = useStyles();

  const [checkQuestion, setCheckQuestion] = useState([
    !!data?.description,
    !!data.requiredMessage,
    // false,
  ]);

  const visible = useInput(data?.visible, false, false, true);
  const question = useInput(data?.question || '');
  const required = useInput(!!data?.required, false, false, true);
  const descriptionQuestion = useInput(data?.description || '');
  const requiredQuestionMessage = useInput(data?.requiredMessage || '');
  const [loading, setLoading] = React.useState(false);

  const handleSaveComponent = () => {
    const dataJson = {
      ...(descriptionQuestion.value ? { description: descriptionQuestion.value } : {}),
      ...(requiredQuestionMessage.value ? { requiredMessage: requiredQuestionMessage.value } : {}),
      ...(data.dataReportLink ? { dataReportLink: data.dataReportLink } : {}),
      question: question.value,
      visible: visible.value,
      required: required.value,
    };

    onUpdateJson(dataJson, 'dateOfBirth');
  };
  const saveOnTimeOut = () => {
    setLoading(true);
    setTimeout(() => {
      handleSaveComponent();
      setLoading(false);
    }, 500);
  };
  const renderSettings = () => (
    <Settings
      // questions={['description', 'required', 'date validation']}
      componentId={componentId}
      componentType="date"
      defaultDataReportLink={data.dataReportLink}
      onSelectDataReportLink={
        !isSubComponent ? (dataReportLink) => onUpdateJson({ ...data, dataReportLink }) : undefined
      }
      questions={['description', 'required']}
      checkQuestion={checkQuestion}
      descriptionQuestion={descriptionQuestion}
      requiredQuestion={requiredQuestionMessage}
      setCheckQuestion={setCheckQuestion}
      saveOnTimeOut={saveOnTimeOut}
    />
  );

  const [showAnswerBool, setShowAnswerBool] = React.useState(true);
  const handleShowAnswerBool = (state) => {
    setShowAnswerBool((state) => !state);
    // console.log(hideShowMessage)
  };

  const renderPreview = () => (
    <Box>
      <PreviewLabel question={question.value} qNumber={componentIndex + 1} required={required.value} />
      <HideAnswer onClick={handleShowAnswerBool} marginLeft="35px" />
      <Box marginLeft="42px">
        {showAnswerBool && (
          <Box display="flex">
            {/* <CalendarIcon fill="#6D7385" /> */}
            <Typography className={classes.labelAnswer}>MM/DD/YYYY</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );

  return (
    <Box marginBottom="20px">
      <WrapperComponent
        allowDnD={false}
        onBlur={(!questionBankComponent && saveOnTimeOut) || function () {}}
        renderSettings={renderSettings}
        componentIndex={componentIndex}
        duplicateComponent={duplicateComponent}
        isQuestionBankComponent={questionBankComponent}
        isDemographicComponent
        required={required}
        renderPreview={renderPreview}
        onDeleteComponent={onDeleteComponent}
        id={id}
        componentId={componentId}
        visible={visible}
        path={path}
        loading={loading}
        dataSaved={data?.question}
        refActionModal={refActionModal}
        setModal={setModal}
        shouldRenderToggle={shouldRenderToggle}
      >
        <Box display="flex" alignItems="center">
          <CalendarIcon fill="#6D7385" />
          <Typography className={classes.title}>Date Of Birth</Typography>
        </Box>
        <Box display="flex" alignItems="center" marginTop="15px">
          <Typography className={classes.label}>Q{componentIndex + 1}</Typography>
          <ArrowRightAltIcon style={{ fontSize: 14, marginRight: 10 }} />
          <Input
            placeholder="Enter name question or label"
            value={question.value}
            onChange={(e) => {
              question.onChange(e);
              saveOnTimeOut();
            }}
          />
        </Box>
      </WrapperComponent>
    </Box>
  );
}
