import { Box, Checkbox, Typography, Divider } from '@material-ui/core';
import React, { useEffect } from 'react';
import styled from 'styled-components';
// import Input from '../../components/Input';
import { CustomSwitch } from '../../../formEngine/components/WrapperComponent';
import PreviewLabel from '../../../formEngine/utils/Label';
import SelectableButton from './SelectableButton';
import Date from './Date';
import Number from './Number';
import Condition from './Condition';
import Text from './Text';
import DnDArea from '../../../components/DnDArea';
import DnDItem from '../../../components/DnDItem';
import { onChangeDnDValue, onRemoveDnDItem } from '../../../formEngine/utils/utls';
import { getCaseNumberPreview, getFormTypeOptions } from '../../../services/unarmed';

const CaseNumberItemSpacing = ({ title, children }) => (
  <Box display="flex" alignItems="center" marginBottom={1}>
    <Typography variant="body1" style={{ width: 160 }}>
      {title}
    </Typography>
    {children}
  </Box>
);

export default function CaseNumberSettings({
  responseValidation,
  responseTypeValidation,
  caseNumber,
  setCaseNumber,
  id,
}) {
  const [logicItems, setLogicItems] = React.useState([]);
  const [logicOptions, setLogicOptions] = React.useState(null);
  const [preview, setPreview] = React.useState('');

  useEffect(() => {
    const onGetFormTypeOptons = async () => {
      try {
        const { data } = await getFormTypeOptions(id);
        setLogicOptions(data?.logic?.tagTypes);
      } catch (error) {
        console.log(error);
      }
    };
    try {
      onGetFormTypeOptons(
        logicItems.map((item) => {
          if (item?.tagType === 'condition') {
            return {
              tagType: item?.tagType,
              config: {
                rules: item?.conditions,
              },
            };
          }
          if (item?.tagType === 'number') {
            return {
              tagType: item?.tagType,
              config: {
                consecutive: item?.value === 'consecutive_number',
                length: item?.length,
              },
            };
          }
          if (item?.tagType === 'date') {
            return {
              tagType: item?.tagType,
              config: {
                format: item?.format,
                separator: item?.separator,
              },
            };
          }

          return {
            tagType: item?.tagType,
            config: {
              value: item?.value,
            },
          };
        })
      );
    } catch (error) {
      console.log(error);
    }
  }, [id, logicItems]);

  useEffect(() => {
    setCaseNumber((prevState) => {
      const caseNumerCopy = { ...prevState };

      caseNumerCopy.automate.logic = logicItems;

      const onGetCaseNumberPreview = async () => {
        try {
          const { data } = await getCaseNumberPreview(
            caseNumerCopy?.automate?.logic.map((item) => {
              if (item?.tagType === 'condition') {
                return {
                  tagType: item?.tagType,
                  config: {
                    rules: item?.conditions,
                  },
                };
              }
              if (item?.tagType === 'number') {
                return {
                  tagType: item?.tagType,
                  config: {
                    consecutive: item?.value === 'consecutive_number',
                    length: item?.length,
                  },
                };
              }
              if (item?.tagType === 'date') {
                return {
                  tagType: item?.tagType,
                  config: {
                    format: item?.format,
                    separator: item?.separator,
                  },
                };
              }

              return {
                tagType: item?.tagType,
                config: {
                  value: item?.value,
                },
              };
            })
          );
          setPreview(data?.preview);
        } catch (error) {
          console.log(error);
        }
      };
      if (caseNumerCopy?.automate?.enabled) {
        onGetCaseNumberPreview();
      }
      return caseNumerCopy;
    });
  }, [logicItems, setCaseNumber]);

  useEffect(() => {
    if (caseNumber) {
      setLogicItems(caseNumber?.automate?.logic);
    }
  }, [caseNumber]);

  const handleOnChangeQuestion = (e, prop) => {
    setCaseNumber((prevState) => {
      const checkQuestionCopy = { ...prevState };

      checkQuestionCopy.automate[prop] = e.target.checked;

      return checkQuestionCopy;
    });
  };

  const renderLogicItem = (logicType, index, logic) => {
    switch (logicType) {
      case 'date':
        return (
          <Date
            onChange={onChangeDnDValue}
            setLogicItems={setLogicItems}
            index={index}
            onRemoveItem={() => onRemoveDnDItem(logicItems, setLogicItems, index)}
            logic={logic}
            options={logicOptions?.date}
          />
        );
      case 'number':
        return (
          <Number
            onChange={onChangeDnDValue}
            setLogicItems={setLogicItems}
            index={index}
            onRemoveItem={() => onRemoveDnDItem(logicItems, setLogicItems, index)}
            logic={logic}
            options={logicOptions?.number}
          />
        );
      case 'condition':
        return (
          <Condition
            onChange={onChangeDnDValue}
            setLogicItems={setLogicItems}
            index={index}
            onRemoveItem={() => onRemoveDnDItem(logicItems, setLogicItems, index)}
            logic={logic}
            options={logicOptions?.condition?.conditionsFields}
          />
        );
      case 'text':
        return (
          <Text
            onChange={onChangeDnDValue}
            setLogicItems={setLogicItems}
            index={index}
            onRemoveItem={() => onRemoveDnDItem(logicItems, setLogicItems, index)}
            logic={logic}
            options={logicOptions?.text}
          />
        );
      default:
        return null;
    }
  };

  const handleAddLogicItem = (item) => {
    setLogicItems((prevState) => [...prevState, item]);
  };

  const renderer = (moveItem) =>
    logicItems?.map((logic, index) => (
      <DnDItem key={index} moveItem={moveItem} index={index}>
        {renderLogicItem(logic.tagType, index, logic)}
      </DnDItem>
    ));

  return (
    <Box>
      <PreviewLabel question="Case number" qNumber={1} />
      <Box marginLeft={4}>
        <CaseNumberItemSpacing title="Automate">
          <CustomSwitch
            checked={caseNumber?.automate?.enabled}
            onChange={(e) => handleOnChangeQuestion(e, 'enabled')}
          />
        </CaseNumberItemSpacing>
      </Box>
      {caseNumber?.automate?.enabled && (
        <>
          <Typography variant="body1" style={{ fontWeight: 'bold', marginTop: 10 }}>
            General
          </Typography>
          <Box marginLeft={4}>
            <CaseNumberItemSpacing title="Manual Override">
              <CustomSwitch
                checked={caseNumber?.automate?.manualOverride}
                onChange={(e) => handleOnChangeQuestion(e, 'manualOverride')}
              />
            </CaseNumberItemSpacing>
            <CaseNumberItemSpacing title="Use Tracking Number">
              <CustomSwitch
                checked={caseNumber?.automate?.useTrackingNumber}
                onChange={(e) => handleOnChangeQuestion(e, 'useTrackingNumber')}
              />
            </CaseNumberItemSpacing>
          </Box>
          {!caseNumber?.automate?.useTrackingNumber && (
            <>
              <Typography variant="body1" style={{ fontWeight: 'bold', marginTop: 30, marginBottom: 10 }}>
                Logic
              </Typography>

              <DnDArea selectedItems={logicItems} setSelectedItems={setLogicItems} renderer={renderer} />
              <Box marginTop={1} marginBottom={1} style={{ gap: 10 }}>
                <SelectableButton text="ADD TAG" onClick={handleAddLogicItem} options={logicOptions} />
              </Box>
              <Divider light />
              <Typography variant="body1" style={{ marginTop: 30 }}>
                <strong>Preview e.g.</strong> {preview}
              </Typography>
            </>
          )}
        </>
      )}
    </Box>
  );
}

export const CustomCheckbox = styled(Checkbox)`
  &.MuiCheckbox-colorPrimary.Mui-checked {
    color: #2e66fe;
  }
`;
