import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  Tooltip,
  Typography,
  Popover,
  List,
  ListItem,
  Divider,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import moment from 'moment';
import EnhancedTable from '../../components/Table';
import { getfiltersConfigById, getViewByIdv } from '../../services/unarmed';
import AddMore from '../../components/AddMore';
import { TYPE_USER_TABS } from '../../utils/constants';
import OfficerFilter from './OfficersFilter';
import PageButtons from '../../components/PageButtons';

export const ViewTabOfficers = ({
  onOfficersFetched,
  classes,
  badge,
  officers,
  handleClick,
  openPopover,
  anchorEl,
  handleClose,
  searchTerm,
  setSearchTerm,
  showColumns,
  handleToggleColumn,
  formatHeadersText,
  visibleColumns,
  hideColumns,
  openFilter,
  setOpenFilter,
  name,
  setPage,
  flaggedOnly,
  page,
  rowsPerPage,
  sort,
  sortBy,
  setIsFiltering,
  selected,
  setOpen,
  fetchFiltersConfig,
  setSort,
  filtersLoaded,
  setFiltersLoaded,
  setSortBy,
  tabSelected,
  setVisibleColumns,
  setOpenDelete,
  onEditMultipleOfficers,
  setOfficers,
  onClearAllFilters,
  renderRow,
  getVisibleColumns,
  setChecked,
  checked,
  loading,
  setSelected,
  handleChangeRowsPerPage,
  setLoading,
  getOfficers,
  isFiltering,
}) => {
  const [view, setView] = useState();
  const [filtersConfig, setfiltersConfig] = useState({});
  const [filtersApplied, setFiltersApplied] = useState({});
  const [filterCount, setFilterCount] = useState(0);

  const handleOnSetFiltersConfig = () => {
    const newFiltersApplied = {};
    if (filtersConfig) {
      const configCopy = { ...filtersConfig };

      delete configCopy.sortOrder;
      delete configCopy.sortBy;
      delete configCopy.page;
      delete configCopy.limit;
      delete configCopy.isFiltering;
      Object.keys(configCopy).forEach((key) => {
        const value = configCopy[key];
        let isApplied = false;

        if (value === null || !value || value === '0') {
          isApplied = false;
        } else if (Array.isArray(value)) {
          isApplied = value.length > 0;
        } else if (typeof value === 'boolean') {
          isApplied = value === true;
        } else {
          isApplied = true;
        }

        newFiltersApplied[key] = isApplied;
      });

      const newCount = Object.values(newFiltersApplied).filter((item) => item).length;
      setFilterCount(newCount);
      setFiltersApplied(newFiltersApplied);
    }
  };

  const onGetOfficers = async () => {
    try {
      setLoading(true);
      const { data, headers } = await getOfficers({
        includeTotalCases: true,
        page,
        limit: rowsPerPage,
        sort,
        sortBy,
        isFiltering,
        flaggedOnly: flaggedOnly.value,
        name: name.value,
        badge: badge.value,
      });
      setOfficers({ data, headers });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const onGetOfficers1 = async ({ sortv, sortByv, isFilteringv, flaggedOnlyv, namev, badgev }) => {
    try {
      setLoading(true);
      const { data, headers } = await getOfficers({
        includeTotalCases: true,
        page,
        limit: rowsPerPage,
        sort: sortv,
        sortBy: sortByv,
        isFiltering: isFilteringv,
        flaggedOnly: flaggedOnlyv,
        name: namev,
        badge: badgev,
      });

      setOfficers({ data, headers });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    handleOnSetFiltersConfig();
    if (filtersConfig) {
      badge.setValue(filtersConfig?.badgeNumber);
      name.setValue(filtersConfig?.name);
      flaggedOnly.setValue(filtersConfig?.flaggedOnly || false);
      setSort(sort || filtersConfig?.sortOrder);
      setSortBy(sortBy || filtersConfig?.sortBy);

      setIsFiltering(true);
      setFiltersLoaded(true);
      onGetOfficers1({
        sortv: sort || filtersConfig?.sortOrder,
        sortByv: sortBy || filtersConfig?.sortBy,
        isFilteringv: true,
        flaggedOnlyv: filtersConfig?.flaggedOnly,
        namev: filtersConfig?.name,
        badgev: filtersConfig?.badgeNumber,
      });
    } else if (filtersConfig === null) {
      setIsFiltering(false);
      setFiltersLoaded(true);
      onGetOfficers();
    }
  }, [filtersConfig, page, sort, sortBy]);

  useEffect(() => {
    const fetchData = async () => {
      if (tabSelected?.displayObjectId && tabSelected?.displayObjectType === TYPE_USER_TABS.VIEW) {
        const responseView = await getViewByIdv(tabSelected?.displayObjectId);
        if (responseView?.data?.view[0]) {
          setView(responseView.data.view[0]);
          setVisibleColumns(responseView.data.view[0].columns);
        }
      }
    };

    fetchData();
  }, [tabSelected]);

  useEffect(() => {
    const fetchData = async () => {
      if (view) {
        const responsefiltersConfig = await getfiltersConfigById(view?.filtersConfigId);
        setfiltersConfig(responsefiltersConfig?.data?.filterConfig[0]?.config);
      }
    };

    fetchData();
  }, [view]);

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2} marginTop="35px">
        <Box display="flex" alignItems="center">
          <Typography className={classes.title} variant="subtitle2" id="tableTitle" component="div">
            All Officers ({officers?.headers?.['x-pagination-totalitems']})
          </Typography>

          <AddMore text="" marginLeft="0" marginTop="0" onClick={handleClick} />
          <Popover
            open={openPopover}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Box p={2} width="240px" height="400px" style={{ overflow: 'auto' }}>
              <Box display="flex" justifyContent="center">
                <Typography variant="h7" style={{ marginBottom: '2px', fontWeight: 'bold' }}>
                  Show / Hide Columns
                </Typography>
              </Box>

              <Divider style={{ marginTop: '2px', marginBottom: '2px' }} />

              <TextField
                placeholder="Search columns"
                variant="standard"
                fullWidth
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon style={{ fontSize: '12px' }} />
                    </InputAdornment>
                  ),
                  disableUnderline: true,
                  style: {
                    fontSize: '12px',
                    height: '28px',
                  },
                }}
                style={{
                  padding: '5px 0',
                  backgroundColor: 'transparent',
                  fontSize: '12px',
                  height: '28px',
                }}
              />

              <Divider style={{ marginTop: '7px', marginBottom: '5px' }} />

              <Box display="flex" justifyContent="center">
                <Typography variant="caption" style={{ marginBottom: '5px', fontSize: '14px', fontWeight: 'bold' }}>
                  Visible
                </Typography>
              </Box>

              <List dense style={{ padding: 0 }}>
                {showColumns.map((column) => (
                  <ListItem
                    key={column}
                    button
                    onClick={() => handleToggleColumn(view._id, column)}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: '.1px',
                    }}
                  >
                    <Tooltip title={formatHeadersText(column)}>
                      <Typography variant="caption" style={{ lineHeight: '1.2' }}>
                        {formatHeadersText(column)}
                      </Typography>
                    </Tooltip>
                    <Checkbox
                      checked={visibleColumns[column]}
                      color="primary"
                      style={{ transform: 'scale(0.7)', paddingTop: '2px', paddingBottom: '2px' }}
                    />
                  </ListItem>
                ))}
              </List>

              <Divider style={{ margin: '10px 0' }} />

              <Box display="flex" justifyContent="center">
                <Typography
                  variant="caption"
                  style={{ marginBottom: '5px', marginTop: '5px', fontSize: '14px', fontWeight: 'bold' }}
                >
                  Hidden
                </Typography>
              </Box>

              <List dense style={{ padding: 0 }}>
                {hideColumns.map((column) => (
                  <ListItem
                    key={column}
                    button
                    onClick={() => handleToggleColumn(view._id, column)}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: '.1px',
                    }}
                  >
                    <Tooltip title={formatHeadersText(column)}>
                      <Typography variant="caption" style={{ lineHeight: '1.2' }}>
                        {formatHeadersText(column)}
                      </Typography>
                    </Tooltip>
                    <Checkbox
                      checked={visibleColumns[column]}
                      color="primary"
                      style={{ transform: 'scale(0.7)', paddingTop: '2px', paddingBottom: '2px' }}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          </Popover>
        </Box>

        <PageButtons
          filterCount={filterCount}
          text=""
          length={officers?.headers?.['x-pagination-totalitems']}
          openFilter={openFilter}
          setOpenFilter={setOpenFilter}
          onClickDelete={() => setOpenDelete(true)}
          disabledDelete={selected.length === 0}
          onClickFlag={onEditMultipleOfficers}
          disabledFlag={selected.length === 0}
          onClickDownload={() => setOpen(true)}
          disabledDownload={false}
          downloadButtonText="Create Officer"
          filterButtonDataTestId="officers-button-open-filters"
          deleteButtonDataTestId="officers-button-delete-officers"
          downloadButtonDataTestId="officers-button-create-officer"
        />
      </Box>

      {openFilter && (
        <OfficerFilter
          filtersConfig={filtersConfig}
          filtersApplied={filtersApplied}
          fetchFiltersConfig={fetchFiltersConfig}
          badge={badge}
          setPage={setPage}
          page={page}
          rowsPerPage={rowsPerPage}
          name={name}
          setOfficers={setOfficers}
          sort={sort}
          sortBy={sortBy}
          setIsFiltering={setIsFiltering}
          onClick={() => setOpenFilter(false)}
          onClearFilters={onClearAllFilters}
          flaggedOnly={flaggedOnly}
          setfiltersConfig={setfiltersConfig}
          filterConfigId={view?.filtersConfigId}
        />
      )}

      <Box display="flex" justifyContent="space-between" marginBottom="0">
        <EnhancedTable
          sort={sort}
          setSort={setSort}
          columns={getVisibleColumns()}
          rows={officers?.data}
          showCheckBox
          setSortBy={setSortBy}
          rowRender={renderRow}
          setChecked={setChecked}
          checked={checked}
          loading={loading}
          page={page}
          tab="officer"
          setPage={setPage}
          selected={selected}
          setSelected={setSelected}
          count={officers?.headers?.['x-pagination-totalitems']}
          rowsPerPage={rowsPerPage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Box>
    </>
  );
};
