import React, { useCallback, useEffect, useState } from 'react';
import {
  Backdrop,
  Box,
  Checkbox,
  CircularProgress,
  Fade,
  Modal,
  Tooltip,
  Typography,
  Popover,
  List,
  ListItem,
  Divider,
  TextField,
  InputAdornment,
  Button,
  Tabs,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import moment from 'moment';
import { toast } from 'react-toastify';
import EnhancedTable from '../../components/Table';
import TabPanel from '../../components/TabPanel';
import { TabComp } from '../CaseDetails/CaseDetails';
import InviteUser from './InviteUser';
import UserFilter from './UsersFilter';
import FilterButton from '../../components/FilterButton';
import DashButton from '../../components/DashButton';
import { getUsers, getfiltersConfigById, getViewByIdv, getUsersInvitations, getUsage } from '../../services/unarmed';
import AddMore from '../../components/AddMore';
import { TYPE_USER_TABS } from '../../utils/constants';

export const ViewTabUsers = ({
  a11yProps,
  anchorEl,
  checked,
  classes,
  email,
  fetchFiltersConfig,
  flagged,
  flaggedOnly,
  formatHeadersText,
  from,
  getVisibleColumns,
  handleChange,
  handleChangeRowsPerPage,
  handleClick,
  handleClose,
  handleToggleColumn,
  hideColumns,
  loading,
  loadingDeleteUser,
  name,
  onClearFilters,
  onDeleteUsers,
  onEditMultipleUsers,
  onUpdateUsers,
  open,
  openDeleteUser,
  openFilter,
  openPopover,
  openInviteUser,
  organization,
  page,
  phone,
  renderRow,
  role,
  rowsPerPage,
  searchTerm,
  selected,
  setChecked,
  setEmail,
  setFiltersLoaded,
  setFrom,
  setIsFiltering,
  setLoading,
  setOpenInviteUser,
  setOpenDeleteUser,
  setOpenFilter,
  setPage,
  setPhone,
  setRole,
  setSearchTerm,
  setSelected,
  setShowUpgradeModal,
  setSort,
  setSortBy,
  setTo,
  setUsage,
  setUsername,
  setUsers,
  setValue,
  setVisibleColumns,
  showColumns,
  sort,
  sortBy,
  tabSelected,
  to,
  usage,
  users,
  value,
  visibleColumns,
  stateUsage
}) => {
  const [view, setView] = useState();
  const [filtersConfig, setfiltersConfig] = useState({});
  const [filtersApplied, setFiltersApplied] = useState({});
  const [filterCount, setFilterCount] = useState(0);

  const onGetUsers = async () => {
    try {
      setLoading(true);
      let res = null;
      if (value === 0) {
        res = await getUsers({
          page: page || 1,
          limit: rowsPerPage,
          sortBy: sortBy || 'createdAt',
          sort: sort || 'desc',
          from,
          to,
          name,
          role,
          email,
          phone,
          flaggedOnly: flagged.value,
          isFiltering: true,
        });
      }

      if (value === 1) {
        res = await getUsersInvitations(
          page || 1,
          rowsPerPage,
          sortBy || 'createdAt',
          sort || 'desc',
          from,
          to,
          name,
          role,
          email,
          flagged.value,
          phone,
          true
        );
      }

      const data = await getUsage(organization?._id);
      setUsage(data);

      setUsers({ data: res.data, headers: res.headers });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  const onGetUsers1 = async ({ sortByV, sortV, fromV, toV, usernameV, roleV, emailV, phoneV, flaggedV }) => {
    try {
      setLoading(true);
      let res = null;
      if (value === 0) {
        res = await getUsers({
          page: page || 1,
          limit: rowsPerPage,
          sortBy: sortBy || 'createdAt',
          sort: sortV || 'desc',
          from: fromV,
          to: toV,
          name: usernameV,
          role: roleV,
          email: emailV,
          phone: phoneV,
          flagged: flaggedV, // flagged.value
          isFiltering: true,
        });
      }

      if (value === 1) {
        res = await getUsersInvitations(
          page || 1,
          rowsPerPage,
          sortBy || 'createdAt',
          sortV || 'desc',
          from,
          to,
          name,
          role,
          email,
          flaggedV,
          phone,
          true
        );
      }

      const data = await getUsage(organization?._id);
      setUsage(data);

      setUsers({ data: res.data, headers: res.headers });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  const handleOnSetFiltersConfig = () => {
    const newFiltersApplied = {};
    if (filtersConfig) {
      const configCopy = { ...filtersConfig };

      delete configCopy.sortOrder;
      delete configCopy.sortBy;
      delete configCopy.page;
      delete configCopy.limit;
      delete configCopy.isFiltering;
      Object.keys(configCopy).forEach((key) => {
        const value1 = configCopy[key];
        let isApplied = false;

        if (value1 === null || !value1 || value1 === '0') {
          isApplied = false;
        } else if (Array.isArray(value1)) {
          isApplied = value1.length > 0;
        } else if (typeof value1 === 'boolean') {
          isApplied = value1 === true;
        } else {
          isApplied = true;
        }

        newFiltersApplied[key] = isApplied;
      });

      const newCount = Object.values(newFiltersApplied).filter((item) => item).length;
      setFilterCount(newCount);
      setFiltersApplied(newFiltersApplied);
    }
  };

  useEffect(() => {
    handleOnSetFiltersConfig();
    if (filtersConfig) {
      setEmail(filtersConfig?.email);
      setUsername(filtersConfig?.name);
      setPhone(filtersConfig?.phoneNumber);
      setRole(filtersConfig?.role);
      setTo(filtersConfig?.to || null);
      setFrom(filtersConfig?.from || null);
      flaggedOnly.setValue(filtersConfig?.flaggedOnly);

      setFiltersLoaded(true);
      onGetUsers1({
        fromV: filtersConfig?.from,
        toV: filtersConfig?.to,
        sortByV: sortBy,
        sortV: sort,
        usernameV: filtersConfig?.name,
        roleV: filtersConfig?.role,
        emailV: filtersConfig?.email,
        phoneV: filtersConfig?.phoneNumber,
        flaggedV: filtersConfig?.flaggedOnly,
      });
    } else if (filtersConfig === null) {
      setFiltersLoaded(true);
      onGetUsers();
    }
  }, [filtersConfig, page, sort, sortBy, stateUsage]);

  useEffect(() => {
    const fetchData = async () => {
      if (tabSelected?.displayObjectId && tabSelected?.displayObjectType === TYPE_USER_TABS.VIEW) {
        const responseView = await getViewByIdv(tabSelected?.displayObjectId);
        if (responseView?.data?.view[0]) {
          setView(responseView.data.view[0]);
          setVisibleColumns(responseView.data.view[0].columns);
        }
      }
    };

    fetchData();
  }, [tabSelected]);

  useEffect(() => {
    const fetchData = async () => {
      if (view) {
        const responsefiltersConfig = await getfiltersConfigById(view?.filtersConfigId);
        setfiltersConfig(responsefiltersConfig?.data?.filterConfig[0]?.config);
      }
    };

    fetchData();
  }, [view]);

  useEffect(() => {
    const fetchData = async () => {
      if (tabSelected?.displayObjectId && tabSelected?.displayObjectType === TYPE_USER_TABS.VIEW) {
        const responseView = await getViewByIdv(tabSelected?.displayObjectId);
        if (responseView?.data?.view[0]) {
          setView(responseView.data.view[0]);
          setVisibleColumns(responseView.data.view[0].columns);
        }
      }
    };

    fetchData();
  }, [tabSelected]);

  return (
    <>
      <Box alignContent="center" justifyContent="flex-end" display="flex" paddingTop="30px">
        <Box display="flex" alignItems="center" justifyContent="flex-start" style={{ width: '100%' }}>
          <Typography style={{ fontSize: 14, color: '#747474' }}>
            Available invitations:{' '}
            <strong style={{ fontSize: 14, color: '#333' }}>
              {organization?.features?.limits?.users - (usage?.activeUsers + usage?.pendingUsers) || 0}
            </strong>
          </Typography>

          <AddMore text="" marginLeft="5px" marginTop="0" onClick={handleClick} />
        </Box>

        <Popover
          open={openPopover}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Box p={2} width="240px" height="400px" style={{ overflow: 'auto' }}>
            <Box display="flex" justifyContent="center">
              <Typography variant="h7" style={{ marginBottom: '2px', fontWeight: 'bold' }}>
                Show / Hide Columns
              </Typography>
            </Box>

            <Divider style={{ marginTop: '2px', marginBottom: '2px' }} />

            <TextField
              placeholder="Search columns"
              variant="standard"
              fullWidth
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ fontSize: '12px' }} />
                  </InputAdornment>
                ),
                disableUnderline: true,
                style: {
                  fontSize: '12px',
                  height: '28px',
                },
              }}
              style={{
                padding: '5px 0',
                backgroundColor: 'transparent',
                fontSize: '12px',
                height: '28px',
              }}
            />

            <Divider style={{ marginTop: '7px', marginBottom: '5px' }} />

            <Box display="flex" justifyContent="center">
              <Typography variant="caption" style={{ marginBottom: '5px', fontSize: '14px', fontWeight: 'bold' }}>
                Visible
              </Typography>
            </Box>

            <List dense style={{ padding: 0 }}>
              {showColumns.map((column) => (
                <ListItem
                  key={column}
                  button
                  onClick={() => handleToggleColumn(view._id, column)}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '.1px',
                  }}
                >
                  <Tooltip title={formatHeadersText(column)}>
                    <Typography variant="caption" style={{ lineHeight: '1.2' }}>
                      {formatHeadersText(column)}
                    </Typography>
                  </Tooltip>
                  <Checkbox
                    checked={visibleColumns[column]}
                    color="primary"
                    style={{ transform: 'scale(0.7)', paddingTop: '2px', paddingBottom: '2px' }}
                  />
                </ListItem>
              ))}
            </List>

            <Divider style={{ margin: '10px 0' }} />

            <Box display="flex" justifyContent="center">
              <Typography
                variant="caption"
                style={{ marginBottom: '5px', marginTop: '5px', fontSize: '14px', fontWeight: 'bold' }}
              >
                Hidden
              </Typography>
            </Box>

            <List dense style={{ padding: 0 }}>
              {hideColumns.map((column) => (
                <ListItem
                  key={column}
                  button
                  onClick={() => handleToggleColumn(view._id, column)}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '.1px',
                  }}
                >
                  <Tooltip title={formatHeadersText(column)}>
                    <Typography variant="caption" style={{ lineHeight: '1.2' }}>
                      {formatHeadersText(column)}
                    </Typography>
                  </Tooltip>
                  <Checkbox
                    checked={visibleColumns[column]}
                    color="primary"
                    style={{ transform: 'scale(0.7)', paddingTop: '2px', paddingBottom: '2px' }}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        </Popover>
      </Box>

      <Box alignContent="center" justifyContent="space-between" display="flex" paddingTop="10px">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          indicatorColor="primary"
          textColor="primary"
        >
          <TabComp label={`Active (${usage?.activeUsers || 0})`} {...a11yProps(0)} />
          <TabComp label={`Pending (${usage?.pendingUsers || 0})`} {...a11yProps(1)} />
        </Tabs>
        <Box alignItems="center" display="flex">
          <FilterButton
            count={filterCount}
            openFilter={openFilter}
            onClick={() => setOpenFilter(!openFilter)}
            marginRight="10px"
          />
          <DashButton
            dataTestId="allegations-button-download"
            onClick={onEditMultipleUsers}
            disabled={selected.length === 0}
            marginRight="10px"
          >
            Flag
          </DashButton>
          <DashButton onClick={() => setOpenDeleteUser(true)} disabled={selected.length === 0} marginRight="10px">
            Delete
          </DashButton>
          <DashButton
            onClick={() => {
              if (organization?.features?.limits?.users - (usage?.activeUsers + usage?.pendingUsers) === 0) {
                setShowUpgradeModal(true);
              } else {
                setOpenInviteUser(true);
              }
            }}
            marginRight="10px"
          >
            Invite User
          </DashButton>
        </Box>
      </Box>

      {openFilter && (
        <UserFilter
          setOpen={setOpenFilter}
          filterConfigId={view?.filtersConfigId}
          email={email}
          phoneNumber={phone}
          role={role}
          sortOrder={sort}
          page={page}
          flagged={flagged}
          setUsername={setUsername}
          setEmail={setEmail}
          setPhone={setPhone}
          setRole={setRole}
          setfiltersConfig={setfiltersConfig}
          filtersConfig={filtersConfig}
          filtersApplied={filtersApplied}
          fetchFiltersConfig={fetchFiltersConfig}
          name={name}
          from={from}
          to={to}
          setPage={setPage}
          flaggedOnly={flaggedOnly}
          setFrom={setFrom}
          setTo={setTo}
          setUsers={setUsers}
          rowsPerPage={rowsPerPage}
          sort={sort}
          value={value}
          sortBy={sortBy}
          onClearFilters={onClearFilters}
          setIsFiltering={setIsFiltering}
        />
      )}

      <TabPanel value={value} index={0} padding="0">
        <Box display="flex" justifyContent="space-between" height="100%" marginBottom="0">
          <EnhancedTable
            sort={sort}
            setSort={setSort}
            columns={getVisibleColumns()}
            rows={users.data}
            showCheckBox
            setSortBy={setSortBy}
            rowRender={renderRow()}
            setChecked={setChecked}
            checked={checked}
            loading={loading}
            page={page}
            setPage={setPage}
            selected={selected}
            setSelected={setSelected}
            count={users?.headers?.['x-pagination-totalitems']}
            rowsPerPage={rowsPerPage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Box>
      </TabPanel>

      <TabPanel value={value} index={1} padding="0">
        <Box display="flex" justifyContent="space-between" height="100%" marginBottom="0">
          <EnhancedTable
            sort={sort}
            setSort={setSort}
            columns={getVisibleColumns()}
            rows={users.data}
            tab={value}
            showCheckBox
            setSortBy={setSortBy}
            rowRender={renderRow(false)}
            setChecked={setChecked}
            checked={checked}
            loading={loading}
            page={page}
            setPage={setPage}
            selected={selected}
            setSelected={setSelected}
            count={users?.headers?.['x-pagination-totalitems']}
            rowsPerPage={rowsPerPage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Box>
      </TabPanel>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openInviteUser}
        onClose={() => setOpenInviteUser(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openInviteUser}>
          <div className={classes.paper}>
            <InviteUser
              setOpen={setOpenInviteUser}
              onUpdateUsers={onUpdateUsers}
              tab={value}
              setTab={setValue}
              setSort={setSort}
              limitInvites={organization?.features?.limits?.users}
              leftInvites={organization?.features?.limits?.users - (usage?.activeUsers + usage?.pendingUsers) || 0}
            />
          </div>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title1"
        aria-describedby="transition-modal-description1"
        className={classes.modal}
        open={openDeleteUser}
        onClose={() => setOpenDeleteUser(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openDeleteUser}>
          <div className={classes.paper}>
            <Typography style={{ fontSize: 20, fontWeight: '500' }}>Are you sure you want to delete?</Typography>
            <Typography variant="body2">This action can’t be undone!</Typography>
            <Box display="flex" alignItems="center" justifyContent="flex-end" marginTop="20px">
              <Button variant="outlined" className={classes.buttonOutlined} onClick={() => setOpenDeleteUser(false)}>
                CANCEL
              </Button>
              <Button variant="outlined" className={classes.buttonOutlined} onClick={onDeleteUsers}>
                {loadingDeleteUser ? <CircularProgress color="#fff" size="25px" /> : 'DELETE'}
              </Button>
            </Box>
          </div>
        </Fade>
      </Modal>
    </>
  );
};
