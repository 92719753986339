import { Tabs } from '@material-ui/core';
import React from 'react';
import { TabComp } from '../../CaseDetails/CaseDetails';

const tabsArray = [
  { label: 'Actions', value: 0 },
  { label: 'Recommendations', value: 1 },
  { label: 'Contacts', value: 2 },
  { label: 'Notes', value: 3 },
  { label: 'Attachments', value: 4 },
  { label: 'History', value: 5 },
];

export default function HeaderTabs({ tabValue, handleChangeTab, a11yProps, tabs = tabsArray }) {
  return (
    <div>
      <Tabs
        value={tabValue}
        onChange={handleChangeTab}
        aria-label="simple tabs example"
        indicatorColor="primary"
        textColor="primary"
      >
        {tabs.map((tab, i) => (
          <TabComp label={tab.label} {...a11yProps(tab.value)} key={i} />
        ))}
      </Tabs>
    </div>
  );
}
