import { Box, Typography, CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import FileDownload from 'js-file-download';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import {
  archiveOfficerAttachment,
  getOfficerAttachments,
  getOfficerFileAttachments,
  saveOfficerAttachments,
  unarchiveOfficerAttachment,
  updateOfficersAttachmentTags,
} from '../../services/unarmed';
import ImageDownload from '../../components/ImageDownload';
import { handleOpenPopupFiles } from '../../utils';
import CarouselModal from '../../components/CarouselModal';
import ModalUploadFile from '../../components/ModalUploadFile';
import Tags from '../../components/Tags';
import TagsFilters from '../../components/TagsFilters';

export default function AttachmentTab({ officerId, organizationId }) {
  const [attachments, setAttachments] = useState([]);
  const [attachmentsFiltered, setAttachmentsFiltered] = useState([]);
  const [showAttachmentsFiltered, setShowAttachmentsFiltered] = useState(false);
  const [archivedOnly, setArchivedOnly] = useState(false);
  const [tags, setTags] = useState([]);

  const [loading, setLoading] = useState(false);
  const [loadingDownload, setLoadingDonwload] = useState('idle');
  const [open, setOpen] = React.useState(false);

  const [openPopupFiles, setOpenPopupFiles] = React.useState(false);
  const [filesSelected, setFilesSelected] = React.useState({ files: [], selectedFileIndex: 0 });
  const [loadingOpenFiles, setLoadingOpenFiles] = React.useState([]);

  const onGetOfficerAttachments = async () => {
    const { data } = await getOfficerAttachments(officerId);
    const _attachments = data.sort((x, y) => new Date(y.createdAt).getTime() - new Date(x.createdAt).getTime());
    setAttachments(_attachments);
    filterAttachments(_attachments, tags, archivedOnly);
  };

  const filterAttachments = (attachments, tags, archivedOnly) => {
    setAttachmentsFiltered(
      attachments
        .filter((attachment) => {
          if (!tags?.length) {
            return true;
          }
          return attachment.tags.map((tag) => tag?._id || tag).find((tag) => tags.indexOf(tag) !== -1);
        })
        .filter((attachment) => (archivedOnly ? !!attachment.archivedDate : !attachment.archivedDate))
    );
  };

  useEffect(() => {
    onGetOfficerAttachments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const onDownloadAll = async () => {
    setLoadingDonwload('pending');
    getOfficerFileAttachments(officerId)
      .then(({ data }) => {
        FileDownload(data, `${officerId}-attachments.zip`);
        setLoadingDonwload('resolved');
      })
      .catch((err) => {
        setLoadingDonwload('rejected');
      });
  };

  const onSubmit = async (e, files) => {
    e.preventDefault();

    try {
      setLoading(true);
      const filesData = files.map((file) => ({
        file: file.hashId,
        tags: file?.tags?.map((tag) => tag?._id) || [],
      }));

      await saveOfficerAttachments(officerId, { files: filesData });
      onGetOfficerAttachments();

      setOpen(false);
    } catch (error) {
      toast.error(error?.response?.data?.message);
      toast.error(error?.response?.data?.error?.details[0]?.message);
    } finally {
      setLoading(false);
    }
  };

  const attachmentsToShow = showAttachmentsFiltered ? attachmentsFiltered : attachments;

  return (
    <Box width="100%" maxWidth={600}>
      <Box marginTop={2}>
        <TagsFilters
          onSubmitFilters={(tags, archivedOnly) => {
            setTags(tags);
            setShowAttachmentsFiltered(true);
            filterAttachments(attachments, tags, archivedOnly);
          }}
          archivedOnly={archivedOnly}
          setArchivedOnly={setArchivedOnly}
        />
      </Box>
      <Box
        style={{ background: '#fff', borderRadius: 5 }}
        boxShadow="0 3px 6px #00000005"
        width="100%"
        marginTop={2}
        maxWidth={600}
        padding="20px"
      >
        <CarouselModal
          open={openPopupFiles}
          setOpen={setOpenPopupFiles}
          filesSelected={filesSelected}
          setLoadingOpenFiles={setLoadingOpenFiles}
          loadingOpenFiles={loadingOpenFiles}
        />
        <Box display="flex" alignItems="center" justifyContent="space-between" marginBottom="20px">
          {!archivedOnly && (
            <Box display="flex" onClick={handleOpen} style={{ cursor: 'pointer' }}>
              <AttachFileIcon fontSize="small" htmlColor="#4b7bff" />{' '}
              <Typography style={{ color: '#4b7bff', fontSize: 14, fontWeight: '500' }}>New attachments</Typography>
            </Box>
          )}
          {attachments?.length && !archivedOnly ? (
            <Box display="flex" onClick={onDownloadAll}>
              {loadingDownload === 'pending' && (
                <CircularProgress size={20} style={{ marginRight: '10px', color: '#4b7bff' }} />
              )}

              <Typography
                style={{
                  color: '#4b7bff',
                  fontSize: 14,
                  fontWeight: '500',
                  cursor: 'pointer',
                }}
              >
                Download all
              </Typography>
            </Box>
          ) : null}
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center">
          {loading && <CircularProgress style={{ color: '#4b7bff' }} />}

          {!attachmentsToShow.length && !loading && (
            <Typography style={{ fontWeight: 'bold' }}>No attachments added to this officer</Typography>
          )}
        </Box>

        {attachmentsToShow?.map((file, i) => (
          <ImageDownload
            index={i}
            key={file?._id}
            extension={file.name.match(/[^.]+$/)}
            name={file.name}
            size={file.size}
            urlImg={file.download_url}
            onPreviewImage={() => {
              if (file?.mimetype?.includes('pdf')) {
                const link = document.createElement('a');
                link.href = file.preview_url;
                link.setAttribute('target', '_blank');
                document.body.appendChild(link);
                link.click();
              } else if (
                file?.mimetype?.includes('audio') ||
                file?.mimetype?.includes('video') ||
                file?.mimetype?.includes('image')
              ) {
                handleOpenPopupFiles(file.url, attachments, setOpenPopupFiles, setFilesSelected, setLoadingOpenFiles);
              }
            }}
            restorable={archivedOnly && (file?.archivableByUser ?? true)}
            deletable={!archivedOnly && (file?.archivableByUser ?? true)}
            downloadable={!archivedOnly}
            entityId={officerId}
            id={file._id}
            onDeleteFile={async (entityId, id) => {
              await archiveOfficerAttachment(entityId, id);
              onGetOfficerAttachments();
              //onEditAttachments && onEditAttachments();
            }}
            onRestoreFile={async (entityId, id) => {
              await unarchiveOfficerAttachment(entityId, id);
              onGetOfficerAttachments();
              //onEditAttachments && onEditAttachments();
            }}
          >
            <Tags
              fileId={file?._id}
              defaultTags={file?.tags || []}
              onClickAddTag={(tag) => {
                updateOfficersAttachmentTags(officerId, file._id, {
                  tags: [...(file?.tags.map((_tag) => _tag?._id) || []), tag?._id],
                });
                setAttachments((_attachments) =>
                  _attachments.map((_attachment) => {
                    if (_attachment._id === file._id) {
                      return {
                        ..._attachment,
                        tags: [...(_attachment?.tags || []), tag],
                      };
                    }

                    return { ..._attachment };
                  })
                );
              }}
              withAddBtn={!archivedOnly}
              canRemove={!archivedOnly}
              onClickRemove={(tag) => {
                updateOfficersAttachmentTags(officerId, file._id, {
                  tags: file?.tags?.filter((_tag) => _tag?._id !== tag?._id).map((_tag) => _tag?._id),
                });
                setAttachments((_attachments) =>
                  _attachments.map((_attachment) => {
                    if (_attachment._id === file._id) {
                      return {
                        ..._attachment,
                        tags: file?.tags?.filter((_tag) => _tag?._id !== tag?._id),
                      };
                    }

                    return { ..._attachment };
                  })
                );
              }}
            />
          </ImageDownload>
        ))}
        <ModalUploadFile
          open={open}
          onClose={() => setOpen(false)}
          organizationId={organizationId}
          onConfirm={(e, files) => onSubmit(e, files)}
        />
      </Box>
    </Box>
  );
}

AttachmentTab.propTypes = {
  officerId: PropTypes.number.isRequired,
  organizationId: PropTypes.string.isRequired,
};
