import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Tag from './Tag';
import CreateAttachmentTag from './CreateAttachmentTag';
import { useTagsProvider } from '../provider/TagsProvider';

/**
 * Props for the Tags component.
 *
 * @typedef {Object} TagsProps
 * @property {Array<String>} defaultTags - The default tags to display.
 * @property {String} fileId - The ID of the file associated with the tags.
 * @property {Boolean} [withAddBtn=false] - Determines if the add button should be displayed.
 * @property {Boolean} [canRemove=false] - Determines if the remove button should be displayed.
 * @property {Number} [rightCreateTag=-235] - The right position of the create tag button.
 * @property {String} [positionCreateTag='top'] - The position of the create tag button relative to the tags.
 * @property {String} [sizeCreateTag='large'] - The size of the create tag button.
 * @property {Function} [onClickAddTag=()=>{}] - The function to be called when the add button is clicked.
 * @property {Function} [onClickRemove=()=>{}] - The function to be called when the remove button is clicked.
 */

/**
 * Renders a Tags component.
 *
 * @param {TagsProps} props - The props for the Tags component.
 * @returns {React.Node} The rendered component.
 */
const Tags = ({
  defaultTags,
  fileId,
  withAddBtn = false,
  canRemove = false,
  rightCreateTag = -235,
  positionCreateTag = 'top',
  sizeCreateTag = 'large',
  onClickAddTag = () => {},
  onClickRemove = () => {},
  autoUpdateTags = true,
}) => {
  const [tags, setTags] = useState([]);
  const [tagsLoaded, setTagsLoaded] = useState(false);
  const [addingTag, setAddingTag] = useState(false);

  const { tagsChanged, setTagsChanged } = useTagsProvider();

  /**
   * Checks if any tags have been changed and updates the state accordingly.
   */
  const checkIfTagsChanged = useCallback(() => {
    if (fileId && autoUpdateTags) {
      tagsChanged.forEach((tagData) => {
        const tagExits = tags.find((_tag) => _tag?._id === tagData?.tag?._id);

        if (!tagExits && tagData.status === 'added' && tagData.fileId === fileId) {
          setTags((_tags) => [..._tags, tagData.tag]);
        }

        if (tagExits && tagData.status === 'removed' && tagData.fileId === fileId) {
          setTags((_tags) => [..._tags.filter((_tag) => _tag?._id !== tagData.tag?._id)]);
        }
      });
    }
  }, [tags, tagsChanged, autoUpdateTags, fileId]);

  useEffect(() => {
    if (defaultTags.length > 0) {
      setTags(defaultTags.filter((tag) => typeof tag !== 'string' && tag));
    }

    setTimeout(() => {
      setTagsLoaded(true);
    }, 100);
  }, [defaultTags]);

  useEffect(() => {
    if (tagsLoaded) {
      checkIfTagsChanged();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagsLoaded, tags]);

  /**
   * Returns a sorted array of unique tags.
   *
   * @returns {Array} The sorted array of tags.
   */
  const tagsSorted = useMemo(() => {
    const uniqueTags = new Set();

    tags.forEach((tag) => {
      uniqueTags.add(tag?._id);
    });

    return Array.from(uniqueTags).map((uniqueTag) => tags.find((_tag) => _tag?._id === uniqueTag));
  }, [tags]);

  return (
    <Container>
      {tagsSorted.map((tag) => (
        <Tag
          key={tag?._id}
          text={tag?.name || tag?.tagName}
          canRemove={canRemove}
          bg={tag?.backgroundColor}
          color={tag?.textColor}
          onClickRemove={() => {
            setTags((_tags) => [..._tags.filter((_tag) => _tag?._id !== tag?._id)]);
            onClickRemove(tag);

            // check if was added
            let wasAdded = false;

            tagsChanged.forEach((tagChanged) => {
              if (fileId === tagChanged.fileId && tagChanged.tag?._id === tag?._id && tagChanged.status === 'added') {
                wasAdded = true;
              }
            });

            if (!wasAdded) {
              setTagsChanged((_tags) => [..._tags, { fileId, status: 'removed', tag }]);
            } else {
              setTagsChanged((_tags) => [
                ..._tags.filter(
                  (_tagChanged) =>
                    _tagChanged.fileId !== fileId &&
                    _tagChanged?.tag?._id !== tag?._id &&
                    _tagChanged.status === 'added'
                ),
              ]);
            }
          }}
        />
      ))}
      {withAddBtn && (
        <Add
          save={addingTag}
          onClick={() => {
            setAddingTag(true);
          }}
        >
          Add tag
        </Add>
      )}
      {addingTag && (
        <CreateAttachmentTag
          size={sizeCreateTag}
          currentTags={tags}
          right={rightCreateTag}
          position={positionCreateTag}
          onClickAdd={(tag) => {
            onClickAddTag(tag);
            setTags((_tags) => [..._tags, tag]);

            // check if was removed
            let wasRemoved = false;

            tagsChanged.forEach((tagChanged) => {
              if (fileId === tagChanged.fileId && tagChanged.tag?._id === tag?._id && tagChanged.status === 'removed') {
                wasRemoved = true;
              }
            });

            if (!wasRemoved) {
              setTagsChanged((_tags) => [..._tags, { fileId, status: 'added', tag }]);
            } else {
              setTagsChanged((_tags) => [
                ..._tags.filter(
                  (_tagChanged) =>
                    _tagChanged.fileId !== fileId &&
                    _tagChanged?.tag?._id !== tag?._id &&
                    _tagChanged.status === 'removed'
                ),
              ]);
            }
          }}
          onClickClose={() => setAddingTag(false)}
        />
      )}
    </Container>
  );
};

Tags.propTypes = {
  fileId: PropTypes.string,
  defaultTags: PropTypes.array.isRequired,
  canRemove: PropTypes.bool,
  withAddBtn: PropTypes.bool,
  rightCreateTag: PropTypes.number,
  positionCreateTag: PropTypes.string,
  sizeCreateTag: PropTypes.string,
  autoUpdateTags: PropTypes.bool,
  onClickAddTag: PropTypes.func,
  onClickRemove: PropTypes.func,
};

const Container = styled.div`
  display: inline-flex;
  max-width: 100%;
  flex-wrap: wrap;
  margin-top: 8px;
  margin-bottom: 8px;
  position: relative;
`;

const Add = styled.span`
  height: 24px;
  background-color: ${(props) => (props.save ? '#F2F8FF' : '#f9fafb')};
  color: ${(props) => (props.save ? '#408BEC' : '#c9c9c9')};
  border-width: 1px;
  border-style: dashed;
  border-color: ${(props) => (props.save ? '#408BEC' : '#c9c9c9')};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  padding: 2px 8px;
  border-radius: 4px;
  margin-right: 4px;
  cursor: pointer;
`;

export default Tags;
