import { Box, CircularProgress, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { toast } from 'react-toastify';
import Alert from '../../components/Alert';
import DashButton from '../../components/DashButton';
import Dropdown from '../../formEngine/components/Dropdown';
import ShortText from '../../formEngine/components/ShortText';
import useInput from '../../hooks/useInput';
import { onSaveFormType } from '../../services/unarmed';
import { subtypeOptions } from '../../utils/options';
import {
  AllegationOptions,
  DisciplineOptions,
  DispositionOptions,
  ReporterOptions,
  sourceOptions,
} from '../CaseDetails/UpdateCase';
import CaseNumber from './components/CaseNumber';
import { ContainerPageDetail } from './PageDetails';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    position: 'relative',
    padding: 28,
    minHeight: 195,
    borderRadius: 8,
    boxShadow: '0px 3px 6px #0000000D',
    backgroundColor: '#fff',
    marginTop: 35,
  },
}));

export const investigatorOptions = ['IA', 'PCOB', 'Both'];

export const renderHeder = ({ title, onClick, loading, isDefaultForm }) => (
  <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
    <Typography style={{ fontSize: 24, fontWeight: '500' }}>{title}</Typography>

    <DashButton onClick={onClick} disabled={isDefaultForm || loading}>
      {loading ? <CircularProgress color="#fff" size="20px" /> : 'Save'}{' '}
    </DashButton>
  </Box>
);

export default function Standard({ id, formInfoUpdate, isDefaultForm, setFormInfo }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [caseUpdates, setCaseUpdates] = useState({});
  // const visible1 = useInput(true, false, false, true);
  const visible2 = useInput(true, false, false, true);
  const source = useInput(true, false, false, true);
  const reporter = useInput(true, false, false, true);
  const subtype = useInput(true, false, false, true);
  const investigator = useInput(true, false, false, true);
  const allegations = useInput(true, false, false, true);
  const dispositions = useInput(true, false, false, true);
  const disciplines = useInput(true, false, false, true);
  const onUpdateJson = (value) => {
    setCaseUpdates((prevState) => ({ ...prevState, ...value }));
  };

  useEffect(() => {
    if (formInfoUpdate) {
      // visible1.setValue(formInfoUpdate?.caseNumber?.visible);
      visible2.setValue(formInfoUpdate?.internalAffairs?.visible);
      subtype.setValue(formInfoUpdate?.subtype?.visible);
      source.setValue(formInfoUpdate?.source?.visible);
      reporter.setValue(formInfoUpdate?.reporter?.visible);
      allegations.setValue(formInfoUpdate?.allegations?.visible);
      dispositions.setValue(formInfoUpdate?.dispositions?.visible);
      disciplines.setValue(formInfoUpdate?.disciplines?.visible);
      investigator.setValue(formInfoUpdate?.investigator?.visible);
    }
  }, [formInfoUpdate]);

  const onSave = async () => {
    setLoading(true);
    try {
      const data = {
        caseUpdates: {
          active: formInfoUpdate?.active,
          internalAffairs: {
            visible: visible2.value,
          },
          ...caseUpdates,
        },
      };
      const res = await onSaveFormType(data, id);

      setFormInfo((prevState) => ({
        ...prevState,
        caseUpdates: res.data?.caseUpdates,
      }));
      toast.success('Case updates has been updated');
    } catch (error) {
      toast.error(error?.response?.data?.error?.details?.[0]?.message);
    }
    setLoading(false);
  };
  return (
    <ContainerPageDetail style={{ width: '100%', height: 'calc(116.1vh - 64px)', overflowY: 'auto' }}>
      <Box padding="30px 20px 20px" width="100%">
        {renderHeder({
          title: 'Standard',
          onClick: onSave,
          loading,
          isDefaultForm,
        })}
        {isDefaultForm && (
          <Alert status="info">
            <Typography style={{ fontSize: 14 }}>
              Default forms cannot be deleted or updated. Duplicate this form to edit.
            </Typography>
          </Alert>
        )}
        <DndProvider backend={HTML5Backend}>
          <Box className={classes.container}>
            {isDefaultForm && (
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  zIndex: 1,
                  background: 'rgba(0,0,0,0.01)',
                }}
              />
            )}
            <CaseNumber
              questionBankComponent
              data={{ question: 'Case Number' }}
              componentIndex={0}
              isStaticComponent
              onUpdateJson={onUpdateJson}
              // visible={visible1}
              id={id}
              formInfoUpdate={formInfoUpdate}
            />
            <ShortText
              questionBankComponent
              data={{ question: 'Internal Affairs Case Number' }}
              componentIndex={0}
              isStaticComponent
              onUpdateJson={onUpdateJson}
              visible={visible2}
            />
            <Dropdown
              data={{
                question: 'Subtype',
                options: formInfoUpdate?.subtype?.values || subtypeOptions,
              }}
              componentIndex={0}
              isStaticComponent
              onUpdateJson={onUpdateJson}
              shouldRenderSettings={false}
              allowDnD={false}
              includeRequired={false}
              visible={subtype}
            />
            <Dropdown
              data={{
                question: 'Source',
                options: formInfoUpdate?.source?.values || sourceOptions,
              }}
              componentIndex={0}
              isStaticComponent
              onUpdateJson={onUpdateJson}
              shouldRenderSettings={false}
              allowDnD={false}
              includeRequired={false}
              visible={source}
            />
            <Dropdown
              data={{
                question: 'Reporter',
                options: formInfoUpdate?.reporter?.values || ReporterOptions,
              }}
              componentIndex={0}
              isStaticComponent
              onUpdateJson={onUpdateJson}
              shouldRenderSettings={false}
              allowDnD={false}
              includeRequired={false}
              visible={reporter}
            />
            <Dropdown
              data={{
                question: 'Allegations',
                options: formInfoUpdate?.allegations?.values || AllegationOptions,
              }}
              componentIndex={0}
              isStaticComponent
              onUpdateJson={onUpdateJson}
              shouldRenderSettings={false}
              allowDnD={false}
              includeRequired={false}
              visible={allegations}
            />
            <Dropdown
              data={{
                question: 'Dispositions',
                options: formInfoUpdate?.dispositions?.values || DispositionOptions,
              }}
              componentIndex={0}
              isStaticComponent
              onUpdateJson={onUpdateJson}
              shouldRenderSettings={false}
              allowDnD={false}
              includeRequired={false}
              visible={dispositions}
            />
            <Dropdown
              data={{
                question: 'Disciplines',
                options: formInfoUpdate?.disciplines?.values || DisciplineOptions,
              }}
              componentIndex={0}
              isStaticComponent
              onUpdateJson={onUpdateJson}
              shouldRenderSettings={false}
              allowDnD={false}
              includeRequired={false}
              visible={disciplines}
            />
            <Dropdown
              data={{
                question: 'Investigator',
                options: formInfoUpdate?.investigator?.values || investigatorOptions,
              }}
              componentIndex={0}
              isStaticComponent
              onUpdateJson={onUpdateJson}
              shouldRenderSettings={false}
              allowDnD={false}
              includeRequired={false}
              visible={investigator}
            />
          </Box>
        </DndProvider>
      </Box>
    </ContainerPageDetail>
  );
}
