import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, CircularProgress, IconButton, Typography } from '@material-ui/core';
import styled from 'styled-components';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import Card from '../../components/Card';
import { OutLineInput } from './UpdateCase';
import useInput from '../../hooks/useInput';
import { linkCase, onSearchGeneral, unLinkCase } from '../../services/unarmed';
import { axiosCatchError } from '../../utils';
import { matchfieldOptions } from '../../utils/options';

/**
 * RelatedCases component allows users to search for and link/unlink cases related to a form.
 *
 * @component
 * @param {Object} props
 * @param {string} props.formId - The ID of the form to which cases are linked.
 * @param {Array<Object>} props.related - Array of already linked cases.
 * @returns {React.Element}
 */
const RelatedCases = ({ formId, related }) => {
  const search = useInput('');
  const [showResults, setShowResults] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cases, setCases] = useState([]);
  const [linked, setLinked] = useState([...related]);
  const [showMinCharsSearchWarning, setShowMinCharsSearchWarning] = useState(false);

  /**
   * Handles search input and fetches cases matching the search text.
   *
   * @param {string} searchText - The text to search for cases.
   */
  const handleOnSearch = (searchText) => {
    if (searchText.length >= 3 && searchText !== '') {
      setShowMinCharsSearchWarning(false);
      setLoading(true);
      onSearchGeneral(searchText, {
        caseNumberOnly: false,
        exclude: [formId, ...linked.map((caseData) => caseData?._id)],
      })
        .then((res) => {
          setLoading(false);
          setShowResults(true);
          setCases(res.data);
        })
        .catch((error) => {
          setLoading(false);
          axiosCatchError(error);
        });
    } else {
      setShowMinCharsSearchWarning(true);
    }
  };

  /**
   * Links a case to the form.
   *
   * @param {Object} caseData - The case data to link.
   */
  const handleOnLinkCase = (caseData) => {
    linkCase(formId, caseData?._id)
      .then(() => {
        setLinked([...linked, caseData]);
        toast.success(`Case ${caseData?.code} was linked successfully`);
        setCases((_cases) => _cases.filter((_case) => _case?._id !== caseData?._id));
      })
      .catch((error) => {
        axiosCatchError(error);
      });
  };

  /**
   * Unlinks a case from the form.
   *
   * @param {Object} caseData - The case data to unlink.
   */
  const handleUnLinkCase = (caseData) => {
    unLinkCase(formId, caseData?._id)
      .then(() => {
        setLinked(linked.filter((_caseData) => _caseData?._id !== caseData?._id));
        toast.success(`Case ${caseData?.code} was unlinked successfully`);
      })
      .catch((error) => {
        axiosCatchError(error);
      });
  };

  return (
    <Card title="Related Cases">
      <Box>
        <OutLineInput
          data-testid="case-details-officers-input-search-officer"
          placeholder="Search cases"
          value={search.value}
          onChange={(e) => {
            const text = e.target.value;

            if (text === '' && showResults && cases.length === 0) {
              setShowResults(false);
            }

            search.setValue(text);
          }}
          error={search.error}
          aria-describedby="outlined-weight-helper-text"
          inputProps={{ 'aria-label': 'weight' }}
          labelWidth={0}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              handleOnSearch(search.value);
            }
          }}
        />
        {showMinCharsSearchWarning && (
          <Typography style={{ color: 'red', marginTop: 8, fontSize: 14 }}>
            Enter at least 3 characters to search
          </Typography>
        )}
      </Box>
      <Box marginTop={2} display="flex" justifyContent="flex-end">
        <Button color="primary" variant="contained" onClick={() => handleOnSearch(search.value)}>
          SEARCH
        </Button>
      </Box>
      {loading && (
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      )}
      {search.value !== '' && cases.length === 0 && showResults && !loading ? (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography style={{ color: '#9E9E9E', fontWeight: '500' }}>No results found</Typography>
        </Box>
      ) : (
        <>
          {showResults && cases.length > 0 && !loading && (
            <Box marginTop={2}>
              <ResultHeader>
                <Title>Results</Title>
                <Button
                  color="primary"
                  onClick={() => {
                    setShowResults(false);
                    setCases([]);
                    search.setValue('');
                  }}
                >
                  Clear
                </Button>
              </ResultHeader>

              <Results>
                {cases.map((caseData, caseKey) => (
                  <ResultItem margin={8} key={caseKey} withBorder={caseKey < cases.length - 1}>
                    <ResultTitle>TRACKING NUMBER</ResultTitle>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                      <Box>
                        <Link to={`/case/${caseData.code}`}>
                          <ResultLink>{caseData.code}</ResultLink>
                        </Link>
                        {caseData.matchingFields && caseData.matchingFields.length > 0 && (
                          <>
                            {caseData.matchingFields.map((field, fieldIndex) => {
                              const fieldLabel = matchfieldOptions.find((item) => item.beKey === field.key);

                              return (
                                <Box display="flex" alignItems="center" marginTop={1} key={fieldIndex}>
                                  <ResultBold>{fieldLabel ? fieldLabel?.text : field.key}:</ResultBold>
                                  <ResultValue>{field.value}</ResultValue>
                                </Box>
                              );
                            })}
                          </>
                        )}
                      </Box>
                      <Box>
                        <IconButton onClick={() => handleOnLinkCase(caseData)}>
                          <AddCircleOutlineIcon htmlColor="#4b7bff" />
                        </IconButton>
                      </Box>
                    </Box>
                  </ResultItem>
                ))}
              </Results>
            </Box>
          )}
        </>
      )}
      <Box marginTop={2}>
        <ResultHeader>
          <Title>Linked</Title>
        </ResultHeader>
        {linked.length > 0 ? (
          <Results>
            {linked.map((caseData, caseKey) => (
              <ResultItem margin={0} key={caseKey} withBorder={caseKey < linked.length - 1}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Link to={`/case/${caseData.code}`}>
                    <ResultLink>{caseData?.code}</ResultLink>
                  </Link>
                  <IconButton onClick={() => handleUnLinkCase(caseData)}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </ResultItem>
            ))}
          </Results>
        ) : (
          <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
            <Typography style={{ color: '#9E9E9E', fontWeight: '500' }}>No linked cases</Typography>
          </Box>
        )}
      </Box>
    </Card>
  );
};

RelatedCases.propTypes = {
  formId: PropTypes.string.isRequired,
  related: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
    })
  ).isRequired,
};

const ResultHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
  border-bottom: 1px solid #e2e2e2;
`;

const Title = styled.p`
  font-size: 16px;
  font-weight: 500;
  color: #575a66;
`;

const Results = styled.div``;

const ResultItem = styled.div`
  padding-top: ${(props) => `${props.margin}px`};
  padding-bottom: ${(props) => `${props.margin}px`};

  ${(props) =>
    props.withBorder &&
    `
    border-bottom: 1px solid #e2e2e2;
  `}
`;

const ResultTitle = styled.p`
  color: #a1a1a1;
  font-weight: bold;
  font-size: 16px;
`;

const ResultLink = styled.a`
  color: #4b7bff;
  display: inline-block;
`;

const ResultBold = styled.span`
  font-weight: 500;
  margin-right: 4px;
`;

const ResultValue = styled.span`
  color: #a1a1a1;
`;

export default RelatedCases;
