import React from 'react';
import { Box, makeStyles, Tooltip, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import formatText from '../../utils/formatText';
import AccordionWrap from '../../components/AccordionWrap';

const useStyles = makeStyles(() => ({
  formSection: {
    fontSize: 14,
    color: '#3B70FE',
    fontWeight: '500',
    margin: '20px 0',
  },
  label: {
    fontSize: 12,
    color: '#585858',
  },
}));

const PersonalInformation = ({ form }) => {
  const classes = useStyles();

  return (
    <Box style={{ background: '#fff', borderRadius: 5 }} boxShadow="0 3px 6px #00000005">
      <AccordionWrap
        id="personal-information"
        header={
          <Box>
            <Typography
              variant="h5"
              style={{
                fontWeight: '500',
                fontSize: 16,
                color: '#575A66',
                marginBottom: 20,
              }}
            >
              Personal Information
            </Typography>
          </Box>
        }
      >
        <Box>
          <Typography className={classes.formSection}>Basic Info</Typography>
          <Box display="flex" alignItems="center" flexWrap="wrap">
            <Box width="100%" maxWidth="150px" marginRight="20px" marginBottom="10px">
              <Typography variant="body2" className={classes.label}>
                First name
              </Typography>
              <Tooltip title={form?.civilian?.firstName}>
                <Typography variant="subtitle">
                  {formatText(form?.civilian?.firstName, 15) || 'Not specified'}
                </Typography>
              </Tooltip>
            </Box>
            <Box width="100%" maxWidth="150px" marginRight="20px" marginBottom="10px">
              <Typography variant="body2" className={classes.label}>
                Last name
              </Typography>
              <Tooltip title={form?.civilian?.lastName}>
                <Typography variant="subtitle">
                  {formatText(form?.civilian?.lastName, 15) || 'Not specified'}
                </Typography>
              </Tooltip>
            </Box>
            <Box width="100%" maxWidth="150px" marginRight="20px" marginBottom="10px">
              <Typography variant="body2" className={classes.label}>
                Email
              </Typography>
              <Tooltip title={form?.civilian?.email}>
                <Typography variant="subtitle">{formatText(form?.civilian?.email, 15) || 'Not specified'}</Typography>
              </Tooltip>
            </Box>
            <Box width="100%" maxWidth="150px" marginRight="20px" marginBottom="10px">
              <Typography variant="body2" className={classes.label}>
                Phone
              </Typography>
              <Tooltip title={form?.civilian?.phone}>
                <Typography variant="subtitle">{formatText(form?.civilian?.phone, 15) || 'Not specified'}</Typography>
              </Tooltip>
            </Box>
          </Box>

          <Typography className={classes.formSection}>Contact Info</Typography>
          <Box display="flex" alignItems="center" flexWrap="wrap">
            <Box width="100%" maxWidth="150px" marginRight="20px" marginBottom="10px">
              <Typography variant="body2" className={classes.label}>
                Street name
              </Typography>
              <Tooltip title={form?.civilian?.addressLine1}>
                <Typography variant="subtitle">
                  {formatText(form?.civilian?.addressLine1, 26) || 'Not specified'}
                </Typography>
              </Tooltip>
            </Box>
            <Box width="100%" maxWidth="150px" marginRight="20px" marginBottom="10px">
              <Typography variant="body2" className={classes.label}>
                Apt/Unit #
              </Typography>
              <Tooltip title={form?.civilian?.addressLine2}>
                <Typography variant="subtitle">
                  {formatText(form?.civilian?.addressLine2, 26) || 'Not specified'}
                </Typography>
              </Tooltip>
            </Box>
            <Box width="100%" maxWidth="150px" marginRight="20px" marginBottom="10px">
              <Typography variant="body2" className={classes.label}>
                City
              </Typography>
              <Tooltip title={form?.civilian?.city?.name}>
                <Typography variant="subtitle">
                  {formatText(form?.civilian?.city?.name, 26) || 'Not specified'}
                </Typography>
              </Tooltip>
            </Box>
            <Box width="100%" maxWidth="150px" marginRight="20px" marginBottom="10px">
              <Typography variant="body2" className={classes.label}>
                State
              </Typography>
              <Tooltip title={form?.civilian?.state?.name}>
                <Typography variant="subtitle">
                  {formatText(form?.civilian?.state?.name, 26) || 'Not specified'}
                </Typography>
              </Tooltip>
            </Box>
            <Box width="100%" maxWidth="150px" marginRight="20px" marginBottom="10px">
              <Typography variant="body2" className={classes.label}>
                Zip code
              </Typography>
              <Tooltip title={form?.civilian?.zipCode}>
                <Typography variant="subtitle">{formatText(form?.civilian?.zipCode) || 'Not specified'}</Typography>
              </Tooltip>
            </Box>
          </Box>
        </Box>
      </AccordionWrap>
    </Box>
  );
};

PersonalInformation.propTypes = {
  form: PropTypes.object,
};

export default PersonalInformation;
