/* eslint-disable no-useless-escape */
import moment from 'moment';
import { toast } from 'react-toastify';

export function formatPhoneNumber(string, fullString) {
  const cleaned = `${string}`.replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }

  if (Array.isArray(string)) {
    return string.join(', ');
  }

  if (fullString && fullString.match(/(\d{4})-(\d{2})-(\d{2})T(\d{2})\:(\d{2})\:(\d{2})/)) {
    return moment(fullString).format('MM/DD/YYYY');
  }

  return string;
}

export const validateIsoDate = (date) => {
  if (date.match(/(\d{4})-(\d{2})-(\d{2})T(\d{2})\:(\d{2})\:(\d{2})/)) {
    return true;
  }
  return false;
};

const isDate = (date) => new Date(date) !== 'Invalid Date' && !Number.isNaN(new Date(date));

export const checkValueType = (value, type) => {
  // eslint-disable-next-line no-restricted-globals
  if (type === 'shortText') {
    return value;
  }
  if (type === 'date' || (type === 'demographics' && isDate(value))) {
    return moment(value).format('MM-DD-YYYY');
  }
  if (typeof value === 'boolean') {
    return value === true ? 'Yes' : 'No';
  }

  return formatPhoneNumber(value);
};

export const transformStatus = (status) => status;

export const formatCubeJsTableColumns = (title, key) => {
  let column = `${title} `;
  const currentKey = key.split('.');
  const lengthKey = currentKey[currentKey.length - 1];
  if (lengthKey === 'day') {
    column += `by Day`;
  } else if (lengthKey === 'hour') {
    column += `by Hour`;
  } else if (lengthKey === 'minute') {
    column += 'by Minute';
  } else if (lengthKey === 'week') {
    column += 'by Week';
  } else if (lengthKey === 'month') {
    column += 'by Month';
  } else if (lengthKey === 'year') {
    column += 'by Year';
  }
  return column;
};

export const getFormColor = (formType) => {
  switch (formType) {
    case 'COMPLAINT':
      return 'rgb(255, 66, 66)';
    case 'COMPLIMENT':
      return '#00b27c';
    // case 'AUDIT':
    //   return '#E89800';
    default:
      return '#2E66FE';
  }
};

export const getFormColorPlaceholder = (formType) => {
  switch (formType) {
    case 'COMPLAINT':
      return 'rgba(255, 66, 66, 0.1)';
    case 'COMPLIMENT':
      return '#e5f6f1';
    // case 'AUDIT':
    //   return '#E89800';
    default:
      return '#2E66FE1A';
  }
};

export const fileUploadAceptedTypes = [
  'image/*',
  'video/*',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'audio/*',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
];

export const handleOpenPopupFiles = (imgUrl, filesOp, setOpenPopupFiles, setFilesSelected, setLoadingOpenFiles) => {
  setOpenPopupFiles(true);

  const filesToOpen = filesOp.filter(
    (flop) => flop.mimetype.includes('image') || flop.mimetype.includes('video') || flop.mimetype.includes('audio')
  );
  const selectedFile = filesToOpen.find((flop) => flop.url === imgUrl);

  const selectedFileIndex = filesToOpen.indexOf(selectedFile);

  setFilesSelected({ files: filesToOpen, selectedFileIndex });
  setLoadingOpenFiles(
    filesToOpen.map(() => ({
      loading: false,
    }))
  );
};

export const sortByCreatedAt = (data) =>
  data.sort((x, y) => new Date(y.createdAt).getTime() - new Date(x.createdAt).getTime());

export const axiosCatchError = (error) => {
  const message = error?.response?.data?.error?.details[0]?.message || error?.response?.data?.message;

  toast.error(message);
};

export const containsHTML = (str) => {
  const pattern = /<([a-z][a-z0-9]*)\b[^>]*>(.*?)<\/\1>|<([a-z][a-z0-9]*)\b[^\/]*\/>/gi;
  return pattern.test(str);
};

export const sortOrderForms = (forms) =>
  forms?.sort((x, y) => {
    // First, sort by the order property
    if (x.order !== y.order) {
      return x.order - y.order;
    }
    // If the order property is the same, sort alphabetically by some other property (e.g., name)
    return x.name.localeCompare(y.name);
  });

export const getDocumentTitle = (orgName) => {
  let moduleName = '';

  if (window.location.pathname === '/') {
    moduleName = 'Dashboard';
  } else {
    const pathNameParts = window.location.pathname.split('/');
    const pathName = pathNameParts.length > 0 ? pathNameParts[1] : '';

    if (pathName !== '') {
      moduleName = pathName[0].toUpperCase() + pathName.slice(1);
    }
  }

  const pageTitle = moduleName !== '' ? `${orgName} | ${moduleName}` : orgName;

  return pageTitle;
};

export const createFormTypePageId = (pageName, pageIndex) => {
  const pageId = `${pageName.toLowerCase().replace(/ /g, '-')}-${pageIndex}`;

  return pageId;
};
