/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import { Box, Button, FormControl, IconButton, makeStyles, Popper, TextField, Typography } from '@material-ui/core';
import { Avatar, Col, Row, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Autocomplete } from '@material-ui/lab';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import DeleteIcon from '@material-ui/icons/Delete';

import profileImg from '../../assets/profile.png';
import Card, { useCardStyles } from '../../components/Card';
import AddMore from '../../components/AddMore';
import {
  fetchCubeBucketsDate,
  getFilterByCase,
  getUsers,
  getUsersSearch,
  userGetNotificationConfig,
  userSaveNotificationConfig,
} from '../../services/unarmed';
import { EIS, NOTIFICATIONS } from '../../config/constants';
import { axiosCatchError } from '../../utils';

let isSafari = false;

if (navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1) {
  isSafari = true;
}

const useStyles = (_isSafari = false) =>
  makeStyles({
    customPopperWithLargePadding: {
      top: !_isSafari ? '-1% !important' : '-6% !important',
      left: !_isSafari ? '-0% !important' : '-9% !important',
      height: '150px !important',
      zIndex: 1000,
      padding: '0px 22px',

      '& .MuiPaper-root': {
        height: '150px !important',
      },

      '& .MuiAutocomplete-listbox': {
        height: '150px !important',
      },
    },
    customPopper: {
      top: !_isSafari ? '-1% !important' : '-6% !important',
      left: !_isSafari ? '-0% !important' : '-9% !important',
      height: '150px !important',
      zIndex: 1000,
      padding: '0px 11px',

      '& .MuiPaper-root': {
        height: '150px !important',
      },

      '& .MuiAutocomplete-listbox': {
        height: '150px !important',
      },
    },
    inputRoot: {
      flexWrap: 'nowrap',
      overflowY: 'hidden',
      overflowX: 'hidden',
      border: '1px solid rgba(0, 0, 0, 0.23)',
      scrollbarWidth: 'thin',

      '& .MuiOutlinedInput-notchedOutline': {
        border: '0 !important',
      },
    },
    inputRootClicked: {
      flexWrap: 'nowrap',
      overflowY: 'hidden',
      overflowX: 'scroll',
      border: '1px solid rgba(0, 0, 0, 0.23)',
      scrollbarWidth: 'thin',

      '& .MuiOutlinedInput-notchedOutline': {
        border: '0 !important',
      },
    },
  });

function CustomPopper(props) {
  const classes = useStyles(isSafari)();
  return (
    <Popper
      {...props}
      className={props.withLargePadding ? classes.customPopperWithLargePadding : classes.customPopper}
    />
  );
}

function findItemsInArray(searchArray, targetArray) {
  return searchArray.filter((item) => targetArray.includes(item));
}

/**
 * Component to render different configurations based on the provided configType.
 *
 * @param {Object} props - Component properties.
 * @param {string} props.configType - Type of the configuration (e.g., 'configCreated', 'configAssigned').
 * @param {Object} props.fields - Field values for the configuration.
 * @param {Object} props.options - Options for the various fields.
 * @param {function} props.addNewConfig - Callback to add a new configuration.
 * @param {boolean} props.email - Whether email notifications are enabled.
 * @param {boolean} props.dashboard - Whether dashboard notifications are enabled.
 * @param {function} props.onChangeEmail - Callback when email switch is toggled.
 * @param {function} props.onChangeDashboard - Callback when dashboard switch is toggled.
 * @param {function} props.onChangeField - Callback when a field value changes.
 */
const ConfigRow = ({
  configType,
  fields,
  options,
  addNewConfig,
  email,
  dashboard,
  onChangeEmail,
  onChangeDashboard,
  onChangeField,
  onSearch,
  onClickDelete,
  configIndex,
}) => {
  const [defaultCaseTypes, setDefaultCaseTypes] = useState([...(options?.caseTypes || [])]);
  const [autocompleteFocused, setAutocompleteFocused] = useState('');

  const classes = useStyles(isSafari)();

  useEffect(() => {
    if (options.caseTypes) {
      setDefaultCaseTypes(options.caseTypes);
    }
  }, [options]);

  useEffect(() => {
    if (fields.caseType && Array.isArray(fields.caseType) && defaultCaseTypes.length > 0) {
      const foundItems = findItemsInArray(fields.caseType, defaultCaseTypes);

      if (foundItems.length !== fields.caseType.length) {
        setDefaultCaseTypes([
          ...fields.caseType,
          ...defaultCaseTypes.filter((item) => fields.caseType.indexOf(item) === -1),
        ]);
      }
    }
  }, [fields.caseType, defaultCaseTypes]);

  const selectUser = (
    <Autocomplete
      size="small"
      freeSolo
      id="free-solo-2-demo"
      disableClearable
      multiple
      limitTags={2}
      renderOption={(user) => {
        if (typeof user === 'string') return user;

        return (
          <Box display="flex" alignItems="center" padding="8px 0px">
            <Avatar src={user?.photo?.url || user?.profile_img || profileImg} style={{ marginRight: 10 }} />
            <Box>
              <Typography variant="body1" style={{ fontSize: 13 }}>
                {user?.firstName ? `${user?.firstName} ${user?.lastName} ` : `${user?.username || ''}`}
              </Typography>
              <Typography variant="body1" style={{ fontSize: 13 }}>
                {user.email}
              </Typography>
            </Box>
          </Box>
        );
      }}
      classes={{
        inputRoot:
          autocompleteFocused === 'selectUser' && fields?.assignedTo?.length > 2
            ? classes.inputRootClicked
            : classes.inputRoot,
      }}
      PopperComponent={CustomPopper}
      value={fields.assignedTo}
      options={['Select all', ...(options?.users || [])]}
      getOptionLabel={(option) => (typeof option === 'object' ? `${option.firstName} ${option.lastName}` : option)}
      onChange={(e, value) => {
        if (value.includes('Select all')) {
          onChangeField('assignedTo', options.users);
        } else {
          onChangeField(
            'assignedTo',
            value.filter((user) => typeof user !== 'string')
          );

          if (value.map((item) => typeof item).indexOf('string') !== -1) {
            onSearch('assignedTo', '');
          }
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          onChange={(e) => (onSearch ? onSearch('assignedTo', e.target.value) : undefined)}
          margin="normal"
          placeholder={fields.assignedTo.length === 0 ? 'Search for an option' : undefined}
          variant="outlined"
          InputProps={{ ...params.InputProps, type: 'search' }}
          onFocus={() => {
            setAutocompleteFocused('selectUser');
          }}
          onBlur={() => {
            setAutocompleteFocused('');
          }}
        />
      )}
    />
  );

  const emailAndDashboard = (
    <>
      <Col xs={2}>
        <Box display="flex" alignItems="center" height="100%">
          <Switch checked={!!email} onChange={(e) => onChangeEmail(e)} />
        </Box>
      </Col>
      <Col xs={2}>
        <Box display="flex" alignItems="center" height="100%">
          <Switch checked={!!dashboard} onChange={(e) => onChangeDashboard(e)} />
        </Box>
      </Col>
    </>
  );
  const caseType = (
    <>
      <Col xs={3} xxl={2}>
        <Box display="flex" height="100%" alignItems="center">
          <NotificationTitle>Case type</NotificationTitle>
        </Box>
      </Col>
      <Col xs={6}>
        <FormControl variant="outlined" style={{ width: '100%' }}>
          <Autocomplete
            size="small"
            freeSolo
            id="free-solo-2-demo"
            disableClearable
            multiple
            limitTags={2}
            PopperComponent={(props) => <CustomPopper withLargePadding {...props} />}
            value={fields.caseType}
            renderOption={(option) => (
              <Typography style={{ fontSize: 13 }}>{`${option[0]?.toUpperCase()}${option
                ?.substr(1)
                ?.toLowerCase()}`}</Typography>
            )}
            classes={{
              inputRoot:
                autocompleteFocused === 'caseType' && fields?.caseType?.length > 2
                  ? classes.inputRootClicked
                  : classes.inputRoot,
            }}
            getOptionLabel={(value) => `${value[0]?.toUpperCase()}${value?.substr(1)?.toLowerCase()}`}
            options={['Select all', ...defaultCaseTypes]}
            onChange={(e, value) => {
              if (value.includes('Select all')) {
                onChangeField('caseType', options.caseTypes);
              } else {
                onChangeField(
                  'caseType',
                  value.map((_value) => _value?.toUpperCase())
                );
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={fields.caseType.length === 0 ? 'Search for an option' : undefined}
                margin="normal"
                variant="outlined"
                InputProps={{ ...params.InputProps, type: 'search' }}
                onFocus={() => {
                  setAutocompleteFocused('caseType');
                }}
                onBlur={() => {
                  setAutocompleteFocused('');
                }}
              />
            )}
          />
        </FormControl>
      </Col>
    </>
  );
  const assignedTo = (
    <>
      <Col xs={4} xl={3} xxl={2}>
        <Box display="flex" height="100%" justifyContent="center" alignItems="center">
          <NotificationTitle>assigned to</NotificationTitle>
        </Box>
      </Col>
      <Col xs={6}>
        <FormControl variant="outlined" style={{ width: '100%' }}>
          {selectUser}
        </FormControl>
      </Col>
    </>
  );

  const rowContent = (
    <>
      {configType === 'configCreated' && (
        <>
          <Col xs={18}>
            <Row gutter={[16, 16]} display="flex" alignItems="center" height="100%">
              {caseType}
              <Col xs={2}>
                <Box display="flex" height="100%" alignItems="center">
                  <NotificationTitle>created</NotificationTitle>
                </Box>
              </Col>
            </Row>
          </Col>
          {emailAndDashboard}
        </>
      )}
      {configType === 'configAssigned' && (
        <>
          <Col xs={18}>
            <Row gutter={[16, 16]} display="flex" alignItems="center" height="100%">
              {caseType}
              {assignedTo}
            </Row>
          </Col>
          {emailAndDashboard}
        </>
      )}
      {configType === 'configUpdated' && (
        <>
          <Col xs={18}>
            <Row gutter={[16, 16]} display="flex" alignItems="center" height="100%">
              {caseType}
              {assignedTo}
              <Col xs={3} xxl={2}>
                <Box display="flex" height="100%" alignItems="center">
                  <NotificationTitle>is updated</NotificationTitle>
                </Box>
              </Col>
            </Row>
          </Col>
          {emailAndDashboard}
        </>
      )}
      {configType === 'configStatus' && (
        <>
          <Col xs={18}>
            <Row gutter={[16, 16]} display="flex" alignItems="center" height="100%">
              {caseType}
              <Col xs={3} xxl={2}>
                <Box display="flex" height="100%" justifyContent="center" alignItems="center">
                  <NotificationTitle>is status</NotificationTitle>
                </Box>
              </Col>
              <Col md={6} lg={5} xl={5} xxl={4}>
                <FormControl variant="outlined" style={{ width: '100%' }}>
                  <Autocomplete
                    size="small"
                    freeSolo
                    id="free-solo-2-demo"
                    disableClearable
                    PopperComponent={CustomPopper}
                    value={fields.status}
                    options={options.statuses}
                    getOptionLabel={(value) => `${value[0].toUpperCase()}${value.substr(1).toLowerCase()}`}
                    onChange={(e, value) => onChangeField('status', value.toUpperCase())}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Search for an option"
                        margin="normal"
                        variant="outlined"
                        InputProps={{ ...params.InputProps, type: 'search' }}
                      />
                    )}
                  />
                </FormControl>
              </Col>
              <Col xs={1}>
                <Box display="flex" height="100%" justifyContent="center" alignItems="center">
                  <NotificationTitle>for</NotificationTitle>
                </Box>
              </Col>
              <Col md={4} lg={4} xl={3} xxl={3}>
                <FormControl variant="outlined" style={{ width: '100%' }}>
                  <Autocomplete
                    size="small"
                    freeSolo
                    id="free-solo-2-demo"
                    disableClearable
                    PopperComponent={CustomPopper}
                    value={fields.time}
                    options={options.days}
                    onChange={(e, value) => onChangeField('time', value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // label="Select an option"
                        margin="normal"
                        variant="outlined"
                        InputProps={{ ...params.InputProps, type: 'search' }}
                      />
                    )}
                  />
                </FormControl>
              </Col>
              <Col xs={2}>
                <Box display="flex" height="100%" alignItems="center">
                  <NotificationTitle>days</NotificationTitle>
                </Box>
              </Col>
            </Row>
          </Col>
          {emailAndDashboard}
        </>
      )}
      {configType === 'configDueDate' && (
        <>
          <Col xs={18}>
            <Row gutter={[16, 16]} display="flex" alignItems="center" height="100%">
              <Col xs={10} md={9} lg={8} xl={6} xxl={5}>
                <Box display="flex" height="100%" alignItems="center">
                  <NotificationTitle>Due date for task assigned to</NotificationTitle>
                </Box>
              </Col>
              <Col xs={5}>
                <FormControl variant="outlined" style={{ width: '100%' }}>
                  {selectUser}
                </FormControl>
              </Col>
              <Col xs={1}>
                <Box display="flex" height="100%" justifyContent="center" alignItems="center">
                  <NotificationTitle>is</NotificationTitle>
                </Box>
              </Col>
              <Col md={4} lg={4} xl={3} xxl={3}>
                <FormControl variant="outlined" style={{ width: '100%' }}>
                  <Autocomplete
                    size="small"
                    freeSolo
                    id="free-solo-2-demo"
                    disableClearable
                    PopperComponent={CustomPopper}
                    value={fields.time}
                    options={options.days}
                    onChange={(e, value) => onChangeField('time', value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // label="Select an option"
                        margin="normal"
                        variant="outlined"
                        InputProps={{ ...params.InputProps, type: 'search' }}
                      />
                    )}
                  />
                </FormControl>
              </Col>
              <Col xs={4}>
                <Box display="flex" height="100%" alignItems="center">
                  <NotificationTitle>days away </NotificationTitle>
                </Box>
              </Col>
            </Row>
          </Col>
          {emailAndDashboard}
        </>
      )}
      {configType === 'configDateAction' && (
        <>
          <Col xs={18}>
            <Row gutter={[16, 16]} display="flex" alignItems="center" height="100%">
              <Col xs={2} xxl={1}>
                <Box display="flex" height="100%" alignItems="center">
                  <NotificationTitle>Date</NotificationTitle>
                </Box>
              </Col>
              <Col xs={12} sm={11} md={10} lg={9} xl={8} xxl={6}>
                <FormControl variant="outlined" style={{ width: '100%' }}>
                  <Autocomplete
                    size="small"
                    freeSolo
                    id="free-solo-2-demo"
                    disableClearable
                    PopperComponent={CustomPopper}
                    value={fields.action}
                    getOptionLabel={(option) => option.description}
                    options={options.actions}
                    onChange={(e, value) => onChangeField('action', value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select an option"
                        margin="normal"
                        variant="outlined"
                        InputProps={{ ...params.InputProps, type: 'search' }}
                      />
                    )}
                  />
                </FormControl>
              </Col>
              <Col xs={1}>
                <Box display="flex" height="100%" justifyContent="center" alignItems="center">
                  <NotificationTitle>is</NotificationTitle>
                </Box>
              </Col>
              <Col md={4} lg={4} xl={3} xxl={3}>
                <FormControl variant="outlined" style={{ width: '100%' }}>
                  <Autocomplete
                    size="small"
                    freeSolo
                    id="free-solo-2-demo"
                    disableClearable
                    PopperComponent={CustomPopper}
                    value={fields.time}
                    options={options.days}
                    onChange={(e, value) => onChangeField('time', value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // label="Select an option"
                        margin="normal"
                        variant="outlined"
                        InputProps={{ ...params.InputProps, type: 'search' }}
                      />
                    )}
                  />
                </FormControl>
              </Col>
              <Col xs={4}>
                <Box display="flex" height="100%" alignItems="center">
                  <NotificationTitle>days passed </NotificationTitle>
                </Box>
              </Col>
            </Row>
          </Col>
          {emailAndDashboard}
        </>
      )}
      {configType === 'configOfficerLevel' && (
        <>
          <Col xs={18}>
            <Row gutter={[16, 16]} display="flex" alignItems="center" height="100%">
              <Col xs={5} lg={4} xxl={3}>
                <Box display="flex" height="100%" alignItems="center">
                  <NotificationTitle>Officer is at EIS</NotificationTitle>
                </Box>
              </Col>
              <Col md={6} lg={5} xl={4} xxl={3}>
                <FormControl variant="outlined" style={{ width: '100%' }}>
                  <Autocomplete
                    size="small"
                    freeSolo
                    id="free-solo-2-demo"
                    disableClearable
                    PopperComponent={CustomPopper}
                    value={fields.level}
                    options={options.levels}
                    getOptionLabel={(value) => `${value[0].toUpperCase()}${value.substr(1).toLowerCase()}`}
                    onChange={(e, value) => onChangeField('level', value.toUpperCase())}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select an option"
                        margin="normal"
                        variant="outlined"
                        InputProps={{ ...params.InputProps, type: 'search' }}
                      />
                    )}
                  />
                </FormControl>
              </Col>
              <Col xs={2}>
                <Box display="flex" height="100%" alignItems="center">
                  <NotificationTitle>level</NotificationTitle>
                </Box>
              </Col>
            </Row>
          </Col>
          {emailAndDashboard}
        </>
      )}
    </>
  );

  return (
    <NotificationItem>
      <Row alignItems="center" gutter={[8, 8]}>
        {rowContent}
        {configIndex > 0 && (
          <Col xs={1}>
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
              <IconButton onClick={onClickDelete}>
                <DeleteIcon />
              </IconButton>
            </Box>
          </Col>
        )}
      </Row>
      <AddMore text="new option" marginLeft="0" marginTop="0" onClick={addNewConfig} />
    </NotificationItem>
  );
};

ConfigRow.propTypes = {
  configType: PropTypes.string.isRequired,
  fields: PropTypes.shape({
    caseType: PropTypes.array,
    assignedTo: PropTypes.array,
    status: PropTypes.string,
    time: PropTypes.string,
    action: PropTypes.string,
    level: PropTypes.string,
  }).isRequired,
  options: PropTypes.shape({
    caseTypes: PropTypes.array.isRequired,
    users: PropTypes.array.isRequired,
    statuses: PropTypes.array,
    days: PropTypes.array,
    actions: PropTypes.array,
    levels: PropTypes.array,
  }).isRequired,
  addNewConfig: PropTypes.func.isRequired,
  email: PropTypes.bool.isRequired,
  dashboard: PropTypes.bool.isRequired,
  onChangeEmail: PropTypes.func.isRequired,
  onChangeDashboard: PropTypes.func.isRequired,
  onChangeField: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  configIndex: PropTypes.number.isRequired,
};

const ProfileConfigNotifications = () => {
  const [caseTypes, setCaseTypes] = useState([]);
  const [users, setUsers] = useState([]);
  const [defaultUsers, setDefaultUsers] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [dates, setDates] = useState([]);

  const [configCreated, setConfigCreated] = useState([
    {
      caseType: [],
      dashboard: false,
      email: false,
    },
  ]);
  const [configAssigned, setConfigAssigned] = useState([
    {
      caseType: [],
      assignedTo: [],
      dashboard: false,
      email: false,
    },
  ]);
  const [configUpdated, setConfigUpdated] = useState([
    {
      caseType: [],
      assignedTo: [],
      dashboard: false,
      email: false,
    },
  ]);
  const [configStatus, setConfigStatus] = useState([
    {
      caseType: [],
      status: 'RECEIVED',
      time: 30,
      dashboard: false,
      email: false,
    },
  ]);
  const [configDueDate, setConfigDueDate] = useState([
    {
      assignedTo: [],
      time: 30,
      dashboard: false,
      email: false,
    },
  ]);
  const [configDateAction, setConfigDateAction] = useState([
    {
      action: '',
      time: 30,
      dashboard: false,
      email: false,
    },
  ]);
  const [configOfficerLevel, setConfigOfficerLevel] = useState([
    {
      level: 'HIGH',
      dashboard: false,
      email: false,
    },
  ]);

  const classesCard = useCardStyles();

  const onGetUsers = async () => {
    try {
      const { data } = await getUsers(null, 1);
      setDefaultUsers(data);
      setUsers(data);
    } catch (error) {
      axiosCatchError(error);
    }
  };

  const handleOnSaveNotificationsConfig = () => {
    userSaveNotificationConfig({
      configCreated: {
        data: configCreated,
      },
      configAssigned: {
        data: configAssigned
          .filter((config) => config?.assignedTo?.length > 0)
          .map((config) => ({
            ...config,
            assignedTo: config.assignedTo?.map((user) => (typeof user === 'object' ? user._id : user) || []),
          })),
      },
      configUpdated: {
        data: configUpdated
          .filter((config) => config?.assignedTo?.length > 0)
          .map((config) => ({
            ...config,
            assignedTo: config.assignedTo?.map((user) => (typeof user === 'object' ? user._id : user) || []),
          })),
      },
      configStatus: {
        data: configStatus.map((config) => ({ ...config, time: parseInt(config.time) })),
      },
      configDueDate: {
        data: configDueDate
          .filter((config) => config?.assignedTo?.length > 0)
          .map((config) => ({
            ...config,
            time: parseInt(config.time || 30),
            assignedTo: config.assignedTo?.map((user) => (typeof user === 'object' ? user._id : user) || []),
          })),
      },
      configDateAction: {
        data: configDateAction
          .filter((config) => config.action)
          .map((config) => {
            let action;

            if (config.action.key === 'task' || config.action.key === 'action') {
              action = config.action.key;
            } else if (
              config.action.key === 'projects-created-date' ||
              config.action.key === 'projects-actions-attachments-created-date' ||
              config.action.key === 'projects-attachments-created-date' ||
              config.action.key === 'projects-notes-attachments-created-date' ||
              config.action.key === 'cases-notes-created-date' ||
              config.action.key === 'cases-notes-updated-date' ||
              config.action.key === 'cases-task-created-date' ||
              config.action.key === 'cases-task-updated-date' ||
              config.action.key === 'users-created-date' ||
              config.action.key === 'users-updated-date' ||
              config.action.key === 'officers-created-date' ||
              config.action.key === 'officers-updated-date' ||
              config.action.key === 'cases-updated-date' ||
              config.action.key === 'cases-created-date' ||
              config.action.key === 'cases-incident-date' ||
              config.action.key === 'cases-last-status-date' ||
              config.action.key === 'cases-new-date-cube'
            ) {
              action = `fixed:${config.action.key}`;
            } else {
              action = `data:${config.action.key}`;
            }

            return {
              ...config,
              action,
              time: parseInt(config.time),
            };
          }),
      },
      configOfficerLevel: {
        data: configOfficerLevel,
      },
    })
      .then(() => {
        toast.success('Configuration saved successfully!');
      })
      .catch((error) => {
        axiosCatchError(error);
      });
  };

  const handleOnChangeConfig = (config, setConfig, configIndex, propName, value) => {
    const _config = [...config];

    _config[configIndex] = {
      ...config[configIndex],
      [propName]: value,
    };

    setConfig(_config);
  };

  const handleOnSearchUsers = (search) => {
    if (search?.length >= 3) {
      getUsersSearch(100, 'name', 'asc', search).then((res) => {
        const _users = res?.data || [];

        setUsers(_users);
      });
    } else {
      setUsers(defaultUsers);
    }
  };

  const handleOnDeleteRow = (config, setConfig, rowIndex) => {
    setConfig(config.filter((item, itemIndex) => itemIndex !== rowIndex));
  };

  useEffect(() => {
    getFilterByCase().then((data) => {
      setStatuses(data.availableStatuses);
      setCaseTypes(data.type.filter((item) => !!item));
    });
    onGetUsers();
    userGetNotificationConfig()
      .then((res) => {
        if (res.data) {
          if (res.data.configCreated && res.data.configCreated.data.length > 0) {
            setConfigCreated(res.data.configCreated.data);
          }

          if (res.data.configAssigned && res.data.configAssigned.data.length > 0) {
            setConfigAssigned(res.data.configAssigned.data);
          }

          if (res.data.configUpdated && res.data.configUpdated.data.length > 0) {
            setConfigUpdated(res.data.configUpdated.data);
          }

          if (res.data.configStatus && res.data.configStatus.data.length > 0) {
            setConfigStatus(res.data.configStatus.data);
          }

          if (res.data.configDueDate && res.data.configDueDate.data.length > 0) {
            setConfigDueDate(res.data.configDueDate.data);
          }

          fetchCubeBucketsDate().then((_res) => {
            setDates(_res.data);

            if (res.data.configDateAction && res.data.configDateAction.data.length > 0) {
              setConfigDateAction(
                res.data.configDateAction.data.map((config) => {
                  if (config.action === 'action') {
                    return { ...config, action: { description: 'Actions', key: 'action' } };
                  }

                  if (config.action === 'task') {
                    return { ...config, action: { description: 'Tasks', key: 'task' } };
                  }

                  if (config.action === 'fixed:projects-created-date') {
                    return {
                      ...config,
                      action: { description: 'Projects Created Date', key: 'projects-created-date' },
                    };
                  }

                  if (config.action === 'fixed:projects-actions-attachments-created-date') {
                    return {
                      ...config,
                      action: {
                        description: 'Projects Actions Attachments Created Date',
                        key: 'projects-actions-attachments-created-date',
                      },
                    };
                  }

                  if (config.action === 'fixed:projects-attachments-created-date') {
                    return {
                      ...config,
                      action: {
                        description: 'Projects Attachments Created Date',
                        key: 'projects-attachments-created-date',
                      },
                    };
                  }

                  if (config.action === 'fixed:projects-notes-attachments-created-date') {
                    return {
                      ...config,
                      action: {
                        description: 'Projects Notes Attachments Created Date',
                        key: 'projects-notes-attachments-created-date',
                      },
                    };
                  }

                  if (config.action === 'fixed:cases-notes-created-date') {
                    return {
                      ...config,
                      action: { description: 'Cases Notes Created Date', key: 'cases-notes-created-date' },
                    };
                  }

                  if (config.action === 'fixed:cases-notes-updated-date') {
                    return {
                      ...config,
                      action: { description: 'Cases Notes Updated Date', key: 'cases-notes-updated-date' },
                    };
                  }

                  if (config.action === 'fixed:cases-task-created-date') {
                    return {
                      ...config,
                      action: { description: 'Cases Task Created Date', key: 'cases-task-created-date' },
                    };
                  }

                  if (config.action === 'fixed:cases-task-updated-date') {
                    return {
                      ...config,
                      action: { description: 'Cases Task Updated Date', key: 'cases-task-updated-date' },
                    };
                  }

                  if (config.action === 'fixed:users-created-date') {
                    return {
                      ...config,
                      action: { description: 'Users - Created Date', key: 'users-created-date' },
                    };
                  }

                  if (config.action === 'fixed:users-updated-date') {
                    return {
                      ...config,
                      action: { description: 'Users - Updated Date', key: 'users-updated-date' },
                    };
                  }

                  if (config.action === 'fixed:officers-created-date') {
                    return {
                      ...config,
                      action: { description: 'Officers - Created Date', key: 'officers-created-date' },
                    };
                  }

                  if (config.action === 'fixed:officers-updated-date') {
                    return {
                      ...config,
                      action: { description: 'Officers - Updated Date', key: 'officers-updated-date' },
                    };
                  }

                  if (config.action === 'fixed:cases-updated-date') {
                    return {
                      ...config,
                      action: { description: 'Cases - Updated Date', key: 'cases-updated-date' },
                    };
                  }

                  if (config.action === 'fixed:cases-created-date') {
                    return {
                      ...config,
                      action: { description: 'Cases - Created Date', key: 'cases-created-date' },
                    };
                  }

                  if (config.action === 'fixed:cases-incident-date') {
                    return {
                      ...config,
                      action: { description: 'Cases - Incident Date', key: 'cases-incident-date' },
                    };
                  }

                  if (config.action === 'fixed:cases-last-status-date') {
                    return {
                      ...config,
                      action: { description: 'Cases - Last Status Date', key: 'cases-last-status-date' },
                    };
                  }

                  if (config.action === 'fixed:cases-new-date-cube') {
                    return {
                      ...config,
                      action: { description: 'Cases - New date cube', key: 'cases-new-date-cube' },
                    };
                  }

                  const dataKey = config?.action?.split('data:');
                  const key = dataKey?.length > 0 ? dataKey[1] : undefined;
                  const action = key ? _res.data.find((data) => data.key === key) : undefined;

                  return {
                    ...config,
                    action,
                  };
                })
              );
            }
          });

          if (res.data.configOfficerLevel && res.data.configOfficerLevel.data.length > 0) {
            setConfigOfficerLevel(res.data.configOfficerLevel.data);
          }
        }
      })
      .catch((error) => {
        axiosCatchError(error);
      });
  }, []);

  return (
    <Box width="100%" style={{ background: '#fff' }} marginTop="40px">
      <Card
        title={
          <Row style={{ width: '100%', height: 44 }} alignItems="center">
            <Col xs={18}>
              <Box display="flex" alignItems="center" height="100%">
                <Typography className={classesCard.title} variant="body2">
                  Notifications
                </Typography>
              </Box>
            </Col>
            <Col xs={2}>
              <Box display="flex" alignItems="center" height="100%">
                <Typography className={classesCard.title} variant="body2">
                  Email
                </Typography>
              </Box>
            </Col>
            <Col xs={2}>
              <Box display="flex" alignItems="center" height="100%">
                <Typography className={classesCard.title} variant="body2">
                  Dashboard
                </Typography>
              </Box>
            </Col>
            <Col xs={2}>
              <Box display="flex" alignItems="center" height="100%">
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  style={{ height: 30 }}
                  onClick={handleOnSaveNotificationsConfig}
                >
                  Save
                </Button>
              </Box>
            </Col>
          </Row>
        }
      >
        {configCreated.map((config, configIndex) => (
          <ConfigRow
            configIndex={configIndex}
            onClickDelete={() => handleOnDeleteRow(configCreated, setConfigCreated, configIndex)}
            key={configIndex}
            configType="configCreated"
            options={{ caseTypes }}
            fields={{ caseType: config.caseType }}
            email={config.email}
            dashboard={config.dashboard}
            onChangeEmail={(value) =>
              handleOnChangeConfig(configCreated, setConfigCreated, configIndex, 'email', value)
            }
            onChangeDashboard={(value) =>
              handleOnChangeConfig(configCreated, setConfigCreated, configIndex, 'dashboard', value)
            }
            onChangeField={(field, value) =>
              handleOnChangeConfig(configCreated, setConfigCreated, configIndex, field, value)
            }
            addNewConfig={() =>
              setConfigCreated([
                ...configCreated,
                {
                  caseType: [],
                  dashboard: false,
                  email: false,
                },
              ])
            }
          />
        ))}
        {configAssigned.map((config, configIndex) => (
          <ConfigRow
            configIndex={configIndex}
            onClickDelete={() => handleOnDeleteRow(configAssigned, setConfigAssigned, configIndex)}
            key={configIndex}
            configType="configAssigned"
            options={{ caseTypes, users }}
            fields={{ caseType: config.caseType, assignedTo: config.assignedTo }}
            email={config.email}
            dashboard={config.dashboard}
            onChangeEmail={(value) =>
              handleOnChangeConfig(configAssigned, setConfigAssigned, configIndex, 'email', value)
            }
            onChangeDashboard={(value) =>
              handleOnChangeConfig(configAssigned, setConfigAssigned, configIndex, 'dashboard', value)
            }
            onChangeField={(field, value) =>
              handleOnChangeConfig(configAssigned, setConfigAssigned, configIndex, field, value)
            }
            addNewConfig={() =>
              setConfigAssigned([
                ...configAssigned,
                {
                  caseType: [],
                  assignedTo: [],
                  email: false,
                  dashboard: false,
                },
              ])
            }
            onSearch={(field, value) => {
              if (field === 'assignedTo') {
                handleOnSearchUsers(value);
              }
            }}
          />
        ))}
        {configUpdated.map((config, configIndex) => (
          <ConfigRow
            configIndex={configIndex}
            onClickDelete={() => handleOnDeleteRow(configUpdated, setConfigUpdated, configIndex)}
            key={configIndex}
            configType="configUpdated"
            options={{ caseTypes, users }}
            fields={{ caseType: config.caseType, assignedTo: config.assignedTo }}
            email={config.email}
            dashboard={config.dashboard}
            onChangeEmail={(value) =>
              handleOnChangeConfig(configUpdated, setConfigUpdated, configIndex, 'email', value)
            }
            onChangeDashboard={(value) =>
              handleOnChangeConfig(configUpdated, setConfigUpdated, configIndex, 'dashboard', value)
            }
            onChangeField={(field, value) =>
              handleOnChangeConfig(configUpdated, setConfigUpdated, configIndex, field, value)
            }
            addNewConfig={() =>
              setConfigUpdated([
                ...configUpdated,
                {
                  caseType: [],
                  assignedTo: [],
                  email: false,
                  dashboard: false,
                },
              ])
            }
            onSearch={(field, value) => {
              if (field === 'assignedTo') {
                handleOnSearchUsers(value);
              }
            }}
          />
        ))}
        {configStatus.map((config, configIndex) => (
          <ConfigRow
            configIndex={configIndex}
            onClickDelete={() => handleOnDeleteRow(configStatus, setConfigStatus, configIndex)}
            key={configIndex}
            configType="configStatus"
            options={{ caseTypes, days: NOTIFICATIONS.DAYS, statuses }}
            fields={{ caseType: config.caseType, time: config.time.toString(), status: config.status }}
            email={config.email}
            dashboard={config.dashboard}
            onChangeEmail={(value) => handleOnChangeConfig(configStatus, setConfigStatus, configIndex, 'email', value)}
            onChangeDashboard={(value) =>
              handleOnChangeConfig(configStatus, setConfigStatus, configIndex, 'dashboard', value)
            }
            onChangeField={(field, value) =>
              handleOnChangeConfig(configStatus, setConfigStatus, configIndex, field, value)
            }
            addNewConfig={() =>
              setConfigStatus([
                ...configStatus,
                {
                  caseType: [],
                  status: 'RECEIVED',
                  time: 30,
                  email: false,
                  dashboard: false,
                },
              ])
            }
          />
        ))}
        {configDueDate.map((config, configIndex) => (
          <ConfigRow
            configIndex={configIndex}
            onClickDelete={() => handleOnDeleteRow(configDueDate, setConfigDueDate, configIndex)}
            key={configIndex}
            configType="configDueDate"
            options={{ days: NOTIFICATIONS.DAYS, users }}
            fields={{ time: config.time.toString(), assignedTo: config.assignedTo }}
            email={config.email}
            dashboard={config.dashboard}
            onChangeEmail={(value) =>
              handleOnChangeConfig(configDueDate, setConfigDueDate, configIndex, 'email', value)
            }
            onChangeDashboard={(value) =>
              handleOnChangeConfig(configDueDate, setConfigDueDate, configIndex, 'dashboard', value)
            }
            onChangeField={(field, value) =>
              handleOnChangeConfig(configDueDate, setConfigDueDate, configIndex, field, value)
            }
            addNewConfig={() =>
              setConfigDueDate([
                ...configDueDate,
                {
                  assignedTo: [],
                  time: 30,
                  email: false,
                  dashboard: false,
                },
              ])
            }
            onSearch={(field, value) => {
              if (field === 'assignedTo') {
                handleOnSearchUsers(value);
              }
            }}
          />
        ))}
        {configDateAction.map((config, configIndex) => (
          <ConfigRow
            configIndex={configIndex}
            onClickDelete={() => handleOnDeleteRow(configDateAction, setConfigDateAction, configIndex)}
            key={configIndex}
            configType="configDateAction"
            options={{
              actions: [
                { description: 'Actions', key: 'action' },
                { description: 'Tasks', key: 'task' },
                { description: 'Projects Created Date', key: 'projects-created-date' },
                {
                  description: 'Projects Actions Attachments Created Date',
                  key: 'projects-actions-attachments-created-date',
                },
                { description: 'Projects Attachments Created Date', key: 'projects-attachments-created-date' },
                {
                  description: 'Projects Notes Attachments Created Date',
                  key: 'projects-notes-attachments-created-date',
                },
                { description: 'Cases Notes Created Date', key: 'cases-notes-created-date' },
                { description: 'Cases Notes Updated Date', key: 'cases-notes-updated-date' },
                { description: 'Cases Task Created Date', key: 'cases-task-created-date' },
                { description: 'Cases Task Updated Date', key: 'cases-task-updated-date' },
                { description: 'Users - Created Date', key: 'users-created-date' },
                { description: 'Users - Updated Date', key: 'users-updated-date' },
                { description: 'Officers - Created Date', key: 'officers-created-date' },
                { description: 'Officers - Updated Date', key: 'officers-updated-date' },
                { description: 'Cases - Updated Date', key: 'cases-updated-date' },
                { description: 'Cases - Created Date', key: 'cases-created-date' },
                { description: 'Cases - Incident Date', key: 'cases-incident-date' },
                { description: 'Cases - Last Status Date', key: 'cases-last-status-date' },
                { description: 'Cases - New date cube', key: 'cases-new-date-cube' },
                ...dates,
              ],
              days: NOTIFICATIONS.DAYS,
            }}
            fields={{ action: config.action, time: config.time.toString() }}
            email={config.email}
            dashboard={config.dashboard}
            onChangeEmail={(value) =>
              handleOnChangeConfig(configDateAction, setConfigDateAction, configIndex, 'email', value)
            }
            onChangeDashboard={(value) =>
              handleOnChangeConfig(configDateAction, setConfigDateAction, configIndex, 'dashboard', value)
            }
            onChangeField={(field, value) =>
              handleOnChangeConfig(configDateAction, setConfigDateAction, configIndex, field, value)
            }
            addNewConfig={() =>
              setConfigDateAction([
                ...configDateAction,
                {
                  action: '',
                  time: 30,
                  email: false,
                  dashboard: false,
                },
              ])
            }
          />
        ))}
        {configOfficerLevel.map((config, configIndex) => (
          <ConfigRow
            configIndex={configIndex}
            onClickDelete={() => handleOnDeleteRow(configOfficerLevel, setConfigOfficerLevel, configIndex)}
            key={configIndex}
            configType="configOfficerLevel"
            options={{ levels: Object.keys(EIS.RISK_LEVEL) }}
            fields={{ level: config.level }}
            email={config.email}
            dashboard={config.dashboard}
            onChangeEmail={(value) =>
              handleOnChangeConfig(configOfficerLevel, setConfigOfficerLevel, configIndex, 'email', value)
            }
            onChangeDashboard={(value) =>
              handleOnChangeConfig(configOfficerLevel, setConfigOfficerLevel, configIndex, 'dashboard', value)
            }
            onChangeField={(field, value) =>
              handleOnChangeConfig(configOfficerLevel, setConfigOfficerLevel, configIndex, field, value)
            }
            addNewConfig={() =>
              setConfigOfficerLevel([
                ...configOfficerLevel,
                {
                  level: 'HIGH',
                  email: false,
                  dashboard: false,
                },
              ])
            }
          />
        ))}
      </Card>
    </Box>
  );
};

const NotificationTitle = styled.span`
  font-size: 16px;
  overflow: hidden;
  white-space: nowrap;
`;

const NotificationItem = styled.div`
  padding-bottom: 8px;
  border-bottom: 1px solid #e2e2e2;
`;

export default ProfileConfigNotifications;
