import { Box, makeStyles, Typography } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import PopperPortal from './PopperPortal';

const useStyles = makeStyles({
  item: {
    height: 45,
    background: '#fff',
    borderRadius: 4,
    border: '1px solid #7070701F',
    padding: '15px 16px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    textTransform: 'capitalize',
  },
  label: {
    fontSize: 14,
    color: '#00000061',
  },

  seletedItem: {
    color: '#000000DE',
    fontSize: 14,
    fontWeight: '400',
  },
  img: {
    width: 16,
    height: 16,
    marginRight: 5,
  },
  hoverItem: {
    cursor: 'pointer',
    borderRadius: 4,
    '& :hover': {
      transition: 'ease .3s all',
      background: '#f2f2f2',
    },
  },
});

export default function DropdownSelectableItem({
  options,
  value,
  setItems,
  widthItem,
}) {
  const [open, setOpen] = useState(false);

  const classes = useStyles();

  const ref = useRef(null);

  const handleClick = (item) => {
    setItems(item);
    setOpen(false);
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: '10px',
        position: 'relative',
        width: widthItem,
      }}
      ref={ref}
    >
      <Box className={classes.item} onClick={() => setOpen(true)}>
        {value}
      </Box>
      <PopperPortal open={open} setOpen={setOpen} left={0}>
        <Box
          width={ref.current?.offsetWidth}
          height={200}
          style={{ background: '#fff', zIndex: 99, overflowY: 'auto' }}
        >
          <Box padding="7px 12px">
            {options.map((item, index) => (
              <Box
                className={classes.hoverItem}
                key={index}
                onClick={() => handleClick(item)}
              >
                <Box display="flex" alignItems="center" padding="10px">
                  <Typography
                    style={{ fontSize: 14, textTransform: 'capitalize' }}
                  >
                    {item}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </PopperPortal>
    </div>
  );
}
