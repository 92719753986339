/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import { Box, makeStyles, Tabs, FormControlLabel, Switch, CircularProgress, withStyles } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import DeleteIcon from '@material-ui/icons/Delete';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import { blue } from '@material-ui/core/colors';
import { useDrag } from 'react-dnd';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { COMPONENT } from '../utils/constants';
import TabPanel from '../../components/TabPanel';
import { TabComp } from '../../pages/CaseDetails/CaseDetails';
import { useDataReportLinkProvider } from '../../provider/DataReportLinkProvider';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    borderRadius: 5,
    border: '1px solid #2E66FE33',
  },
  header: {
    background: '#fff',
    borderRadius: '5px 5px 0 0',
    borderBottom: '1px solid #2E66FE1A',
  },
  body: {
    background: '#F9FAFD80',
    borderRadius: 5,
  },
  button: {
    background: '#1F63FF',
    textTransform: 'capitalize',
    cursor: 'pointer',
    minWidth: 36,
    boxShadow: 'unset',
    color: '#fff',
    padding: '6px 16px',
    '&:hover': {
      background: '#1F63FF',
      boxShadow: 'unset',
    },
  },
  dndContainer: {
    display: 'flex',
    alignItems: 'center',
    transition: 'ease all .3s',
  },
}));

export function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export const CustomSwitch = withStyles({
  switchBase: {
    '&$checked': {
      color: blue.A700,
    },
    '&$checked + $track': {
      backgroundColor: blue.A700,
    },
  },
  checked: {},
  track: {},
})(Switch);

/**
 * WrapperComponent - A component that wraps other components and provides various rendering and functionality options.
 * @param {Object} props - The props object containing various configuration options.
 * @param {ReactNode} props.children - The child components to be wrapped.
 * @param {Object|null} props.renderSettings - The settings for rendering.
 * @param {Function} props.renderPreview - The function to render preview. Defaults to a function returning 'Preview'.
 * @param {boolean} props.includeRequired - Flag indicating whether required fields should be included.
 * @param {Object} props.required - The required field object containing value and onChange function.
 * @param {string} props.id - The id of the component.
 * @param {Function} props.onDeleteComponent - Function to handle deletion of the component.
 * @param {string} props.path - The path of the component.
 * @param {boolean} props.loading - Flag indicating whether component is in loading state.
 * @param {boolean} props.isQuestionBankComponent - Flag indicating whether the component is a question bank.
 * @param {boolean} props.containsQuestionBanks - Flag indicating whether the component contains question banks.
 * @param {boolean} props.duplicateComponent - Flag indicating whether the component is a duplicate.
 * @param {number} props.componentIndex - The index of the component.
 * @param {boolean} props.allowDnD - Flag indicating whether drag and drop is allowed.
 * @param {boolean} props.visible - Flag indicating whether the component is visible.
 * @param {boolean} props.isStaticComponent - Flag indicating whether the component is static.
 * @param {boolean} props.dataSaved - Flag indicating whether the data is saved.
 * @param {Function} props.refActionModal - Reference function for action modal.
 * @param {Function} props.setModal - Function to set modal.
 * @param {boolean} props.isDemographicComponent - Flag indicating whether the component is demographic.
 * @param {Function} props.onBlur - Function to handle blur event.
 * @param {boolean} props.shouldRenderToggle - Flag indicating whether toggle should be rendered.
 * @param {boolean} props.allowMoveComponents - Flag indicating whether moving components is allowed.
 */
export default function WrapperComponent({
  children,
  renderSettings = null,
  renderPreview = () => 'Preview',
  includeRequired = true,
  required = { value: '', onChange: () => {} },
  id,
  componentId,
  onDeleteComponent,
  path,
  loading,
  isQuestionBankComponent,
  containsQuestionBanks,
  duplicateComponent,
  componentIndex,
  allowDnD = true,
  visible,
  isStaticComponent,
  dataSaved,
  refActionModal,
  setModal,
  isDemographicComponent,
  onBlur = () => {},
  shouldRenderToggle,
  allowMoveComponents = true,
  withDataReportLinkSettings,
}) {
  const classes = useStyles();
  const [value, setValue] = useState(
    dataSaved && !isQuestionBankComponent ? (typeof renderSettings === 'function' ? 2 : 1) : 0
  );
  const ref = useRef(null);
  const [isHover, setIsHover] = useState(false);

  const { setComponentsChanged } = useDataReportLinkProvider();

  const deleteFromDataReportLink = () => {
    setComponentsChanged((components) => ({
      ...components,
      [componentId]: undefined,
    }));
  };

  const [{ isDragging }, drag] = useDrag({
    item: { type: COMPONENT, id, path, isCustomComponent: allowMoveComponents },
    type: COMPONENT,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  if (!isQuestionBankComponent && allowDnD) {
    drag(ref);
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div
      ref={ref}
      style={{ opacity, paddingTop: isQuestionBankComponent ? 20 : 0 }}
      className={classes.dndContainer}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => {
        if (!isDragging) {
          onBlur();
          setIsHover(false);
        }
      }}
    >
      {!isQuestionBankComponent && !isDemographicComponent && (
        <DragIndicatorIcon
          htmlColor="#6D7385"
          style={{
            marginRight: 7,
            cursor: isDragging ? 'grabbing' : 'grab',
            opacity: isHover ? 1 : 0,
            transition: 'ease all .3s',
          }}
        />
      )}
      <Box style={{ padding: '0 20px', width: '100%' }}>
        <Box className={classes.container}>
          <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.header}>
            <Box>
              {containsQuestionBanks && !isQuestionBankComponent && (
                <>
                  {withDataReportLinkSettings ? (
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="simple tabs example"
                      indicatorColor="primary"
                      textColor="primary"
                    >
                      <TabComp label="Edit" {...a11yProps(0)} />
                      <TabComp label="Settings" {...a11yProps(1)} />
                    </Tabs>
                  ) : (
                    <Box height={48} />
                  )}
                </>
              )}
              {isQuestionBankComponent && (
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="simple tabs example"
                  indicatorColor="primary"
                  textColor="primary"
                >
                  {!allowDnD && <TabComp label="Edit" {...a11yProps(0)} style={{ padding: '0 20px' }} />}
                  <TabComp label="Preview" {...a11yProps(!allowDnD ? 1 : 0)} />
                </Tabs>
              )}
              {!containsQuestionBanks && !isQuestionBankComponent && (
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="simple tabs example"
                  indicatorColor="primary"
                  textColor="primary"
                >
                  <TabComp label="Edit" {...a11yProps(0)} style={{ padding: '0 20px' }} />
                  {typeof renderSettings === 'function' && <TabComp label="Settings" {...a11yProps(1)} />}
                  <TabComp label="Preview" {...a11yProps(typeof renderSettings === 'function' ? 2 : 1)} />
                </Tabs>
              )}
            </Box>
            <Box display="flex" alignItems="center" style={{ paddingRight: 30 }}>
              {containsQuestionBanks && !isQuestionBankComponent && !isDemographicComponent && (
                <DeleteIcon
                  htmlColor="#6D7385"
                  style={{ marginLeft: 15, cursor: 'pointer' }}
                  onClick={() => {
                    setModal(true);
                    refActionModal.current = {
                      nameAction: 'question',
                      actionPress: () => {
                        deleteFromDataReportLink();
                        onDeleteComponent();
                      },
                    };
                  }}
                />
              )}
              {!containsQuestionBanks && !isQuestionBankComponent && !isDemographicComponent && (
                <>
                  <FilterNoneIcon
                    onClick={() => duplicateComponent(parseInt(path), componentIndex)}
                    htmlColor="#6D7385"
                    style={{
                      cursor: 'pointer',
                    }}
                    fontSize="small"
                  />
                  <DeleteIcon
                    htmlColor="#6D7385"
                    style={{ marginLeft: 15, cursor: 'pointer' }}
                    onClick={() => {
                      setModal(true);
                      refActionModal.current = {
                        nameAction: 'question',
                        actionPress: () => {
                          deleteFromDataReportLink();
                          onDeleteComponent();
                        },
                      };
                    }}
                  />
                </>
              )}
              {isStaticComponent && <CustomSwitch checked={visible.value} onChange={visible.onChange} />}
              {shouldRenderToggle && <CustomSwitch checked={visible.value} onChange={visible.onChange} />}
            </Box>
          </Box>
          <Box className={classes.body}>
            {containsQuestionBanks && !isQuestionBankComponent && (
              <>
                {withDataReportLinkSettings ? (
                  <>
                    <TabPanel value={value} index={0} marginBottom={0}>
                      {children}
                    </TabPanel>
                    {typeof renderSettings === 'function' && (
                      <TabPanel value={value} index={1} marginBottom={0}>
                        {renderSettings()}
                      </TabPanel>
                    )}
                  </>
                ) : (
                  children
                )}
              </>
            )}
            {isQuestionBankComponent && (
              <>
                {!allowDnD && (
                  <TabPanel value={value} index={0} marginBottom={0}>
                    {children}
                    <Box display="flex" alignItems="center" justifyContent="space-between" paddingTop="20px">
                      {includeRequired ? (
                        <FormControlLabel
                          style={{ margin: 0 }}
                          control={<CustomSwitch checked={required.value} onChange={required.onChange} />}
                          labelPlacement="start"
                          label="Required Question"
                        />
                      ) : (
                        <Box />
                      )}
                      <Box height={20}>{loading && <CircularProgress color="#1f63ff" size="15px" />}</Box>
                    </Box>
                  </TabPanel>
                )}
                <TabPanel value={value} index={!allowDnD ? 1 : 0} marginBottom={0}>
                  {renderPreview()}
                </TabPanel>
              </>
            )}
            {!containsQuestionBanks && !isQuestionBankComponent && (
              <>
                <TabPanel value={value} index={0} marginBottom={0}>
                  {children}
                  <Box display="flex" alignItems="center" justifyContent="space-between" paddingTop="20px">
                    {includeRequired ? (
                      <FormControlLabel
                        style={{ margin: 0 }}
                        control={<CustomSwitch checked={required.value} onChange={required.onChange} />}
                        labelPlacement="start"
                        label="Required Question"
                      />
                    ) : (
                      <Box />
                    )}
                    <Box height={20}>{loading && <CircularProgress style={{ color: '#1f63ff' }} size="15px" />}</Box>
                  </Box>
                </TabPanel>
                {typeof renderSettings === 'function' && (
                  <TabPanel value={value} index={1} marginBottom={0}>
                    {renderSettings()}
                  </TabPanel>
                )}
                <TabPanel value={value} index={typeof renderSettings === 'function' ? 2 : 1} marginBottom={0}>
                  {renderPreview()}
                </TabPanel>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </div>
  );
}

WrapperComponent.propTypes = {
  children: PropTypes.node.isRequired,
  renderSettings: PropTypes.object,
  renderPreview: PropTypes.func,
  includeRequired: PropTypes.bool,
  required: PropTypes.shape({
    value: PropTypes.string,
    onChange: PropTypes.func,
  }),
  id: PropTypes.string.isRequired,
  componentId: PropTypes.string.isRequired,
  onDeleteComponent: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  isQuestionBankComponent: PropTypes.bool.isRequired,
  containsQuestionBanks: PropTypes.bool.isRequired,
  duplicateComponent: PropTypes.bool.isRequired,
  componentIndex: PropTypes.number.isRequired,
  allowDnD: PropTypes.bool,
  visible: PropTypes.bool.isRequired,
  isStaticComponent: PropTypes.bool.isRequired,
  dataSaved: PropTypes.bool.isRequired,
  refActionModal: PropTypes.func.isRequired,
  setModal: PropTypes.func.isRequired,
  isDemographicComponent: PropTypes.bool.isRequired,
  onBlur: PropTypes.func,
  shouldRenderToggle: PropTypes.bool,
  allowMoveComponents: PropTypes.bool,
  withDataReportLinkSettings: PropTypes.bool,
};
