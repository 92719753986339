import React, { useState } from 'react';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import {
  Box,
  Button,
  CircularProgress,
  ListItemIcon,
  MenuItem,
  Typography,
} from '@material-ui/core';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import PopperPortal from '../../components/PopperPortal';
import { createFormTypes } from '../../services/unarmed';
import { toast } from 'react-toastify';

export default function RenderTableOptions({ id, refreshCallBack }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const onDuplicate = async () => {
    setLoading(true);
    try {
      await createFormTypes({ copyFrom: id });
      await refreshCallBack();
      setOpen(false);
      setLoading(false);
      toast.success('Form Type was successfully duplicated');
    } catch (error) {
      setLoading(false);
      toast.success('Something went wrong when duplicate form type');
    }
  };

  return (
    <Box position="relative">
      <Button onClick={() => setOpen(true)}>
        <MoreHorizIcon />
      </Button>
      <PopperPortal open={open} setOpen={setOpen} left={-155} top={-9}>
        {loading ? (
          <CircularProgress color="#333" />
        ) : (
          <MenuItem onClick={onDuplicate}>
            <ListItemIcon>
              <FilterNoneIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit" noWrap>
              Duplicate
            </Typography>
          </MenuItem>
        )}
      </PopperPortal>
    </Box>
  );
}
