import { Box, Typography, Button, CircularProgress, makeStyles } from '@material-ui/core';

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { deleteFormTypes } from '../../services/unarmed';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 20,
  },
  title: {
    marginTop: 50,
  },
  button: {
    background: ' #1F63FF',
    textTransform: 'capitalize',
    marginRight: 10,
    color: '#fff',
    height: 36,
    width: 30,
    '&:hover': {
      background: '#1F63FF',
    },
    '& .MuiButton-label': {
      fontSize: 13,
    },
    '&:disabled': {
      background: '#1f63ff61',
      color: '#fff',
    },
  },
  button2: {
    background: ' #1F63FF',
    textTransform: 'capitalize',
    color: '#fff',
    '&:hover': {
      background: '#1F63FF',
    },
    '& .MuiButton-label': {
      fontSize: 14,
    },
  },
  buttonOutlined: {
    letterSpacing: '1px',
    fontSize: 13,
    fontWeight: '500',
    marginRight: 10,
    width: 'fit-content',
    alignSelf: 'flex-end',
    border: '1px solid transparent',
    color: '#4b7bff',
    '&:hover': {
      background: 'transparent',
      border: '1px solid transparent',
    },
    '& .MuiButton-label': {
      fontSize: 13,
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: '#fff',
    boxShadow: theme.shadows[5],
    padding: 30,
    maxWidth: 600,
    minWidth: 400,
    outline: 0,
  },
}));

/**
 * DeleteForms component to confirm and delete selected form types.
 *
 * @component
 * @example
 * const selected = [1, 2, 3];
 * const setOpen = (open) => {};
 * const setSelected = (selected) => {};
 * const refreshCallBack = () => {};
 * return (
 *   <DeleteForms selected={selected} setOpen={setOpen} setSelected={setSelected} refreshCallBack={refreshCallBack} />
 * );
 *
 * @param {Object} props - The component props.
 * @param {Array.<number>} props.selected - Array of selected form type IDs to delete.
 * @param {function} props.setOpen - Function to control the open state of the parent modal/dialog.
 * @param {function} props.setSelected - Function to update the selected form types.
 * @param {function} props.refreshCallBack - Callback function to refresh the form types list.
 */
export default function DeleteForms({ selected, setOpen, setSelected, refreshCallBack }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const onDeleteFormTypes = async () => {
    let ids = '';
    for (const id of selected) {
      const index = selected.indexOf(id);
      if (index + 1 === selected.length) {
        ids += `ids[]=${id}`;
      } else {
        ids += `ids[]=${id}&`;
      }
    }

    try {
      setLoading(true);

      await deleteFormTypes(ids);
      await refreshCallBack();

      setLoading(false);
      setSelected([]);
      setOpen(false);
      toast.success('Form Type(s) deleted Successfully');
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setLoading(false);
    }
  };

  return (
    <div className={classes.paper}>
      <Typography style={{ fontSize: 20, fontWeight: '500' }}>Are you sure you want to delete?</Typography>
      <Typography variant="body2">This action can’t be undone!</Typography>
      <Box display="flex" alignItems="center" justifyContent="flex-end" marginTop="20px">
        <Button
          data-testid="delete-forms-button-cancel"
          variant="outlined"
          className={classes.buttonOutlined}
          onClick={() => setOpen(false)}
        >
          CANCEL
        </Button>
        <Button
          data-testid="delete-forms-button-delete"
          variant="outlined"
          className={classes.buttonOutlined}
          onClick={onDeleteFormTypes}
        >
          {loading ? <CircularProgress color="#fff" size="25px" /> : 'DELETE'}
        </Button>
      </Box>
    </div>
  );
}

DeleteForms.propTypes = {
  selected: PropTypes.arrayOf(PropTypes.number).isRequired,
  setOpen: PropTypes.func.isRequired,
  setSelected: PropTypes.func.isRequired,
  refreshCallBack: PropTypes.func.isRequired,
};
