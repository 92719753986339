import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Backdrop, Modal, Typography, makeStyles, Fade, Button, Box, CircularProgress } from '@material-ui/core';
import BackupIcon from '@material-ui/icons/Backup';
import styled from 'styled-components';

import { axiosCatchError, fileUploadAceptedTypes } from '../utils';
import Attachment from './Attachment';
import { uploadFormImg } from '../services/unarmed';

const DropZoneContainer = styled.div`
  width: 100%;
  margin-bottom: 16px;

  .MuiDropzoneArea-root {
    min-height: unset !important;
  }
`;

const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: theme.shadows[5],
    padding: '30px 50px',
    minWidth: 695,
    alignSelf: 'center',
    borderRadius: 4,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dropzoneBox: {
    background: '#4762FA08',
    borderRadius: 4,
    border: '1px dashed #364F7431',
    padding: 34,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: 16,
    cursor: 'pointer',
    width: '100%',
  },
}));

/**
 * Component for uploading files in a modal.
 * @param {object} props - The component props.
 * @param {boolean} props.open - Determines if the modal is open or closed.
 * @param {function} props.onClose - Callback function to handle the modal close event.
 * @param {string} props.organizationId - The ID of the organization.
 * @param {function} props.onConfirm - Callback function to handle the upload confirmation event.
 * @returns {JSX.Element} - The ModalUploadFile component UI.
 */
const ModalUploadFile = ({ open, onClose, organizationId, onConfirm }) => {
  /**
   * Hook to manage the selected files.
   */
  const [files, setFiles] = useState([]);

  /**
   * Hook to manage the file tags.
   */
  const [fileTags, setFileTags] = useState([]);

  /**
   * Hook to manage the loading state.
   */
  const [loading, setLoading] = useState(false);

  /**
   * CSS styles for the component.
   */
  const classes = useStyles();

  /**
   * Handles the upload of a single file.
   * @param {File} file - The file to upload.
   * @param {number} fileKey - The key/index of the file in the files array.
   * @returns {Promise} - A promise that resolves with the uploaded file information.
   */
  const onUploadFile = (file, fileKey) =>
    new Promise((resolve) => {
      const formData = new FormData();

      formData.append('file', file);
      formData.append('organizationId', organizationId);

      uploadFormImg(formData).then((fileResponse) => {
        resolve({ file: fileResponse, fileKey, path: file.name });
      });
    });

  /**
   * Handles the upload of multiple files.
   * @param {Array} _files - The files to upload.
   * @returns {Promise} - A promise that resolves with the uploaded files information.
   */
  const handleOnUploadFiles = useCallback(
    async (_files) => {
      const requests = [];
      const newFiles = [];

      setLoading(true);

      _files.forEach((_file, _fileKey) => {
        requests.push(onUploadFile(_file.file, _fileKey));
      });

      try {
        const responses = await Promise.all(requests);

        responses.forEach(({ file, path }) => {
          const { _id, publicUrl, previewUrl, name, size, hashId, mimetype } = file;
          const fileData = fileTags.find((_file) => path === _file?.path);
          let tags = [];

          if (fileData) {
            tags = fileData.tags;
          }

          const newFile = {
            _id,
            name,
            size,
            hashId,
            mimetype,
            url: publicUrl,
            public_url: publicUrl,
            preview_url: previewUrl,
            tags,
          };

          newFiles.push(newFile);
        });
      } catch (error) {
        axiosCatchError(error);
      }

      setLoading(false);

      return newFiles;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fileTags]
  );

  /**
   * Handles the click event of the Done button.
   * @param {Event} e - The click event object.
   * @returns {Promise<void>} - A promise that resolves after handling the click event.
   */
  const handleOnClickDone = useCallback(
    async (e) => {
      if (files.length > 0) {
        const newFiles = await handleOnUploadFiles(files);

        onConfirm(e, newFiles);
        setFiles([]);
      }
    },
    [files, handleOnUploadFiles, onConfirm]
  );

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles((_files) => [
      ..._files,
      ...selectedFiles.map((file) => ({ file, path: file.name, preview: URL.createObjectURL(file) })),
    ]);
    setFileTags((fileTags) =>
      selectedFiles.map((file) => {
        const defaultTags = fileTags.find((fileTag) => fileTag.path === file.name);
        return {
          path: file.name,
          tags: defaultTags ? defaultTags.tags : [],
        };
      })
    );
  };

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Paper className={classes.paper} style={{ backgroundColor: '#fff' }}>
          <Typography variant="h6" style={{ fontSize: 20, fontWeight: 'bold', marginBottom: 30 }}>
            Upload file
          </Typography>
          <DropZoneContainer>
            <div className={classes.dropzoneBox}>
              <BackupIcon style={{ fontSize: 64 }} htmlColor="#A2BAFF" />
              <Typography>Drag files here or browse</Typography>
              <input
                type="file"
                accept={fileUploadAceptedTypes.join(',')}
                multiple
                onChange={handleFileChange}
                style={{ display: 'none' }}
                id="file-upload"
              />
              <label htmlFor="file-upload">
                <Button component="span" color="primary">
                  Browse Files
                </Button>
              </label>
            </div>
          </DropZoneContainer>
          <div>
            {files.map((fileWrapper, index) => {
              const { file, preview } = fileWrapper;
              const fileData = fileTags.find((fileTag) => fileTag.path === file.name);

              return (
                <Attachment
                  key={fileWrapper.path}
                  rightCreateTag={0}
                  positionCreateTag="bottom"
                  sizeCreateTag="small"
                  defaultTags={fileData?.tags || []}
                  name={file.name}
                  size={file.size || 0}
                  url={preview}
                  mimetype={file.type || ''}
                  withAddBtn
                  canRemoveTag
                  onClickAddTag={(tag) => {
                    setFileTags((_files) =>
                      _files.map((_file) => {
                        if (_file?.path === fileWrapper?.path) {
                          return {
                            ..._file,
                            tags: [...(_file?.tags || []), tag],
                          };
                        }

                        return _file;
                      })
                    );
                  }}
                  onClickRemoveTag={(tag) => {
                    setFileTags((_files) =>
                      _files.map((_file) => {
                        if (_file?.path === fileWrapper?.path) {
                          return {
                            ..._file,
                            tags: _file?.tags?.filter((_tag) => _tag?._id !== tag?._id),
                          };
                        }

                        return _file;
                      })
                    );
                  }}
                />
              );
            })}
          </div>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <Button
              style={{ width: 100, marginRight: 8 }}
              data-testid="upload-file-cancel"
              onClick={() => {
                setFiles([]);
                onClose();
              }}
            >
              CANCEL
            </Button>
            {loading ? (
              <CircularProgress />
            ) : (
              <Button
                color="primary"
                style={{ width: 100, color: '#2E66FE' }}
                onClick={handleOnClickDone}
                data-testid="upload-file-done"
              >
                DONE
              </Button>
            )}
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
};

const Paper = styled.div`
  max-height: 800px;
  max-width: 800px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`;

ModalUploadFile.propTypes = {
  open: PropTypes.bool.isRequired,
  organizationId: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default ModalUploadFile;
