import React, { useEffect, useState } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import ShortTextIcon from '@material-ui/icons/ShortText';

import Input from '../../../components/Input';
import useInput from '../../../hooks/useInput';
import StaticWrapper from './StaticWrapper';
import CaseNumberSettings from './CaseNumberSettings';

const useStyles = makeStyles(() => ({
  title: {
    fontSize: 14,
    color: '#000000DE',
    marginLeft: 10,
  },
  label: {
    fontSize: 16,
    color: '#000000DE',
    fontWeight: '500',
  },
}));

export default function CaseNumber({
  id,
  onUpdateJson,
  data,
  componentIndex,
  isStaticComponent = false,
  formInfoUpdate,
}) {
  const classes = useStyles();
  const maxCharacters = useInput(data?.maxCharacters || '');
  const [checkQuestion, setCheckQuestion] = useState([
    !!data?.description,
    !!data.requiredMessage,
    !!data?.maxCharacters,
  ]);

  const required = useInput(!!data?.required, false, false, true);
  const descriptionQuestion = useInput(data?.description || '');
  const requiredQuestionMessage = useInput(data?.requiredMessage || '');
  const question = useInput(data?.question || '');

  const [caseNumber, setCaseNumber] = useState({
    visible: true,
    automate: {
      enabled: false,
      manualOverride: false,
      useTrackingNumber: false,
      logic: [],
    },
  });

  useEffect(() => {
    if (formInfoUpdate) {
      const caseNumberFormatted = JSON.parse(JSON.stringify(formInfoUpdate.caseNumber));
      caseNumberFormatted.automate.logic = caseNumberFormatted.automate.logic.map((item) => {
        if (item?.tagType === 'condition') {
          return {
            tagType: item?.tagType,
            conditions: item?.config?.rules,
          };
        }
        if (item?.tagType === 'number') {
          return {
            tagType: item?.tagType,
            value: item?.config?.consecutive ? 'consecutive_number' : 'random_number',
            length: item?.config?.length,
          };
        }
        if (item?.tagType === 'date') {
          return {
            tagType: item?.tagType,
            format: item?.config?.format,
            separator: item?.config?.separator,
          };
        }
        if (item?.tagType === 'text') {
          return {
            tagType: item?.tagType,
            value: item?.config?.value,
          };
        }
        return item;
      });
      setCaseNumber(caseNumberFormatted);
    }
  }, [formInfoUpdate]);

  const handleSaveComponent = () => {
    const dataJson = {
      caseNumber: {
        visible: caseNumber?.visible,
        automate: {
          enabled: caseNumber?.automate?.enabled,
          manualOverride: caseNumber?.automate?.manualOverride,
          useTrackingNumber: caseNumber?.automate?.useTrackingNumber,
          logic: caseNumber?.automate?.logic?.map((item) => {
            if (item?.tagType === 'condition') {
              return {
                tagType: item?.tagType,
                config: {
                  rules: item?.conditions?.map((condition) => ({
                    when: condition?.when,
                    equals: condition?.equals,
                    value: condition?.value,
                  })),
                },
              };
            }
            if (item?.tagType === 'number') {
              return {
                tagType: item?.tagType,
                config: {
                  consecutive: item?.value === 'consecutive_number',
                  length: item?.length,
                },
              };
            }
            if (item?.tagType === 'date') {
              return {
                tagType: item?.tagType,
                config: {
                  format: item?.format,
                  separator: item?.separator,
                },
              };
            }

            return {
              tagType: item?.tagType,
              config: {
                value: item?.value,
              },
            };
          }),
        },
      },
    };

    onUpdateJson(dataJson);
  };

  const saveOnTimeOut = () => {
    setTimeout(() => {
      handleSaveComponent();
    }, 500);
  };

  useEffect(() => {
    saveOnTimeOut();
    return () => saveOnTimeOut;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseNumber]);

  const handleChangeVisible = (e) => {
    setCaseNumber((prevState) => {
      const checkQuestionCopy = { ...prevState };

      checkQuestionCopy.visible = e.target.checked;

      return checkQuestionCopy;
    });
  };

  const renderSettings = () => (
    <CaseNumberSettings
      saveOnTimeOut={saveOnTimeOut}
      questions={['description', 'required', 'max']}
      checkQuestion={checkQuestion}
      descriptionQuestion={descriptionQuestion}
      requiredQuestion={requiredQuestionMessage}
      setCheckQuestion={setCheckQuestion}
      maxCharacter={maxCharacters}
      maxCharacterType="short"
      caseNumber={caseNumber}
      setCaseNumber={setCaseNumber}
      id={id}
    />
  );

  return (
    <StaticWrapper
      renderSettings={renderSettings}
      renderPreview={null}
      visible={{ value: caseNumber?.visible, onChange: handleChangeVisible }}
    >
      <Box display="flex" alignItems="center">
        <ShortTextIcon htmlColor="#6D7385" />
        <Typography className={classes.title}>Short text</Typography>
      </Box>
      <Box display="flex" alignItems="center" marginTop="15px">
        <Typography className={classes.label}>Q{componentIndex + 1}</Typography>
        <ArrowRightAltIcon style={{ fontSize: 14, marginRight: 10 }} />
        <Input
          disabled={isStaticComponent}
          placeholder="Enter name question"
          value={question.value}
          onChange={(e) => {
            question.onChange(e);
            saveOnTimeOut();
          }}
        />
      </Box>
    </StaticWrapper>
  );
}
