import React from 'react';
import { Box, Button, Typography, makeStyles } from '@material-ui/core';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import DnDArea from '../../../components/DnDArea';
import DnDItem from '../../../components/DnDItem';
import ModalPopUp from '../../../components/ModaPopupl';
import useUsers from '../../../hooks/useUsers';
import { updateProjectActions } from '../../../services/unarmed';
import ActionItem from '../components/ActionItem';
import OverviewItem from '../components/OverviewItem';
import RelatedProjects from '../components/RelatedProjects';
import TotalTimeSpent from '../components/TotalTimeSpent';
import ActionCard from '../components/ActionCard';
import { BoxContainer } from '../../../styles/GlobalStyles';
import Card, { useCardStyles } from '../../../components/Card';
import Btn from '../../../components/Btn';

const getTotalTimeSpent = (actions) => {
  let timeSpentSoFar = 0;
  if (!actions?.length) return timeSpentSoFar;
  actions.forEach((action) => {
    if (action?.values?.timeSpent) {
      timeSpentSoFar += action.values?.timeSpent;
    }
  });

  return timeSpentSoFar;
};

// {
//   "times": {
//     "visible": false,
//     "requiredValue": false
//   },
//   "notes": {
//     "visible": false,
//     "requiredValue": false
//   },
//   "assignedTo": {
//     "visible": false,
//     "requiredValue": false
//   },
//   "attachments": {
//     "visible": false,
//     "requiredValue": false
//   },
//   "_id": "638e8a901ed540001201f431",
//   "name": "Template Name"
// }

const overviewTemplate = {
  actionName: 'Template Name',
  overviewFeatures: [
    {
      label: 'Times:',
      visible: false,
      required: false,
      key: 'times',
    },
    {
      label: 'Notes:',
      visible: false,
      required: false,
      key: 'notes',
    },
    {
      label: 'Assigned To:',
      visible: false,
      required: false,
      key: 'assignedTo',
    },
    {
      label: 'Attachments:',
      visible: false,
      required: false,
      key: 'attachments',
    },
  ],
};

const useStyles = makeStyles({
  buttonRoot: {
    minWidth: 0,
  },
  buttonText: {
    padding: '0px 2px',
  },
});

export default function Actions({
  relatedProjects,
  id,
  organizationId,
  statusOptions,
  refetch,
  tabValue,
  actions,
  setActions,
  organization,
}) {
  const [users] = useUsers();
  const [openModal, setOpenModal] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const [actionTemplate, setActionTemplate] = React.useState(overviewTemplate);
  const [loading, setLoading] = React.useState(false);
  const [shouldResetHashFiles, setShouldResetHashFiles] = React.useState(false);

  const classes = useCardStyles();
  const actionsClasses = useStyles();

  const getActionsRequired = () => {
    const requiredActions = [];
    actions.forEach((action) => {
      if (action?.times?.required) {
        if (!action?.values?.startDateTime || !action?.values?.endDateTime) {
          requiredActions.push(action);
        }
      } else if (action?.notes?.required) {
        if (!action?.values?.notes) {
          requiredActions.push(action);
        }
      } else if (action?.assignedTo?.required) {
        if (!action?.values?.assignedTo?.length && Object.keys(action?.values?.assignedTo?.[0] || {})?.length) {
          requiredActions.push(action);
        }
      } else if (action?.attachments?.required) {
        if (!action?.values?.attachments?.length) {
          requiredActions.push(action);
        }
      }
    });

    return requiredActions.length;
  };

  const handleAddAction = () => {
    const newAction = {
      times: {
        visible: actionTemplate?.overviewFeatures?.[0]?.visible,
        required: actionTemplate?.overviewFeatures?.[0]?.required,
      },
      notes: {
        visible: actionTemplate?.overviewFeatures?.[1]?.visible,
        required: actionTemplate?.overviewFeatures?.[1]?.required,
      },
      assignedTo: {
        visible: actionTemplate?.overviewFeatures?.[2]?.visible,
        required: actionTemplate?.overviewFeatures?.[2]?.required,
      },
      attachments: {
        visible: actionTemplate?.overviewFeatures?.[3]?.visible,
        required: actionTemplate?.overviewFeatures?.[3]?.required,
      },
      name: actionTemplate?.actionName,
      values: {
        startDateTime: null,
        endDateTime: null,
        status: '',
        assignedTo: [],
        notes: '',
        attachments: [],
      },
    };

    if (selectedIndex !== null) {
      setActions((prevState) => {
        const newState = [...prevState];
        newState.splice(selectedIndex + 1, 0, newAction);
        return newState;
      });
    } else {
      setActions((prevState) => [...prevState, newAction]);
    }
    setActionTemplate(overviewTemplate);
    setOpenModal(false);
    setSelectedIndex(null);
  };

  const onSaveActions = async (upcomingAction = null) => {
    setLoading(true);
    try {
      const actionsToEndpoint = actions.map((action) => {
        const { values, name, assignedTo, attachments, notes, times } = action;

        const currentAction = {
          name,
          ...(action?._id ? { _id: action._id } : {}),
          values: {
            ...(values.startDateTime ? { startDateTime: values.startDateTime } : {}),
            ...(values.endDateTime ? { endDateTime: values.endDateTime } : {}),
            ...(values.status ? { status: values.status } : {}),
            ...(values.notes ? { notes: values.notes } : {}),
            ...(values.assignedTo?.length
              ? {
                  assignedTo:
                    values?.assignedTo?.map((assigned) => (typeof assigned === 'string' ? assigned : assigned?._id)) ||
                    [],
                }
              : {}),
            ...(values.type && { type: values.type }),
            ...(values.subType && { subType: values.subType }),
            ...(values.attachments?.length
              ? {
                  attachments: values.attachments?.map((attachment) => ({
                    ...(attachment.hashId
                      ? { file: attachment.hashId, tags: attachment?.tags?.map((tag) => tag?._id) || [] }
                      : { _id: attachment._id }),
                  })),
                }
              : {}),
          },
          settings: {
            times,
            notes,
            assignedTo,
            attachments,
          },
        };
        return currentAction;
      });

      await updateProjectActions(id, [...actionsToEndpoint, ...(upcomingAction ? [upcomingAction] : [])]);
      setShouldResetHashFiles(true);
      refetch();
      toast.success('Actions updated successfully');
    } catch (error) {
      toast.error(
        error.response?.data?.error?.details?.[0]?.message || error.response?.data?.message || 'Something went wrong'
      );
    } finally {
      setLoading(false);
    }
  };

  const handleChangeActionValues = (index, prop, value) => {
    const actionsCopy = [...actions];
    const action = { ...actionsCopy[index] };
    action.values[prop] = value;
    actionsCopy[index] = action;
    setActions(actionsCopy);
  };

  const onRemoveAction = (index) => {
    setActions((prevState) => {
      const newState = [...prevState];
      newState.splice(index, 1);
      return newState;
    });
  };

  const onChangeActionFeatures = (e, prop, subProp) => {
    const { checked } = e.target;
    const features = [...actionTemplate.overviewFeatures];
    const feature = { ...features.find((ft) => ft.label === prop) };
    const featureIndex = features.findIndex((ft) => ft.label === prop);
    if (subProp === 'visible' && !checked) {
      feature[subProp] = checked;
      feature.required = false;
    } else {
      feature[subProp] = checked;
    }

    features[featureIndex] = feature;

    setActionTemplate((prevState) => ({
      ...prevState,
      overviewFeatures: features,
    }));
  };

  const renderer = (moveItem) =>
    actions?.map((action, index) => (
      <Box style={{ marginBottom: 30 }} key={action?._id || `action-${index}`}>
        <DnDItem moveItem={moveItem} index={index}>
          <ActionItem
            project={action.project}
            name={action.name}
            assignedTo={action.assignedTo}
            attachments={action.attachments}
            notes={action.notes}
            times={action.times}
            values={action.values}
            types={action.types}
            updatedAt={action.updatedAt}
            refetch={refetch}
            index={index}
            projectId={id}
            organizationId={organizationId}
            statusOptions={statusOptions}
            users={users}
            onRemoveAction={() => {
              onRemoveAction(index);
              toast.success('Action removed successfully');
            }}
            actionSettings={organization?.project?.actions}
            handleChangeActionValues={handleChangeActionValues}
            shouldResetHashFiles={shouldResetHashFiles}
            setShouldResetHashFiles={setShouldResetHashFiles}
            tabValue={tabValue}
          />
        </DnDItem>
      </Box>
    ));

  return (
    <Box display="flex">
      <Card
        title={
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography className={classes.title} variant="body2">
              Actions
            </Typography>
          </Box>
        }
      >
        <Box width="100%">
          {actions.length === 0 && (
            <Typography variant="body2" style={{ fontWeight: '500', fontSize: 14, textAlign: 'center' }}>
              There is not any actions added yet
            </Typography>
          )}
          <DndProvider backend={HTML5Backend}>
            <DnDArea selectedItems={actions} setSelectedItems={setActions} renderer={renderer} />
          </DndProvider>
          {actions.length ? (
            <Box display="flex" justifyContent="flex-end">
              <Btn
                dataTestId="projects-actions-button-submit"
                text="Save"
                loading={loading}
                color="primary"
                variant="contained"
                onClick={() => onSaveActions(null)}
                disabled={getActionsRequired() || loading}
                classes={{ root: actionsClasses.button, text: actionsClasses.buttonText }}
              />
            </Box>
          ) : null}
          {/* {!actions.length ? <AddMore text="Add new action" marginLeft="40%" onClick={() => setOpenModal(true)} /> : null} */}
        </Box>
      </Card>
      <BoxContainer marginLeft={2}>
        <ActionCard
          actionSettings={organization?.project?.actions}
          users={users}
          onSaveActions={onSaveActions}
          organizationId={organizationId}
        />
        <TotalTimeSpent totalTimeSpent={getTotalTimeSpent(actions)} />
        <RelatedProjects relatedProjects={relatedProjects} id={id} refetch={refetch} />
      </BoxContainer>
      <ModalPopUp setOpen={setOpenModal} open={openModal} maxWidth={700}>
        <OverviewItem
          setTemplate={setActionTemplate}
          overviewFeatures={actionTemplate.overviewFeatures}
          actionName={actionTemplate.actionName}
          onChangeActionFeatures={onChangeActionFeatures}
        />

        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <Button
            onClick={() => {
              setOpenModal(false);
              setActionTemplate(overviewTemplate);
            }}
            color="primary"
            style={{ color: '#3B70FE' }}
          >
            CANCEL
          </Button>
          <Button onClick={handleAddAction} color="primary" style={{ color: '#3B70FE' }}>
            ADD ACTION
          </Button>
        </Box>
      </ModalPopUp>
    </Box>
  );
}

Actions.propTypes = {
  relatedProjects: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
  organizationId: PropTypes.string.isRequired,
  statusOptions: PropTypes.array.isRequired,
  refetch: PropTypes.func.isRequired,
  tabValue: PropTypes.number.isRequired,
  actions: PropTypes.array.isRequired,
  setActions: PropTypes.func.isRequired,
  organization: PropTypes.object.isRequired,
};
