import { Box, Typography, Button, makeStyles } from '@material-ui/core';

import React from 'react';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 20,
  },
  title: {
    marginTop: 50,
  },
  button: {
    background: ' #1F63FF',
    textTransform: 'capitalize',
    marginRight: 10,
    color: '#fff',
    height: 36,
    width: 30,
    '&:hover': {
      background: '#1F63FF',
    },
    '& .MuiButton-label': {
      fontSize: 13,
    },
    '&:disabled': {
      background: '#1f63ff61',
      color: '#fff',
    },
  },
  button2: {
    background: ' #1F63FF',
    textTransform: 'capitalize',
    color: '#fff',
    '&:hover': {
      background: '#1F63FF',
    },
    '& .MuiButton-label': {
      fontSize: 14,
    },
  },
  buttonOutlined: {
    letterSpacing: '1px',
    fontSize: 13,
    fontWeight: '500',
    marginRight: 10,
    width: 'fit-content',
    alignSelf: 'flex-end',
    border: '1px solid transparent',
    color: '#4b7bff',
    '&:hover': {
      background: 'transparent',
      border: '1px solid transparent',
    },
    '& .MuiButton-label': {
      fontSize: 13,
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: '#fff',
    boxShadow: theme.shadows[5],
    padding: 30,
    maxWidth: 600,
    minWidth: 400,
    outline: 0,
  },
}));

export default function DeleteActionPopup({ setOpen, handleClicked }) {
  const classes = useStyles();

  return (
    <div className={classes.paper}>
      <Typography style={{ fontSize: 20, fontWeight: '500' }}>
        Are you sure you want to delete?
      </Typography>
      <Typography variant="body2">This action can’t be undone!</Typography>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        marginTop="20px"
      >
        <Button
          variant="outlined"
          className={classes.buttonOutlined}
          onClick={() => setOpen(false)}
        >
          CANCEL
        </Button>
        <Button
          variant="outlined"
          className={classes.buttonOutlined}
          onClick={() => {
            handleClicked();
            setOpen(false);
          }}
        >
          DELETE
        </Button>
      </Box>
    </div>
  );
}
