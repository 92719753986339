import React, { useState } from 'react';
import { Box, FormControl, InputLabel, makeStyles, Select, Typography } from '@material-ui/core';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import WrapperComponent from './WrapperComponent';
import Input from '../../components/Input';
import DnDArea from '../../components/DnDArea';
import DnDItem from '../../components/DnDItem';
import { onAddDnDItem, onChangeDnDValue, onRemoveDnDItem } from '../utils/utls';
import AddMore from '../../components/AddMore';
import useInput from '../../hooks/useInput';
import Settings from '../utils/Settings';
import PreviewLabel from '../utils/Label';
import HideAnswer from '../../components/HideAnswer';

const useStyles = makeStyles(() => ({
  title: {
    fontSize: 14,
    color: '#000000DE',
    marginLeft: 10,
  },
  label: {
    fontSize: 16,
    color: '#000000DE',
    fontWeight: '500',
  },

  subLabel: {
    fontSize: 14,
    color: '#000000DE',
    fontWeight: '500',
  },

  settingsQuestions: {
    fontSize: 14,
    minWidth: 160,
  },
}));

export const mapOptionsToState = (options) => {
  if (options) {
    return options.map((option) => {
      if (typeof option === 'string') {
        return {
          value: option,
        };
      }

      return { value: option.label };
    });
  }

  return [{ value: '' }, { value: '' }, { value: '' }];
};

export default function Gender({
  type,
  questionBankComponent,
  id,
  componentId,
  onUpdateJson,
  onDeleteComponent,
  path,
  data,
  componentIndex,
  duplicateComponent,
  shouldRenderSettings = true,
  allowDnD = true,
  includeRequired,
  isStaticComponent = false,
  refActionModal,
  setModal,
  shouldRenderToggle,
  isSubComponent,
}) {
  const classes = useStyles();
  const [answers, setAnswers] = useState(mapOptionsToState(data.options));
  const visible = useInput(data?.visible, false, false, true);

  const [checkQuestion, setCheckQuestion] = useState([
    !!data?.description,
    !!data.requiredMessage,
    !!data?.multipleChoice,
  ]);

  const required = useInput(!!data?.required, false, false, true);
  const descriptionQuestion = useInput(data?.description || '');
  const requiredQuestionMessage = useInput(data?.requiredMessage || '');
  const question = useInput(data?.question || '');
  const [loading, setLoading] = React.useState(false);

  const handleSaveComponent = () => {
    let dataJson = {};

    if (isStaticComponent) {
      dataJson = {
        [question.value.toLowerCase()]: {
          visible: visible.value,
          values: answers.map((answer) => answer.value),
        },
      };
    } else {
      dataJson = {
        question: question.value,
        ...(descriptionQuestion.value ? { description: descriptionQuestion.value } : {}),
        ...(requiredQuestionMessage.value ? { requiredMessage: requiredQuestionMessage.value } : {}),
        ...(data.dataReportLink ? { dataReportLink: data.dataReportLink } : {}),
        visible: visible.value,
        required: required.value,
        multipleChoice: checkQuestion[2],
        options: answers.map((answer) => answer.value),
      };
    }

    onUpdateJson(dataJson, 'gender');
  };

  const item = (answer, index) => (
    <Box width="100%" maxWidth={434} display="flex" alignItems="center" marginBottom="10px">
      <Typography className={classes.subLabel} style={{ marginRight: 10 }}>
        A{index + 1}
      </Typography>

      <Input
        placeholder={`Enter option ${index + 1}`}
        value={answer.value}
        onChange={(e) => {
          onChangeDnDValue(e, setAnswers, index);
          saveOnTimeOut();
        }}
      />
      <DeleteIcon
        htmlColor="#6D7385"
        style={{ marginLeft: 20, cursor: 'pointer' }}
        onClick={() => {
          onRemoveDnDItem(answers, setAnswers, index);
          saveOnTimeOut();
        }}
      />
    </Box>
  );

  const renderer = (moveItem) =>
    answers.map((answer, index) => {
      if (allowDnD) {
        return (
          <DnDItem key={index} moveItem={moveItem} index={index}>
            {item(answer, index)}
          </DnDItem>
        );
      }
      return item(answer, index);
    });
  const saveOnTimeOut = () => {
    setLoading(true);
    setTimeout(() => {
      handleSaveComponent();
      setLoading(false);
    }, 500);
  };
  const renderSettings = () => (
    <Settings
      componentId={componentId}
      componentType="gender"
      defaultDataReportLink={data.dataReportLink}
      onSelectDataReportLink={
        !isSubComponent ? (dataReportLink) => onUpdateJson({ ...data, dataReportLink }) : undefined
      }
      questions={['description', 'required', 'multiple choice']}
      checkQuestion={checkQuestion}
      descriptionQuestion={descriptionQuestion}
      requiredQuestion={requiredQuestionMessage}
      setCheckQuestion={setCheckQuestion}
      saveOnTimeOut={saveOnTimeOut}
    />
  );

  const renderPreview = () => (
    <Box>
      <PreviewLabel question={question.value} qNumber={componentIndex + 1} required={required.value} />
      <Box marginTop="-16px" marginLeft="45px">
        <HideAnswer marginLeft="-7px" marginTop={0}>
          <FormControl variant="standard" style={{ minWidth: 280, marginBottom: 20 }}>
            <InputLabel id="demo-simple-select-standard-label">Select an Option</InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              disabled
              label="Age"
              value=""
            />
          </FormControl>
          {answers.map((answer, index) => (
            <Box display="flex" alignItems="center" key={index} marginBottom="10px">
              <ArrowRightAltIcon htmlColor="#00000061" style={{ fontSize: 16, marginRight: 10 }} />
              <Typography style={{ color: '#00000061' }}>{answer.value}</Typography>
            </Box>
          ))}
        </HideAnswer>
      </Box>
    </Box>
  );

  return (
    <Box marginBottom="20px">
      <WrapperComponent
        onBlur={(!questionBankComponent && saveOnTimeOut) || function () {}}
        renderSettings={shouldRenderSettings ? renderSettings : null}
        componentIndex={componentIndex}
        duplicateComponent={duplicateComponent}
        isQuestionBankComponent={questionBankComponent || isStaticComponent}
        renderPreview={renderPreview}
        required={required}
        onDeleteComponent={onDeleteComponent}
        id={id}
        componentId={componentId}
        path={path}
        loading={loading}
        isDemographicComponent
        allowDnD={false}
        includeRequired={includeRequired}
        visible={visible}
        isStaticComponent={isStaticComponent}
        dataSaved={data?.question}
        refActionModal={refActionModal}
        setModal={setModal}
        shouldRenderToggle={shouldRenderToggle}
      >
        <Box display="flex" alignItems="center">
          <ArrowDropDownIcon htmlColor="#6D7385" />
          <Typography className={classes.title}>Gender</Typography>
        </Box>
        <Box display="flex" alignItems="center" marginTop="15px">
          <Typography className={classes.label}>Q{componentIndex + 1}</Typography>
          <ArrowRightAltIcon style={{ fontSize: 14, marginRight: 10 }} />
          <Input
            placeholder="Enter name question"
            value={question.value}
            onChange={(e) => {
              question.onChange(e);
              saveOnTimeOut();
            }}
            disabled={isStaticComponent}
          />
        </Box>
        <Box marginLeft="20px" marginTop="30px">
          {/* <DndProvider backend={HTML5Backend}> */}
          <DnDArea selectedItems={answers} setSelectedItems={setAnswers} renderer={renderer} />
          {/* </DndProvider> */}
        </Box>
        <AddMore
          dataTestId="forms-gender-button-add-more"
          onClick={() => onAddDnDItem(answers, setAnswers, 'radio', '')}
          marginLeft={allowDnD ? '77px' : '18px'}
        />
      </WrapperComponent>
    </Box>
  );
}
