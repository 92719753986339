import React, { useCallback } from 'react';
import update from 'immutability-helper';
import { Box } from '@material-ui/core';

const DragArea = ({
  selectedItems = [],
  setSelectedItems,
  ComponentToRender,
  componentProps,
}) => {
  const moveItem = useCallback(
    (dragIndex, hoverIndex) => {
      const dragItem = selectedItems[dragIndex];
      setSelectedItems(
        update(selectedItems, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragItem],
          ],
        })
      );
    },
    [selectedItems, setSelectedItems]
  );

    return (
      <Box width="100%" marginTop="12px" minHeight={175}>
        {selectedItems.map((selected, index) => (
          <ComponentToRender
            key={selected?.id}
            moveItem={moveItem}
            index={index}
            id={selected?.id}
            {...selected}
            style={{
              transform: 'translate3d(0, ' + 10 + 'px, 0)',
            }}
            {...componentProps}
          />
        ))}
      </Box>
    );
};

export default DragArea;
