import React, { useCallback, useLayoutEffect, useState } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import shortid from 'shortid';
import styled from 'styled-components';
import Alert from '../../components/Alert';

import AddMore from '../../components/AddMore';

import { addComponentToJson, DeleteComponentFromJson, onUpdateJson } from '../../formEngine/utils/utls';
import PageHeader from './PageHeader';
import Section from './Section';

import { handleMoveWithinParent } from '../../formEngine/utils/helpers';
import DropZone from '../../formEngine/utils/DropZone';
import { QUESTION_BANK } from '../../formEngine/utils/constants';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    height: 'calc(116.1vh - 64px)',
    overflowY: 'auto',
    padding: 24,
  },
}));

export default function PageDetails({
  page,
  setPages,
  handleVisibleQuestion,
  handleInitVisibleQuestion,
  handleSaveForm,
  loading,
  formInfo,
  pages,
  refActionModal,
  setOpen,
  isDefaultForm,
}) {
  const classes = useStyles();
  const [layout, setLayout] = useState(page.sections);
  useLayoutEffect(() => {
    setLayout(page.sections);
  }, [page]);

  const addNewSection = () => {
    setPages((prevState) => {
      const pagesCopy = [...prevState];
      pagesCopy[page.pageIndex].sections = [...layout, { name: '', id: shortid.generate(), components: [] }];

      return pagesCopy;
    });
  };

  const deleteSection = (sectionIx) => {
    setPages((prevState) => {
      const pagesCopy = [...prevState];
      const sections = pagesCopy[page.pageIndex].sections.filter((_, index) => sectionIx !== index);
      const questionsBankAdded = pagesCopy[page.pageIndex].sections[sectionIx].components.filter(
        (res) => res.type === 'questionBank'
      );
      // check questionBank components and reset questions
      handleInitVisibleQuestion(questionsBankAdded, 'reset');

      pagesCopy[page.pageIndex].sections = sections;
      return pagesCopy;
    });
  };

  const duplicateComponent = (componentsArray, sectionIx, ixComponent) => {
    setPages((prevState) => {
      const pagesCopy = [...prevState];
      const componentType = pagesCopy[page.pageIndex].sections[sectionIx].components[ixComponent].type;

      const componentDuplicate = componentsArray().find((res) => res.type === componentType);
      componentDuplicate.data = pagesCopy[page.pageIndex].sections[sectionIx].components[ixComponent].data;
      const componentsBefore = [
        ...pagesCopy[page.pageIndex].sections[sectionIx].components.slice(0, ixComponent + 1),
        componentDuplicate,
      ];
      const componentsAfter = pagesCopy[page.pageIndex].sections[sectionIx].components.slice(ixComponent + 1);
      pagesCopy[page.pageIndex].sections[sectionIx].components = [...componentsBefore, ...componentsAfter];
      return pagesCopy;
    });
  };

  const handleChangeSectionName = (e, index) => {
    const { value } = e.target;

    setPages((prevState) => {
      const pagesCopy = [...prevState];
      pagesCopy[page.pageIndex].sections[index].name = value;

      return pagesCopy;
    });
  };

  const handleDrop = useCallback(
    (dropZone, item) => {
      const splitDropZonePath = dropZone.path.split('-');
      const pathToDropZone = splitDropZonePath.slice(0, -1).join('-');
      if (item.type === QUESTION_BANK) {
        // 1. Move sidebar item into section
        const sectionIndex = +dropZone.path.split('-')[0];
        const dropIndex = +dropZone.path.split('-')[1];
        handleVisibleQuestion(item.id, false);

        const pagesCopy = Array.from(pages);
        const components = [...layout[sectionIndex].components];
        const questionBankComponent = {
          type: 'questionBank',
          data: {
            type: item.componentType,
          },
          idQuestion: item.id,
          components: item.components.map((component) => ({
            ...component,
            questionBankComponent: true,
          })),
        };
        components.splice(dropIndex, 0, questionBankComponent);
        pagesCopy[page.pageIndex].sections[sectionIndex].components = components;

        setPages(pagesCopy);
        return;
      }

      // move down here since sidebar items dont have path
      const splitItemPath = item.path.split('-');
      const pathToItem = splitItemPath.slice(0, -1).join('-');

      // 2. Pure move (no create)
      if (splitItemPath.length === splitDropZonePath.length) {
        // 2.a. move within parent
        if (pathToItem === pathToDropZone) {
          const pagesCopy = Array.from(pages);

          const moveItem = handleMoveWithinParent(layout, splitDropZonePath, splitItemPath);
          pagesCopy[page.pageIndex].sections = moveItem;

          setPages(pagesCopy);
        }
      }
    },
    [layout, handleVisibleQuestion]
  );
  const renderSection = (section, currentPath) => (
    <Section
      data={section}
      handleDrop={handleDrop}
      components={section.components}
      path={currentPath}
      pages={pages}
      isDefaultForm={isDefaultForm}
      addComponent={(component) =>
        addComponentToJson({
          setPages,
          page,
          components: section.components,
          component,
          currentSection: +currentPath,
        })
      }
      refActionModal={refActionModal}
      duplicateComponent={duplicateComponent}
      setModal={setOpen}
      deleteSection={deleteSection}
      deleteComponent={(componentIndex) => {
        if (section.components[componentIndex].type === 'questionBank') {
          handleVisibleQuestion(section.components[componentIndex].idQuestion, true);
        }
        DeleteComponentFromJson({
          pages,
          setPages,
          page,
          components: section.components,
          componentIndex,
          currentSection: +currentPath,
        });
      }}
      onUpdateJson={(componentIndex, data, subProp) =>
        onUpdateJson({
          setPages,
          page,
          componentIndex,
          data,
          currentSection: +currentPath,
          subProp,
        })
      }
      handleChangeSectionName={handleChangeSectionName}
    />
  );

  const onSaveForm = (dataTopInfo) => {
    // if (questionsRequired && formInfo?.published) {
    //   return setSomeQuesRequired(true);
    // }
    // setSomeQuesRequired(false);
    handleSaveForm(dataTopInfo);
  };

  return (
    <ContainerPageDetail className={classes.container}>
      <PageHeader onSaveForm={onSaveForm} loading={loading} formInfo={formInfo} isDefaultForm={isDefaultForm} />
      {formInfo?.defaultForm && (
        <Alert status="info">
          <Typography style={{ fontSize: 14 }}>
            Default forms cannot be deleted or updated. Duplicate this form to edit.
          </Typography>
        </Alert>
      )}

      {layout.map((section, index) => {
        const currentPath = `${index}`;

        return (
          <React.Fragment key={section.id}>
            <DropZone
              data={{
                path: currentPath,
                childrenCount: layout.length,
              }}
              onDrop={handleDrop}
              path={currentPath}
            />
            {renderSection(section, currentPath)}
          </React.Fragment>
        );
      })}
      <DropZone
        data={{
          path: `${layout.length}`,
          childrenCount: layout.length,
        }}
        onDrop={handleDrop}
        isLast
      />
      {!isDefaultForm && (
        <Box display="flex" justifyContent="center">
          <AddMore
            dataTestId="forms-button-add-new-section"
            marginLeft={0}
            text="Add new section"
            onClick={addNewSection}
            style={{ cursor: 'pointer' }}
          />
        </Box>
      )}
    </ContainerPageDetail>
  );
}

export const ContainerPageDetail = styled.div`
  /* ::-webkit-scrollbar {
    width: 0px;
  } */
`;
