import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { convertHMS } from '../utils';

const useStyles = makeStyles(() => ({
  formSection: {
    fontSize: 14,
    color: '#3f51b5',
    fontWeight: '500',
    margin: '20px 0',
  },
  containerTitle: {
    padding: '10px 20px',
    borderBottom: '1px solid #e2e2e2',
  },
  resaltText: {
    color: '#3B70FE',
    fontWeight: 600,
    fontSize: 13,
  },
  label: {
    fontWeight: '500',
    fontSize: 14,
    margin: '10px 0 10px',
  },
  button: {
    background: ' #3B70FE',
    textTransform: 'capitalize',
    color: '#fff',
    '&:hover': {
      background: '#3B70FE',
    },
    '& .MuiButton-label': {
      fontSize: 14,
    },
    marginTop: 10,
    marginBottom: 20,
  },
  labelError: {
    fontSize: 12,
    color: 'red',
  },
}));

const TotalTimeSpent = ({ totalTimeSpent }) => {
  const classes = useStyles();

  return (
    <Box
      style={{ background: '#fff', borderRadius: 5 }}
      boxShadow="0 3px 6px #00000005"
      mb="20px"
    >
      <Box className={classes.containerTitle}>
        <Typography variant="body2" style={{ fontWeight: '500', fontSize: 16 }}>
          Total time spent
        </Typography>
      </Box>
      <Box padding="10px 20px">
        <Typography
          variant="body2"
          style={{ fontWeight: '600', fontSize: 35, color: '#000000DE' }}
        >
          {convertHMS(totalTimeSpent) || '00:00:00'}
        </Typography>
      </Box>
    </Box>
  );
};

export default TotalTimeSpent;
