/* eslint-disable react/display-name */
import {
  Backdrop,
  Box,
  Checkbox,
  CircularProgress,
  Fade,
  makeStyles,
  Modal,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  Popover,
  IconButton,
  List,
  ListItem,
  Divider,
  TextField,
  InputAdornment,
  Button,
  Grid,
  Input,
  InputLabel,
  Tabs,
} from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { toast } from 'react-toastify';
import Flagged from '../../assets/flagged.png';
import { Context } from '../../Context';
import {
  deleteInvitationUser,
  deleteUser,
  getUsage,
  getUsers,
  getUsersInvitations,
  editMultipleUsers,
  updateViewsById,
} from '../../services/unarmed';
import { ViewTabUsers } from './ViewTabUsers';
import useInput from '../../hooks/useInput';
import useOrganization from '../../hooks/useOrganization';
import UpgradeModal from '../../components/UpgradeModal';
import RenderMoreOption from './RenderMoreOptions';
import { logEvent } from '../../services/firebase';
import useFiltersConfig from '../../hooks/useFiltersConfig';
import { useTabsUtil } from '../../hooks/useTabs';
import { TYPE_USER_TABS } from '../../utils/constants';
import TabsModules from '../../components/TabsModules';
import InviteUser from './InviteUser';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 20,
  },
  title: {
    marginTop: 50,
  },
  label: {
    fontSize: 12,
    color: '#aaa',
  },
  button: {
    letterSpacing: '1px',
    fontSize: 13,
    background: '#2E66FE',
    fontWeight: '400',
    width: 'fit-content',
    alignSelf: 'flex-end',
    color: '#fff',
    '&:hover': {
      background: '#4b7bff',
    },
    '& .MuiButton-label': {
      fontSize: 13,
    },
  },
  btn: {
    background: ' #1F63FF',
    textTransform: 'capitalize',
    color: '#fff',
    '&:hover': {
      background: '#1F63FF',
    },
    '& .MuiButton-label': {
      fontSize: 14,
    },
    '&:disabled': {
      background: '#1f63ff61',
      color: '#fff',
    },
  },
  buttonOutlined: {
    letterSpacing: '1px',
    fontSize: 13,
    fontWeight: '500',
    marginRight: 10,
    width: 'fit-content',
    alignSelf: 'flex-end',
    border: '1px solid transparent',
    color: '#4b7bff',
    '&:hover': {
      background: 'transparent',
      border: '1px solid transparent',
    },
    '& .MuiButton-label': {
      fontSize: 13,
    },
  },

  img: {
    width: 120,
    height: 120,
    borderRadius: '50%',
    objectFit: 'cover',
  },

  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: '#fff',
    boxShadow: theme.shadows[5],
    padding: 30,
    maxWidth: 600,
    outline: 0,
  },
}));

const Users = () => {
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const { authUser, setActive } = useContext(Context);
  const location = useLocation();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selected, setSelected] = React.useState([]);
  const [checked, setChecked] = useState(false);
  const [stateUsage, setStateUsage] = useState(false);
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(location?.state?.value || 0);
  const [loadingDeleteUser, setLoadingDeleteUser] = useState(false);
  const [sortBy, setSortBy] = useState('createdAt');
  const [sort, setSort] = useState('desc');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [role, setRole] = useState('');
  const flaggedOnly = useInput(false, false, false, true);
  const flagged = useInput('');
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [isFiltering, setIsFiltering] = useState(false);
  const { organization } = useOrganization();
  const [usage, setUsage] = useState(null);
  const history = useHistory();

  const [openInviteUser, setOpenInviteUser] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [openDeleteUser, setOpenDeleteUser] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const baseColumns = {
    flag: true,
    firstName: true,
    lastName: true,
    phone: true,
    email: true,
    role: true,
    dateAdded: true,
  };
  const [loadingEditUsers, setLoadingEditUsers] = useState(false);
  const [view, setView] = useState(null);
  const [visibleColumns, setVisibleColumns] = useState(baseColumns);
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  const filteredColumns = Object.keys(visibleColumns).filter((column) =>
    column.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const phoneNumber = useInput('');

  const showColumns = filteredColumns.filter((column) => visibleColumns[column]);
  const hideColumns = filteredColumns.filter((column) => !visibleColumns[column]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);
  const popoverId = openInviteUser ? 'column-popover' : undefined;

  const {
    filtersConfig,
    filterCount,
    filtersLoaded,
    filtersApplied,
    resetFilterCount,
    setFiltersLoaded,
    fetchFiltersConfig,
  } = useFiltersConfig('users');

  useEffect(() => {
    setActive('/users');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    activeTab,
    setActiveTab,
    tabSelected,
    setTabSelected,
    onGetUserTabs,
    userTabs,
    updateNameTab,
    setUserTabs,
    handleSaveTab,
    handleTabSelection,
    nextPosition,
    setShowReportTable,
    handleTabsReorder,
    handleTabSelectionAction,
    deleteSelectedTab,
    activeTabName,
    setActiveTabName,
  } = useTabsUtil({ moduleName: 'USERS' });

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(1);
  };

  const onClearFilters = async () => {
    setRole('0');
    setFrom(null);
    setTo(null);
    setUsername('');
    setEmail('');
    setPhone('');
    flaggedOnly.setValue(false);
    resetFilterCount();

    try {
      setLoading(true);
      let res = null;
      if (value === 0) {
        res = await getUsers({ page, rowsPerPage, sortBy, sort });
      }

      if (value === 1) {
        res = await getUsersInvitations({ page, rowsPerPage, sortBy, sort });
      }
      setUsers({ data: res.data, headers: res.headers });
      setLoading(false);
      fetchFiltersConfig();
      toast.success('Successfully cleared filters!');
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  const goTouserProfile = (e, row, handleClick) => {
    const go = handleClick(e, row._id);
    if (go && value === 0) {
      history.push(`/user/${row._id}`);
      logEvent('enter_profile_details');
    } else if (go && value === 1) {
      history.push(`/invitation/${row._id}`);
      logEvent('enter_invite_details');
    }
  };

  const renderRow = (withRedirectToProfile = true) => (row, index, handleClick) => {
    const isItemSelected = isSelected(row._id);
    const labelId = `enhanced-table-checkbox-${index}`;

    return (
      <TableRow
        hover
        aria-checked={isItemSelected}
        selected={isItemSelected}
        role="checkbox"
        tabIndex={-1}
        key={index}
        className={classes.row}
        style={withRedirectToProfile ? { cursor: 'pointer', height: 50 } : { height: 50 }}
        onClick={withRedirectToProfile ? (e) => goTouserProfile(e, row, handleClick) : (e) => handleClick(e, row._id)}
      >
        <TableCell padding="checkbox">
          <Checkbox checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} color="primary" />
        </TableCell>
        {visibleColumns?.flag && (
          <TableCell component="th" scope="row" align="left">
            {row?.flagged && <img src={Flagged} alt="Flagged" style={{ width: '20px', height: '20px' }} />}
          </TableCell>
        )}
        {visibleColumns.firstName && (
          <TableCell component="th" scope="row" align="left">
            {row?.firstName}
          </TableCell>
        )}
        {visibleColumns.lastName && <TableCell align="left">{row?.lastName}</TableCell>}
        {visibleColumns.phone && (
          <TableCell align="left">
            {row?.phoneNumber?.replace(/(\d{3})(\d{3})(\d{4})/g, '($1) $2-$3') ||
              row?.phone?.replace(/(\d{3})(\d{3})(\d{4})/g, '($1) $2-$3')}
          </TableCell>
        )}
        {visibleColumns.email && (
          <TableCell component="th" scope="row" align="left">
            {row?.email}
          </TableCell>
        )}
        {visibleColumns.role && (
          <TableCell component="th" scope="row" align="left" style={{ textTransform: 'capitalize' }}>
            {row?.role || row?.type}
          </TableCell>
        )}
        {visibleColumns.dateAdded && (
          <TableCell align="left" style={{ textTransform: 'capitalize' }}>
            {row?.createdAt && moment(row?.createdAt).format('MMMM DD, YYYY')}
          </TableCell>
        )}
        {value === 1 && (
          <TableCell padding="checkbox">
            <RenderMoreOption
              value={value}
              users={users}
              setUsage={setUsage}
              setUsers={setUsers}
              row={row}
              organization={organization}
            />
          </TableCell>
        )}
      </TableRow>
    );
  };

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setStateUsage(!stateUsage);
  };

  const handleToggleColumn = async (viewId, columnName) => {
    try {
      const updatedColumns = {
        ...visibleColumns,
        [columnName]: !visibleColumns[columnName],
      };

      const response = await updateViewsById(viewId, { columns: updatedColumns });

      if (response && response.status === 200 && response.view) {
        setVisibleColumns(response.view.columns);
      } else {
        console.error('Failed to update columns:', response);
      }
    } catch (error) {
      console.error('Error updating column visibility:', error);
    }
  };

  const formatHeadersText = (str) => {
    const result = str.replace(/([A-Z])/g, ' $1');

    return result.replace(/^./, (firstChar) => firstChar.toUpperCase()).trim();
  };

  const onEditMultipleUsers = async () => {
    let ids = '';
    const selectedUsers = users.data.filter((user) => selected.includes(user._id));
    const allAreFlagged = selectedUsers.every((user) => user.flagged);

    for (const id of selected) {
      const index = selected.indexOf(id);
      if (index + 1 === selected.length) {
        ids += `ids[]=${id}`;
      } else {
        ids += `ids[]=${id}&`;
      }
    }

    try {
      setLoadingEditUsers(true);
      await editMultipleUsers(ids);

      setUsers((prevUsers) => {
        const updatedData = prevUsers.data.map((user) => {
          if (selected.includes(user._id)) {
            return {
              ...user,
              flagged: !allAreFlagged,
            };
          }
          return user;
        });

        return {
          ...prevUsers,
          data: updatedData,
        };
      });

      setLoadingEditUsers(false);
      setSelected([]);
      toast.success(allAreFlagged ? 'Successfully unflagged users' : 'Successfully flagged users');
    } catch (error) {
      setLoadingEditUsers(false);
      toast.error(error.message);
    }
  };

  const onDeleteUsers = async () => {
    let ids = '';
    for (const id of selected) {
      const index = selected.indexOf(id);
      if (index + 1 === selected.length) {
        ids += `ids[]=${id}`;
      } else {
        ids += `ids[]=${id}&`;
      }
    }

    try {
      setLoadingDeleteUser(true);

      if (value === 0) {
        await deleteUser(ids);
      } else {
        await deleteInvitationUser(ids);
      }
      let availableData = users.data.slice();
      for (const id of selected) {
        availableData = availableData.filter((x) => x._id !== id);
      }

      const data = await getUsage(organization?._id);
      setUsage(data);
      setUsers({ ...users, data: availableData });

      setLoadingDeleteUser(false);
      setOpenDeleteUser(false);
      setSelected([]);
    } catch (error) {
      setLoadingDeleteUser(false);
      toast.error(error);
    }
  };
  const description = (
    <>
      To Increase your limit please contact us to <a href="mailto:hello@sivilco.com">hello@sivilco.com</a>
    </>
  );

  const getVisibleColumns = () =>
    Object.keys(visibleColumns)
      .filter((column) => visibleColumns[column])
      .map((column) => formatHeadersText(column));

  return (
    <>
      {organization && <UpgradeModal open={!organization?.features?.dashboard?.users?.enabled} />}
      <UpgradeModal
        open={showUpgradeModal}
        callback={() => setShowUpgradeModal(false)}
        type="danger"
        title="You can't create more users, you reached your limit."
        description={description}
      />
      <PageWrapper
        className={classes.container}
        style={
          !organization?.features?.dashboard?.users?.enabled
            ? {
                filter: 'blur(6px)',
                height: '70vh',
                overflow: 'hidden',
              }
            : {}
        }
      >
        <Typography variant="h5">Users</Typography>

        <TabsModules
          setTabs={setUserTabs}
          updateNameTab={updateNameTab}
          tabSelected={tabSelected}
          activeTabName={activeTabName}
          activeTab={activeTab}
          setTabSelected={setTabSelected}
          setActiveTab={setActiveTab}
          setActiveTabName={setActiveTabName}
          tabs={userTabs}
          navigateToDataPage={() => {}}
          saveTab={handleSaveTab}
          onTabSelect={handleTabSelection}
          nextPosition={nextPosition}
          saveTabPositions={handleTabsReorder}
          onTabAction={handleTabSelectionAction}
          deleteTab={deleteSelectedTab}
          moduleName="USERS"
        />

        {activeTab === TYPE_USER_TABS.MAIN && (
          <ViewTabUsers
            setValue={setValue}
            stateUsage={stateUsage}
            onDeleteUsers={onDeleteUsers}
            flagged={flagged}
            setUsage={setUsage}
            setLoading={setLoading}
            setUsername={setUsername}
            setEmail={setEmail}
            setPhone={setPhone}
            setRole={setRole}
            handleChange={handleChange}
            a11yProps={a11yProps}
            name={username}
            phoneNumber={phoneNumber}
            email={email}
            role={role}
            from={from}
            to={to}
            setPage={setPage}
            phone={phone}
            flaggedOnly={flaggedOnly}
            setFrom={setFrom}
            setTo={setTo}
            setUsers={setUsers}
            page={page}
            rowsPerPage={rowsPerPage}
            sort={sort}
            value={value}
            sortBy={sortBy}
            onClearFilters={onClearFilters}
            setIsFiltering={setIsFiltering}
            organization={organization}
            usage={usage}
            filtersLoaded={filtersLoaded}
            setFiltersLoaded={setFiltersLoaded}
            setOpenFilter={setOpenFilter}
            anchorEl={anchorEl}
            authUser={authUser}
            checked={checked}
            classes={classes}
            module={module}
            fetchFiltersConfig={fetchFiltersConfig}
            filterCount={filterCount}
            filtersApplied={filtersApplied}
            openDeleteUser={openDeleteUser}
            setOpenDeleteUser={setOpenDeleteUser}
            filtersConfig={filtersConfig}
            formatHeadersText={formatHeadersText}
            users={users}
            getVisibleColumns={getVisibleColumns}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleClick={handleClick}
            handleClose={handleClose}
            handleToggleColumn={handleToggleColumn}
            hideColumns={hideColumns}
            loading={loading}
            onEditMultipleUsers={onEditMultipleUsers}
            openFilter={openFilter}
            openPopover={openPopover}
            renderRow={renderRow}
            searchTerm={searchTerm}
            selected={selected}
            setChecked={setChecked}
            openInviteUser={openInviteUser}
            setOpenInviteUser={setOpenInviteUser}
            setSearchTerm={setSearchTerm}
            setSelected={setSelected}
            setSort={setSort}
            setSortBy={setSortBy}
            showColumns={showColumns}
            tabSelected={tabSelected}
            visibleColumns={visibleColumns}
            setVisibleColumns={setVisibleColumns}
          />
        )}

        {activeTab === TYPE_USER_TABS.VIEW && (
          <ViewTabUsers
            setValue={setValue}
            stateUsage={stateUsage}
            onDeleteUsers={onDeleteUsers}
            setUsage={setUsage}
            setLoading={setLoading}
            setUsername={setUsername}
            setEmail={setEmail}
            setPhone={setPhone}
            setRole={setRole}
            handleChange={handleChange}
            a11yProps={a11yProps}
            name={username}
            phoneNumber={phoneNumber}
            email={email}
            role={role}
            from={from}
            to={to}
            setPage={setPage}
            phone={phone}
            flaggedOnly={flaggedOnly}
            setFrom={setFrom}
            setTo={setTo}
            setUsers={setUsers}
            page={page}
            rowsPerPage={rowsPerPage}
            sort={sort}
            value={value}
            sortBy={sortBy}
            onClearFilters={onClearFilters}
            setIsFiltering={setIsFiltering}
            organization={organization}
            usage={usage}
            filtersLoaded={filtersLoaded}
            setFiltersLoaded={setFiltersLoaded}
            setOpenFilter={setOpenFilter}
            anchorEl={anchorEl}
            authUser={authUser}
            checked={checked}
            classes={classes}
            module={module}
            fetchFiltersConfig={fetchFiltersConfig}
            filterCount={filterCount}
            filtersApplied={filtersApplied}
            openDeleteUser={openDeleteUser}
            setOpenDeleteUser={setOpenDeleteUser}
            filtersConfig={filtersConfig}
            formatHeadersText={formatHeadersText}
            users={users}
            getVisibleColumns={getVisibleColumns}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleClick={handleClick}
            handleClose={handleClose}
            handleToggleColumn={handleToggleColumn}
            hideColumns={hideColumns}
            loading={loading}
            onEditMultipleUsers={onEditMultipleUsers}
            openFilter={openFilter}
            openPopover={openPopover}
            renderRow={renderRow}
            searchTerm={searchTerm}
            selected={selected}
            setChecked={setChecked}
            openInviteUser={openInviteUser}
            setOpenInviteUser={setOpenInviteUser}
            setSearchTerm={setSearchTerm}
            setSelected={setSelected}
            setSort={setSort}
            setSortBy={setSortBy}
            flagged={flagged}
            showColumns={showColumns}
            tabSelected={tabSelected}
            visibleColumns={visibleColumns}
            setVisibleColumns={setVisibleColumns}
          />
        )}
      </PageWrapper>
    </>
  );
};
const PageWrapper = styled.div`
  width: 100%;
`;

export default Users;
