import { Box, makeStyles, Tabs, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import TabPanel from '../../components/TabPanel';
import { TabComp } from '../CaseDetails/CaseDetails';

import FAQ from '../FAQ/FAQ';
import Permissions from '../Permissions/Permissions';
import Usage from '../Usage/Usage';
import ProjectTypes from '../ProjectsTypes/ProjectTypes';
import useOrganization from '../../hooks/useOrganization';
import OfficerEISTab from './OfficerEisTab';
import TasksTypes from '../TasksTypes/TasksTypes';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 20,
  },
  title: {
    marginTop: 50,
  },
  label: {
    fontSize: 12,
    color: '#aaa',
  },
  button: {
    letterSpacing: '1px',
    fontSize: 13,
    background: '#2E66FE',
    fontWeight: '400',
    width: 'fit-content',
    alignSelf: 'flex-end',
    color: '#fff',
    '&:hover': {
      background: '#4b7bff',
    },
    '& .MuiButton-label': {
      fontSize: 13,
    },
  },
  btn: {
    background: ' #1F63FF',
    textTransform: 'capitalize',
    color: '#fff',
    '&:hover': {
      background: '#1F63FF',
    },
    '& .MuiButton-label': {
      fontSize: 14,
    },
    '&:disabled': {
      background: '#1f63ff61',
      color: '#fff',
    },
  },
  buttonOutlined: {
    letterSpacing: '1px',
    fontSize: 13,
    fontWeight: '500',
    marginRight: 10,
    width: 'fit-content',
    alignSelf: 'flex-end',
    border: '1px solid transparent',
    color: '#4b7bff',
    '&:hover': {
      background: 'transparent',
      border: '1px solid transparent',
    },
    '& .MuiButton-label': {
      fontSize: 13,
    },
  },

  img: {
    width: 120,
    height: 120,
    borderRadius: '50%',
    objectFit: 'cover',
  },

  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: '#fff',
    boxShadow: theme.shadows[5],
    padding: 30,
    maxWidth: 600,
    outline: 0,
  },
  tabsContainer: {
    background: '#fff',
    borderRadius: 4,
  },
}));

const Settings = () => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const { organization, setRefetch } = useOrganization();
  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    if (organization) {
      const currentTabs = [
        {
          label: 'Projects',
          key: 'projects',
          Component: <ProjectTypes organization={organization} setRefetch={setRefetch} />,
        },
        {
          label: 'Tasks',
          key: 'tasks',
          Component: <TasksTypes organization={organization} setRefetch={setRefetch} />,
        },
        {
          label: 'FAQ',
          key: 'faq',
          Component: <FAQ organization={organization} />,
        },
        {
          label: 'Permissions',
          key: 'permissions',
          Component: <Permissions />,
        },
        {
          label: 'Usage',
          key: 'usage',
          Component: <Usage />,
        },
        {
          label: 'EIS',
          key: 'eis',
          Component: <OfficerEISTab organization={organization} setRefetch={setRefetch} />,
        },
      ];
      const disabledTabs = organization?.features?.dashboard;

      const filteredTabs = currentTabs.filter((tab) => {
        if (disabledTabs?.[tab.key]?.enabled === undefined) {
          return tab;
        }
        if (disabledTabs?.[tab.key]?.enabled) {
          return tab;
        }

        return null;
      });
      setTabs(filteredTabs);
    }
  }, [organization, setRefetch]);

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <PageWrapper className={classes.container}>
        <Typography variant="h5">Settings</Typography>
        <Box
          alignContent="center"
          justifyContent="space-between"
          display="flex"
          marginTop={2}
          className={classes.tabsContainer}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
            indicatorColor="primary"
            textColor="primary"
          >
            {tabs.map((tab, index) => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <TabComp key={index} label={tab.label} {...a11yProps(index)} />
            ))}
          </Tabs>
        </Box>
        {tabs.map((tab, index) => (
          <TabPanel key={index} value={value} index={index} padding="0">
            <Box display="flex" justifyContent="space-between" height="100%" marginBottom="0">
              {tab.Component}
            </Box>
          </TabPanel>
        ))}
      </PageWrapper>
    </>
  );
};

const PageWrapper = styled.div`
  width: 100%;
`;

export default Settings;
