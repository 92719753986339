import { CircularProgress } from '@material-ui/core';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import MenuTable from '../../components/MenuTable';
import {
  deleteInvitationUser,
  deleteUser,
  getUsage,
  resendUserInvitation,
} from '../../services/unarmed';
import DeleteIcon from '@material-ui/icons/Delete';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

const RenderMoreOption = ({
  value,
  row,
  users,
  setUsers,
  setUsage,
  organization,
}) => {
  const [loading, setLoading] = useState(false);

  const onResendInviteUser = async () => {
    setLoading(true);
    resendUserInvitation(row?._id)
      .then((res) => {
        toast.success('Invitation forwarded Successfully');
        setLoading(false);
      })
      .catch((err) => {
        toast.error(
          'Something went wrong while trying to forwarded the invitation'
        );
        setLoading(false);
      });
  };

  const onDeleteUser = async () => {
    const id = `ids[]=${row?._id}`;

    try {
      setLoading(true);

      await deleteUser(id);
      if (value === 0) {
      } else {
        await deleteInvitationUser(id);
      }
      const availableData = users.data
        .slice()
        .filter((x) => x._id !== row?._id);

      const data = await getUsage(organization?._id);
      setUsage(data);
      setUsers({ ...users, data: availableData });

      setLoading(false);
      toast.success(
        value === 0
          ? 'User deleted Successfully'
          : 'Invitation deleted Successfully!'
      );
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };

  const activeOptions = [
    {
      text: 'Delete',
      onClick: onDeleteUser,
      Icon: DeleteIcon,
    },
  ];
  const pendingOptions = [
    {
      text: 'Resend invitation',
      onClick: onResendInviteUser,
      Icon: MailOutlineIcon,
    },
  ];

  return loading ? (
    <CircularProgress color="#333" size="20px" />
  ) : (
    <MenuTable options={value === 0 ? activeOptions : pendingOptions} />
  );
};

export default RenderMoreOption;
