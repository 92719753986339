import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import DropdownResults from '../../components/DropdownResults';
import { getOrganizations, onTransferCase } from '../../services/unarmed';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 20,
  },
  title: {
    marginTop: 50,
  },
  label: {
    fontSize: 12,
    color: '#aaa',
  },
  button: {
    letterSpacing: '1px',
    fontSize: 13,
    background: '#2E66FE',
    fontWeight: '400',
    width: 'fit-content',
    alignSelf: 'flex-end',
    color: '#fff',
    '&:hover': {
      background: '#4b7bff',
    },
    '& .MuiButton-label': {
      fontSize: 13,
    },
  },

  buttonOutlined: {
    letterSpacing: '1px',
    fontSize: 13,
    fontWeight: '500',
    marginRight: 10,
    width: 'fit-content',
    alignSelf: 'flex-end',
    border: '1px solid transparent',
    color: '#4b7bff',
    '&:hover': {
      background: 'transparent',
      border: '1px solid transparent',
    },
    '& .MuiButton-label': {
      fontSize: 13,
    },
    '&:disabled': {
      border: 0,
    },
  },

  img: {
    width: 120,
    height: 120,
    borderRadius: '50%',
    objectFit: 'cover',
  },

  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: '#fff',
    boxShadow: theme.shadows[5],
    padding: 30,
    maxWidth: 500,
  },

  alertContainer: {
    height: 'fit-content',
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    background: '#FFFBF3',
    borderLeft: '6px solid #FFC64C',
    borderRadius: 2,
    marginTop: 10,
    marginBottom: 20,
    padding: '10px',
  },
}));

export default function TransferCase({ setOpen, organizationName, id, setForm, setIsRequestingData }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [organzations, setOrganizations] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [confirm, setConfirm] = useState(false);

  React.useState(() => {
    getOrganizations()
      .then(({ data }) => setOrganizations(data))
      .catch((err) => console.log(err));
  }, []);

  const transferCase = () => {
    if (!selectedOrg || !confirm) {
      toast.error('Please Select an Organization and confirm the case transfer');
      return;
    }
    setLoading(true);
    onTransferCase(id, {
      transferOrganizationId: selectedOrg?._id,
    })
      .then(({ data }) => {
        setLoading(false);
        setOpen(false);
        setIsRequestingData(true);
        setForm((prevState) => ({ ...prevState, ...data }));
        toast.success('The Case was transferred Successfully');
        setIsRequestingData(false);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.error?.details[0]?.message || error?.response?.data?.message);
        setLoading(false);
      });
  };

  return (
    <>
      <Typography variant="h6">Transfer Cases</Typography>

      <Box className={classes.alertContainer}>
        <InfoIcon htmlColor="#FFC64C" style={{ marginRight: 12, fontSize: 15 }} />
        <Typography style={{ fontSize: 13 }}>
          You can only transfer this case to one organization. Please confirm case transfer in the checkbox below. This
          action cannot be undone.
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            id="standard-required1"
            label={<Typography> From</Typography>}
            style={{ width: '100%' }}
            value={organizationName}
            disabled
          />
        </Grid>
        <Grid item xs={12} style={{ position: 'relative' }}>
          <TextField
            id="standard-required1"
            label={<Typography> To</Typography>}
            style={{ width: '100%' }}
            value={selectedOrg?.name || ''}
            onClick={() => setShowDropdown(true)}
          />
          {showDropdown && (
            <DropdownResults
              list={organzations}
              setSelectedOrg={setSelectedOrg}
              setOpen={setShowDropdown}
              open={showDropdown}
            />
          )}
        </Grid>
        <Grid item xs={12} style={{ marginTop: 15 }}>
          <FormControlLabel
            value="end"
            control={<Checkbox color="primary" checked={confirm} onChange={() => setConfirm(!confirm)} />}
            label="Confirm case transfer"
            labelPlacement="end"
          />
        </Grid>
      </Grid>
      <Box display="flex" alignItems="center" justifyContent="flex-end" marginTop="20px">
        <Button
          variant="outlined"
          className={classes.buttonOutlined}
          style={{ color: '#333' }}
          onClick={() => setOpen(false)}
        >
          CANCEL
        </Button>
        <Button
          style={{ border: 0 }}
          variant="outlined"
          disabled={!confirm || loading}
          className={classes.buttonOutlined}
          onClick={transferCase}
        >
          {loading ? <CircularProgress color="#fff" size="25px" /> : 'Transfer'}
        </Button>
      </Box>
    </>
  );
}
