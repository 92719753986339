import {
  ClickAwayListener,
  Grow,
  makeStyles,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() => ({
  container: {
    height: 80,
    width: '100%',
    overflowY: 'auto',
    borderRadius: 4,
    boxShadow: '0 1px 3px #00000033',
    background: '#fff',
    position: 'absolute',
    zIndex: 1,
  },
  li: {
    padding: 10,
    fontSize: 14,
    transition: 'ease all .3s',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f2f2f2',
    },
  },
}));

export default function DropdownResults({
  list = [],
  setSelectedOrg,
  setOpen,
  open,
}) {
  const classes = useStyles();
  const anchorRef = React.useRef(null);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  return (
    <div className={classes.container}>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: 9, position: 'absolute', width: '100%' }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  {list.map((item) => (
                    <MenuItem
                      item={item.id}
                      className={classes.li}
                      onClick={() => {
                        handleClose();
                        setSelectedOrg(item);
                      }}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}
