import React from 'react';
import AddressInformation from '../components/AddressInformation';
import ContactInfo from '../components/ContactInfo';
import Date from '../components/Date';
import DateOfBirth from '../components/DateOfBirth';
import Demographics from '../components/Demographics';
import Dropdown from '../components/Dropdown';
import Email from '../components/Email';
import File from '../components/FIle';
import Gender from '../components/Gender';
import Hour from '../components/Hour';
import LongText from '../components/LongText';
import MultipleChoice from '../components/MultipleChoice';
import Phone from '../components/Phone';
import Police from '../components/Police';
import PrimaryLanguage from '../components/PrimaryLanguage';
import QuestionBankSideComponent from '../components/QuestionBankSideComponent';
import Race from '../components/Race';
import SexualOrientation from '../components/SexaulOrientation';
import ShortText from '../components/ShortText';
import SingleChoice from '../components/SingleChoice';
import Subtitle from '../components/Subtitle';
import Title from '../components/Title';
import Url from '../components/URL';
import Witnesses from '../components/Witnesses';
import YesNo from '../components/YesNo';
import RaceEthnicity from '../components/RaceEthnicity';
import Ethnicity from '../components/Ethnicity';

/**
 * @description This function is used to inject special props to the component that is about to be rendered
 */
const onAddPropsToComponent = ({
  Component,
  componentEnhanced,
  componentId,
  deleteComponent,
  componentIndex,
  onChangeOptions,
  onUpdateJson,
  refActionModal,
  setModal,
  handleSubComponents = false,
  isSubComponent,
}) => {
  componentEnhanced.component = Component;
  componentEnhanced.onDeleteComponent = () => deleteComponent(componentIndex);

  if (handleSubComponents) {
    componentEnhanced.onUpdateJson = (data, subProp) => onUpdateJson(componentIndex, data, subProp);
    componentEnhanced.onUpdateJsonTop = (data) => onUpdateJson(componentIndex, data);
  } else if (isSubComponent) {
    componentEnhanced.onUpdateJson = (data, subProp) => onUpdateJson(data, subProp);
  } else {
    componentEnhanced.onUpdateJson = (data) => onUpdateJson(componentIndex, data);
  }
  componentEnhanced.isSubComponent = isSubComponent;
  componentEnhanced.componentIndex = componentIndex;
  componentEnhanced.onChangeOptions = onChangeOptions;
  componentEnhanced.refActionModal = refActionModal;
  componentEnhanced.setModal = setModal;
};

const getComponent = (components, type) => {
  for (const component of components) {
    if (component.type === type) {
      return component;
    }
  }
  return null;
};

/*
  Fix demographic component, adding race and ethnicity,
  so race and ethnicity are displayed for old forms where demographics was already included without those fields
*/
const fixDemographicComponentForRaceAndEthnicity = (component) => {
  if (component.type !== 'demographics') {
    return;
  }

  const race = getComponent(component.components, 'race');
  const ethnicity = getComponent(component.components, 'ethnicity');
  if (!race && !ethnicity) {
    component.components.splice(1, 0, {
      type: 'ethnicity',
      data: {
        visible: false,
        question: 'Ethnicity',
        required: false,
        multipleChoice: false,
        options: [
          'Black or African American',
          'Hispanic or Latinx',
          'Asian',
          'Native Hawaiian or Pacific Islander',
          'American Indian or Alaskan Native',
          'Middle Eastern or South Asian',
          'Multiracial',
          'White',
          'Not Listed',
          'Prefer not to answer',
        ],
      },
      name: '',
      id: 'WPrblNyorF2',
      shouldRenderToggle: true,
    });
    component.components.splice(3, 0, {
      type: 'race',
      data: {
        visible: false,
        question: 'Race',
        required: false,
        multipleChoice: false,
        options: [
          'Black or African American',
          'Hispanic or Latinx',
          'Asian',
          'Native Hawaiian or Pacific Islander',
          'American Indian or Alaskan Native',
          'Middle Eastern or South Asian',
          'Multiracial',
          'White',
          'Not Listed',
          'Prefer not to answer',
        ],
      },
      name: '',
      id: 'WPrblNyorF1',
      shouldRenderToggle: true,
    });
  }
};

/**
 * @description This function is used to get the config for the components that are going to be rendered
 */
export const getConfig = ({
  componentsFromServer = [],
  deleteComponent,
  onChangeOptions,
  onUpdateJson,
  refActionModal,
  setModal,
  includeRequired = true,
  isSubComponent = false,
}) =>
  componentsFromServer.map((component, index) => {
    const componentEnhanced = { ...component, includeRequired };
    switch (component.type) {
      case 'questionBank':
        onAddPropsToComponent({
          onUpdateJson,
          Component: QuestionBankSideComponent,
          deleteComponent,
          componentEnhanced,
          componentIndex: index,
          refActionModal,
          setModal,
        });
        break;
      case 'demographics':
        fixDemographicComponentForRaceAndEthnicity(component);
        onAddPropsToComponent({
          onUpdateJson,
          Component: Demographics,
          deleteComponent,
          componentEnhanced,
          componentIndex: index,
          refActionModal,
          setModal,
          handleSubComponents: true,
        });
        break;
      case 'title':
        onAddPropsToComponent({
          onUpdateJson,
          Component: Title,
          deleteComponent,
          componentEnhanced,
          componentIndex: index,
          refActionModal,
          setModal,
        });
        break;
      case 'primaryLanguage':
        onAddPropsToComponent({
          onUpdateJson,
          Component: PrimaryLanguage,
          deleteComponent,
          componentEnhanced,
          componentIndex: index,
          refActionModal,
          setModal,
        });
        break;
      case 'subtitle':
        onAddPropsToComponent({
          onUpdateJson,
          Component: Subtitle,

          componentEnhanced,
          componentIndex: index,
          deleteComponent,
          refActionModal,
          setModal,
        });
        break;
      case 'singleChoice':
        onAddPropsToComponent({
          onUpdateJson,
          Component: SingleChoice,

          componentEnhanced,
          componentIndex: index,
          deleteComponent,
          refActionModal,
          setModal,
        });
        break;
      case 'multipleChoices':
        onAddPropsToComponent({
          onUpdateJson,
          Component: MultipleChoice,

          componentEnhanced,
          componentIndex: index,
          deleteComponent,
          refActionModal,
          setModal,
        });
        break;
      case 'dropdown':
        onAddPropsToComponent({
          onUpdateJson,
          Component: Dropdown,

          componentEnhanced,
          componentIndex: index,
          deleteComponent,
          refActionModal,
          setModal,
        });
        break;
      case 'gender':
        onAddPropsToComponent({
          onUpdateJson,
          Component: Gender,

          componentEnhanced,
          componentIndex: index,
          deleteComponent,
          refActionModal,
          setModal,
          isSubComponent,
        });
        break;
      case 'sexualOrientation':
        onAddPropsToComponent({
          onUpdateJson,
          Component: SexualOrientation,

          componentEnhanced,
          componentIndex: index,
          deleteComponent,
          refActionModal,
          setModal,
          isSubComponent,
        });
        break;
      case 'raceEthnicity':
        onAddPropsToComponent({
          onUpdateJson,
          Component: RaceEthnicity,

          componentEnhanced,
          componentIndex: index,
          deleteComponent,
          refActionModal,
          setModal,
          isSubComponent,
        });
        break;
      case 'race':
        onAddPropsToComponent({
          onUpdateJson,
          Component: Race,

          componentEnhanced,
          componentIndex: index,
          deleteComponent,
          refActionModal,
          setModal,
          isSubComponent,
        });
        break;
      case 'ethnicity':
        onAddPropsToComponent({
          onUpdateJson,
          Component: Ethnicity,

          componentEnhanced,
          componentIndex: index,
          deleteComponent,
          refActionModal,
          setModal,
          isSubComponent,
        });
        break;
      case 'yesNo':
        onAddPropsToComponent({
          onUpdateJson,
          Component: YesNo,

          componentEnhanced,
          componentIndex: index,
          deleteComponent,
          refActionModal,
          setModal,
        });
        break;
      case 'shortText':
        onAddPropsToComponent({
          onUpdateJson,
          Component: ShortText,

          componentEnhanced,
          componentIndex: index,
          deleteComponent,
          refActionModal,
          setModal,
        });
        break;
      case 'longText':
        onAddPropsToComponent({
          onUpdateJson,
          Component: LongText,
          componentEnhanced,
          componentIndex: index,
          deleteComponent,
          refActionModal,
          setModal,
        });
        break;
      case 'fileUpload':
        onAddPropsToComponent({
          onUpdateJson,
          Component: File,

          componentEnhanced,
          componentIndex: index,
          deleteComponent,
          refActionModal,
          setModal,
        });
        break;
      case 'url':
        onAddPropsToComponent({
          onUpdateJson,
          Component: Url,

          componentEnhanced,
          componentIndex: index,
          deleteComponent,
          refActionModal,
          setModal,
        });
        break;
      case 'contactInformation':
        onAddPropsToComponent({
          onUpdateJson,
          Component: ContactInfo,

          componentEnhanced,
          componentIndex: index,
          deleteComponent,
          refActionModal,
          setModal,
        });
        break;
      case 'policeOfficersOrEmployees':
        onAddPropsToComponent({
          onUpdateJson,
          Component: Police,
          onChangeOptions,

          componentEnhanced,
          componentIndex: index,
          deleteComponent,
          refActionModal,
          setModal,
        });
        break;
      case 'witnesses':
        onAddPropsToComponent({
          onUpdateJson,
          Component: Witnesses,
          onChangeOptions,

          componentEnhanced,
          componentIndex: index,
          deleteComponent,
          refActionModal,
          setModal,
        });
        break;
      case 'addressInformation':
        onAddPropsToComponent({
          onUpdateJson,
          Component: AddressInformation,

          componentEnhanced,
          componentIndex: index,
          deleteComponent,
          refActionModal,
          setModal,
        });
        break;
      case 'date':
        onAddPropsToComponent({
          onUpdateJson,
          Component: Date,

          componentEnhanced,
          componentIndex: index,
          deleteComponent,
          refActionModal,
          setModal,
        });
        break;
      case 'dateOfBirth':
        onAddPropsToComponent({
          onUpdateJson,
          Component: DateOfBirth,

          componentEnhanced,
          componentIndex: index,
          deleteComponent,
          refActionModal,
          setModal,
          isSubComponent,
        });
        break;
      case 'hour':
        onAddPropsToComponent({
          onUpdateJson,
          Component: Hour,

          componentEnhanced,
          componentIndex: index,
          deleteComponent,
          refActionModal,
          setModal,
        });
        break;
      case 'phone':
        onAddPropsToComponent({
          onUpdateJson,
          Component: Phone,

          componentEnhanced,
          componentIndex: index,
          deleteComponent,
          refActionModal,
          setModal,
        });
        break;
      case 'email':
        onAddPropsToComponent({
          onUpdateJson,
          Component: Email,

          componentEnhanced,
          componentIndex: index,
          deleteComponent,
          refActionModal,
          setModal,
        });
        break;

      default:
        return <div>Unsupported Component</div>;
    }

    return componentEnhanced;
  });
