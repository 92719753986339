import { Box, Typography } from '@material-ui/core';
import React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';

import { OutLineInputBox } from '../../../styles/GlobalStyles';

export default function MonitorItem({ onChange, value, handleDelete }) {
  return (
    <Box display="flex" alignItems="center">
      <OutLineInputBox
        value={value}
        onChange={onChange}
        placeholder="0"
        type="number"
        min={0}
        style={{ width: 40, textAlign: 'center', marginRight: 20 }}
      />
      <Typography>Months follow up</Typography>
      <DeleteIcon htmlColor="#6D7385" onClick={handleDelete} style={{ marginLeft: 15, cursor: 'pointer' }} />
    </Box>
  );
}
