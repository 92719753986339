import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  Tooltip,
  Typography,
  Popover,
  IconButton,
  List,
  ListItem,
  Divider,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import moment from 'moment';
import PageButtons from '../../components/PageButtons';
import EnhancedTable from '../../components/Table';
import { fetchContacts, getfiltersConfigById, getViewByIdv, updateFilterConfigById } from '../../services/unarmed';
import AddMore from '../../components/AddMore';
import { TYPE_USER_TABS } from '../../utils/constants';
import ContactsFilters from '../../components/ContactsFilters';

export const ViewTabContacts = ({
  classes,
  contacts,
  handleClick,
  openPopover,
  anchorEl,
  handleClose,
  searchTerm,
  setSearchTerm,
  showColumns,
  handleToggleColumn,
  formatHeadersText,
  visibleColumns,
  hideColumns,
  openFilter,
  setOpenFilter,
  setLoadingFilters,
  loadingFilters,
  onEditMultipleContacts,
  selected,
  setOpen,
  name,
  phoneNumber,
  email,
  module,
  flaggedOnly,
  type,
  fetchFiltersConfig,
  setOpenCreate,
  onClearFilters,
  setPage,
  rowsPerPage,
  sort,
  sortBy,
  authUser,
  setSort,
  filtersLoaded,
  setFiltersLoaded,
  getVisibleColumns,
  handleOnClickDownload,
  // handleOnFetchContactsWithFilters,
  handleOnFetchAllContacts,
  setSortBy,
  parseContactPhoneNumber,
  setContacts,
  renderRow,
  setChecked,
  checked,
  page,
  setSelected,
  handleChangeRowsPerPage,
  tabSelected,
  setVisibleColumns,
  loading,
}) => {
  const [view, setView] = useState();
  const [filtersConfig, setfiltersConfig] = useState({});
  const [filtersApplied, setFiltersApplied] = useState({});
  const [filterCount, setFilterCount] = useState(0);

  const handleOnSetFiltersConfig = () => {
    const newFiltersApplied = {};
    if (filtersConfig) {
      const configCopy = { ...filtersConfig };

      delete configCopy.sortOrder;
      delete configCopy.sortBy;
      delete configCopy.page;
      delete configCopy.limit;
      delete configCopy.isFiltering;
      Object.keys(configCopy).forEach((key) => {
        const value = configCopy[key];
        let isApplied = false;

        if (value === null || !value || value === '0') {
          isApplied = false;
        } else if (Array.isArray(value)) {
          isApplied = value.length > 0;
        } else if (typeof value === 'boolean') {
          isApplied = value === true;
        } else {
          isApplied = true;
        }

        newFiltersApplied[key] = isApplied;
      });

      const newCount = Object.values(newFiltersApplied).filter((item) => item).length;
      setFilterCount(newCount);
      setFiltersApplied(newFiltersApplied);
    }
  };

  const getData = () => {
    const params = {
      ...(email.value ? { email: email.value } : {}),
      ...(name.value ? { name: name.value } : {}),
      ...(phoneNumber.value ? { phoneNumber: parseContactPhoneNumber(phoneNumber.value) } : {}),
      ...(module.value && module.value !== '0'
        ? { module: module.value === 'not specified' ? 'none' : module.value }
        : {}),
      ...{ flaggedOnly: flaggedOnly.value },
      // ...(type.value && type.value !== '0' ? { type: type.value === 'not specified' ? 'none' : type.value } : {}),
      ...(filtersConfig ? { filtersConfigId: filtersConfig?._id } : {}),
      sortBy,
      sortOrder: sort,
      page,
      limit: rowsPerPage,
    };

    fetchContacts(params)
      .then((res) => {
        setLoadingFilters(false);
        setContacts(res);
        //fetchFiltersConfig();
      })
      .catch((error) => {
        const message = error?.response?.data?.error?.details[0]?.message || error?.response?.data?.message;

        setLoadingFilters(false);
      });
  };

  const getData1 = ({ email1, name1, phoneNumber1, module1, flaggedOnly1 }) => {
    const params = {
      ...(email1 ? { email: email1 } : {}),
      ...(name1 ? { name: name1 } : {}),
      ...(phoneNumber1 ? { phoneNumber: parseContactPhoneNumber(phoneNumber1) } : {}),
      ...(module1 && module1 !== '0' ? { module: module1 === 'not specified' ? 'none' : module1 } : {}),
      ...{ flaggedOnly: flaggedOnly1 },
      // ...(type.value && type.value !== '0' ? { type: type.value === 'not specified' ? 'none' : type.value } : {}),
      ...(filtersConfig ? { filtersConfigId: filtersConfig?._id } : {}),
      sortBy,
      sortOrder: sort,
      page,
      limit: rowsPerPage,
    };

    fetchContacts(params)
      .then((res) => {
        setLoadingFilters(false);
        setContacts(res);
        //fetchFiltersConfig();
      })
      .catch((error) => {
        const message = error?.response?.data?.error?.details[0]?.message || error?.response?.data?.message;

        setLoadingFilters(false);
      });
  };
  const saveFiltersApplied = async () => {
    setLoadingFilters(true);
    const filterSaved = {
      name: name.value,
      email: email.value,
      phoneNumber: phoneNumber.value,
      module: module.value,
      sortBy,
      sortOrder: sort,
      flaggedOnly: flaggedOnly.value,
    };
    setfiltersConfig({ ...filtersConfig, ...filterSaved });
    await updateFilterConfigById(view?.filtersConfigId, filterSaved);
  };

  const onClearFiltersF = async () => {
    onClearFilters()
    setLoadingFilters(true);
    const filterSaved = {
      name: '',
      email: '',
      phoneNumber: '',
      module: '',
      sortBy,
      sortOrder: sort,
      flaggedOnly: false,
    };
    setfiltersConfig({ ...filtersConfig, ...filterSaved });
    await updateFilterConfigById(view?.filtersConfigId, filterSaved);
  };

  useEffect(() => {
    handleOnSetFiltersConfig();
    if (filtersConfig) {
      console.log('entro aqui', filtersConfig)
      email.setValue(filtersConfig?.email);
      name.setValue(filtersConfig?.name);
      phoneNumber.setValue(filtersConfig?.phoneNumber);
      setSort(sort || filtersConfig?.sortOrder);
      setSortBy(sortBy || filtersConfig?.sortBy);
      module.setValue(filtersConfig?.module);
      flaggedOnly.setValue(filtersConfig?.flaggedOnly);
      // setValue(config?.type || '');

      setFiltersLoaded(true);
      getData1({
        email1: filtersConfig.email,
        name1: filtersConfig.name,
        phoneNumber1: filtersConfig.phoneNumber,
        module1: filtersConfig.module,
        flaggedOnly1: filtersConfig.flaggedOnly,
      });
    } else if (filtersConfig === null) {
      setFiltersLoaded(true);
      getData();
    }
  }, [filtersConfig, page, sort, sortBy]);

  useEffect(() => {
    const fetchData = async () => {
      if (view) {
        const responsefiltersConfig = await getfiltersConfigById(view?.filtersConfigId);
        setfiltersConfig(responsefiltersConfig?.data?.filterConfig[0]?.config);
      }
    };

    fetchData();
  }, [view]);

  useEffect(() => {
    const fetchData = async () => {
      if (tabSelected?.displayObjectId && tabSelected?.displayObjectType === TYPE_USER_TABS.VIEW) {
        const responseView = await getViewByIdv(tabSelected?.displayObjectId);
        if (responseView?.data?.view[0]) {
          setView(responseView.data.view[0]);
          setVisibleColumns(responseView.data.view[0].columns);
        }
      }
    };

    fetchData();
  }, [tabSelected]);

  return (
    <>
      <>
        <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2} marginTop="35px">
          <Box display="flex" alignItems="center">
            <Typography className={classes.title} variant="subtitle2" id="tableTitle" component="div">
              All Contacts ({contacts?.headers?.['x-pagination-totalitems']})
            </Typography>

            <AddMore text="" marginLeft="0" marginTop="0" onClick={handleClick} />
            <Popover
              open={openPopover}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
              <Box p={2} width="240px" height="400px" style={{ overflow: 'auto' }}>
                <Box display="flex" justifyContent="center">
                  <Typography variant="h7" style={{ marginBottom: '2px', fontWeight: 'bold' }}>
                    Show / Hide Columns
                  </Typography>
                </Box>

                <Divider style={{ marginTop: '2px', marginBottom: '2px' }} />

                <TextField
                  placeholder="Search columns"
                  variant="standard"
                  fullWidth
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon style={{ fontSize: '12px' }} />
                      </InputAdornment>
                    ),
                    disableUnderline: true,
                    style: {
                      fontSize: '12px',
                      height: '28px',
                    },
                  }}
                  style={{
                    padding: '5px 0',
                    backgroundColor: 'transparent',
                    fontSize: '12px',
                    height: '28px',
                  }}
                />

                <Divider style={{ marginTop: '7px', marginBottom: '5px' }} />

                <Box display="flex" justifyContent="center">
                  <Typography variant="caption" style={{ marginBottom: '5px', fontSize: '14px', fontWeight: 'bold' }}>
                    Visible
                  </Typography>
                </Box>

                <List dense style={{ padding: 0 }}>
                  {showColumns.map((column) => (
                    <ListItem
                      key={column}
                      button
                      onClick={() => handleToggleColumn(view._id, column)}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '.1px',
                      }}
                    >
                      <Tooltip title={formatHeadersText(column)}>
                        <Typography variant="caption" style={{ lineHeight: '1.2' }}>
                          {formatHeadersText(column)}
                        </Typography>
                      </Tooltip>
                      <Checkbox
                        checked={visibleColumns[column]}
                        color="primary"
                        style={{ transform: 'scale(0.7)', paddingTop: '2px', paddingBottom: '2px' }}
                      />
                    </ListItem>
                  ))}
                </List>

                <Divider style={{ margin: '10px 0' }} />

                <Box display="flex" justifyContent="center">
                  <Typography
                    variant="caption"
                    style={{ marginBottom: '5px', marginTop: '5px', fontSize: '14px', fontWeight: 'bold' }}
                  >
                    Hidden
                  </Typography>
                </Box>

                <List dense style={{ padding: 0 }}>
                  {hideColumns.map((column) => (
                    <ListItem
                      key={column}
                      button
                      onClick={() => handleToggleColumn(view._id, column)}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '.1px',
                      }}
                    >
                      <Tooltip title={formatHeadersText(column)}>
                        <Typography variant="caption" style={{ lineHeight: '1.2' }}>
                          {formatHeadersText(column)}
                        </Typography>
                      </Tooltip>
                      <Checkbox
                        checked={visibleColumns[column]}
                        color="primary"
                        style={{ transform: 'scale(0.7)', paddingTop: '2px', paddingBottom: '2px' }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Popover>
          </Box>

          <PageButtons
            filterCount={filterCount}
            text=""
            length={contacts?.headers?.['x-pagination-totalitems']}
            openFilter={openFilter}
            downloadButtonText="Create Contact"
            filterButtonDataTestId="contacts-button-open-filters"
            deleteButtonDataTestId="contacts-button-delete-contacts"
            downloadButtonDataTestId="contacts-button-create-contact"
            setOpenFilter={setOpenFilter}
            onClickFlag={onEditMultipleContacts}
            disabledFlag={selected.length === 0}
            onClickDelete={() => setOpen(true)}
            disabledDelete={selected.length === 0}
            onClickDownload={handleOnClickDownload}
            disabledDownload={false}
          />
        </Box>

        {openFilter && (
          <ContactsFilters
            filtersApplied={filtersApplied}
            name={name}
            phoneNumber={phoneNumber}
            email={email}
            module={module}
            flaggedOnly={flaggedOnly}
            // type={type}
            onClearFilters={onClearFiltersF}
            onClickApply={() => saveFiltersApplied()}
            loading={loadingFilters}
            onClickCancel={() => {
              handleOnFetchAllContacts();
              setOpenFilter(false);
            }}
          />
        )}

        <Box>
          <EnhancedTable
            sort={sort}
            setSort={setSort}
            columns={getVisibleColumns()}
            rows={contacts?.data}
            showCheckBox
            setSortBy={setSortBy}
            rowRender={renderRow}
            setChecked={setChecked}
            checked={checked}
            loading={loading}
            page={page}
            setPage={setPage}
            selected={selected}
            setSelected={setSelected}
            count={contacts?.headers?.['x-pagination-totalitems']}
            rowsPerPage={rowsPerPage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 15, 20, 50, 100]}
          />
        </Box>
      </>
    </>
  );
};
