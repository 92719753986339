/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { toast } from 'react-toastify';
import AddMore from '../../../components/AddMore';
import { addRecommendation } from '../../../services/unarmed';
import RecommendationItem from '../components/RecommendationItem';
import { BoxLeftContainer } from '../../../styles/GlobalStyles';
import Card from '../../../components/Card';

/**
 * Recommendations component displays a list of recommendations for a project.
 * @param {object} props - Component props.
 * @param {string} props.projectId - ID of the project.
 * @param {array} props.projectRecommendations - Array of project recommendations.
 * @param {function} props.refetch - Function to refetch data.
 * @returns {JSX.Element} Recommendations component.
 */
export default function Recommendations({ projectId, projectRecommendations, refetch }) {
  const [recommendations, setRecommendations] = useState([]);
  const [loadingAddRecommendation, setLoadingAddRecommendation] = useState(false);

  useEffect(() => {
    if (projectRecommendations) {
      const projectRecommendationsFormat = projectRecommendations?.map((recommendation) => ({
        id: recommendation?._id,
        description: recommendation?.description || '',
        status: recommendation?.status?.toLowerCase(),
        edit: false,
        notes: recommendation?.notes || '',
        submittedDate: recommendation?.submittedDate,
        responseDate: recommendation?.responseDate,
      }));
      setRecommendations(projectRecommendationsFormat);
    }
  }, [projectRecommendations]);

  const toggleEditRecommendation = (index) => {
    const recommendationsCopy = [...recommendations];
    recommendationsCopy[index].edit = !recommendationsCopy[index]?.edit;
    setRecommendations(recommendationsCopy);
  };

  const onAddRecommendation = async () => {
    try {
      setLoadingAddRecommendation(true);
      await addRecommendation(projectId);
      refetch();
      toast.success('Recommendation added successfully');
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoadingAddRecommendation(false);
    }
  };

  return (
    <BoxLeftContainer>
      <Card title="Recommendation">
        {recommendations?.length === 0 && (
          <Typography variant="body2" style={{ fontWeight: '500', fontSize: 14, textAlign: 'center' }}>
            There is not any recommendations added yet
          </Typography>
        )}
        {recommendations?.map((recommendation, index) => (
          <RecommendationItem
            {...recommendation}
            key={recommendation?.id}
            projectId={projectId}
            refetch={refetch}
            toggleEditRecommendation={() => toggleEditRecommendation(index)}
          />
        ))}
        <AddMore
          dataTestId="projects-recommendations-button-add-new"
          text="Add a Recommendation"
          marginLeft="40%"
          onClick={onAddRecommendation}
          disabled={loadingAddRecommendation}
        />
      </Card>
    </BoxLeftContainer>
  );
}

// PropTypes for type checking
Recommendations.propTypes = {
  projectId: PropTypes.string.isRequired,
  projectRecommendations: PropTypes.array.isRequired,
  refetch: PropTypes.func.isRequired,
};
