import React from 'react';

import DndLogicItem from './DndLogicItem';

import Input from '../../../components/Input';

export default function Text({ onChange, setLogicItems, index, onRemoveItem, logic }) {
  return (
    <DndLogicItem title="Text" onRemove={onRemoveItem}>
      <Input
        placeholder="Add a value"
        style={{ width: 200 }}
        onChange={(e) => {
          onChange(e, setLogicItems, index, 'value');
        }}
        value={logic?.value}
      />
    </DndLogicItem>
  );
}
