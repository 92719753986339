import { Box } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { useDrag } from 'react-dnd';
import { QUESTION_BANK } from '../../formEngine/utils/constants';

const CntQuestion = styled.div`
  display: flex;
  width: 100%;
  border-radius: 4px;
  background-color: white;
  height: auto;
  min-height: 50px;
  max-height: auto;
  cursor: pointer;
  margin-bottom: 10px;
`;

const CntCard = styled.div`
  display: flex;
  width: 95%;
  padding-right: 10px;
  align-items: center;
`;

const CntDrag = styled.div`
  width: 5%;
  min-height: 50px;
  max-height: auto;
  background-color: #6d738526;
  opacity: 1;
  border-start-start-radius: 4px;
  border-end-start-radius: 4px;
  margin-right: 5px;
`;

const CntOne = styled.div`
  width: 95%;
`;

const CntRequired = styled.div`
  display: flex;
  background-color: ${(props) => (props.isActive ? '#fc0000' : 'transparent')};
  border-radius: 200px;
  border: ${(props) => (props.isActive ? 0 : 1)}px solid black;
  width: 18px;
  height: 18px;
  justify-content: center;
  align-items: center;
`;

const CntContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  // border: 1px solid red;
`;

const PointCnt = styled.div`
  background-image: radial-gradient(
    circle,
    currentcolor 1.2px,
    transparent 1.2px
  );
  background-position-y: bottom;
  color: #d6d6d3;
  background-size: 1ex 6px;
  background-repeat: repeat-x repeat-y;
  height: 100%;
`;

const TextShow = styled.p`
  color: #2e66fe;
  font-size: 12px;
`;

const TextTitle = styled.p`
  margin: 0px;
  padding: 0px;
  font-size: 14px;
`;

const TextOptions = styled.p`
  margin: 0px;
  padding: 0px;
  padding-left: 10px;
  font-size: 11px;
  color: #2e66fe;
`;

const TextRequired = styled.p`
  color: ${(props) => (props.isActive ? 'white' : 'black')};
  font-weight: 700;
  font-size: 12px;
  margin: 0px;
  padding: 0px;
`;

export default function QuestionBankComponent({
  data,
  handleToggleOptions,
  questionRequiredActive,
  isDefaultForm,
}) {
  const [{ opacity }, drag] = useDrag({
    item: {
      type: QUESTION_BANK,
      components: data.components,
      id: data._id,
      componentType: data.type,
    },
    type: QUESTION_BANK,
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
    }),
  });

  const renderQuestionBankContent = () => (
    <CntQuestion>
      <CntDrag>
        <PointCnt />
      </CntDrag>
      <CntCard>
        <CntContent>
          <CntOne>
            <TextTitle>{data.label}</TextTitle>
            {data.components[0]?.data?.options?.length > 0 ? (
              <>
                <Box
                  onClick={handleToggleOptions}
                  display="flex"
                  alignItems="center"
                >
                  {data.toggleOptions ? (
                    <ArrowDropDownIcon htmlColor="#2E66FE" />
                  ) : (
                    <ArrowRightIcon htmlColor="#2E66FE" />
                  )}
                  <TextShow>Show fields</TextShow>
                </Box>
                {data.toggleOptions
                  ? data.components[0]?.data?.options.map((item) => (
                      <TextOptions>
                        - {typeof item === 'string' ? item : item.label}
                      </TextOptions>
                    ))
                  : null}
              </>
            ) : null}
            {data.showAnswers ? (
              <Box display="flex" alignItems="center">
                <ArrowRightIcon htmlColor="#2E66FE" />
                <TextShow>Show answers</TextShow>
              </Box>
            ) : null}
          </CntOne>
          {data.required ? (
            <CntRequired title="Is required" isActive={questionRequiredActive}>
              <TextRequired isActive={questionRequiredActive}>¡</TextRequired>
            </CntRequired>
          ) : null}
        </CntContent>
      </CntCard>
    </CntQuestion>
  );

  return isDefaultForm ? (
    <div className="sideBarItem">{renderQuestionBankContent()}</div>
  ) : (
    <div className="sideBarItem" ref={drag} style={{ opacity }}>
      {renderQuestionBankContent()}
    </div>
  );
}
