/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Typography } from '@material-ui/core';
import ReactCodeInput from 'react-code-input';
import PropTypes from 'prop-types';

const inputStyle = {
  border: 0,
  borderBottom: '1px solid #000000',
  height: 44,
  width: 44,
  fontFamily: 'Roboto',
  fontSize: 20,
  color: '#273240',
  outline: 'none',
  textAlign: 'center',
};

export default function MfaStep2({ phoneNumber, code, setCode, resendCode }) {
  return (
    <>
      <Typography style={{ marginTop: 10, fontSize: 14, color: '#000000AD' }}>
        We sent a message to <strong>{phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/g, '($1) $2-$3')}</strong> with a
        verification code. please enter the code in the field below to continue.
      </Typography>
      <ReactCodeInput
        type="number"
        fields={6}
        inputMode="numeric"
        inputStyle={inputStyle}
        value={code}
        onChange={(cd) => {
          setCode(cd);
        }}
      />
      <Typography style={{ marginTop: 20, fontSize: 14, color: '#000000AD' }} align="center">
        Didn’t get?{' '}
        <strong onClick={resendCode} style={{ cursor: 'pointer', color: '#2E66FE' }}>
          Resend
        </strong>
      </Typography>
    </>
  );
}

MfaStep2.propTypes = {
  phoneNumber: PropTypes.object,
  code: PropTypes.object,
  setCode: PropTypes.func,
  resendCode: PropTypes.func,
};
