import { Box, Button, CircularProgress, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() => ({
  button: {
    background: ' #1F63FF',
    textTransform: 'capitalize',
    marginRight: 10,
    color: '#fff',
    height: 36,
    width: 30,
    '&:hover': {
      background: '#1F63FF',
    },
    '& .MuiButton-label': {
      fontSize: 13,
    },
    '&:disabled': {
      background: '#1f63ff61',
      color: '#fff',
    },
  },
  button2: {
    background: ' #1F63FF',
    textTransform: 'capitalize',
    color: '#fff',
    '&:hover': {
      background: '#1F63FF',
    },
    '& .MuiButton-label': {
      fontSize: 14,
    },
  },
  buttonOutlined: {
    letterSpacing: '1px',
    fontSize: 13,
    fontWeight: '500',
    marginRight: 10,
    width: 'fit-content',
    alignSelf: 'flex-end',
    border: 0,
    color: '#4b7bff',
    '&:hover': {
      background: 'transparent',
      border: 0,
    },
    '& .MuiButton-label': {
      fontSize: 13,
    },
    '&:disabled': {
      border: 0,
    },
  },
}));

export default function ModalButtons({
  loading,
  handleSubmit,
  handleCancel,
  isValid,
  buttonText = 'Create',
  cancelColor = '#1F63FF',
}) {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      marginTop="20px"
    >
      <Button
        variant="outlined"
        className={classes.buttonOutlined}
        onClick={handleCancel}
        style={{ color: cancelColor }}
      >
        CANCEL
      </Button>
      <Button
        variant="outlined"
        className={classes.buttonOutlined}
        onClick={handleSubmit}
        disabled={loading || isValid()}
      >
        {loading ? (
          <CircularProgress color="#4b7bff" size="25px" />
        ) : (
          buttonText
        )}
      </Button>
    </Box>
  );
}
