import { Box, makeStyles, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteIcon from '@material-ui/icons/Delete';
import RestoreIcon from '@material-ui/icons/Restore';
import PropTypes from 'prop-types';

import formatTxt from '../utils/formatText';
import { downloadImage } from '../utils/libs';
import { bytesToMB } from './Attachment';
import ConfirmDialog from './ConfirmDialog';
import { archiveCaseAttachment, unarchiveCaseAttachment } from '../services/unarmed';
import { toast } from 'react-toastify';

const useStyles = makeStyles(() => ({
  container: {
    borderBottom: '1px solid #f2f2f2',
    borderRadius: 5,
    width: '100%',
    cursor: 'pointer',
    marginBottom: 8,
    paddingBottom: 8,
    flexDirection: 'column',
  },
  extension: {
    width: 50,
    height: 45,
    padding: 10,
    borderRadius: 5,
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& > *': {
      marginLeft: '10px',
    },
  },
}));

const getRandomWord = () => {
  const words = ['APPLE', 'LEMON', 'CLOUD', 'STAR', 'WAVE', 'STONE', 'PLANT', 'RIVER', 'SUN', 'LEAF'];
  return words[Math.floor(Math.random() * words.length)];
};

const ImageDownload = ({
  index,
  name,
  size = 0,
  urlImg,
  extension,
  onClick,
  onDownload = null,
  id,
  onPreviewImage = null,
  version = 'original',
  children,
  deletable = true,
  entityId,
  onDeleteFile,
  restorable = true,
  onRestoreFile,
  downloadable = true,
}) => {
  const sizeInMb = bytesToMB(size);
  const [openDeleteAttachment, setOpenDeleteAttachment] = useState(false);
  const [openRestoreAttachment, setOpenRestoreAttachment] = useState(false);
  const [randomWord, setRandomWord] = useState(getRandomWord());
  const [loading, setLoading] = useState(false);

  const classes = useStyles();
  const renderBg = () => {
    if (index === 0) {
      return '#FEB5351A';
    }
    if (index === 1) {
      return '#FF42421A';
    }
    if (index === 2) {
      return '#00C9B21A';
    }
    return '#2962FF1F';
  };
  const renderColor = () => {
    if (index === 0) {
      return '#FEB535';
    }
    if (index === 1) {
      return '#FF4242';
    }
    if (index === 2) {
      return '#00C9B2';
    }
    return '#4B7BFF';
  };

  return (
    <Box className={classes.container}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" onClick={onPreviewImage}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{ background: renderBg() }}
            className={classes.extension}
          >
            <strong style={{ color: renderColor(), textTransform: 'uppercase' }}>.{extension}</strong>
          </Box>
          <Box display="flex" flexDirection="column" marginLeft={2} alignItems="flex-start" justifyContent="center">
            <Typography variant="body2" align="left">
              {formatTxt(name, 40)}
            </Typography>
            {size ? (
              <Typography variant="body2" align="left" style={{ color: 'rgba(161, 161, 161, 1)' }}>
                {sizeInMb} MB
              </Typography>
            ) : null}
          </Box>
        </Box>
        <Box>
          {typeof onClick === 'function' ? (
            <a href={`/case-exported/${id}?version=${version}`} target="_blank noreferrer">
              <GetAppIcon
                fontSize="small"
                htmlColor="rgb(109, 115, 133)"
                onClick={onClick}
                style={{ cursor: 'pointer' }}
              />
            </a>
          ) : (
            <Box className={classes.actionsContainer}>
              <ConfirmDialog
                open={openDeleteAttachment}
                onConfirm={async () => {
                  setLoading(true);
                  try {
                    if (onDeleteFile) {
                      await onDeleteFile(entityId, id);
                    }
                    toast.success('File succesfully deleted');
                  } catch (error) {
                    toast.error(`Error deleting file: ${error?.response?.data?.message || error?.message || error}`);
                  } finally {
                    setLoading(false);
                  }
                  setOpenDeleteAttachment(false);
                }}
                title={`Are you sure you want to delete "${name}"?`}
                description={`Attachments will be archived for 30 days before being permanently deleted`}
                onClose={() => {
                  setOpenDeleteAttachment(false);
                }}
                loading={loading}
                reconfirmValue={randomWord}
              />
              <ConfirmDialog
                open={openRestoreAttachment}
                onConfirm={async () => {
                  setLoading(true);
                  try {
                    if (onRestoreFile) {
                      await onRestoreFile(entityId, id);
                    }
                    toast.success('File succesfully restored');
                  } catch (error) {
                    toast.error(`Error restoring file: ${error?.response?.data?.message || error?.message || error}`);
                  } finally {
                    setLoading(false);
                  }
                  setOpenRestoreAttachment(false);
                }}
                title={`Are you sure you want to restore "${name}"?`}
                description={`The attachment will show again in your main attachments list`}
                onClose={() => {
                  setOpenRestoreAttachment(false);
                }}
                loading={loading}
              />
              {downloadable ? (
                <Box
                  onClick={() => (onDownload ? onDownload() : downloadImage(urlImg, name))}
                  style={{ cursor: 'pointer' }}
                >
                  <GetAppIcon fontSize="small" htmlColor="rgb(109, 115, 133)" />
                </Box>
              ) : null}
              {deletable ? (
                <Box
                  onClick={() => {
                    setRandomWord(getRandomWord());
                    setOpenDeleteAttachment(true);
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <DeleteIcon fontSize="small" htmlColor="rgb(109, 115, 133)" />
                </Box>
              ) : null}
              {restorable ? (
                <Box onClick={() => setOpenRestoreAttachment(true)} style={{ cursor: 'pointer' }}>
                  <RestoreIcon fontSize="small" htmlColor="rgb(109, 115, 133)" />
                </Box>
              ) : null}
            </Box>
          )}
        </Box>
      </Box>
      {children}
    </Box>
  );
};

ImageDownload.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  size: PropTypes.number,
  urlImg: PropTypes.string.isRequired,
  extension: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  version: PropTypes.string,
  onPreviewImage: PropTypes.func,
  deletable: PropTypes.bool,
  entityId: PropTypes.string,
  onDeleteFile: PropTypes.func,
};

export default ImageDownload;
