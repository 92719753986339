/* eslint-disable no-else-return */
import { Box, Button, Card, CardContent, Divider, makeStyles, TextField, Typography } from '@material-ui/core';
import React, { useState, useContext } from 'react';
import { toast } from 'react-toastify';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory } from 'react-router-dom';
import * as firebase from 'firebase';

import microsoftLogo from '../../../assets/Microsoft_logo.svg.png';
import useInput from '../../../hooks/useInput';
import { signInWithEmailAndPassword, signInWithMicrosoft } from '../../../services/firebase';
import { unarmedWithToken } from '../../../services/axios';
import useOrganization from '../../../hooks/useOrganization';
import LogoHome from '../../../components/LogoHome';
import { Context } from '../../../Context';

const useStyles = makeStyles(() => ({
  card: {
    width: 500,
    padding: 30,
  },
  unarmedImg: {
    objectFit: 'contain',
    height: 60,
    width: 150,
    alignSelf: 'center',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
  },
  cardTitle: {
    marginTop: 20,
    fontWeight: '500',
  },
  text: {
    color: '#00000099',
  },
  form: {
    marginTop: 10,
  },
  textField: {
    width: '100%',
    marginBottom: 20,
  },
  button: {
    background: ' #364F74',
    color: '#fff',
    width: '100%',
    ':hover': {
      background: '#364F74',
    },
    marginTop: 10,
    marginBottom: 20,
  },

  divider: {
    width: '47%',
    background: '#0000001F',
  },
  googleButton: {
    width: '100%',
    margin: '20px 0',
    border: '1px solid #0000001F',
    textTransform: 'none',
    color: '#5E5E5E',
  },
  link: {
    color: '#4B7BFF',
    cursor: 'pointer',
    marginTop: 20,
    display: 'flex',
    alignItems: 'center',
  },
  googleIcon: {
    width: 20,
    height: 20,
    objectFit: 'cover',
    marginRight: 10,
  },
}));

// const appVerifier = new firebase.auth.RecaptchaVerifier('recaptcha');

const LoginForm = () => {
  const classes = useStyles();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const email = useInput('', isSubmitting);
  const password = useInput('', isSubmitting);
  const [showTwoFactor, setShowTwoFactorCode] = useState(false);
  const verificationCode = useInput('');
  const [verifyLoading, setVerifyLoading] = useState(false);
  const [verificationID, setVerificationID] = useState(null);
  const [res, setRes] = useState(null);
  const { getOrganizationCallback, organization } = useOrganization();
  const { signUpUser, setSignUpUser } = useContext(Context);
  const history = useHistory();

  const onLogin = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    if (!email.value || !password.value) {
      return;
    }
    try {
      setLoading(true);
      // await signInPhone(email.value, password.value);
      const { user } = await signInWithEmailAndPassword(email.value, password.value);
      await unarmedWithToken().post('/activity/login');
      const _organization = await getOrganizationCallback();

      if (signUpUser) {
        setSignUpUser(null);
      }

      if (user.multiFactor.enrolledFactors.length === 0 && _organization?.features?.dashboard?.forceMFA?.enabled) {
        history.push('/mfa');
      } else {
        history.push('/');
      }
      setLoading(false);
    } catch (error) {
      if (error?.code === 'auth/multi-factor-auth-required') {
        const { resolver } = error;
        setRes(resolver);
        const appVerifier = new firebase.auth.RecaptchaVerifier('recaptcha', {
          size: 'invisible',
        });

        const phoneInfoOptions = {
          multiFactorHint: resolver.hints[0],
          session: resolver.session,
        };
        const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
        // Send SMS verification code.
        return phoneAuthProvider
          .verifyPhoneNumber(phoneInfoOptions, appVerifier)
          .then(function (verificationId) {
            // verificationId will be needed for sign-in completion.
            setLoading(false);
            setShowTwoFactorCode(true);
            setVerificationID(verificationId);
          })
          .catch((error) => {
            toast.error(error.message);
          });
      } else if (error?.code?.includes('auth/user-not-found')) {
        email.setError('This email is not registered');
      } else if (error?.code?.includes('auth/wrong-password')) {
        password.setError('Please, check your credentials');
      } else {
        // Display any other possible error
        toast.error(error.message);
      }

      setLoading(false);
    }
  };

  const socialLogin = async () => {
    try {
      const _organization = await getOrganizationCallback();
      const { user } = await signInWithMicrosoft();
      if (!user) {
        return;
      }

      if (user.code === 'auth/popup-closed-by-user' || user.code === 'auth/network-request-failed') {
        return;
      }

      if (user.multiFactor.enrolledFactors.length === 0 && _organization?.features?.dashboard?.forceMFA?.enabled) {
        history.push('/mfa');
      } else {
        history.push('/');
      }
    } catch (error) {
      if (error?.code === 'auth/multi-factor-auth-required') {
        const { resolver } = error;
        setRes(resolver);
        const appVerifier = new firebase.auth.RecaptchaVerifier('recaptcha', {
          size: 'invisible',
        });

        const phoneInfoOptions = {
          multiFactorHint: resolver.hints[0],
          session: resolver.session,
        };
        const phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
        // Send SMS verification code.
        return phoneAuthProvider
          .verifyPhoneNumber(phoneInfoOptions, appVerifier)
          .then(function (verificationId) {
            // verificationId will be needed for sign-in completion.
            setLoading(false);
            setShowTwoFactorCode(true);
            setVerificationID(verificationId);
          })
          .catch((error) => {
            toast.error(error.message);
          });
      } else if (error?.code?.includes('auth/user-not-found')) {
        email.setError('This email is not registered');
      } else if (error?.code?.includes('auth/wrong-password')) {
        password.setError('Please, check your credentials');
      } else {
        // Display any other possible error
        toast.error(error.message);
      }
      setLoading(false);
    }
  };

  const onVerifyCode = async () => {
    if (!verificationCode.value) {
      verificationCode.setError('Please fill in the input');
      return;
    }
    verificationCode.setError('');

    try {
      setVerifyLoading(true);
      const cred = firebase.auth.PhoneAuthProvider.credential(verificationID, verificationCode.value);
      const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
      // Complete sign-in.
      setVerifyLoading(false);
      await res.resolveSignIn(multiFactorAssertion);
      await unarmedWithToken().post('/activity/login');
    } catch (error) {
      if (error.code === 'auth/invalid-verification-code') {
        verificationCode.setError('Upps! Wrong code');
      } else {
        toast.error(error.message);
      }

      setVerifyLoading(false);
    }
  };

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent} style={{ padding: 0 }}>
        <LogoHome />
        {showTwoFactor ? (
          <>
            <Typography variant="h4" className={classes.cardTitle}>
              Verification Code
            </Typography>
            <Typography variant="body2" className={classes.text} style={{ marginBottom: 15 }}>
              We sent the code to {res?.f?.mfaInfo[0]?.phoneInfo}
            </Typography>
            <TextField
              id="standard-basic"
              label="Verification code"
              value={verificationCode.value}
              onChange={verificationCode.onChange}
              className={classes.textField}
              error={verificationCode.error}
              helperText={verificationCode.error}
            />
            <Button
              data-testid="login-button-verify-code"
              variant="contained"
              className={classes.button}
              onClick={onVerifyCode}
              color="primary"
            >
              {verifyLoading ? <CircularProgress color="#fff" size="25px" /> : 'Verify code'}
            </Button>
          </>
        ) : (
          <>
            <Typography variant="h4" className={classes.cardTitle}>
              Login
            </Typography>
            <Typography variant="body2" className={classes.text}>
              Enter your email address and password to access admin panel.
            </Typography>
            <form noValidate onSubmit={onLogin} autoComplete="off" className={classes.form}>
              <TextField
                id="standard-basic"
                label="Email"
                value={email.value}
                onChange={email.onChange}
                className={classes.textField}
                error={email.error}
                helperText={email.error}
              />
              <TextField
                id="standard-basic"
                label="Password"
                type="password"
                value={password.value}
                onChange={password.onChange}
                className={classes.textField}
                error={password.error}
                helperText={password.error}
              />
              <Box display="flex" justifyContent="flex-end">
                <Typography
                  variant="body2"
                  className={classes.link}
                  style={{ margin: 0 }}
                  onClick={() => history.push('/reset-password')}
                >
                  Reset password <ArrowForwardIcon style={{ fontSize: 13 }} />
                </Typography>
              </Box>
              <Button
                data-testid="login-button-submit"
                variant="contained"
                className={classes.button}
                color="primary"
                type="submbit"
                disabled={!password.value || !email.value}
              >
                {loading ? <CircularProgress color="#fff" size="25px" /> : 'LOGIN'}
              </Button>
            </form>
            {organization?.features?.dashboard?.dashboard?.microsoft_sso && (
              <>
                <Box display="flex" style={{ alignItems: 'center', justifyContent: 'space-between' }}>
                  <Divider className={classes.divider} />
                  Or
                  <Divider className={classes.divider} />
                </Box>
                <Button
                  data-testid="login-button-sign-in-microsoft"
                  variant="outlined"
                  className={classes.googleButton}
                  onClick={socialLogin}
                >
                  <img src={microsoftLogo} alt="404" className={classes.googleIcon} /> Sign in with Microsoft
                </Button>
              </>
            )}
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default LoginForm;
