import { Box, Button, Card, CardContent, Divider, makeStyles, TextField, Typography } from '@material-ui/core';
import React, { useState, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import useInput from '../../../hooks/useInput';
import { signupInvtion } from '../../../services/unarmed';
import { signInWithEmailAndPassword, signInWithMicrosoft } from '../../../services/firebase';
import Btn from '../../../components/Btn';
import useOrganization from '../../../hooks/useOrganization';
import microsoftLogo from '../../../assets/Microsoft_logo.svg.png';
import LogoHome from '../../../components/LogoHome';
import { Context } from '../../../Context';

const useStyles = makeStyles(() => ({
  card: {
    width: 500,
    padding: 30,
  },
  unarmedImg: {
    objectFit: 'contain',
    height: 60,
    width: 150,
    alignSelf: 'center',
    cursor: 'pointer',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
  },
  cardTitle: {
    marginTop: 20,
    fontWeight: '500',
  },
  text: {
    color: '#00000099',
  },
  form: {
    marginTop: 10,
  },
  textField: {
    width: '100%',
    marginBottom: 20,
  },
  button: {
    background: ' #364F74',
    color: '#fff',
    width: '100%',
    ':hover': {
      background: '#364F74',
    },
    marginTop: 10,
    marginBottom: 20,
  },

  divider: {
    width: '47%',
    background: '#0000001F',
  },
  googleButton: {
    width: '100%',
    margin: '20px 0',
    border: '1px solid #0000001F',
    textTransform: 'none',
    color: '#5E5E5E',
  },
  link: {
    color: '#4B7BFF',
    cursor: 'pointer',
    marginTop: 20,
    display: 'flex',
    alignItems: 'center',
  },
  googleIcon: {
    width: 20,
    height: 20,
    objectFit: 'cover',
    marginRight: 10,
  },
}));

const SignupForm = ({ invitation }) => {
  const classes = useStyles();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const confirmPassword = useInput('', isSubmitting);
  const history = useHistory();
  const password = useInput('', isSubmitting);
  const location = useLocation();
  const query = React.useMemo(() => new URLSearchParams(location.search), [location.search]);
  const { organization } = useOrganization();
  const { setIsAuth } = useContext(Context);

  const onSignup = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    if (!password.value || !confirmPassword.value) {
      return;
    }

    if (password.value !== confirmPassword.value) {
      confirmPassword.setError('Both Passwords must match');
      return;
    }
    if (password.value.length < 12) {
      password.setError('Password must be at least 12 characters long');

      return;
    }
    if (!password.value.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*/()-,=+{}[\]<>?`~|;:'".]).{12,}$/)) {
      password.setError(
        'Password should be a minimum of 12 characters and include at least one uppercase letter, one lowercase letter, one number, and a special character.'
      );
      return;
    }
    password.setError('');
    confirmPassword.setError('');
    try {
      setLoading(true);
      const userData = {
        password: password.value,
        email: invitation.email,
        code: query.get('code'),
      };
      await signupInvtion(userData);
      signInWithEmailAndPassword(invitation?.email, password.value);
      setLoading(false);
      setIsAuth(true);

      history.push('/mfa');
    } catch (error) {
      toast.error(error.response.data.error.details[0].message);
      setLoading(false);
    }
  };

  const onSignInWithMicrosoft = async () => {
    try {
      const { user } = await signInWithMicrosoft();

      if (user.email !== invitation?.email) {
        toast.error('Please use the email you signed were invited');
        return;
      }
      const userData = {
        code: query.get('code'),
      };
      const token = await user.getIdToken();

      signupInvtion(userData, true, token);
      history.push('/mfa');
    } catch (error) {
      if (error.code === 'auth/account-exists-with-different-credential') {
        toast.error(
          'This account already exists with a different credential. Please use the email you signed were invited'
        );
      }
    }
  };

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent} style={{ padding: 0 }}>
        <LogoHome />
        <Typography variant="h4" className={classes.cardTitle}>
          Account Registration
        </Typography>
        <Typography variant="body2" className={classes.text}>
          Please set your password to get started!
        </Typography>
        <form noValidate onSubmit={onSignup} autoComplete="off" className={classes.form}>
          <TextField
            id="standard-basic"
            label="First Name"
            value={`${invitation?.firstName} `}
            className={classes.textField}
            disabled
          />
          <TextField
            id="standard-basic"
            label="Last Name"
            value={`${invitation?.lastName} `}
            className={classes.textField}
            disabled
          />

          <TextField
            id="standard-basic"
            label="Email"
            value={`${invitation?.email} `}
            className={classes.textField}
            disabled
          />
          <TextField
            id="standard-basic"
            label="Password"
            type="password"
            value={password.value}
            onChange={password.onChange}
            className={classes.textField}
            error={password.error}
            helperText={password.error}
          />
          <TextField
            id="standard-basic"
            label="Confirm Password"
            type="password"
            value={confirmPassword.value}
            onChange={(e) => {
              confirmPassword.onChange(e);
              if (e.target.value !== password.value) {
                confirmPassword.setError('Both Passwords must match');
              } else {
                confirmPassword.setError('');
              }
            }}
            className={classes.textField}
            error={confirmPassword.error}
            helperText={confirmPassword.error}
          />
          <Btn
            dataTestId="sign-up-button-submit"
            className={classes.button}
            loading={loading}
            text="Registration"
            type="submit"
            circularColor="#fff"
            color="primary"
          />
          {organization?.features?.dashboard?.dashboard?.microsoft_sso && (
            <>
              <Box display="flex" style={{ alignItems: 'center', justifyContent: 'space-between' }}>
                <Divider className={classes.divider} />
                Or
                <Divider className={classes.divider} />
              </Box>
              <Button
                data-testid="login-button-sign-in-microsoft"
                variant="outlined"
                className={classes.googleButton}
                onClick={onSignInWithMicrosoft}
              >
                <img src={microsoftLogo} alt="404" className={classes.googleIcon} /> Sign up with Microsoft
              </Button>
            </>
          )}
        </form>
      </CardContent>
    </Card>
  );
};

SignupForm.propTypes = {
  invitation: PropTypes.string.isRequired,
};

export default SignupForm;
