import React, { useState } from 'react';
import { Box, makeStyles, Typography, Radio, RadioGroup, FormControlLabel } from '@material-ui/core';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import WrapperComponent from './WrapperComponent';
import Input from '../../components/Input';
import useInput from '../../hooks/useInput';
import Settings from '../utils/Settings';
import { ReactComponent as YesNoIcon } from '../../assets/icons/rule_black_24dp.svg';
import PreviewLabel from '../utils/Label';
import HideAnswer from '../../components/HideAnswer';

const useStyles = makeStyles(() => ({
  title: {
    fontSize: 14,
    color: '#000000DE',
    marginLeft: 10,
  },
  label: {
    fontSize: 16,
    color: '#000000DE',
    fontWeight: '500',
  },
}));

export default function YesNo({
  type,
  questionBankComponent,
  path,
  id,
  componentId,
  data,
  componentIndex,
  onUpdateJson,
  onDeleteComponent,
  duplicateComponent,
  refActionModal,
  setModal,
  includeRequired,
}) {
  const classes = useStyles();
  const [checkQuestion, setCheckQuestion] = useState([!!data?.description, !!data.requiredMessage, !!data?.checkbox]);
  const descriptionQuestion = useInput(data?.description || '');
  const requiredQuestionMessage = useInput(data?.requiredMessage || '');
  const question = useInput(data?.question || '');
  const required = useInput(!!data?.required, false, false, true);
  const [loading, setLoading] = React.useState(false);

  const handleSaveComponent = () => {
    const dataJson = {
      ...(descriptionQuestion.value ? { description: descriptionQuestion.value } : {}),
      ...(requiredQuestionMessage.value ? { requiredMessage: requiredQuestionMessage.value } : {}),
      ...(data.dataReportLink ? { dataReportLink: data.dataReportLink } : {}),
      question: question.value,
      required: required.value,
      checkbox: checkQuestion[2],
    };

    onUpdateJson(dataJson);
  };
  const saveOnTimeOut = () => {
    setLoading(true);
    setTimeout(() => {
      handleSaveComponent();
      setLoading(false);
    }, 500);
  };
  const renderSettings = () => (
    <Settings
      componentId={componentId}
      componentType="yesNo"
      defaultDataReportLink={data.dataReportLink}
      onSelectDataReportLink={(dataReportLink) => onUpdateJson({ ...data, dataReportLink })}
      questions={['description', 'required', 'checkboxQuestion']}
      checkQuestion={checkQuestion}
      descriptionQuestion={descriptionQuestion}
      requiredQuestion={requiredQuestionMessage}
      setCheckQuestion={setCheckQuestion}
      saveOnTimeOut={saveOnTimeOut}
    />
  );

  // const inputQuestion = useInput('');
  const [yesNoValue, setYesNoValue] = React.useState('');
  const handleChangeYesNo = (event) => {
    setYesNoValue(event.target.value);
  };

  const [showAnswerBool, setShowAnswerBool] = React.useState(true);
  const handleShowAnswerBool = (state) => {
    setShowAnswerBool((state) => !state);
    // console.log(hideShowMessage)
  };

  const renderPreview = () => (
    <Box>
      <PreviewLabel question={question.value} qNumber={componentIndex + 1} required={required.value} />
      <HideAnswer onClick={handleShowAnswerBool} marginLeft="35px" />
      <Box marginLeft="42px">
        {showAnswerBool && (
          <RadioGroup
            name="controlled-radio-buttons-group"
            value={yesNoValue}
            onChange={(e) => {
              handleChangeYesNo(e);
              saveOnTimeOut();
            }}
          >
            <FormControlLabel value="Yes" control={<Radio color="primary" />} label="Yes" disabled />
            <FormControlLabel value="No" control={<Radio color="primary" />} label="No" disabled />
          </RadioGroup>
        )}
      </Box>
    </Box>
  );

  return (
    <WrapperComponent
      onBlur={(!questionBankComponent && saveOnTimeOut) || function () {}}
      renderSettings={renderSettings}
      componentIndex={componentIndex}
      duplicateComponent={duplicateComponent}
      isQuestionBankComponent={questionBankComponent}
      required={required}
      renderPreview={renderPreview}
      onDeleteComponent={onDeleteComponent}
      id={id}
      componentId={componentId}
      includeRequired={includeRequired}
      path={path}
      loading={loading}
      dataSaved={data?.question}
      refActionModal={refActionModal}
      setModal={setModal}
    >
      <Box display="flex" alignItems="center">
        <YesNoIcon fill="#6D7385" />
        <Typography className={classes.title}>Yes/No</Typography>
      </Box>
      <Box display="flex" alignItems="center" marginTop="15px">
        <Typography className={classes.label}>Q{componentIndex + 1}</Typography>
        <ArrowRightAltIcon style={{ fontSize: 14, marginRight: 10 }} />
        <Input
          placeholder="Enter name question"
          value={question.value}
          onChange={(e) => {
            question.onChange(e);
            saveOnTimeOut();
          }}
        />
      </Box>
    </WrapperComponent>
  );
}
