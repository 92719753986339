import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { Button, Box, Divider, CircularProgress, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { createTag } from '../services/unarmed';
import Tag from './Tag';
import { useTags } from '../hooks/useTags';

/**
 * CreateAttachmentTag component for adding tags to attachments
 * @param {object} props - The properties object
 * @param {Array} props.currentTags - The current tags list
 * @param {string} props.position - The position of the tag
 * @param {string} props.size - The size of the tag (default is 'large')
 * @param {string} props.right - The right position of the tag
 * @param {function} props.onClickAdd - The function to handle adding tags
 * @param {function} props.onClickClose - The function to handle closing the tag
 */
const CreateAttachmentTag = ({ currentTags, position, size = 'large', right, onClickAdd, onClickClose }) => {
  /**
   * useState hook to manage tag text state
   */
  const [tagText, setTagText] = useState('');

  /**
   * useState hook for handling loading state
   */
  const [loading, setLoading] = useState(false);

  /**
   * Custom hook to get recently used tags
   */
  const [tagsRecently, onChangeSearch, loadingTags] = useTags();

  /**
   * Function to handle form submission
   */
  const handleOnSubmit = useCallback(() => {
    if (tagText !== '') {
      setLoading(true);
      createTag({ name: tagText })
        .then((res) => {
          if (res.status === 200) {
            setTagText('');
            onClickAdd(res.data);
            setTimeout(() => {
              setLoading(false);
              onClickClose();
            }, 500);
          } else {
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          const message =
            error?.response?.data?.error?.details[0]?.message ||
            error?.response?.data?.message ||
            'Something went wrong';

          toast.error(message);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagText, onClickAdd]);

  /**
   * Object containing sizes for different size options
   */
  const sizes = {
    small: {
      width: 250,
      height: 250,
    },
    medium: {
      width: 275,
      height: 300,
    },
    large: {
      width: 325,
      height: 350,
    },
  };

  /**
   * Interval duration for typing indication
   */
  const doneTypingInterval = 750;

  let interval;

  const mostUsedTags = tagsRecently
    .filter((tagRecently) => !currentTags.find((currentTag) => currentTag?._id === tagRecently?._id))
    .slice(0, 5);

  return (
    <>
      <Backdrop onClick={onClickClose} />
      <Container right={right} position={position} style={sizes[size]}>
        <Box>
          <Input
            value={tagText}
            placeholder="Search or create new tag (max 30 characters)"
            onChange={(e) => {
              setTagText(e.target.value);
            }}
            maxLength={30}
            onKeyUp={() => {
              clearTimeout(interval);

              interval = setTimeout(() => onChangeSearch(tagText), doneTypingInterval);
            }}
            onKeyDown={() => {
              clearTimeout(interval);
            }}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                handleOnSubmit();
              }
            }}
          />
          <Divider />
        </Box>
        <Scroll>
          <div>
            <TextRecently>{tagText === '' ? 'Most used tags' : 'Search results'}</TextRecently>
          </div>
          {tagText !== '' && tagsRecently.length === 0 && (
            <Typography variant="body2">No tags with the name "{tagText}" were found</Typography>
          )}
          {tagText === '' && mostUsedTags.length === 0 && !loadingTags && (
            <Typography variant="body2">No tags available</Typography>
          )}
          {mostUsedTags.map((tag) => (
            <Box width="100%" display="flex" justifyContent="flex-start" flexDirection="row" key={tag._id}>
              <Tag
                text={tag?.tagName || tag?.name}
                bg={tag?.backgroundColor}
                color={tag?.textColor}
                onClick={() => {
                  onClickAdd(tag);
                  onClickClose();
                }}
              />
            </Box>
          ))}
        </Scroll>
        <Box>
          <Divider />
          <Box display="flex" justifyContent="flex-end" alignItems="center" marginTop="8px">
            <Button style={{ marginRight: 8 }} onClick={onClickClose}>
              CANCEL
            </Button>
            {loading ? (
              <CircularProgress size="20px" />
            ) : (
              <Button color="primary" onClick={handleOnSubmit}>
                CREATE
              </Button>
            )}
          </Box>
        </Box>
      </Container>
    </>
  );
};

CreateAttachmentTag.propTypes = {
  size: PropTypes.string,
  right: PropTypes.number.isRequired,
  currentTags: PropTypes.array.isRequired,
  position: PropTypes.string.isRequired,
  onClickAdd: PropTypes.func.isRequired,
  onClickClose: PropTypes.func.isRequired,
};

const Backdrop = styled.div`
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: transparent;
  z-index: 99990;
`;

const Container = styled.div`
  background-color: #fff;
  position: absolute;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.22);
  z-index: 99991;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: space-between;
  right: ${(props) => `${props.right}px`};

  ${(props) =>
    props.position === 'top'
      ? `
    top: 0px;
  `
      : `
    bottom: 0px;
  `}
`;

const Input = styled.input`
  font-family: Roboto;
  font-size: 14px;
  color: rgba(87, 90, 102, 1);
  margin-bottom: 8px;
  border: 0;
  width: 100%;

  &:focus-visible,
  &:focus,
  &:active {
    border: 0px;
  }
`;

const TextRecently = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 4px;
  color: rgba(136, 141, 160, 1);
`;

const Scroll = styled.div`
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  height: 70%;
  flex-direction: column;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export default CreateAttachmentTag;
