/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import DashboardCard from '../../components/DashboardCard';
import OfficerInfo from './OfficerInfo';
import iconGreen from '../../assets/greencard.png';
import iconRed from '../../assets/redCard.png';
import blueIcon from '../../assets/record_voice_over.png';
import { getOfficer, getOfficerStatus } from '../../services/unarmed';
import { Context } from '../../Context';
import useInput from '../../hooks/useInput';
import HeaderTabs from '../Projects/components/HeaderTabs';
import TabPanel from '../../components/TabPanel';
import OfficerCases from './OfficerCases';
import InterveneTab from './InterveneTab';
import MonitorTab from './MonitorTab';
import useOrganization from '../../hooks/useOrganization';
import HistoryTab from './HistoryTab';
import AttachmentsTab from './AttachmentTab';
import { updateUrlWithParams } from '../../utils/libs';

const tabsArray = [
  { label: 'Cases', value: 0 },
  { label: 'Intervene', value: 1 },
  { label: 'Monitor', value: 2 },
  { label: 'Attachments', value: 3 },
  { label: 'History', value: 4 },
];

const useStyles = makeStyles(() => ({
  container: {
    padding: 16,
  },
  title: {
    marginTop: 50,
  },
}));
const OfficerProfile = () => {
  const classes = useStyles();
  const { authUser, setActive } = useContext(Context);
  const [status, setStatus] = useState('idle');
  const [stats, setStats] = useState(null);
  const history = useHistory();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const { organization } = useOrganization();
  const email = useInput('');
  const firstName = useInput('');
  const lastName = useInput('');
  const badge = useInput('');
  const [gender, setGender] = useState([]);
  const [race, setRace] = useState([]);
  const [officer, setOfficer] = useState({});
  const phone = useInput('');
  const [value, setValue] = useState(0);

  useEffect(() => {
    setActive('/officers');
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const onGetStats = async () => {
      setStatus('pending');
      try {
        const { data } = await getOfficerStatus(id);
        setStats(data);
        setStatus('resolved');
      } catch (error) {
        toast.error(error?.message);
        setStatus('rejected');
      }
    };

    const onGetOfficerInfo = async () => {
      try {
        setLoading(true);
        const data = await getOfficer(id);
        setOfficer(data);
        firstName.setValue(data.firstName);
        lastName.setValue(data.lastName);
        badge.setValue(data.badgeNumber);
        phone.setValue(data.phoneNumber);
        email.setValue(data.email);
        setGender(data?.gender);
        setRace(data?.raceEthnicity);

        setLoading(false);
      } catch (error) {
        toast.error(error?.message);
        setLoading(false);
      }
    };

    if (authUser) {
      onGetStats();
      onGetOfficerInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, authUser]);

  useEffect(() => {
    if (organization) {
      updateUrlWithParams(
        {
          organization_name: organization?.name,
          tab_name: 'cases',
        },
        {
          tab_name: 'cases',
        }
      );
    }
  }, [organization]);

  const handleChange = (event, newValue) => {
    setValue(newValue);

    const tabData = tabsArray[newValue];
    const tabLabel = tabData.label?.toLowerCase()?.replace(/ /g, '-');

    updateUrlWithParams(
      {
        organization_name: organization.name,
        tab_name: tabLabel,
      },
      {
        tab_name: tabLabel,
      }
    );
  };

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

  const renderCards = () => (
    <Box display="flex" alignItems="center">
      <DashboardCard
        dataTestId="officer-card-total-compliments"
        styleContainer={{ marginTop: 20 }}
        cardText="Total Compliments"
        color="#01BEA826"
        icon={iconGreen}
        value={stats?.totalCompliments || 0}
        buttonText="View all compliments"
        onClickButton={() => {
          history.push('/cases', {
            badgeNumber: badge.value,
            name: `${firstName.value} ${lastName.value}`,
            showAlert: true,
            isFiltering: true,
            formType: 'COMPLIMENT',
          });
          setActive('/cases');
        }}
      />
      <DashboardCard
        dataTestId="officer-card-total-complaints"
        styleContainer={{ marginTop: 20 }}
        cardText="Total Complaints"
        color="#CC2D4B26"
        icon={iconRed}
        value={stats?.totalComplaints || 0}
        buttonText="View all complaints"
        onClickButton={() => {
          history.push('/cases', {
            badgeNumber: badge.value,
            name: `${firstName.value} ${lastName.value}`,
            showAlert: true,
            isFiltering: true,
            formType: 'COMPLAINT',
          });
          setActive('/cases');
        }}
      />
      <DashboardCard
        dataTestId="officer-card-total-allegations"
        styleContainer={{ marginTop: 20, marginRight: 0 }}
        cardText="Total Allegations"
        color="#2E66FE26"
        icon={blueIcon}
        value={stats?.totalAllegations || 0}
        buttonText="View all allegations"
        onClickButton={() => {
          history.push('/allegations', {
            badge: badge.value,
            openFilter: true,
          });
          setActive('/allegations');
        }}
      />
    </Box>
  );

  return (
    <>
      <Box width="100%" className={classes.container}>
        <Box width="100%" style={{ background: '#fff', boxShadow: '0 4px 8px #eee' }}>
          <OfficerInfo
            officer={officer}
            loading={loading}
            firstName={firstName}
            lastName={lastName}
            badge={badge}
            phone={phone}
            id={id}
            gender={gender}
            setGender={setGender}
            race={race}
            setRace={setRace}
            setOfficer={setOfficer}
            renderCards={renderCards}
            email={email}
            organization={organization}
          />
          <Box style={{ padding: '0 20px' }}>
            <HeaderTabs tabs={tabsArray} handleChangeTab={handleChange} a11yProps={a11yProps} tabValue={value} />
          </Box>
        </Box>
        <TabPanel data-testid="officer-tab-cases" value={value} padding={0} index={0}>
          <OfficerCases officerBadgeNumber={badge.value} />
        </TabPanel>
        <TabPanel data-testid="officer-tab-intervene" value={value} padding={0} index={1}>
          <InterveneTab
            organizationId={organization?._id}
            interveneOptions={organization?.EIS?.interveneChecklist}
            officerId={id}
          />
        </TabPanel>
        <TabPanel data-testid="officer-tab-monitor" value={value} padding={0} index={2}>
          <MonitorTab
            organizationId={organization?._id}
            monitorOptions={organization?.EIS?.monitorMeetingList}
            officerId={id}
          />
        </TabPanel>
        <TabPanel data-testid="officer-tab-attachments" value={value} padding={0} index={3}>
          <AttachmentsTab officerId={id} organizationId={organization?._id} />
        </TabPanel>
        <TabPanel data-testid="officer-tab-history" value={value} padding={0} index={4}>
          <HistoryTab id={id} />
        </TabPanel>
      </Box>
    </>
  );
};

export default OfficerProfile;
