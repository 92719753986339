/* eslint-disable no-nested-ternary */
import { Box, makeStyles, Typography, Tooltip } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import InfoIcon from '@material-ui/icons/Info';
import { downloadImage } from '../../utils/libs';
import { LogCaseChange, PreviewImg } from '../../components/Log';
import { permissionsOptionsForCases } from '../Permissions/Permissions';
import TimeLineItem from './TimeLineItem';
import { validateIsoDate } from '../../utils';
import LogIcon from '../../components/LogIcon';
import HTMLContent from '../../components/HTMLContent';
import CaseTaskUpdateItem from './CaseTaskUpdateItem';
import CarouselModal from '../../components/CarouselModal';
import Tag from '../../components/Tag';
import { contactFieldsNames, parseChangeValue } from '../Contacts/ContactHistory';
import Tags from '../../components/Tags';
import TimeLineItemNotes from './TimeLineItemNotes';

const CasesInfoText = ({ casesInfo = [] }) => {
  const casesList = casesInfo?.map((caseInfo) => caseInfo?.entityId || caseInfo.code)?.slice(0, 2);
  const casesLength = casesInfo?.length;

  if (casesLength > 2) {
    return (
      <>
        <b>{casesList.join(',')}</b> and <b>{casesLength - 2}</b> more
      </>
    );
  }

  return <b>{casesList.join(',')}</b>;
};

CasesInfoText.propTypes = {
  casesInfo: PropTypes.array,
};

const ProjectsInfoText = ({ projectsInfo = [] }) => {
  const projectsList = projectsInfo?.map((projectInfo) => projectInfo?.entityId)?.slice(0, 2);
  const projectsLength = projectsInfo?.length;

  if (projectsLength > 2) {
    return (
      <>
        <b>{projectsList.join(',')}</b> and <b>{projectsLength - 2}</b> more
      </>
    );
  }

  return <b>{projectsList.join(',')}</b>;
};

ProjectsInfoText.propTypes = {
  casesInfo: PropTypes.array,
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  imgModal: {
    objectFit: 'contain',
    maxWidth: 600,
    maxHeight: 400,
  },
  title: {
    fontSize: 16,
    fontWeight: '500',
    textTransform: 'capitalize',
    marginBottom: 22,
  },
  itemsContainer: {
    width: '100%',
    background: '#fff',
    borderRadius: 5,
    boxShadow: '0 3px 6px #00000005',
    padding: '20px 40px',
  },
  texxt: {
    fontSize: 14,
  },
  link: {
    display: 'flex',
    position: 'absolute',
    top: 10,
    right: 80,
    cursor: 'pointer',
    padding: 13,
    zIndex: 999999,
    borderRadius: '50%',
    background: '#494949',
  },
  emphasis: {
    color: '#4b7bff',
    fontWeight: '600',
  },
}));

const UserInfo = ({ user }) => (user?.email ? <strong>{user?.email}</strong> : 'A user');

UserInfo.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string.isRequired,
  }),
};

const getUserInitials = (user) => {
  if (!user) {
    return '';
  }

  return user?.firstName?.[0] || '' + user?.lastName?.[0] || '';
};

const stripHTML = (str) => {
  if (!str) return '';
  return str.replace(/<\/?[^>]+(>|$)/g, '');
};

const TimeLine = ({ items = [], title }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [filesSelected, setFilesSelected] = React.useState({ files: [], selectedFileIndex: 0 });
  const [loadingOpenFiles, setLoadingOpenFiles] = React.useState([]);
  const history = useHistory();

  const convertValueToHTML = (value) => {
    if (Array.isArray(value)) {
      if (value.length) {
        return <span className={classes.emphasis}>{value.join(', ')}</span>;
      } else {
        return 'an empty value';
      }
    }
    if (typeof value === 'boolean') {
      return <span className={classes.emphasis}>{value ? 'Yes' : 'No'}</span>;
    }
    if (value === undefined || value === null || value === '') {
      return 'an empty value';
    }
    return value;
  };

  const handleOpen = (filesOp) => {
    setOpen(true);
    const filesToOpen = filesOp.filter((flop) => flop.mimetype.includes('image') || flop.mimetype.includes('video'));
    setFilesSelected({ files: filesToOpen, selectedFileIndex: 0 });
    setLoadingOpenFiles(
      filesToOpen.map(() => ({
        loading: false,
      }))
    );
  };

  const handleClickEvents = (fl, files) => ({
    onDownload: () => downloadImage(fl.download_url, fl.name),
    onClick: () => handleOpen(files),
  });

  const getActionLabel = (key) => {
    if (key === 'startDateTime') {
      return 'Start Date Time';
    }
    if (key === 'endDateTime') {
      return 'End Date Time';
    }

    if (key === 'notes') {
      return 'Notes';
    }

    if (key === 'description') {
      return 'Description';
    }
    if (key === 'status') {
      return 'Status';
    }
    if (key === 'submittedDate') {
      return 'Submitted Date';
    }
    if (key === 'responseDate') {
      return 'Response Date';
    }
    if (key === 'bradyList') {
      return 'Brady list';
    }
    if (key === 'firstName') {
      return 'First name';
    }
    if (key === 'lastName') {
      return 'Last name';
    }
    if (key === 'email') {
      return 'Email';
    }
    if (key === 'raceEthnicity') {
      return 'Race/Ethnicity';
    }
    if (key === 'race') {
      return 'Race';
    }
    if (key === 'ethnicity') {
      return 'Ethnicity';
    }
    if (key === 'gender') {
      return 'Gender';
    }
    if (key === 'phoneNumber') {
      return 'Phone number';
    }
    if (key === 'photo') {
      return 'a profile picture';
    }
  };

  const getStatusTitle = (status) => {
    if (status) {
      return status;
    }
  };

  const renderText = (item) => {
    const currentTime = moment();

    const getDate = () =>
      `${moment(item.createdAt).format('MM/DD/YYYY')} at ${moment(item.createdAt).format('hh:mm A')}`;

    const getTooltipDate = () => {
      if (moment(item.createdAt).format('MM-DD-YYYY') === currentTime.format('MM-DD-YYYY')) {
        return `${moment(item.createdAt).fromNow()}`;
      }
      const days = currentTime.endOf('day').diff(moment(item.createdAt).endOf('day'), 'days');
      return `${days} Day${days === 1 ? '' : 's'} ago`;
    };

    const user = item?.data?.user;

    if (item.type === 'ADHOC_REPORT_CREATED') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={item?.data?.user} /> created report {item?.data?.adHocReport?.name}
          </Typography>
        </TimeLineItem>
      );
    }

    if (item.type === 'ADHOC_REPORT_UPDATED') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={item?.data?.user} /> updated report {item?.data?.adHocReport?.name}
          </Typography>
        </TimeLineItem>
      );
    }

    if (item.type === 'ADHOC_REPORT_DELETED') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={item?.data?.user} /> deleted report {item?.data?.adHocReport?.name}
          </Typography>
        </TimeLineItem>
      );
    }

    if (item.type === 'CONTACT_UPDATED') {
      const changes = item?.data?.changes;

      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          {item?.data?.changes?.length > 0 ? (
            <>
              {changes.map((change, changeKey) => {
                const fieldName = contactFieldsNames[change.fieldName];

                if (!fieldName) return null;

                return (
                  <Typography className={classes.texxt} key={changeKey}>
                    {change.oldValue ? (
                      <>
                        <UserInfo user={item?.data?.user} /> has changed <b>{fieldName}</b> from{' '}
                        <span className={classes.emphasis}>{parseChangeValue(change.oldValue, fieldName)}</span> to{' '}
                        <span className={classes.emphasis}>{parseChangeValue(change.newValue, fieldName)}</span> in
                        contact{' '}
                        <Link to={`/contacts/${item?.data?.contact?._id}`}>
                          <span className={classes.emphasis}>{item?.data?.contact?.email}</span>
                        </Link>
                      </>
                    ) : (
                      <>
                        <UserInfo user={item?.data?.user} /> has added <b>{fieldName}</b> to contact{' '}
                        <span className={classes.emphasis}>{parseChangeValue(change.newValue, fieldName)}</span> in
                        contact{' '}
                        <Link to={`/contacts/${item?.data?.contact?._id}`}>
                          <span className={classes.emphasis}>{item?.data?.contact?.email}</span>
                        </Link>
                      </>
                    )}
                  </Typography>
                );
              })}
            </>
          ) : (
            <>
              <UserInfo user={item?.data?.user} /> has updated the contact without changes.
            </>
          )}
        </TimeLineItem>
      );
    }

    if (item.type === 'CONTACT_CREATED') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={item?.data?.user} /> has created contact{' '}
            <Link to={`/contacts/${item?.data?.contact?._id}`}>
              <span className={classes.emphasis}>{item?.data?.contact?.email}</span>
            </Link>
          </Typography>
        </TimeLineItem>
      );
    }

    if (item.type === 'CONTACT_DELETED') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={item?.data?.user} /> has removed contact{' '}
            <span className={classes.emphasis}>{item?.data?.contact?.email}</span>
          </Typography>
        </TimeLineItem>
      );
    }

    if (item.type === 'USER_CONFIG_NOTIFICATION_CREATED_CASE') {
      return (
        <TimeLineItem unread={item?.unread} createdAt={item.createdAt} key={item?._id}>
          <Typography className={classes.texxt}>
            A new <strong>{item?.data?.form?.caseType || item.data?.caseType}</strong> with tracking number{' '}
            <Link to={`/case/${item?.data?.form?.code || item?.data?.code}`}>
              <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.form?.code || item?.data?.code}</span>
            </Link>{' '}
            has been created.
          </Typography>
        </TimeLineItem>
      );
    }

    if (item.type === 'USER_CONFIG_NOTIFICATION_IS_ASSIGNED_TO') {
      return (
        <TimeLineItem unread={item?.unread} createdAt={item.createdAt} key={item?._id}>
          <Typography className={classes.texxt}>
            <strong>{item?.data?.form?.caseType}</strong> with tracking number{' '}
            <Link to={`/case/${item?.data?.form?.code}`}>
              <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.form?.code}</span>
            </Link>{' '}
            has been assigned to <strong>{item?.data?.user?.assignedUserNames}</strong>.
          </Typography>
        </TimeLineItem>
      );
    }

    if (item.type === 'USER_CONFIG_NOTIFICATION_UPDATED_CASE') {
      return (
        <TimeLineItem unread={item?.unread} createdAt={item.createdAt} key={item?._id}>
          <Typography className={classes.texxt}>
            <strong>{item?.data?.form?.caseType}</strong> with tracking number{' '}
            <Link to={`/case/${item?.data?.form?.code}`}>
              <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.form?.code}</span>
            </Link>{' '}
            assigned to <strong>{item?.data?.user?.assignedUserNames}</strong> has been updated.
          </Typography>
        </TimeLineItem>
      );
    }

    if (item.type === 'USER_CONFIG_NOTIFICATION_STATUS_BY_TIME') {
      return (
        <TimeLineItem unread={item?.unread} createdAt={item.createdAt} key={item?._id}>
          <Typography className={classes.texxt}>
            The following cases <CasesInfoText casesInfo={item?.data?.form?.casesInfo} /> have been in{' '}
            <strong>{item?.data?.form?.status}</strong> status for <strong>{item?.data?.time} days</strong>
          </Typography>
        </TimeLineItem>
      );
    }

    if (item.type === 'USER_CONFIG_NOTIFICATION_TASK_DUE_DATE') {
      return (
        <TimeLineItem unread={item?.unread} createdAt={item.createdAt} key={item?._id}>
          <Typography className={classes.texxt}>
            The following cases <CasesInfoText casesInfo={item?.data?.form?.casesInfo} /> assigned to{' '}
            <strong>{item?.data?.assignedToNames}</strong> have tasks due in <strong>{item?.data?.time} days</strong>
          </Typography>
        </TimeLineItem>
      );
    }

    if (item.type === 'USER_CONFIG_NOTIFICATION_ACTION_DATE') {
      const { action } = item.data;
      if (action === 'action') {
        return (
          <TimeLineItem unread={item?.unread} createdAt={item.createdAt} key={item?._id}>
            <Typography className={classes.texxt}>
              The following projects <ProjectsInfoText projectsInfo={item?.data?.form?.casesInfo} /> have Action Tasks
              that took place <strong>{item?.data?.time} days</strong> ago
            </Typography>
          </TimeLineItem>
        );
      } else if (action === 'task') {
        return (
          <TimeLineItem unread={item?.unread} createdAt={item.createdAt} key={item?._id}>
            <Typography className={classes.texxt}>
              {item?.data?.nameDataLinkReport ? (
                <>
                  The following cases <CasesInfoText casesInfo={item?.data?.form?.casesInfo} /> with Date field{' '}
                  <strong>{item?.data?.nameDataLinkReport}</strong> took place{' '}
                  <strong>{item?.data?.time} days ago</strong>
                </>
              ) : (
                <>
                  The following cases <CasesInfoText casesInfo={item?.data?.form?.casesInfo} /> have tasks that took
                  place <strong>{item?.data?.time} days ago</strong>
                </>
              )}
            </Typography>
          </TimeLineItem>
        );
      } else if (action === 'fixed') {
        return (
          <TimeLineItem unread={item?.unread} createdAt={item.createdAt} key={item?._id}>
            <Typography className={classes.texxt}>
              The following cases <CasesInfoText casesInfo={item?.data?.form?.casesInfo} /> with Date field{' '}
              <strong>{item.data?.label}</strong> took place <strong>{item?.data?.time} days</strong> ago
            </Typography>
          </TimeLineItem>
        );
      } else {
        //item.action === 'data'
        return (
          <TimeLineItem unread={item?.unread} createdAt={item.createdAt} key={item?._id}>
            <Typography className={classes.texxt}>
              The following cases <CasesInfoText casesInfo={item?.data?.form?.casesInfo} /> with Date field{' '}
              <strong>{item?.data?.nameDataLinkReport}</strong> took place <strong>{item?.data?.time} days ago</strong>
            </Typography>
          </TimeLineItem>
        );
      }
    }

    if (item.type === 'USER_CONFIG_NOTIFICATION_OFFICER_LEVEL') {
      return (
        <TimeLineItem unread={item?.unread} createdAt={item.createdAt} key={item?._id}>
          <Typography className={classes.texxt}>
            An officer with Badge ID <strong>{item?.data?.officer?.badgeNumber}</strong> has moved from{' '}
            <strong>{item?.data?.levelBefore}</strong> to <strong>{item?.data?.level}</strong> in the Early Intervention
            System (EIS).
          </Typography>
        </TimeLineItem>
      );
    }

    if (item.type === 'CASE_CONTACT_ASSIGNED') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={item?.data?.user} /> has added contact{' '}
            <Link to={`/contacts/${item?.data?.contact?._id}`}>
              <span className={classes.emphasis}>{item?.data?.contact?.email}</span>
            </Link>{' '}
            to case{' '}
            <Link to={`/case/${item?.data?.form?._id}`}>
              <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.form?.code}</span>
            </Link>
          </Typography>
        </TimeLineItem>
      );
    }

    if (item?.type === 'CASE_CONTACT_REMOVE') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={item?.data?.user} /> has removed contact{' '}
            <span className={classes.emphasis}>{item?.data?.contact?.email}</span> from case{' '}
            <Link to={`/case/${item?.data?.form?._id}`}>
              <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.form?.code}</span>
            </Link>
          </Typography>
        </TimeLineItem>
      );
    }

    if (item.type === 'PROJECT_CONTACT_ASSIGNED') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={item?.data?.user} /> has added contact{' '}
            <Link to={`/contacts/${item?.data?.contact?._id}`}>
              <span className={classes.emphasis}>{item?.data?.contact?.email}</span>
            </Link>{' '}
            to project{' '}
            <Link to={`/project/${item?.data?.form?._id}`}>
              <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.project?.name}</span>
            </Link>
          </Typography>
        </TimeLineItem>
      );
    }

    if (item.type === 'PROJECT_CONTACT_DELETED') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={item?.data?.user} /> has removed contact{' '}
            <Link to={`/contacts/${item?.data?.contact?._id}`}>
              <span className={classes.emphasis}>{item?.data?.contact?.email}</span>
            </Link>{' '}
            from project{' '}
            <Link to={`/project/${item?.data?.form?._id}`}>
              <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.project?.name}</span>
            </Link>
          </Typography>
        </TimeLineItem>
      );
    }

    if (item.type === 'CONTACT_ATTACHMENT_TAGS_EDIT' || item.type === 'CONTACT_ATTACHMENT_TAGS_ADDED') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> has added {item?.data?.tags?.length > 1 ? 'tags' : 'tag'}{' '}
            {item?.data?.tags?.map((tag) => (
              <Tag bg={tag?.backgroundColor} color={tag?.textColor} key={tag?._id} text={tag?.name} />
            ))}{' '}
            to attachment:{' '}
            <a href={item?.data?.file?.url}>
              <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.file?.name}</span>
            </a>{' '}
            to contact:{' '}
            <Link to={`/contacts/${item?.data?.contact?._id}`}>
              <span className={classes.emphasis}>
                {item.data.contact.firstName} {item.data.contact.lastName}
              </span>
            </Link>
          </Typography>
        </TimeLineItem>
      );
    }

    if (item.type === 'CONTACT_COMMENT_OR_ATTACHMENT_ADDED') {
      const comment = item?.data?.comment;
      const files = item?.data?.files;

      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={item?.data?.user} /> made a{' '}
            <span style={{ color: '#2E66FE', fontWeight: '500' }}>new comment</span> in the contact:{' '}
            <Link to={`/contacts/${item?.data?.contact?._id}`}>
              <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.contact?.email}</span>
            </Link>
            <br />
            <HTMLContent content={comment} />
            <br />
            <Box display="flex" flexDirection="column">
              {files?.map((fl, i) => (
                <>
                  <PreviewImg
                    key={fl._id}
                    style={
                      !fl.name.includes('.pdf')
                        ? { minHeight: 100, margin: 6 }
                        : { minHeight: 35, width: 'fit-content' }
                    }
                  >
                    <LogIcon fl={fl} handleClickEvents={handleClickEvents(fl)} index={i} />
                  </PreviewImg>
                  <Tags autoUpdateTags={false} fileId={fl?._id} defaultTags={fl?.tags || []} />
                </>
              ))}
            </Box>
          </Typography>
        </TimeLineItem>
      );
    }

    if (item.type === 'CONTACT_COMMENT_OR_ATTACHMENT_EDIT') {
      const oldComment = item?.data?.oldComment;
      const newComment = item?.data?.newComment;

      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={item?.data?.user} /> edited a comment in the contact:{' '}
            <Link to={`/contacts/${item?.data?.contact?._id}`}>
              <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.contact?.email}</span>
            </Link>
            <br />
            <b>before:</b>
            <br />
            <HTMLContent content={oldComment} />
            <b>after:</b>
            <br />
            <HTMLContent content={newComment} />
          </Typography>
        </TimeLineItem>
      );
    }

    if (item.type === 'CONTACT_ATTACHMENT_TAGS_DELETE') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={item?.data?.user} /> removed {item?.data?.tags?.length > 1 ? 'tags' : 'tag'}{' '}
            {item?.data?.tags?.map((tag) => (
              <Tag bg={tag?.backgroundColor} color={tag?.textColor} key={tag?._id} text={tag?.name} />
            ))}{' '}
            from attachment: <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.file?.name}</span> from
            contact:{' '}
            <Link to={`/contacts/${item?.data?.contact?._id}`}>
              <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.contact?.email}</span>
            </Link>
          </Typography>
        </TimeLineItem>
      );
    }

    if (item.type === 'OFFICER_ATTACHMENT_TAGS_ADDED') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> has added {item?.data?.tags?.length > 1 ? 'tags' : 'tag'}{' '}
            {item?.data?.tags?.map((tag) => (
              <Tag bg={tag?.backgroundColor} color={tag?.textColor} key={tag?._id} text={tag?.name} />
            ))}{' '}
            to attachment:{' '}
            <a href={item?.data?.file?.url}>
              <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.file?.name}</span>
            </a>{' '}
            to officer:{' '}
            <Link to={`/officer/${item?.data?.officer?._id}`}>
              <span className={classes.emphasis}>
                {item.data.officer.firstName} {item.data.officer.lastName}
              </span>
            </Link>
          </Typography>
        </TimeLineItem>
      );
    }

    if (item.type === 'OFFICER_ATTACHMENT_TAGS_DELETE') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> has removed {item?.data?.tags?.length > 1 ? 'tags' : 'tag'}{' '}
            {item?.data?.tags?.map((tag) => (
              <Tag bg={tag?.backgroundColor} color={tag?.textColor} key={tag?._id} text={tag?.name} />
            ))}{' '}
            from attachment: <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.file?.name}</span> from
            officer:{' '}
            <Link to={`/officer/${item?.data?.officer?._id}`}>
              <span className={classes.emphasis}>
                {item.data.officer.firstName} {item.data.officer.lastName}
              </span>
            </Link>
          </Typography>
        </TimeLineItem>
      );
    }

    if (item.type === 'OFFICER_UPDATED') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            {item?.data?.changes?.map((change, index) => (
              <Box key={index}>
                {!change?.oldValue ? (
                  <>
                    <UserInfo user={user} /> has added <strong>{getActionLabel(change?.fieldName)}</strong>{' '}
                    <span className={classes.emphasis}>{change?.newValue}</span>{' '}
                  </>
                ) : (
                  <>
                    <UserInfo user={user} /> has changed <strong>{getActionLabel(change?.fieldName)}</strong> from{' '}
                    <span className={classes.emphasis}>{change.oldValue.toString()}</span> to{' '}
                    <span className={classes.emphasis}>{change.newValue.toString()}</span>{' '}
                  </>
                )}
                in officer{' '}
                <Link to={`/officer/${item?.data?.officer?._id}`}>
                  <span className={classes.emphasis}>
                    {item.data.officer.firstName} {item.data.officer.lastName}
                  </span>
                </Link>
              </Box>
            ))}
          </Typography>
        </TimeLineItem>
      );
    }
    if (item.type === 'OFFICER_COMMENT_OR_ATTACHMENT' || item.type === 'OFFICER_ATTACHMENT') {
      const { files, comment, type: optionType, intervene, meeting, officer } = item.data;
      const _user = item.data.user;
      const hasFiles = files.length > 0;
      const commentText = comment ? (
        <>
          {' made a '}
          <span className={classes.emphasis}>new comment</span>
        </>
      ) : null;
      const filesText = hasFiles ? (
        <>
          <span className={classes.emphasis}>
            {' Uploaded '} {files.length}
          </span>{' '}
          file(s)
        </>
      ) : null;
      const optionText = optionType ? (
        <>
          {' to '}
          {optionType === 'INTERVENE' ? 'Intervene' : 'Monitor'}{' '}
          <span className={classes.emphasis}>
            {optionType === 'INTERVENE' ? intervene : meeting ? `${meeting} months meeting` : null}
          </span>
        </>
      ) : null;
      const whereText = (
        <>
          in the officer{' '}
          <Link to={`/officer/${officer?._id}`}>
            <span className={classes.emphasis}>
              {officer.firstName} {officer.lastName}
            </span>
          </Link>
        </>
      );

      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <div className={classes.texxt}>
            <UserInfo user={_user} />
            {commentText}
            {comment && hasFiles ? ' and ' : null}
            {filesText}
            {optionText}
            {whereText}
            <br />
            <HTMLContent content={comment} />
            <br />
            {hasFiles ? (
              <Box display="flex" flexDirection="column">
                {files?.map((fl, i) => (
                  <PreviewImg
                    key={fl._id}
                    style={
                      !fl.name.includes('.pdf') ? { height: 100, margin: 6 } : { minHeight: 35, width: 'fit-content' }
                    }
                  >
                    <LogIcon fl={fl} handleClickEvents={handleClickEvents(fl, files)} index={i} />
                  </PreviewImg>
                ))}
              </Box>
            ) : null}
          </div>
        </TimeLineItem>
      );
    }

    if (item.type === 'PROJECT_NOTE_TAGS_ADDED') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> has added {item?.data?.tags?.length > 1 ? 'tags' : 'tag'}{' '}
            {item?.data?.tags?.map((tag) => (
              <Tag bg={tag?.backgroundColor} color={tag?.textColor} key={tag?._id} text={tag?.name} />
            ))}{' '}
            to note to project: <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.project?.name}</span>
          </Typography>
        </TimeLineItem>
      );
    }

    if (item.type === 'PROJECT_ATTACHMENT_TAGS_ADDED') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> has added {item?.data?.tags?.length > 1 ? 'tags' : 'tag'}{' '}
            {item?.data?.tags?.map((tag) => (
              <Tag bg={tag?.backgroundColor} color={tag?.textColor} key={tag?._id} text={tag?.name} />
            ))}{' '}
            to attachment: <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.file?.name}</span> to
            project: <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.project?.name}</span>
          </Typography>
        </TimeLineItem>
      );
    }

    if (item.type === 'PROJECT_ATTACHMENT_TAGS_DELETE') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> has removed {item?.data?.tags?.length > 1 ? 'tags' : 'tag'}{' '}
            {item?.data?.tags?.map((tag) => (
              <Tag bg={tag?.backgroundColor} color={tag?.textColor} key={tag?._id} text={tag?.name} />
            ))}{' '}
            from attachment: <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.file?.name}</span> from
            project: <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.project?.name}</span>
          </Typography>
        </TimeLineItem>
      );
    }

    if (item.type === 'PROJECT_NOTE_FILE' && item?.data?.note && !item?.data?.files.length) {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> made a <span className={classes.emphasis}>new comment</span> to project{' '}
            {/* <Link to={`/case/${item?.data?.form?.code}`}> */}
            <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.project?.name}</span>
            {/* </Link> */}
            <br />
            <HTMLContent content={item?.data?.note} />
          </Typography>
        </TimeLineItem>
      );
    }
    if (item.type === 'PROJECT_NOTE_FILE' && item?.data?.files.length > 0 && item?.data?.note) {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> made a <span className={classes.emphasis}>new comment</span> and{' '}
            <span className={classes.emphasis}>uploaded {item?.data?.files.length}</span> file(s) in the project:{' '}
            {/* <Link to={`/case/${item?.data?.form?.code}`}> */}
            <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.project?.name}</span>
            {/* </Link> */}
            <HTMLContent content={item?.data?.note} />
            <br />
            <Box display="flex" margin="10px 0" flexDirection="column">
              {item?.data?.files?.map((fl, i) => (
                <PreviewImg
                  key={fl._id}
                  style={
                    !fl.name.includes('.pdf') ? { height: '100%', margin: 6 } : { minHeight: 35, width: 'fit-content' }
                  }
                >
                  <LogIcon fl={fl} handleClickEvents={handleClickEvents(fl, item?.data?.files)} index={i} />
                </PreviewImg>
              ))}
            </Box>
          </Typography>
        </TimeLineItem>
      );
    }
    if (item.type === 'CASES_REACH_30_DAYS') {
      const cases = item?.data?.cases;
      return (
        <>
          <Box display="flex" alignItems="center">
            <InfoIcon fontSize="50px" htmlColor="red" style={{ marginRight: 10 }} />
            <Typography className={classes.texxt}>
              The following case(s):{' '}
              {cases?.map((caseNumber, index) => (
                <Typography
                  variant="text"
                  style={{
                    color: '#2E66FE',
                    fontWeight: '500',
                    textTransform: 'capitalize',
                    cursor: 'pointer',
                  }}
                  key={index}
                  onClick={() => history.push(`/case/${caseNumber}`)}
                >
                  {caseNumber},{'  '}
                </Typography>
              ))}
              {cases.length > 1 ? 'have ' : 'has '} reached 30 Days since its creation date{' '}
              {moment(item.createdAt).subtract(30, 'days').calendar()}
            </Typography>
          </Box>
          <Box width="180px">
            <Tooltip title={getTooltipDate()}>
              <Typography style={{ fontSize: 14, marginTop: 6, color: '#cecece' }}>{getDate()}</Typography>
            </Tooltip>
          </Box>
        </>
      );
    }
    if (item.type === 'CASES_REACH_60_DAYS') {
      const cases = item?.data?.cases;
      return (
        <>
          <Box display="flex" alignItems="center">
            <InfoIcon fontSize="50px" htmlColor="red" style={{ marginRight: 10 }} />
            <Typography className={classes.texxt}>
              The following case(s):{' '}
              {cases?.map((caseNumber, index) => (
                <Typography
                  variant="text"
                  style={{
                    color: '#2E66FE',
                    fontWeight: '500',
                    textTransform: 'capitalize',
                    cursor: 'pointer',
                  }}
                  key={index}
                  onClick={() => history.push(`/case/${caseNumber}`)}
                >
                  {caseNumber},{'  '}
                </Typography>
              ))}
              {cases.length > 1 ? 'have ' : 'has '} reached 60 Days since its creation date{' '}
              {moment(item.createdAt).subtract(60, 'days').calendar()}
            </Typography>
          </Box>
          <Box width="180px">
            <Tooltip title={getTooltipDate()}>
              <Typography style={{ fontSize: 14, marginTop: 6, color: '#cecece' }}>{getDate()}</Typography>
            </Tooltip>
          </Box>
        </>
      );
    }
    if (item.type === 'CASES_REACH_90_DAYS') {
      const cases = item?.data?.cases;
      return (
        <>
          <Box display="flex" alignItems="center">
            <InfoIcon fontSize="50px" htmlColor="red" style={{ marginRight: 10 }} />
            <Typography className={classes.texxt}>
              The following case(s):{' '}
              {cases?.map((caseNumber, index) => (
                <Typography
                  variant="text"
                  style={{
                    color: '#2E66FE',
                    fontWeight: '500',
                    textTransform: 'capitalize',
                    cursor: 'pointer',
                  }}
                  key={index}
                  onClick={() => history.push(`/case/${caseNumber}`)}
                >
                  {caseNumber},{'  '}
                </Typography>
              ))}
              {cases.length > 1 ? 'have ' : 'has '} reached 90 Days since its creation date{' '}
              {moment(item.createdAt).subtract(90, 'days').calendar()}
            </Typography>
          </Box>
          <Box width="180px">
            <Tooltip title={getTooltipDate()}>
              <Typography style={{ fontSize: 14, marginTop: 6, color: '#cecece' }}>{getDate()}</Typography>
            </Tooltip>
          </Box>
        </>
      );
    }
    if (item.type === 'PROJECT_ASSIGNED') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> assigned{' '}
            <span
              style={{
                color: '#2E66FE',
                fontWeight: '500',
                textTransform: 'capitalize',
              }}
            >
              {item?.data?.assignedUser?.firstName} {item?.data?.assignedUser?.lastName}
            </span>{' '}
            to project:{' '}
            <span
              style={{
                color: '#2E66FE',
                fontWeight: '500',
                textTransform: 'capitalize',
              }}
            >
              {item?.data?.project?.name}
            </span>
          </Typography>
        </TimeLineItem>
      );
    }
    if (item.type === 'PROJECT_UNASSIGNED') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> removed{' '}
            <span
              style={{
                color: '#2E66FE',
                fontWeight: '500',
                textTransform: 'capitalize',
              }}
            >
              {item?.data?.unassignedUser?.firstName} {item?.data?.unassignedUser?.lastName}
            </span>{' '}
            to project:{' '}
            <span
              style={{
                color: '#2E66FE',
                fontWeight: '500',
                textTransform: 'capitalize',
              }}
            >
              {item?.data?.project?.name}
            </span>
          </Typography>
        </TimeLineItem>
      );
    }
    if (item.type === 'PROJECT_RELATED') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> related project{' '}
            <span
              style={{
                color: '#2E66FE',
                fontWeight: '500',
                textTransform: 'capitalize',
              }}
            >
              {item?.data?.project1?.name}
            </span>{' '}
            with project{' '}
            <span
              style={{
                color: '#2E66FE',
                fontWeight: '500',
                textTransform: 'capitalize',
              }}
            >
              {item?.data?.project2?.name}
            </span>
          </Typography>
        </TimeLineItem>
      );
    }
    if (item.type === 'PROJECT_REMOVED_RELATED') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> unrelated project{' '}
            <span
              style={{
                color: '#2E66FE',
                fontWeight: '500',
                textTransform: 'capitalize',
              }}
            >
              {item?.data?.removedProject?.name}
            </span>{' '}
            with project{' '}
            <span
              style={{
                color: '#2E66FE',
                fontWeight: '500',
                textTransform: 'capitalize',
              }}
            >
              {item?.data?.project?.name}
            </span>
          </Typography>
        </TimeLineItem>
      );
    }
    if (item.type === 'PROJECT_RECOMMENDATIONS_DELETED') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> deleted a recommendation from project:{' '}
            <span
              style={{
                color: '#2E66FE',
                fontWeight: '500',
                textTransform: 'capitalize',
              }}
            >
              {item?.data?.project?.name}
            </span>
          </Typography>
        </TimeLineItem>
      );
    }
    if (item.type === 'PROJECT_RECOMMENDATIONS_EDITED') {
      const _user = item.data.user;

      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            {item?.data?.changes?.map((change, index) => {
              const isLongText = change.key === 'description' || change.key === 'notes';

              if (isLongText) {
                return (
                  <Box key={index}>
                    <UserInfo user={_user} /> has {!change?.oldValue ? 'added' : 'changed'}{' '}
                    {getActionLabel(change?.key)} in recommendation
                    {!change?.oldValue ? (
                      <HTMLContent content={change.newValue} />
                    ) : (
                      <>
                        {' from: '}
                        <HTMLContent content={change.oldValue} />
                        {' to: '}
                        <HTMLContent content={change.newValue} />
                      </>
                    )}
                  </Box>
                );
              }
              return (
                <Box key={index}>
                  {!change?.oldValue ? (
                    <>
                      <UserInfo user={_user} /> has added{' '}
                      <span className={classes.emphasis}>
                        {validateIsoDate(change.newValue.toString())
                          ? moment(change.newValue).format('DD-MM-YYYY')
                          : change.newValue}
                      </span>{' '}
                      to {getActionLabel(change?.key)} in recommendation
                    </>
                  ) : (
                    <>
                      <UserInfo user={_user} /> has changed {getActionLabel(change?.key)} to recommendations{' '}
                      <span className={classes.emphasis}>
                        {validateIsoDate(change.oldValue.toString())
                          ? moment(change.oldValue).format('DD-MM-YYYY')
                          : change.oldValue}
                      </span>{' '}
                      to{' '}
                      <span className={classes.emphasis}>
                        {validateIsoDate(change.newValue.toString())
                          ? moment(change.newValue).format('DD-MM-YYYY')
                          : change.newValue}
                      </span>{' '}
                    </>
                  )}
                </Box>
              );
            })}
            on project:{' '}
            <span
              style={{
                color: '#2E66FE',
                fontWeight: '500',
                textTransform: 'capitalize',
              }}
            >
              {item?.data?.project?.name}
            </span>
          </Typography>
        </TimeLineItem>
      );
    }
    if (item.type === 'PROJECT_CREATED') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> created project{' '}
            <span
              style={{
                color: '#2E66FE',
                fontWeight: '500',
                textTransform: 'capitalize',
              }}
            >
              {item?.data?.project?.name}
            </span>
          </Typography>
        </TimeLineItem>
      );
    }
    if (item.type === 'PROJECT_DELETED') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> deleted project{' '}
            <span
              style={{
                color: '#2E66FE',
                fontWeight: '500',
                textTransform: 'capitalize',
              }}
            >
              {item?.data?.project?.name}
            </span>
          </Typography>
        </TimeLineItem>
      );
    }
    if (item.type === 'PROJECT_TEMPLATE_SAVED') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> saved a project template{' '}
            <span
              style={{
                color: '#2E66FE',
                fontWeight: '500',
                textTransform: 'capitalize',
              }}
            >
              {item?.data?.projectTemplate?.name}
            </span>{' '}
            in the project:{' '}
            <span
              style={{
                color: '#2E66FE',
                fontWeight: '500',
                textTransform: 'capitalize',
              }}
            >
              {item?.data?.project?.name}
            </span>
          </Typography>
        </TimeLineItem>
      );
    }
    if (item.type === 'PROJECT_ACTIONS_ADDED') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> added a new action in the project:{' '}
            <span
              style={{
                color: '#2E66FE',
                fontWeight: '500',
                textTransform: 'capitalize',
              }}
            >
              {item?.data?.project?.name}
            </span>
          </Typography>
        </TimeLineItem>
      );
    }
    if (item.type === 'PROJECT_ACTIONS_ASSIGNED') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> assigned a new user{' '}
            <span
              style={{
                color: '#2E66FE',
                fontWeight: '500',
                textTransform: 'capitalize',
              }}
            >
              {item?.data?.unassignedUser?.firstName} {item?.data?.unassignedUser?.lastName}
            </span>{' '}
            to action in the project:{' '}
            <span
              style={{
                color: '#2E66FE',
                fontWeight: '500',
                textTransform: 'capitalize',
              }}
            >
              {item?.data?.project?.name}
            </span>
          </Typography>
        </TimeLineItem>
      );
    }
    if (item.type === 'PROJECT_ACTIONS_REMOVED') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> removed action in the project:{' '}
            <span
              style={{
                color: '#2E66FE',
                fontWeight: '500',
                textTransform: 'capitalize',
              }}
            >
              {item?.data?.project?.name}
            </span>
          </Typography>
        </TimeLineItem>
      );
    }
    if (item.type === 'PROJECT_ACTIONS_UPDATED') {
      const { action, project } = item.data;
      const _user = item.data.user;
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            {item?.data?.changes?.map((change, index) => {
              const isNotes = change.key === 'notes';
              if (isNotes) {
                return (
                  <Box key={index}>
                    {!change?.oldValue ? (
                      <>
                        <UserInfo user={_user} /> has added a note to action {action} in the{' '}
                        {getActionLabel(change?.key)} to project{' '}
                        <span className={classes.emphasis}>{project?.name}</span>{' '}
                        <HTMLContent content={change.newValue} />
                      </>
                    ) : (
                      <>
                        <UserInfo user={_user} /> has changed action {action} {getActionLabel(change?.key)}
                        {' for project '}
                        <span className={classes.emphasis}>{project?.name}</span> {' from:'}
                        <HTMLContent content={change.oldValue} />
                        {' to:'}
                        <HTMLContent content={change.newValue} />
                      </>
                    )}
                  </Box>
                );
              }
              return (
                <Box key={index}>
                  {!change?.oldValue ? (
                    <>
                      <UserInfo user={_user} /> has added a note to action {action} in the {getActionLabel(change?.key)}{' '}
                      to project <span className={classes.emphasis}>{project?.name}</span>{' '}
                      <HTMLContent content={change.newValue} />
                      <strong>{item?.data?.user?.email}</strong> has added{' '}
                      <span className={classes.emphasis}>
                        {validateIsoDate(change.newValue.toString())
                          ? moment(change.newValue).format('DD-MM-YYYY')
                          : change.newValue}
                      </span>{' '}
                      to action in the {getActionLabel(change?.key)} to project{' '}
                      <span className={classes.emphasis}>{item?.data?.project?.name}</span>{' '}
                    </>
                  ) : (
                    <>
                      <UserInfo user={_user} /> has changed to action {getActionLabel(change?.key)}{' '}
                      <span className={classes.emphasis}>
                        {validateIsoDate(change.oldValue.toString())
                          ? moment(change.oldValue).format('DD-MM-YYYY')
                          : change.oldValue}
                      </span>{' '}
                      to{' '}
                      <span className={classes.emphasis}>
                        {validateIsoDate(change.newValue.toString())
                          ? moment(change.newValue).format('DD-MM-YYYY')
                          : change.newValue}
                      </span>{' '}
                      for project <span className={classes.emphasis}>{item?.data?.project?.name}</span>{' '}
                    </>
                  )}
                </Box>
              );
            })}
          </Typography>
        </TimeLineItem>
      );
    }
    if (item.type === 'PROJECT_VIEWED') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> viewed project:{' '}
            <span
              style={{
                color: '#2E66FE',
                fontWeight: '500',
                textTransform: 'capitalize',
              }}
            >
              {item?.data?.project?.name}
            </span>
          </Typography>
        </TimeLineItem>
      );
    }
    if (item.type === 'PROJECT_RECOMMENDATIONS_ADDED') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> created a new recommendation in project{' '}
            <span
              style={{
                color: '#2E66FE',
                fontWeight: '500',
                textTransform: 'capitalize',
              }}
            >
              {item?.data?.project?.name}
            </span>
          </Typography>
        </TimeLineItem>
      );
    }
    if (item.type === 'PROJECT_STATUS_CHANGED') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> changed Status from{' '}
            <span
              style={{
                color: '#2E66FE',
                fontWeight: '500',
                textTransform: 'capitalize',
              }}
            >
              {item?.data?.oldStatus}
            </span>{' '}
            to{' '}
            <span
              style={{
                color: '#2E66FE',
                fontWeight: '500',
                textTransform: 'capitalize',
              }}
            >
              {item?.data?.newStatus}
            </span>{' '}
            in the project:{' '}
            <span
              style={{
                color: '#2E66FE',
                fontWeight: '500',
                textTransform: 'capitalize',
              }}
            >
              {item?.data?.project?.name}
            </span>
          </Typography>
        </TimeLineItem>
      );
    }
    if (item.type === 'PROJECT_SUBTYPE_CHANGED') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> changed Subtype from{' '}
            <span
              style={{
                color: '#2E66FE',
                fontWeight: '500',
                textTransform: 'capitalize',
              }}
            >
              {item?.data?.oldSubType}
            </span>{' '}
            to{' '}
            <span
              style={{
                color: '#2E66FE',
                fontWeight: '500',
                textTransform: 'capitalize',
              }}
            >
              {item?.data?.newSubType}
            </span>{' '}
            in the project:{' '}
            <span
              style={{
                color: '#2E66FE',
                fontWeight: '500',
                textTransform: 'capitalize',
              }}
            >
              {item?.data?.project?.name}
            </span>
          </Typography>
        </TimeLineItem>
      );
    }
    if (item.type === 'PROJECT_TYPE_CHANGED') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> changed Type from{' '}
            <span
              style={{
                color: '#2E66FE',
                fontWeight: '500',
                textTransform: 'capitalize',
              }}
            >
              {item?.data?.oldType}
            </span>{' '}
            to{' '}
            <span
              style={{
                color: '#2E66FE',
                fontWeight: '500',
                textTransform: 'capitalize',
              }}
            >
              {item?.data?.newType}
            </span>{' '}
            in the project:{' '}
            <span
              style={{
                color: '#2E66FE',
                fontWeight: '500',
                textTransform: 'capitalize',
              }}
            >
              {item?.data?.project?.name}
            </span>
          </Typography>
        </TimeLineItem>
      );
    }

    if (item.type === 'PROJECT_NOTE_UNARCHIVED') {
      return (
        <span className={classes.texxt}>
          <UserInfo user={user} /> has unarchived the note{' '}
          <span style={{ color: '#2E66FE', fontWeight: '500' }}>{stripHTML(item?.data?.comment?.comment)}</span> on the project with tracking
          number{' '}
          <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.form?.code}</span>

        </span>
      );
    }

    if (item.type === 'PROJECT_NOTE_ARCHIVED') {
      return (
        <span className={classes.texxt}>
          <UserInfo user={user} /> has archived the note{' '}
          <span style={{ color: '#2E66FE', fontWeight: '500' }}>{stripHTML(item?.data?.comment?.comment)}</span> on the project with tracking
          number{' '}
          <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.form?.code}</span>
        </span>
      );
    }
    if (item.type === 'USER_PASSWORD_RESETED') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} />{' '}
            <span
              style={{
                color: '#2E66FE',
                fontWeight: '500',
                textTransform: 'capitalize',
              }}
            >
              reset the account password
            </span>
          </Typography>
        </TimeLineItem>
      );
    }
    if (item.type === 'USER_PASSWORD_UPDATED') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} />{' '}
            <span
              style={{
                color: '#2E66FE',
                fontWeight: '500',
                textTransform: 'capitalize',
              }}
            >
              updated the account password
            </span>
          </Typography>
        </TimeLineItem>
      );
    }
    if (item.type === 'ADHOC_REPORT_DELETED') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> deleted a{' '}
            <span
              style={{
                color: '#2E66FE',
                fontWeight: '500',
                textTransform: 'capitalize',
              }}
            >
              {item?.data?.adHocReport?.type?.toLowerCase()}
            </span>{' '}
            report with the name{' '}
            <span
              style={{
                color: '#2E66FE',
                fontWeight: '500',
                textTransform: 'capitalize',
              }}
            >
              {item?.data?.adHocReport?.name?.toLowerCase()}
            </span>
            .
          </Typography>
        </TimeLineItem>
      );
    }
    if (item.type === 'ADHOC_REPORT_CREATED') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> created a{' '}
            <span
              style={{
                color: '#2E66FE',
                fontWeight: '500',
                textTransform: 'capitalize',
              }}
            >
              {item?.data?.adHocReport?.type?.toLowerCase()}
            </span>{' '}
            report with the name{' '}
            <span
              style={{
                color: '#2E66FE',
                fontWeight: '500',
                textTransform: 'capitalize',
              }}
            >
              {item?.data?.adHocReport?.name?.toLowerCase()}
            </span>
          </Typography>
        </TimeLineItem>
      );
    }
    if (item.type === 'ADHOC_REPORT_DELETED') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> deleted a report.
          </Typography>
        </TimeLineItem>
      );
    }
    if (item.type === 'CASE_REFERRAL_DESTINATION') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={`${item?.data?.oldOrganization?.prefix}`}
        >
          <Typography className={classes.texxt}>
            This case was transferred from organization{' '}
            <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.oldOrganization?.name}</span> with a new
            tracking number:
            <Typography className={classes.texxt}>
              <Link to={`/case/${item?.data?.form?.code}`}>
                <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.form?.code}</span>
              </Link>
            </Typography>{' '}
          </Typography>
        </TimeLineItem>
      );
    }
    if (item.type === 'CASE_REFERRAL_ORIGIN') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <strong>{item?.data?.user?.email}</strong> transfer case{' '}
            <Link to={`/case/${item?.data?.form?.code}`}>
              <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.form?.code}</span>
            </Link>{' '}
            to organization{' '}
            <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.newOrganization?.name}</span> with a new
            tracking number: <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.newForm?.code}</span>
          </Typography>
        </TimeLineItem>
      );
    }
    if (item.type === 'CASES_DOWNLOAD') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> downloaded{' '}
            <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.forms?.length}</span>{' '}
            {item?.data?.forms?.length > 1 ? 'cases' : 'case'} with the following tracking number(s):{' '}
          </Typography>
          {item?.data?.forms?.map((form, index) => (
            <Typography className={classes.texxt}>
              <Link to={`/case/${form}`} key={index}>
                <span style={{ color: '#2E66FE', fontWeight: '500' }}>{form}</span>
              </Link>
            </Typography>
          ))}
        </TimeLineItem>
      );
    }

    if (item.type === 'CASE_LINKED') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> The case with tracking number{' '}
            <Link to={`/case/${item?.data?.form?.code}`}>
              <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.form?.code}</span>
            </Link>{' '}
            has been linked to the case with tracking number{' '}
            <Link to={`/case/${item?.data?.otherForm?.code}`}>
              <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.otherForm?.code}</span>
            </Link>
            .
          </Typography>
        </TimeLineItem>
      );
    }

    if (item.type === 'CASE_UNLINKED') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> The case with tracking number{' '}
            <Link to={`/case/${item.data?.form?.code}`}>
              <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item.data?.form?.code}</span>
            </Link>{' '}
            has been unlinked to the case with tracking number{' '}
            <Link to={`/case/${item.data?.otherForm?.code}`}>
              <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item.data?.otherForm?.code}</span>
            </Link>
            .
          </Typography>
        </TimeLineItem>
      );
    }

    if (item.type === 'ALLEGATIONS_DOWNLOADED') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> downloaded{' '}
            <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.count}</span>{' '}
            {item?.data?.count === 1 ? 'allegation' : 'allegations'} with the following tracking number(s):{' '}
          </Typography>
          {item?.data?.forms?.map((form, index) => (
            <Typography className={classes.texxt}>
              <Link to={`/case/${form}`} key={index}>
                <span style={{ color: '#2E66FE', fontWeight: '500' }}>{form}</span>
              </Link>
            </Typography>
          ))}
        </TimeLineItem>
      );
    }
    if (item.type === 'ACTIVITY_DOWNLOADED') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> downloaded an activity file from{' '}
            <span style={{ color: '#2E66FE', fontWeight: '500' }}>
              {moment(item?.data?.startDate).format('MM-DD-YYYY')}
            </span>{' '}
            to{' '}
            <span style={{ color: '#2E66FE', fontWeight: '500' }}>
              {moment(item?.data?.endDate).format('MM-DD-YYYY')}{' '}
            </span>
          </Typography>
        </TimeLineItem>
      );
    }
    if (item.type === 'LOGOUT') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> logged out.{' '}
          </Typography>
        </TimeLineItem>
      );
    }
    if (item.type === 'LOGIN') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> logged in.{' '}
          </Typography>
        </TimeLineItem>
      );
    }

    if (item.type === 'CASE_EDIT_DATA') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> edited case detail:{' '}
            <Link to={`/case/${item?.data?.form?.code}`}>
              <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.form?.code}</span>
            </Link>
            <br />
            {item?.data?.changes?.length > 0 ? (
              <>
                {item?.data?.changes
                  // ?.filter((change) => change?.newValue)
                  ?.map((change) => (
                    <div key={change?.fieldName}>
                      <span>
                        changed <b>{change?.fieldName}</b> <LogCaseChange change={change} />
                      </span>
                    </div>
                  ))}
              </>
            ) : (
              <>Without changes</>
            )}
          </Typography>
        </TimeLineItem>
      );
    }

    if (item.type === 'CASE_VIEW') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> viewed case:{' '}
            <Link to={`/case/${item?.data?.form?.code}`}>
              <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.form?.code}</span>
            </Link>
          </Typography>
        </TimeLineItem>
      );
    }

    if (item.type === 'CASE_ATTACHMENT_TAGS_ADDED') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> has added {item?.data?.tags?.length > 1 ? 'tags' : 'tag'}{' '}
            {item?.data?.tags?.map((tag) => (
              <Tag bg={tag?.backgroundColor} color={tag?.textColor} key={tag?._id} text={tag?.name} />
            ))}{' '}
            to attachment: <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.file?.name}</span> to
            case:{' '}
            <Link to={`/case/${item?.data?.form?.code}`}>
              <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.form?.code}</span>
            </Link>
          </Typography>
        </TimeLineItem>
      );
    }

    if (item.type === 'CASE_ATTACHMENT_TAGS_DELETED') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> has removed {item?.data?.tags?.length > 1 ? 'tags' : 'tag'}{' '}
            {item?.data?.tags?.map((tag) => (
              <Tag bg={tag?.backgroundColor} color={tag?.textColor} key={tag?._id} text={tag?.name} />
            ))}{' '}
            from attachment <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.file?.name}</span> from
            case:{' '}
            <Link to={`/case/${item?.data?.form?.code}`}>
              <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.form?.code}</span>
            </Link>
          </Typography>
        </TimeLineItem>
      );
    }

    if (item.type === 'CASE_TASK_ATTACHMENT_TAGS_ADDED') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> has added {item?.data?.tags?.length > 1 ? 'tags' : 'tag'}{' '}
            {item?.data?.tags?.map((tag) => (
              <Tag bg={tag?.backgroundColor} color={tag?.textColor} key={tag?._id} text={tag?.name} />
            ))}{' '}
            to task attachment: <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.file?.name}</span> to
            case:{' '}
            <Link to={`/case/${item?.data?.form?.code}`}>
              <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.form?.code}</span>
            </Link>
          </Typography>
        </TimeLineItem>
      );
    }

    if (item.type === 'CASE_TASK_ATTACHMENT_TAGS_DELETED') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> has removed {item?.data?.tags?.length > 1 ? 'tags' : 'tag'}{' '}
            {item?.data?.tags?.map((tag) => (
              <Tag bg={tag?.backgroundColor} color={tag?.textColor} key={tag?._id} text={tag?.name} />
            ))}{' '}
            from task attachment <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.file?.name}</span>{' '}
            from case:{' '}
            <Link to={`/case/${item?.data?.form?.code}`}>
              <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.form?.code}</span>
            </Link>
          </Typography>
        </TimeLineItem>
      );
    }

    if (
      item.type === 'CASE_TASK_UPDATED' ||
      item.type === 'CASE_TASK_ASSIGNMENT_ADD' ||
      item.type === 'CASE_TASK_ASSIGNMENT_REMOVE' ||
      item.type === 'CASE_TASK_ATTACHMENT_ADDED' ||
      item.type === 'CASE_TASK_ATTACHMENT_DELETED'
    ) {
      return <CaseTaskUpdateItem item={item} user={user} withTimeLine withCaseLink />;
    }
    if (item.type === 'ORGANIZATION_TASK_TYPES_UPDATED') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> updated task types
          </Typography>
        </TimeLineItem>
      );
    }
    if (item.type === 'ORGANIZATION_TASK_SETTINGS_UPDATED') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> updated task settings
          </Typography>
        </TimeLineItem>
      );
    }
    if (item.type === 'FORMTYPE_TASK_UPDATED') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> form type updated task:{' '}
            <Link to={`/form/${item?.data?.formType?._id}`}>
              <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.formType?.name}</span>
            </Link>
          </Typography>
        </TimeLineItem>
      );
    }
    if (item.type === 'CASE_ARCHIVED') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> archived case:{' '}
            <Link to={`/case/${item?.data?.form?.code}`}>
              <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.form?.code}</span>
            </Link>
          </Typography>
        </TimeLineItem>
      );
    }
    if (item.type === 'CASE_UNARCHIVED') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> restored case:{' '}
            <Link to={`/case/${item?.data?.form?.code}`}>
              <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.form?.code}</span>
            </Link>
          </Typography>
        </TimeLineItem>
      );
    }
    if (item.type === 'CASE_DELETE') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> deleted case:{' '}
            <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.form?.code}</span>
          </Typography>
        </TimeLineItem>
      );
    }
    if (item.type === 'CASE_ASSIGNMENT_ADD') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> has assigned user:{' '}
            <span style={{ color: '#2E66FE', fontWeight: '500' }}>
              {item?.data?.assignments?.[0]?.firstName} {item?.data?.assignments?.[0]?.lastName}
            </span>{' '}
            to case:{' '}
            <Link to={`/case/${item?.data?.form?.code}`}>
              <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.form?.code}</span>
            </Link>
          </Typography>
        </TimeLineItem>
      );
    }
    if (item.type === 'CASE_ASSIGNMENT_NOTIFY_ASSIGNED') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> has assigned you to case:{' '}
            <Link to={`/case/${item?.data?.form?.code}`}>
              <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.form?.code}</span>
            </Link>
          </Typography>
        </TimeLineItem>
      );
    }
    if (item.type === 'CASE_ASSIGNMENT_REMOVE') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> has removed user:{' '}
            <span style={{ color: '#2E66FE', fontWeight: '500' }}>
              {item?.data?.assignments?.[0]?.firstName} {item?.data?.assignments?.[0]?.lastName}
            </span>{' '}
            from the case:{' '}
            <Link to={`/case/${item?.data?.form?.code}`}>
              <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.form?.code}</span>
            </Link>
          </Typography>
        </TimeLineItem>
      );
    }
    if (item.type === 'OFFICER_DELETED') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> removed officer{' '}
            <span
              style={{ color: '#2E66FE', fontWeight: '500' }}
            >{`${item?.data?.officer?.firstName} ${item?.data?.officer?.lastName}`}</span>{' '}
            with Badge/Serial Number{' '}
            <Link to={`/officer/${item?.data?.officer?._id}`}>
              <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.officer?.badgeNumber}</span>{' '}
            </Link>
          </Typography>
        </TimeLineItem>
      );
    }
    if (item.type === 'OFFICER_CREATED') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> added officer{' '}
            <span
              style={{ color: '#2E66FE', fontWeight: '500' }}
            >{`${item?.data?.officer?.firstName} ${item?.data?.officer?.lastName}`}</span>{' '}
            with Badge/Serial Number{' '}
            <Link to={`/officer/${item?.data?.officer?._id}`}>
              <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.officer?.badgeNumber}</span>{' '}
            </Link>
          </Typography>
        </TimeLineItem>
      );
    }

    if (item.type === 'CASE_OFFICER_ADD') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> has added officer{' '}
            <span
              style={{ color: '#2E66FE', fontWeight: '500' }}
            >{`${item?.data?.officers?.[0]?.firstName} ${item?.data?.officers?.[0]?.lastName}`}</span>{' '}
            with Badge/Serial Number{' '}
            <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.officers?.[0]?.badgeNumber}</span> to
            case:{' '}
            <Link to={`/case/${item?.data?.form?.code}`}>
              <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.form?.code}</span>
            </Link>
          </Typography>
        </TimeLineItem>
      );
    }
    if (item.type === 'CASE_OFFICER_REMOVE') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> removed officer{' '}
            <span
              style={{ color: '#2E66FE', fontWeight: '500' }}
            >{`${item?.data?.officers?.[0]?.firstName} ${item?.data?.officers?.[0]?.lastName}`}</span>{' '}
            with Badge/Serial Number{' '}
            <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.officers?.[0]?.badgeNumber}</span> from
            case:{' '}
            <Link to={`/case/${item?.data?.form?.code}`}>
              <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.form?.code}</span>
            </Link>
          </Typography>
        </TimeLineItem>
      );
    }
    if (item.type === 'OFFICER_CREATED') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> created officer{' '}
            <span
              style={{ color: '#2E66FE', fontWeight: '500' }}
            >{`${item?.data?.officer?.firstName} ${item?.data?.officer?.lastName}`}</span>{' '}
            with Badge/Serial Number{' '}
            <Link to={`/officer/${item?.data?.officer?._id}`}>
              <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.officer?.badgeNumber}</span>{' '}
            </Link>
          </Typography>
        </TimeLineItem>
      );
    }
    if (item.type === 'OFFICER_VIEW') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> viewed officer{' '}
            <span
              style={{ color: '#2E66FE', fontWeight: '500' }}
            >{`${item?.data?.officer?.firstName} ${item?.data?.officer?.lastName}`}</span>{' '}
            with Badge/Serial Number{' '}
            <Link to={`/officer/${item?.data?.officer?._id}`}>
              <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.officer?.badgeNumber}</span>{' '}
            </Link>
          </Typography>
        </TimeLineItem>
      );
    }
    if (item.type === 'USER_VIEW') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> viewed user{' '}
            <Link to={`/user/${item?.data?.otherUser?._id}`}>
              <span
                style={{ color: '#2E66FE', fontWeight: '500' }}
              >{`${item?.data?.otherUser?.firstName} ${item?.data?.otherUser?.lastName}`}</span>
            </Link>
          </Typography>
        </TimeLineItem>
      );
    }
    if (item.type === 'INVITATION_CREATED') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> invited user{' '}
            <span
              style={{ color: '#2E66FE', fontWeight: '500' }}
            >{`${item?.data?.invitation?.firstName} ${item?.data?.invitation?.lastName} (${item?.data?.invitation?.email})`}</span>{' '}
            to <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.invitation?.type}</span> role.
          </Typography>
        </TimeLineItem>
      );
    }
    if (item.type === 'INVITATION_RESEND') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> resent an invitation to{' '}
            <span
              style={{ color: '#2E66FE', fontWeight: '500' }}
            >{`${item?.data?.invitation?.firstName} ${item?.data?.invitation?.lastName} (${item?.data?.invitation?.email})`}</span>{' '}
            for the <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.invitation?.type}</span> role.
          </Typography>
        </TimeLineItem>
      );
    }
    if (item.type === 'INVITATION_DELETED') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> deleted the invitation for{' '}
            <span
              style={{ color: '#2E66FE', fontWeight: '500' }}
            >{`${item?.data?.invitation?.firstName} ${item?.data?.invitation?.lastName} (${item?.data?.invitation?.email})`}</span>{' '}
          </Typography>
        </TimeLineItem>
      );
    }
    if (
      item.type === 'FORMTYPE_CREATED' ||
      item.type === 'FORMTYPE_UPDATED' ||
      item.type === 'FORMTYPE_DELETED' ||
      item.type === 'FORMTYPE_DUPLICATED'
    ) {
      const idForm = item?.data?.formType?._id ?? null;
      const dataUser = {
        email: item?.data?.user?.email ?? '',
        avatar: getUserInitials(item?.data?.user),
        photo: item?.data?.user?.photo?.public_url,
      };
      const nameForm = item?.data?.formType?.name ?? '';
      const nameOriginal = item?.data?.originalFormType?.name ?? '';
      const descriptionForm =
        item.type === 'FORMTYPE_CREATED'
          ? 'created form with the name:'
          : item.type === 'FORMTYPE_UPDATED'
          ? 'update form with the name:'
          : item.type === 'FORMTYPE_DELETED'
          ? 'erased form with the name:'
          : '';
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={dataUser.photo}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={dataUser.avatar}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> {descriptionForm}{' '}
            {item.type === 'FORMTYPE_DUPLICATED' ? (
              <>
                duplicated form from{' '}
                <Link to={`/form/${item?.data?.originalFormType?._id}`}>
                  <span style={{ color: '#2E66FE', fontWeight: '500' }}>{nameOriginal}</span>
                </Link>{' '}
                and create a new form with the name:{' '}
              </>
            ) : null}
            <Link to={`/form/${idForm}`}>
              <span style={{ color: '#2E66FE', fontWeight: '500' }}>{nameForm}</span>
            </Link>
          </Typography>
        </TimeLineItem>
      );
    }
    if (item.type === 'USER_REGISTERED') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <span
              style={{ color: '#2E66FE', fontWeight: '500' }}
            >{`${user?.firstName} ${user?.lastName} (${user?.email})`}</span>{' '}
            completed registration as for the{' '}
            <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.user?.role}</span> role.
          </Typography>
        </TimeLineItem>
      );
    }
    if (item.type === 'USER_DELETED') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> deleted user{' '}
            <span
              style={{ color: '#2E66FE', fontWeight: '500' }}
            >{`${item?.data?.otherUser?.firstName} ${item?.data?.otherUser?.lastName} (${item?.data?.otherUser?.email})`}</span>{' '}
            from the <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.otherUser?.role}</span> role.
          </Typography>
        </TimeLineItem>
      );
    }
    if (item.type === 'CASE_ALLEGATION_ROW_ADD') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> added an allegation row to Officer{' '}
            <span
              style={{ color: '#2E66FE', fontWeight: '500' }}
            >{`${item?.data?.officer?.firstName} ${item?.data?.officer?.lastName}`}</span>{' '}
            with Badge/Serial Number{' '}
            <Link to={`/officer/${item?.data?.officer?._id}`}>
              <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.officer?.badgeNumber}</span>{' '}
            </Link>
            to case:{' '}
            <Link to={`/case/${item?.data?.form?.code}`}>
              <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.form?.code}</span>
            </Link>
          </Typography>
        </TimeLineItem>
      );
    }
    if (item.type === 'CASE_ALLEGATION_ROW_DELETE') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> has removed an Allegation row to Officer{' '}
            <span
              style={{ color: '#2E66FE', fontWeight: '500' }}
            >{`${item?.data?.officer?.firstName} ${item?.data?.officer?.lastName}`}</span>{' '}
            with Badge/Serial Number{' '}
            <Link to={`/officer/${item?.data?.officer?._id}`}>
              <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.officer?.badgeNumber}</span>{' '}
            </Link>
            to case:{' '}
            <Link to={`/case/${item?.data?.form?.code}`}>
              <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.form?.code}</span>
            </Link>
          </Typography>
        </TimeLineItem>
      );
    }
    if (item.type === 'CASE_COMMENT_OR_ATTACHMENT' && item?.data?.files.length > 0 && item?.data?.comment) {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> made a <span className={classes.emphasis}>new comment</span> and{' '}
            <span className={classes.emphasis}>uploaded {item?.data?.files.length}</span> file(s) in the case:{' '}
            <Link to={`/case/${item?.data?.form?.code}`}>
              <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.form?.code}</span>
            </Link>
            <br />
            <div
              style={{
                fontSize: 14,
                marginTop: 6,
                color: '#585858',
                display: 'block',
              }}
            >
              <HTMLContent content={item?.data?.comment} />
            </div>
            <br />
            <Box display="flex" flexDirection="column">
              {item?.data?.files?.map((fl, i) => (
                <PreviewImg
                  key={fl._id}
                  style={
                    !fl.name.includes('.pdf') ? { height: '100%', margin: 6 } : { minHeight: 35, width: 'fit-content' }
                  }
                >
                  <LogIcon fl={fl} handleClickEvents={handleClickEvents(fl, item?.data?.files)} index={i} />
                </PreviewImg>
              ))}
            </Box>
          </Typography>
        </TimeLineItem>
      );
    }
    if (item.type === 'CASE_COMMENT_OR_ATTACHMENT' && item?.data?.comment) {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> made a <span className={classes.emphasis}>new comment</span> in the case:{' '}
            <Link to={`/case/${item?.data?.form?.code}`}>
              <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.form?.code}</span>
            </Link>
            <br />
            <div
              style={{
                fontSize: 14,
                marginTop: 6,
                color: '#585858',
                display: 'block',
              }}
            >
              <HTMLContent content={item?.data?.comment} />
            </div>
          </Typography>
        </TimeLineItem>
      );
    }
    if (item.type === 'CASE_COMMENT_OR_ATTACHMENT' && item?.data?.files.length > 0) {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> <span className={classes.emphasis}>uploaded {item?.data?.files.length}</span>{' '}
            file(s) in the case:{' '}
            <Link to={`/case/${item?.data?.form?.code}`}>
              <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.form?.code}</span>
            </Link>
            <br />
            <Box display="flex" margin="10px 0" flexDirection="column">
              {item?.data?.files?.map((fl, i) => (
                <PreviewImg
                  key={fl._id}
                  style={
                    !fl.name.includes('.pdf') ? { height: '100%', margin: 6 } : { minHeight: 35, width: 'fit-content' }
                  }
                >
                  <LogIcon fl={fl} handleClickEvents={handleClickEvents(fl, item?.data?.files)} index={i} />
                </PreviewImg>
              ))}
            </Box>
          </Typography>
        </TimeLineItem>
      );
    }
    if (item.type === 'CASE_COMMENT_EDIT') {
      const { oldComment, newComment } = item.data;
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> edited a comment in the case:{' '}
            <Link to={`/case/${item?.data?.form?.code}`}>
              <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.form?.code}</span>
            </Link>
            <br />
            <div
              style={{
                fontSize: 14,
                marginTop: 6,
                color: '#585858',
                display: 'block',
              }}
            >
              <span style={{ fontWeight: 'bold' }}>{' before:'}</span>
              <HTMLContent content={oldComment} />
              <span style={{ fontWeight: 'bold' }}>{' after:'}</span>
              <HTMLContent content={newComment} />
            </div>
          </Typography>
        </TimeLineItem>
      );
    }

    if (item.type === 'CASE_ALLEGATION_ROW_UPDATE') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            {item?.data?.changes?.map((change, index) => {
              if (change?.fieldName === 'allegation') {
                return (
                  <Box key={index}>
                    {!change?.oldValue ? (
                      <>
                        <UserInfo user={user} /> has added <span className={classes.emphasis}>{change.newValue}</span>{' '}
                        to Allegation for Officer{' '}
                        <span className={classes.emphasis}>
                          {item?.data?.officer?.firstName} {item?.data?.officer?.lastName}
                        </span>{' '}
                        with Badge Number:{' '}
                        <Link to={`/officer/${item?.data?.officer?._id}`}>
                          <span className={classes.emphasis}>{item?.data?.officer?.badgeNumber}</span>
                        </Link>
                      </>
                    ) : (
                      <>
                        <UserInfo user={user} /> has changed the Allegation from{' '}
                        <span className={classes.emphasis}>{change.oldValue}</span> to{' '}
                        <span className={classes.emphasis}>{change.newValue}</span>
                        for Officer{' '}
                        <span className={classes.emphasis}>
                          {item?.data?.officer?.firstName} {item?.data?.officer?.lastName}
                        </span>{' '}
                        with Badge Number:{' '}
                        <Link to={`/officer/${item?.data?.officer?._id}`}>
                          <span className={classes.emphasis}>{item?.data?.officer?.badgeNumber}</span>
                        </Link>
                      </>
                    )}
                  </Box>
                );
              }
              if (change?.fieldName === 'description') {
                return (
                  <Box key={index}>
                    {!change?.oldValue ? (
                      <>
                        <UserInfo user={user} /> has added a Description for Officer{' '}
                        <span className={classes.emphasis}>
                          {item?.data?.officer?.firstName}
                          {item?.data?.officer?.lastName}
                        </span>{' '}
                        with Badge Number:{' '}
                        <Link to={`/officer/${item?.data?.officer?._id}`}>
                          <span className={classes.emphasis}>{item?.data?.officer?.badgeNumber}</span>
                        </Link>
                        <HTMLContent content={change.newValue} />
                      </>
                    ) : (
                      <>
                        <UserInfo user={user} /> has changed the Description for Officer{' '}
                        <span className={classes.emphasis}>
                          {item?.data?.officer?.firstName} {item?.data?.officer?.lastName}
                        </span>{' '}
                        with Badge Number:{' '}
                        <Link to={`/officer/${item?.data?.officer?._id}`}>
                          <span className={classes.emphasis}>{item?.data?.officer?.badgeNumber}</span>
                        </Link>
                        {' from:'}
                        <HTMLContent content={change.oldValue} />
                        {' to:'}
                        <HTMLContent content={change.newValue} />
                      </>
                    )}
                  </Box>
                );
              }
              if (change?.fieldName === 'disposition') {
                return (
                  <Box key={index}>
                    {!change?.oldValue ? (
                      <>
                        <UserInfo user={user} /> has added <span className={classes.emphasis}>{change.newValue}</span>{' '}
                        to Disposition for Officer{' '}
                        <span className={classes.emphasis}>
                          {item?.data?.officer?.firstName} {item?.data?.officer?.lastName}
                        </span>{' '}
                        with Badge Number:{' '}
                        <Link to={`/officer/${item?.data?.officer?._id}`}>
                          <span className={classes.emphasis}>{item?.data?.officer?.badgeNumber}</span>
                        </Link>
                      </>
                    ) : (
                      <>
                        <UserInfo user={user} /> has changed the Disposition from{' '}
                        <span className={classes.emphasis}>{change.oldValue}</span> to{' '}
                        <span className={classes.emphasis}>{change.newValue}</span> for Officer{' '}
                        <span className={classes.emphasis}>
                          {item?.data?.officer?.firstName} {item?.data?.officer?.lastName}
                        </span>{' '}
                        with Badge Number:{' '}
                        <Link to={`/officer/${item?.data?.officer?._id}`}>
                          <span className={classes.emphasis}>{item?.data?.officer?.badgeNumber}</span>
                        </Link>
                      </>
                    )}
                  </Box>
                );
              }
              if (change?.fieldName === 'discipline') {
                return (
                  <Box key={index}>
                    {!change?.oldValue ? (
                      <>
                        <UserInfo user={user} /> has added <span className={classes.emphasis}>{change.newValue}</span>{' '}
                        to Discipline for Officer{' '}
                        <span className={classes.emphasis}>
                          {item?.data?.officer?.firstName} {item?.data?.officer?.lastName}
                        </span>{' '}
                        with Badge Number:{' '}
                        <Link to={`/officer/${item?.data?.officer?._id}`}>
                          <span className={classes.emphasis}>{item?.data?.officer?.badgeNumber}</span>
                        </Link>
                      </>
                    ) : (
                      <>
                        <UserInfo user={user} /> has changed the Discipline from{' '}
                        <span className={classes.emphasis}>{change.oldValue}</span> to{' '}
                        <span className={classes.emphasis}>{change.newValue}</span>
                        to Officer{' '}
                        <span className={classes.emphasis}>
                          {item?.data?.officer?.firstName} {item?.data?.officer?.lastName}
                        </span>{' '}
                        with Badge Number:{' '}
                        <Link to={`/officer/${item?.data?.officer?._id}`}>
                          <span className={classes.emphasis}>{item?.data?.officer?.badgeNumber}</span>
                        </Link>
                      </>
                    )}
                  </Box>
                );
              }
              if (change?.fieldName === 'reason') {
                return (
                  <Box key={index}>
                    {!change?.oldValue ? (
                      <>
                        <UserInfo user={user} /> has added <span className={classes.emphasis}>{change.newValue}</span>{' '}
                        to Reason for Officer{' '}
                        <span className={classes.emphasis}>
                          {item?.data?.officer?.firstName} {item?.data?.officer?.lastName}
                        </span>{' '}
                        with Badge Number:{' '}
                        <Link to={`/officer/${item?.data?.officer?._id}`}>
                          <span className={classes.emphasis}>{item?.data?.officer?.badgeNumber}</span>
                        </Link>
                      </>
                    ) : (
                      <>
                        <UserInfo user={user} /> has changed the Reason from{' '}
                        <span className={classes.emphasis}>{change.oldValue}</span> to{' '}
                        <span className={classes.emphasis}>{change.newValue} </span>
                        to Officer{' '}
                        <span className={classes.emphasis}>
                          {item?.data?.officer?.firstName} {item?.data?.officer?.lastName}
                        </span>{' '}
                        with Badge Number:{' '}
                        <Link to={`/officer/${item?.data?.officer?._id}`}>
                          <span className={classes.emphasis}>{item?.data?.officer?.badgeNumber}</span>
                        </Link>
                      </>
                    )}
                  </Box>
                );
              }

              if (change?.fieldName === 'status') {
                return (
                  <Box key={index}>
                    {!change?.oldValue ? (
                      <>
                        <UserInfo user={user} /> has added <span className={classes.emphasis}>{change.newValue}</span>{' '}
                        in Status to Officer{' '}
                        <span className={classes.emphasis}>
                          {item?.data?.officer?.firstName} {item?.data?.officer?.lastName}
                        </span>{' '}
                        with Badge Number:{' '}
                        <Link to={`/officer/${item?.data?.officer?._id}`}>
                          <span className={classes.emphasis}>{item?.data?.officer?.badgeNumber}</span>
                        </Link>
                      </>
                    ) : (
                      <>
                        <UserInfo user={user} /> has changed the Allegation's Status from{' '}
                        <span className={classes.emphasis}>{change.oldValue}</span> to{' '}
                        <span className={classes.emphasis}>{change.newValue}</span> for Officer{' '}
                        <span className={classes.emphasis}>
                          {item?.data?.officer?.firstName} {item?.data?.officer?.lastName}
                        </span>{' '}
                        with Badge Number:{' '}
                        <Link to={`/officer/${item?.data?.officer?._id}`}>
                          <span className={classes.emphasis}>{item?.data?.officer?.badgeNumber}</span>
                        </Link>
                      </>
                    )}
                  </Box>
                );
              }

              return null;
            })}
            to case:{' '}
            <Link to={`/case/${item?.data?.form?.code}`}>
              <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.form?.code}</span>
            </Link>
          </Typography>
        </TimeLineItem>
      );
    }
    if (item.type === 'ORGANIZATION_PERMISSION_UPDATED') {
      return (
        item?.data?.changes?.length > 0 && (
          <TimeLineItem
            unread={item?.unread}
            profileImg={item?.data?.user?.photo?.public_url}
            createdAt={item.createdAt}
            key={item?._id}
            avatar={getUserInitials(item?.data?.user)}
          >
            {item?.data?.changes?.map((change, index) => (
              <Typography className={classes.texxt} key={index}>
                <UserInfo user={user} /> change{' '}
                <span
                  style={{
                    color: '#2E66FE',
                    fontWeight: '500',
                    textTransform: 'capitalize',
                  }}
                >
                  {change.fieldName?.split('.')[1] === 'formTypes'
                    ? `${change.fieldName?.split('.')[0]} Forms`
                    : change.fieldName?.split('.').join(' ')}
                </span>{' '}
                permissions from{' '}
                <span
                  style={{
                    color: '#2E66FE',
                    fontWeight: '500',
                    textTransform: 'capitalize',
                  }}
                >
                  {permissionsOptionsForCases.find((op) => op.value === change.oldValue).text}
                </span>{' '}
                to{' '}
                <span
                  style={{
                    color: '#2E66FE',
                    fontWeight: '500',
                    textTransform: 'capitalize',
                  }}
                >
                  {permissionsOptionsForCases.find((op) => op.value === change.newValue).text}
                </span>
              </Typography>
            ))}
          </TimeLineItem>
        )
      );
    }
    if (item.type === 'CASE_UPDATE') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            {item?.data?.changes?.map((change, index) => {
              if (change?.fieldName === 'status') {
                return (
                  <Box key={index}>
                    <UserInfo user={user} /> has changed the case status from{' '}
                    <span className={classes.emphasis}>{getStatusTitle(change?.oldValue)}</span> to{' '}
                    <span className={classes.emphasis}>{getStatusTitle(change?.newValue)}</span>
                  </Box>
                );
              }

              if (change?.fieldName === 'type') {
                return (
                  <Box key={index}>
                    <UserInfo user={user} /> has changed the case type{' '}
                    <span className={classes.emphasis}>{getStatusTitle(change?.oldValue)}</span> to{' '}
                    <span className={classes.emphasis}>{getStatusTitle(change?.newValue)}</span>
                  </Box>
                );
              }

              if (change?.fieldName === 'caseNumber') {
                return (
                  <Box key={index}>
                    {!change?.oldValue ? (
                      <>
                        <UserInfo user={user} /> has changed the case number to{' '}
                        <span className={classes.emphasis}>{change.newValue}</span>
                      </>
                    ) : (
                      <>
                        <UserInfo user={user} /> has changed the case number from{' '}
                        <span className={classes.emphasis}>{change.oldValue}</span> to{' '}
                        <span className={classes.emphasis}>{change.newValue}</span>
                      </>
                    )}
                  </Box>
                );
              }

              if (change?.fieldName === 'internalAffairsCaseNumber') {
                //Fix this field name
                change.fieldName = 'Internal Affairs Case Number';
              }
              if (change?.fieldName?.length > 0) {
                return (
                  <Box key={index}>
                    {!change?.oldValue ? (
                      <>
                        <UserInfo user={user} /> has added {convertValueToHTML(change.newValue)} to{' '}
                        <span style={{ textTransform: 'capitalize' }}>{change?.fieldName}</span>{' '}
                      </>
                    ) : (
                      <>
                        <UserInfo user={user} /> has changed the{' '}
                        <span style={{ textTransform: 'capitalize' }}>{change?.fieldName}</span> from{' '}
                        {convertValueToHTML(change.oldValue)} to {convertValueToHTML(change.newValue)}
                      </>
                    )}
                  </Box>
                );
              }

              return null;
            })}
            to case:{' '}
            <Link to={`/case/${item?.data?.form?.code}`}>
              <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.form?.code}</span>
            </Link>
          </Typography>
        </TimeLineItem>
      );
    }

    if (item.type === 'IMPORT_CASE') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> imported case{' '}
            <Link to={`/case/${item?.data?.form?.code}`}>
              <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.form?.code}</span>{' '}
            </Link>
          </Typography>
        </TimeLineItem>
      );
    }
    if (item.type === 'IMPORT_CASES' && item?.data?.event === 'end') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> imported{' '}
            <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.successCount}</span> cases{' '}
          </Typography>
        </TimeLineItem>
      );
    }
    if (item.type === 'IMPORT_OFFICER') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> imported Officer{' '}
            <span
              style={{ color: '#2E66FE', fontWeight: '500' }}
            >{`${item?.data?.officer?.firstName} ${item?.data?.officer?.lastName}`}</span>{' '}
            with Badge/Serial Number{' '}
            <Link to={`/officer/${item?.data?.officer?._id}`}>
              <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.officer?.badgeNumber}</span>{' '}
            </Link>
          </Typography>
        </TimeLineItem>
      );
    }
    if (item.type === 'IMPORT_USER') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> imported User{' '}
            <Link to={`/invitation/${item?.invitation?.user?._id}`}>
              <span
                style={{ color: '#2E66FE', fontWeight: '500' }}
              >{`${item?.data?.invitation?.firstName} ${item?.data?.invitation?.lastName}`}</span>{' '}
            </Link>
            to <span style={{ color: '#2E66FE', fontWeight: '500' }}>{`${item?.data?.invitation?.type} `}</span> role.
          </Typography>
        </TimeLineItem>
      );
    }
    if (item.type === 'IMPORT_OFFICERS ' && item?.data?.event === 'end') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> imported{' '}
            <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.successCount}</span> officers{' '}
          </Typography>
        </TimeLineItem>
      );
    }
    if (item.type === 'IMPORT_USERS' && item?.data?.event === 'end') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={user} /> imported{' '}
            <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.successCount}</span> users.{' '}
          </Typography>
        </TimeLineItem>
      );
    }

    if (item.type === 'USER_EDIT_ROLE') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={item?.data?.user} /> has changed the role of{' '}
            <span className={classes.emphasis}>{item?.data?.requestedUser?.email}</span> from{' '}
            <span className={classes.emphasis}>{item?.data?.oldRole}</span> to{' '}
            <span className={classes.emphasis}>{item?.data?.newRole}</span>
          </Typography>
        </TimeLineItem>
      );
    }

    if (item.type === 'CASE_TASK_ASSIGNED_TO_YOU') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={item?.data?.user} /> has assigned <strong>you</strong> to the task{' '}
            <strong>{item.data?.task?.name}</strong> in the case{' '}
            <Link to={`/case/${item?.data?.form?.code}`}>
              <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.form?.code}</span>
            </Link>
          </Typography>
        </TimeLineItem>
      );
    }

    if (item.type === 'CASE_TASK_ASSIGNED') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={item?.data?.user} /> has assigned{' '}
            <span style={{ color: '#2E66FE', fontWeight: '500' }}>
              {item.data?.users?.map((u) => `${u.firstName} ${u.lastName}`).join(', ')}
            </span>{' '}
            to the task <strong>{item.data?.task?.name}</strong> in the case{' '}
            <Link to={`/case/${item?.data?.form?.code}`}>
              <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.form?.code}</span>
            </Link>
          </Typography>
        </TimeLineItem>
      );
    }

    if (item.type === 'CASE_TASK_UNASSIGNED_FROM_YOU') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={item?.data?.user} /> has removed <strong>you</strong> from the task{' '}
            <strong>{item.data?.task?.name}</strong> in the case{' '}
            <Link to={`/case/${item?.data?.form?.code}`}>
              <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.form?.code}</span>
            </Link>
          </Typography>
        </TimeLineItem>
      );
    }

    if (item.type === 'CASE_TASK_UNASSIGNED') {
      return (
        <TimeLineItem
          unread={item?.unread}
          profileImg={item?.data?.user?.photo?.public_url}
          createdAt={item.createdAt}
          key={item?._id}
          avatar={getUserInitials(item?.data?.user)}
        >
          <Typography className={classes.texxt}>
            <UserInfo user={item?.data?.user} /> has removed{' '}
            <span style={{ color: '#2E66FE', fontWeight: '500' }}>
              {item.data?.users?.map((u) => `${u.firstName} ${u.lastName}`).join(', ')}
            </span>{' '}
            from the task <strong>{item.data?.task?.name}</strong> in the case{' '}
            <Link to={`/case/${item?.data?.form?.code}`}>
              <span style={{ color: '#2E66FE', fontWeight: '500' }}>{item?.data?.form?.code}</span>
            </Link>
          </Typography>
        </TimeLineItem>
      );
    }
  };

  return (
    <Box width="100%" marginBottom="20px" marginTop="20px">
      <Typography className={classes.title}>{title}</Typography>

      <Box display="flex">
        <Box marginRight="33px" display="flex" marginLeft="17px" flexDirection="column" alignItems="center">
          <TimeLineDot />
          <Box height="100%" width="1px" style={{ background: '#364F7433' }} />
          <TimeLineDot />
        </Box>

        <Box className={classes.itemsContainer}>
          {items?.map((item, id) => (
            <React.Fragment key={id}>{renderText(item)}</React.Fragment>
          ))}
        </Box>
      </Box>
      <CarouselModal
        open={open}
        setOpen={setOpen}
        filesSelected={filesSelected}
        setLoadingOpenFiles={setLoadingOpenFiles}
        loadingOpenFiles={loadingOpenFiles}
      />
    </Box>
  );
};

TimeLine.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

const TimeLineDot = styled.div`
  width: 9px;
  height: 9px;
  border-radius: 50%;
  ${(props) => (props.isFilled ? `background: #364F74;` : `border: 1px solid #364F74`)}
`;

export default TimeLine;
