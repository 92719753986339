import React from 'react';
import styled from 'styled-components';

const colorsByPriority = [
  {
    low: {
      color: '#FEB535',
      backgroundColor: '#fcf7d2',
    },
  },
  {
    high: {
      color: '#FF4242',
      backgroundColor: '#FEBFBF',
    },
  },
  {
    medium: {
      color: '#fca249',
      backgroundColor: '#FFE4C9',
    },
  },
];

const getColorByPriority = (priority, selectedPriority) => {
  const color = colorsByPriority.find((clr) => clr[priority]);
  if (selectedPriority) {
    return {
      backgroundColor: color[priority].color,
    };
  }
  return color[priority];
};

export default function LabelWithColor({ label, priority = 'medium', selectedPriority }) {
  return <LabelContainer style={getColorByPriority(priority, selectedPriority)}>{label}</LabelContainer>;
}

const LabelContainer = styled.div`
  border-radius: 120px;
  padding: 5px 10px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  width: 100%;
`;
