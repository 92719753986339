import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { updateOfficerCustomFields } from "../services/unarmed";
import UpdateRenderer from "../pages/CaseDetails/UpdateCaseComponents/UpdateRenderer";
import { Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({

  button: {
    background: ' #3B70FE',
    textTransform: 'capitalize',
    marginBottom: '10px',
    color: '#fff',
    '&:hover': {
      background: '#3B70FE',
    },
    '& .MuiButton-label': {
      fontSize: 14,
    },
  },
}));

export const CustomFieldsAllegations = ({
    allegation,
    officerId,
    id,
    setLoading,
    setLoadingState,
    setIsRequestingData,
    setIsRefetch,
    setEditRow,
    loading,
    loadingState,
    row,
    setForm,
    CircularProgress
}) => {
  const classes = useStyles();
  const [customFieldsValues, setCustomFieldsValues] = useState([]);

  const onUpdateFieldsForm = async () => {
    try {
      setLoading(true);
      setLoadingState('updateForm');
      setIsRequestingData(true);
      const updatedCustomFields = customFieldsValues.map((field) => ({
        ...field,
        sections: field.sections.map((section) => ({
          ...section,
          components: section.components.map((component) =>
            (component === '' || component === undefined || component === null) ? null : component
          )
        }))
      }));

      await updateOfficerCustomFields(id, {
        allegationId: allegation._id,
        officerId,
        officersCustomFields: updatedCustomFields,
      });
      setLoadingState('');
      setIsRefetch();
      setLoading(false);
      setEditRow(null);
      setIsRequestingData(false);
      toast.success('An Allegation row has been updated Successfully');
    } catch (error) {
      setLoadingState('');
      toast.error(error?.response?.data?.error?.details[0]?.message);
      toast.error(error?.response?.data?.message);
      setLoading(false);
      console.log(error);
    }
  };

    useEffect(() => {

        console.log({allegation})

        if (allegation && allegation?.officersCustomFields?.length > 0) {
        const pages1 = allegation?.officersCustomFields?.map((page) => ({
            sections: [
            {
                components: page?.sections?.[0]?.components?.map((component) =>
                !component.value ? null : component.value
                ) || []
            }
            ]
        })) || [];

        setCustomFieldsValues(pages1);
        }
    }, [allegation]);

    return (
        <>
              <UpdateRenderer
                id={row._id}
                setForm={setForm}
                version={2}
                components={allegation?.officersCustomFields}
                customFieldsValues={customFieldsValues}
                setCustomFieldsValues={setCustomFieldsValues}
                setIsRequestingData={false}
              />
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
                <Button
                  variant="contained" 
                  className={classes.button}
                  disabled={loading && loadingState === 'updateForm'}
                  onClick={onUpdateFieldsForm}
                >
                  {loading && loadingState === 'updateForm' ? <CircularProgress color="#fff" size="25px" /> : 'SAVE'}
                </Button>
              </div>
            </>
    )
}
