import { Box, makeStyles, Tooltip, Typography } from '@material-ui/core';
import React from 'react';
import formatTxt from '../utils/formatText';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  code: {
    color: '#3B70FE',
    marginRight: 10,
    minWidth: 120,
    fontWeight: 600,
    fontSize: 13,
  },
  label: {
    textTransform: 'capitalize',
    color: 'rgb(21,21,21)',
    marginRight: 10,
    fontSize: 13,
    minWidth: 'fit-content',
    fontWeight: 500,
  },
  value: {
    color: 'rgb(131,131,131)',
    marginRight: 10,
    fontSize: 13,
  },
  container: {
    cursor: 'pointer',
    '&:hover': {
      transition: 'ease .3s all',
      background: '#F9FAFD',
    },
  },
}));

const SearchedDropdown = ({ searchResult = [], onClose, loading }) => {
  const classes = useStyles();
  const history = useHistory();

  const changeTextFromBE = (category) => {
    switch (category) {
      case 'OFFICERSINFO': {
        return 'POLICE';
      }
      case 'OTHERS': {
        return 'WITNESSES';
      }

      case 'officersInfo': {
        return 'Officer';
      }
      case 'firstName:': {
        return 'First Name';
      }
      case 'lastName:': {
        return 'Last Name';
      }
      case 'addressLine1:': {
        return 'Address Line 1';
      }
      case 'primaryLanguage:': {
        return 'Primary Language';
      }
      case 'addressLine2:': {
        return 'Address Line 2';
      }
      case 'state.name:': {
        return 'State ';
      }
      case 'city.name:': {
        return 'City ';
      }

      case 'others': {
        return 'Witness';
      }
      case 'caseNumber:': {
        return 'Case Number';
      }

      case 'CASENUMBER': {
        return 'CASE NUMBER';
      }

      case 'RECEIVED': {
        return 'Received';
      }
      case 'IN_PROGRESS': {
        return 'In Progress';
      }
      case 'CLOSED': {
        return 'In Closed';
      }
      case 'CODE': {
        return 'TRACKING NUMBER';
      }
      case 'code:': {
        return 'Tracking Number';
      }

      default: {
        return category;
      }
    }
  };

  return (
    <Box
      width="500px"
      style={{ background: '#fff' }}
      position="absolute"
      top="62px"
      left="102px"
      zIndex={2}
      boxShadow="0 3px 6px rgba(0,0,0,.1)"
    >
      {searchResult && (
        <Box
          padding="10px 20px"
          width="100%"
          height="100%"
          maxHeight="300px"
          style={{ overflowY: 'auto' }}
        >
          {Object.keys(searchResult).length > 0 ? (
            Object.keys(searchResult).map((category, index) => (
              <Box key={index}>
                <Typography
                  style={{ color: 'rgb(151,151, 151)', margin: '10px 0' }}
                >
                  {changeTextFromBE(searchResult[category].category)}
                </Typography>
                {searchResult[category].search.map((s, i) => {
                  const splitedValues = s.split(';');
                  return (
                    <Box
                      key={i}
                      display={
                        !splitedValues[1].includes('statement') && 'flex'
                      }
                      padding="7px 10px"
                      className={classes.container}
                      onClick={() => {
                        onClose();
                        history.push(`/case/${splitedValues[0]}`);
                      }}
                    >
                      <Typography className={classes.code}>
                        {splitedValues[0]}{' '}
                      </Typography>
                      <Typography className={classes.label}>
                        {splitedValues[1].split(' ').length === 1
                          ? changeTextFromBE(splitedValues[1].split(' ')[0])
                          : `  ${changeTextFromBE(
                              splitedValues[1].split(' ')[0]
                            )} 
                        ${changeTextFromBE(splitedValues[1].split(' ')[1])}`}
                      </Typography>
                      <Tooltip title={changeTextFromBE(splitedValues[2])}>
                        <Typography className={classes.value}>
                          {formatTxt(changeTextFromBE(splitedValues[2]), 23)}
                        </Typography>
                      </Tooltip>
                    </Box>
                  );
                })}
              </Box>
            ))
          ) : (
            <Typography style={{ color: 'rgb(151,151, 151)' }}>
              No results found
            </Typography>
          )}
        </Box>
      )}

      {/* <Box
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        padding="5px 10px"
        style={{ cursor: 'pointer', background: '#f8f8f9' }}
      >
        <SearchIcon htmlColor="#000000ad" />
        <Typography style={{ color: '#000000ad', fontSize: 13 }}>
          See all results for <strong>Jose</strong>
        </Typography>
      </Box> */}
    </Box>
  );
};

export default SearchedDropdown;
