import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { OutLineInput } from '../CaseDetails/UpdateCase';

const useStyles = makeStyles(() => ({
  container: {
    padding: 20,
  },
  labelError: {
    fontSize: 12,
    color: 'red',
  },
  title: {
    marginTop: 50,
  },
  label: {
    fontSize: 12,
    color: '#aaa',
  },
  button: {
    letterSpacing: '1px',
    fontSize: 13,
    background: '#2E66FE',
    fontWeight: '400',
    width: 'fit-content',
    alignSelf: 'flex-end',
    color: '#fff',
    '&:hover': {
      background: '#4b7bff',
    },
    '& .MuiButton-label': {
      fontSize: 13,
    },
  },
  buttonOutlined: {
    letterSpacing: '1px',
    fontSize: 13,
    background: '#fff',
    fontWeight: '500',
    marginRight: 10,
    width: 'fit-content',
    alignSelf: 'flex-end',
    border: '1px solid #4b7bff ',
    color: '#4b7bff',
    '&:hover': {
      background: '#fff',
      border: '1px solid #4b7bff ',
    },
    '& .MuiButton-label': {
      fontSize: 13,
    },
  },
}));

/**
 * ProfileInformation component for displaying and editing user profile information.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.me - The current user object.
 * @param {Object} props.firstName - First name object with value, onChange handler, and error.
 * @param {Object} props.lastName - Last name object with value, onChange handler, and error.
 * @param {Object} props.phoneNumber - Phone number object with value, onChange handler, and error.
 * @param {boolean} props.isEditing - Flag indicating if the profile is in edit mode.
 * @returns {React.ReactElement} The ProfileInformation component.
 */
const ProfileInformation = ({ me, firstName, lastName, phoneNumber, isEditing }) => {
  const classes = useStyles();

  return (
    <Box
      style={{ background: '#fff' }}
      boxShadow="0 4px 8px #eee"
      display="flex"
      flexDirection="column"
      width="100%"
      padding="15px 22px"
      borderRadius={4}
    >
      <Box padding="10px 15px" flexGrow={1}>
        <Box display="flex" width="100%" justifyContent="space-between">
          <Box display="flex" width="100%" maxWidth="200px" alignItems="center" justifyContent="space-between">
            <Box width="100%" maxWidth="200px">
              <Typography variant="body2" className={classes.label}>
                Email Address
              </Typography>
              <Typography variant="subtitle">{me?.email || 'N/A'}</Typography>
            </Box>
          </Box>
          <Box width="100%" maxWidth="200px">
            <Typography variant="body2" className={classes.label}>
              First Name {isEditing && <strong style={{ color: 'red' }}>*</strong>}
            </Typography>
            {isEditing ? (
              <>
                <OutLineInput
                  placeholder="First Name"
                  value={firstName.value}
                  onChange={firstName.onChange}
                  error={firstName.error}
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    'aria-label': 'weight',
                    'data-testid': 'profile-edit-input-first-name',
                  }}
                  labelWidth={0}
                />
                <span className={classes.labelError}>{firstName.error}</span>
              </>
            ) : (
              <Typography variant="subtitle">{me?.firstName || 'N/A'}</Typography>
            )}
          </Box>
          <Box width="100%" maxWidth="200px">
            <Typography variant="body2" className={classes.label}>
              Last Name {isEditing && <strong style={{ color: 'red' }}>*</strong>}
            </Typography>
            {isEditing ? (
              <>
                <OutLineInput
                  placeholder="Last Name"
                  value={lastName.value}
                  onChange={lastName.onChange}
                  error={lastName.error}
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    'aria-label': 'weight',
                    'data-testid': 'profile-edit-input-last-name',
                  }}
                  labelWidth={0}
                />
                <span className={classes.labelError}>{lastName.error}</span>
              </>
            ) : (
              <Typography variant="subtitle">{me?.lastName || 'N/A'}</Typography>
            )}
          </Box>
          <Box width="100%" maxWidth="200px">
            <Typography variant="body2" className={classes.label}>
              Phone Number {isEditing && <strong style={{ color: 'red' }}>*</strong>}
            </Typography>
            {isEditing ? (
              <>
                <OutLineInput
                  placeholder="Phone Number"
                  value={phoneNumber.value}
                  onChange={phoneNumber.onChange}
                  error={phoneNumber.error}
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    'aria-label': 'weight',
                    'data-testid': 'profile-edit-input-phone-number',
                  }}
                  labelWidth={0}
                />
                <span className={classes.labelError}>{phoneNumber.error}</span>
              </>
            ) : (
              <Typography variant="subtitle">
                {me?.phone?.replace(/(\d{3})(\d{3})(\d{4})/g, '($1) $2-$3') ||
                  me?.phoneNumber?.replace(/(\d{3})(\d{3})(\d{4})/g, '($1) $2-$3') ||
                  'N/A'}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

ProfileInformation.propTypes = {
  me: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    phone: PropTypes.string,
    phoneNumber: PropTypes.string,
    email: PropTypes.string,
  }),
  firstName: PropTypes.shape({
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    error: PropTypes.string,
  }).isRequired,
  lastName: PropTypes.shape({
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    error: PropTypes.string,
  }).isRequired,
  phoneNumber: PropTypes.shape({
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    error: PropTypes.string,
  }).isRequired,
  isEditing: PropTypes.bool.isRequired,
};

ProfileInformation.defaultProps = {
  me: {
    firstName: 'N/A',
    lastName: 'N/A',
    phone: 'N/A',
    phoneNumber: 'N/A',
    email: 'N/A',
  },
};

export default ProfileInformation;
