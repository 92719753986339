import { useHistory } from 'react-router-dom';
import React, { useState } from 'react';
import { Avatar, Box, Button, Divider, Grid, Typography, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import moment from 'moment';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import UserAttr from '../../components/UserAttr';
import ModalEditContact from './ModalEditContact';
import DashboardCard from '../../components/DashboardCard';
import { formatPhoneNumber } from '../../utils';

const useStyles = makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(18),
    height: theme.spacing(18),
  },
}));

/**
 * React component for displaying contact information and related statistics.
 *
 * @component
 * @param {Object} props - The properties passed to the ContactInfo component.
 * @param {Object} props.contact - The contact information.
 * @param {Object} props.stats - The statistics related to the contact.
 * @param {Function} props.onUpdate - The function to handle updates to the contact information.
 */
const ContactInfo = ({ openEditModal, onCloseEditModal, contact, stats, onUpdate }) => {
  const classes = useStyles();
  const history = useHistory();

  const [showAllAttrs, setShowAllAttrs] = useState(false);

  /**
   * Renders additional dashboard cards for displaying contact statistics.
   *
   * @returns {JSX.Element} The rendered dashboard cards.
   */
  const renderCards = () => (
    <Box display="flex" alignItems="center" flexWrap="wrap">
      <DashboardCard
        dataTestId="contact-details-total-cases-card"
        styleContainer={{ marginTop: 20 }}
        cardText="Total Cases"
        color="#01BEA826"
        IconComponent={FileCopyIcon}
        iconColor="#01bda5"
        value={stats?.cases || 0}
        buttonText="View all Cases"
        onClickButton={() => {
          history.push('/cases', {
            name: `${contact.firstName} ${contact.lastName}`,
            email: contact.email,
            id: contact?._id,
            showAlert: true,
            isFiltering: true,
            isFilteringByContact: true,
          });
        }}
      />
      <DashboardCard
        dataTestId="contact-details-total-projects-card"
        styleContainer={{ marginTop: 20, marginRight: 0 }}
        cardText="Total Projects"
        color="#CC2D4B26"
        IconComponent={FileCopyIcon}
        iconColor="#d22a47"
        value={stats?.projects || 0}
        buttonText="View all Projects"
        onClickButton={() => {
          history.push('/projects', {
            name: `${contact.firstName} ${contact.lastName}`,
            email: contact.email,
            id: contact?._id,
            showAlert: true,
            isFiltering: true,
            isFilteringByContact: true,
          });
        }}
      />
      {/* <DashboardCard
        styleContainer={{ marginTop: 20 }}
        cardText="Total Events"
        color="#2E66FE26"
        IconComponent={FileCopyIcon}
        iconColor="#3164fc"
        value={stats?.events || 0}
        buttonText="View all Events"
        onClickButton={() => {}}
      /> */}
    </Box>
  );

  return (
    <Box paddingX={4} paddingY={3}>
      <Box width="100%" alignItems="center" display="flex" justifyContent="space-between" height={200}>
        <Box display="flex" alignItems="center">
          <Box mr={2} width="180px">
            <Avatar src={contact?.photo?.public_url} alt={contact?.email} className={classes.avatar} />
          </Box>
          <Box display="flex" justifyContent="flex-start" flexDirection="column">
            <Typography variant="h5" style={{ fontSize: 20, fontWeight: 'bold' }}>
              {contact?.firstName || contact?.lastName
                ? `${contact.firstName || ''} ${contact.lastName || ''}`
                : 'Contact name not specified'}
            </Typography>
            <UserAttr title="Phone Number" value={formatPhoneNumber(contact.phoneNumber)} />
            <UserAttr title="E-mail" value={contact.email} />
            <UserAttr title="Type" value={contact.type || 'Not specified'} />
            {contact.type === 'Organization' && contact?.companyName && (
              <UserAttr title="Company Name" value={contact.companyName || 'Not specified'} />
            )}
          </Box>
        </Box>
        <Box>{renderCards}</Box>
      </Box>
      <Grid container alignItems="center">
        <Grid item xs={12}>
          <Box paddingY={4}>
            <Divider />
          </Box>
          <Box display="grid" gridTemplateColumns="repeat(auto-fit, minmax(100px, 1fr))" gridGap="8px">
            <div>
              <UserAttr
                title="Birthdate"
                value={
                  contact?.demographic?.birthdate
                    ? moment(contact?.demographic?.birthdate).format('MM/DD/YYYY')
                    : 'Not specified'
                }
              />
            </div>
            <div>
              <UserAttr title="Suffix" value={contact?.suffix || 'Not specified'} />
            </div>
            <div>
              <UserAttr title="Title" value={contact?.title || 'Not specified'} />
            </div>
            <div>
              <UserAttr title="Pronouns" value={contact?.pronouns || 'Not specified'} />
            </div>
            <div>
              <UserAttr title="Salutation" value={contact?.salutation || 'Not specified'} />
            </div>
          </Box>
          <Box mt={6}>
            <Box display="grid" gridTemplateColumns="repeat(auto-fit, minmax(100px, 1fr))" gridGap="8px">
              <div>
                <UserAttr
                  title="Race/Ethnicity"
                  value={
                    contact?.demographic?.raceEthnicity?.length > 0
                      ? contact?.demographic?.raceEthnicity.join(', ')
                      : 'Not specified'
                  }
                />
              </div>
              <div>
                <UserAttr
                  title="Gender"
                  value={
                    contact?.demographic?.gender?.length > 0
                      ? contact?.demographic?.gender?.join(', ')
                      : 'Not specified'
                  }
                />
              </div>
              <div>
                <UserAttr
                  title="Created by"
                  value={
                    contact?.createdBy
                      ? `${contact?.createdBy?.firstName} ${contact?.createdBy?.lastName}`
                      : 'Not specified'
                  }
                />
              </div>
              <div>
                <UserAttr title="Department" value={contact?.department || 'Not specified'} />
              </div>
              <div>
                <UserAttr title="Description" value={contact?.description || 'Not specified'} />
              </div>
            </Box>
          </Box>
          {showAllAttrs && (
            <>
              <Box paddingY={4}>
                <Divider />
              </Box>
              <Box display="grid" gridTemplateColumns="repeat(auto-fit, minmax(100px, 1fr))" gridGap="8px">
                <div>
                  <UserAttr title="Mailing Street" value={contact?.mailingLocation?.address || 'Not specified'} />
                </div>
                <div>
                  <UserAttr title="Mailing Apt/Unit#" value={contact?.mailingLocation?.aptUnit || 'Not specified'} />
                </div>
                <div>
                  <UserAttr
                    title="Mailing State/Province"
                    value={contact?.mailingLocation?.state?.name || 'Not specified'}
                  />
                </div>
                <div>
                  <UserAttr title="Mailing city" value={contact?.mailingLocation?.city?.name || 'Not specified'} />
                </div>
                <div>
                  <UserAttr
                    title="Mailing Zip / Postal Code"
                    value={contact?.mailingLocation?.zipCode || 'Not specified'}
                  />
                </div>
              </Box>
              <Box mt={6}>
                <Box display="grid" gridTemplateColumns="repeat(auto-fit, minmax(100px, 1fr))" gridGap="8px">
                  <div>
                    <UserAttr title="Other Street" value={contact?.otherLocation?.address || 'Not specified'} />
                  </div>
                  <div>
                    <UserAttr title="Other Apt/Unit#" value={contact?.otherLocation?.aptUnit || 'Not specified'} />
                  </div>
                  <div>
                    <UserAttr
                      title="Other State/Province"
                      value={contact?.otherLocation?.state?.name || 'Not specified'}
                    />
                  </div>
                  <div>
                    <UserAttr title="Other city" value={contact?.otherLocation?.city?.name || 'Not specified'} />
                  </div>
                  <div>
                    <UserAttr
                      title="Other Zip / Postal Code"
                      value={contact?.otherLocation?.zipCode || 'Not specified'}
                    />
                  </div>
                </Box>
              </Box>
            </>
          )}
          <Box display="flex" justifyContent="flex-end">
            <Button
              data-testid="contact-detail-button-see-all"
              color="primary"
              onClick={() => setShowAllAttrs(!showAllAttrs)}
            >
              {showAllAttrs ? 'See less' : 'See all'}
              {showAllAttrs ? (
                <KeyboardArrowUpIcon style={{ marginLeft: 8 }} />
              ) : (
                <KeyboardArrowDownIcon style={{ marginLeft: 8 }} />
              )}
            </Button>
          </Box>
        </Grid>
      </Grid>
      <ModalEditContact contact={contact} open={openEditModal} onClose={onCloseEditModal} onUpdate={onUpdate} />
    </Box>
  );
};

ContactInfo.propTypes = {
  contact: PropTypes.string,
  openEditModal: PropTypes.bool,
  onCloseEditModal: PropTypes.func,
  stats: PropTypes.object,
  onUpdate: PropTypes.func,
};

export default ContactInfo;
