import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, CircularProgress, Input, InputLabel, Select, MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';
import MultiSelectBox from '../../components/MultiSelectBox';
import { genderOptions, raceEthnicityOptions, pronounsOptions } from '../../utils/options';
import DatePickerMUI from '../../components/DatePickerMUi';
import { TextMaskCustom } from '../Officers/OfficersTable';
import { getCitiesOfState } from '../../services/countryStateService';
import { validateEmail } from '../../utils/validateForm';
import SelectClearable from '../../components/SelectClearable';

/**
 * Functional component representing a form for managing contact information.
 *
 * @component
 * @param {Object} props - The properties passed to the ContactForm component.
 * @param {string} props.firstName - First name of the contact.
 * @param {string} props.lastName - Last name of the contact.
 * @param {string} props.phoneNumber - Phone number of the contact.
 * @param {string} props.email - Email address of the contact.
 * @param {string} props.gender - Gender of the contact.
 * @param {string} props.raceEthnicity - Race or ethnicity of the contact.
 * @param {string} props.pronouns - Pronouns of the contact.
 * @param {string} props.salutation - Salutation for the contact.
 * @param {string} props.birthdate - Birthdate of the contact.
 * @param {string} props.title - Title of the contact.
 * @param {string} props.suffix - Suffix for the contact.
 * @param {string} props.description - Description for the contact.
 * @param {string} props.department - Department for the contact.
 * @param {string} props.mailingStreet - Mailing street address of the contact.
 * @param {string} props.mailingApt - Mailing apartment or unit number of the contact.
 * @param {string} props.mailingState - Mailing state of the contact.
 * @param {string} props.mailingCity - Mailing city of the contact.
 * @param {string} props.mailingZipCode - Mailing ZIP code of the contact.
 * @param {string} props.otherStreet - Other street address of the contact.
 * @param {string} props.otherApt - Other apartment or unit number of the contact.
 * @param {string} props.otherState - Other state of the contact.
 * @param {string} props.otherCity - Other city of the contact.
 * @param {string} props.otherZipCode - Other ZIP code of the contact.
 * @param {Array} props.states - List of available states for selection.
 * @param {boolean} props.loading - Indicates whether the form is in a loading state.
 * @param {function} props.onClickClose - Event handler for the close button click.
 * @param {function} props.onClickSave - Event handler for the save button click.
 * @param {boolean} props.isEdit - Indicates whether the form is in edit mode.
 *
 * @returns {JSX.Element} The JSX representation of the ContactForm component.
 */
const ContactForm = ({
  firstName,
  lastName,
  type,
  companyName,
  phoneNumber,
  email,
  gender,
  raceEthnicity,
  pronouns,
  salutation,
  birthdate,
  title,
  suffix,
  description,
  department,
  mailingStreet,
  mailingApt,
  mailingState,
  mailingCity,
  mailingZipCode,
  otherStreet,
  otherApt,
  otherState,
  otherCity,
  otherZipCode,
  states,
  loading,
  onClickClose,
  onClickSave,
  isEdit,
}) => {
  const [cities, setCities] = useState([]);
  const [otherCities, setOtherCities] = useState([]);
  const [validEmail, setValidEmail] = useState(true);

  const onValidateEmail = () => {
    if (email && email.value !== '') {
      setValidEmail(validateEmail(email.value));
    }
  };

  useEffect(() => {
    if (mailingState && mailingState.value) {
      const isoCodeState = states.find((st) => st.name === mailingState.value);

      getCitiesOfState(isoCodeState?.isoCode).then((res) => setCities(res));
    }
  }, [mailingState, states]);

  useEffect(() => {
    if (otherState && otherState.value) {
      const isoCodeState = states.find((st) => st.name === otherState.value);

      getCitiesOfState(isoCodeState?.isoCode).then((res) => setOtherCities(res));
    }
  }, [otherState, states]);

  useEffect(() => {
    onValidateEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email?.value]);

  const typeValue = type.value;

  return (
    <>
      {email && (
        <Box width="100%" mb={2}>
          <TextField
            label="Email"
            placeholder="Enter the email address"
            value={email.value}
            onChange={email.onChange}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              'data-testid': 'new-contact-input-email',
            }}
            error={!validEmail}
            helperText={!validEmail ? 'The email is not valid' : undefined}
            fullWidth
            required
          />
        </Box>
      )}
      <Box width="100%" mb={2}>
        <TextField
          inputProps={{
            'data-testid': 'new-contact-input-first-name',
          }}
          label="First name"
          placeholder="Write the first name"
          value={firstName.value}
          onChange={firstName.onChange}
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
        />
      </Box>
      <Box width="100%" mb={2}>
        <TextField
          inputProps={{
            'data-testid': 'new-contact-input-last-name',
          }}
          label="Last name"
          placeholder="Write the last name"
          value={lastName.value}
          onChange={lastName.onChange}
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
        />
      </Box>
      <Box width="100%" mb={2}>
        <InputLabel shrink>Type</InputLabel>
        <Select
          inputProps={{
            'data-testid': 'new-contact-input-type',
          }}
          displayEmpty
          value={type.value}
          onChange={type.onChange}
          options={['Individual', 'Organization']}
          fullWidth
        >
          <MenuItem disabled value="">
            Select the type
          </MenuItem>
          {['Individual', 'Organization'].map((option, optionIndex) => (
            <MenuItem key={optionIndex} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </Box>
      {typeValue === 'Organization' && (
        <Box width="100%" mb={2}>
          <TextField
            inputProps={{
              'data-testid': 'new-contact-input-company-name',
            }}
            label="Company Name"
            placeholder="Enter the company name"
            value={companyName.value}
            onChange={companyName.onChange}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            required
          />
        </Box>
      )}
      <Box width="100%" mb={2}>
        <InputLabel shrink>Phone number</InputLabel>
        <Input
          inputProps={{
            'data-testid': 'new-contact-input-phone-number',
          }}
          value={phoneNumber.value}
          onChange={phoneNumber.onChange}
          autoComplete="off"
          name="textmask"
          style={{ width: '100%' }}
          error={phoneNumber.error}
          id="formatted-text-mask-input"
          inputComponent={TextMaskCustom}
          maxLength={14}
        />
      </Box>
      {description && (
        <Box width="100%" mb={2}>
          <TextField
            inputProps={{
              'data-testid': 'new-contact-input-description',
            }}
            label="Description"
            placeholder="Enter the description"
            value={description.value}
            onChange={description.onChange}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
        </Box>
      )}
      {department && (
        <Box width="100%" mb={2}>
          <TextField
            inputProps={{
              'data-testid': 'new-contact-input-department',
            }}
            label="Department"
            placeholder="Enter the department"
            value={department.value}
            onChange={department.onChange}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
        </Box>
      )}
      {raceEthnicity && (
        <Box width="100%" mb={2}>
          <InputLabel shrink>Race/Ethnicity</InputLabel>
          <MultiSelectBox
            dataTestId="new-contact-input-race-ethnicity"
            value={raceEthnicity.value}
            onChange={raceEthnicity.onChange}
            id="discliplineMutliSelct1"
            options={raceEthnicityOptions || []}
          />
        </Box>
      )}
      {gender && (
        <Box width="100%" mb={2}>
          <InputLabel shrink>Gender</InputLabel>
          <MultiSelectBox
            dataTestId="new-contact-input-gender"
            value={gender.value}
            onChange={gender.onChange}
            id="discliplineMutliSelct1"
            options={genderOptions || []}
          />
        </Box>
      )}
      {pronouns && (
        <Box width="100%" mb={2}>
          <InputLabel shrink>Pronouns</InputLabel>
          <Select
            inputProps={{
              'data-testid': 'new-contact-input-pronouns',
            }}
            displayEmpty
            value={pronouns.value}
            onChange={pronouns.onChange}
            options={pronounsOptions || []}
            fullWidth
          >
            <MenuItem disabled value="">
              Select the pronouns
            </MenuItem>
            {pronounsOptions.map((option, optionIndex) => (
              <MenuItem key={optionIndex} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </Box>
      )}
      {salutation && (
        <Box width="100%" mb={2}>
          <TextField
            inputProps={{
              'data-testid': 'new-contact-input-salutation',
            }}
            label="Salutation"
            placeholder="Write the salutation"
            value={salutation.value}
            onChange={salutation.onChange}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
        </Box>
      )}
      {birthdate && (
        <Box width="100%" mb={2}>
          <InputLabel shrink>Birthdate</InputLabel>
          <DatePickerMUI
            dataTestId="new-contact-input-birthdate"
            propWidth="100%"
            inputVariant="filled"
            label="Write the birthdate"
            propPaddingInput="10px 0px"
            propFontSize={16}
            date={birthdate.value}
            setdate={(date) => birthdate.setValue(date)}
          />
        </Box>
      )}
      {title && (
        <Box width="100%" mb={2}>
          <TextField
            inputProps={{
              'data-testid': 'new-contact-input-title',
            }}
            label="Title"
            placeholder="Write the title"
            value={title.value}
            onChange={title.onChange}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
        </Box>
      )}
      {suffix && (
        <Box width="100%" mb={2}>
          <TextField
            inputProps={{
              'data-testid': 'new-contact-input-suffix',
            }}
            label="Suffix"
            placeholder="Write the suffix"
            value={suffix.value}
            onChange={suffix.onChange}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
        </Box>
      )}
      {mailingStreet && (
        <Box width="100%" mb={2}>
          <TextField
            inputProps={{
              'data-testid': 'new-contact-input-mailing-street',
            }}
            label="Mailing Street"
            placeholder="Write the mailing Street name"
            value={mailingStreet.value}
            onChange={mailingStreet.onChange}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
        </Box>
      )}
      {mailingApt && (
        <Box width="100%" mb={2}>
          <TextField
            inputProps={{
              'data-testid': 'new-contact-input-mailing-apt-unit',
            }}
            label="Mailing Apt/Unit #"
            placeholder="Write the mailing Apt/Unit #"
            value={mailingApt.value}
            onChange={mailingApt.onChange}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
        </Box>
      )}
      {mailingState && (
        <Box width="100%" mb={2}>
          <InputLabel shrink>Mailing State/Province</InputLabel>
          <SelectClearable
            dataTestId="new-contact-input-mailing-state"
            value={mailingState.value}
            onChange={mailingState.onChange}
            setValue={mailingState.setValue}
            options={states}
          />
        </Box>
      )}
      {mailingCity && (
        <Box width="100%" mb={2}>
          <InputLabel shrink>Mailing City</InputLabel>
          <SelectClearable
            dataTestId="new-contact-input-mailing-city"
            value={mailingCity.value}
            onChange={mailingCity.onChange}
            setValue={mailingCity.setValue}
            options={cities}
          />
        </Box>
      )}
      {mailingZipCode && (
        <Box width="100%" mb={2}>
          <TextField
            inputProps={{
              'data-testid': 'new-contact-input-mailing-zip-code',
            }}
            label="Mailing Zip / Postal Code"
            placeholder="Write the mailing Zip / Postal Code"
            value={mailingZipCode.value}
            onChange={(e) => {
              if (e.target.value?.length <= 5) {
                mailingZipCode.onChange(e);
              }
            }}
            InputLabelProps={{
              shrink: true,
            }}
            min={5}
            step={1}
            type="number"
            fullWidth
          />
        </Box>
      )}

      {otherStreet && (
        <Box width="100%" mb={2}>
          <TextField
            inputProps={{
              'data-testid': 'new-contact-input-other-street',
            }}
            label="Other Street"
            placeholder="Write the other Street name"
            value={otherStreet.value}
            onChange={otherStreet.onChange}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
        </Box>
      )}
      {otherApt && (
        <Box width="100%" mb={2}>
          <TextField
            inputProps={{
              'data-testid': 'new-contact-input-other-apt-unit',
            }}
            label="Other Apt/Unit #"
            placeholder="Write the other Apt/Unit #"
            value={otherApt.value}
            onChange={otherApt.onChange}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
        </Box>
      )}
      {otherState && (
        <Box width="100%" mb={2}>
          <InputLabel shrink>Other State/Province</InputLabel>
          <SelectClearable
            dataTestId="new-contact-input-other-state"
            value={otherState.value}
            onChange={otherState.onChange}
            setValue={otherState.setValue}
            options={states}
          />
        </Box>
      )}
      {otherCity && (
        <Box width="100%" mb={2}>
          <InputLabel shrink>Other City</InputLabel>
          <SelectClearable
            dataTestId="new-contact-input-other-city"
            value={otherCity.value}
            onChange={otherCity.onChange}
            setValue={otherCity.setValue}
            options={otherCities}
          />
        </Box>
      )}
      {otherZipCode && (
        <Box width="100%" mb={2}>
          <TextField
            inputProps={{
              'data-testid': 'new-contact-input-other-zip-code',
            }}
            label="Other Zip / Postal Code"
            placeholder="Write the other Zip / Postal Code"
            value={otherZipCode.value}
            onChange={(e) => {
              if (e.target.value?.length <= 5) {
                otherZipCode.onChange(e);
              }
            }}
            min={5}
            step={1}
            InputLabelProps={{
              shrink: true,
            }}
            type="number"
            fullWidth
          />
        </Box>
      )}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <Button
          style={{
            width: 70,
            marginRight: 8,
            textTransform: 'capitalize',
            color: '#4b7bff',
            border: '1px solid #4b7bff',
          }}
          onClick={onClickClose}
          variant="outlined"
          data-testid="new-contact-button-cancel"
        >
          Cancel
        </Button>
        {loading ? (
          <CircularProgress />
        ) : (
          <Button
            variant="contained"
            style={{
              width: isEdit ? 60 : 150,
              color: '#FFFFFF',
              textTransform: 'capitalize',
              background: '#2E66FE',
            }}
            onClick={onClickSave}
            disabled={!validEmail}
            data-testid="new-contact-button-save"
          >
            {isEdit ? 'Save' : 'Create contact'}
          </Button>
        )}
      </Box>
    </>
  );
};

ContactForm.propTypes = {
  firstName: PropTypes.object,
  lastName: PropTypes.object,
  type: PropTypes.object,
  companyName: PropTypes.object,
  phoneNumber: PropTypes.object,
  email: PropTypes.object,
  gender: PropTypes.object,
  raceEthnicity: PropTypes.object,
  pronouns: PropTypes.object,
  salutation: PropTypes.object,
  birthdate: PropTypes.object,
  title: PropTypes.object,
  suffix: PropTypes.object,
  description: PropTypes.object,
  department: PropTypes.object,
  mailingStreet: PropTypes.object,
  mailingApt: PropTypes.object,
  mailingState: PropTypes.object,
  mailingCity: PropTypes.object,
  mailingZipCode: PropTypes.object,
  otherStreet: PropTypes.object,
  otherApt: PropTypes.object,
  otherState: PropTypes.object,
  otherCity: PropTypes.object,
  otherZipCode: PropTypes.object,
  states: PropTypes.array,
  loading: PropTypes.bool,
  onClickClose: PropTypes.func,
  onClickSave: PropTypes.func,
  isEdit: PropTypes.bool,
};

export default ContactForm;
