import React, { useState } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import TodayIcon from '@material-ui/icons/Today';
import CancelIcon from '@material-ui/icons/Cancel';

import PopperPortal from './PopperPortal';
import schemaIcon from '../assets/schema.png';
import ReportSearchInput, { onFilterCubes } from './ReportSearchInput';

const useStyles = makeStyles({
  item: {
    height: 45,
    background: '#fff',
    borderRadius: 4,
    border: '1px solid #7070701F',
    padding: '15px 16px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  label: {
    fontSize: 14,
    color: '#00000061',
  },

  seletedItem: {
    color: '#000000DE',
    fontSize: 14,
    fontWeight: '400',
  },
  img: {
    width: 16,
    height: 16,
    marginRight: 5,
  },
  hoverItem: {
    cursor: 'pointer',
    borderRadius: 4,
    '& :hover': {
      transition: 'ease .3s all',
      background: '#f2f2f2',
    },
  },
});

export default function ReportItem({ label, cubes = [], setItems, value, showTodayIcon }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');

  const handleClick = (item) => {
    setItems((prevState) => {
      const stateCopy = { ...prevState };
      stateCopy.value = item;
      return stateCopy;
    });

    setOpen(false);
  };

  const handleRemove = () => {
    setItems((prevState) => {
      const stateCopy = { ...prevState };
      stateCopy.value = null;
      return stateCopy;
    });
  };

  const cubesFiltered = onFilterCubes(search, cubes);
  const checkedEmptyCubes = cubesFiltered.filter((cube) => cube.dimensions.length > 0);

  return (
    <Box
      display="flex"
      alignItems="center"
      marginBottom="10px"
      position="relative"
    >
      <Box className={classes.item} onClick={() => setOpen(true)}>
        <Box display="flex" alignItems="center" width="90%">
          {open ? (
            <ReportSearchInput search={search} setSearch={setSearch} />
          ) : (
            <>
              {value ? (
                <>
                  <Typography className={classes.seletedItem}>{`${value?.shortTitle}`}</Typography>
                </>
              ) : (
                <Typography className={classes.label}>{`${label}`}</Typography>
              )}
            </>
          )}
        </Box>
      </Box>
      {value && (
        <CancelIcon
          fontSize="small"
          htmlColor="#6D7385"
          style={{
            position: 'absolute',
            right: 15,
            zIndex: 8,
            cursor: 'pointer',
          }}
          onClick={handleRemove}
        />
      )}
      <PopperPortal open={open} setOpen={setOpen} left={0}>
        <Box
          width={333}
          height={checkedEmptyCubes.length > 0 ? 300 : 200}
          style={{ background: '#fff', zIndex: 99, overflowY: 'auto' }}
        >
          {checkedEmptyCubes.length > 0 ? (
            <>
              {cubesFiltered.map((dimension, index) => {
                const finalDimensions = dimension.dimensions
                  .filter((dim) => dim.type === 'time')
                  .map((dimm, idx) => (
                    <Box className={classes.hoverItem} key={idx} onClick={() => handleClick(dimm)}>
                      <Box display="flex" alignItems="center" padding="19px 20px">
                      {dimm.type === 'time' && showTodayIcon && <TodayIcon fontSize="small" style={{ marginRight: 10 }} />}
                        <Typography style={{ fontSize: 14 }}>{dimm?.title}</Typography>
                      </Box>
                    </Box>
                  ));
                return finalDimensions.length ? (
                  <Box padding="7px 12px" key={index}>
                    <Box display="flex" alignItems="center">
                      <img className={classes.img} src={schemaIcon} />
                      <Typography style={{ fontSize: 14 }}>{dimension?.title.replace('-', '')}</Typography>
                    </Box>
                    {finalDimensions}
                  </Box>
                ) : null;
              })}
            </>
          ) : (
            <Box display="flex" justifyContent="center" alignItems="center" marginTop={1} width="100%" height="90%">
              <Typography>No results</Typography>
            </Box>
          )}
        </Box>
      </PopperPortal>
    </Box>
  );
}
