import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import AccordionWrap from '../../components/AccordionWrap';

export const getAboutUsValue = (value) => {
  if (!value) {
    return;
  }
  const aboutUsMapArray = [
    { value: 'WEBSITE', text: 'Website' },
    { value: 'SEARCH_ENGINE', text: 'Search Engine (e.g. Google)' },
    { value: 'SOCIAL_MEDIA', text: 'Social Media (e.g. Facebook)' },
    { value: 'EMAIL', text: 'Email' },
    { value: 'RADIO', text: 'Radio' },
    { value: 'TV', text: 'TV' },
    { value: 'NEWSPAPER', text: 'Newspaper' },
    { value: 'WORD_OF_MOUTH', text: 'Word of Mouth' },
    { value: 'OTHER', text: 'Other' },
  ];

  const arrayValue = aboutUsMapArray.filter((about) => about.value === value);
  return arrayValue[0]?.text;
};

const useStyles = makeStyles((theme) => ({
  formSection: {
    fontSize: 14,
    color: '#3B70FE',
    fontWeight: '500',
    margin: '20px 0',
  },
  label: {
    fontSize: 12,
    color: '#585858',
  },
}));

const AditionalDetails = ({ form }) => {
  const classes = useStyles();

  return (
    <Box
      style={{
        background: '#fff',
        borderRadius: 5,
        marginTop: 20,
      }}
      boxShadow="0 3px 6px #00000005"
    >
      <AccordionWrap
        id="additional-information"
        header={
          <Box style={{ marginBottom: 15 }}>
            <Typography
              variant="h5"
              style={{
                fontWeight: '500',
                fontSize: 16,
                color: '#575A66',
              }}
            >
              Additional Information
            </Typography>
          </Box>
        }
      >
        <Box style={{ marginLeft: 10 }}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="body2" className={classes.label}>
                How do you hear about us?
              </Typography>
              <Typography variant="subtitle">
                {form?.howDidYouHearAboutUs?.map((how, i) => (
                  <span key={i}>
                    {getAboutUsValue(how)}
                    {form?.howDidYouHearAboutUs?.length > 1 && form?.howDidYouHearAboutUs?.length !== i + 1 && ','}{' '}
                  </span>
                )) || 'Not specified'}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body2" className={classes.label}>
                What best describes you?
              </Typography>
              <Typography variant="subtitle">{form?.whatBestDescribesYou || 'Not specified'}</Typography>
            </Grid>
          </Grid>
          <Typography className={classes.formSection}>Demographics</Typography>
          <Box display="flex" flexWrap="wrap">
            <Box width="100%" maxWidth="100px" marginRight="20px" marginBottom="10px">
              <Typography variant="body2" className={classes.label}>
                Date of Birth
              </Typography>
              <Typography variant="subtitle">
                {form?.demographic?.birthdate
                  ? moment(form?.demographic?.birthdate).format('MM/DD/YYYY')
                  : 'Not specified'}
              </Typography>
            </Box>
            <Box width="100%" maxWidth="140px" marginRight="20px" marginBottom="10px">
              <Typography variant="body2" className={classes.label}>
                Gender
              </Typography>
              <Typography variant="subtitle">
                {form?.demographic?.gender.map((gd, i) => (
                  <span key={i}>
                    {gd}
                    {form?.demographic?.gender?.length > 1 && form?.demographic?.gender?.length !== i + 1 && ','}{' '}
                  </span>
                ))}
                {form?.demographic?.gender.length === 0 && <span>Not specified</span>}
              </Typography>
            </Box>
            <Box width="100%" maxWidth="140px" marginRight="20px" marginBottom="10px">
              <Typography variant="body2" className={classes.label}>
                Race/Ethnicity
              </Typography>
              {form?.demographic?.raceEthnicity.map((rc, i) => (
                <span key={i}>
                  {rc}
                  {form?.demographic?.raceEthnicity?.length > 1 &&
                    form?.demographic?.raceEthnicity?.length !== i + 1 &&
                    ','}{' '}
                </span>
              ))}
              {form?.demographic?.raceEthnicity.length === 0 && <span>Not specified</span>}
            </Box>
            <Box width="100%" maxWidth="140px" marginRight="20px" marginBottom="10px">
              <Typography variant="body2" className={classes.label}>
                Sexual Orientatioin
              </Typography>
              {form?.demographic?.sexualOrientation.map((so, i) => (
                <span key={i}>
                  {so}
                  {form?.demographic?.sexualOrientation?.length > 1 &&
                    form?.demographic?.sexualOrientation?.length !== i + 1 &&
                    ','}{' '}
                </span>
              ))}
              {form?.demographic?.sexualOrientation.length === 0 && <span>Not specified</span>}
            </Box>
            <Box width="100%" maxWidth="140px" marginRight="20px" marginBottom="10px">
              <Typography variant="body2" className={classes.label}>
                Is English your primary language?
              </Typography>
              <Typography variant="subtitle" style={{ textTransform: 'capitalize' }}>
                {form?.demographic?.primaryLanguage || 'Not specified'}
              </Typography>
            </Box>
          </Box>
        </Box>
      </AccordionWrap>
    </Box>
  );
};

AditionalDetails.propTypes = {
  form: PropTypes.object,
};

export default AditionalDetails;
