import { Box, Button, Container, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { getFormColor } from '../../utils';
import useOrganization from '../../hooks/useOrganization';

import Logo from '../../components/Logo';
import { logEvent } from '../../services/firebase';

const useStyles = makeStyles(() => ({
  topBar: {
    width: '100%',
    height: 100,
    background: '#364F74',
    display: 'flex',
    alignItems: 'center',
  },
  formSection: {
    fontSize: 14,
    color: '#000000DE',
    fontWeight: 'bold',
    marginTop: 20,
  },
  stepper: {
    background: 'transparent',
    width: '100%',
    maxWidth: 660,
  },
  unarmedlogo: {
    width: 150,
    height: 50,
    objectFit: 'contain',
    cursor: 'pointer',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  stepLabel: {
    color: '#fff',
  },
}));

const SuccessSumitted = () => {
  const classes = useStyles();
  const { id } = useParams();
  const location = useLocation();
  const history = useHistory();
  const { organization } = useOrganization();

  useEffect(() => {
    if (id && location.state?.type) {
      logEvent('new_case_sent', {
        trackingCode: id,
        type: location.state?.type,
      });
    }
  }, [id, location]);

  const reportType = location?.state?.type;
  const types = {
    COMPLIMENT: "Compliment",
    COMPLAINT: "Complaint",
    REVIEW: "Review",
    RECOMMENDATION: "Recommendation",
    ...organization?.customFormTypes,
  };
  const reportTypeDisplayText = types[reportType] || reportType;

  return (
    <>
      <div className={classes.topBar}>
        <Container className={classes.container}>
          <Logo logo="white" />
        </Container>
      </div>
      <ContainerDiv>
        <Typography variant="h3" style={{ fontWeight: 'bold' }}>
          Thank you!
        </Typography>

        <Box
          height={42}
          margin="20px 0"
          border="1px solid #0026CA19"
          display="flex"
          bgcolor="#FAFBFF"
          alignItems="center"
          width="240px"
          borderRadius="5px"
          padding="10px"
          justifyContent="space-between"
        >
          <Typography>{id}</Typography>
          <CopyToClipboard text={id} onCopy={() => toast.success('Tracking Number copied!')}>
            <FileCopyOutlinedIcon fontSize="small" htmlColor="#4B7BFF" style={{ cursor: 'pointer' }} />
          </CopyToClipboard>
        </Box>

        <Box display="flex" style={{ marginBottom: 20 }}>
          <CheckCircleOutlineIcon htmlColor={getFormColor(location.state?.type)} fontSize="small" />
          <Typography
            variant="body2"
            style={{
              color: getFormColor(location.state?.type),
              marginLeft: 10,
            }}
          >
            {reportTypeDisplayText} form submitted successfully
          </Typography>
        </Box>
        <Button color="primary" variant="contained" onClick={() => history.push(`/case/${id}`)}>
          SEE CASE DETAILS
        </Button>
      </ContainerDiv>
    </>
  );
};

const ContainerDiv = styled.div`
  @media screen and (min-width: 320px) {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 500px;
    text-align: center;
  }
  @media screen and (min-width: 768px) {
    padding-top: 120px;
    padding-left: 140px;
    text-align: unset;
    display: block;
  }
`;
export default SuccessSumitted;
