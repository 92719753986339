import React, { useEffect, useState } from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';
import { DropzoneArea } from 'material-ui-dropzone';
import BackupIcon from '@material-ui/icons/Backup';
import { Box, CircularProgress, makeStyles, Typography } from '@material-ui/core';

import Module from '../Module';
import { getAvailableFormType, getImportOptions } from '../../../services/unarmed';
import { exportCSVFile } from '../../../utils/convertJsonCsv';
import SelectEngine from '../../../components/Select';

const useStyles = makeStyles(() => ({
  alertContainer: {
    height: 'fit-content',
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    background: '#FFFBF3',
    borderLeft: '6px solid #FFC64C',
    borderRadius: 2,
    margin: '32px 0',
    padding: '10px',
  },
  csvStatus: {
    width: '100%',
    height: 52,
    border: '1px solid #7070701A',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: 20,
    background: '#fff',
    padding: '0 24px',
  },
}));

const Step1 = ({ orgId, csv, handleFileUpload, setSelectOptions, selectedModule, setSelectedModule, selectedForm }) => {
  const classes = useStyles();
  const [formsAvailable, setFormsAvailable] = useState([]);

  useEffect(() => {
    const onGetAvailableForms = async () => {
      try {
        const { data } = await getAvailableFormType(orgId);
        const options = data.map((dt) => ({ text: dt?.name, value: dt?._id }));
        setFormsAvailable(options);
      } catch (error) {
        console.log(error);
      }
    };
    if (orgId) {
      onGetAvailableForms();
    }
  }, [orgId]);

  const generateFileName = (module, formTypeId) => {
    if (module === 'Cases') {
      // Search for selected formTypeId
      const selectedFormType = formsAvailable.find((form) => form.value === formTypeId);
      // Generate file name using form name
      return `${module}-${selectedFormType.text}-example`.toLowerCase();
    }

    return `${module}-example`.toLowerCase();
  };

  const onGetImportOption = (module, formTypeId) => {
    getImportOptions(module, formTypeId)
      .then((res) => {
        const headers = {};
        const bodyObj = {};
        const selectedOptions = [];
        for (const item of res) {
          headers[item.target] = item.label;
          bodyObj[item.target] = item.example;

          headers['typeDisplayText'] = 'typeDisplayText';
          bodyObj['typeDisplayText'] = 'howYouWantTheTypeTextToBeDisplayed';

          selectedOptions.push({
            value: item.target,
            text: item.label,
            beKey: item.target,
            disabled: false,
            type: item.type,
            required: item.required || false,
          });
        }

        // Generate the file name
        const filename = generateFileName(module, formTypeId);
        setSelectOptions(selectedOptions);
        exportCSVFile(headers, [bodyObj], filename, false, false);
      })
      .catch((err) => console.log(err));
  };

  // FIX: Fixed redundant code
  const casesModuleClick = (module) => {
    setSelectedModule(module);
  };
  const officerModuleClick = (module) => {
    onGetImportOption(module);
    setSelectedModule(module);
  };
  const usersModuleClick = (module) => {
    onGetImportOption(module);
    setSelectedModule(module);
  };

  const modules = [
    { module: 'Cases', onClick: casesModuleClick },
    { module: 'Officers', onClick: officerModuleClick },
    { module: 'Users', onClick: usersModuleClick },
  ];

  return (
    <>
      <Typography style={{ marginTop: 10, fontSize: 18, color: '#000000AD' }}>
        Choose the module where you want to import your csv file
      </Typography>
      <Box width="100%" maxWidth="940px" display="flex" flexWrap="wrap" marginTop="20px">
        {modules.map((module, i) => (
          <Module
            module={module.module}
            key={i}
            isSelected={module.module === selectedModule}
            onClick={() => module.onClick(module.module)}
          />
        ))}
      </Box>

      {selectedModule && (
        <Box width="100%" maxWidth="940px" display="flex" flexWrap="wrap" marginTop="20px">
          {selectedModule === 'Cases' && (
            <>
              <Box marginBottom="20px">
                <SelectEngine
                  options={formsAvailable}
                  value={selectedForm.value}
                  onChange={(e) => {
                    selectedForm.onChange(e);
                    onGetImportOption('Cases', e.target.value);
                  }}
                />
              </Box>
              {selectedForm.value && (
                <>
                  <Box className={classes.alertContainer} style={{ marginTop: 10 }}>
                    <Typography style={{ fontSize: 13 }}>
                      Download the {selectedModule} CSV template to organize information in the respective columns
                      before importing the file.
                    </Typography>
                    <Box display="flex" alignItems="center" style={{ cursor: 'pointer' }}>
                      <GetAppIcon htmlColor="#FEB535" />
                      <a id="downloadLink">
                        <Typography
                          style={{
                            fontSize: 13,
                            color: '#FEB535',
                            marginLeft: 5,
                          }}
                        >
                          Download
                        </Typography>
                      </a>
                    </Box>
                  </Box>
                  <Typography
                    style={{
                      fontSize: 14,
                      color: '#000000DE',
                      fontWeight: '500',
                      marginBottom: 10,
                      width: '100%',
                    }}
                  >
                    Upload your CSV File
                  </Typography>
                  <Typography
                    style={{
                      fontSize: 14,
                      color: '#a1a1aa',
                      marginBottom: 10,
                      fontWeight: 500,
                    }}
                  >
                    You can upload any .csv file.{' '}
                    <span style={{ color: '#747474' }}>
                      Remember the first row must contain the names of the fields
                    </span>
                  </Typography>
                  {csv ? (
                    <Box className={classes.csvStatus}>
                      <Typography style={{ fontSize: 14, color: '#000000DE' }}>{csv.name}</Typography>
                      <Box display="flex">
                        <CircularProgress size="20px" style={{ color: '#2E66FE !important' }} />
                        <Typography
                          style={{
                            fontSize: 14,
                            color: '#000000DE',
                            marginLeft: 10,
                          }}
                        >
                          processing your file…
                        </Typography>
                      </Box>
                    </Box>
                  ) : (
                    <DropzoneArea
                      maxFileSize={5000000}
                      dropzoneClass="import-dropzone"
                      onChange={(fl) => {
                        if (fl.length > 0) {
                          handleFileUpload(fl[fl.length - 1]);
                        }
                      }}
                      clearOnUnmount
                      filesLimit={1}
                      dropzoneText="Drag and drop your CSV file here or browser"
                      showPreviewsInDropzone={false}
                      Icon={() => <BackupIcon style={{ fontSize: 40 }} htmlColor="#A2BAFF" />}
                    />
                  )}
                  <Box marginBottom="75px" width="100%" />
                </>
              )}
            </>
          )}
          {selectedModule !== 'Cases' && (
            <>
              <Box className={classes.alertContainer}>
                <Typography style={{ fontSize: 13 }}>
                  Download the {selectedModule} CSV template to organize information in the respective columns before
                  importing the file.
                </Typography>
                <Box display="flex" alignItems="center" style={{ cursor: 'pointer' }}>
                  <GetAppIcon htmlColor="#FEB535" />
                  <a id="downloadLink">
                    <Typography style={{ fontSize: 13, color: '#FEB535', marginLeft: 5 }}>Download</Typography>
                  </a>
                </Box>
              </Box>
              <Typography
                style={{
                  fontSize: 14,
                  color: '#000000DE',
                  fontWeight: '500',
                  marginBottom: 10,
                  width: '100%',
                }}
              >
                Upload your CSV File
              </Typography>
              <Typography
                style={{
                  fontSize: 14,
                  color: '#a1a1aa',
                  marginBottom: 10,
                  fontWeight: 500,
                }}
              >
                You can upload any .csv file.{' '}
                <span style={{ color: '#747474' }}>Remember the first row must contain the names of the fields</span>
              </Typography>
              {csv ? (
                <Box className={classes.csvStatus}>
                  <Typography style={{ fontSize: 14, color: '#000000DE' }}>{csv.name}</Typography>
                  <Box display="flex">
                    <CircularProgress size="20px" style={{ color: '#2E66FE !important' }} />
                    <Typography
                      style={{
                        fontSize: 14,
                        color: '#000000DE',
                        marginLeft: 10,
                      }}
                    >
                      processing your file…
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <DropzoneArea
                  maxFileSize={5000000}
                  dropzoneClass="import-dropzone"
                  onChange={(fl) => {
                    if (fl.length > 0) {
                      handleFileUpload(fl[fl.length - 1]);
                    }
                  }}
                  clearOnUnmount
                  filesLimit={1}
                  dropzoneText="Drag and drop your CSV file here or browser"
                  showPreviewsInDropzone={false}
                  Icon={() => <BackupIcon style={{ fontSize: 40 }} htmlColor="#A2BAFF" />}
                />
              )}
              <Box marginBottom="75px" width="100%" />
            </>
          )}
        </Box>
      )}
    </>
  );
};

export default Step1;
