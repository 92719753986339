import { Box, makeStyles, Typography } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { downloadImage } from '../../../utils/libs';
import LogIcon from '../../../components/LogIcon';
import TimeLineItem from '../../Activity/TimeLineItem';
import HTMLContent from '../../../components/HTMLContent';
import Tags from '../../../components/Tags';
import CarouselModal from '../../../components/CarouselModal';
import ArchivedImage from '../../../components/ArchivedImage';
import DeleteIcon from '@material-ui/icons/Delete';
import { CustomModalDelete } from '../../../components/CustomModalDelete';
import TimeLineItemNotes from '../../Activity/TimeLineItemNotes';
import useOrganization from '../../../hooks/useOrganization';
import { Context } from '../../../Context';
const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  imgModal: {
    objectFit: 'contain',
    maxWidth: 600,
    maxHeight: 400,
  },
  link: {
    display: 'flex',
    position: 'absolute',
    top: 10,
    right: 80,
    cursor: 'pointer',
    padding: 13,
    zIndex: 999999,
    borderRadius: '50%',
    background: '#494949',
  },
  emphasis: {
    color: '#4b7bff',
    textTransform: 'capitalize',
    fontWeight: '600',
  },
  deleteButton: {
    color: '#848484',
    cursor: 'pointer',
    fontSize: '24px',
    display: 'none',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    position: 'absolute',
    top: '12px',
    right: '12px',
  },
  timelineItem: {
    fontSize: 14,
    position: 'relative',
    '&:hover': {
      '& $editButton': {
        display: 'block',
      },
      '& $deleteButton': {
        display: 'block',
      },
    },
  },
}));

const UserInfo = ({ user, userInfo }) => {
  const email = user?.email || userInfo?.email;
  return email ? <strong>{email}</strong> : 'A user';
};

UserInfo.propTypes = {
  user: PropTypes.string.isRequired,
  userInfo: PropTypes.shape({
    name: PropTypes.string.isRequired,
    age: PropTypes.number.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
};

export default function Log({ createdAt, files = [], comment, user, userInfo, meeting, intervene, handleRestoreClick, handleDeleteNote, archivedDate }) {
  const classes = useStyles();
  const { organization } = useOrganization();
  const { myUser } = useContext(Context);
  const [open, setOpen] = React.useState(false);
  const [openDelete, setOpenDelete] = useState(false)
  const [filesSelected, setFilesSelected] = React.useState({ files: [], selectedFileIndex: 0 });
  const [loadingOpenFiles, setLoadingOpenFiles] = React.useState([]);

  const handleOpen = (filesOp) => {
    setOpen(true);
    const filesToOpen = filesOp.filter((flop) => flop.mimetype.includes('image') || flop.mimetype.includes('video'));
    setFilesSelected({ files: filesToOpen, selectedFileIndex: 0 });
    setLoadingOpenFiles(
      filesToOpen.map(() => ({
        loading: false,
      }))
    );
  };

  const handleClickEvents = (fl) => ({
    onDownload: () => downloadImage(fl.download_url, fl.name),
    onClick: () => handleOpen(files),
  });

  const renderName = (wholeName = false) => {
    if (userInfo && !wholeName) {
      return `${userInfo?.email[0]?.toUpperCase()} ${userInfo?.email[1]?.toUpperCase()}`;
    }

    if (userInfo && wholeName) {
      return userInfo?.email;
    }

    if (!user && !userInfo && !wholeName) {
      return 'DU';
    }
    if (!user && !userInfo && wholeName) {
      return 'Deleted User';
    }
  };

  const getCanDelete = () => {
    if(myUser.role === 'admin' && organization?.permissions?.[myUser?.role]?.canDeleteNotesOfficers !== 0 ){
      return true
    }else if(organization?.permissions?.[myUser?.role]?.canDeleteNotesOfficers !== 0 && (myUser._id === user._id)  ){
      return true
    }else{
      return false
    }
  }

  const renderLog = () => {
    const hasFiles = files.length > 0;
    const hasComment = !!comment;
    const hasBoth = hasFiles && hasComment;

    return (
      <>
        <UserInfo user={user} userInfo={userInfo} />{' '}
        {hasComment && (
          <>
            made a <span className={classes.emphasis}>new comment</span>
          </>
        )}
        {hasBoth && ' and '}
        {hasFiles && (
          <>
            <span className={classes.emphasis}>uploaded {files.length}</span> file(s)
          </>
        )}{' '}
        {intervene && (
          <>
            to <span className={classes.emphasis}>{intervene}</span>
          </>
        )}
        {typeof meeting === 'number' && (
          <>
            to <span className={classes.emphasis}>{`${meeting} months meeting`}</span>
          </>
        )}
        {hasComment && (
          <>
            <HTMLContent content={comment} />
          </>
        )}
        {hasFiles && (
          <>
            <Box display="flex" flexDirection="column">
              {files.map((file, index) => {
                const showPreview = !file?.archivedDate;
                return (
                  <div key={index}>
                    <Box display="flex" alignItems="center" justifyContent="flex-start">
                      <PreviewImg
                        key={file._id}
                        style={
                          !file.name.includes('.pdf')
                            ? { height: 100, margin: 6 }
                            : { minHeight: 35, width: 'fit-content' }
                        }
                      >
                        {showPreview ? (
                          <LogIcon fl={file} handleClickEvents={handleClickEvents(file)} index={index} />
                        ) : (
                          <ArchivedImage />
                        )}
                      </PreviewImg>
                      <Typography style={{ fontSize: 14 }}>{file.name}</Typography>
                    </Box>
                    <Tags fileId={file?._id} defaultTags={file?.tags || []} allTags={[]} tagsRecently={[]} />
                  </div>
                );
              })}
            </Box>
          </>
        )}
      </>
    );
  };

  return (
    <>
      <TimeLineItemNotes
          createdAt={createdAt}
          profileImg={null}
          avatar={renderName()}
          onRestoreClick={handleRestoreClick}
          archivedDate={archivedDate}
          //canEdit={myUser?._id === createdBy?._id}
          canDelete={getCanDelete() }
          onDeleteClick={() => setOpenDelete(true)}
          editing={false} >
        {renderLog()}
        <CustomModalDelete
          open={openDelete}
          handleClose={() => setOpenDelete(false)}
          fileName={comment}
          onConfirm={handleDeleteNote}
        />
      </TimeLineItemNotes>
      <CarouselModal
        open={open}
        setOpen={setOpen}
        filesSelected={filesSelected}
        setLoadingOpenFiles={setLoadingOpenFiles}
        loadingOpenFiles={loadingOpenFiles}
      />
    </>
  );
}

Log.propTypes = {
  handleRestoreClick: PropTypes.func.isRequired,
  handleDeleteNote: PropTypes.func.isRequired,
  archivedDate: PropTypes.instanceOf(Date).isRequired,
  createdAt: PropTypes.instanceOf(Date).isRequired,
  files: PropTypes.arrayOf(PropTypes.string),
  comment: PropTypes.string.isRequired,
  user: PropTypes.string.isRequired,
  userInfo: PropTypes.shape({
    name: PropTypes.string,
    age: PropTypes.number,
    email: PropTypes.string,
  }),
  meeting: PropTypes.shape({
    title: PropTypes.string,
    location: PropTypes.string,
    date: PropTypes.instanceOf(Date),
  }),
  intervene: PropTypes.bool,
};

const PreviewImg = styled.div`
  height: 100px;
  position: relative;

  overflow: hidden;
  margin-right: 10px;
  & div:hover {
    opacity: 1;
  }
`;
