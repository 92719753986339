import React, { Fragment, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Modal, Fade, Paper, makeStyles, Typography, Box, Button, CircularProgress } from '@material-ui/core';
import { toast } from 'react-toastify';
import shortid from 'shortid';

import FormSection from '../pages/FormDashboard/FormSection';
import { getQuestionBank } from '../services/unarmed';

const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: theme.shadows[5],
    padding: 64,
    minWidth: 600,
    alignSelf: 'center',
    borderRadius: 4,
    maxHeight: '80vh',
    overflowY: 'scroll',
    maxWidth: 700,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

/**
 * React component for editing case details in a modal.
 *
 * @component
 * @param {Object} props - The properties passed to the component.
 * @param {string} props.formId - The ID of the form being edited.
 * @param {Object} props.organization - The organization associated with the form.
 * @param {boolean} props.open - Flag indicating whether the modal is open.
 * @param {Object} props.page - The current page being edited.
 * @param {string} props.title - The title of the modal.
 * @param {number} props.pageIndex - The index of the current page.
 * @param {Function} props.onClose - Function to handle modal closure.
 * @param {Function} props.onSubmit - Function to handle form submission.
 */
export const ModalEditCaseDetails = ({
  formId,
  organization,
  open,
  page,
  formPages,
  title,
  pageIndex,
  onClose,
  onSubmit,
}) => {
  /**
   * Material-UI styles for the component.
   * @type {Function}
   */
  const classes = useStyles();

  /**
   * State variable to store the available question banks.
   * @type {Array}
   */
  const [questionsBanks, setQuestionsBanks] = useState([]);

  /**
   * State variable to store the form values being edited.
   * @type {Array}
   */
  const [formValues, setFormValues] = useState([]);

  /**
   * State variable to store image previews.
   * @type {Array}
   */
  const [imagesPreview, setImagesPreview] = useState([]);

  /**
   * State variable indicating whether data is being loaded.
   * @type {boolean}
   */
  const [loading, setLoading] = useState(false);

  /**
   * Helper function to process and update components with question banks.
   *
   * @param {Array} components - Array of components to process.
   * @returns {Array} - Processed array of components.
   */
  const getComponents = (components) => {
    const questionBankComponents = components.map((component) => {
      if (component.type === 'questionBank') {
        const comps = questionsBanks.find((q) => q.type === component.data.type);
        return {
          ...component,
          components: comps?.components?.map((comp, index) => ({
            ...comp,
            data: {
              ...comp.data,
              questionBankIndex: index,
            },
          })),
        };
      }

      return component;
    });

    return questionBankComponents;
  };

  /**
   * Helper function to get form values based on page, section, and component indices.
   *
   * @param {number} _pageIndex - Index of the current page.
   * @param {number} sectionIndex - Index of the current section.
   * @param {number} componentIndex - Index of the current component.
   * @returns {*} - Form value for the specified indices.
   */
  const getFormValue = (_pageIndex, sectionIndex, componentIndex) => {
    if (formValues.length === 0) {
      return;
    }
    return formValues[_pageIndex]?.sections?.[sectionIndex]?.components[componentIndex];
  };

  /**
   * Helper function to update form values based on input data.
   *
   * @param {number} _pageIndex - Index of the current page.
   * @param {number} sectionIndex - Index of the current section.
   * @param {number} componentIndex - Index of the current component.
   * @param {*} value - New value to be set.
   * @param {number} demographicIndex - Index of the demographic data (if applicable).
   */
  const onSaveFormValues = (_pageIndex, sectionIndex, componentIndex, value, dmographicIndex) => {
    if (formValues.length === 0) {
      return;
    }
    const formValuesCopy = Array.from(formValues);

    if (typeof demographicIndex === 'number') {
      formValuesCopy[_pageIndex].sections[sectionIndex].components[componentIndex].components[dmographicIndex] = value;
    } else {
      formValuesCopy[_pageIndex].sections[sectionIndex].components[componentIndex] = value;
    }
    setFormValues(formValuesCopy);
  };

  /**
   * useEffect hook to fetch form type and question banks on component mount or when formId and organization change.
   */
  useEffect(() => {
    const onGetFormType = async () => {
      setLoading(true);
      try {
        const formatArrayValues = formPages.map((_page, _pageIndex) => ({
          sections: _page.sections.map((section, sectionIndex) => ({
            components: section.components.map((component, componentIndex) => {
              const value =
                pageIndex === _pageIndex ? page.sections[sectionIndex]?.components[componentIndex]?.value : null;

              if (component.type === 'questionBank') {
                return value;
              }

              if (component.type === 'addressInformation') {
                return {
                  street: value?.street || null,
                  aptUnit: value?.aptUnit || null,
                  state: value?.state?.name || null,
                  city: value?.city?.name || null,
                  zipCode: value?.zipCode || null,
                };
              }

              if (component.type === 'contactInformation') {
                return {
                  email: value?.email,
                  firstName: value?.firstName,
                  lastName: value?.lastName,
                  phone: value?.phone,
                  fileAnonymously: value?.fileAnonymously,
                  workTelephone: value?.workTelephone,
                };
              }

              return value;
            }),
          })),
        }));

        setFormValues(formatArrayValues);
        setLoading(false);
      } catch (error) {
        toast.error(error.message);
      }
    };
    const onGetQuestionsBanks = async () => {
      try {
        const { data } = await getQuestionBank();
        setQuestionsBanks(data);
      } catch (error) {
        toast.error(error.message);
      }
    };
    if (organization) {
      onGetFormType();
      onGetQuestionsBanks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formId, organization, pageIndex]);

  const pageFormatted = useMemo(
    () => ({
      ...page,
      sections: page.sections.map((section, sectionIndex) => ({
        ...section,
        components: section.components.map((component, componentIndex) => {
          if (component.type === 'demographics') {
            const demographicskeys = Object.keys(component.data);
            const demographicsComps = demographicskeys.map((key) => ({
              type: key,
              data: {
                ...component.data[key],
                questionBankIndex: demographicskeys.indexOf(key),
                isDemographics: true,
                demographicKey: key,
              },
              id: shortid.generate(),
              path: `[${pageIndex}].sections[${sectionIndex}].components[${componentIndex}].components[${demographicskeys.indexOf(
                key
              )}]`,
            }));

            const getDemographicsComponentByType = (type) =>
              demographicsComps.find((comp) => comp.type === type) || null;

            const demographicsComponentsOrdered = [
              getDemographicsComponentByType('birthdate'),
              getDemographicsComponentByType('gender'),
              getDemographicsComponentByType('raceEthnicity'),
              getDemographicsComponentByType('race'),
              getDemographicsComponentByType('ethnicity'),
              getDemographicsComponentByType('sexualOrientation'),
            ];

            return {
              ...component,
              components: demographicsComponentsOrdered.filter((comp) => comp?.data?.visible),
              path: `[${pageIndex}].sections[${sectionIndex}].components[${componentIndex}]`,
            };
          }
          return {
            ...component,
            path: `[${pageIndex}].sections[${sectionIndex}].components[${componentIndex}]`,
          };
        }),
      })),
    }),
    [page, pageIndex]
  );

  /**
   * Render the JSX structure for the component.
   */
  return (
    <Modal className={classes.modal} open={open} onClose={onClose}>
      <Fade in={open}>
        <Paper className={classes.paper}>
          <h1>{title}</h1>
          {loading ? (
            <Box mt={2} display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Fragment key={pageIndex}>
                <Typography className={classes.pageTitle}>{page?.name}</Typography>
                {pageFormatted.sections.map((section, sectionIndex) => (
                  <FormSection
                    key={sectionIndex}
                    withFileUpload={false}
                    name={section.name?.trim()}
                    pageIndex={pageIndex}
                    sectionIndex={sectionIndex}
                    components={section?.components}
                    getComponents={getComponents}
                    onSaveFormValues={(componentIndex, value, demographicIndex) =>
                      onSaveFormValues(pageIndex, sectionIndex, componentIndex, value, demographicIndex)
                    }
                    imagesPreview={imagesPreview}
                    setImagesPreview={setImagesPreview}
                    organizationId={organization?._id}
                    getFormValue={(componentIndex) => getFormValue(pageIndex, sectionIndex, componentIndex)}
                  />
                ))}
              </Fragment>
              <Box display="flex" justifyContent="flex-end" alignItems="center" mt={4}>
                <Button
                  onClick={onClose}
                  style={{ textTransform: 'capitalize', color: '#4b7bff', border: '1px solid #4b7bff' }}
                  variant="outlined"
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => onSubmit(formValues)}
                  style={{ marginLeft: 8, textTransform: 'capitalize' }}
                >
                  Save
                </Button>
              </Box>
            </>
          )}
        </Paper>
      </Fade>
    </Modal>
  );
};

/**
 * PropTypes to specify the expected types for component props.
 *
 * @type {Object}
 */
ModalEditCaseDetails.propTypes = {
  open: PropTypes.bool,
  formId: PropTypes.string,
  title: PropTypes.string,
  onSubmit: PropTypes.func,
  page: PropTypes.object,
  organization: PropTypes.object,
  formPages: PropTypes.array,
  pageIndex: PropTypes.number,
  onClose: PropTypes.func,
};
