import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import React, { useEffect, useState } from 'react';
import SelectBox from '../../components/SelectBox';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CheckIcon from '@material-ui/icons/Check';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const useStyles = makeStyles(() => ({
  button: {
    background: ' #4b7bff',
    textTransform: 'capitalize',
    color: '#fff',
    '&:hover': {
      background: '#4b7bff',
    },
    '& .MuiButton-label': {
      fontSize: 14,
    },
    marginTop: 10,
    marginBottom: 20,
  },
  labelHightlighted: {
    height: 26,
    padding: '5px 10px',
    width: 'fit-content',
    background: '#6D738526',
    borderRadius: '2px',
    fontWeight: '500',
  },
}));

const MatchFieldRow = ({
  field,
  options = [],
  checkRowValues,
  setSelectOptions,
  indexRow,
  setConfirmRows,
  confirmRows,
  setRowSelected,
  rowValues,
  confirmAll,
  setConfirmAll,
}) => {
  const classes = useStyles();

  const getPreselectedOption = options.find((option) => option.text === field);
  const [selected, setSelected] = useState(() => {
    if (getPreselectedOption) {
      const optionsCopy = options.slice();
      const index = options.findIndex((option) => option.text === field);
      optionsCopy[index].disabled = true;
      setSelectOptions(optionsCopy);
      return getPreselectedOption.value;
    }
    return rowValues[indexRow]?.selected || '';
  });
  const [lock, setLock] = useState(false);
  const { rowItems, totalItems } = checkRowValues(field);

  const onChangeOptions = (e) => {
    const { value } = e.target;
    if (value === '0') {
      return;
    }
    const optionsCopy = options.slice();
    const index = options.findIndex((option) => option.value === value);
    optionsCopy[index].disabled = true;
    if (selected) {
      const selectedIndex = options.findIndex((option) => option.value === selected);
      optionsCopy[selectedIndex].disabled = false;
    }

    setSelected(value);
    setSelectOptions(optionsCopy);
    if (lock) {
      const confirmRowsCopy = { ...confirmRows };
      delete confirmRowsCopy[indexRow];

      setLock(false);
      setConfirmRows(confirmRowsCopy);
    }
  };

  const onMapRow = () => {
    setLock(true);
    const getSelectedOption = options.find((option) => option.value === selected);

    setConfirmRows((prevState) => ({
      ...prevState,
      [indexRow]: {
        field,
        selected,
        text: getSelectedOption?.text,
        beKey: getSelectedOption?.beKey,
        type: getSelectedOption?.type,
      },
    }));
    setRowSelected(indexRow, { selected });
  };

  useEffect(() => {
    if (confirmAll && getPreselectedOption?.value === selected) {
      onMapRow();
      setConfirmAll(false);
    } else if (confirmAll && selected) {
      onMapRow();
      setConfirmAll(false);
    }
  }, [confirmAll]);
  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" marginBottom="44px">
      <Box display="flex" alignItems="center" minWidth="257px" justifyContent="space-between">
        <Typography
          style={{
            fontSize: 14,
            fontWeight: '500',
          }}
        >
          {field}
        </Typography>

        <ArrowForwardIcon htmlColor="#000000AD" />
      </Box>
      <Box display="flex" alignItems="center" marginLeft="55px" justifyContent="space-between" width="100%">
        <Box display="flex" alignItems="center">
          <Box width="230px">
            <SelectBox
              options={{ obj: options }}
              outlined
              value={selected}
              showDefaultValue={false}
              onChange={onChangeOptions}
            />
          </Box>
          <Box marginLeft="18px">
            {getPreselectedOption && getPreselectedOption.value === selected && (
              <Box display="flex" alignItems="center">
                <CheckCircleOutlineIcon htmlColor="#009A6D" style={{ fontSize: 16, marginRight: 5 }} />
                <Typography style={{ fontSize: 14 }}>
                  This field matched with{' '}
                  <label className={classes.labelHightlighted}>{getPreselectedOption.text}</label>
                </Typography>
              </Box>
            )}
            {rowItems === totalItems ? (
              <Box display="flex" alignItems="center">
                <CheckCircleOutlineIcon htmlColor="#009A6D" style={{ fontSize: 16, marginRight: 5 }} />
                <Typography style={{ fontSize: 14 }}>All cells contain data</Typography>
              </Box>
            ) : (
              <Box display="flex" alignItems="center">
                <ErrorOutlineIcon htmlColor="#FEB535" style={{ fontSize: 16, marginRight: 5 }} />
                <Typography style={{ fontSize: 14 }}>
                  {rowItems}/{totalItems} cells contain data
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
        {lock ? (
          <Box display="flex" alignItems="center">
            <CheckIcon htmlColor="#009A6D" />
            <Typography style={{ fontSize: 14, marginLeft: 5 }}>Confirmed mapping</Typography>
          </Box>
        ) : (
          <Button variant="contained" className={classes.button} onClick={onMapRow} disabled={!selected ? true : false}>
            Confirm mapping
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default MatchFieldRow;
