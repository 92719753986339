import { Button, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

import React from 'react';

const useStyles = makeStyles(() => ({
  btn: {
    '&:disabled': {
      background: '#1f63ff61 !important',
      color: '#fff !important',
    },
  },
}));

const DashButton = ({
  variant = 'contained',
  onClick,
  children,
  disabled,
  marginRight,
  marginLeft,
  marginTop,
  outline = false,
  style,
  dataTestId,
}) => {
  const classes = useStyles();
  return (
    <Button
      data-testid={dataTestId}
      variant={variant}
      disabled={disabled}
      className={classes.btn}
      autoCapitalize="none"
      style={
        outline
          ? {
              background: '#fff',
              border: '1px solid #1F63FF',
              cursor: 'pointer',
              color: '#1F63FF',
              minWidth: 'fit-content',
              textTransform: 'capitalize',
              maxWidth: 36,
              height: 36,
              padding: '6px 12px',
              marginRight,
              marginLeft,
              marginTop,
              ...style,
            }
          : {
              background: '#1F63FF',
              textTransform: 'capitalize',
              cursor: 'pointer',
              minWidth: 36,
              color: '#fff',
              height: 36,
              padding: '6px 16px',
              marginRight,
              marginLeft,
              marginTop,
              ...style,
            }
      }
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

DashButton.propTypes = {
  dataTestId: PropTypes.string,
  variant: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  marginRight: PropTypes.number,
  marginLeft: PropTypes.number,
  marginTop: PropTypes.number,
  outline: PropTypes.bool,
  style: PropTypes.object,
};

export default DashButton;
