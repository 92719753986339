export const TYPE_USER_TABS = {
  REPORTS: 'REPORTS',
  EXPLORE: 'EXPLORE',
  DASHBOARDS_LIST: 'DASHBOARDS_LIST',
  DASHBOARD: 'DASHBOARD',
  VIEW: 'VIEW',
  MAIN: 'MAIN'
};

export const DISPLAY_OBJECT_TYPES = {
  DASHBOARD: 'DASHBOARD',
  VIEW: 'VIEW',
}