import { Box, Button, CircularProgress, Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Context } from '../../Context';
import { getSingleUser, getInvitationUser } from '../../services/unarmed';
import ProfileInformation from '../Profile/ProfileInformation';
import profileImg from '../../assets/profile.png';
import { unarmedWithToken } from '../../services/axios';
import ModalUserRole from '../../components/ModalUserRole';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 20,
  },
  title: {
    marginTop: 50,
  },
  label: {
    fontSize: 12,
    color: '#aaa',
  },
  button: {
    letterSpacing: '1px',
    fontSize: 13,
    background: '#2E66FE',
    fontWeight: '400',
    width: 'fit-content',
    alignSelf: 'flex-end',
    color: '#fff',
    '&:hover': {
      background: '#4b7bff',
    },
    '& .MuiButton-label': {
      fontSize: 13,
    },
  },

  buttonOutlined: {
    letterSpacing: '1px',
    fontSize: 13,
    background: '#fff',
    fontWeight: '500',
    marginRight: 10,
    width: 'fit-content',
    alignSelf: 'flex-end',
    border: '1px solid #4b7bff ',
    color: '#4b7bff',
    '&:hover': {
      background: '#fff',
      border: '1px solid #4b7bff ',
    },
    '& .MuiButton-label': {
      fontSize: 13,
    },
  },

  img: {
    width: 120,
    height: 120,
    borderRadius: '50%',
    objectFit: 'cover',
  },

  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: '#F9FAFF',
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: 20,
  },
}));
const User = () => {
  const classes = useStyles();
  const [me, setMe] = useState({});
  const { authUser, setActive, myUser } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const location = useLocation();
  const { id } = useParams();

  const onGetSingleUser = async () => {
    try {
      setLoading(true);
      let res = null;

      if (location?.href?.includes('invitation')) {
        res = await getInvitationUser(id);
      } else {
        res = await getSingleUser(id);
        await unarmedWithToken().post('/activity/user-view', { userId: id });
      }

      setMe(res.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    setActive('/users');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (authUser) {
      onGetSingleUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser]);

  return (
    <Box width="100%" className={classes.container}>
      <Typography variant="h5">Users </Typography>
      <Box width="100%" style={{ background: '#fff' }} marginTop="20px">
        <Box
          width="100%"
          display="flex"
          padding="10px 20px"
          alignItems="center"
          borderBottom="1px solid #0000001F"
          justifyContent="space-between"
        >
          <Typography variant="subtile1" style={{ fontWeight: '500' }}>
            User Profile
          </Typography>
        </Box>
        {loading ? (
          <Box width="100%" height="300px" display="flex" alignItems="center" justifyContent="center">
            <CircularProgress color="#000" size="40px" />
          </Box>
        ) : (
          <>
            <Box borderRadius={4} padding="20px" style={{ background: '#fff' }}>
              <Grid container justifyContent="space-between" alignItems="start">
                <Grid item xs={10}>
                  <Box display="flex" alignItems="center">
                    <img
                      src={me?.photo?.url || me?.profile_img || profileImg}
                      alt="profileImg"
                      className={classes.img}
                    />
                    <Box display="flex" justifyContent="flex-start" flexDirection="column" marginLeft="20px">
                      <Typography
                        style={{
                          textAlign: 'center',
                          fontSize: 20,
                          fontWeight: '500',
                        }}
                      >
                        {`${me?.firstName} ${me?.lastName}`}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: 14,
                          textTransform: 'capitalize',
                          color: '#333',
                          marginBottom: 4,
                        }}
                      >
                        {`${me?.role || me?.type || ''}`}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                {myUser?.role === 'admin' && (
                  <Grid item xs={2}>
                    <Button
                      disabled={me?.role === 'admin'}
                      variant="contained"
                      color="primary"
                      onClick={() => setOpenModal(true)}
                      style={{ marginTop: 10 }}
                    >
                      EDIT ROLE
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Box>
            <ProfileInformation me={me} />
          </>
        )}
      </Box>
      <ModalUserRole userId={id} open={openModal} onClose={() => setOpenModal(false)} onRoleChanged={onGetSingleUser} />
    </Box>
  );
};

export default User;
