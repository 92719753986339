import { useEffect, useState, useCallback } from 'react';
import { toast } from 'react-toastify';

import { getProjectInfo } from '../services/unarmed';

const useProject = (id) => {
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(false);

  const onProject = useCallback(async () => {
    try {
      setLoading(true);

      const { data } = await getProjectInfo(id);

      setProject(data);
    } catch (error) {
      toast.error(error.message);
      setProject(null);
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      onProject();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return {
    loading,
    setProject,
    project,
    refetch: onProject,
  };
};

export default useProject;
