import React, { useState, useEffect } from 'react';
import { Box, Grid, Button, Typography, makeStyles, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import SearchIcon from '@material-ui/icons/Search';

import { OutLineInput } from '../pages/CaseDetails/UpdateCase';
import { fetchContacts } from '../services/unarmed';
import { axiosCatchError, formatPhoneNumber } from '../utils';
import Card from './Card';
import SearchResults from './SearchResults';

const useStyles = makeStyles(() => ({
  button: {
    background: ' #3B70FE',
    textTransform: 'capitalize',
    color: '#fff',
    '&:hover': {
      background: '#3B70FE',
    },
    '& .MuiButton-label': {
      fontSize: 14,
    },
    marginTop: 10,
    marginBottom: 20,
  },
}));

const SearchContacts = ({ search, linkFunc, linkId, defaultContacts, onAddedContact }) => {
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [searched, setSearched] = useState(false);

  const classes = useStyles();

  const handleOnClickAddContact = (newContact) => {
    setLoading(newContact?._id);
    linkFunc(linkId, newContact?._id)
      .then((res) => {
        setLoading(false);
        setContacts([...contacts.filter((contact) => contact?._id !== newContact?._id)]);
        toast.success('Contact added successfully!');
        onAddedContact(newContact);
      })
      .catch((error) => {
        setLoading(false);
        axiosCatchError(error);
      });
  };

  const handleOnSearch = (e) => {
    e.preventDefault();

    if (search.value !== '') {
      const params = {
        searchValue: search.value,
      };

      setLoadingSearch(true);
      fetchContacts(params)
        .then((res) => {
          setContacts(res.data);
          setLoadingSearch(false);
          setSearched(true);
        })
        .catch((error) => {
          axiosCatchError(error);
          setLoadingSearch(false);
          setSearched(true);
        });
    }
  };

  const handleOnClear = () => {
    search.setValue('');
    setContacts([]);
  };

  useEffect(() => {
    if (search.value === '' && searched) {
      setSearched(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search.value]);

  return (
    <Card title="Search Contact">
      <form onSubmit={handleOnSearch}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item xs={12}>
            <Box position="relative">
              <OutLineInput
                data-testid="case-details-contacts-input-search-contact"
                placeholder="Search contacts..."
                value={search.value}
                onChange={search.onChange}
                error={search.error}
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  'aria-label': 'weight',
                }}
                labelWidth={0}
              />
              <SearchIcon htmlColor="#707687" style={{ position: 'absolute', right: 10, top: 5 }} />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" alignItems="flex-end" flexDirection="column" mt={2}>
              <Button
                data-testid="case-details-contacts-button-search-submit"
                color="primary"
                variant="contained"
                className={classes.button}
                type="submit"
              >
                {loadingSearch ? <CircularProgress color="#fff" size="25px" /> : 'Search'}
              </Button>
              <Box mt={1}>
                <Typography
                  variant="body2"
                  style={{
                    fontWeight: '500',
                    fontSize: 12,
                    color: '#3B70FE',
                    cursor: 'pointer',
                  }}
                  data-testid="case-details-contact-button-clear"
                  onClick={handleOnClear}
                >
                  Clear
                </Typography>
              </Box>
            </Box>
          </Grid>
          <SearchResults
            columns={[
              {
                name: 'First name',
                key: 'firstName',
                xs: 2,
              },
              {
                name: 'Last name',
                key: 'lastName',
                xs: 2,
              },
              {
                name: 'Phone number',
                key: 'phoneNumber',
                renderValue: (value) => formatPhoneNumber(value),
                xs: 3,
              },
              {
                name: 'Email',
                key: 'email',
                xs: 4,
              },
            ]}
            data={contacts}
            loading={loading}
            defaultData={defaultContacts}
            onClickAdd={(item) => handleOnClickAddContact(item)}
            searched={search.value !== '' && searched}
            noResultsText="No contacts found yet"
          />
        </Grid>
      </form>
    </Card>
  );
};

SearchContacts.propTypes = {
  search: PropTypes.object.isRequired,
  defaultContacts: PropTypes.array,
  linkFunc: PropTypes.func,
  linkId: PropTypes.number,
  onAddedContact: PropTypes.func,
};

export default SearchContacts;
