/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-nested-ternary */
import {
  Backdrop,
  Box,
  Checkbox,
  CircularProgress,
  Fade,
  makeStyles,
  Modal,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { useHistory, useLocation, Link } from 'react-router-dom';
import React, { useContext, useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import moment from 'moment';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import Flagged from '../../assets/flagged.png';
import TabsModules from '../../components/TabsModules';
import { Context } from '../../Context';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import {
  deleteForm,
  getCivilianCases,
  getFormsRange,
  getViewById,
  editMultipleForms,
  updateViewsById,
} from '../../services/unarmed';
import formatTxt from '../../utils/formatText';
import CaseFilters from '../../components/CaseFilters';
import useInput from '../../hooks/useInput';
import Alert from '../../components/Alert';
import UpgradeModal from '../../components/UpgradeModal';
import useOrganization from '../../hooks/useOrganization';
import { logEvent } from '../../services/firebase';
import { axiosCatchError, transformStatus } from '../../utils';
import { exportCSVFile } from '../../utils/convertJsonCsv';
import AssignedToTooltip from '../../components/AssignedToTooltip';
import ArrayTooltip from '../../components/ArrayTooltip';
import PageWrapper from '../../components/PageWrapper';
import PageButtons from '../../components/PageButtons';
import { StyledButtonOutlined } from '../../styles/GlobalStyles';
import useFiltersConfig from '../../hooks/useFiltersConfig';
import { useTabsUtil } from '../../hooks/useTabs';
import { TYPE_USER_TABS } from '../../utils/constants';
import { ViewTabCases } from './ViewTabCases';

const useStyles = makeStyles((theme) => ({
  button: {
    background: ' #1F63FF',
    textTransform: 'capitalize',
    marginRight: 10,
    color: '#fff',
    height: 36,
    width: 30,
    '&:hover': {
      background: '#1F63FF',
    },
    '& .MuiButton-label': {
      fontSize: 13,
    },
    '&:disabled': {
      background: '#1f63ff61',
      color: '#fff',
    },
  },
  button2: {
    background: ' #1F63FF',
    textTransform: 'capitalize',
    color: '#fff',
    '&:hover': {
      background: '#1F63FF',
    },
    '& .MuiButton-label': {
      fontSize: 14,
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: '#fff',
    boxShadow: theme.shadows[5],
    padding: 30,
    maxWidth: 600,
    outline: 0,
  },
  row: {
    cursor: 'pointer',
    height: 50,
    maxHeight: 50,
    background: '#fff',
    '&:hover': {
      backgroundColor: '#f5f5f5',
      '& $externalIcon': {
        visibility: 'visible',
        color: '#1F63FF',
      },
    },
  },
  externalIcon: {
    visibility: 'hidden',
  },
}));

const getStatusTitle = (row) => {
  const currentStatus = row?.availableStatuses?.filter((status) => status.status === row?.status);
  return transformStatus(currentStatus[0]?.status);
};

const capitalize = (word) => {
  const lowerCase = word.toLowerCase();
  return lowerCase.charAt(0).toUpperCase() + lowerCase.slice(1);
};

export const TransferredFrom = ({ referralOrg }) => {
  if (!referralOrg) {
    return null;
  }

  const tooltipTitle = `Transferred from ${referralOrg?.name}`;
  return (
    <Tooltip title={tooltipTitle}>
      <span>TransferredFrom</span>
    </Tooltip>
  );
};

TransferredFrom.propTypes = {
  referralOrg: PropTypes.string.isRequired,
};

export const TransferredTo = ({ referralOrg }) => {
  if (!referralOrg) {
    return null;
  }

  const tooltipTitle = `Transferred to ${referralOrg?.name}`;
  return (
    <Tooltip title={tooltipTitle}>
      <span>TransferredTo</span>
    </Tooltip>
  );
};

TransferredTo.propTypes = {
  referralOrg: PropTypes.string.isRequired,
};

export const CaseRenderRow = ({
  isItemSelected,
  index,
  row,
  labelId,
  description,
  handleClick,
  withCheckbox,
  visibleColumns,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const goToCaseDetails = (e) => {
    const go = handleClick(e, row._id);
    if (go) {
      history.push(`/case/${row.code}`);
      logEvent('enter_case_detail');
    }
  };

  return (
    <TableRow
      hover
      aria-checked={isItemSelected}
      selected={isItemSelected}
      role="checkbox"
      tabIndex={-1}
      key={index}
      className={classes.row}
      onClick={goToCaseDetails}
    >
      {withCheckbox && (
        <TableCell padding="checkbox">
          <Checkbox checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} color="primary" />
        </TableCell>
      )}

      {visibleColumns?.flag && (
        <TableCell component="th" scope="row" align="left">
          {row?.flagged && <img src={Flagged} alt="Flagged" style={{ width: '20px', height: '20px' }} />}
        </TableCell>
      )}
      {visibleColumns?.type && (
        <TableCell
          align="left"
          style={{
            color: row.type === 'Complaint' ? '#FF4242' : row.type === 'Compliment' ? '#008858' : '#2E66FE',
            textTransform: 'capitalize',
            fontWeight: '500',
          }}
        >
          {row?.typeDisplayText}
        </TableCell>
      )}
      {visibleColumns?.subtype && (
        <TableCell align="left">
          <ArrayTooltip items={row?.subtype} />
        </TableCell>
      )}
      {visibleColumns?.trackingNumber && <TableCell align="left">{row?.code}</TableCell>}
      {visibleColumns?.caseNumber && <TableCell align="left">{row?.caseNumber}</TableCell>}
      {visibleColumns?.internalAffairsCaseNumber && <TableCell align="left">{row?.internalAffairsCaseNumber}</TableCell>}
      {visibleColumns?.createdDate && (
        <TableCell component="th" scope="row" align="left">
          {moment(row.createdAt).format('MMMM DD, YYYY')}
        </TableCell>
      )}
      {visibleColumns?.name && (
        <TableCell align="left" style={{ textTransform: 'capitalize' }}>
          {row.civilian?.firstName
            ? `${formatTxt(row.civilian?.firstName, 10)} ${formatTxt(row.civilian?.lastName, 10)}`
            : ''}
        </TableCell>
      )}
      {visibleColumns?.assignedTo && (
        <TableCell align="left">
          <AssignedToTooltip users={row.assigned?.filter((assigned) => !!assigned)} />
        </TableCell>
      )}
      {visibleColumns?.source && (
        <TableCell align="left">
          <ArrayTooltip items={row?.source} />
        </TableCell>
      )}
      {visibleColumns?.status && (
        <TableCell align="left">
          <Box display="flex" flexDirection="row" alignItems="center">
            <Box flexGrow="1">
              <p style={{ fontWeight: '500' }}>{getStatusTitle(row)}</p>
              <p style={{ fontSize: 12, color: '#585858' }}>
                {row?.statusLog && moment(row?.statusLog?.[row?.status]?.datetime).format('MMMM DD, hh:mm a')}
              </p>
            </Box>
          </Box>
        </TableCell>
      )}

      {visibleColumns?.reporter && (
        <TableCell align="left">
          <ArrayTooltip items={row?.reporter} />
        </TableCell>
      )}

      {visibleColumns?.investigator && (
        <TableCell align="left">
          <ArrayTooltip items={row?.investigator} />
        </TableCell>
      )}

      {visibleColumns?.allegations && (
        <TableCell align="left">
          <ArrayTooltip items={row?.allegations} />
        </TableCell>
      )}

      {visibleColumns?.dispositions && (
        <TableCell align="left">
          <ArrayTooltip items={row?.dispositions} />
        </TableCell>
      )}

      {visibleColumns?.discipline && (
        <TableCell align="left">
          <ArrayTooltip items={row?.discipline} />
        </TableCell>
      )}

      <TableCell align="left">
        <Box display="flex" alignItems="center">
          <Link
            to={`/case/${row.code}`}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.externalIcon}
            title="Open case in new tab"
            onClick={(e) => {
              e.stopPropagation();
              return false;
            }}
          >
            <OpenInNewIcon />
          </Link>
        </Box>
      </TableCell>
    </TableRow>
  );
};

CaseRenderRow.propTypes = {
  row: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  labelId: PropTypes.string.isRequired,
  isItemSelected: PropTypes.bool.isRequired,
  withCheckbox: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
  visibleColumns: PropTypes.object.isRequired,
};

const Cases = () => {
  const classes = useStyles();
  const location = useLocation();
  const { authUser } = useContext(Context);
  const [forms, setForms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selected, setSelected] = React.useState([]);
  const [checked, setChecked] = useState(false);
  const isSelected = (_name) => selected.indexOf(_name) !== -1;
  const [openFilter, setOpenFilter] = useState(location.state?.openFilter || false);
  const [sortBy, setSortBy] = useState('createdAt');
  const [sort, setSort] = useState('desc');
  const [open, setOpenModal] = useState(false);
  const [loadingDeleteCase, setLoadingDeleteCase] = useState(false);
  const filterSaved = JSON.parse(localStorage.getItem('filters'));
  const [formType, setFormType] = useState(location.state?.formType ? location.state?.formType : null);
  const code = useInput('');
  const [assigned, setAssigned] = useState();
  const status = useInput(() => (location.state?.filter ? location.state?.filter : null));
  const subType = useInput([]);
  const source = useInput([]);
  const reporter = useInput([]);
  const investigator = useInput([]);
  const baseColumns = {
    flag: true,
    type: true,
    subtype: false,
    trackingNumber: true,
    caseNumber: false,
    internalAffairsCaseNumber: false,
    createdDate: true,
    name: true,
    assignedTo: true,
    source: false,
    status: true,
    reporter: false,
    investigator: false,
    allegations: false,
    dispositions: false,
    discipline: false,
  };
  const [allegation, setAllegation] = useState([]);
  const [disposition, setDisposition] = useState([]);

  const [discipline, setDiscipline] = useState(filterSaved?.discipline || []);
  const name = useInput(filterSaved?.name || '');
  const [from, setFrom] = useState(filterSaved?.from || null);
  const [to, setTo] = useState(filterSaved?.to || null);
  const archivedOnly = useInput(false, false, false, true);
  const transferredOnly = useInput(false, false, false, true);
  const flaggedOnly = useInput(false, false, false, true);
  const { organization } = useOrganization();

  const [isFiltering, setIsFiltering] = useState(() =>
    location.state?.isFiltering ? location.state?.isFiltering : false
  );

  const [showAlert, setShowAlert] = useState(location?.state?.showAlert || false);
  const [isFilteringByContact, setIsFilteringByContact] = useState(location?.state?.isFilteringByContact || false);

  const [loadingEditForms, setLoadingEditForms] = useState(false);
  const [visibleColumns, setVisibleColumns] = useState(baseColumns);
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  const filteredColumns = Object.keys(visibleColumns).filter((column) =>
    column.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const showColumns = filteredColumns.filter((column) => visibleColumns[column]);
  const hideColumns = filteredColumns.filter((column) => !visibleColumns[column]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);
  const popoverId = open ? 'column-popover' : undefined;

  const {
    filtersConfig,
    filterCount,
    filtersApplied,
    resetFilterCount,
    fetchFiltersConfig,
  } = useFiltersConfig('forms');

  const {
    activeTab,
    setActiveTab,
    tabSelected,
    setTabSelected,
    userTabs,
    updateNameTab,
    setUserTabs,
    handleSaveTab,
    handleTabSelection,
    nextPosition,
    handleTabsReorder,
    handleTabSelectionAction,
    deleteSelectedTab,
    activeTabName,
    setActiveTabName,
  } = useTabsUtil({ moduleName: 'CASES' });

  const onClearFilters = async () => {
    setFormType('0');
    subType.setValue([]);
    setFrom(null);
    setTo(null);
    code.setValue(null);
    status.setValue('0');
    name.setValue(null);
    setAssigned(null);
    source.setValue([]);
    reporter.setValue([]);
    investigator.setValue([]);
    setAllegation([]);
    setDisposition([]);
    setDiscipline([]);
    localStorage.removeItem('filters');
    setIsFiltering(false);
    archivedOnly.setValue(false);
    transferredOnly.setValue(false);
    flaggedOnly.setValue(false);

    resetFilterCount();
    try {
      setLoading(true);
      const { data, headers } = await getCivilianCases({
        token: authUser.tokenId,
        page,
        limit: rowsPerPage,
        sortBy,
        sort,
        isFiltering: false,
        contact: isFilteringByContact ? location?.state?.id : undefined,
        ...(filtersConfig ? { filtersConfigId: filtersConfig?._id } : {}),
      });

      setForms({ data, headers });
      setLoading(false);
      fetchFiltersConfig();
      toast.success('Successfully cleared filters!');
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  const onClearOfficersCases = async () => {
    setShowAlert(false);
    setIsFilteringByContact(false);
  };

  const handleToggleColumn = async (viewId, columnName) => {
    try {
      const updatedColumns = {
        ...visibleColumns,
        [columnName]: !visibleColumns[columnName],
      };

      const response = await updateViewsById(viewId, { columns: updatedColumns });

      if (response && response.status === 200 && response.view) {
        setVisibleColumns(response.view.columns);
      } else {
        console.error('Failed to update columns:', response);
      }
    } catch (error) {
      console.error('Error updating column visibility:', error);
    }
  };


  const formatHeadersText = (str) => {
    const result = str.replace(/([A-Z])/g, ' $1');

    return result.replace(/^./, (firstChar) => firstChar.toUpperCase()).trim();
  };

  const onEditMultipleForms = async () => {
    let ids = '';
    const selectedForms = forms.data.filter((form) => selected.includes(form._id));
    const allAreFlagged = selectedForms.every((form) => form.flagged);

    for (const id of selected) {
      const index = selected.indexOf(id);
      if (index + 1 === selected.length) {
        ids += `ids[]=${id}`;
      } else {
        ids += `ids[]=${id}&`;
      }
    }

    try {
      setLoadingEditForms(true);
      await editMultipleForms(ids);

      setForms((prevForms) => {
        const updatedData = prevForms.data.map((form) => {
          if (selected.includes(form._id)) {
            return {
              ...form,
              flagged: !allAreFlagged,
            };
          }
          return form;
        });

        return {
          ...prevForms,
          data: updatedData,
        };
      });

      setLoadingEditForms(false);
      setSelected([]);
      toast.success(allAreFlagged ? 'Successfully unflagged cases' : 'Successfully flagged cases');
    } catch (error) {
      setLoadingEditForms(false);
      toast.error(error.message);
    }
  };

  const onGetFormRange = async () => {
    let ids = '';
    for (const id of selected) {
      const index = selected.indexOf(id);
      if (index + 1 === selected.length) {
        ids += `formIds[]=${id}`;
      } else {
        ids += `formIds[]=${id}&`;
      }
    }
    try {
      const { data } = await getFormsRange(authUser.tokenId, ids, {
        archivedOnly: archivedOnly.value,
        transferredOnly: transferredOnly.value,
      });

      const headers = [
        'Flag',
        'Type',
        'Tracking Number',
        'Created Date',
        'Name',
        'Assigned To',
        'Status',
        'Status Date',
      ];

      const dataToExport = data?.map((row) => ({
        [headers[0]]: row?.flagged,
        [headers[1]]: capitalize(row?.type),
        [headers[2]]: row.code || '',
        [headers[3]]: row.createdAt ? moment(row.createdAt).format('MMMM DD, YYYY') : '',
        [headers[4]]: `${row?.civilian?.firstName || ''} ${row?.civilian?.lastName || ''}`,
        [headers[5]]: row?.assigned
          .map(
            (assignedUser) =>
              `${assignedUser?.firstName || ''} ${assignedUser?.lastName || ''} (${assignedUser?.email || ''})`
          )
          .join(', '),
        [headers[6]]: capitalize(row.status) || '',
        [headers[7]]: row?.statusLog?.[row?.status]?.datetime
          ? moment(row?.statusLog?.[row?.status]?.datetime).format('MMMM DD, YYYY')
          : '',
      }));

      // const csvObj = data.map((dt) => ({
      //   'Allegation Type': dt.allegation?.allegation || 'N/A',
      //   'Tracking Number': dt.code,
      //   'Created Date': moment(dt?.allegation.createdAt).format(`MM-DD-YYYY`),
      //   'Badge/Serial Number': dt?.officer.badgeNumber,
      //   'Officer Name': `${dt?.officer?.firstName} ${dt?.officer?.lastName}`,
      //   Status: convertStatusToLowerCase(dt?.allegation?.status) || 'N/A',
      // }));
      exportCSVFile(headers, dataToExport, `Oversight-Cases-${moment().format('MMMM-DD-YYYY')}`);

      // setOpen(false);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(1);
  };

  const onDeleteForm = async () => {
    let ids = '';
    for (const id of selected) {
      const index = selected.indexOf(id);
      if (index + 1 === selected.length) {
        ids += `ids[]=${id}`;
      } else {
        ids += `ids[]=${id}&`;
      }
    }

    try {
      setLoadingDeleteCase(true);
      setIsFiltering(true);
      const res = await deleteForm(ids);

      if (res.data) {
        if (res.data.deleted && res.data.deleted.length > 0) {
          const casesDeleted = res.data.deleted.join(', ');

          toast.success(`The cases ${casesDeleted} was deleted successfully`);
        }

        if (res.data.notDeleted && res.data.notDeleted.length > 0) {
          const casesNotDeleted = res.data.notDeleted.join(', ');

          toast.error(
            `The cases ${casesNotDeleted}, can not be deleted please un-link related cases to be able to delete the cases`
          );
        }
      }

      const { data, headers } = await getCivilianCases({
        token: '',
        page,
        limit: rowsPerPage,
        sortBy,
        sort,
        isFiltering: true,
        type: formType,
        from: from ? moment(from).format('YYYY-MM-DD') : null,
        to: to ? moment(to).format('YYYY-MM-DD') : null,
        code: code.value,
        status: status.value,
        name: name.value,
        assignedTo: assigned?._id || (assigned === 'null' ? assigned : location.state?.unassigned),
        source: source.value,
        reporter: reporter.value,
        investigator: investigator.value,
        allegation,
        disposition,
        discipline,
        badgeNumber: location?.state?.badgeNumber || null,
        archivedOnly: archivedOnly.value,
        transferredOnly: transferredOnly.value,
        flaggedOnly: flaggedOnly.value,
        subType: subType.value,
        contact: undefined,
        ...(filtersConfig ? { filtersConfigId: filtersConfig?._id } : {}),
      });

      setForms({ data, headers });
      setLoadingDeleteCase(false);
      setOpenModal(false);
      setSelected([]);
    } catch (error) {
      setLoadingDeleteCase(false);
      axiosCatchError(error);
    }
  };

  const renderRow = (row, index, handleClick) => {
    const isItemSelected = isSelected(row._id);
    const labelId = `enhanced-table-checkbox-${index}`;
    const description = [
      row.archived ? 'Archived' : null,
      row.transferred && row.referral?.previous?.organization ? (
        <TransferredFrom referralOrg={row.referral.previous.organization} />
      ) : null,
      row.transferred && row.referral?.next?.organization ? (
        <TransferredTo referralOrg={row.referral.next.organization} />
      ) : null,
    ]
      .filter(Boolean)
      .flatMap((value, _index, array) => {
        if (_index < array.length - 1) {
          return [value, ' - '];
        }

        return [value];
      });

    return (
      <CaseRenderRow
        row={row}
        index={index}
        description={description}
        labelId={labelId}
        handleClick={handleClick}
        isItemSelected={isItemSelected}
        visibleColumns={visibleColumns}
        withCheckbox
      />
    );
  };

  const getVisibleColumns = () => [
    ...Object.keys(visibleColumns)
      .filter((column) => visibleColumns[column])
      .map((column) => formatHeadersText(column)),
    ''
  ];

  return (
    <>
      {organization && <UpgradeModal open={!organization?.features?.dashboard?.cases?.enabled} />}

      <PageWrapper
        style={
          !organization?.features?.dashboard?.cases?.enabled
            ? { filter: 'blur(6px)', height: '70vh', overflow: 'hidden' }
            : {}
        }
      >
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h5">Cases</Typography>
        </Box>

        <TabsModules
          setTabs={setUserTabs}
          updateNameTab={updateNameTab}
          tabSelected={tabSelected}
          activeTabName={activeTabName}
          activeTab={activeTab}
          setTabSelected={setTabSelected}
          setActiveTab={setActiveTab}
          setActiveTabName={setActiveTabName}
          tabs={userTabs}
          navigateToDataPage={() => {}}
          saveTab={handleSaveTab}
          onTabSelect={handleTabSelection}
          nextPosition={nextPosition}
          saveTabPositions={handleTabsReorder}
          onTabAction={handleTabSelectionAction}
          deleteTab={deleteSelectedTab}
          moduleName="CASES"
          formType={formType}
        />

        {activeTab === TYPE_USER_TABS.MAIN && (
          <ViewTabCases
            tabSelected={tabSelected}
            allegation={allegation}
            anchorEl={anchorEl}
            archivedOnly={archivedOnly}
            assigned={assigned}
            authUser={authUser}
            checked={checked}
            classes={classes}
            code={code}
            discipline={discipline}
            disposition={disposition}
            fetchFiltersConfig={fetchFiltersConfig}
            filterCount={filterCount}
            filtersApplied={filtersApplied}
            filtersConfig={filtersConfig}
            flaggedOnly={flaggedOnly}
            formatHeadersText={formatHeadersText}
            forms={forms}
            formType={formType}
            from={from}
            getVisibleColumns={getVisibleColumns}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleClick={handleClick}
            handleClose={handleClose}
            handleToggleColumn={handleToggleColumn}
            hideColumns={hideColumns}
            investigator={investigator}
            isFiltering={isFiltering}
            isFilteringByContact={isFilteringByContact}
            loading={loading}
            location={location}
            name={name}
            onClearFilters={onClearFilters}
            onClearOfficersCases={onClearOfficersCases}
            onEditMultipleForms={onEditMultipleForms}
            onGetFormRange={onGetFormRange}
            openFilter={openFilter}
            openPopover={openPopover}
            page={page}
            renderRow={renderRow}
            reporter={reporter}
            rowsPerPage={rowsPerPage}
            searchTerm={searchTerm}
            selected={selected}
            setAllegation={setAllegation}
            setAssigned={setAssigned}
            setChecked={setChecked}
            setDiscipline={setDiscipline}
            setDisposition={setDisposition}
            setForms={setForms}
            setFormType={setFormType}
            setFrom={setFrom}
            setIsFiltering={setIsFiltering}
            setOpenFilter={setOpenFilter}
            setOpenModal={setOpenModal}
            setPage={setPage}
            setSearchTerm={setSearchTerm}
            setSelected={setSelected}
            setSort={setSort}
            setSortBy={setSortBy}
            setTo={setTo}
            showAlert={showAlert}
            showColumns={showColumns}
            sort={sort}
            sortBy={sortBy}
            source={source}
            status={status}
            subType={subType}
            to={to}
            transferredOnly={transferredOnly}
            visibleColumns={visibleColumns}
            setVisibleColumns={setVisibleColumns}
          />
        )}

        {activeTab === TYPE_USER_TABS.VIEW && (
          <ViewTabCases
            setFormType={setFormType}
            tabSelected={tabSelected}
            classes={classes}
            forms={forms}
            handleClick={handleClick}
            openPopover={openPopover}
            anchorEl={anchorEl}
            handleClose={handleClose}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            showColumns={showColumns}
            handleToggleColumn={handleToggleColumn}
            formatHeadersText={formatHeadersText}
            hideColumns={hideColumns}
            filterCount={filterCount}
            openFilter={openFilter}
            setOpenFilter={setOpenFilter}
            onEditMultipleForms={onEditMultipleForms}
            selected={selected}
            setOpenModal={setOpenModal}
            onGetFormRange={onGetFormRange}
            filtersApplied={filtersApplied}
            fetchFiltersConfig={fetchFiltersConfig}
            setForms={setForms}
            onClearFilters={onClearFilters}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            sort={sort}
            sortBy={sortBy}
            authUser={authUser}
            formType={formType}
            code={code}
            from={from}
            to={to}
            status={status}
            setFrom={setFrom}
            setTo={setTo}
            name={name}
            assigned={assigned}
            setAssigned={setAssigned}
            source={source}
            reporter={reporter}
            investigator={investigator}
            allegation={allegation}
            disposition={disposition}
            discipline={discipline}
            setAllegation={setAllegation}
            setDisposition={setDisposition}
            setDiscipline={setDiscipline}
            location={location}
            isFiltering={isFiltering}
            setIsFiltering={setIsFiltering}
            archivedOnly={archivedOnly}
            transferredOnly={transferredOnly}
            flaggedOnly={flaggedOnly}
            subType={subType}
            isFilteringByContact={isFilteringByContact}
            filtersConfig={filtersConfig}
            showAlert={showAlert}
            onClearOfficersCases={onClearOfficersCases}
            setSort={setSort}
            getVisibleColumns={getVisibleColumns}
            setSortBy={setSortBy}
            renderRow={renderRow}
            setChecked={setChecked}
            checked={checked}
            loading={loading}
            page={page}
            setSelected={setSelected}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            visibleColumns={visibleColumns}
            setVisibleColumns={setVisibleColumns}
          />
        )}
      </PageWrapper>

      <a id="downloadLink" style={{ position: 'absolute' }} />

      <Modal
        aria-labelledby="transition-modal-title2"
        aria-describedby="transition-modal-description2"
        className={classes.modal}
        open={open}
        onClose={() => setOpenModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Typography style={{ fontSize: 20, fontWeight: '500' }}>Are you sure you want to delete?</Typography>
            <Typography variant="body2">
              Cases will be archived for 7 years before being permanently deleted.
            </Typography>
            <Box display="flex" alignItems="center" justifyContent="flex-end" marginTop="20px">
              <StyledButtonOutlined variant="outlined" onClick={() => setOpenModal(false)}>
                CANCEL
              </StyledButtonOutlined>
              <StyledButtonOutlined variant="outlined" onClick={onDeleteForm}>
                {loadingDeleteCase ? <CircularProgress color="#fff" size="25px" /> : 'DELETE'}
              </StyledButtonOutlined>
            </Box>
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default Cases;
