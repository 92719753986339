import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  makeStyles,
  TextField,
  MenuItem,
  Typography,
} from '@material-ui/core';
import styled from 'styled-components';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { onDuplicateCase } from '../../services/unarmed';
import { useHistory } from 'react-router-dom';
import { TrendingUpTwoTone } from '@material-ui/icons';

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 20,
  },
  title: {
    marginTop: 50,
  },
  label: {
    fontSize: 12,
    color: '#aaa',
  },
  button: {
    letterSpacing: '1px',
    fontSize: 13,
    background: '#2E66FE',
    fontWeight: '400',
    width: 'fit-content',
    alignSelf: 'flex-end',
    color: '#fff',
    '&:hover': {
      background: '#4b7bff',
    },
    '& .MuiButton-label': {
      fontSize: 13,
    },
  },

  buttonOutlined: {
    letterSpacing: '1px',
    fontSize: 13,
    fontWeight: '500',
    marginRight: 10,
    width: 'fit-content',
    alignSelf: 'flex-end',
    border: '1px solid transparent',
    color: '#4b7bff',
    '&:hover': {
      background: 'transparent',
      border: '1px solid transparent',
    },
    '& .MuiButton-label': {
      fontSize: 13,
    },
    '&:disabled': {
      border: 0,
    },
  },

  img: {
    width: 120,
    height: 120,
    borderRadius: '50%',
    objectFit: 'cover',
  },

  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: '#fff',
    boxShadow: theme.shadows[5],
    padding: 30,
    maxWidth: 500,
  },

  alertContainer: {
    height: 'fit-content',
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    background: '#FFFBF3',
    borderLeft: '6px solid #FFC64C',
    borderRadius: 2,
    marginTop: 10,
    marginBottom: 20,
    padding: '10px',
  },
}));

const includeFields = [
  { label: "Cases Updates", value: 'caseUpdates' },
  { label: "Contacts", value: 'contacts' },
  { label: "Officers", value: 'officers' },
  { label: "Tasks", value: 'tasks' },
  { label: "Notes", value: 'notes' },
  { label: "Attachments", value: 'attachments' },
  { label: "History", value: 'history' },
]

const newCaseOptions = [
  { label: "Created date from case source", value: 'originalCreatedDate' },
  { label: "Assign case to me", value: 'assignToMe' },
  { label: "Archive source case", value: 'archiveOriginal' },
]

export default function DuplicateCase({ setOpen, organizationName, typeOptions, id, setForm, setIsRequestingData }) {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState({
    caseType: "",
    include: {
      caseUpdates: true,
      contacts: true,
      officers: true,
      tasks: true,
      notes: true,
      attachments: true,
      history: true,
    },
    additionalOptions: {
      originalCreatedDate: true,
      assignToMe: true,
      archiveOriginal: true,
    }
  })

  const handleCheckboxChange = (e, property) => {
    setOptions({
      ...options,
      [property]: {
        ...options[property],
        [e.target.name]: e.target.checked,
      }
    })
  }

  const handleDuplicateCase = () => {
    setLoading(true);
    onDuplicateCase(id, options)
      .then(({ data }) => {
        setLoading(false);
        setOpen(false);
        setIsRequestingData(true);
        setForm((prevState) => ({ ...prevState, ...data }));
        toast.success(`The Case was duplicated successfully. The Case Code is ${data.code}`);
        toast.success(`You have been redirected to the new case.`);
        setIsRequestingData(false);

        history.push(`/case/${data.code}`);
      })
      .catch((error) => {
        toast.error('Error duplicating the case. Please make sure that a form of the type you are trying to duplicate into exists.');
        setLoading(false);
      });
  };

  return (
    <>
      <TitleContainer>
        <Typography variant="h6">Duplicate Cases</Typography>
        <Typography style={{ fontSize: 14, fontWeight: 400, color: "#575A66" }} variant="subtitle2">Select options to duplicate a case</Typography>
      </TitleContainer>

      <Grid container spacing={2} style={{ padding: "20px 15px" }}>
        <Grid item xs={12}>
          <div>
            <div>
              { !options.caseType &&
                <Typography style={{ fontSize: 14, fontWeight: 400, color: "#575A66" }} variant="subtitle2">Select new case type</Typography>
              }
              <TextField
                id="filled-select-currency"
                select
                label="Select Type"
                style={{ width: 170, textTransform: 'capitalize' }}
                value={options.caseType}
                onChange={(e) => setOptions({ ...options, caseType: e.target.value })}
              >
                {typeOptions.map((option) => (
                  <MenuItem key={option.text} value={option.value} style={{ textTransform: 'capitalize' }}>
                    {option.text}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </div>
        </Grid>
        <Grid item xs={6}>
          <Typography style={{ fontSize: 14, fontWeight: 400, color: "#575A66" }} variant="subtitle2">Include</Typography>
          <div style={{ display: 'flex', flexDirection: "column" }}>
            {
              includeFields.map((field) => (
                <FormControlLabel
                  key={field.value}
                  control={
                    <Checkbox
                      checked={options.include[field.value]}
                      onChange={(e) => handleCheckboxChange(e, "include")}
                      name={field.value}
                      color="primary"
                    />
                  }
                  label={field.label}
                />
              ))
            }
          </div>
        </Grid>
        <Grid item xs={6}>
          <Typography style={{ fontSize: 14, fontWeight: 400, color: "#575A66" }} variant="subtitle2">Options for new case</Typography>
          <div style={{ display: 'flex', flexDirection: "column" }}>
            {
              newCaseOptions.map((field) => (
                <FormControlLabel
                  key={field.value}
                  control={
                    <Checkbox
                      checked={options.additionalOptions[field.value]}
                      onChange={(e) => handleCheckboxChange(e, "additionalOptions")}
                      name={field.value}
                      color="primary"
                    />
                  }
                  label={field.label}
                />
              ))
            }
          </div>
        </Grid>
      </Grid>

      <Box display="flex" alignItems="center" justifyContent="flex-end" marginTop="20px">
        <Button
          variant="outlined"
          className={classes.buttonOutlined}
          style={{ color: '#333' }}
          onClick={() => setOpen(false)}
        >
          CANCEL
        </Button>
        <Button
          style={{ border: 0 }}
          variant="outlined"
          disabled={options.caseType === "" || loading}
          className={classes.buttonOutlined}
          onClick={handleDuplicateCase}
        >
          {loading ? <CircularProgress color="#fff" size="25px" /> : 'Duplicate'}
        </Button>
      </Box>
    </>
  );
}
