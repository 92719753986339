import { Typography } from '@material-ui/core';
import React from 'react';

const MfaVerifyEmail = () => (
  <Typography style={{ marginTop: 10, fontSize: 14, color: '#000000AD' }}>
    Please check your inbox to verify your email address.
  </Typography>
);

export default MfaVerifyEmail;
