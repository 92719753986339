/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import AudiotrackIcon from '@material-ui/icons/Audiotrack';

export default function LogIcon({ fl, handleClickEvents, index }) {
  if (fl.name.includes('.pdf')) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        style={{ cursor: 'pointer' }}
        key={index}
        onClick={() => handleClickEvents.onDownload()}
      >
        <PictureAsPdfIcon htmlColor="#333" style={{ marginRight: 20, fontSize: 60 }} /> <br />
      </Box>
    );
  }

  if (fl.mimetype.includes('audio')) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        key={index}
        style={{ cursor: 'pointer' }}
        onClick={() => handleClickEvents.onDownload()}
      >
        <AudiotrackIcon htmlColor="#333" style={{ marginRight: 20, fontSize: 60 }} /> <br />
      </Box>
    );
  }

  if (fl.mimetype.includes('image')) {
    return (
      <img
        src={fl.public_url || fl.url}
        alt="404"
        key={index}
        onClick={() => handleClickEvents.onClick()}
        style={{
          width: 100,
          height: 100,
          objectFit: 'contain',
          marginRight: 10,
          borderRadius: 4,
          cursor: 'pointer',
        }}
      />
    );
  }
  if (fl.mimetype.includes('video')) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="left"
        onClick={() => handleClickEvents.onClick()}
        key={index}
        height="100%"
        style={{ cursor: 'pointer' }}
      >
        <VideoLibraryIcon htmlColor="#333" style={{ fontSize: 60 }} />
      </Box>
    );
  }

  if (!fl.name.includes('.pdf') && !fl.name.includes('.mp4') && !fl.mimetype.includes('image')) {
    return (
      <Box
        key={index}
        display="flex"
        flexDirection="column"
        style={{ cursor: 'pointer' }}
        onClick={() => handleClickEvents.onDownload()}
      >
        <InsertDriveFileIcon fontSize="large" htmlColor="#333" style={{ marginRight: 20, fontSize: 60 }} />
      </Box>
    );
  }

  return <Typography key={index}>File not supported</Typography>;
}

LogIcon.propTypes = {
  fl: PropTypes.any.isRequired,
  handleClickEvents: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};
