import React, { useContext, useEffect, useState } from 'react';

import { Context } from './Context';
import AppRouter from './routes/AppRouter';
import { onAuthStateChanged } from './services/firebase';
import LoadingScreen from './components/LoadingScreen';

let resentEmailCount = 1;

function App() {
  const { activateAuth, isAuth, setIsAuth, organization, loadingOrg, signUpUser } = useContext(Context);
  const [loading, setLoading] = useState(true);
  const [userAut, setUsers] = useState(null);

  useEffect(() => {
    onAuthStateChanged((user) => {
      if (user && organization) {
        const token = localStorage.getItem('authToken');

        if (!token) {
          if (!user.emailVerified && organization?.features?.dashboard?.forceMFA?.enabled) {
            if (resentEmailCount === 1) {
              user.sendEmailVerification({
                url: `${window.location.origin}/mfa`,
              });
            }
            resentEmailCount += 1;
          }
        }

        // Generate a new token Id everytime the user changes
        user.getIdToken().then((newToken) => {
          activateAuth({ ...user, tokenId: newToken });
          localStorage.setItem('authToken', newToken);
          localStorage.setItem('refreshToken', user.refreshToken);
        });

        setUsers(user);
        setIsAuth(true);
        setLoading(false);
      }

      if (loadingOrg === 'resolved') {
        setLoading(false);
      }
    });
    // eslint-disable-next-line
  }, [organization, loadingOrg, signUpUser]);

  return loading ? (
    <LoadingScreen />
  ) : (
    <AppRouter
      auth={isAuth}
      showMfa={
        userAut?.multiFactor?.enrolledFactors?.length === 0 && organization?.features?.dashboard?.forceMFA?.enabled
      }
      hasEnrollFactor={userAut?.multiFactor?.enrolledFactors?.length === 0}
    />
  );
}

export default App;
