import React, { useMemo } from 'react';
import { Box } from '@material-ui/core';
import WrapperComponent from './WrapperComponent';
import { Renderer } from '../config/Renderer';

import { getConfig } from '../config/Config';

/**
 * @description This component is used to render the question bank side component, also it render recursively the components inside the question bank. (if you are not familiar with recursion, please check this link: https://developer.mozilla.org/en-US/docs/Glossary/Recursion)
 */

export default function QuestionBankSideComponent({
  id,
  onDeleteComponent,
  path,
  components,
  refActionModal,
  setModal,
}) {
  const config = useMemo(
    () =>
      getConfig({
        componentsFromServer: components,
      }),
    [components]
  );

  return (
    <WrapperComponent
      onDeleteComponent={onDeleteComponent}
      id={id}
      path={path}
      renderPreview={() => {}}
      containsQuestionBanks
      refActionModal={refActionModal}
      setModal={setModal}
    >
      <Box paddingBottom="20px">
        <Renderer config={config} path={path} />
      </Box>
    </WrapperComponent>
  );
}
