import { ClickAwayListener, Grow, MenuList, Paper, Popper } from '@material-ui/core';
import React from 'react';

export default function PopperPortal({ open, setOpen, children, left = 32, top = 50, right }) {
  const anchorRef = React.useRef(null);
  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  return (
    <Popper
      open={open}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
      disablePortal
      style={{ zIndex: 9, position: 'absolute', top, left, right }}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList style={{ padding: 0 }} autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                {children}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}
