import { Box, Switch, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import DnDItem from '../../components/DnDItem';
import Input from '../../components/Input';
import { onChangeDnDValue } from '../../formEngine/utils/utls';

export default function Page({
  index,
  dragType,
  onMoveComponent,
  moveItem,
  setPages,
  onChangePages,
  page,
  handleDeletePage,
  selected,
  onSelectPage,
  disablePageOptions,
  isDefaultForm,
  withToggle = false,
}) {
  const [edit, setEdit] = useState(false);

  const renderPageContent = () =>
    edit ? (
      <Box
        width="100%"
        maxWidth={434}
        display="flex"
        alignItems="center"
        marginBotom="10px"
        onKeyPress={(e) => {
          const keycode = e.keyCode ? e.keyCode : e.which;
          if (keycode === 13) {
            setEdit(false);
          }
        }}
      >
        <Input
          placeholder={`${page.label} ${index + 1}`}
          value={page?.name}
          onChange={(e) => onChangeDnDValue(e, setPages, index, 'name', false, onChangePages)}
        />
      </Box>
    ) : (
      <PageTransition
        width="100%"
        maxWidth={434}
        display="flex"
        alignItems="center"
        borderRadius="4px"
        justifyContent="space-between"
        marginBotom="10px"
        selected={selected}
        style={{ background: '#fff' }}
      >
        <Typography
          style={{
            fontSize: 14,
            color: 'rgba(0, 0, 0, 0.87',
            fontFamily: 'Roboto',
            padding: '10px 16px',
            width: '100%',
          }}
          onClick={onSelectPage}
        >
          {page?.name || `${page.label} ${index + 1}`}
        </Typography>
        {!isDefaultForm && (
          <>
            <Box display="flex" alignItems="center" className="actions" style={{ padding: '10px 16px' }}>
              <EditIcon style={{ fontSize: 14 }} htmlColor="#6D7385" onClick={() => setEdit(true)} />
              <DeleteIcon
                htmlColor="#6D7385"
                style={{ marginLeft: 10, fontSize: 14 }}
                onClick={!disablePageOptions && handleDeletePage}
              />
            </Box>
            {withToggle && (
              <Switch
                color="primary"
                checked={page?.active}
                onChange={(e) => {
                  setPages((_pages) => {
                    const newPages = _pages.map((_page, _pageIndex) => {
                      if (index === _pageIndex) {
                        return { ...page, active: e.target.checked };
                      }
                      return {
                        ..._page,
                      };
                    });

                    onChangePages(newPages);

                    return newPages;
                  });
                }}
              />
            )}
          </>
        )}
      </PageTransition>
    );

  return (
    <Box onBlur={() => setEdit(false)}>
      {isDefaultForm ? (
        <div key={index} style={{ marginBottom: 10 }}>
          {renderPageContent()}
        </div>
      ) : (
        <DnDItem onMoveComponent={onMoveComponent} dragType={dragType} key={index} moveItem={moveItem} index={index}>
          {renderPageContent()}
        </DnDItem>
      )}
    </Box>
  );
}

Page.propTypes = {
  index: PropTypes.number.isRequired,
  onMoveComponent: PropTypes.func,
  dragType: PropTypes.string.isRequired,
  moveItem: PropTypes.func.isRequired,
  setPages: PropTypes.func.isRequired,
  onChangePages: PropTypes.func.isRequired,
  page: PropTypes.object.isRequired,
  handleDeletePage: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  onSelectPage: PropTypes.func.isRequired,
  disablePageOptions: PropTypes.bool.isRequired,
  isDefaultForm: PropTypes.bool.isRequired,
  withToggle: PropTypes.bool,
};

export const PageTransition = styled(Box)`
  cursor: pointer;
  ${(props) => props.selected && `border: 1px solid #2E66FECC`};
  .actions {
    transition: opacity 0.3s 0s ease-in-out;

    opacity: 0;
  }
  :hover .actions {
    transition: opacity 0.3s 0s ease-in-out;
    opacity: 1;
  }
`;
