import { Box, Checkbox, CircularProgress, Divider, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import shortid from 'shortid';
import AddMore from '../../components/AddMore';
import DashButton from '../../components/DashButton';
import { onUpdateOrganization } from '../../services/unarmed';
import TypeItem from './TypeItem';

const overviewTemplate = {
  actionName: 'Template Name',
  overviewFeatures: [
    {
      label: 'Times:',
      visible: false,
      required: false,
      key: 'times',
    },
    {
      label: 'Notes:',
      visible: false,
      required: false,
      key: 'notes',
    },
    {
      label: 'Assigned To:',
      visible: false,
      required: false,
      key: 'assignedTo',
    },
    {
      label: 'Attachments:',
      visible: false,
      required: false,
      key: 'attachments',
    },
  ],
};

export const useStylesProjectTypesSettings = makeStyles(() => ({
  container: {
    padding: 20,
    width: '100%',
  },
  typesContainer: {
    width: '100%',
    padding: 30,
    minHeight: 195,
    position: 'relative',
    borderRadius: 8,
    boxShadow: '0px 3px 6px #0000000D',
    backgroundColor: '#fff',
    marginTop: 20,
  },
  title: {
    fontSize: 16,
    marginBottom: 15,
    fontWeight: '500',
  },
  types: {
    width: 450,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  headerTitle: {
    minWidth: 170,
    fontWeight: '500',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 20,
  },
  itemContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    margin: '40px auto 10px',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 15,
  },
  names: {
    fontWeight: '500',

    minWidth: 250,
  },
  actionContainer: {
    marginLeft: 80,
  },
  columnText: {
    fontWeight: '500',
  },
}));

export default function ProjectTypes({ organization, setRefetch }) {
  const classes = useStylesProjectTypesSettings();
  const [loading, setLoading] = useState(false);

  const [types, setTypes] = useState([]);
  const [actionTypes, setActionTypes] = useState([]);
  const [actionTemplate, setActionTemplate] = React.useState(overviewTemplate);

  useEffect(() => {
    if (organization) {
      setTypes(
        organization?.project?.types?.map((type) => ({
          ...type,
          id: shortid.generate(),
          subTypes: type?.subTypes?.map((subType) => ({
            subtype: subType,
            id: shortid.generate(),
          })),
        }))
      );
      setActionTypes(
        organization?.project?.actions?.types?.map((type) => ({
          ...type,
          id: shortid.generate(),
          subTypes: type?.subTypes?.map((subType) => ({
            subtype: subType,
            id: shortid.generate(),
          })),
        }))
      );
      setActionTemplate({
        overviewFeatures: [
          {
            label: 'Times:',
            visible: organization?.project?.actions?.settings?.times?.visible || false,
            required: organization?.project?.actions?.settings?.times?.required || false,
            key: 'times',
          },
          {
            label: 'Notes:',
            visible: organization?.project?.actions?.settings?.notes?.visible || false,
            required: organization?.project?.actions?.settings?.notes?.required || false,
            key: 'notes',
          },
          {
            label: 'Assigned To:',
            visible: organization?.project?.actions?.settings?.assignedTo?.visible || false,
            required: organization?.project?.actions?.settings?.assignedTo?.required || false,
            key: 'assignedTo',
          },
          {
            label: 'Attachments:',
            visible: organization?.project?.actions?.settings?.attachments?.visible || false,
            required: organization?.project?.actions?.settings?.attachments?.required || false,
            key: 'attachments',
          },
        ],
      });
    }
  }, [organization]);

  const handleSave = async () => {
    setLoading(true);
    try {
      const data = {
        project: {
          types: types.map((type) => ({
            type: type.type,
            subTypes: type.subTypes.map((subType) => subType.subtype),
          })),
          actions: {
            types: actionTypes.map((type) => ({
              type: type.type,
              subTypes: type.subTypes.map((subType) => subType.subtype),
            })),
            settings: {
              times: {
                required: actionTemplate?.overviewFeatures?.[0].required,
                visible: actionTemplate?.overviewFeatures?.[0].visible,
              },
              notes: {
                required: actionTemplate?.overviewFeatures?.[1].required,
                visible: actionTemplate?.overviewFeatures?.[1].visible,
              },
              assignedTo: {
                required: actionTemplate?.overviewFeatures?.[2].required,
                visible: actionTemplate?.overviewFeatures?.[2].visible,
              },
              attachments: {
                required: actionTemplate?.overviewFeatures?.[3].required,
                visible: actionTemplate?.overviewFeatures?.[3].visible,
              },
            },
          },
        },
      };
      await onUpdateOrganization(organization?._id, data);
      setRefetch(true);
      toast.success('Updated project types successfully');
    } catch (error) {
      toast.error('Cannot update project types');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event, action, typeIndex, subTypeIndex, state, setState) => {
    const { value } = event.target;
    const typesCopy = [...state];
    if (action === 'type') {
      typesCopy[typeIndex].type = value;
      setState(typesCopy);
    } else if (action === 'subType') {
      const subTypeCopy = [...typesCopy[typeIndex].subTypes];
      subTypeCopy[subTypeIndex].subtype = value;
      setState(typesCopy);
    }
  };

  const handleDelete = (action, typeIndex, subTypeIndex, state, setState) => {
    switch (action) {
      case 'type': {
        const typesCopy = [...state];
        typesCopy.splice(typeIndex, 1);
        setState(typesCopy);
        break;
      }
      case 'SubType': {
        const typesCopy = [...state];
        const subTypesCopy = [...typesCopy[typeIndex].subTypes];
        subTypesCopy.splice(subTypeIndex, 1);
        typesCopy[typeIndex].subTypes = subTypesCopy;
        setState(typesCopy);
        break;
      }
      default: {
        const typesCopy = [...state];
        typesCopy.splice(typeIndex, 1);
        setState(typesCopy);
      }
    }
  };

  const handleAdd = (action, index, state, setState) => {
    switch (action) {
      case 'type': {
        const newType = {
          type: '',
          id: shortid.generate(),
          subTypes: [],
        };
        setState([...state, newType]);
        break;
      }
      case 'SubType': {
        const newSubtype = {
          id: shortid.generate(),
          subType: '',
        };
        const typesCopy = [...state];
        const subTypes = [...typesCopy[index].subTypes];
        typesCopy[index].subTypes = [...subTypes, newSubtype];
        setState(typesCopy);
        break;
      }
      default: {
        const newType = {
          type: '',
          id: shortid.generate(),
          subTypes: [],
        };
        setState([...state, newType]);
        break;
      }
    }
  };

  const onChangeActionFeatures = (e, prop, subProp) => {
    const { checked } = e.target;
    const features = [...actionTemplate.overviewFeatures];
    const feature = { ...features.find((ft) => ft.label === prop) };
    const featureIndex = features.findIndex((ft) => ft.label === prop);
    if (subProp === 'visible' && !checked) {
      feature[subProp] = checked;
      feature.required = false;
    } else {
      feature[subProp] = checked;
    }

    features[featureIndex] = feature;

    setActionTemplate((prevState) => ({
      ...prevState,
      overviewFeatures: features,
    }));
  };

  return (
    <>
      <Box className={classes.container}>
        <Box display="flex" justifyContent="flex-end" alignItems="center" width="100%">
          <DashButton onClick={handleSave}>
            {loading ? <CircularProgress color="#fff" size="20px" /> : 'Save'}{' '}
          </DashButton>
        </Box>
        <Box className={classes.typesContainer}>
          <Typography className={classes.title}>Project Types</Typography>
          <Divider style={{ marginBottom: 20 }} />
          <Box className={classes.types}>
            {types.map((type, typeIndex) => (
              <Box key={type.id} display="flex" flexDirection="column" alignItems="flex-end" marginBottom={2}>
                <TypeItem
                  placeholder={`Type ${typeIndex + 1}`}
                  value={type.type}
                  onChange={(e) => handleChange(e, 'type', typeIndex, null, types, setTypes)}
                  onClick={() => handleDelete('type', typeIndex, null, types, setTypes)}
                />
                <Box marginTop={type.subTypes?.length ? 2 : 1} width={400} alignSelf="flex-end" marginBottom={1}>
                  {type.subTypes?.map((subtype, subTypeIndex) => (
                    <TypeItem
                      placeholder={`Subtype ${subTypeIndex + 1}`}
                      key={subtype.id}
                      value={subtype.subtype}
                      onChange={(e) => handleChange(e, 'subType', typeIndex, subTypeIndex, types, setTypes)}
                      marginBottom={2}
                      onClick={() => handleDelete('SubType', typeIndex, subTypeIndex, types, setTypes)}
                    />
                  ))}
                  <AddMore
                    text="New Subtype"
                    marginLeft={type.subTypes?.length ? '0' : '-50px'}
                    marginTop="0"
                    onClick={() => handleAdd('SubType', typeIndex, types, setTypes)}
                  />
                </Box>
              </Box>
            ))}
            <Divider style={{ marginBottom: 10 }} />
            <Box display="flex" alignItems="center" justifyContent="center">
              <AddMore
                text="New Type"
                marginLeft="0"
                marginTop="0"
                onClick={() => handleAdd('type', null, types, setTypes)}
              />
            </Box>
          </Box>
        </Box>
        <Box className={classes.typesContainer}>
          <Typography className={classes.title}>Action Types</Typography>
          <Divider style={{ marginBottom: 20 }} />
          <Box className={classes.types}>
            {actionTypes.map((type, typeIndex) => (
              <Box key={type.id} display="flex" flexDirection="column" alignItems="flex-end" marginBottom={2}>
                <TypeItem
                  placeholder={`Type ${typeIndex + 1}`}
                  value={type.type}
                  onChange={(e) => handleChange(e, 'type', typeIndex, null, actionTypes, setActionTypes)}
                  onClick={() => handleDelete('type', typeIndex, null, actionTypes, setActionTypes)}
                />
                <Box marginTop={type.subTypes?.length ? 2 : 1} width={400} alignSelf="flex-end" marginBottom={1}>
                  {type.subTypes?.map((subtype, subTypeIndex) => (
                    <TypeItem
                      placeholder={`Subtype ${subTypeIndex + 1}`}
                      key={subtype.id}
                      value={subtype.subtype}
                      onChange={(e) => handleChange(e, 'subType', typeIndex, subTypeIndex, actionTypes, setActionTypes)}
                      marginBottom={2}
                      onClick={() => handleDelete('SubType', typeIndex, subTypeIndex, actionTypes, setActionTypes)}
                    />
                  ))}
                  <AddMore
                    text="New Subtype"
                    marginLeft={type.subTypes?.length ? '0' : '-50px'}
                    marginTop="0"
                    onClick={() => handleAdd('SubType', typeIndex, actionTypes, setActionTypes)}
                  />
                </Box>
              </Box>
            ))}
            <Divider style={{ marginBottom: 10 }} />
            <Box display="flex" alignItems="center" justifyContent="center">
              <AddMore
                text="New Type"
                marginLeft="0"
                marginTop="0"
                onClick={() => handleAdd('type', null, actionTypes, setActionTypes)}
              />
            </Box>
          </Box>
        </Box>
        <Box className={classes.typesContainer}>
          <Typography className={classes.title}>Action Settings</Typography>
          <Divider style={{ marginBottom: 20 }} />
          <Box className={classes.types}>
            <Box className={classes.itemContainer}>
              <Box className={classes.item}>
                <Typography className={classes.names} />
                <Typography className={classes.columnText}>Visible</Typography>
                <Typography className={[classes.actionContainer, classes.columnText]}>Required</Typography>
              </Box>
              {actionTemplate?.overviewFeatures?.map((item, actionIndex) => (
                <Box className={classes.item} key={`actionProp-${actionIndex}`}>
                  <Typography className={classes.names}>{item.label}</Typography>
                  <Checkbox
                    color="primary"
                    checked={item.visible}
                    onChange={(e) => onChangeActionFeatures(e, item.label, 'visible')}
                  />
                  <Checkbox
                    color="primary"
                    checked={item.required}
                    style={{ marginLeft: 95 }}
                    disabled={!item?.visible}
                    onChange={(e) => onChangeActionFeatures(e, item.label, 'required')}
                  />
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
