/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable no-nested-ternary */
import {
  Box,
  Typography,
  makeStyles,
  Button,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
  Menu,
  ListItemIcon,
  Divider,
  ClickAwayListener,
  Snackbar,
} from '@material-ui/core';
import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react';
import html2canvas from 'html2canvas';
import { Bar, Line, Pie } from 'react-chartjs-2';
import { useDeepCompareMemo } from 'use-deep-compare';
import { Table } from 'antd';
import Papa from 'papaparse';
import { exportComponentAsPNG } from 'react-component-export-image';
import moment from 'moment';
import 'antd/dist/antd.css';
import GetApp from '@material-ui/icons/GetApp';
import { exportCSVFile } from '../../../utils/convertJsonCsv';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { id } from 'date-fns/locale';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ContentCharts, { chartsItems } from './ContentCharts';
import ContentChartsButton from './ContentChartsButton';
import HTMLContent from '../../../components/HTMLContent';
import { containsHTML } from '../../../utils';
import useOrganization from '../../../hooks/useOrganization';
import useBuildingSchemaStatus from '../../../hooks/useBuildingSchemaStatus';
import {
  getDashboardsExplore,
  addDashboardReport,
  addReportInToDashboard,
  uploadReportImage
} from '../../../services/unarmed';
import CreateDashboard from '../CreateDashboard';
import ModalPopUp from '../../../components/ModaPopupl';
import SelectBox from '../../../components/SelectBox';
import Popper from '@material-ui/core/Popper';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '10px 0',
    width: '100%',
    backgroundColor: '#fff',
  },
  tableContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: '#fff',
  },
  buttons: {
    marginRight: 20,
  },
  customButton: {
    borderColor: '#d1d5db',
    color: '#6b7280',
    backgroundColor: '#f9fafb',
    textTransform: 'none',
    fontWeight: 400,
    fontSize: '14px',
    padding: '6px 12px',
    borderRadius: '0px',
    marginRight: '15px',
    '&:hover': {
      borderColor: '#9ca3af',
      backgroundColor: '#f3f4f6',
    },
  },
  iconLeft: {
    marginRight: '8px',
  },

  smallText: {
    fontSize: '12px',
    color: 'gray',
  },

  smallTextDashboard: {
    fontSize: '12px',
    color: 'blue',
  },

  TextSearchMenu: {
    height: '40px',
    '& .MuiInputBase-root': {
      fontSize: '10px',
    },
  },
  customSelectWrapper: {
    marginRight: '15px',
    '& .MuiSelect-root': {
      minHeight: '40px',
      padding: '6px 12px',
    },
  },
}));

const COLORS_SERIES = [
  '#5b8ff9',
  '#5ad8a6',
  '#5e7092',
  '#f6bd18',
  '#6f5efa',
  '#6ec8ec',
  '#945fb9',
  '#ff9845',
  '#299796',
  '#fe99c3',
];

const COLORS_BG_SERIES = [
  'rgba(91, 143, 249, 0.5)',
  'rgba(90, 216, 166, 0.5)',
  'rgba(94, 112, 146, 0.5)',
  'rgba(246, 189, 24, 0.5)',
  'rgba(111, 94, 250, 0.5)',
  'rgba(110, 200, 236, 0.5)',
  'rgba(148, 95, 185, 0.5)',
  'rgba(255, 152, 69, 0.5)',
  'rgba(41, 151, 150, 0.5)',
  'rgba(254, 153, 195, 0.5)',
];

const commonOptions = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    intersect: false,
  },
  plugins: {
    legend: {
      position: 'bottom',
    },
  },
  scales: {
    x: {
      ticks: {
        padding: 12,
      },
    },
    y: {
      ticks: {
        precision: 0,
      },
    },
  },
};

const AreaChartRenderer = ({ resultSet }) => {
  const datasets = useDeepCompareMemo(
    () =>
      resultSet.series().map((s, index) => ({
        label: s.title,
        data: s.series.map((r) => r.value),
        borderColor: COLORS_SERIES[index],
        backgroundColor: COLORS_BG_SERIES[index],
        pointRadius: 1,
        tension: 0.1,
        pointHoverRadius: 1,
        borderWidth: 2,
        fill: true,
      })),
    [resultSet]
  );
  const data = {
    labels: resultSet.categories().map((c) => c.x),
    datasets,
  };
  return (
    <div style={{ height: '400px', width: '100%' }}>
      <Line data={data} options={commonOptions} />
    </div>
  );
};

const LineChartRenderer = ({ resultSet }) => {
  const datasets = useDeepCompareMemo(
    () =>
      resultSet.series().map((s, index) => ({
        label: s.title,
        data: s.series.map((r) => r.value),
        borderColor: COLORS_SERIES[index],
        pointRadius: 1,
        tension: 0.1,
        pointHoverRadius: 1,
        borderWidth: 2,
        fill: false,
      })),
    [resultSet]
  );
  const data = {
    labels: resultSet.categories().map((c) => c.x),
    datasets,
  };
  return (
    <div style={{ height: '400px', width: '100%' }}>
      <Line data={data} options={commonOptions} />
    </div>
  );
};

const BarChartRenderer = ({ resultSet, pivotConfig }) => {
  const datasets = useDeepCompareMemo(
    () =>
      resultSet.series().map((s, index) => ({
        label: s.title,
        data: s.series.map((r) => r.value),
        backgroundColor: COLORS_SERIES[index],
        fill: false,
      })),
    [resultSet]
  );
  const data = {
    labels: resultSet.categories().map((c) => c.x),
    datasets,
  };
  const options = {
    ...commonOptions,
    scales: {
      x: {
        ...commonOptions.scales.x,
        stacked: !(pivotConfig.x || []).includes('measures'),
      },
    },
  };
  return <Bar type="bar" data={data} options={options} style={{ height: 'auto'}} />;
};

const renderChart = ({ reports, resultSet, error, pivotConfig, chartType }) => {
  if (error) {
    return <div>{error.toString()}</div>;
  }

  if (!resultSet) {
    return <div />;
  }

  if (chartType === 'Number') {
    return (
      <Box>
        {resultSet.seriesNames().map((s) => (
          <Typography variant="h4">{resultSet.totalRow()[s.key]}</Typography>
        ))}
      </Box>
    );
  }

  if (chartType === 'Area') {
    return (
      <div style={{ height: '450px', width: '100%' }}>
        <AreaChartRenderer resultSet={resultSet} />
      </div>
  )};

  if (chartType === 'Table') {
    return (
      <Table
        style={{ minWidth: '100%' }}
        pagination={false}
        columns={
          reports?.columns.map((column) => ({
            ...column,
            render: (text) => (containsHTML(text) ? <HTMLContent content={text} /> : text),
          })) || []
        }
        dataSource={reports?.data || []}
      />
    );
  }

  if (chartType === 'Pie') {
    const data = {
      labels: resultSet.categories().map((c) => c.x),
      datasets: resultSet.series().map((s) => ({
        label: s.title,
        data: s.series.map((r) => r.value),
        backgroundColor: COLORS_SERIES,
      })),
    };

    const pieOptions = {
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'bottom',
        },
      },
    };

    return (
      <div style={{ height: '450px', width: '100%' }}>
        <Pie data={data} options={pieOptions} />
      </div>
    );
  }

  if (chartType === 'Bar') {
    return <BarChartRenderer resultSet={resultSet} pivotConfig={pivotConfig} style={{ height: '80%' }} />;
  }

  if (chartType === 'Line') {
    return <LineChartRenderer resultSet={resultSet} />;
  }
};

const ChartRenderer = ({ reports, dimensions, chartType, resultSet, timeDimensions }) =>
  renderChart({
    reports,
    chartType,
    resultSet,
    pivotConfig: {
      x: [
        ...(dimensions || []),
        ...(timeDimensions?.[0] ? [`${timeDimensions?.[0]?.dimension}.${timeDimensions?.[0]?.granularity}`] : []),
      ],
      y: ['measures'],
      fillMissingDates: true,
      joinDateRange: false,
    },
  });

const ExploreContent = forwardRef(function ExploreContent(
  { reports, resultSet, report, cubeQuery, timeDimensions, reportId, reportName, reportImageUrl }, ref
) {
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedChart, setSelected] = useState(chartsItems[4]);
  const [showPopUp, setShowPopUp] = useState(false);
  const [openDashboardModal, setOpenDashboardModal] = useState(false);
  const [openDownloadMenu, setOpenDownloadMenu] = useState(false);
  const refChart = useRef(null);
  const { organization } = useOrganization();
  const [cubesBuilded] = useBuildingSchemaStatus(organization);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(true);

  const chartOptions = ['Line', 'Area', 'Bar', 'Pie', 'Table'];
  const [embedCode, setEmbedCode] = useState('');
  const [openEmbed, setOpenEmbed] = useState(false);
  const classes = useStyles();
  const anchorRef = useRef(null);
  const [anchorPosition, setAnchorPosition] = useState({ top: 0, left: 0 });
  const [reportImageUrlC, setReportImageUrlC] = useState(reportImageUrl || '');

  const [dashboardsMenu, setDashboardsMenu] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredDashboards, setFilteredDashboards] = useState([]);
  const downloadOptions = [
    { title: 'PNG', value: 'png' },
    { title: 'CSV', value: 'csv' },
  ];

  useEffect(() => {
    if (reportImageUrl) {
      setReportImageUrlC(reportImageUrl);
    }
  }, [reportImageUrl]);

  const handleClickDashboard = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleDashboardMenuClose = () => {
    setAnchorEl(null);
  };

  const handleToggle = () => {
    setOpenDownloadMenu((prev) => !prev);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpenDownloadMenu(false);
  };

  useEffect(() => {
    const fetchDashboards = async () => {
      setLoading(true);
      try {
        const response = await getDashboardsExplore();
        const { data } = response;
        setDashboardsMenu(Array.isArray(data.dashboards) ? data.dashboards : []);
      } catch (error) {
        console.error('Error fetching dashboards:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchDashboards();
  }, []);

  useEffect(() => {
    if (Array.isArray(dashboardsMenu)) {
      setFilteredDashboards(
        dashboardsMenu.filter((dashboard) => dashboard.name.toLowerCase().includes(searchTerm.toLowerCase()))
      );
    } else {
      setFilteredDashboards([]);
    }
  }, [searchTerm, dashboardsMenu]);

  const handleChartSelection = () => {
    setSelected(selectedChart === 'Table' ? 'Chart' : 'Table');
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  useImperativeHandle(ref, () => ({
    async handleImageUpload(name) {
      let file = null;
      try {
        const canvas = await html2canvas(refChart.current);
        if (!canvas) {
          console.error('Error: canvas is undefined');
          return;
        }

        file = await new Promise((resolve, reject) => {
          canvas.toBlob((blob) => {
            if (blob) {
              const fileName = `${name}-${moment().format()}.png`;
              const file = new File([blob], fileName, { type: 'image/png' });
              resolve(file);
            } else {
              reject(new Error('Canvas to Blob conversion failed.'));
            }
          });
        });
      } catch (err) {
        console.error('Error exporting component as PNG:', err);
        return;
      }

      if (!file) {
        console.error('No file was generated');
        return;
      }

      const formData = new FormData();
      formData.append('file', file);

      try {
        const uploadedImageUrl = await uploadReportImage(formData, report.id);
        console.log('Uploaded image URL:', uploadedImageUrl);
        setReportImageUrlC(uploadedImageUrl);
        return uploadedImageUrl;
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    }
  }));

  const handleDashboardCreate = async (newDashboard) => {
    try {
      const dashboardId = newDashboard._id;
      const response = await addDashboardReport({
        dashboardId,
        report,
      });

      if (response.status === 200) {
        setSnackbarMessage('Dashboard and report created successfully!');

        await ref.current.handleImageUpload(reportName)
      } else {
        setSnackbarMessage('Failed to add report to the dashboard');
      }

      setSnackbarOpen(true);
      setOpenDashboardModal(false);
    } catch (error) {
      console.error('Error creating dashboard report:', error);
      setSnackbarMessage('Error creating dashboard report.');
      setSnackbarOpen(true);
    }
  };

  const handleDasboardReportCreate = (dashboard, report) => {
    console.log('need to create dashboard report here');
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleEmbedOpen = () => {
    const generatedEmbedCode = `
    <img src=${reportImageUrlC}
     style="max-width: 40%; height: auto;"
     alt="${reportName}"/>
    `;
    setEmbedCode(generatedEmbedCode);
    setOpenEmbed(true);
  };

  const handleEmbedClose = () => {
    setOpenEmbed(false);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(embedCode);
  };

  const onSelectOption = (type, name) => {
    if (type === 'png') {
      exportComponentAsPNG(refChart, { fileName: `${name}-${moment().format()}.png` });
    } else if (type === 'csv') {
      const exportData = reports?.data.map((data) => {
        const body = {};
        reports.columns.forEach((c) => {
          const title = c.title?.trim();
          body[title] = data[c.key];
        });
        return body;
      });
      const csv = Papa.unparse(exportData);
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `${name || 'report'}.csv`;
      link.click();
    }
  };

  useEffect(() => {
    if (organization && organization.buildingSchemaEndDate) {
      setShowPopUp(true);
    }
  }, [organization]);

  const handleAddReportToDashboard = async (dashboard) => {
    try {
      if (!reportId) {
        setSnackbarMessage('Select the report that you want to add please.');
        setSnackbarOpen(true);
        handleDashboardMenuClose();
        return;
      }
      const response = await addReportInToDashboard({
        dashboardId: dashboard._id,
        reportId,
      });
      if (response.status === 200) {
        setSnackbarMessage('Tab saved successfully.');
        await ref.current.handleImageUpload(reportName)
        setSnackbarOpen(true);
        handleDashboardMenuClose();
      }
    } catch (error) {
      setSnackbarMessage(
        error?.request?.response?.message || 'We got a problem adding a report into the dashboard selected'
      );
      setSnackbarOpen(true);
      handleDashboardMenuClose();
    }
  };

  if (!organization) return null;

  return (
    <Box
      marginTop="10px"
      width="100%"
      height="calc(68vh - 64px)"
      style={{ overflowY: 'auto', overflowX: 'auto', position: 'relative' }}
    >
      <ModalPopUp open={openDashboardModal} setOpen={setOpenDashboardModal} disableBackdropClick disableEscapeKeyDown>
        <CreateDashboard setDashboards={handleDashboardCreate} setOpen={setOpenDashboardModal} />
      </ModalPopUp>
      <Dialog open={openEmbed} onClose={handleEmbedClose} fullWidth maxWidth="sm">
        <DialogTitle>Embed Report</DialogTitle>
        <DialogContent>
          <p>Copy the code below to embed this report on your website:</p>
          <TextField
            multiline
            fullWidth
            rows={6}
            value={embedCode}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <IconButton onClick={handleCopy}>
                  <FileCopyIcon />
                </IconButton>
              ),
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEmbedClose} color="primary">
            Done
          </Button>
        </DialogActions>
      </Dialog>

      <ContentCharts selectedChart={selectedChart} setSelected={setSelected}>
        <Box className={classes.tableContainer}>
          <Box className={classes.buttonContainer}>
            <Box>
              {/* Botón del selector de gráficos */}
              <ContentChartsButton selectedChart={selectedChart} setSelected={setSelected} />
            </Box>

            <>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleDashboardMenuClose}
                disableAutoFocus
                disableEnforceFocus
                sx={{
                  width: 150,
                  '& .MuiPaper-root': {
                    backgroundColor: '#f4f4f4',
                  },
                }}
              >
                <MenuItem disableRipple sx={{ padding: 0 }}>
                  <TextField
                    variant="outlined"
                    placeholder="Search Dashboards"
                    fullWidth
                    size="small"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className={classes.TextSearchMenu}
                  />
                </MenuItem>

                <Divider />

                <div style={{ maxHeight: '150px', overflowY: 'auto' }}>
                  {loading ? (
                    <MenuItem disabled>Loading dashboards...</MenuItem>
                  ) : filteredDashboards.length > 0 ? (
                    filteredDashboards.map((dashboard) => (
                      <MenuItem onClick={async () => handleAddReportToDashboard(dashboard)} key={dashboard._id}>
                        <ListItemIcon sx={{ minWidth: '30px' }}>
                          <DashboardIcon fontSize="small" />
                        </ListItemIcon>
                        <Typography variant="inherit" className={classes.smallText}>
                          {dashboard.name}
                        </Typography>
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No dashboards available</MenuItem>
                  )}
                </div>

                <Divider />

                <MenuItem
                  onClick={() => {
                    setOpenDashboardModal(true);
                  }}
                  sx={{ fontSize: '10px', padding: '6px 8px' }}
                >
                  <ListItemIcon sx={{ minWidth: '30px', color: 'blue' }}>
                    <AddCircleOutlineIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography variant="inherit" className={classes.smallTextDashboard}>
                    Add to New Dashboard
                  </Typography>
                </MenuItem>
              </Menu>

              <Button
                className={classes.customButton}
                variant="outlined"
                endIcon={<AddCircleOutlineIcon className={classes.iconLeft} />}
                onClick={handleMenuOpen}
                sx={{ marginTop: 2 }}
              >
                Add to Dashboard
              </Button>
            </>

            {reportImageUrlC && (
              <Button className={classes.customButton} variant="outlined" onClick={handleEmbedOpen}>
                { "Embed < >"}
              </Button>
            )}

            <Button
              ref={anchorRef}
              className={classes.customButton}
              variant="outlined"
              endIcon={<GetApp />}
              onClick={handleToggle}
            >
              Download
            </Button>

            <ClickAwayListener onClickAway={handleClose}>
              <Popper
                open={openDownloadMenu}
                anchorEl={anchorRef.current}
                placement="bottom-start"
                transition
              >
                {({ TransitionProps }) => (
                  <Box
                    {...TransitionProps}
                    style={{
                      zIndex: 1000,
                      backgroundColor: 'white',
                      border: '1px solid #ccc',
                      padding: '8px',
                    }}
                  >
                    {downloadOptions.map((option, index) => (
                      <MenuItem
                        key={index}
                        onClick={() => {
                          onSelectOption(option.value, report?.name);
                          setOpenDownloadMenu(false);
                        }}
                      >
                        {option.title}
                      </MenuItem>
                    ))}
                  </Box>
                )}
              </Popper>
            </ClickAwayListener>
          </Box>
        </Box>

        <div ref={refChart} style={{ height: '80%' }}>
          {resultSet && (
            <ChartRenderer
              reports={reports}
              chartType={selectedChart.text}
              resultSet={resultSet}
              dimensions={cubeQuery?.dimensions}
              timeDimensions={timeDimensions}
            />
          )}
        </div>
      </ContentCharts>

      <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose} message={snackbarMessage} />
    </Box>
  );
});

export default ExploreContent;
