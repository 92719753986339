import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import EmailIcon from '@material-ui/icons/Email';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  formSection: {
    fontSize: 14,
    color: '#3f51b5',
    fontWeight: '500',
    margin: '20px 0',
  },
  containerTitle: {
    padding: '10px 20px',
    borderBottom: '1px solid #e2e2e2',
  },
  resaltText: {
    color: '#4b7bff',
    fontWeight: 600,
    fontSize: 13,
  },
  label: {
    fontWeight: '500',
    fontSize: 14,
    margin: '10px 0 10px',
  },
  button: {
    background: ' #4b7bff',
    textTransform: 'capitalize',
    color: '#fff',
    '&:hover': {
      background: '#4b7bff',
    },
    '& .MuiButton-label': {
      fontSize: 14,
    },
    marginTop: 10,
    marginBottom: 20,
  },
  labelError: {
    fontSize: 12,
    color: 'red',
  },
}));

const SuccessImported = () => {
  const history = useHistory();
  const classes = useStyles();
  return (
    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" height="116.1vh">
      <EmailIcon htmlColor="#6D73853B" style={{ fontSize: 60 }} />
      <Typography
        style={{
          fontSize: 16,
          color: '#000000DE',
          margin: '32px 0 16px',
          fontWeight: '500',
        }}
      >
        CSV data import successful
      </Typography>
      <Typography
        style={{
          fontSize: 14,
          color: '#000000AD',
          marginBottom: 32,
          width: '100%',
          maxWidth: 570,
        }}
        align="center"
      >
        We are uploading the data from your CSV file, we will notify you by email when the process is finished.
      </Typography>

      <Button variant="contained" className={classes.button} onClick={() => history.push('/import')}>
        Upload new file
      </Button>
    </Box>
  );
};

export default SuccessImported;
