import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { Editor } from '@tinymce/tinymce-react';
import { TINYMCE_DEFAULT_CONFIG, TINYMCE_SCRIPT_SRC } from '../../../config/config';

const useStyles = makeStyles(() => ({
  title: {
    fontSize: 14,
    color: '#000000DE',
    marginLeft: 10,
  },
  label: {
    fontSize: 16,
    color: '#000000DE',
    fontWeight: '500',
  },
  dropzoneBox: {
    background: '#4762FA08',
    borderRadius: 4,
    border: '1px dashed #364F7431',
    padding: 34,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: 16,
  },

  dropzoneText: {
    display: 'block',
    fontSize: 12,
    marginTop: 10,
  },
}));

export default function AnswerComponent({ placeholder = 'Enter answer', value, onChange, labelAnswer = 'A', limit }) {
  const classes = useStyles();
  return (
    <Box display="flex">
      <Box display="flex" alignItems="center">
        <Typography className={classes.label}>{labelAnswer}</Typography>
        <ArrowRightAltIcon style={{ fontSize: 14, marginRight: 10 }} />
      </Box>
      <Editor
        tinymceScriptSrc={TINYMCE_SCRIPT_SRC}
        init={{
          ...TINYMCE_DEFAULT_CONFIG,
          placeholder,
          height: 200,
        }}
        value={value}
        onEditorChange={(newValue) => {
          onChange({ target: { value: newValue } });
        }}
      />
      {/*
      <TextArea
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        limit={limit}
      />
    */}
    </Box>
  );
}
