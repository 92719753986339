import axios from 'axios';

function resetToken() {
  return axios.create({
    baseURL: `https://securetoken.googleapis.com/v1/token?key=${process.env.REACT_APP_API_KEY}`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });
}

const config = {
  baseURL: process.env.REACT_APP_UNARMED_ENDPOINT,
  headers: {
    'content-type': 'application/json',
    ...(process.env.REACT_APP_TENANT_ID
      ? {
          'X-Tenant-Id': process.env.REACT_APP_TENANT_ID,
        }
      : {}),
  },
  responseType: 'json',
};

const axiosApiInstance = axios.create(config);

const axiosApiFileInstance = axios.create({
  baseURL: process.env.REACT_APP_UNARMED_ENDPOINT,
  headers: {
    'content-disposition': 'attachment;filename=report.xls',
    'content-type': 'application/vnd.ms-excel',
  },
});

axiosApiInstance.interceptors.request.use(
  async (cfg) => {
    const token = await localStorage.getItem('authToken');
    if (token) {
      cfg.headers.Authorization = `Bearer ${token}`;
    }
    return cfg;
  },
  (error: any) => {
    Promise.reject(error);
  }
);

axiosApiInstance.interceptors.response.use(
  (response) => response,
  async function (error) {
    const originalRequest = error.config;
    if (!error.response) {
      return Promise.reject('Network Error');
    }
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = localStorage.getItem('refreshToken');
      const { data } = await resetToken().post('', `grant_type=refresh_token&refresh_token=${refreshToken}`);
      originalRequest.headers.Authorization = `Bearer ${data.id_token}`;

      localStorage.setItem('authToken', data.id_token);
      localStorage.setItem('refreshToken', data.refresh_token);

      return axios(originalRequest);
    }
    return Promise.reject(error);
  }
);
axiosApiFileInstance.interceptors.request.use(
  async (cfg) => {
    const token = await localStorage.getItem('authToken');
    if (token) {
      cfg.headers.Authorization = `Bearer ${token}`;
    }
    return cfg;
  },
  (error: any) => {
    Promise.reject(error);
  }
);

axiosApiFileInstance.interceptors.response.use(
  (response) => response,
  async function (error) {
    const originalRequest = error.config;
    if (!error.response) {
      return Promise.reject('Network Error');
    }
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = localStorage.getItem('refreshToken');
      const { data } = await resetToken().post('', `grant_type=refresh_token&refresh_token=${refreshToken}`);
      originalRequest.headers.Authorization = `Bearer ${data.id_token}`;

      localStorage.setItem('authToken', data.id_token);
      localStorage.setItem('refreshToken', data.refresh_token);

      return axios(originalRequest);
    }
    return Promise.reject(error);
  }
);

export const unarmedWithToken = () => axiosApiInstance;

export const unarmedWithTokenAndDownloadFile = () => axiosApiFileInstance;

export const unarmed = (token) => {
  if (token) {
    return axios.create({
      baseURL: process.env.REACT_APP_UNARMED_ENDPOINT,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  return axios.create({
    baseURL: process.env.REACT_APP_UNARMED_ENDPOINT,
  });
};

export const unarmedFile = () =>
  axios.create({
    baseURL: process.env.REACT_APP_UNARMED_ENDPOINT,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
      ...(process.env.REACT_APP_TENANT_ID
        ? {
            'X-Tenant-Id': process.env.REACT_APP_TENANT_ID,
          }
        : {}),
    },
  });
