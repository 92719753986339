import React, { useEffect, useState } from 'react';
import { Box, Grid, makeStyles, Radio, TextField, Typography } from '@material-ui/core';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import LocationOnIcon from '@material-ui/icons/LocationOn';
import WrapperComponent from './WrapperComponent';
import Input from '../../components/Input';
import { handleChangeCheckBox, onChangeDnDValue } from '../utils/utls';

import useInput from '../../hooks/useInput';
import Settings, { CustomCheckbox } from '../utils/Settings';
import OutLineTable from '../../components/OutLineTable';
import PreviewLabel from '../utils/Label';
import HideAnswer from '../../components/HideAnswer';

const useStyles = makeStyles(() => ({
  title: {
    fontSize: 14,
    color: '#000000DE',
    marginLeft: 10,
  },
  label: {
    fontSize: 16,
    color: '#000000DE',
    fontWeight: '500',
  },

  fieldLabel: {
    fontSize: 14,
  },

  subLabel: {
    fontSize: 14,
    color: '#000000DE',
    fontWeight: '500',
  },

  settingsQuestions: {
    fontSize: 14,
    minWidth: 160,
  },
  type: {
    width: 131,
  },
  labelContainer: {
    width: 425,
    marginLeft: 20,
  },

  td: {},
}));
const columns = [
  { name: 'Type', styles: {} },
  { name: 'Label', styles: {} },
  { name: 'Visible', styles: { textAlign: 'right' } },
  { name: 'Required', styles: { textAlign: 'right' } },
];
export default function ContactInfo({
  questionBankComponent,
  id,
  componentId,
  onUpdateJson,
  onDeleteComponent,
  path,
  data,
  componentIndex,
  duplicateComponent,
  refActionModal,
  setModal,
}) {
  const classes = useStyles();
  const [rows, setRows] = useState([
    {
      type: 'Street Name',
      value: 'Street Name',
      visible: true,
      required: false,
    },
    { type: 'Apt/Unit #', value: 'Apt/Unit #', visible: true, required: false },
    {
      type: 'State',
      value: 'State',
      visible: true,
      required: false,
    },
    {
      type: 'City',
      value: 'City',
      visible: true,
      required: false,
    },
    {
      type: 'Zip Code',
      value: 'Zip Code',
      visible: true,
      required: false,
    },
  ]);

  const [checkQuestion, setCheckQuestion] = useState([!!data?.description]);
  const descriptionQuestion = useInput(data?.description || '');
  const requiredQuestionMessage = useInput(data?.requiredMessage || '');
  const question = useInput(data?.question || '');
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    if (data?.hasOwnProperty('street')) {
      const preFetchData = [
        {
          type: 'Street Name',
          value: data?.street?.text,
          visible: data?.street?.visible,
          required: data?.street?.required,
        },
        {
          type: 'Apt/Unit #',
          value: data?.aptUnit?.text,
          visible: data?.aptUnit?.visible,
          required: data?.aptUnit?.required,
        },
        {
          type: 'State',
          value: data?.state?.text,
          visible: data?.state?.visible,
          required: data?.state?.required,
        },
        {
          type: 'City',
          value: data?.city?.text,
          visible: data?.city?.visible,
          required: data?.city?.required,
        },
        {
          type: 'Zip Code',
          value: data?.zipCode?.text,
          visible: data?.zipCode?.visible,
          required: data?.zipCode?.required,
        },
      ];
      setRows(preFetchData);
    }
  }, [data]);

  const handleSaveComponent = () => {
    const dataJson = {
      question: question.value,
      ...(descriptionQuestion.value ? { description: descriptionQuestion.value } : {}),
      ...(data.dataReportLink ? { dataReportLink: data.dataReportLink } : {}),
      street: {
        text: rows[0].value,
        visible: rows[0].visible,
        required: rows[0].required,
      },
      aptUnit: {
        text: rows[1].value,
        visible: rows[1].visible,
        required: rows[1].required,
      },

      state: {
        text: rows[2].value,
        visible: rows[2].visible,
        required: rows[2].required,
      },
      city: {
        text: rows[3].value,
        visible: rows[3].visible,
        required: rows[3].required,
      },

      zipCode: {
        text: rows[4].value,
        visible: rows[4].visible,
        required: rows[4].required,
      },
    };

    onUpdateJson(dataJson);
  };

  const saveOnTimeOut = () => {
    setLoading(true);
    setTimeout(() => {
      handleSaveComponent();
      setLoading(false);
    }, 500);
  };

  const renderSettings = () => (
    <Settings
      componentId={componentId}
      componentType="addressInformation"
      defaultDataReportLink={data.dataReportLink}
      onSelectDataReportLink={(dataReportLink) => onUpdateJson({ ...data, dataReportLink })}
      questions={['description']}
      checkQuestion={checkQuestion}
      descriptionQuestion={descriptionQuestion}
      requiredQuestion={requiredQuestionMessage}
      setCheckQuestion={setCheckQuestion}
      saveOnTimeOut={saveOnTimeOut}
    />
  );

  const renderPreview = () => (
    <Box>
      <PreviewLabel question={question.value} qNumber={componentIndex + 1} />
      <Box maxWidth={600} marginTop="-15px" marginLeft="45px">
        <HideAnswer marginLeft="-7px" marginTop={0}>
          <Grid container spacing={2}>
            {rows.map((row, index) => {
              if (row.visible) {
                return (
                  <Grid item xs={6}>
                    <TextField
                      key={index}
                      label={
                        <>
                          {row.value} {row.required && <span style={{ color: 'red', fontSize: 12 }}>*</span>}{' '}
                        </>
                      }
                      variant="standard"
                      disabled
                      style={{ width: '100%' }}
                      placeholder={row.type}
                    />
                  </Grid>
                );
              }
            })}
          </Grid>
        </HideAnswer>
      </Box>
    </Box>
  );

  const renderRows = (row, index) => (
    <>
      <td className={classes.td} style={{ width: 150 }}>
        <Box display="flex" alignItems="center" justifyContent="space-between" width={130}>
          <Typography className={classes.fieldLabel}>{row.type}</Typography>
          {row.type !== 'Anonymously' && <ArrowRightAltIcon style={{ fontSize: 14 }} />}
        </Box>
      </td>
      <td className={classes.td}>
        <Box width={250}>
          {row.type !== 'Anonymously' && (
            <Input
              placeholder={row.type}
              value={row.value}
              onChange={(e) => {
                onChangeDnDValue(e, setRows, index);
                // saveOnTimeOut();
              }}
            />
          )}
        </Box>
      </td>
      <td className={classes.td}>
        <Box display="flex" justifyContent="flex-end">
          <CustomCheckbox
            color="primary"
            checked={row.visible}
            onChange={(e) => {
              handleChangeCheckBox(e, index, 'visible', rows, setRows);
              saveOnTimeOut();
            }}
          />
        </Box>
      </td>
      <td className={classes.td}>
        <Box display="flex" justifyContent="flex-end" marginRight="6px">
          <CustomCheckbox
            color="primary"
            checked={row.required}
            onChange={(e) => {
              handleChangeCheckBox(e, index, 'required', rows, setRows);
              saveOnTimeOut();
            }}
          />
        </Box>
      </td>
    </>
  );

  return (
    <WrapperComponent
      onBlur={(!questionBankComponent && saveOnTimeOut) || function () {}}
      renderSettings={renderSettings}
      componentIndex={componentIndex}
      duplicateComponent={duplicateComponent}
      isQuestionBankComponent={questionBankComponent}
      includeRequired={false}
      renderPreview={renderPreview}
      onDeleteComponent={onDeleteComponent}
      id={id}
      componentId={componentId}
      path={path}
      loading={loading}
      dataSaved={data?.question}
      refActionModal={refActionModal}
      setModal={setModal}
    >
      <Box display="flex" alignItems="center">
        <LocationOnIcon htmlColor="#6D7385" />
        <Typography className={classes.title}>Address Information</Typography>
      </Box>
      <Box display="flex" alignItems="center" marginTop="15px">
        <Typography className={classes.label}>Q{componentIndex + 1}</Typography>
        <ArrowRightAltIcon style={{ fontSize: 14, marginRight: 10 }} />
        <Input
          placeholder="Address Information"
          value={question.value}
          onChange={(e) => {
            question.onChange(e);
            saveOnTimeOut();
          }}
        />
      </Box>
      <Box marginTop="38px">
        <OutLineTable columns={columns} rows={rows} renderRows={renderRows} />
      </Box>
    </WrapperComponent>
  );
}
