import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import styled from 'styled-components';
import { Box, Typography } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from 'react-router-dom';

import upArrow from '../assets/upArrow.png';
import { Context } from '../Context';

const useStyles = makeStyles(() => ({
  button: {
    background: ' #2E66FE',
    textTransform: 'capitalize',
    color: '#fff',
    padding: 15,
    cursor: 'pointer',
    borderRadius: '4px',
    '&:hover': {
      background: '#2E66FE',
    },
    '& .MuiButton-label': {
      fontSize: 14,
    },
    marginTop: 10,
    marginBottom: 20,
    fontSize: 14,
    fontWeight: '500',
  },
  labelError: {
    fontSize: 12,
    color: 'red',
  },

  circle: {
    width: 76,
    height: 76,
    borderRadius: '50%',
    border: '3px solid #FEB535',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 25,
    flexDirection: 'column',
  },
}));

function UpgradeModal({
  open,
  callback,
  type = 'warning',
  title = 'Upgrade Required!',
  description = (
    <>
      This feature is not included in your current Sivil subscription. Please upgrade your account to access additional
      tools. Contact us at <a href="mailto:hello@sivilco.com">hello@sivilco.com</a>
    </>
  ),
}) {
  const classes = useStyles();
  const { setActive } = useContext(Context);
  const history = useHistory();

  const renderIcon = () => {
    switch (type) {
      case 'warning':
        return (
          <Box className={classes.circle}>
            <img src={upArrow} style={{ width: 30 }} alt="upArrow" />
            <Box width="15px" height="5px" style={{ background: '#FEB535' }} />
          </Box>
        );
      case 'danger':
        return <ErrorOutlineIcon htmlColor="red" style={{ fontSize: 75, marginBottom: 10 }} />;

      default:
        return (
          <Box className={classes.circle}>
            <img src={upArrow} style={{ width: 30 }} alt="upArrow" />
            <Box width="15px" height="5px" style={{ background: '#FEB535' }} />
          </Box>
        );
    }
  };

  return (
    <>
      <Popup
        onClose={() => {
          if (typeof callback === 'function') {
            callback();
          } else {
            history.push('/');
            setActive('/');
          }
        }}
        aria-labelledby="simple-dialog-title"
        open={open}
        className={classes.modal}
      >
        <CloseIcon
          style={{
            position: 'absolute',
            top: 10,
            right: 10,
            cursor: 'pointer',
          }}
          htmlColor="#6D7385"
          onClick={() => {
            if (typeof callback === 'function') {
              callback();
            } else {
              history.push('/');
              setActive('/');
            }
          }}
        />
        <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column" padding="20px">
          {renderIcon()}
          <Typography style={{ fontSize: 18, fontWeight: '500', marginBottom: 10 }} align="center">
            {title}
          </Typography>
          <Typography
            style={{
              fontSize: 14,
              width: '100%',
              maxWidth: 570,
              marginBottom: 5,
            }}
            align="center"
          >
            {description}
          </Typography>

          {/* <Button variant="contained" className={classes.button}>
            UPGRADE
          </Button> */}
          <a href="mailto:hello@sivilco.com" target="_blank" without rel="noreferrer" className={classes.button}>
            UPGRADE
          </a>
        </Box>
      </Popup>
    </>
  );
}

const Popup = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    max-width: 450px !important;
    width: 100%;
  }
`;

export default UpgradeModal;
