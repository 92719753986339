// Setting up Sentry
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import React from 'react';
import ReactDOM from 'react-dom';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from '@material-ui/core';

import App from './App';
import Context from './Context';
import GlobalStyles, { globalTheme } from './styles/GlobalStyles';
import 'react-toastify/dist/ReactToastify.css';
import './i18n/i18n';
import TagsProvider from './provider/TagsProvider';
import AccordionProvider from './provider/AccordionProvider';
import DataReportLinkProvider from './provider/DataReportLinkProvider';

Sentry.init({
  dsn: 'https://acfc3be5e0f747c8a8a58accbf628a86@o4504260247683072.ingest.sentry.io/4504406989799424',
  integrations: [new BrowserTracing()],
  environment: process.env.REACT_APP_ENV,
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <Context.Provider>
    <GlobalStyles />
    <ThemeProvider theme={globalTheme}>
      <AccordionProvider>
        <TagsProvider>
          <DataReportLinkProvider>
            <App />
          </DataReportLinkProvider>
        </TagsProvider>
      </AccordionProvider>
    </ThemeProvider>
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  </Context.Provider>,
  document.getElementById('root')
);
