import React from 'react';
import GridLayout from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import '../styles/components/modularComponents.css';
import { updateDashboardReportSize } from '../services/unarmed';

export const ModularComponents = ({ layout, width }) => (
  <div>
    <GridLayout
      className="layout"
      layout={layout}
      cols={12}
      rowHeight={30}
      width={width}
      isResizable
      isDraggable
      compactType={null}
      preventCollision={false}
      draggableCancel=".no-drag"
      onResizeStop={(newItem) => {
        newItem.forEach((dashboardR) => {
          updateDashboardReportSize(dashboardR.i, {
            w: dashboardR.w,
            h: dashboardR.h,
          })
            .then((response) => {
              console.log('Size updated successfully:', response);
            })
            .catch((error) => {
              console.error('Error updating size:', error);
            });
        });
      }}
      onDragStop={(newItem) => {
        newItem.forEach((dashboardR) => {
          updateDashboardReportSize(dashboardR.i, {
            x: dashboardR.x,
            y: dashboardR.y,
          })
            .then((response) => {
              console.log('Position updated successfully:', response);
            })
            .catch((error) => {
              console.error('Error updating position:', error);
            });
        });
      }}
    >
      {layout.map((component) => (
        <div key={component.i} className="layout-item">
          {component.content}
        </div>
      ))}
    </GridLayout>
  </div>
);
export default ModularComponents;
