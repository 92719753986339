import { Box } from '@material-ui/core';
import React from 'react';
import Log from '../../components/Log';

export default function History({ log = [], loading }) {
  return (
    <Box
      style={{ background: '#fff', borderRadius: 5 }}
      boxShadow="0 3px 6px #00000005"
      width="100%"
      maxWidth="800px"
      height="fit-content"
      padding="10px"
    >
      {log?.map((logItem, i) => (
        <Log {...logItem} key={i} />
      ))}
    </Box>
  );
}
