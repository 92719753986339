/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Avatar, Box, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import profileImg from '../assets/profile.png';

const useStyles = makeStyles((theme) => ({
  assignedAvatar: {
    width: 40,
    height: 40,
    border: '1px dashed #585858',
    background: '#F8F8F9',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
  userItem: {
    padding: 10,
    cursor: 'pointer',
  },
  usersContainer: {
    position: 'absolute',
    width: 300,
    top: 80,
    left: 0,
    background: '#fff',
    zIndex: 2,
  },
  backdrop: {
    zIndex: 1,
    position: 'fixed',
    height: '116.1vh',
    width: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  closeIcon: {
    position: 'absolute',
    top: ' -5px',
    right: ' 4px',
    zIndex: 1,
    cursor: 'pointer',
    transition: 'ease all .3s',
    fontSize: '13px',
    background: '#364F74',
    borderRadius: '50%',
    color: '#fff',
    padding: 3,
    opacity: 0,
  },
  containerAvatar: {
    '&:hover svg': {
      opacity: 1,
    },
  },
}));

const UserDropdown = ({ onSelectUser, usersFiltered = [], showUnassigned, onClose }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.backdrop} onClick={onClose} />
      <Box boxShadow={1} className={classes.usersContainer}>
        <Box maxHeight="300px" style={{ overflowY: 'auto' }}>
          {showUnassigned && (
            <Box display="flex" alignItems="center" className={classes.userItem} onClick={() => onSelectUser('null')}>
              <Avatar src={profileImg} style={{ marginRight: 10 }} />
              <Box>
                <Typography variant="body1" style={{ fontSize: 13 }}>
                  Unassigned
                </Typography>
              </Box>
            </Box>
          )}
          {usersFiltered.length > 0 ? (
            <>
              {usersFiltered.map((user, i) => (
                <Box
                  display="flex"
                  alignItems="center"
                  className={classes.userItem}
                  key={i}
                  onClick={() => onSelectUser(user, i)}
                >
                  <Avatar src={user?.photo?.url || user?.profile_img || profileImg} style={{ marginRight: 10 }} />
                  <Box>
                    <Typography variant="body1" style={{ fontSize: 13 }}>
                      {user?.firstName ? `${user?.firstName} ${user?.lastName} ` : `${user?.username || ''}`}
                    </Typography>
                    <Typography variant="body1" style={{ fontSize: 13 }}>
                      {user.email}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </>
          ) : (
            <>
              {!showUnassigned && (
                <Box display="flex" justifyContent="center" padding={1}>
                  <Typography>No users found.</Typography>
                </Box>
              )}
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

UserDropdown.propTypes = {
  onSelectUser: PropTypes.object,
  usersFiltered: PropTypes.array,
  onClose: PropTypes.func,
  showUnassigned: PropTypes.bool,
};

export default UserDropdown;
