/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Box, CircularProgress, Tabs, Typography, makeStyles, Button, FormControlLabel } from '@material-ui/core';
import { CustomSwitch } from '../../formEngine/components/WrapperComponent';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { TabComp } from '../CaseDetails/CaseDetails';
import TabPanel from '../../components/TabPanel';
import ContactInfo from './ContactInfo';
import {
  createContactComment,
  editContactComment,
  fetchContact,
  fetchContactCases,
  fetchContactNotes,
  fetchContactProjects,
  fetchContactStats,
  onArchiveNoteContact,
  onUnArchiveNoteContact,
  updateNotesSortOrder,
} from '../../services/unarmed';
import { CaseRenderRow, TransferredFrom, TransferredTo } from '../Cases/Cases';
import TableContainer from '../../components/TableContainer';
import { ProjectRenderRow } from '../Projects/Projects';
import { NoteLog } from '../../components/NoteLogs';
import CaseComment from '../CaseDetails/CaseComment';
import useOrganization from '../../hooks/useOrganization';
import { axiosCatchError } from '../../utils';
import ContactAttachments from './ContactAttachments';
import ContactHistory from './ContactHistory';
import { Context } from '../../Context';
import { downloadContactImage, updateUrlWithParams } from '../../utils/libs';
import Card from '../../components/Card';
import { BoxLeftContainer } from '../../styles/GlobalStyles';

const useStyles = makeStyles(() => ({
  button: {
    background: ' #3B70FE',
    textTransform: 'capitalize',
    color: '#fff',
    '&:hover': {
      background: '#3B70FE',
    },
    '& .MuiButton-label': {
      fontSize: 14,
    },
  },
}));

const casesColumns = ['Type', 'Tracking number', 'Created Date', 'Name', 'Assigned To', 'Status'];
const visibleColumns={type: true, trackingNumber: true, createdDate: true, name: true, assignedTo: true, status: true}

const projectsColumns = ['Type', 'Subtype', 'Number', 'Name', 'Created Date', 'Created by'];

const tabs = [
  { label: 'Cases', value: 0 },
  { label: 'Projects', value: 1 },
  { label: 'Notes', value: 2 },
  { label: 'Attachments', value: 4 },
  { label: 'History', value: 5 },
];

/**
 * React component for displaying the profile of a contact, including details, cases, projects, notes, attachments, and history.
 *
 * @component
 */
const ContactProfile = () => {
  const [contact, setContact] = useState();
  const [value, setValue] = useState(0);
  const [notesShow, setNotesShow] = useState([]);
  const [archivedOnly, setArchivedOnly] = useState(false);
  const [cases, setCases] = useState([]);
  const [projects, setProjects] = useState([]);
  const [loadingCases, setLoadingCases] = useState(false);
  const [loadingProjects, setLoadingProjects] = useState(false);
  const [stats, setStats] = useState({
    cases: 0,
    projects: 0,
    events: 0,
  });

  const getCanDelete = (user) => {
    if(myUser.role === 'admin' && organization?.permissions?.[myUser?.role]?.canDeleteNotesContacts !== 0 ){
      return true
    }else if(organization?.permissions?.[myUser?.role]?.canDeleteNotesContacts !== 0 && (myUser._id === user._id)  ){
      return true
    }else{
      return false
    }
  }
  const [notes, setNotes] = useState([]);
  const [editingNote, setEditingNote] = useState();
  const { organization } = useOrganization();
  const { id } = useParams();
  const [openEditModal, setOpenEditModal] = useState(false);

  const { myUser } = useContext(Context);

  const initialSortOrder = localStorage.getItem('contactNotesSortOrder') || (myUser?.notesSortOrderDesc?.contacts ? 'desc' : 'asc');
  const [sortOrder, setSortOrder] = useState(initialSortOrder);

  const classes = useStyles();

  const handleArchiveOnly = () => {
    setArchivedOnly(!archivedOnly)
  }

  useEffect(() => {
    if(archivedOnly) {
      const newLogs = notes.filter(log => log.hasOwnProperty('archivedDate') && log.archivedDate !== null);
      setNotesShow(newLogs)
    }else{
      const newLogsWithoutArchivedDate = notes.filter(log => !log.hasOwnProperty('archivedDate'));
      setNotesShow(newLogsWithoutArchivedDate)
    }

  }, [notes, archivedOnly])

  /**
   * Handles the change of tabs.
   *
   * @param {Event} event - The event object.
   * @param {number} newValue - The new value (index) of the selected tab.
   */
  const handleChange = (event, newValue) => {
    const tabData = tabs[newValue];
    const tabLabel = tabData.label?.toLowerCase()?.replace(/ /g, '-');

    setValue(newValue);
    updateUrlWithParams(
      {
        organization_name: organization.name,
        tab_name: tabLabel,
      },
      {
        tab_name: tabLabel,
      }
    );
  };

  const sortByCreatedAt = (data, order = 'desc') => {
    return data.sort((x, y) => {
      const dateX = new Date(x.createdAt).getTime();
      const dateY = new Date(y.createdAt).getTime();

      if (order === 'asc') {
        return dateX - dateY;
      }
      return dateY - dateX;
    });
  };

  const toggleSortOrder = () => {
    const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    const updatedSortOrder = newSortOrder === 'desc';

    updateNotesSortOrder(myUser?._id, 'contacts', updatedSortOrder)
      .then(() => {
        setSortOrder(newSortOrder);
        localStorage.setItem('contactNotesSortOrder', newSortOrder);
      })
      .catch((error) => {
        toast.error('Failed to update sort order');
      });
  };

  /**
   * Provides accessibility props for a tab.
   *
   * @param {number} index - The index of the tab.
   * @returns {Object} Accessibility props for the tab.
   */
  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

  /**
   * Updates the contact information.
   */
  const onUpdateContactInfo = useCallback(() => {
    fetchContact(id).then((res) => {
      setContact(res.data);
    });
  }, [id]);

  /**
   * Renders a row for a case in the table.
   *
   * @param {Object} row - The case data.
   * @param {number} index - The index of the row.
   * @param {Function} handleClick - The function to handle row click.
   * @returns {JSX.Element} The rendered row.
   */
  const renderRowCase = (row, index, handleClick) => {
    const labelId = `enhanced-table-checkbox-${index}`;
    const description = [
      row.archived ? 'Archived' : null,
      row.transferred && row.referral?.previous?.organization ? (
        <TransferredFrom referralOrg={row.referral.previous.organization} />
      ) : null,
      row.transferred && row.referral?.next?.organization ? (
        <TransferredTo referralOrg={row.referral.next.organization} />
      ) : null,
    ]
      .filter(Boolean)
      .flatMap((_value, _index, array) => {
        if (_index < array.length - 1) {
          return [_value, ' - '];
        }

        return [_value];
      });

    return (
      <CaseRenderRow
        row={row}
        index={index}
        description={description}
        labelId={labelId}
        handleClick={handleClick}
        isItemSelected={false}
        visibleColumns={visibleColumns}
      />
    );
  };

  /**
   * Renders a row for a project in the table.
   *
   * @param {Object} row - The project data.
   * @param {number} index - The index of the row.
   * @param {Function} handleClick - The function to handle row click.
   * @returns {JSX.Element} The rendered row.
   */
  const renderRowProject = (row, index, handleClick) => {
    const labelId = `enhanced-table-checkbox-${index}`;

    return (
      <ProjectRenderRow index={index} row={row} labelId={labelId} handleClick={handleClick} isItemSelected={false} />
    );
  };

  /**
   * Fetches notes for the contact.
   */
  const handleOnFetchNotes = () => {
    fetchContactNotes(id).then((res) => {
      setNotes(sortByCreatedAt(res.data, sortOrder));
    });
  };

  useEffect(() => {
    onUpdateContactInfo();
  }, [id, onUpdateContactInfo]);

  useEffect(() => {
    if (contact) {
      setLoadingCases(true);
      fetchContactCases(contact?._id).then((res) => {
        setLoadingCases(false);
        setCases(res.data);
      });

      setLoadingProjects(true);
      fetchContactProjects(contact?._id).then((res) => {
        setLoadingProjects(false);
        setProjects(res.data);
      });

      fetchContactStats(contact?._id).then((res) => {
        setStats(res.data);
      });

      handleOnFetchNotes();
    }
  }, [contact]);

  useEffect(() => {
    handleOnFetchNotes();
  }, [sortOrder, id]);

  useEffect(() => {
    if (organization) {
      updateUrlWithParams(
        {
          organization_name: organization?.name,
          tab_name: 'cases',
        },
        {
          tab_name: 'cases',
        }
      );
    }
  }, [organization]);

  return (
    <Box padding={2}>
      <Box bgcolor="#fff" boxShadow="0 4px 8px #eee" borderRadius={4}>
        <Box
          width="100%"
          display="flex"
          padding={2}
          alignItems="center"
          borderBottom="1px solid #0000001F"
          justifyContent="space-between"
        >
          <Typography variant="h5">Contact Profile</Typography>
          <Button
            data-testid="contact-detail-button-edit"
            onClick={() => setOpenEditModal(true)}
            color="primary"
            variant="contained"
            className={classes.button}
          >
            Edit Profile
          </Button>
        </Box>
        {!contact ? (
          <Box display="flex" justifyContent="center" alignItems="center" paddingY={4}>
            <CircularProgress />
          </Box>
        ) : (
          <ContactInfo
            openEditModal={openEditModal}
            onCloseEditModal={() => setOpenEditModal(false)}
            contact={contact}
            stats={stats}
            onUpdate={onUpdateContactInfo}
            onClickCard={(tab) => {
              if (tab === 'cases') {
                setValue(0);
              }

              if (tab === 'projects') {
                setValue(1);
              }
            }}
          />
        )}
        <Box paddingX={2}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
            indicatorColor="primary"
            textColor="primary"
          >
            {tabs.map((tab, i) => (
              <TabComp
                data-testid={`contact-details-tab-${tab.label.toLowerCase()}`}
                label={tab.label}
                key={i}
                {...a11yProps(tab.value)}
              />
            ))}
          </Tabs>
        </Box>
      </Box>
      <TabPanel value={value} padding={0} index={0}>
        <Box width="100%" bgcolor="#fff" boxShadow="0 4px 8px #eee" mt={2} borderRadius={8}>
          <TableContainer columns={casesColumns} data={cases} loading={loadingCases} onRenderRow={renderRowCase} />
        </Box>
      </TabPanel>
      <TabPanel value={value} padding={0} index={1}>
        <Box width="100%" bgcolor="#fff" boxShadow="0 4px 8px #eee" mt={2} borderRadius={8}>
          <TableContainer
            columns={projectsColumns}
            data={projects}
            loading={loadingProjects}
            onRenderRow={renderRowProject}
          />
        </Box>
      </TabPanel>
      <TabPanel value={value} padding={0} index={2}>
        <Box display="flex" marginTop={2}>
          <Card
          title={
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Box>
                <Typography variant="h6">Notes</Typography>
              </Box>
              <Box style={{ display: 'flex'}}>
                <FormControlLabel
                  control={
                    <CustomSwitch
                      checked={sortOrder === 'desc'}
                      onChange={toggleSortOrder}
                      color="primary"
                    />
                  }
                  label={sortOrder === 'desc' ? 'Most Recent' : 'Oldest'}
                  labelPlacement="end"
                  style={{ marginLeft: '500px' }}
                />
                  <FormControlLabel
                      style={{ margin: 0 }}
                      control={<CustomSwitch checked={archivedOnly} onChange={handleArchiveOnly} />}
                      labelPlacement="end"
                      label="Archived only"
                    />
              </Box>

            </Box>
          }>
            {!notesShow?.length ? (
              <Typography style={{ textAlign: 'center', fontWeight: 'bold' }}>
                No notes added to this contact
              </Typography>
            ) : null}
            {notesShow.map((note) => {
              return(
              <NoteLog
                archivedDate={note?.archivedDate}
                handleDeleteNote={async() => {
                  await onArchiveNoteContact(id, note?._id)
                  fetchContactNotes(id).then((res) => {
                    setNotes(sortByCreatedAt(res.data, sortOrder));
                  });
                }}
                handleRestoreClick={async() => {
                  await onUnArchiveNoteContact(id, note?._id)
                  fetchContactNotes(id).then((res) => {
                    setNotes(sortByCreatedAt(res.data, sortOrder));
                  });
                }}
                key={note?._id}
                _id={note?._id}
                createdAt={note?.createdAt}
                files={note?.files}
                comment={note?.comment}
                user={note?.user}
                userInfo={note?.userInfo}
                updatedAt={note?.updatedAt}
                subject="contact"
                canEdit={note?.user?._id === myUser?._id}
                canDelete={getCanDelete(note?.user)}
                onEditClick={(item) => setEditingNote(item)}
                editing={editingNote?._id === note?._id}
                onCancelEditingClick={() => setEditingNote(undefined)}
                onDownloadImage={(file) => downloadContactImage(id, file?._id, file?.name)}
              />
            )})}
          </Card>
          <CaseComment
            organizationId={organization?._id}
            setIsRequestingData={() => {}}
            callBack={async (filesHash, comment, resetValues, canceled = false) => {
              if (!canceled) {
                if (editingNote) {
                  await editContactComment(contact?._id, editingNote?._id, {
                    comment,
                  }).catch(axiosCatchError);
                } else {
                  await createContactComment(contact?._id, {
                    ...(comment ? { comment } : {}),
                    files: filesHash,
                  }).catch(axiosCatchError);
                }

                handleOnFetchNotes();
              }

              resetValues();
              setEditingNote(undefined);
            }}
            editingLog={editingNote}
          />
        </Box>
      </TabPanel>
      <TabPanel value={value} padding={0} index={3}>
        <ContactAttachments id={contact?._id} onAddNewAttachment={handleOnFetchNotes} />
      </TabPanel>
      <TabPanel value={value} padding={0} index={4}>
        <BoxLeftContainer marginTop={2}>
          <Card title="History">
            <ContactHistory id={contact?._id} />
          </Card>
        </BoxLeftContainer>
      </TabPanel>
    </Box>
  );
};

export default ContactProfile;
