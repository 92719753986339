import React from 'react';
import { Box, CircularProgress, Grid, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Carousel from 'react-material-ui-carousel';
import GetAppIcon from '@material-ui/icons/GetApp';
import ReactPlayer from 'react-player';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import { downloadImage } from '../utils/libs';
import ModalPopUp from './ModaPopupl';
import Tags from './Tags';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 4,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 4, 4),
  },
  imgModal: {
    objectFit: 'contain',
    width: '100%',
    maxHeight: 360,
    borderRadius: 4,
    border: '1px solid rgba(0, 0, 0, 0.1)',
  },
  link: {
    display: 'flex',
    position: 'absolute',
    top: 10,
    right: 80,
    cursor: 'pointer',
    padding: 13,
    zIndex: 999999,
    borderRadius: '50%',
    background: '#494949',
  },
  fileName: {
    textAlign: 'left',
    fontSize: 20,
    fontWeight: 600,
  },
}));

export default function CarouselModal({
  open,
  setOpen,
  filesSelected,
  loadingOpenFiles,
  setLoadingOpenFiles,
  onDownloadImage,
}) {
  const classes = useStyles();
  const downloadAsset = async (file, index) => {
    setLoadingOpenFiles((prevState) => {
      const stateCopy = [...prevState];
      stateCopy[index].loading = true;
      return stateCopy;
    });
    if (onDownloadImage) {
      await onDownloadImage(file);
    } else {
      await downloadImage(file.download_url, file.name).catch((err) => {
        toast.error('Something went wrong downloading the file.');
      });
    }

    setLoadingOpenFiles((prevState) => {
      const stateCopy = [...prevState];
      stateCopy[index].loading = false;
      return stateCopy;
    });
  };

  return (
    <ModalPopUp open={open} setOpen={setOpen} allowBg={false} maxWidth={774}>
      <Box position="relative">
        <Box width="774px">
          <Carousel
            autoPlay={false}
            navButtonsAlwaysVisible
            navButtonsWrapperProps={{
              className: 'Custom-CarouselModal-Btns',
            }}
            className="Custom-CarouselModal"
            index={filesSelected.selectedFileIndex}
          >
            {filesSelected.files.map((file, index) => {
              if (file.mimetype.includes('image')) {
                return (
                  <Box
                    key={index}
                    display="flex"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    flexDirection="column"
                    className={classes.paper}
                  >
                    <Grid container alignItems="center" justifyContent="space-between">
                      <Grid item xs={8}>
                        <h6 className={classes.fileName} data-testid={`file-name-${index}`}>
                          {file.name}
                        </h6>
                      </Grid>
                      <Grid item xs={4}>
                        <Box display="flex" justifyContent="flex-end">
                          {loadingOpenFiles[index]?.loading ? (
                            <CircularProgress
                              size={20}
                              style={{ color: 'rgba(48, 48, 48, 1)' }}
                              data-testid={`loading-${index}`}
                            />
                          ) : !file.archivedDate ? (
                            <GetAppIcon
                              onClick={() => downloadAsset(file, index)}
                              style={{ cursor: 'pointer' }}
                              htmlColor="rgba(48, 48, 48, 1)"
                              data-testid={`download-icon-${index}`}
                            />
                          ) : null}
                          <CloseIcon
                            style={{
                              cursor: 'pointer',
                              marginLeft: 8,
                            }}
                            onClick={() => setOpen(false)}
                            htmlColor="rgba(48, 48, 48, 1)"
                            data-testid="close-icon"
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Box width="100%" display="flex" justifyContent="center" alignItems="center" mt={1} mb={1}>
                      <img
                        src={file.public_url}
                        className={classes.imgModal}
                        alt={file.name}
                        data-testid={`image-${index}`}
                      />
                    </Box>
                    <Tags fileId={file?._id} defaultTags={file?.tags || []} />
                  </Box>
                );
              }

              if (file.mimetype.includes('video') || file.mimetype.includes('audio')) {
                return (
                  <Box
                    className={classes.paper}
                    display="flex"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    flexDirection="column"
                    key={index}
                  >
                    <Grid container alignItems="center" justifyContent="space-between">
                      <Grid item xs={8}>
                        <h6 className={classes.fileName} data-testid={`file-name-${index}`}>
                          {file.name}
                        </h6>
                      </Grid>
                      <Grid item xs={4}>
                        <Box display="flex" justifyContent="flex-end" alignItems="center">
                          {loadingOpenFiles[index]?.loading ? (
                            <CircularProgress
                              size={20}
                              style={{ color: 'rgba(48, 48, 48, 1)' }}
                              data-testid={`loading-${index}`}
                            />
                          ) : (
                            <GetAppIcon
                              onClick={() => downloadAsset(file, index)}
                              style={{ cursor: 'pointer' }}
                              htmlColor="rgba(48, 48, 48, 1)"
                              data-testid={`download-icon-${index}`}
                            />
                          )}
                          <CloseIcon
                            style={{
                              cursor: 'pointer',
                              marginLeft: 8,
                            }}
                            onClick={() => setOpen(false)}
                            htmlColor="rgba(48, 48, 48, 1)"
                            data-testid="close-icon"
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Box display="flex" justifyContent="center" alignItems="center">
                      <ReactPlayer
                        url={file.public_url}
                        controls
                        stopOnUnmount={false}
                        key={index}
                        onEnablePIP={false}
                        pip={false}
                        data-testid={`react-player-${index}`}
                      />
                    </Box>
                    <Tags fileId={file?._id} defaultTags={file?.tags || []} />
                  </Box>
                );
              }

              return null;
            })}
          </Carousel>
        </Box>
      </Box>
    </ModalPopUp>
  );
}

CarouselModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onDownloadImage: PropTypes.func.isRequired,
  filesSelected: PropTypes.shape({
    length: PropTypes.number.isRequired,
    selectedFileIndex: PropTypes.number.isRequired,
    files: PropTypes.arrayOf(
      PropTypes.shape({
        download_url: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        mimetype: PropTypes.string.isRequired,
        public_url: PropTypes.string.isRequired,
        tags: PropTypes.arrayOf(PropTypes.string),
      })
    ),
  }).isRequired,
  loadingOpenFiles: PropTypes.arrayOf(PropTypes.object).isRequired,
  setLoadingOpenFiles: PropTypes.func.isRequired,
};
