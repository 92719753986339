import React, { useState } from 'react';
import { Button, Modal, Box, Typography } from '@material-ui/core';

export const CustomModalDelete = ({ open, handleClose, fileName, onConfirm }) => {
  const stripHTML = (str) => {
    if (!str) return '';
    const plainText = str.replace(/<\/?[^>]+(>|$)/g, '');
    return plainText.length > 500 ? `${plainText.slice(0, 500)}...` : plainText;
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="confirmation-modal"
      aria-describedby="confirmation-to-delete-file"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography variant="h6" id="confirmation-modal">
          Are you sure you want to delete {fileName ? `"${stripHTML(fileName)}"` : 'this note'}?
        </Typography>
        <span>Notes will be archived for 30 days before being permanently deleted</span>
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            variant="contained"
            color="primary"
            style={{ marginRight: 2, textTransform: 'none' }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            style={{ marginLeft: 5, textTransform: 'none'}}
            variant="contained"
            color="primary"
            onClick={() => {
              onConfirm();
              handleClose();
            }}
          >
            Confirm
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
