/* eslint-disable react/jsx-props-no-spreading */
import React, { Fragment } from 'react';
import DropZone from '../utils/DropZone';

/**
 * @description This function is used to map the config with the props of the component.
 * @param {Array} config - The config of the component.
 */
const mapPropsToConfig = (config) => {
  const configWithProps = [];
  config.forEach((item) => {
    if (item.component) {
      const { component, ...props } = item;

      configWithProps.push({
        ...props,
        Component: component,
      });
    }
  });

  return configWithProps;
};

/**
 * @description This component is used to render the components recursively, also it render the DropZone component to allow the drag and drop functionality. for more information about the drag and drop functionality, please check this link: https://react-dnd.github.io/react-dnd/about and for more information of recursion, please check this link: https://developer.mozilla.org/en-US/docs/Glossary/Recursion
 * @param {Array} config - The config of the component.
 * @param {Function} handleDrop - The function to handle the drop event.
 * @param {String} path - The path of the component.
 * @param {Function} duplicateComponent - The function to duplicate a component.
 */
export const Renderer = ({ config, handleDrop, path, duplicateComponent }) => {
  if (!config) {
    throw new Error('You are calling Renderer with no config.');
  }

  const configWithProps = mapPropsToConfig(config);

  /**
   *
   * @description This function is used to render the components with the drag and drop functionality.
   */
  const renderComponentsWithDnD = (items) => (
    <>
      {items.map((item, index) => {
        const { Component, id, itemId, ...props } = item;
        const currentPath = `${path}-${index}`;
        return (
          <Fragment key={id}>
            <DropZone
              data={{
                path: currentPath,
                childrenCount: items.length,
              }}
              onDrop={handleDrop}
            />
            <Component
              duplicateComponent={duplicateComponent}
              {...props}
              path={currentPath}
              componentId={id}
              id={index}
            />
          </Fragment>
        );
      })}

      <DropZone
        data={{
          path: `${path}-${items.length}`,
          childrenCount: items.length,
        }}
        onDrop={handleDrop}
        isLast
      />
    </>
  );

  /**
   *
   * @description This function is used to render the components without the drag and drop functionality.
   */
  const renderComponents = (items, index) =>
    items.map((item, itemIndex) => {
      const currentPath = `${path}-${index}`;
      const { Component, id, ...props } = item;

      return <Component {...props} path={currentPath} componentId={id} id={id} key={itemIndex} />;
    });

  if (typeof handleDrop === 'function') {
    return renderComponentsWithDnD(configWithProps);
  }

  return renderComponents(configWithProps);
};
