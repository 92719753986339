import { Box, Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

import DashButton from './DashButton';
import FilterButton from './FilterButton';

const useStyles = makeStyles(() => ({
  title: {
    marginTop: 50,
  },
}));

/**
 * PageButtons Component
 *
 * This component represents a set of action buttons typically used in a page header or toolbar.
 * It includes a title, filter button, delete button, and download button.
 *
 * @component
 *
 * @param {Object} props - The properties of the component.
 * @param {string} props.text - The title text to be displayed.
 * @param {number} [props.length=0] - The length or count associated with the displayed title.
 * @param {boolean} props.openFilter - A boolean indicating whether the filter popover is open.
 * @param {Function} props.setOpenFilter - A function to toggle the state of the filter popover.
 * @param {Function} props.onClickDelete - A function to be executed when the delete button is clicked.
 * @param {boolean} props.disabledDelete - A boolean indicating whether the delete button is disabled.
 * @param {Function} props.onClickDownload - A function to be executed when the download button is clicked.
 * @param {boolean} props.disabledDownload - A boolean indicating whether the download button is disabled.
 *
 * @returns {JSX.Element} The rendered PageButtons component.
 *
 * @example
 * // Example usage of PageButtons component
 * <PageButtons
 *   text="List of Items"
 *   length={10}
 *   openFilter={false}
 *   setOpenFilter={handleFilterToggle}
 *   onClickDelete={handleDeleteClick}
 *   disabledDelete={false}
 *   onClickDownload={handleDownloadClick}
 *   disabledDownload={true}
 * />
 */
const PageButtons = ({
  text = '',
  length = 0,
  openFilter,
  setOpenFilter,
  onClickDelete,
  disabledDelete,
  downloadButtonText = 'Download',
  onClickFlag,
  disabledFlag,
  onClickDownload,
  disabledDownload,
  filterButtonDataTestId,
  deleteButtonDataTestId,
  downloadButtonDataTestId,
  filterCount = 0,
}) => {
  const classes = useStyles();

  return (
    <Box display="flex" justifyContent="space-between" alignItems="flex-end">
      {text!=='' && (
       <Typography className={classes.title} variant="subtitle2" id="tableTitle" component="div">
        {text} ({length})
      </Typography>
      )}
      <Box display="flex" alignItems="center">
        <FilterButton
          count={filterCount}
          dataTestId={filterButtonDataTestId}
          openFilter={openFilter}
          onClick={() => setOpenFilter(!openFilter)}
          marginRight="10px"
        />
        <DashButton
          dataTestId={deleteButtonDataTestId}
          onClick={onClickFlag}
          disabled={disabledFlag}
          marginRight="10px"
        >
          Flag
        </DashButton>
        <DashButton
          dataTestId={deleteButtonDataTestId}
          onClick={onClickDelete}
          disabled={disabledDelete}
          marginRight="10px"
        >
          Delete
        </DashButton>
        <DashButton
          dataTestId={downloadButtonDataTestId}
          onClick={onClickDownload}
          disabled={disabledDownload}
          marginRight="10px"
        >
          {downloadButtonText}
        </DashButton>
      </Box>
    </Box>
  );
};

PageButtons.propTypes = {
  text: PropTypes.string,
  length: PropTypes.number,
  openFilter: PropTypes.bool,
  setOpenFilter: PropTypes.func,
  onClickDelete: PropTypes.func,
  disabledDelete: PropTypes.bool,
  onClickFlag: PropTypes.func,
  disabledFlag: PropTypes.bool,
  onClickDownload: PropTypes.func,
  disabledDownload: PropTypes.bool,
  downloadButtonText: PropTypes.string,
  downloadButtonDataTestId: PropTypes.string,
  filterButtonDataTestId: PropTypes.string,
  deleteButtonDataTestId: PropTypes.string,
  filterCount: PropTypes.number,
};

export default PageButtons;
