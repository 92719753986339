import { Box, Typography, Button, MenuItem, TextField, Menu, ListItemIcon, Divider, Snackbar } from '@material-ui/core';
import React, { useEffect, useState, useRef } from 'react';
import PublicIcon from '@material-ui/icons/Public';
import CreateIcon from '@material-ui/icons/Create';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DashboardIcon from '@material-ui/icons/Dashboard';
import { OutLineInput } from '../CaseDetails/UpdateCase';
import {
  addReportInToDashboard,
  getReportsExplore,
  getDashboardReports,
  updateDashboard,
} from '../../services/unarmed';
import DashboardReports from './DashboardReports';

export default function DashboardProfile({ dashboard, dashboardId }) {
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredReports, setFilteredReports] = useState([]);
  const [dashboardReports, setDashboardReports] = useState({ data: [], headers: {} });
  const [reportsMenu, setReportsMenu] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [isEditingName, setIsEditingName] = useState(false);
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [dashboardName, setDashboardName] = useState('');
  const [dashboardDescription, setDashboardDescription] = useState('');
  const [dashboardLoading, setDashboardLoading] = useState(true);
  const [dashboardIdModular, setDashboardIdModular] = useState(dashboardId);
  const [refreshTrigger, setRefreshTrigger] = useState(false);
  const editBoxRef = useRef(null);
  const [key, setKey] = useState(0);

  useEffect(() => {
    if (dashboard) {
      setDashboardLoading(true);
      setDashboardName(dashboard?.dashboard?.name || '');
      setDashboardDescription(dashboard?.dashboard?.description || '');
      setDashboardLoading(false);
    }
  }, [dashboard, dashboardId]);

  useEffect(() => {
    let isMounted = true;

    const fetchDashboardReports = async () => {
      if (!dashboardId) return;

      try {
        setLoading(true);
        const response = await getDashboardReports(dashboardId);

        if (isMounted) {
          const { data } = response;
          setDashboardReports(Array.isArray(data) ? data : []);
        }
      } catch (error) {
        console.error('Error fetching dashboard reports:', error);
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    setDashboardReports({ data: [], headers: {} });

    if (dashboardId) {
      fetchDashboardReports();
    }

    return () => {
      isMounted = false;
    };
  }, [dashboardId]);

  useEffect(() => {
    const fetchAllReports = async () => {
      setLoading(true);
      let allReports = [];
      let page = 1;
      let hasMorePages = true;

      try {
        while (hasMorePages) {
          const response = await getReportsExplore(page);
          const { data } = response;

          allReports = [...allReports, ...data];

          if (data.length < 20) {
            hasMorePages = false;
          } else {
            page += 1;
          }
        }
      } catch (error) {
        console.error('Error fetching reports:', error);
      } finally {
        setReportsMenu(allReports);
        setLoading(false);
      }
    };

    fetchAllReports();
  }, []);

  // Filter reports based on search term
  useEffect(() => {
    if (Array.isArray(reportsMenu)) {
      setFilteredReports(
        reportsMenu.filter((report) =>
          report.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }
  }, [searchTerm, reportsMenu]);

  useEffect(() => {
    if (Array.isArray(reportsMenu)) {
      setFilteredReports(reportsMenu.filter((report) => report.name.toLowerCase().includes(searchTerm.toLowerCase())));
    } else {
      setFilteredReports([]);
    }
  }, [searchTerm, reportsMenu]);

  const handleAddReportToDashboard = async (report) => {
    try {
      if (!report._id) {
        setSnackbarMessage('Select the report that you want to add, please.');
        setSnackbarOpen(true);
        handleReportsMenuClose();
        return;
      }

      const response = await addReportInToDashboard({
        dashboardId,
        reportId: report._id,
      });

      if (response.status === 200) {
        setRefreshTrigger(prev => !prev);
        setSnackbarMessage('Report added successfully.');
        setSnackbarOpen(true);
        handleReportsMenuClose();
      }
    } catch (error) {
      setSnackbarMessage(error?.request?.response?.message || 'Error adding report to dashboard');
      setSnackbarOpen(true);
      handleReportsMenuClose();
    }
  };

  const handleDashboardUpdate = async () => {
    try {
      const data = {
        name: dashboardName,
        description: dashboardDescription,
      };
      const response = await updateDashboard(dashboardId, data);

      if (response.status === 200) {
        setSnackbarMessage('Dashboard updated successfully.');
      } else {
        setSnackbarMessage('Failed to update dashboard.');
      }
      setSnackbarOpen(true);
      setIsEdit(false);
    } catch (error) {
      setSnackbarMessage('Error updating dashboard.');
      setSnackbarOpen(true);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (editBoxRef.current && !editBoxRef.current.contains(event.target)) {
        if (isEdit) {
          handleDashboardUpdate();
        }
        setIsEdit(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isEdit, dashboardName, dashboardDescription]);

  const handleKeyDown = (event, setEditingState) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      setEditingState(false);
    }
  };

  const EditableDashboard = () => (
    <Box display="flex" flexDirection="column" ref={editBoxRef}>
      <Box display="flex" alignItems="center" marginBottom="8px">
        {isEditingName ? (
          <Box width={250}>
            <OutLineInput
              autoFocus
              placeholder="Dashboard Name"
              value={dashboardName}
              onChange={(e) => setDashboardName(e.target.value)}
              onBlur={() => setIsEditingName(false)}
              onKeyDown={(e) => handleKeyDown(e, setIsEditingName)}
            />
          </Box>
        ) : (
          <Typography
            style={{ fontSize: 25, fontWeight: '600', cursor: 'pointer' }}
            onClick={() => {
              setIsEdit(true);
              setIsEditingName(true);
            }}
          >
            {dashboardName || 'Unnamed Dashboard'}
          </Typography>
        )}
      </Box>

      <Box display="flex" alignItems="center" width="100%">
        {isEditingDescription ? (
          <Box width={400}>
            <OutLineInput
              autoFocus
              placeholder="Dashboard Description"
              value={dashboardDescription}
              onChange={(e) => setDashboardDescription(e.target.value)}
              onBlur={() => setIsEditingDescription(false)}
              onKeyDown={(e) => handleKeyDown(e, setIsEditingDescription)}
            />
          </Box>
        ) : (
          <Box
            display="flex"
            alignItems="center"
            width="100%"
            onClick={() => {
              setIsEdit(true);
              setIsEditingDescription(true);
            }}
          >
            <Typography style={{ fontSize: 15 }}>{dashboardDescription || 'No description provided'}</Typography>
            <CreateIcon style={{ marginLeft: 8, cursor: 'pointer', fontSize: 13 }} />
          </Box>
        )}
      </Box>
      <Box display="flex" alignItems="center" width="100%" marginTop="8px">
        {dashboard?.dashboard?.type === 'PRIVATE' ? (
          <>
            <LockOutlinedIcon htmlColor="#6D7385" style={{ marginRight: 6, fontSize: 18 }} />
            <Typography
              style={{
                color: '#6D7385',
                fontSize: 12,
                textTransform: 'capitalize',
              }}
            >
              Private
            </Typography>
          </>
        ) : (
          <>
            <PublicIcon htmlColor="#6D7385" style={{ marginRight: 6, fontSize: 18 }} />
            <Typography
              style={{
                color: '#6D7385',
                fontSize: 12,
                textTransform: 'capitalize',
              }}
            >
              Global
            </Typography>
          </>
        )}
      </Box>
    </Box>
  );

  const handleReportsMenuClose = () => setAnchorEl(null);
  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleSnackbarClose = () => setSnackbarOpen(false);

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} paddingTop="20px">
        <Box display="flex" flexDirection="column">
          {dashboard ? <EditableDashboard /> : <Typography>Loading...</Typography>}
        </Box>
        <Button
          variant="outlined"
          style={{
            backgroundColor: '#007bff',
            color: '#fff',
            borderColor: '#007bff',
            textTransform: 'capitalize'
          }}
          endIcon={<AddCircleOutlineIcon />}
          onClick={handleMenuOpen}
        >
          Add Report
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleReportsMenuClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          sx={{
            width: '180px',
            '& .MuiPaper-root': {
              maxHeight: '300px',
              width: '180px',
              padding: '8px',
            },
          }}
        >
          <MenuItem disableRipple sx={{ padding: 0 }}>
            <TextField
              variant="outlined"
              placeholder="Search Reports"
              fullWidth
              size="small"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              sx={{
                width: '180px',
                maxHeight: '30px',
                '& .MuiInputBase-root': {
                  fontSize: '12px',
                  padding: '4px',
                },
                '& .MuiOutlinedInput-root': {
                  borderRadius: '4px',
                },
              }}
            />
          </MenuItem>
          <Divider />
          <div style={{
              maxHeight: '300px',
              maxWidth: '350px',
              overflowY: 'auto',
              overflowX: 'hidden',
              scrollbarWidth: 'thin',
              scrollbarColor: '#888 #f1f1f1'
            }}>
            {loading ? (
              <MenuItem disabled>Loading reports...</MenuItem>
            ) : filteredReports.length > 0 ? (
              filteredReports.map((report) => (
                <MenuItem onClick={() => handleAddReportToDashboard(report)} key={report._id}>
                  <ListItemIcon>
                    <DashboardIcon fontSize="small" />
                  </ListItemIcon>
                  <Typography variant="inherit" style={{ fontSize: '12px' }}>
                    {report.name}
                  </Typography>
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>No reports available</MenuItem>
            )}
          </div>
        </Menu>
      </Box>

      <Divider />

      <Box>
        <DashboardReports
          dashboardId={dashboardId}
          refreshTrigger={refreshTrigger}
        />
      </Box>

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose} message={snackbarMessage} />
    </Box>
  );
}
