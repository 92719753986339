import {
  Box,
  FormControl,
  InputBase,
  makeStyles,
  NativeSelect,
  Typography,
  withStyles,
} from '@material-ui/core';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { toast } from 'react-toastify';
import AssignUser from '../../../components/AssignUser';
import SelectBox from '../../../components/SelectBox';
import { getFormColor, getFormColorPlaceholder } from '../../../utils';
import HeaderTabs from './HeaderTabs';
import useUsers from '../../../hooks/useUsers';
import { updateProject } from '../../../services/unarmed';
import getProjectSubTypes from '../utils';
import { BootstrapInput } from '../../../styles/GlobalStyles';

const useStyles = makeStyles(() => ({
  container: {
    padding: '20px 40px 0',
    background: '#fff',
    width: '100%',
    minHeight: 115,
  },
  description: {
    fontSize: 12,
    color: '#0000003F',
    letterSpacing: 0.4,
    marginTop: 5,
  },
  createdBy: {
    color: '#585858',
    fontSize: 14,
    marginTop: 10,
  },
  layout: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
  },
  tag: {
    width: 'fit-content',
    // padding: '5px 10px',
    marginLeft: 10,
    borderRadius: 5,
    textTransform: 'capitalize',
    // height: '100%',
  },
}));

export default function Header({
  tabValue,
  handleChangeTab,
  a11yProps,
  project,
  statusOptions,
  typesOptions = [],
}) {
  const [types, setTypes] = useState([]);
  const [subTypes, setSubTypes] = useState([]);
  const [type, setType] = useState('');
  const [subType, setSubType] = useState('');
  const [status, setStatus] = useState('');
  const classes = useStyles();

  const [
    users,
    assignedUsers,
    setAssignedUsers,
    onAssignUser,
    onDeleteUser,
    loadingUsers,
  ] = useUsers(project?._id, project?.assignedTo);

  // console.log('assignedUsers', assignedUsers);

  useEffect(() => {
    if (typesOptions.length) {
      setTypes(typesOptions?.map((x) => ({ text: x.type, value: x.type })));
      const subTypesOptions = getProjectSubTypes(
        typesOptions,
        project?.projectType
      );
      setSubTypes(subTypesOptions);
      setType(project?.projectType);
      setSubType(project?.subType);
      setStatus(project?.status);
    }
  }, [typesOptions, project]);

  useEffect(() => {
    if (type) {
      const subTypesOptions = getProjectSubTypes(typesOptions, type);
      setSubTypes(subTypesOptions);
    }
  }, [type]);

  const onChangeStatus = async (e) => {
    const { value } = e.target;
    setStatus(value);
    try {
      await updateProject(project?._id, { status: value });
      toast.success('Project status updated successfully');
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeTypes = async (e, action) => {
    const data = {};
    const { value } = e.target;

    if (action === 'type') {
      setType(value);
      data.projectType = value;
    } else {
      setSubType(value);
      data.subType = value;
    }
    try {
      await updateProject(project?._id, data);
      if (action === 'type') {
        toast.success('Project Types updated successfully');
      } else {
        toast.success('Project Subtypes updated successfully');
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.layout}>
        <Box display="flex" alignItems="flex-start">
          <Box marginRight={3}>
            <Typography variant="h5">{project?.name}</Typography>
            <Typography variant="body2" className={classes.createdBy}>
              Created by{' '}
              {`${project?.createdBy?.firstName} ${project?.createdBy?.lastName}`}{' '}
              - {moment(project?.createdAt).format('MMMM DD, YYYY')}
            </Typography>
            <Typography variant="body2" className={classes.description}>
              {project?.description}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Box
              className={classes.tag}
              style={{
                background: getFormColorPlaceholder('other'),
              }}
            >
              <SelectBox
                options={{ obj: types || [] }}
                value={type}
                onChange={(e) => onChangeTypes(e, 'type')}
                allowPlaceHolder={false}
                withColors={{
                  color: getFormColor('other'),
                }}
              />
            </Box>
            <Box
              className={classes.tag}
              style={{
                background: getFormColorPlaceholder('other'),
              }}
            >
              <SelectBox
                options={subTypes || []}
                value={subType}
                onChange={(e) => onChangeTypes(e, 'subtype')}
                allowPlaceHolder
                withColors={{
                  color: getFormColor('other'),
                }}
              />
            </Box>
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <Box display="flex" marginRight={3}>
            <AssignUser
              assignedUsers={assignedUsers}
              setAssignedUsers={setAssignedUsers}
              defaultUsers={users}
              onAssignUser={onAssignUser}
              loadingRemoveUser={false}
              onDeleteUser={onDeleteUser}
            />
          </Box>
          <Box display="flex" justifyContent="flex-end" flexDirection="column">
            <FormControl>
              <NativeSelect
                id="demo-customized-select-native"
                value={status}
                onChange={onChangeStatus}
                input={<BootstrapInput />}
                IconComponent={KeyboardArrowDownIcon}
              >
                {statusOptions?.map((status, index) => (
                  <option
                    key={index}
                    value={status}
                    style={{
                      textAlign: 'center',

                      marginRight: 10,
                    }}
                  >
                    {status}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
            <Typography
              variant="body2"
              style={{ color: '#585858', fontSize: 13, marginTop: 10 }}
            >
              Last update {moment().format('MMMM DD, YYYY - hh:mm a')}
            </Typography>
          </Box>
        </Box>
      </Box>
      <HeaderTabs
        tabValue={tabValue}
        handleChangeTab={handleChangeTab}
        a11yProps={a11yProps}
      />
    </Box>
  );
}
