import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import logo from '../assets/oversignBlack.png';

const LogoHome = ({ width = 150 }) => {
  const history = useHistory();

  return <Image style={{ width }} alt="logo-oversight" src={logo} onClick={() => history.push('/')} />;
};

const Image = styled.img`
  max-width: 100%;
  cursor: pointer;
  object-fit: contain;
  align-self: center;

  @media screen and (max-width: 689px) {
    width: 120px;
  }
`;

LogoHome.propTypes = {
  width: PropTypes.number,
};

export default LogoHome;
