import React, { useState } from 'react';
import { Box, Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import WrapperComponent from './WrapperComponent';
import Input from '../../components/Input';
import PolicyIcon from '@material-ui/icons/Policy';
import { handleChangeCheckBox, onChangeDnDValue } from '../utils/utls';

import Settings, { CustomCheckbox } from '../utils/Settings';
import OutLineTable from '../../components/OutLineTable';
import PreviewLabel from '../utils/Label';
import HideAnswer from '../../components/HideAnswer';
import useInput from '../../hooks/useInput';

const useStyles = makeStyles(() => ({
  title: {
    fontSize: 14,
    color: '#000000DE',
    marginLeft: 10,
  },
  label: {
    fontSize: 16,
    color: '#000000DE',
    fontWeight: '500',
  },

  fieldLabel: {
    fontSize: 14,
  },

  subLabel: {
    fontSize: 14,
    color: '#000000DE',
    fontWeight: '500',
  },

  settingsQuestions: {
    fontSize: 14,
    minWidth: 160,
  },
  type: {
    width: 131,
  },
  labelContainer: {
    width: 425,
    marginLeft: 20,
  },

  td: {},
}));
const columns = [
  { name: 'Type', styles: {} },
  { name: 'Label', styles: {} },
  { name: 'Visible', styles: { textAlign: 'right' } },
  { name: 'Required', styles: { textAlign: 'right' } },
];
export default function Police({
  questionBankComponent,
  id,
  onUpdateJson,
  onDeleteComponent,
  path,
  componentIndex,
  data,
  duplicateComponent,
  refActionModal,
  setModal,
}) {
  const classes = useStyles();
  const [rows, setRows] = useState([
    {
      type: 'First Name',
      text: 'First Name',
      visible: true,
      required: false,
    },
    {
      type: 'Last Name',
      text: 'Last Name',
      visible: true,
      required: false,
    },
    {
      type: 'Badge/Serial Number',
      text: 'Badge/Serial Number',
      visible: true,
      required: false,
    },
    {
      type: 'Officer Description',
      text: 'Officer Description',
      visible: true,
      required: false,
    },
  ]);

  const [checkQuestion, setCheckQuestion] = useState([false]);
  const descriptionQuestion = useInput(data?.description || '');
  const requiredQuestionMessage = useInput(data?.requiredMessage || '');
  const question = useInput(data?.question || '');

  const renderSettings = () => (
    <Settings
      questions={['description']}
      checkQuestion={checkQuestion}
      descriptionQuestion={descriptionQuestion}
      requiredQuestion={requiredQuestionMessage}
      setCheckQuestion={setCheckQuestion}
    />
  );

  const handleSaveComponent = () => {
    const dataJson = {
      question: question.value,
      ...(descriptionQuestion.value ? { description: descriptionQuestion.value } : {}),
      firstName: {
        text: rows[0].text,
        visible: rows[0].visible,
        required: rows[0].required,
      },
      lastName: {
        text: rows[1].text,
        visible: rows[1].visible,
        required: rows[1].required,
      },
      badge: {
        text: rows[2].text,
        visible: rows[2].visible,
        required: rows[2].required,
      },
    };

    onUpdateJson(dataJson);
  };

  const renderPreview = () => (
    <Box>
      <PreviewLabel question={question.value} qNumber={componentIndex + 1} />
      <Box maxWidth={600} marginTop="-15px" marginLeft="45px">
        <HideAnswer marginLeft={'-7px'} marginTop={0}>
          <Grid container spacing={2}>
            {rows.map((row, index) => {
              if (row.visible) {
                return (
                  <Grid item xs={6}>
                    <TextField
                      key={index}
                      label={
                        <>
                          {row.text} {row.required && <span style={{ color: 'red', fontSize: 12 }}>*</span>}{' '}
                        </>
                      }
                      variant="standard"
                      disabled
                      style={{ width: '100%' }}
                      placeholder={row.type}
                    />
                  </Grid>
                );
              }
            })}
          </Grid>
        </HideAnswer>
      </Box>
    </Box>
  );

  const renderRows = (row, index) => (
    <>
      <td className={classes.td} style={{ width: 150 }}>
        <Box display="flex" alignItems="center" justifyContent="space-between" width={130}>
          <Typography className={classes.fieldLabel}>{row.type}</Typography>
          <ArrowRightAltIcon style={{ fontSize: 14 }} />
        </Box>
      </td>
      <td className={classes.td}>
        <Box width={250}>
          <Input
            placeholder={row.type}
            value={row.text}
            onChange={(e) => onChangeDnDValue(e, setRows, index, 'text')}
          />
        </Box>
      </td>
      <td className={classes.td}>
        <Box display="flex" justifyContent="flex-end">
          <CustomCheckbox
            color="primary"
            checked={row.visible}
            onChange={(e) => handleChangeCheckBox(e, index, 'visible', rows, setRows)}
          />
        </Box>
      </td>
      <td className={classes.td}>
        <Box display="flex" justifyContent="flex-end" marginRight="6px">
          <CustomCheckbox
            color="primary"
            checked={row.required}
            onChange={(e) => handleChangeCheckBox(e, index, 'required', rows, setRows)}
          />
        </Box>
      </td>
    </>
  );

  return (
    <WrapperComponent
      renderSettings={renderSettings}
      componentIndex={componentIndex}
      duplicateComponent={duplicateComponent}
      isQuestionBankComponent={questionBankComponent}
      includeRequired={false}
      renderPreview={renderPreview}
      onDeleteComponent={onDeleteComponent}
      id={id}
      path={path}
      handleSaveComponent={handleSaveComponent}
      dataSaved={data?.question}
      refActionModal={refActionModal}
      setModal={setModal}
    >
      <Box display="flex" alignItems="center">
        <PolicyIcon htmlColor="#6D7385" />
        <Typography className={classes.title}>Police</Typography>
      </Box>
      <Box display="flex" alignItems="center" marginTop="15px">
        <Typography className={classes.label}>Q{componentIndex + 1}</Typography>
        <ArrowRightAltIcon style={{ fontSize: 14, marginRight: 10 }} />
        <Input placeholder="Officers" value={question.value} onChange={question.onChange} />
      </Box>
      <Box marginTop="38px">
        <OutLineTable columns={columns} rows={rows} renderRows={renderRows} />
      </Box>
    </WrapperComponent>
  );
}
