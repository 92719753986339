import React, { useState, createContext, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import { unarmedWithToken } from './services/axios';
import { signOut } from './services/firebase';
import { getMe, getOrganizationsByState } from './services/unarmed';
import firebase from 'firebase';

export const Context = createContext();

export function getOrg(location) {
  const org = location.href.split('.')[0].split('//')[1];
  if (org.includes('localhost')) {
    return process.env.REACT_APP_SUBDOMAIN || 'dashboard-dev';
  }

  return org;
}

const Provider = ({ children }) => {
  const [isAuth, setIsAuth] = useState(false);
  const [authUser, setAuthUser] = useState(null);
  const [me, setMe] = useState(null);
  const [active, setActive] = useState(window.location.pathname);
  const [organization, setOrganization] = useState(null);
  const [requireRefresh, setRequireRefresh] = useState(false);
  const [loadingOrg, setLoadingOrg] = useState('idle');
  const [signUpUser, setSignUpUser] = useState(null);
  const [loading, setLoading] = useState(false);

  const onGetMe = useCallback(async () => {
    try {
      if (authUser?.tokenId) {
        const { data } = await getMe(authUser.tokenId);

        firebase.analytics().setUserProperties({ role: data.role });

        setMe(data);
        setLoading(false);
      }
    } catch (error) {
      if (
        error.response.data.message === 'Expired token' ||
        error.response.data.message === 'Authentication required'
      ) {
        setLoading(false);
        signOut();
        setIsAuth(false);
        setAuthUser(null);
        toast.error(
          error.response.data.message === 'Expired token'
            ? 'Your password has expired. Please reset your password.'
            : 'This user is not allowed to be authenticated'
        );
        localStorage.removeItem('authToken');
        localStorage.removeItem('refreshToken');
      }
    }
  }, [authUser?.tokenId]);

  useEffect(() => {
    if (authUser && authUser.tokenId) {
      // GetMe everytime token changes
      setLoading(true);
      onGetMe();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser?.tokenId]);

  useEffect(() => {
    const { location } = window;

    setLoadingOrg('pending');
    getOrganizationsByState(getOrg(location))
      .then((res) => {
        setOrganization(res.data);
        setLoadingOrg('resolved');
      })
      .catch((err) => {
        console.log(err);
        setLoadingOrg('rejected');
      });
  }, []);

  useEffect(() => {
    if (me && organization) {
      if (me?.organizationId !== organization?.id) {
        toast.error('You are not allowed to access this organization');
        signOut();
        setIsAuth(false);
        setAuthUser(null);
        localStorage.removeItem('authToken');
        localStorage.removeItem('refreshToken');
      }
    }
  }, [me, organization]);

  const value = {
    isAuth,
    authUser,
    active,
    setActive,
    requireRefresh,
    setIsAuth,
    signUpUser,
    setSignUpUser,
    setRequireRefresh,
    activateAuth: (user) => {
      setAuthUser(user);
    },
    setAuthUser,
    removeAuthToLogin: (message = 'Please Re-authenticate to add a MFA') => {
      signOut();
      setIsAuth(false);
      setAuthUser(null);
      if (message) {
        toast.error(message);
      }
      localStorage.removeItem('authToken');
      localStorage.removeItem('refreshToken');
    },
    removeAuth: async () => {
      await unarmedWithToken().post('/activity/logout');
      await signOut();
      setIsAuth(false);
      setAuthUser(null);
      // history.push('/login');
      setActive('/');
      localStorage.removeItem('authToken');
      localStorage.removeItem('refreshToken');
    },
    loadingOrg,
    setLoadingOrg,
    myUser: me,
    setMyuser: setMe,
    organization,
    setOrganization,
    onGetMe,
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default {
  Provider,
  Consumer: Context.Consumer,
};
