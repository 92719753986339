import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Fade,
  ListItemIcon,
  makeStyles,
  MenuItem,
  Modal,
  Typography,
} from '@material-ui/core';

import React from 'react';
import ArchiveIcon from '@material-ui/icons/Archive';
import TransferIcon from '@material-ui/icons/Send';
import DuplicateIcon from '@material-ui/icons/FileCopy';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import { toast } from 'react-toastify';
import PopperPortal from '../../components/PopperPortal';
import { onArchive, onUnArchive } from '../../services/unarmed';
import { axiosCatchError } from '../../utils';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 20,
  },
  title: {
    marginTop: 50,
  },
  button: {
    background: ' #1F63FF',
    textTransform: 'capitalize',
    marginRight: 10,
    color: '#fff',
    height: 36,
    width: 30,
    '&:hover': {
      background: '#1F63FF',
    },
    '& .MuiButton-label': {
      fontSize: 13,
    },
    '&:disabled': {
      background: '#1f63ff61',
      color: '#fff',
    },
  },
  button2: {
    background: ' #1F63FF',
    textTransform: 'capitalize',
    color: '#fff',
    '&:hover': {
      background: '#1F63FF',
    },
    '& .MuiButton-label': {
      fontSize: 14,
    },
  },
  buttonOutlined: {
    letterSpacing: '1px',
    fontSize: 13,
    fontWeight: '500',
    marginRight: 10,
    width: 'fit-content',
    alignSelf: 'flex-end',
    border: '1px solid transparent',
    color: '#4b7bff',
    '&:hover': {
      background: 'transparent',
      border: '1px solid transparent',
    },
    '& .MuiButton-label': {
      fontSize: 13,
    },
    '&:disabled': {
      border: '1px solid transparent',
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: '#fff',
    boxShadow: theme.shadows[5],
    padding: 30,
    maxWidth: 480,
    outline: 0,
    alignSelf: 'center',
  },
}));

const restoreMessage = 'Restoring a case brings it back into view for all users';
const ArchiveMessage = 'Archiving a case removes it from view but allows you to restore it at any time';

export default function HeaderOptions({ formId, archived = false, transferredTo = false, setForm, onTransferClick, onDuplicateClick }) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [modal, setModal] = React.useState(false);
  const classes = useStyles();

  const handleArchive = React.useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await onArchive(formId);
      setForm(data);
      setModal(false);
      toast.success('Forms archived successfully');
    } catch (error) {
      axiosCatchError(error);
    }
    setLoading(false);
  }, [formId]);

  const handleUnarchive = React.useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await onUnArchive(formId);
      setForm(data);
      setModal(false);

      toast.success('Forms Restored successfully');
    } catch (error) {
      axiosCatchError(error);
    }
    setLoading(false);
  }, [formId]);

  const options = [
    {
      label: archived ? 'Restore' : 'Archive',
      icon: archived ? <UnarchiveIcon /> : <ArchiveIcon />,
      onClick: () => {
        setOpen(false);
        setModal(true);
      },
    },
  ];
  if (!transferredTo) {
    options.push({
      label: 'Transfer',
      icon: <TransferIcon />,
      onClick: onTransferClick,
    });
  }

  options.push({
    label: 'Duplicate',
    icon: <DuplicateIcon />,
    onClick: onDuplicateClick,
  });
  return (
    <Box position="relative">
      <Button onClick={() => setOpen(true)}>
        <MoreHorizIcon />
      </Button>
      <PopperPortal open={open} setOpen={setOpen} left={-155}>
        {loading ? (
          <CircularProgress color="#333" size="small" />
        ) : (
          options.map((option, index) => (
            <MenuItem key={index} onClick={option.onClick}>
              <ListItemIcon>{option.icon}</ListItemIcon>
              <Typography variant="inherit" noWrap>
                {option.label}
              </Typography>
            </MenuItem>
          ))
        )}
      </PopperPortal>
      <Modal
        aria-labelledby="transition-modal-title1"
        aria-describedby="transition-modal-description1"
        open={modal}
        className={classes.modal}
        onClose={() => setModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modal}>
          <Box className={classes.paper}>
            <Typography style={{ fontSize: 20, fontWeight: '500' }}>{archived ? 'Restore' : 'Archive'} case</Typography>
            <Typography variant="body2">{archived ? restoreMessage : ArchiveMessage}</Typography>
            <Box display="flex" alignItems="center" justifyContent="flex-end" marginTop="20px">
              <Button
                variant="outlined"
                className={classes.buttonOutlined}
                onClick={() => {
                  setModal(false);
                }}
              >
                CANCEL
              </Button>
              <Button
                variant="outlined"
                className={classes.buttonOutlined}
                disabled={loading}
                onClick={archived ? handleUnarchive : handleArchive}
              >
                {loading ? <CircularProgress color="#fff" size="25px" /> : archived ? 'RESTORE' : 'ARCHIVE'}
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
}
