import { Box } from '@material-ui/core';
import React, { useMemo } from 'react';
import { getConfig } from '../config/FormConfig';
import { Renderer } from '../config/FormRenderer';

export default function QuestionBank({
  components,
  onSaveFormValues,
  getFormValue,
  organizationId,
  imagesPreview,
  withFileUpload,
}) {
  const config = useMemo(
    () =>
      getConfig({
        componentsFromServer: components,
        imagesPreview,
      }),
    [components]
  );

  return (
    <Box paddingBottom="20px">
      <Renderer
        config={config}
        withFileUpload={withFileUpload}
        onSaveFormValues={onSaveFormValues}
        getFormValue={getFormValue}
        isQuestionBankRender
        organizationId={organizationId}
      />
    </Box>
  );
}
