import { Box, Divider, FormControl, Grid, makeStyles, NativeSelect, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { useEffect, useState } from 'react';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import moment from 'moment';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';

import { BootstrapInput } from '../../../styles/GlobalStyles';
import useUsers from '../../../hooks/useUsers';
import AssignUser from '../../../components/AssignUser';
import DateTimePickerMUI from '../../../components/DateTimePickerMUi';
import { convertHMS } from '../utils';
import ModalPopUp from '../../../components/ModaPopupl';
import DeleteActionPopup from './PopUpDeleteAction';
import { getFormColor, getFormColorPlaceholder } from '../../../utils';
import SelectBox from '../../../components/SelectBox';
import useProjectTypes from '../../../hooks/useProjectTypes';
import { TINYMCE_DEFAULT_CONFIG, TINYMCE_SCRIPT_SRC } from '../../../config/config';
import ModalUploadFile from '../../../components/ModalUploadFile';
import Attachment from '../../../components/Attachment';
import CarouselModal from '../../../components/CarouselModal';

const useStyles = makeStyles(() => ({
  itemComponent: {
    width: '100%',
    // height: 420,
  },
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 34,
    background: '#fff',

    boxShadow: '0px 3px 6px #00000012',
    borderRadius: 5,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  headerTitle: {
    marginRight: 8,
    fontWeight: '500',
  },
  dropzoneBox: {
    background: '#4762FA08',
    borderRadius: 4,
    border: '1px dashed #364F7431',
    padding: 34,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: 16,
  },

  dropzoneText: {
    display: 'block',
    fontSize: 12,
    marginTop: 10,
    textAlign: 'center',
  },
  deleteIcon: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: '35%',
    margin: 'auto',
    cursor: 'pointer',
  },
  viewIcon: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: '35%',
    right: 0,
    margin: 'auto',
    cursor: 'pointer',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  itemContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    margin: '40px auto 10px',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 15,
  },
  names: {
    fontWeight: '500',

    minWidth: 250,
  },
  actionContainer: {
    marginLeft: 80,
  },
  columnText: {
    fontWeight: '500',
  },
  label: {
    fontWeight: '500',
    fontSize: 14,
    margin: '10px 0 10px',
  },
  featureItem: {
    width: 137,
  },
  timeSpent: {
    height: 64,
    fontSize: 16,
    marginTop: 17,
  },
  required: {
    color: 'red',
    fontSize: 16,
  },
  link: {
    display: 'flex',
    position: 'absolute',
    top: 10,
    right: 80,
    cursor: 'pointer',
    padding: 13,
    zIndex: 999999,
    borderRadius: '50%',
    background: '#494949',
  },
  tag: {
    width: 'fit-content',
    marginLeft: 10,
    borderRadius: 5,
    textTransform: 'capitalize',
  },
}));

export default function ActionItem({
  index = 0,
  project,
  name,
  assignedTo,
  attachments,
  organizationId,
  notes,
  times,
  statusOptions,
  users = [],
  onRemoveAction,
  handleChangeActionValues,
  values,
  types,
  tabValue,
  updatedAt,
}) {
  const classes = useStyles();

  const [, assignedUsers, setAssignedUsers] = useUsers(project?._id, values.assignedTo || [], false, false, users);
  const [open, setOpen] = React.useState(false);
  const [filesSelected, setFilesSelected] = React.useState([]);
  const [loadingOpenFiles, setLoadingOpenFiles] = React.useState([]);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [openModalUploadFile, setOpenModalUploadFile] = useState(false);
  const [files, setFiles] = useState([]);

  const [typesOptions, subTypeOptions] = useProjectTypes(types, values?.type);

  useEffect(() => {
    if (tabValue !== 0) {
      handleChangeActionValues(index, 'attachments', values.attachments);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabValue]);

  useEffect(() => {
    if (values?.attachments?.length) {
      setFiles(
        values?.attachments?.map((file) => ({
          _id: file?._id,
          name: file?.name,
          size: file?.size,
          src: file?.public_url || '',
          mimetype: file?.mimetype,
          public_url: file?.public_url,
          preview_url: file?.preview_url,
          download_url: file?.download_url,
          tags: file?.tags || [],
          archivedDate: file.archivedDate,
        }))
      );
    } else {
      setFiles([]);
    }
  }, [values, name]);

  const onAssignUser = (_, callback) => {
    handleChangeActionValues(index, 'assignedTo', [_[0]?._id]);
    callback();
  };

  const onDeleteUser = () => {
    handleChangeActionValues(index, 'assignedTo', []);
    setAssignedUsers([{}]);
  };

  const onDeleteFile = (file) => {
    const newFiles = [...files.filter((_file) => _file?._id !== file?._id)];

    setFiles(newFiles);
    handleChangeActionValues(index, 'attachments', [...newFiles]);
  };

  const handleOpen = (imgUrl, filesOp) => {
    setOpen(true);
    const filesToOpen = filesOp.filter(
      (flop) => flop.mimetype.includes('image') || flop.mimetype.includes('video') || flop.mimetype.includes('audio')
    );
    const selectedFile = filesToOpen.find((flop) => flop.public_url === imgUrl);
    const selectedFileIndex = filesToOpen.indexOf(selectedFile);
    filesToOpen.splice(selectedFileIndex, 1);
    filesToOpen.unshift(selectedFile);
    setFilesSelected(filesToOpen);
    setLoadingOpenFiles(
      filesToOpen.map(() => ({
        loading: false,
      }))
    );
  };

  return (
    <Box display="flex" alignItems="center" width="100%">
      <Box className={classes.container}>
        <Box className={classes.itemComponent}>
          <Box display="flex" alignItems="center" justifyContent="space-between" padding="10px 20px">
            <Typography className={classes.headerTitle}>
              A{index + 1} {name && <ArrowRightAltIcon style={{ fontSize: 14 }} />}{' '}
            </Typography>
            <Typography className={classes.headerTitle}>{name}</Typography>
            <Box className={classes.iconContainer}>
              <DeleteIcon
                htmlColor="#6D7385"
                style={{ marginLeft: 15, cursor: 'pointer' }}
                // onClick={onRemoveAction}
                onClick={() => setOpenDeleteModal(true)}
              />
            </Box>
          </Box>
          <Divider />
          <Box padding="30px 30px 40px">
            <Box className={classes.header}>
              <Box display="flex" alignItems="flex-start" justifyContent="space-between" width="100%">
                <Box display="flex" alignItems="center" style={{ gap: 10 }}>
                  <Box
                    className={classes.tag}
                    style={{
                      background: getFormColorPlaceholder('other'),
                    }}
                  >
                    <SelectBox
                      options={{ obj: typesOptions || [] }}
                      value={values?.type}
                      onChange={(e) => handleChangeActionValues(index, 'type', e.target.value)}
                      allowPlaceHolder
                      withColors={{
                        color: getFormColor('other'),
                      }}
                    />
                  </Box>
                  <Box
                    className={classes.tag}
                    style={{
                      background: getFormColorPlaceholder('other'),
                    }}
                  >
                    <SelectBox
                      options={subTypeOptions || []}
                      value={values?.subType}
                      onChange={(e) => handleChangeActionValues(index, 'subType', e.target.value)}
                      allowPlaceHolder
                      withColors={{
                        color: getFormColor('other'),
                      }}
                    />
                  </Box>
                </Box>
                <Box display="flex" justifyContent="flex-end" flexDirection="column">
                  <FormControl>
                    <NativeSelect
                      id="demo-customized-select-native1"
                      value={values.status}
                      onChange={(e) => handleChangeActionValues(index, 'status', e.target.value)}
                      input={<BootstrapInput />}
                      IconComponent={KeyboardArrowDownIcon}
                    >
                      <option
                        value={null}
                        style={{
                          textAlign: 'center',
                          textTransform: 'capitalize',
                          marginRight: 10,
                        }}
                      >
                        Select an Option
                      </option>
                      {statusOptions?.map((status, statusOptionIndex) => (
                        <option
                          key={statusOptionIndex}
                          value={status}
                          style={{
                            textAlign: 'center',

                            marginRight: 10,
                          }}
                        >
                          {status}
                        </option>
                      ))}
                    </NativeSelect>
                  </FormControl>
                  <Typography variant="body2" style={{ color: '#585858', fontSize: 13, marginTop: 10 }}>
                    Last update {moment(updatedAt).format('MMMM DD, YYYY - hh:mm a')}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box display="flex" marginTop={4} position="relative">
              <Grid container spacing={4}>
                {times?.visible && (
                  <>
                    <Grid item xs={12} md={6} lg={4}>
                      <Box>
                        <Typography variant="body2" className={classes.label}>
                          Start Date Time: {times?.required && <span className={classes.required}>*</span>}
                        </Typography>
                        <DateTimePickerMUI
                          date={values.startDateTime || null}
                          setdate={(date) => handleChangeActionValues(index, 'startDateTime', date)}
                          dateToCompare={values.endDateTime}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <Box>
                        <Typography variant="body2" className={classes.label}>
                          End Date Time: {times?.required && <span className={classes.required}>*</span>}
                        </Typography>
                        <DateTimePickerMUI
                          date={values.endDateTime || null}
                          type="end"
                          setdate={(date) => handleChangeActionValues(index, 'endDateTime', date)}
                          dateToCompare={values.startDateTime}
                          disabled={!values.startDateTime}
                        />
                      </Box>
                    </Grid>
                  </>
                )}
                <Grid item xs={12} sm={6} md={6} lg={2} xl={2}>
                  <Box className={classes.featureItem} style={{ flex: 1 }}>
                    <Typography variant="body2" className={classes.label}>
                      Time Spent:
                    </Typography>
                    <Typography variant="body2" className={classes.timeSpent}>
                      {convertHMS(values?.timeSpent)}
                    </Typography>
                  </Box>
                </Grid>
                {assignedTo?.visible && (
                  <Grid item xs={12} sm={6} md={6} lg={2} xl={2}>
                    <Box display="flex" marginTop={1}>
                      <AssignUser
                        assignedUsers={assignedUsers}
                        setAssignedUsers={setAssignedUsers}
                        defaultUsers={users}
                        onAssignUser={onAssignUser}
                        loadingRemoveUser={false}
                        onDeleteUser={onDeleteUser}
                        alignX={0}
                        addMoreUser={false}
                        required={assignedTo?.required}
                      />
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Box>
            {notes?.visible && (
              <Box>
                <Typography variant="body2" className={classes.label}>
                  Notes {notes?.required && <span className={classes.required}>*</span>}
                </Typography>
                <Editor
                  tinymceScriptSrc={TINYMCE_SCRIPT_SRC}
                  init={{
                    ...TINYMCE_DEFAULT_CONFIG,
                    placeholder: 'Add a note',
                    height: 200,
                  }}
                  value={values.notes}
                  onEditorChange={(newValue) => {
                    handleChangeActionValues(index, 'notes', newValue);
                  }}
                />
              </Box>
            )}
            {attachments?.visible && (
              <Box marginTop={3}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="body2" className={classes.label}>
                    Attachments {attachments?.required && <span className={classes.required}>*</span>}
                  </Typography>
                  <Box display="flex" onClick={() => setOpenModalUploadFile(true)} style={{ cursor: 'pointer' }}>
                    <AttachFileIcon fontSize="small" htmlColor="#4b7bff" />{' '}
                    <Typography style={{ color: '#4b7bff', fontSize: 14, fontWeight: '500' }}>
                      New attachments
                    </Typography>
                  </Box>
                </Box>
                <Box width="100%">
                  {files?.map((file) => (
                    <Attachment
                      columnImageSize={2}
                      columnTextSize={8}
                      fileId={file?._id}
                      key={file?._id || file?.url}
                      id={file._id}
                      defaultTags={file?.tags || []}
                      name={file?.name}
                      size={file?.size || 0}
                      url={file?.url || file?.public_url}
                      loading={file?.loading || false}
                      mimetype={file?.mimetype || file?.mimetype}
                      withAddBtn={false}
                      rightCreateTag={-235}
                      //onClickDelete={() => onDeleteFile(file)}
                      onClickPreview={() => {
                        if (file.mimetype.includes('pdf')) {
                          const link = document.createElement('a');
                          link.href = file.preview_url;
                          link.setAttribute('target', '_blank');
                          document.body.appendChild(link);
                          link.click();
                          return;
                        }
                        handleOpen(file.public_url, files);
                      }}
                      archived={!!file.archivedDate}
                    />
                  ))}
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <ModalPopUp setOpen={setOpenDeleteModal} open={openDeleteModal} allowBg={false}>
        <DeleteActionPopup setOpen={setOpenDeleteModal} handleClicked={onRemoveAction} />
      </ModalPopUp>
      <CarouselModal
        open={open}
        setOpen={setOpen}
        filesSelected={{
          length: filesSelected.length,
          selectedFileIndex: 0,
          files:
            filesSelected?.map((file) => ({
              download_url: file.download_url,
              name: '',
              mimetype: file.mimetype,
              public_url: file.public_url,
              tags: file?.tags || [],
            })) || [],
        }}
        loadingOpenFiles={loadingOpenFiles}
        setLoadingOpenFiles={setLoadingOpenFiles}
      />
      <ModalUploadFile
        organizationId={organizationId}
        open={openModalUploadFile}
        onClose={() => setOpenModalUploadFile(false)}
        onConfirm={(_, _files) => {
          setFiles((prevFiles) => {
            const newFiles = [...prevFiles, ..._files];

            handleChangeActionValues(index, 'attachments', newFiles);

            return newFiles;
          });
          setOpenModalUploadFile(false);
        }}
      />
    </Box>
  );
}

// Prop Validation
ActionItem.propTypes = {
  index: PropTypes.number,
  project: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  assignedTo: PropTypes.string.isRequired,
  attachments: PropTypes.array.isRequired,
  organizationId: PropTypes.string.isRequired,
  notes: PropTypes.string.isRequired,
  times: PropTypes.array.isRequired,
  _id: PropTypes.string.isRequired,
  statusOptions: PropTypes.array.isRequired,
  users: PropTypes.array,
  onRemoveAction: PropTypes.func.isRequired,
  handleChangeActionValues: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  types: PropTypes.object.isRequired,
  tabValue: PropTypes.number.isRequired,
  updatedAt: PropTypes.string.isRequired,
};
