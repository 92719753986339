import { Box, Button, InputBase, createTheme, makeStyles, withStyles } from '@material-ui/core';
import styled, { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  html {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
  }

  *, *:before, *:after {
    box-sizing: inherit
  }

  body {
    background: white;
    height: 100vh;
    margin: 0 auto;
    overscroll-behavior: none;
    width: 100%;
    font-family: "Roboto";
    -webkit-font-smoothing: antialiased;
  }

  ul, li, h1, h2, h3, p, button {
    margin: 0;
  }


  button {
    background: #ffffff00;
    border: 0;
    outline: 0;
  }

  ul {
    list-style: none;
    padding: 0;
  }
  
  a { 
    text-decoration: none;
    color: #393939
  }

  .react-tel-input .form-control:focus {
    box-shadow: unset !important;
  }
  .react-tel-input :disabled  {
    border-bottom: 1px dotted rgba(0, 0, 0, 0.42) !important;
  }


  .MuiStepIcon-root.MuiStepIcon-active {
    color: #fff !important;
    .MuiStepIcon-text {
      fill: #000;
    }
  }
  .MuiStepIcon-root.MuiStepIcon-completed {
    color: #4B7BFF !important;
  }

  ${'' /* .MuiRadio-root {
    color: #4B7BFF !important;
  } */}
  .MuiStepLabel-label {
    color: #fff !important;
  }

  .MuiFilledInput-input {
    padding: 0 10px
  }

  .MuiFilledInput-underline.Mui-disabled:before {
    border: 0 !important;
  }


  .import-dropzone {
    background-color: #FFFFFF !important;
    border: 1px dashed #364F7431 !important;
    height: 150px !important;
    min-height: 150px !important;
    border-radius: 4px !important;
    .MuiDropzoneArea-textContainer {
      display: flex;
      font-size: 18px;
      align-items: center;
      justify-content: center;
      flex-direction:column-reverse !important;

      height: 100%;
      .MuiTypography-h5 {
        font-size: 14px !important;
      }
    }
  }

  .default-dropzone-class {
    border: unset !important;
    border-color: #DADFE9 !important;
    background-color: #F9FAFF !important;
    outline: 0 !important;
  }

  .primary-dropzone-class {
    border-width: 1px dashed #364F7431 !important;
    background-color: #F9FAFD !important;
    height: 150px !important;
    min-height: 150px !important;
  }

  .primary-dropzone-class .MuiDropzoneArea-text {
    font-size: 12px !important;
    font-weight: 500 !important;
  }

  .MuiDropzoneArea-root {
    margin-bottom: 20px;
    height: 115px;
   
    .MuiDropzoneArea-textContainer {
      display: flex;
      font-size: 18px;
      align-items: center;
      justify-content: center;
      flex-direction:column;
      height: 100%;
    }
  }

  
  .MuiTabs-indicator {
    background-color: #4b7bff !important;
  }

  .Mui-selected {
    color: #4b7bff !important;
  }

  .MuiTab-wrapper {
    width: 100%;
    display: inline-flex;
    align-items: center;
    flex-direction: column;
  }
  

  .MuiTab-root {
    min-width: unset !important;
    margin-right: 20px !important;
  }

  .MuiTableRow-root.Mui-selected, .MuiTableRow-root.Mui-selected:hover{
    background: rgb(234, 236, 247) !important;
  }

  .MuiOutlinedInput-adornedEnd {
    padding-right: 0;
    background-color: #fff;
  }
  .MuiSelect-select.MuiSelect-select {
    text-transform: capitalize;
  }

  .MuiNativeSelect-select.MuiNativeSelect-select {
    text-transform: capitalize;
  }

  .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover {
    background-color: rgba(255,255,255, 0.23) !important; 
  }

  .MuiFormControlLabel-label {
    font-size: 14px !important;
  }

  .MuiLinearProgress-root {
    height: 7px;
    overflow: hidden;
    position: relative;
    background: #70707027 !important;
    border-radius: 100px;
    
    & .MuiLinearProgress-barColorPrimary {
      background-color: #2E66FE !important;
    }
    & .MuiLinearProgress-barColorSecondary {
      background-color: #01BEA8 !important;
    }
  }

  .MuiChip-colorPrimary {
    background-color: #2E66FE !important;
  }

  .MuiTableCell-root.MuiTableCell-root {
    padding: 9px 16px 
  }

  .react-code-input {
    display: flex !important;
    width: 90% !important;
    justify-content: space-between;
    margin: 15px auto 10px;
  
  }

  #logo-brand-img {
    object-fit: contain;
    height: 60px;
    width: 160px;
    align-self: center;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  input[type=number] {
    -moz-appearance: textfield;
  }

  .MuiSelect-select:focus {
    background-color: transparent !important;
  }

  .dropZone {
  flex: 0 0 auto;
  height: 60px;
  transition: 200ms all;
}


.dropZone.horizontalDrag {
  width: 40px;
  height: auto;
}

.dropZone:not(.horizontalDrag).isLast {
  flex: 1 1 auto;
}

.dropZone.active,
.trashDropZone.active {
  background: #d6e1ff;
  transition: 100ms all;
}
  .ant-table-thead > tr > th {
    background: #fff
  }

  .MuiBadge-colorSecondary {
    background-color: #E69E9E !important;
  }

  .sivil-recaptcha iframe,
  .sivil-recaptcha div {
      width: 100% !important;
      height: 100px;
  }

  .DatePickerMUI-active .MuiFormHelperText-contained,
  .DatePickerMUI .MuiFormHelperText-contained {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .DatePickerMUI-active  .MuiFilledInput-root,
  .DatePickerMUI  .MuiFilledInput-root {
    background-color: #fff !important;
  }

 .DatePickerMUI-active .MuiOutlinedInput-notchedOutline {
    border-color: #408BEC !important;
 }

  .custom-dropzone-area {
    padding: 24px;
    height: 200px !important;
    min-height: auto !important;
    background-color: #4762FA08 !important;
    border: 1px dashed #364F7431 !important;
  }

  .custom-dropzone-area .MuiTypography-root .MuiDropzoneArea-text .MuiTypography-h5 {
    font-size: 1rem !important;
  }

  .Custom-CarouselModal {
    overflow: visible !important;
  }

  .Custom-CarouselModal .Custom-CarouselModal-Btns:nth-child(2) {
    right: -100px !important;
  }

  .Custom-CarouselModal .Custom-CarouselModal-Btns:nth-child(3) {
    left: -100px !important;
  }

  .CustomSelectTablePagination option {
    text-align: center;
    margin: 0px;
    padding: 0px;
    display: inline-block;
  }

  span.MuiFormLabel-asterisk.MuiInputLabel-asterisk {
    color: red !important;
  }

  .InputFilledPhoneNumber {
    border: 0;
  }

  .InputFilledPhoneNumber::before {
    border: 0;
    display: none;
  }

  .InputFilledPhoneNumber::after {
    border: 0;
    display: none;
  }

  .InputFilledPhoneNumber input {
    background-color: #f8f8f9;
    height: 16px;
    font-size: 14px !important;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 0;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    outline: 0;
  
    &::placeholder {
      color: #00000061;
    }

    ${(props) =>
      props.bordered &&
      `
      border: 1px solid rgba(0, 0, 0, 0.23);
      background: transparent;
    `}
  }

  .MuiTableCell-root.MuiTableCell-root {
    padding: 8px 24px;
  }

  .MuiTabs-indicator {
    zoom: 116.1% !important;
  }

  .safari .MuiTabs-indicator {
    zoom: 100% !important;
  }
`;

export const OutLineInputBox = styled('input')`
  background-color: #f8f8f9;
  height: 40px;
  font-size: 14px !important;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 0;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  outline: 0;
  &::placeholder {
    color: #00000061;
  }
  ${(props) =>
    props.bordered &&
    `
    border: 1px solid rgba(0, 0, 0, 0.23);
    background: transparent;
  `}
`;

export const StyledButtonOutlined = styled(Button)`
  letter-spacing: 1px;
  font-size: 13px;
  font-weight: 500;
  margin-right: 10;
  width: fit-content;
  align-self: flex-end;
  border: 1px solid transparent;
  color: #4b7bff;

  &:hover {
    background: transparent;
    border: 1px solid transparent;
  }

  & .muibutton-label {
    font-size: 13px;
  }
`;

export const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    '& svg': {
      color: '#fff !important',
      marginRight: 15,
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #008858',
    background: '#008858 !important',
    color: '#fff',
    paddingRight: '35px !important',
    fontSize: 16,
    minWidth: 160,
    padding: '10px 50px 10px 20px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '& option': {
      color: '#333',
    },
    '&:focus': {
      borderRadius: 4,
    },
  },
}))(InputBase);

export const useGlobalStyles = makeStyles(() => ({
  boxProgressWithLabel: {
    width: 120,
    height: 120,
    borderRadius: '50%',
    position: 'absolute',
    zIndex: 1,
    background: 'rgba(255, 255, 255, .6',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  emphasis: {
    color: '#4b7bff',
    textTransform: 'capitalize',
    fontWeight: '600',
  },
}));

export const ImageContainer = styled.div`
  width: 120px;
  max-width: 120px;
  height: 120px;
  position: absolute;
  top: 0;
  opacity: 0;
  .MuiDropzoneArea-root {
    width: 120px !important;
    height: 120px !important;
    border-radius: 50% !important;
    max-width: 120px !important;
    min-width: 120px !important;
    max-height: 120px !important;
    min-height: 120px !important;
    margin-bottom: 0 !important;
  }
`;

export const BoxLeftContainer = styled(Box)`
  width: calc(100% - 570px);
  min-width: calc(100% - 570px);

  @media screen and (min-width: 1546px) {
    width: calc(100% - 620px);
    min-width: calc(100% - 620px);
  }

  @media screen and (min-width: 1800px) {
    width: calc(100% - 670px);
    min-width: calc(100% - 670px);
  }
`;

export const BoxContainer = styled(Box)`
  width: 550px;
  min-width: 550px;

  @media screen and (min-width: 1546px) {
    width: 600px;
    min-width: 600px;
  }

  @media screen and (min-width: 1800px) {
    width: 650px;
    min-width: 650px;
  }
`;

export const Paper = styled(Box)`
  background-color: #fff;
  border-radius: 5px;
`;

export const globalTheme = createTheme({
  overrides: {
    MuiButton: {
      textPrimary: {
        color: '#4b7bff',
      },
      containedPrimary: {
        backgroundColor: '#4b7bff',
      },
    },
    MuiTableHead: {
      root: {
        background: '#faf9fd',
      },
    },
    MuiTableCell: {
      head: {
        fontSize: 15,
      },
    },
    MuiTabs: {
      indicator: {
        zoom: '116.1% !important',
      },
    },
  },
});

export default GlobalStyles;
