import React, { useState } from 'react';
import {
  Box,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import moment from 'moment';
import PropTypes from 'prop-types';

import formatText from '../../utils/formatText';
import MenuTable from '../../components/MenuTable';
import { onTranslate } from '../../services/unarmed';
import AccordionWrap from '../../components/AccordionWrap';

const useStyles = makeStyles(() => ({
  formSection: {
    fontSize: 14,
    color: '#3B70FE',
    fontWeight: '500',
    margin: '20px 0',
  },
  label: {
    fontSize: 12,
    color: '#585858',
  },
}));

const timeRangeOptions = [
  { text: 'Night - 12:00am - 5:59am', value: 1 },
  { text: 'Morning - 6:00am - 11:59am', value: 2 },
  { text: 'Afternoon - 12:00pm - 5:59pm', value: 3 },
  { text: 'Evening - 6:00pm - 11:59pm', value: 4 },
];

export const getTimeRange = (form) => {
  const timeRange = timeRangeOptions.filter((time) => time.value === form?.location?.time);

  return timeRange[0]?.text;
};

const localeLan = ['English'];

const IncidentDetails = ({ form }) => {
  const classes = useStyles();
  const [translate, setTranslate] = useState('');
  const [options, setOptions] = useState(localeLan);
  const [statement, setStatement] = useState(() => form?.statement);

  const onSelect = (value) => {
    if (value === 'Show Original') {
      setTranslate('');
      setOptions(localeLan);
      setStatement(form?.statement);
      return;
    }
    onTranslate(statement).then(({ translations }) => {
      setStatement(translations?.[0]);
    });
    setOptions(['Show Original']);
    setTranslate(value);
  };
  return (
    <Box
      style={{
        background: '#fff',

        borderRadius: 5,
        marginTop: 20,
      }}
      boxShadow="0 3px 6px #00000005"
    >
      <AccordionWrap
        id="incident-details"
        header={
          <Box style={{ marginBottom: 10 }}>
            <Typography
              variant="h5"
              style={{
                fontWeight: '500',
                fontSize: 16,
                color: '#575A66',
              }}
            >
              Incident Details
            </Typography>
          </Box>
        }
      >
        <Box>
          {form?.type === 'COMPLAINT' && (
            <>
              <Typography className={classes.formSection}>Incident Information</Typography>
              <Box display="flex" alignItems="center" flexWrap="wrap">
                <Box width="100%" maxWidth="160px" marginRight="20px" marginBottom="10px">
                  <Typography variant="body2" className={classes.label}>
                    Address
                  </Typography>
                  <Tooltip title={form?.location?.address}>
                    <Typography variant="subtitle">
                      {formatText(form?.location?.address, 15) || 'Not specified'}
                    </Typography>
                  </Tooltip>
                </Box>
                <Box width="100%" maxWidth="130px" marginRight="20px" marginBottom="10px">
                  <Typography variant="body2" className={classes.label}>
                    City
                  </Typography>
                  <Tooltip title={form?.location?.city?.name}>
                    <Typography variant="subtitle">
                      {formatText(form?.location?.city?.name, 20) || 'Not specified'}
                    </Typography>
                  </Tooltip>
                </Box>
                <Box width="100%" maxWidth="130px" marginRight="20px" marginBottom="10px">
                  <Typography variant="body2" className={classes.label}>
                    State
                  </Typography>
                  {/* <Tooltip title={form?.location?.state?.name}> */}
                  <Typography variant="subtitle">
                    {formatText(form?.location?.state?.name, 20) || 'Not specified'}
                  </Typography>
                  {/* </Tooltip> */}
                </Box>
                <Box width="100%" maxWidth="130px" marginRight="20px" marginBottom="10px">
                  <Typography variant="body2" className={classes.label}>
                    Zip Code
                  </Typography>
                  {/* <Tooltip title={form?.location?.state?.name}> */}
                  <Typography variant="subtitle">
                    {formatText(form?.location?.zipCode, 20) || 'Not specified'}
                  </Typography>
                  {/* </Tooltip> */}
                </Box>
                <Box width="100%" maxWidth="160px" marginRight="20px" marginBottom="10px">
                  <Typography variant="body2" className={classes.label}>
                    Incident date
                  </Typography>

                  <Typography variant="subtitle">
                    {' '}
                    {form?.location?.datetime ? moment(form?.location?.datetime).format('MM/DD/YYYY') : 'Not specified'}
                  </Typography>
                </Box>
                <Box width="100%" maxWidth="160px" marginRight="20px" marginBottom="10px">
                  <Typography variant="body2" className={classes.label}>
                    Incident time
                  </Typography>

                  <Typography variant="subtitle"> {getTimeRange(form) || 'Not specified'}</Typography>
                </Box>
              </Box>
            </>
          )}
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography className={classes.formSection}>Statement/Description of incident</Typography>
            <Box width="160px">
              {/* <SelectBox
              options={['English']}
              showDefaultValue={false}
              allowPlaceHolder={false}
              placeholderText="Translate To"
              // value={source.value}
              // onChange={source.onChange}
              id="sourceSelectbox"
            /> */}

              <MenuTable allowChildren options={options} buttonPadding={false} onSelect={onSelect}>
                <Box
                  display="flex"
                  style={{ background: '#6D73850D' }}
                  padding="10px 15px"
                  minWidth="147px"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography
                    style={{
                      color: '#000000AD',
                      fontSize: 14,
                      textTransform: 'capitalize',
                      marginRight: 15,
                    }}
                  >
                    {translate === 'Show Original' || !translate ? 'Translate To' : translate}
                  </Typography>

                  <ArrowDropDownIcon htmlColor="#000000AD" />
                </Box>
              </MenuTable>
            </Box>
          </Box>
          <Grid container spacing={2}>
            {form?.type === 'COMPLIMENT' && (
              <>
                <Grid item xs={12}>
                  <Typography variant="body2" className={classes.label}>
                    Why would you like to thank this employee?
                  </Typography>
                  <Typography variant="subtitle">{form?.whyYouWantToThank || 'Not specified'}</Typography>
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Typography variant="body2" className={classes.label}>
                Statement
              </Typography>
              <Typography variant="subtitle">{statement}</Typography>
            </Grid>
          </Grid>

          <Typography className={classes.formSection}>Incident External Links</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {form?.links?.map((link, i) => (
                <Tooltip title={link} key={i}>
                  <a
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: 'rgb(75, 123, 255)',
                      display: 'block',
                      width: '100%',
                      marginBottom: 10,
                    }}
                  >
                    {formatText(link, 50)}
                  </a>
                </Tooltip>
              ))}
            </Grid>
          </Grid>

          <Typography className={classes.formSection}>Name of Police officers (if Known)</Typography>

          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left" style={{ minWidth: 60, width: 60, maxWidth: 60 }}>
                    N.
                  </TableCell>
                  <TableCell align="left" style={{ minWidth: 140, width: 140 }}>
                    First name
                  </TableCell>
                  <TableCell align="left" style={{ minWidth: 140, width: 140 }}>
                    Last name
                  </TableCell>
                  <TableCell align="left" style={{ minWidth: 180, width: 180 }}>
                    Badge/Serial number
                  </TableCell>
                  <TableCell align="left" style={{ minWidth: 140, width: 140, color: 'transparent' }}>
                    Badge/Serial number
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {form?.employees?.map((row, i) => (
                  <TableRow key={i}>
                    <TableCell align="left">{i + 1}</TableCell>
                    <TableCell align="left">{row.firstName}</TableCell>
                    <TableCell align="left">{row.lastName}</TableCell>
                    <TableCell align="left">{row.id}</TableCell>
                    <TableCell align="left" />
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {form?.type === 'COMPLAINT' && (
            <>
              <Typography className={classes.formSection}>Name of Police witness(es) or others involved</Typography>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left" style={{ minWidth: 60, width: 60, maxWidth: 60 }}>
                        N.
                      </TableCell>
                      <TableCell align="left" style={{ minWidth: 140, width: 140 }}>
                        First name
                      </TableCell>
                      <TableCell align="left" style={{ minWidth: 140, width: 140 }}>
                        Last name
                      </TableCell>
                      <TableCell align="left" style={{ minWidth: 140, width: 140 }}>
                        Phone number
                      </TableCell>
                      <TableCell align="left" style={{ minWidth: 180, width: 180 }}>
                        Email
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {form?.others?.map((row, i) => (
                      <TableRow key={i}>
                        <TableCell align="left">{i + 1}</TableCell>
                        <TableCell align="left">{row.firstName}</TableCell>
                        <TableCell align="left">{row.lastName}</TableCell>
                        <TableCell align="left">{row.phone}</TableCell>
                        <TableCell align="left">{row.email}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}

          {form?.type === 'COMPLIMENT' && (
            <>
              <Typography className={classes.formSection}>Event information</Typography>
              <Box display="flex" alignItems="center" flexWrap="wrap">
                <Box width="100%" maxWidth="150px" marginRight="20px" marginBottom="10px">
                  <Typography variant="body2" className={classes.label}>
                    Location of event
                  </Typography>
                  <Tooltip title={form?.location?.address}>
                    <Typography variant="subtitle">
                      {formatText(form?.location?.address, 20) || 'Not specified'}
                    </Typography>
                  </Tooltip>
                </Box>
                <Box width="100%" maxWidth="150px" marginRight="20px" marginBottom="10px">
                  <Typography variant="body2" className={classes.label}>
                    City
                  </Typography>
                  <Tooltip title={form?.location?.city?.name}>
                    <Typography variant="subtitle">
                      {formatText(form?.location?.city?.name, 20) || 'Not specified'}
                    </Typography>
                  </Tooltip>
                </Box>
                <Box width="100%" maxWidth="150px" marginRight="20px" marginBottom="10px">
                  <Typography variant="body2" className={classes.label}>
                    State
                  </Typography>
                  {/* <Tooltip title={form?.location?.state?.name}> */}
                  <Typography variant="subtitle">
                    {formatText(form?.location?.state?.name, 20) || 'Not specified'}
                  </Typography>
                  {/* </Tooltip> */}
                </Box>
                <Box width="100%" maxWidth="150px" marginRight="20px" marginBottom="10px">
                  <Typography variant="body2" className={classes.label}>
                    Zip Code
                  </Typography>
                  {/* <Tooltip title={form?.location?.state?.name}> */}
                  <Typography variant="subtitle">
                    {formatText(form?.location?.zipCode, 20) || 'Not specified'}
                  </Typography>
                  {/* </Tooltip> */}
                </Box>
                <Box width="100%" maxWidth="150px" marginRight="20px" marginBottom="10px">
                  <Typography variant="body2" className={classes.label}>
                    Date of event
                  </Typography>
                  <Typography variant="subtitle">
                    {form?.location?.datetime ? moment(form?.location?.datetime).format('MM/DD/YYYY') : 'Not specified'}
                  </Typography>
                </Box>
                <Box width="100%" maxWidth="150px" marginRight="20px" marginBottom="10px">
                  <Typography variant="body2" className={classes.label}>
                    Time of event
                  </Typography>
                  <Typography variant="subtitle"> {getTimeRange(form) || 'Not specified'}</Typography>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </AccordionWrap>
    </Box>
  );
};

IncidentDetails.propTypes = {
  form: PropTypes.object,
};

export default IncidentDetails;
