import React, { useState } from 'react';
import { Box, Typography, Avatar } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import formatTxt from '../utils/formatText';

/**
 * Renders a tooltip showing assigned users.
 *
 * @param {Array} users - An array of users assigned to a task.
 * @returns {JSX.Element} - A tooltip element displaying assigned users.
 */
const AssignedToTooltip = ({ users }) => {
  const [onHover, setOnHover] = useState(false);

  const usersToShow = users.slice(1);

  return (
    <Box display="flex" alignItems="center" justifyContent="flex-start" position="relative">
      {users.map((user, i) => {
        if (i === 0) {
          if (user?.photo?.url || user?.profile_img) {
            return (
              <Box
                display="flex"
                alignItems="center"
                onMouseEnter={() => setOnHover(true)}
                onMouseLeave={() => setOnHover(false)}
              >
                <Avatar
                  src={user?.photo?.url || user?.profile_img}
                  style={{ marginRight: 10, width: 30, height: 30 }}
                />
                <Typography variant="subtitle2">{`${formatTxt(user?.firstName, 20)} ${formatTxt(
                  user?.lastName,
                  20
                )}`}</Typography>
                {users.length > 1 && (
                  <span style={{ color: 'rgb(75, 123, 255)', marginLeft: 8 }}>(+{users.length - 1})</span>
                )}
              </Box>
            );
          }

          return (
            <Box
              display="flex"
              alignItems="center"
              onMouseEnter={() => setOnHover(true)}
              onMouseLeave={() => setOnHover(false)}
            >
              <AccountCircleIcon htmlColor="#364F74" fontSize="large" style={{ marginRight: 10 }} />
              <Typography variant="subtitle2">
                {' '}
                {user?.firstName ? `${user?.firstName} ${user?.lastName}` : 'Unassigned'}
              </Typography>
              {users.length > 1 && (
                <span style={{ color: 'rgb(75, 123, 255)', marginLeft: 8 }}>(+{users.length - 1})</span>
              )}
            </Box>
          );
        }

        return null;
      })}
      {users.length > 1 && (
        <Container visible={onHover}>
          {usersToShow.map((user, userKey) => (
            <Box
              display="flex"
              alignItems="center"
              key={user?._id}
              mb={userKey < usersToShow.length - 1 ? 2 : 0}
              style={userKey < usersToShow.length - 1 ? { paddingBottom: 16, borderBottom: '1px solid #e0e0e0' } : {}}
            >
              <Avatar src={user?.photo?.url || user?.profile_img} style={{ marginRight: 16, width: 40, height: 40 }} />
              <Typography variant="subtitle2">{`${formatTxt(user?.firstName, 20)} ${formatTxt(
                user?.lastName,
                20
              )}`}</Typography>
            </Box>
          ))}
        </Container>
      )}
    </Box>
  );
};

const Container = styled.div`
  position: absolute;
  left: -30px;
  top: 45px;
  width: 250px;
  background-color: #fff;
  border-radius: 8px;
  z-index: 99;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  visibility: hidden;

  ${(props) =>
    props.visible &&
    `
  visibility: visible;
  `}
`;

AssignedToTooltip.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      role: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default AssignedToTooltip;
