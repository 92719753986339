import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import useInput from '../../hooks/useInput';
import { createDashboard } from '../../services/unarmed';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 20,
    width: '100%',
    '& .makeStyles-root-51': {
      boxShadow: 'unset !important',
    },
  },
  buttonOutlined: {
    letterSpacing: '1px',
    fontSize: 13,
    fontWeight: '500',
    marginRight: 10,
    width: 'fit-content',
    alignSelf: 'flex-end',
    border: 0,
    color: '#4b7bff',
    '&:hover': {
      background: 'transparent',
      border: 0,
    },
    '& .MuiButton-label': {
      fontSize: 13,
    },
    '&:disabled': {
      border: 0,
    },
  },
  paper: {
    backgroundColor: '#fff',
    boxShadow: theme.shadows[5],
    padding: 30,
    maxWidth: 500,
    alignSelf: 'center',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default function CreateDashboard({ setOpen, setDashboards }) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const type = useInput();
  const name = useInput();
  const description = useInput();

  const onCreateDashboard = async () => {
    if (!type.value || !name.value || !description.value) {
      toast.error('Please fill in all inputs');
      return;
    }

    try {
      setLoading(true);
      const response = await createDashboard({
        name: name.value,
        type: type.value,
        description: description.value,
      });

      if (response.status === 200) {
        setLoading(false);
        setDashboards((prevState) => {
          const stateCopy = { ...prevState };
          stateCopy.data.dashboards = [response.data.dashboard, ...stateCopy.data.dashboards];
          return stateCopy;
        });
        setOpen(false);
        toast.success('New Dashboard was successfully created!');
      }
    } catch (error) {
      console.log(error)
      toast.error(error.response.data.error.details[0].message);
      setLoading(false);
    }
  };

  return (
    <>
      <Typography style={{ fontSize: 20, fontWeight: '500' }}>Create Dashboard</Typography>
      <Typography variant="body2">Widgets can be added via Report Explore or via the dashboard.</Typography>
      <Grid container spacing={2} style={{ marginTop: 10 }}>
        <Grid item xs={12}>
          <TextField
            id="standard-required1"
            label={<Typography> Dashboard Name</Typography>}
            style={{ width: '100%' }}
            value={name.value}
            onChange={name.onChange}
            inputProps={{
              'data-testid': 'create-dashboard-input-name',
            }}
          />
        </Grid>
        <Grid item xs={12} style={{ position: 'relative' }}>
          <TextField
            id="standard-required1"
            label={<Typography>Description</Typography>}
            style={{ width: '100%' }}
            value={description.value}
            onChange={description.onChange}
            inputProps={{
              'data-testid': 'create-dashboard-input-description',
            }}
          />
        </Grid>
      </Grid>
      <Box marginTop="30px">
        <Typography style={{ fontSize: 14, fontWeight: '500' }}>Type</Typography>
        <RadioGroup
          row
          aria-label="position"
          name="position"
          defaultValue="top"
          value={type.value}
          onChange={type.onChange}
          data-testid="create-dashboard-radio-type"
        >
          <FormControlLabel value="GLOBAL" control={<Radio color="primary" />} label="Global" />
          <FormControlLabel value="PRIVATE" control={<Radio color="primary" />} label="Private" />
        </RadioGroup>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="flex-end" marginTop="20px">
        <Button
          data-testid="create-dashboard-button-cancel"
          variant="outlined"
          className={classes.buttonOutlined}
          onClick={() => setOpen(false)}
        >
          CANCEL
        </Button>
        <Button
          data-testid="create-dashboard-button-create"
          variant="outlined"
          className={classes.buttonOutlined}
          onClick={onCreateDashboard}
          disabled={!type.value || loading}
        >
          {loading ? <CircularProgress color="#fff" size="25px" /> : 'CREATE'}
        </Button>
      </Box>
    </>
  );
}

CreateDashboard.propTypes = {
  setOpen: PropTypes.func.isRequired,
  setDashboards: PropTypes.func.isRequired,
};
