import React from 'react';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';

function DateTimePickerMUI({
  date,
  setdate,
  label,
  disableFuture,
  minDate,
  dateToCompare,
  type = 'start',
  propPadding = 10,
  disabled,
}) {
  const onChangeStartDate = (dt) => {
    const d1 = new Date(dt);
    const d2 = new Date(dateToCompare);
    if (d1.getTime() > d2.getTime() && dateToCompare) {
      return;
    }
    setdate(dt);
  };

  const onChangeEnd = (dt) => {
    const d1 = new Date(dt);
    const d2 = new Date(dateToCompare);
    if (d1.getTime() < d2.getTime() && dateToCompare) {
      return;
    }
    setdate(dt);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDateTimePicker
        variant="inline"
        minDate={minDate}
        autoOk
        ampm={true}
        style={{ width: '100%', paddingRight: 0 }}
        value={date}
        onChange={type === 'start' ? onChangeStartDate : onChangeEnd}
        inputVariant="outlined"
        inputProps={{
          style: {
            padding: 10,
            fontSize: 13,

            background: '#fff',
          },
        }}
        color="green"
        onError={console.log}
        placeholder={label}
        disabled={disabled}
        disableFuture={disableFuture}
        icon={<CalendarTodayIcon fontSize="small" />}
        KeyboardButtonProps={{
          'aria-label': 'change date',
          style: { padding: propPadding },
        }}
        format="yyyy/MM/dd hh:mm a"
      />
    </MuiPickersUtilsProvider>
  );
}

export default DateTimePickerMUI;
