import React from 'react';
import { Box, Button, CircularProgress, Divider, Typography, Grid, makeStyles, FormControlLabel } from '@material-ui/core';
import { CustomSwitch } from '../formEngine/components/WrapperComponent';
import propTypes from 'prop-types';
import { OutLineInput } from '../pages/CaseDetails/UpdateCase';
import SelectBox from './SelectBox';
import { activeColors } from './CaseFilters';

const useStyles = makeStyles(() => ({
  button: {
    background: ' #3B70FE',
    textTransform: 'capitalize',
    color: '#fff',
    '&:hover': {
      background: '#3B70FE',
    },
    '& .MuiButton-label': {
      fontSize: 14,
    },
    marginTop: 10,
    marginBottom: 20,
  },
}));

/**
 * Functional component representing a set of filters for contacts.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.name - The input for filtering by name.
 * @param {Object} props.phoneNumber - The input for filtering by phone number.
 * @param {Object} props.email - The input for filtering by email address.
 * @param {Object} props.module - The select box for filtering by module.
 * @param {boolean} props.loading - Indicates whether the component is in a loading state.
 * @param {Function} props.onClearFilters - Function to clear all filters.
 * @param {Function} props.onClickCancel - Function to handle the cancel action.
 * @param {Function} props.onClickApply - Function to handle the apply action.
 * @returns {JSX.Element} - The rendered component.
 */
const ContactsFilters = ({
  name,
  phoneNumber,
  email,
  module,
  // type,
  flaggedOnly,
  loading,
  onClearFilters,
  onClickCancel,
  onClickApply,
  filtersApplied
}) => {
  const classes = useStyles();

  return (
    <Box
      boxShadow="0 4px 8px rgba(0,0,0,.1)"
      style={{ background: '#fff' }}
      padding="10px 20px"
      borderRadius="5px"
      marginTop="20px"
    >
      <Grid container alignItems="flex-start">
        <Grid item xs={2} style={{ marginRight: 8 }}>
          <Typography
            style={{
              display: 'block',
              fontSize: 13,
              fontWeight: 500,
              marginBottom: 3,
            }}
          >
            Email
          </Typography>
          <OutLineInput
            bordered
            active={filtersApplied.email}
            placeholder="Write email address"
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              'aria-label': 'weight',
              'data-testid': 'contacts-filter-input-email',
            }}
            value={email.value || ''}
            onChange={(e) => email.setValue(e.target.value.replace(/[^\w \.@_-]/g, ''))}
            labelWidth={0}
          />
        </Grid>
        <Grid item xs={2} style={{ marginRight: 8 }}>
          <Typography
            style={{
              display: 'block',
              fontSize: 13,
              fontWeight: 500,
              marginBottom: 3,
            }}
          >
            Name
          </Typography>
          <OutLineInput
            bordered
            active={filtersApplied.name}
            placeholder="Write name"
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              'aria-label': 'weight',
              'data-testid': 'contacts-filter-input-name',
            }}
            value={name.value || ''}
            onChange={name.onChange}
            labelWidth={0}
          />
        </Grid>
        {/* <Grid item xs={2} style={{ marginRight: 8 }}>
          <Typography
            style={{
              display: 'block',
              fontSize: 13,
              fontWeight: 500,
              marginBottom: 3,
            }}
          >
            Type
          </Typography>
          <SelectBox
            id="type"
            dataTestId="contacts-filter-select-type"
            options={['Individual', 'Organization', 'not specified']}
            outlined
            value={type.value}
            onChange={type.onChange}
            withColors={filtersApplied.type ? activeColors : null}
          />
        </Grid> */}
        <Grid item xs={2} style={{ marginRight: 8 }}>
          <Typography
            style={{
              display: 'block',
              fontSize: 13,
              fontWeight: 500,
              marginBottom: 3,
            }}
          >
            Phone Number
          </Typography>
          <OutLineInput
            bordered
            active={filtersApplied.phoneNumber}
            placeholder="Write phone number"
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              'aria-label': 'weight',
              'data-testid': 'contacts-filter-input-phone-number',
            }}
            type="number"
            value={phoneNumber.value || ''}
            onChange={phoneNumber.onChange}
            labelWidth={0}
          />
        </Grid>
        <Grid item xs={2} style={{ marginRight: 8 }}>
          <Typography
            style={{
              display: 'block',
              fontSize: 13,
              fontWeight: 500,
              marginBottom: 3,
            }}
          >
            Module
          </Typography>
          <SelectBox
            id="module"
            dataTestId="contacts-filter-select-module"
            options={['cases', 'projects', 'not specified']}
            outlined
            value={module.value}
            onChange={module.onChange}
            withColors={filtersApplied.module ? activeColors : null}
          />
        </Grid>
        <Grid item xs={2} style={{ marginRight: 8 }}>
          <Box display="flex" width="100%" marginTop="20px">
            <Box minWidth="170px" maxWidth="180px" width="100%">
              <FormControlLabel
                style={{ margin: 0 }}
                control={<CustomSwitch checked={flaggedOnly.value} onChange={flaggedOnly.onChange} />}
                labelPlacement="start"
                label="Flagged only"
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Divider style={{ display: 'block', width: '100%', margin: '15px 0' }} />
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center" color="#1F63FF">
          <Typography
            data-testid="contacts-filter-button-clear-all-filters"
            style={{ fontSize: 13, fontWeight: '500', cursor: 'pointer' }}
            onClick={onClearFilters}
          >
            Clear All Filters{' '}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Button
            data-testid="contacts-filter-button-cancel"
            onClick={onClickCancel}
            style={{ textTransform: 'capitalize', marginRight: 16 }}
          >
            Cancel
          </Button>
          <Button
            data-testid="contacts-filter-button-apply"
            onClick={onClickApply}
            className={classes.button}
            color="primary"
            variant="contained"
          >
            {loading ? <CircularProgress color="#fff" size="25px" /> : 'Apply'}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

ContactsFilters.propTypes = {
  name: propTypes.object,
  email: propTypes.object,
  loading: propTypes.bool,
  phoneNumber: propTypes.object,
  flaggedOnly: propTypes.bool,
  module: propTypes.object,
  // type: propTypes.object,
  onClearFilters: propTypes.func,
  onClickCancel: propTypes.func,
  onClickApply: propTypes.func,
  filtersApplied: propTypes.object,
};

export default ContactsFilters;
