import { Box } from '@material-ui/core';
import React from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import SelectBox from '../../../components/SelectBox';
import { addOfficerComments } from '../../../services/unarmed';
import CaseComment from '../../CaseDetails/CaseComment';
import Card from '../../../components/Card';
import { Paper } from '../../../styles/GlobalStyles';

export default function NoteUpdate({
  organizationId,
  officerId,
  type,
  label,
  options = [],
  value,
  onChange,
  onResetSelect,
  setShouldRefetch,
}) {
  const handleSave = async (files, comment, resetValues) => {
    try {
      const reqData = {
        type,
        ...(value && type === 'MONITOR' ? { meeting: value?.replace('months meeting', '') } : {}),
        ...(value && type === 'INTERVENE' ? { intervene: value } : {}),
        ...(comment ? { comment } : {}),
        files,
      };

      await addOfficerComments(officerId, reqData);
      setShouldRefetch((prevState) => !prevState);
      onResetSelect();
      resetValues();
      toast.success('Comment added successfully');
    } catch (error) {
      toast.error(error.response?.error.details[0].message || 'something went wrong');
    }
  };

  return (
    <Box marginLeft={2}>
      <Box marginBottom={2}>
        <Card title={label}>
          <SelectBox
            options={options}
            hidePlaceholderWhenSelectedValue
            value={value}
            onChange={onChange}
            id="subtypeSelectbox"
          />
        </Card>
      </Box>
      <Paper>
        <CaseComment organizationId={organizationId} containerStyles={null} callBack={handleSave} />
      </Paper>
    </Box>
  );
}

NoteUpdate.propTypes = {
  organizationId: PropTypes.number.isRequired,
  officerId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.array,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  onResetSelect: PropTypes.func.isRequired,
  setShouldRefetch: PropTypes.func.isRequired,
};
