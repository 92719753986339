import { Box, makeStyles, Tabs, Switch } from '@material-ui/core';
import React, { useState } from 'react';

import { withStyles } from '@material-ui/styles';
import { blue } from '@material-ui/core/colors';

import TabPanel from '../../../components/TabPanel';
import { TabComp } from '../../CaseDetails/CaseDetails';

export const useStylesStaticWrapper = makeStyles(() => ({
  container: {
    width: '100%',
    borderRadius: 5,
    border: '1px solid #2E66FE33',
  },
  header: {
    background: '#fff',
    borderRadius: '5px 5px 0 0',
    borderBottom: '1px solid #2E66FE1A',
  },
  body: {
    background: '#F9FAFD80',
    borderRadius: 5,
  },
  button: {
    background: '#1F63FF',
    textTransform: 'capitalize',
    cursor: 'pointer',
    minWidth: 36,
    boxShadow: 'unset',
    color: '#fff',
    padding: '6px 16px',
    '&:hover': {
      background: '#1F63FF',
      boxShadow: 'unset',
    },
  },
  dndContainer: {
    display: 'flex',
    alignItems: 'center',
    transition: 'ease all .3s',
  },
}));

export function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export const CustomSwitch = withStyles({
  switchBase: {
    '&$checked': {
      color: blue.A700,
    },
    '&$checked + $track': {
      backgroundColor: blue.A700,
    },
  },
  checked: {},
  track: {},
})(Switch);

export default function StaticWrapper({ renderSettings = null, renderPreview = () => 'Preview', visible }) {
  const classes = useStylesStaticWrapper();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.dndContainer}>
      <Box style={{ padding: '0 20px', width: '100%' }}>
        <Box className={classes.container}>
          <Box display="flex" justifyContent="space-between" alignItems="center" className={classes.header}>
            <Box>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="simple tabs example"
                indicatorColor="primary"
                textColor="primary"
              >
                <TabComp label="Settings" {...a11yProps(0)} />
                {renderPreview && <TabComp label="Preview" {...a11yProps(1)} />}
              </Tabs>
            </Box>
            <Box display="flex" alignItems="center" style={{ paddingRight: 30 }}>
              <CustomSwitch checked={visible.value} onChange={visible.onChange} />
            </Box>
          </Box>
          <Box className={classes.body}>
            <TabPanel value={value} index={0} marginBottom={0}>
              {renderSettings()}
            </TabPanel>
            {renderPreview && (
              <TabPanel value={value} index={1} marginBottom={0}>
                {renderPreview()}
              </TabPanel>
            )}
          </Box>
        </Box>
      </Box>
    </div>
  );
}
