import { Box, CircularProgress, Typography, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { Editor } from '@tinymce/tinymce-react';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import PropTypes from 'prop-types';

import AssignUser from '../../../components/AssignUser';
import DateTimePickerMUI from '../../../components/DateTimePickerMUi';
import useUsers from '../../../hooks/useUsers';
import DashButton from '../../../components/DashButton';
import useInput from '../../../hooks/useInput';
import useProjectTypes from '../../../hooks/useProjectTypes';
import OutlineSelect from './OutlineSelect';
import { TINYMCE_DEFAULT_CONFIG, TINYMCE_SCRIPT_SRC } from '../../../config/config';
import ModalUploadFile from '../../../components/ModalUploadFile';
import CarouselModal from '../../../components/CarouselModal';
import Attachment from '../../../components/Attachment';
import Card from '../../../components/Card';

const useStyles = makeStyles(() => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  headerTitle: {
    marginRight: 8,
    fontWeight: '500',
  },
  dropzoneBox: {
    background: '#4762FA08',
    borderRadius: 4,
    border: '1px dashed #364F7431',
    padding: 34,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: 16,
  },

  dropzoneText: {
    display: 'block',
    fontSize: 12,
    marginTop: 10,
    textAlign: 'center',
  },
  deleteIcon: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: '35%',
    margin: 'auto',
    cursor: 'pointer',
  },
  viewIcon: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: '35%',
    right: 0,
    margin: 'auto',
    cursor: 'pointer',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  itemContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    margin: '40px auto 10px',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 15,
  },
  names: {
    fontWeight: '500',

    minWidth: 250,
  },
  actionContainer: {
    marginLeft: 80,
  },
  columnText: {
    fontWeight: '500',
  },
  label: {
    fontWeight: '500',
    fontSize: 14,
    margin: '10px 0 10px',
  },
  featureItem: {
    width: 137,
  },
  timeSpent: {
    height: 64,
    fontSize: 16,
    marginTop: 17,
  },
  required: {
    color: 'red',
    fontSize: 16,
  },
  link: {
    display: 'flex',
    position: 'absolute',
    top: 10,
    right: 80,
    cursor: 'pointer',
    padding: 13,
    zIndex: 999999,
    borderRadius: '50%',
    background: '#494949',
  },
}));

export default function ActionCard({ users = [], onSaveActions, organizationId, actionSettings }) {
  const classes = useStyles();

  const [files, setFiles] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [filesSelected, setFilesSelected] = React.useState([]);
  const [loadingOpenFiles, setLoadingOpenFiles] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const notes = useInput('');
  const [assignedTo, setAssignedTo] = useState([]);
  const [, assignedUsers, setAssignedUsers] = useUsers([], false, false, users);
  const [openModalUploadFile, setOpenModalUploadFile] = useState(false);

  const onAssignUser = (_, callback) => {
    setAssignedTo([_[0]?._id]);
    callback();
  };

  const { register, watch } = useForm();

  const [typesOptions, subTypeOptions] = useProjectTypes(actionSettings?.types, watch('type'));

  const onDeleteUser = () => {
    setAssignedTo([]);
    setAssignedUsers([{}]);
  };

  const onDeleteFile = (file) => {
    const newFiles = [...files.filter((_file) => _file?._id !== file?._id)];

    setFiles(newFiles);
  };

  const getActionsRequired = () => {
    const requiredActions = [];

    if (actionSettings?.settings?.times?.required) {
      if (!startDate || !endDate) {
        requiredActions.push('true');
      }
    } else if (actionSettings?.settings?.notes?.required) {
      if (!notes) {
        requiredActions.push('true');
      }
    } else if (actionSettings?.settings?.assignedTo?.required) {
      if (!assignedTo?.length) {
        requiredActions.push('true');
      }
    } else if (actionSettings?.settings?.attachments?.required) {
      if (!files?.length) {
        requiredActions.push('true');
      }
    }

    return requiredActions.length;
  };

  const handleOpen = (imgUrl, filesOp) => {
    setOpen(true);
    const filesToOpen = filesOp.filter(
      (flop) => flop.mimetype.includes('image') || flop.mimetype.includes('video') || flop.mimetype.includes('audio')
    );
    const selectedFile = filesToOpen.find((flop) => flop.public_url === imgUrl);
    const selectedFileIndex = filesToOpen.indexOf(selectedFile);
    filesToOpen.splice(selectedFileIndex, 1);
    filesToOpen.unshift(selectedFile);
    setFilesSelected(filesToOpen);
    setLoadingOpenFiles(
      filesToOpen.map(() => ({
        loading: false,
      }))
    );
  };

  const handleSave = async () => {
    setLoading(true);
    const currentAction = {
      values: {
        ...(startDate ? { startDateTime: startDate } : {}),
        ...(endDate ? { endDateTime: endDate } : {}),
        ...(notes.value ? { notes: notes.value } : {}),
        ...(assignedTo?.length && typeof assignedTo[0]?._id ? { assignedTo } : {}),
        ...(files?.length
          ? {
              attachments: files?.map((attachment) => ({
                ...(attachment.hashId
                  ? { file: attachment.hashId, tags: attachment?.tags?.map((tag) => tag?._id) || [] }
                  : { _id: attachment._id }),
              })),
            }
          : {}),
        ...(watch('type') && { type: watch('type') }),
        ...(watch('subType') && { subType: watch('subType') }),
      },
      settings: {
        times: {
          required: actionSettings?.settings?.times?.required,
          visible: actionSettings?.settings?.times?.visible,
        },
        notes: {
          required: actionSettings?.settings?.notes?.required,
          visible: actionSettings?.settings?.notes?.visible,
        },
        assignedTo: {
          required: actionSettings?.settings?.assignedTo?.required,
          visible: actionSettings?.settings?.assignedTo?.visible,
        },
        attachments: {
          visible: actionSettings?.settings?.attachments?.visible,
          required: actionSettings?.settings?.attachments?.required,
        },
      },
    };
    try {
      await onSaveActions(currentAction);
    } catch (error) {
      toast(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box display="flex" alignItems="center" width="100%">
      <Card title="New Action">
        <Box>
          <Box className={classes.header}>
            <Box display="flex" alignItems="flex-start" justifyContent="space-between" width="100%" />
          </Box>
          <Box>
            <OutlineSelect
              label="Type"
              register={register}
              registerKey="type"
              options={typesOptions?.map((tp) => tp.value)}
              required={false}
            />
          </Box>
          <Box marginTop={1}>
            <OutlineSelect
              label="SubType"
              register={register}
              registerKey="subType"
              options={subTypeOptions}
              required={false}
            />
          </Box>
          <Box style={{ gap: 20 }} marginTop={2} position="relative" justifyContent="space-between" flexWrap="wrap">
            {actionSettings?.settings?.times?.visible && (
              <Box marginBottom={2}>
                <Box className={classes.featureItem} style={{ width: '100%' }}>
                  <Typography variant="body2" className={classes.label}>
                    Start Date Time:{' '}
                    {actionSettings?.settings?.times?.required && <span className={classes.required}>*</span>}
                  </Typography>

                  <DateTimePickerMUI
                    date={startDate || null}
                    setdate={(date) => setStartDate(date)}
                    dateToCompare={endDate}
                  />
                </Box>
                <Box className={classes.featureItem} style={{ width: '100%' }} marginBottom={2}>
                  <Typography variant="body2" className={classes.label}>
                    End Date Time:{' '}
                    {actionSettings?.settings?.times?.required && <span className={classes.required}>*</span>}
                  </Typography>
                  <DateTimePickerMUI
                    date={endDate || null}
                    type="end"
                    setdate={(date) => setEndDate(date)}
                    dateToCompare={startDate}
                    disabled={!startDate}
                  />
                </Box>
              </Box>
            )}
            {actionSettings?.settings?.assignedTo?.visible && (
              <Box display="flex" marginTop={1} marginBottom={3}>
                <AssignUser
                  assignedUsers={assignedUsers}
                  setAssignedUsers={setAssignedUsers}
                  defaultUsers={users}
                  onAssignUser={onAssignUser}
                  loadingRemoveUser={false}
                  onDeleteUser={onDeleteUser}
                  alignX={0}
                  addMoreUser={false}
                  required={actionSettings?.settings?.assignedTo?.required}
                />
              </Box>
            )}
          </Box>
          {actionSettings?.settings?.notes?.visible && (
            <Box>
              <Typography variant="body2" className={classes.label}>
                Notes {actionSettings?.settings?.notes?.required && <span className={classes.required}>*</span>}
              </Typography>
              <Editor
                tinymceScriptSrc={TINYMCE_SCRIPT_SRC}
                init={{
                  ...TINYMCE_DEFAULT_CONFIG,
                  placeholder: 'Add a note',
                  height: 200,
                }}
                value={notes.value}
                onEditorChange={(newValue) => {
                  notes.onChange({ target: { value: newValue } });
                }}
              />
            </Box>
          )}
          {actionSettings?.settings?.attachments?.visible && (
            <Box marginTop={3}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="body2" className={classes.label}>
                  Attachments{' '}
                  {actionSettings?.settings?.attachments?.required && <span className={classes.required}>*</span>}
                </Typography>
                <Box display="flex" onClick={() => setOpenModalUploadFile(true)} style={{ cursor: 'pointer' }}>
                  <AttachFileIcon fontSize="small" htmlColor="#4b7bff" />{' '}
                  <Typography style={{ color: '#4b7bff', fontSize: 14, fontWeight: '500' }}>New attachments</Typography>
                </Box>
              </Box>
              <Box width="100%">
                {files?.map((file) => (
                  <Attachment
                    columnImageSize={3}
                    columnTextSize={9}
                    fileId={file?._id}
                    key={file?._id || file?.url}
                    id={file._id}
                    defaultTags={file?.tags || []}
                    name={file?.name}
                    size={file?.size || 0}
                    url={file?.url || file?.public_url}
                    loading={file?.loading || false}
                    mimetype={file?.mimetype || file?.mimetype}
                    withAddBtn={false}
                    rightCreateTag={-235}
                    onClickDelete={() => onDeleteFile(file)}
                    onClickPreview={() => {
                      if (file.mimetype.includes('pdf')) {
                        const link = document.createElement('a');
                        link.href = file.preview_url;
                        link.setAttribute('target', '_blank');
                        document.body.appendChild(link);
                        link.click();
                        return;
                      }
                      handleOpen(file.public_url, files);
                    }}
                  />
                ))}
              </Box>
            </Box>
          )}
          <Box marginTop={2} display="flex" justifyContent="flex-end">
            <DashButton disabled={getActionsRequired() || loading} onClick={handleSave}>
              {loading ? <CircularProgress color="#fff" size="25px" /> : 'Create Action'}
            </DashButton>
          </Box>
        </Box>
      </Card>
      <CarouselModal
        open={open}
        setOpen={setOpen}
        filesSelected={{
          length: filesSelected.length,
          selectedFileIndex: 0,
          files:
            filesSelected?.map((file) => ({
              download_url: file.download_url,
              name: '',
              mimetype: file.mimetype,
              public_url: file.public_url,
              tags: file?.tags || [],
            })) || [],
        }}
        loadingOpenFiles={loadingOpenFiles}
        setLoadingOpenFiles={setLoadingOpenFiles}
      />
      <ModalUploadFile
        organizationId={organizationId}
        open={openModalUploadFile}
        onClose={() => setOpenModalUploadFile(false)}
        onConfirm={(_, _files) => {
          setFiles((prevFiles) => {
            const newFiles = [...prevFiles, ..._files];

            return newFiles;
          });
          setOpenModalUploadFile(false);
        }}
      />
    </Box>
  );
}

ActionCard.propTypes = {
  users: PropTypes.array,
  onSaveActions: PropTypes.func.isRequired,
  organizationId: PropTypes.number.isRequired,
  actionSettings: PropTypes.object.isRequired,
};
