import React from 'react';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import cloudWrong from '../assets/nube_wrong.svg';
import DashButton from '../components/DashButton';
import LogoHome from '../components/LogoHome';

const InvitationConflict = ({
  title = 'Looks like you are already logged into an existing account',
  text = 'Please use a different browser to open this link or use the sign out button below',
  onClickLogout,
}) => (
  <Container>
    <Card>
      <LogoHome />
      <Image src={cloudWrong} />
      <Title>{title}</Title>
      <Text>{text}</Text>
      {onClickLogout && (
        <DashButton
          style={{
            width: 126,
            height: 46,
          }}
          onClick={onClickLogout}
        >
          <span style={{ marginRight: 8, fontSize: 18 }}>Logout</span>
          <ExitToAppIcon />
        </DashButton>
      )}
    </Card>
  </Container>
);

InvitationConflict.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  onClickLogout: PropTypes.func,
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 116.1vh;
  width: 100%;
  padding: 0px 48px;
  background-color: #f9fafd;
`;

const Card = styled.div`
  padding: 32px;
  border-radius: 8px;
  display: flex;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
`;

const Title = styled.h1`
  text-align: center;
  font-size: 32px;

  @media screen and (max-width: 689px) {
    font-size: 24px;
  }
`;

const Text = styled.p`
  font-size: 25px;
  text-align: center;
  margin-top: 16px;
  margin-bottom: 16px;

  @media screen and (max-width: 689px) {
    font-size: 16px;
  }
`;

const Image = styled.img`
  max-width: 100%;
  width: 320px;
  height: 320px;
  margin-bottom: 32px;
  margin-top: 32px;

  @media screen and (max-width: 689px) {
    width: 250px;
    height: 250px;
  }
`;

export default InvitationConflict;
