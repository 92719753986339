import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Fade, Paper, makeStyles, Typography, Box } from '@material-ui/core';
import { toast } from 'react-toastify';

import useInput from '../../hooks/useInput';
import { createContact } from '../../services/unarmed';
import ContactForm from './ContactForm';

/**
 * Material-UI styling hook.
 */
const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: theme.shadows[5],
    padding: 64,
    minWidth: 600,
    alignSelf: 'center',
    borderRadius: 4,
    maxHeight: '80vh',
    overflowY: 'scroll',
    maxWidth: 700,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

/**
 * Parses the provided phone number by removing spaces, parentheses, and dashes.
 *
 * @param {string} phoneNumber - The phone number to parse.
 * @returns {string} The parsed phone number.
 */
export const parseContactPhoneNumber = (phoneNumber) =>
  phoneNumber?.replace(' ', '')?.replace('(', '')?.replace(')', '')?.replace('-', '');

/**
 * Modal for creating a new contact.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.open - Whether the modal is open.
 * @param {Function} props.onClose - Function to handle modal closure.
 * @param {Function} props.onUpdate - Function to handle updates after contact creation.
 */
const ModalCreateContact = ({ open, onClose, onUpdate }) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  const firstName = useInput('');
  const lastName = useInput('');
  const phoneNumber = useInput('');
  const email = useInput('');
  const gender = useInput([]);
  const raceEthnicity = useInput([]);
  const type = useInput('');
  const companyName = useInput('');

  /**
   * Clears input fields.
   */
  const onClearFields = () => {
    firstName.setValue('');
    lastName.setValue('');
    phoneNumber.setValue('');
    email.setValue('');
    gender.setValue([]);
    type.setValue('');
    raceEthnicity.setValue([]);
  };

  const handleOnClose = () => {
    onClose();
    onClearFields();
  };

  /**
   * Handles the save action for creating a new contact.
   */
  const handleOnSave = useCallback(() => {
    const phoneNumberParsed = parseContactPhoneNumber(phoneNumber.value);

    if (email && email.value === '') {
      toast.error('The email is required.');
      return;
    }

    if (phoneNumber.value && phoneNumberParsed.length < 10) {
      toast.error('The phone number must have 10 digits.');
      return;
    }

    if (type.value === 'Organization' && companyName.value === '') {
      toast.error('The company name is required.');
      return;
    }

    const data = {
      ...(firstName.value ? { firstName: firstName.value } : {}),
      ...(lastName.value ? { lastName: lastName.value } : {}),
      ...(phoneNumber.value ? { phoneNumber: phoneNumberParsed } : {}),
      ...(type.value ? { type: type.value } : {}),
      ...(type.value === 'Organization' && companyName.value ? { companyName: companyName.value } : {}),
      email: email.value,
      demographic: {
        gender: gender.value,
        raceEthnicity: raceEthnicity.value,
      },
    };

    setLoading(true);
    createContact(data)
      .then(() => {
        onClose();
        onUpdate();
        onClearFields();
        setLoading(false);
        toast.success('Contact created successfully!');
      })
      .catch((error) => {
        setLoading(false);

        const message = error?.response?.data?.error?.details[0]?.message || error?.response?.data?.message;

        toast.error(message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, phoneNumber, type, companyName, firstName, lastName, gender, raceEthnicity]);

  return (
    <Modal className={classes.modal} open={open} onClose={handleOnClose}>
      <Fade in={open}>
        <Paper className={classes.paper}>
          <Box mb={2}>
            <h1>New Contact</h1>
            <Typography variant="body2">Creation of new contacts</Typography>
          </Box>
          <ContactForm
            firstName={firstName}
            lastName={lastName}
            type={type}
            companyName={companyName}
            phoneNumber={phoneNumber}
            email={email}
            gender={gender}
            raceEthnicity={raceEthnicity}
            loading={loading}
            onClickClose={handleOnClose}
            onClickSave={handleOnSave}
            isEdit={false}
          />
        </Paper>
      </Fade>
    </Modal>
  );
};

ModalCreateContact.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onUpdate: PropTypes.func,
};

export default ModalCreateContact;
