import { Box, Button, CircularProgress, Divider, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import DeleteIcon from '@material-ui/icons/Delete';
import { useHistory } from 'react-router-dom';
import { onGetProjects, updateProject } from '../../../services/unarmed';

import MultiSelectBox from '../../../components/MultiSelectBox';

const useStyles = makeStyles(() => ({
  formSection: {
    fontSize: 14,
    color: '#3f51b5',
    fontWeight: '500',
    margin: '20px 0',
  },
  containerTitle: {
    padding: '10px 20px',
    borderBottom: '1px solid #e2e2e2',
  },
  resaltText: {
    color: '#3B70FE',
    fontWeight: 600,
    fontSize: 13,
  },
  label: {
    fontWeight: '500',
    fontSize: 14,
    margin: '10px 0 10px',
  },
  button: {
    background: ' #3B70FE',
    textTransform: 'capitalize',
    color: '#fff',
    '&:hover': {
      background: '#3B70FE',
    },
    '& .MuiButton-label': {
      fontSize: 14,
    },
    marginTop: 10,
    marginBottom: 20,
  },
  labelError: {
    fontSize: 12,
    color: 'red',
  },
}));

const findProjectById = (projects, projectId) => {
  const project = projects?.find((prj) => prj?._id === projectId);
  return project;
};

const SelectedProjectItem = ({ onRemove, project }) => {
  const history = useHistory();
  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        style={{ background: '#f2f2f2' }}
        padding="10px"
        marginBottom={1}
        borderRadius={5}
      >
        <Typography
          variant="body2"
          style={{ cursor: 'pointer' }}
          onClick={() => history.push(`/project/${project?._id}`)}
        >
          {project?.name}
        </Typography>
        <DeleteIcon onClick={onRemove} htmlColor="#6D7385" style={{ cursor: 'pointer' }} />
      </Box>
    </Box>
  );
};

const RelatedProjects = ({ relatedProjects, id, refetch }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);

  const formatProjectSelected = (selected) => {
    if (selected) {
      const selectedProjectsWithText = selected.map((projectId) => findProjectById(projects?.data, projectId)?.name);
      return selectedProjectsWithText.join(', ');
    }
    return selected.join(', ');
  };

  const getProjects = async () => {
    try {
      const { data, headers } = await onGetProjects();
      setProjects({
        data: data?.filter((project) => project?._id !== id),
        headers,
      });
      setSelectedProjects(relatedProjects);
    } catch (error) {
      toast.error('Cannot get projects');
    }
  };

  useEffect(() => {
    getProjects();
  }, []);

  const saveRelatedProjects = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await updateProject(id, { relatedProjects: selectedProjects });
      toast.success('Related projects have been updated successfully!');
      refetch();
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box style={{ background: '#fff', borderRadius: 5 }} boxShadow="0 3px 6px #00000005" mb="20px">
      <form onSubmit={saveRelatedProjects}>
        <Box className={classes.containerTitle}>
          <Typography variant="body2" style={{ fontWeight: '500', fontSize: 16 }}>
            Related Projects
          </Typography>
        </Box>
        <Box padding="10px 20px 15px" marginTop={3}>
          <MultiSelectBox
            value={selectedProjects}
            onChange={(e) => setSelectedProjects(e.target.value)}
            id="relatedProjects"
            callback={formatProjectSelected}
            options={
              {
                obj:
                  projects?.data?.map((project) => ({
                    text: project.name,
                    value: project._id,
                  })) || [],
              } || []
            }
          />
        </Box>
        <Divider />
        <Box padding="10px 20px " marginTop={3}>
          {selectedProjects?.map((project) => (
            <SelectedProjectItem
              project={findProjectById(projects?.data, project)}
              onRemove={() => setSelectedProjects(selectedProjects.filter((prj) => prj !== project))}
            />
          ))}
          <Box display="flex" justifyContent="flex-end" mt="20px">
            <Button
              data-testid="projects-related-projects-button-submit"
              variant="contained"
              className={classes.button}
              type="submit"
              disabled={loading}
            >
              {loading ? <CircularProgress color="#fff" size="25px" /> : 'Save'}
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default RelatedProjects;
