import {
  Box,
  CircularProgress,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import Alert from '../../components/Alert';
import DashButton from '../../components/DashButton';
import DnDArea from '../../components/DnDArea';
import { onSaveFormType } from '../../services/unarmed';
import Question from '../FAQ/components/Question';
import { ContainerPageDetail } from './PageDetails';

const useStyles = makeStyles(() => ({
  container: {
    padding: 20,
  },
  title: {
    marginTop: 50,
  },
  questionContainer: {
    width: '100%',
    maxWidth: 760,
    padding: 28,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    minHeight: 195,
    position: 'relative',
    borderRadius: 8,
    boxShadow: '0px 3px 6px #0000000D',
    backgroundColor: '#fff',
    marginTop: 20,
  },
}));

export default function CaseStatus({
  id,
  formInfo,
  isDefaultForm,
  setFormInfo,
}) {
  const classes = useStyles();
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingCasesType, setLoadingfaqs] = useState(false);

  useEffect(() => {
    if (formInfo?.availableStatuses) {
      setQuestions(formInfo?.availableStatuses);
    }
  }, [formInfo?.availableStatuses]);

  const addQuestion = () => {
    setQuestions([...questions, { status: '', description: '' }]);
  };

  const removeQuestion = (index) => {
    setQuestions(questions.filter((question, i) => i !== index));
  };

  const onSave = async () => {
    setLoading(true);
    try {
      const requestPayload = {
        availableStatuses: questions,
      };
      const { data } = await onSaveFormType(requestPayload, id);
      setFormInfo(data);
      toast.success('Case Status has been updated');
    } catch (error) {
      toast.error(error?.response?.data?.error?.details?.[0]?.message);
    } finally {
      setLoading(false);
    }
  };

  const renderer = (moveItem) =>
    questions.map((question, index) => (
      <Question
        questionIndex={index}
        moveItem={moveItem}
        key={index}
        labelQuestion="S"
        labelAnswer="D"
        placeholder="Status"
        question={question}
        setQuestions={setQuestions}
        removeQuestion={removeQuestion}
        limitAnswer={200}
        limitQuestion={20}
        styles={{ textTransform: 'uppercase' }}
        keyQuestion="status"
        keyAnswer="description"
        questionUpperCase
      />
    ));
  return (
    <>
      <PageWrapper className={classes.container}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          maxWidth="760px"
        >
          <Box>
            <Typography variant="h5">Case Status </Typography>
            <Typography style={{ fontSize: 14, color: '#747474' }}>
              Edit cases status that will be displayed on the portal case
              details page.
            </Typography>
          </Box>
          <DashButton onClick={onSave} disabled={isDefaultForm || loading}>
            {loading ? <CircularProgress color="#fff" size="20px" /> : 'Save'}{' '}
          </DashButton>
        </Box>
        {isDefaultForm && (
          <Alert status="info">
            <Typography style={{ fontSize: 14 }}>
              Default forms cannot be deleted or updated. Duplicate this form to
              edit.
            </Typography>
          </Alert>
        )}
        <Box className={classes.questionContainer}>
          {loadingCasesType ? (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              height={195}
            >
              <Typography style={{ marginBottom: 10 }}>Loading FAQs</Typography>
              <CircularProgress color="#1F63FF" size="20px" />
            </Box>
          ) : (
            <>
              <DndProvider backend={HTML5Backend}>
                <DnDArea
                  selectedItems={questions}
                  setSelectedItems={setQuestions}
                  renderer={renderer}
                />
              </DndProvider>
              <Box display="flex" alignItems="center" justifyContent="center">
                {isDefaultForm && (
                  <div
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      zIndex: 1,
                      background: 'rgba(0,0,0,0.01)',
                    }}
                  />
                )}
                <DashButton onClick={addQuestion}>ADD STATUS</DashButton>
              </Box>
            </>
          )}
        </Box>
      </PageWrapper>
    </>
  );
}

const PageWrapper = styled.div`
  width: 100%;
`;
