import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import moment from 'moment';

import TimeLineItem from '../Activity/TimeLineItem';
import { UserInfo } from '../../components/NoteLogs';
import HTMLContent from '../../components/HTMLContent';
import { PreviewImg } from '../CaseDetails/CaseComment';
import LogIcon from '../../components/LogIcon';
import Tags from '../../components/Tags';
import Tag from '../../components/Tag';
import { fetchContactHistory } from '../../services/unarmed';
import ArchivedImage from '../../components/ArchivedImage';

export const contactFieldsNames = {
  firstName: 'First name',
  lastName: 'Last name',
  suffix: 'Suffix',
  title: 'Title',
  pronouns: 'Pronouns',
  salutation: 'Salutation',
  phoneNumber: 'Phone number',
  description: 'Description',
  department: 'Department',
  gender: 'Gender',
  raceEthnicity: 'Race/Ethnicity',
  birthdate: 'Birthdate',
  photo: 'Photo',
  type: 'Type',
  companyName: 'Company Name',
  'demographic.gender': 'Gender',
  'demographic.raceEthnicity': 'Race/Ethnicity',
  'demographic.birthdate': 'Birthdate',
  'mailingLocation.address': 'Mailing address',
  'mailingLocation.city.name': 'Mailing city name',
  'mailingLocation.state.name': 'Mailing state name',
  'mailingLocation.zipCode': 'Mailing zip code',
  'mailingLocation.aptUnit': 'Mailing apt/unit #',
  'otherLocation.address': 'Other address',
  'otherLocation.city.name': 'Other city name',
  'otherLocation.state.name': 'Other state name',
  'otherLocation.zipCode': 'Other zip code',
  'otherLocation.aptUnit': 'Other apt/unit #',
};

const useStyles = makeStyles(() => ({
  emphasis: {
    color: '#4b7bff',
    textTransform: 'capitalize',
    fontWeight: '600',
  },
}));

/**
 * Parses the change value for display, converting arrays to comma-separated strings.
 *
 * @param {any} value - The value to be parsed.
 * @returns {string} The parsed value.
 */
export const parseChangeValue = (value, fieldName) => {
  if (fieldName === 'Birthdate') return moment(value).format('YYYY-MM-DD');

  if (Array.isArray(value)) {
    return value.join(', ');
  }

  return value;
};

/**
 * React component for displaying contact history logs.
 *
 * @component
 * @param {Object} props - The properties passed to the ContactHistory component.
 * @param {Array} props.logs - An array of contact history logs.
 */
const ContactHistory = ({ id }) => {
  const classes = useStyles();

  const [logs, setLogs] = useState([]);

  useEffect(() => {
    fetchContactHistory(id).then((res) => {
      setLogs(res.data);
    });
  }, [id]);

  /**
   * Handles the click events on log items.
   */
  const handleClickEvents = () => {};

  return (
    <div>
      {logs.map((log) => {
        let renderLog = null;

        if (log?.type === 'CONTACT_COMMENT_OR_ATTACHMENT_ADDED') {
          const comment = log?.data?.comment;
          const files = log?.data?.files;

          const hasFiles = files.length > 0;
          const hasComment = !!comment;
          const hasBoth = hasFiles && hasComment;
          renderLog = (
            <>
              <UserInfo user={log?.data?.user} />{' '}
              {hasComment && (
                <>
                  made a <span className={classes.emphasis}>new comment</span>
                </>
              )}
              {hasBoth && ' and '}
              {hasFiles && (
                <>
                  <span className={classes.emphasis}>uploaded {files.length}</span> file(s)
                </>
              )}{' '}
              to the contact
              {hasComment && (
                <>
                  <br />
                  <HTMLContent content={comment} />
                </>
              )}
              {hasFiles && (
                <>
                  <Box display="flex" flexDirection="column">
                    {files.map((file, index) => {
                      const showPreview = !file?.archivedDate;
                      return (
                        <>
                          <Box display="flex" alignItems="center" justifyContent="flex-start">
                            <PreviewImg
                              key={file._id}
                              style={
                                !file.name.includes('.pdf')
                                  ? { minHeight: 100, margin: 6 }
                                  : { minHeight: 35, width: 'fit-content' }
                              }
                            >
                              {showPreview ? (
                                <LogIcon fl={file} handleClickEvents={handleClickEvents(file)} index={index} />
                              ) : (
                                <ArchivedImage />
                              )}
                            </PreviewImg>
                            <Typography style={{ fontSize: 14 }}>{file.name}</Typography>
                          </Box>
                          <Tags autoUpdateTags={false} fileId={file?._id} defaultTags={file?.tags || []} />
                        </>
                      );
                    })}
                  </Box>
                </>
              )}
            </>
          );
        }

        if (log?.type === 'CONTACT_CREATED') {
          renderLog = (
            <>
              <UserInfo user={log?.data?.user} /> has created contact
            </>
          );
        }

        if (log?.type === 'CONTACT_UPDATED') {
          const changes = log?.data?.changes;

          renderLog = (
            <>
              {log?.data?.changes?.length > 0 ? (
                <>
                  {changes.map((change, changeKey) => {
                    const fieldName = contactFieldsNames[change.fieldName];

                    if (!fieldName) return null;

                    return (
                      <div key={changeKey}>
                        {change.oldValue ? (
                          <>
                            <UserInfo user={log?.data?.user} /> has changed{' '}
                            <span className={classes.emphasis}>{fieldName}</span> from{' '}
                            <span className={classes.emphasis}>{parseChangeValue(change.oldValue, fieldName)}</span> to{' '}
                            <span className={classes.emphasis}>{parseChangeValue(change.newValue, fieldName)}</span> in
                            contact
                          </>
                        ) : (
                          <>
                            <UserInfo user={log?.data?.user} /> has added{' '}
                            <span className={classes.emphasis}>{fieldName}</span>{' '}
                            <span className={classes.emphasis}>{parseChangeValue(change.newValue, fieldName)}</span> in
                            contact
                          </>
                        )}
                      </div>
                    );
                  })}
                </>
              ) : (
                <>
                  <UserInfo user={log?.data?.user} /> has updated the contact without changes.
                </>
              )}
            </>
          );
        }

        if (log?.type === 'CONTACT_COMMENT_OR_ATTACHMENT_EDIT') {
          const oldComment = log?.data?.oldComment;
          const newComment = log?.data?.newComment;

          renderLog = (
            <>
              <UserInfo user={log?.data?.user} /> edited a comment in the contact
              <br />
              <b>before:</b>
              <br />
              <HTMLContent content={oldComment} />
              <b>after:</b>
              <br />
              <HTMLContent content={newComment} />
            </>
          );
        }

        if (log?.type === 'PROJECT_CONTACT_ASSIGNED') {
          renderLog = (
            <>
              <UserInfo user={log?.data?.user} /> has added contact {log?.data?.contact?.email} to project{' '}
              <Link to={`/project/${log?.data?.project?._id}`}>
                <span className={classes.emphasis}>{log?.data?.project?.name}</span>
              </Link>
            </>
          );
        }

        if (log?.type === 'PROJECT_CONTACT_DELETED') {
          renderLog = (
            <>
              <UserInfo user={log?.data?.user} /> has removed contact {log?.data?.contact?.email} from project{' '}
              <Link to={`/project/${log?.data?.project?._id}`}>
                <span className={classes.emphasis}>{log?.data?.project?.name}</span>
              </Link>
            </>
          );
        }

        if (log?.type === 'CASE_CONTACT_ASSIGNED') {
          renderLog = (
            <>
              <UserInfo user={log?.data?.user} /> has added contact {log?.data?.contact?.email} to case{' '}
              <span className={classes.emphasis}>{log?.data?.form?.code}</span>
            </>
          );
        }

        if (log?.type === 'CASE_CONTACT_REMOVE') {
          renderLog = (
            <>
              <UserInfo user={log?.data?.user} /> has removed contact {log?.data?.contact?.email} from case{' '}
              <span className={classes.emphasis}>{log?.data?.form?.code}</span>
            </>
          );
        }

        if (log?.type === 'CONTACT_ATTACHMENT_TAGS_EDIT' || log?.type === 'CONTACT_ATTACHMENT_TAGS_ADDED') {
          renderLog = (
            <>
              <UserInfo user={log?.data?.user} /> has added {log?.data?.tags?.length > 1 ? 'tags' : 'tag'}{' '}
              {log?.data?.tags?.map((tag) => (
                <Tag bg={tag?.backgroundColor} color={tag?.textColor} key={tag?._id} text={tag?.name} />
              ))}{' '}
              to attachment: <span className={classes.emphasis}>{log?.data?.file?.name}</span> to contact:{' '}
              <span className={classes.emphasis}>{log?.data?.contact?.email}</span>
            </>
          );
        }

        if (log?.type === 'CONTACT_ATTACHMENT_TAGS_DELETE') {
          renderLog = (
            <>
              <UserInfo user={log?.data?.user} /> removed {log?.data?.tags?.length > 1 ? 'tags' : 'tag'}{' '}
              {log?.data?.tags?.map((tag) => (
                <Tag bg={tag?.backgroundColor} color={tag?.textColor} key={tag?._id} text={tag?.name} />
              ))}{' '}
              from attachment: <span className={classes.emphasis}>{log?.data?.file?.name}</span> from contact:{' '}
              <span className={classes.emphasis}>{log?.data?.contact?.email}</span>
            </>
          );
        }

        if (log?.type === 'CONTACT_ATTACHMENT') {
          renderLog = (
            <>
              <UserInfo user={log?.data?.user} /> added new attachment
            </>
          );
        }

        if (log?.type === 'CONTACT_ATTACHMENT_ARCHIVED') {
          renderLog = (
            <>
              <UserInfo user={log?.data?.user} /> has archived the file{' '}
              <span className={classes.emphasis}>{log?.data?.file?.name}</span> on the contact{' '}
              <span className={classes.emphasis}>{log?.data?.contact?.email}</span>.
            </>
          );
        }

        if (log?.type === 'CONTACT_NOTE_UNARCHIVED') {
          renderLog = (
            <>
              <UserInfo user={log?.data?.user} /> has unarchived the file{' '}
              <span className={classes.emphasis}>{log?.data?.comment?.comment}</span> on the contact{' '}
              <span className={classes.emphasis}>{log?.data?.contact?.email}</span>.
            </>
          );
        }

        if (log?.type === 'CONTACT_NOTE_ARCHIVED') {
          renderLog = (
            <>
              <UserInfo user={log?.data?.user} /> has archived the file{' '}
              <span className={classes.emphasis}>{log?.data?.comment?.comment}</span> on the contact{' '}
              <span className={classes.emphasis}>{log?.data?.contact?.email}</span>.
            </>
          );
        }


        if (log?.type === 'CONTACT_ATTACHMENT_UNARCHIVED') {
          renderLog = (
            <>
              <UserInfo user={log?.data?.user} /> has unarchived the file{' '}
              <span className={classes.emphasis}>{log?.data?.file?.name}</span> on the contact{' '}
              <span className={classes.emphasis}>{log?.data?.contact?.email}</span>.
            </>
          );
        }

        return (
          <TimeLineItem
            unread={log?.unread}
            profileImg={log?.data?.user?.photo?.public_url}
            createdAt={log.createdAt}
            key={log?._id}
            avatar={`${log?.data?.user?.firstName[0]}${log?.data?.user?.lastName[0]}`}
          >
            <Typography style={{ fontSize: 14 }}>{renderLog}</Typography>
          </TimeLineItem>
        );
      })}
    </div>
  );
};

ContactHistory.propTypes = {
  id: PropTypes.number,
};

export default ContactHistory;
