import { Box, FormControlLabel, Typography } from '@material-ui/core';
import { CustomSwitch } from '../../../formEngine/components/WrapperComponent';
import React, { useState, useEffect, useContext } from 'react';
import { Context } from '../../../Context';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { createProjectNote, onArchiveNoteProject, onUnArchiveNoteProject, updateNotesSortOrder } from '../../../services/unarmed';
import CaseComment from '../../CaseDetails/CaseComment';
import ProjectNoteLogs from '../components/ProjectNoteLogs';
import Card from '../../../components/Card';

const sortByCreatedAt = (data, order = 'desc') => {
  return data.sort((x, y) => {
    const dateX = new Date(x.createdAt).getTime();
    const dateY = new Date(y.createdAt).getTime();
    return order === 'asc' ? dateX - dateY : dateY - dateX;
  });
};

export default function ProjectNotes({ id, organizationId, logs, refetch }) {
  const { myUser } = useContext(Context);
 const initialSortOrder = localStorage.getItem('projectNotesSortOrder') || (myUser?.notesSortOrderDesc?.projects ? 'desc' : 'asc');

  const [archivedOnly, setArchivedOnly] = useState(false);
  const [sortOrder, setSortOrder] = useState(initialSortOrder);
  const [sortedLogs, setSortedLogs] = useState([]);

  const toggleSortOrder = () => {
    const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    const updatedSortOrder = newSortOrder === 'desc';

    updateNotesSortOrder(myUser?._id, 'projects', updatedSortOrder)
      .then(() => {
        setSortOrder(newSortOrder);
        localStorage.setItem('projectNotesSortOrder', newSortOrder);
      })
      .catch((error) => {
        toast.error('Failed to update sort order');
      });
  };

  const handleArchiveOnly = () => {
    setArchivedOnly(!archivedOnly)
  }

  useEffect(() => {
    setSortedLogs(sortByCreatedAt([...logs], sortOrder));
  }, [sortOrder, logs]);

  return (
    <Box display="flex">
      <Card
        title={
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box>
              <Typography variant="h6">Notes</Typography>
            </Box>
            <Box style={{ display: 'flex'}}>
              <FormControlLabel
                control={
                  <CustomSwitch
                    checked={sortOrder === 'desc'}
                    onChange={toggleSortOrder}
                    color="primary"
                  />
                }
                label={sortOrder === 'desc' ? 'Most Recent' : 'Oldest'}
                labelPlacement="end"
                style={{ marginLeft: '500px' }}
              />
              <FormControlLabel
                      style={{ margin: 0 }}
                      control={<CustomSwitch checked={archivedOnly} onChange={handleArchiveOnly} />}
                      labelPlacement="end"
                      label="Archived only"
                    />
            </Box>
          </Box>
        }
      >
        <ProjectNoteLogs noteLogs={sortedLogs} sortOrder={sortOrder} archivedOnly={archivedOnly} project={id} />
      </Card>
      <CaseComment
        organizationId={organizationId}
        callBack={async (files, text, resetValues) => {
          await createProjectNote(id, { text, attachments: files });
          refetch();
          resetValues();
        }}
      />
    </Box>
  );
}

ProjectNotes.propTypes = {
  id: PropTypes.string.isRequired,
  organizationId: PropTypes.string.isRequired,
  logs: PropTypes.arrayOf(
    PropTypes.shape({
      createdAt: PropTypes.string.isRequired,
    })
  ).isRequired,
  refetch: PropTypes.func.isRequired,
};
