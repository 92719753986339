import { Box, MenuItem, Typography } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import React from 'react';
import PopperPortal from '../../../components/PopperPortal';

export default function ConditionalSelect({ options = [], onChange, value }) {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box style={{ postion: 'relative' }}>
      <Box
        height={40}
        minWidth={200}
        border="1px solid #0000001f"
        borderRadius="4px"
        padding="2.5px 10px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        style={{ cursor: 'pointer' }}
        onClick={() => setOpen(true)}
      >
        {value ? (
          <Typography>{value}</Typography>
        ) : (
          <Typography style={{ fontSize: 14, color: '#00000040' }}>Select An Option</Typography>
        )}

        <ArrowDropDownIcon htmlColor="rgba(0, 0, 0, 0.54)" />
      </Box>

      <PopperPortal setOpen={setOpen} open={open}>
        <Box padding="15px" width="280px" style={{ background: '#fff' }}>
          {options?.map((option) => (
            <Box key={option?.id} marginBottom={2}>
              <Typography variant="body1" style={{ fontSize: 14, fontWeight: '400' }}>
                {option?.category}
              </Typography>
              <MenuItem
                onClick={() => {
                  onChange(option?.id, 'when');
                  handleClose();
                }}
                style={{ fontWeight: 500 }}
              >
                {option?.question}
              </MenuItem>
            </Box>
          ))}
        </Box>
      </PopperPortal>
    </Box>
  );
}
