import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { assignUserToProject, getUsers } from '../services/unarmed';
import { axiosCatchError } from '../utils';

export default function useUsers(
  id,
  usersAssigned = [],
  shouldAddMoreUser = true,
  shouldFetchUsers = true,
  usersSource
) {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [assignedUsers, setAssignedUsers] = useState([{}]);

  useEffect(() => {
    const onGetUsers = async () => {
      try {
        setLoading(true);
        const { data } = await getUsers();
        setUsers(data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    if (shouldFetchUsers) {
      onGetUsers();
    }

    if (usersSource?.length) {
      setUsers(usersSource);
    }
  }, [shouldFetchUsers, usersSource]);

  useEffect(() => {
    if (users?.length) {
      const onFilterUser = (userId) => users.find((user) => user._id === userId);
      const _assignedUsers = usersAssigned
        ? usersAssigned?.map((user) => (typeof user === 'object' ? user : onFilterUser(user)))?.filter((user) => !!user)
        : [];

      if (usersAssigned?.length > 0 && shouldAddMoreUser) {
        setAssignedUsers([..._assignedUsers, {}]);
      } else if (usersAssigned?.length > 0 && !shouldAddMoreUser) {
        setAssignedUsers([..._assignedUsers]);
      }
    }
  }, [users, usersAssigned, shouldAddMoreUser]);

  const onDeleteUser = async (user, index) => {
    const usersArr = [...assignedUsers];
    usersArr.splice(index, 1);
    const userData = {
      users: usersArr,
    };
    if (usersArr.length <= 1) {
      userData.users = [];
    } else {
      userData.users = usersArr.slice(0, -1);
    }
    setAssignedUsers(usersArr);

    try {
      setLoading(true);
      await assignUserToProject(id, {
        users: userData.users?.map((userXt) => userXt?._id),
      });
      toast.success(`the user has been unassigned Successfully`);

      setLoading(false);
    } catch (error) {
      axiosCatchError(error);
      setLoading(false);
    }
  };

  const onAssignUser = async (usersArr, callback) => {
    const assignedUserData = {
      users: [],
    };
    assignedUserData.users = usersArr?.map((user) => user?._id);

    try {
      await assignUserToProject(id, assignedUserData);
      callback();
      toast.success(`user has been assigned Successfully`);
    } catch (error) {
      axiosCatchError(error);
    }
  };

  return [users, assignedUsers, setAssignedUsers, onAssignUser, onDeleteUser, loading, setUsers];
}
