/* eslint-disable no-nested-ternary */
import { Checkbox, FormControl, InputBase, ListItemText, MenuItem, Select, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const BootstrapInput = withStyles((theme) => ({
  root: {
    width: '100%',
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: '#f8f8f9',
    border: '1px solid #f8f8f9',
    fontSize: 13,
    padding: '8px 26px 8px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#f8f8f9',
      backgroundColor: '#f8f8f9',
    },
  },
}))(InputBase);

const inputStyles = {
  borderRadius: 4,
  position: 'relative',
  backgroundColor: '#fff',
  fontSize: 13,
  padding: '8px 26px 8px 12px',
  // Use the system font instead of the default Roboto font.
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),

  '&:focus': {
    borderRadius: 4,
    border: '1px solid rgba(0,0,0,.23)',
    backgroundColor: '#fff',
  },
};

const BootstrapInputOutlined = withStyles((theme) => ({
  root: {
    width: '100%',
  },
  input: {
    border: '1px solid rgba(0,0,0,.23)',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    ...inputStyles,
  },
}))(InputBase);

const BootstrapInputOutlinedActive = withStyles((theme) => ({
  root: {
    width: '100%',
  },
  input: {
    border: '1px solid #408BEC',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    ...inputStyles,
  },
}))(InputBase);

/**
 * MultiSelectBox component for selecting multiple options.
 * @param {Object} props - The props of the component.
 * @param {string} props.dataTestId - The data test ID for testing.
 * @param {Array} props.options - The array of options to select from.
 * @param {Array} props.value - The currently selected values.
 * @param {Function} props.onChange - The function to handle change in selection.
 * @param {string} props.id - The ID for the component.
 * @param {string} props.label - The label for the component.
 * @param {boolean} props.outlined - Whether to render with outlined style.
 * @param {boolean} props.default - Whether to use default input style.
 * @param {Function} props.callback - The callback function to handle selection.
 * @returns {JSX.Element} MultiSelectBox component.
 */
const MultiSelectBox = ({
  dataTestId,
  options = [],
  value,
  onChange,
  id,
  label,
  outlined,
  defaultValue = true,
  callback,
  active = false,
}) => {
  const renderOptions = () => {
    if (Array.isArray(options)) {
      return options.map((option, i) => (
        <MenuItem key={i} value={option}>
          <Checkbox checked={value.indexOf(option) > -1} color="primary" />
          <ListItemText primary={option} />
        </MenuItem>
      ));
    }

    return options.obj.map((option, index) => (
      <MenuItem key={index} value={option?.value}>
        <Checkbox checked={value.indexOf(option.value) > -1} color="primary" />
        <ListItemText primary={option.text} />
      </MenuItem>
    ));
  };

  return (
    <FormControl fullWidth style={{ position: 'relative' }}>
      <Select
        inputProps={{
          'data-testid': dataTestId,
          'aria-label': 'Without label',
        }}
        multiple
        displayEmpty
        value={value}
        onChange={onChange}
        input={
          outlined ? (
            active ? (
              <BootstrapInputOutlinedActive />
            ) : (
              <BootstrapInputOutlined />
            )
          ) : defaultValue ? null : (
            <BootstrapInput />
          )
        }
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <p>Select an Option</p>;
          }

          if (Array.isArray(options)) {
            return selected.join(', ');
          }

          if (callback) {
            return callback(selected);
          }

          return null;
        }}
      >
        <MenuItem disabled value="" selected>
          <p>Select an Option</p>
        </MenuItem>
        {renderOptions()}
      </Select>
    </FormControl>
  );
};

// PropTypes
MultiSelectBox.propTypes = {
  dataTestId: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.array,
  onChange: PropTypes.func,
  id: PropTypes.string,
  label: PropTypes.string,
  outlined: PropTypes.bool,
  defaultValue: PropTypes.bool,
  callback: PropTypes.func,
};

export default MultiSelectBox;
