import React, { useEffect, useState } from 'react';
import { Box, Grid, makeStyles, Radio, TextField, Typography } from '@material-ui/core';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import PersonIcon from '@material-ui/icons/Person';
import WrapperComponent from './WrapperComponent';
import Input from '../../components/Input';
import { handleChangeCheckBox, onChangeDnDValue } from '../utils/utls';

import useInput from '../../hooks/useInput';
import Settings, { CustomCheckbox } from '../utils/Settings';
import OutLineTable from '../../components/OutLineTable';
import PreviewLabel from '../utils/Label';
import HideAnswer from '../../components/HideAnswer';

const useStyles = makeStyles(() => ({
  title: {
    fontSize: 14,
    color: '#000000DE',
    marginLeft: 10,
  },
  label: {
    fontSize: 16,
    color: '#000000DE',
    fontWeight: '500',
  },

  fieldLabel: {
    fontSize: 14,
  },

  subLabel: {
    fontSize: 14,
    color: '#000000DE',
    fontWeight: '500',
  },

  settingsQuestions: {
    fontSize: 14,
    minWidth: 160,
  },
  type: {
    width: 131,
  },
  labelContainer: {
    width: 425,
    marginLeft: 20,
  },

  td: {},
}));
const columns = [
  { name: 'Type', styles: {} },
  { name: 'Label', styles: {} },
  { name: 'Visible', styles: { textAlign: 'right' } },
  { name: 'Required', styles: { textAlign: 'right' } },
];

export const onChangeValues = (e, values, setValue, index, prop = 'value') => {
  const { value } = e.target;
  const valuesCopy = Array.from(values);
  valuesCopy[index][prop] = value;

  setValue(valuesCopy);
};

export default function ContactInfo({
  questionBankComponent,
  id,
  componentId,
  onUpdateJson,
  onDeleteComponent,
  path,
  data,
  componentIndex,
  duplicateComponent,
  refActionModal,
  setModal,
}) {
  const classes = useStyles();
  const [rows, setRows] = useState([
    {
      type: 'Anonymously',
      visible: true,
      required: false,
    },
    { type: 'First Name', value: 'First Name', visible: true, required: false },
    { type: 'Last Name', value: 'Last Name', visible: true, required: false },
    {
      type: 'Email Address',
      value: 'Email Address',
      visible: true,
      required: false,
    },
    {
      type: 'Home/Cell Telephone',
      value: 'Home/Cell Telephone',
      visible: true,
      required: false,
    },
    {
      type: 'Work Telephone',
      value: 'Work Telephone',
      visible: true,
      required: false,
    },
  ]);

  const [checkQuestion, setCheckQuestion] = useState([!!data?.description]);
  const descriptionQuestion = useInput(data?.description || '');
  const requiredQuestionMessage = useInput(data?.requiredMessage || '');
  const question = useInput(data?.question || '');
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    if (data?.hasOwnProperty('email')) {
      const preFetchData = [
        {
          type: 'Anonymously',

          visible: data?.showFileAnonymously?.visible,
          required: data?.showFileAnonymously?.required,
        },
        {
          type: 'First Name',
          value: data?.firstName?.text,
          visible: data?.firstName?.visible,
          required: data?.firstName?.required,
        },
        {
          type: 'Last Name',
          value: data?.lastName?.text,
          visible: data?.lastName?.visible,
          required: data?.lastName?.required,
        },
        {
          type: 'Email Address',
          value: data?.email?.text,
          visible: data?.email?.visible,
          required: data?.email?.required,
        },
        {
          type: 'Home/Cell Telephone',
          value: data?.phone?.text,
          visible: data?.phone?.visible,
          required: data?.phone?.required,
        },
        {
          type: 'Work Telephone',
          value: data?.workTelephone?.text,
          visible: data?.workTelephone?.visible,
          required: data?.workTelephone?.required,
        },
      ];
      setRows(preFetchData);
    }
  }, [data]);

  const handleSaveComponent = () => {
    const dataJson = {
      question: question.value,
      ...(descriptionQuestion.value ? { description: descriptionQuestion.value } : {}),
      ...(data.dataReportLink ? { dataReportLink: data.dataReportLink } : {}),
      showFileAnonymously: {
        visible: rows[0].visible,
        required: rows[0].required,
      },
      firstName: {
        text: rows[1].value,
        visible: rows[1].visible,
        required: rows[1].required,
      },

      lastName: {
        text: rows[2].value,
        visible: rows[2].visible,
        required: rows[2].required,
      },
      email: {
        text: rows[3].value,
        visible: rows[3].visible,
        required: rows[3].required,
      },
      phone: {
        text: rows[4].value,
        visible: rows[4].visible,
        required: rows[4].required,
      },
      workTelephone: {
        text: rows[5].value,
        visible: rows[5].visible,
        required: rows[5].required,
      },
    };

    onUpdateJson(dataJson);
  };

  const saveOnTimeOut = () => {
    setLoading(true);
    setTimeout(() => {
      handleSaveComponent();
      setLoading(false);
    }, 500);
  };

  const renderSettings = () => (
    <Settings
      componentId={componentId}
      componentType="contactInformation"
      defaultDataReportLink={data.dataReportLink}
      onSelectDataReportLink={(dataReportLink) => onUpdateJson({ ...data, dataReportLink })}
      questions={['description']}
      checkQuestion={checkQuestion}
      descriptionQuestion={descriptionQuestion}
      requiredQuestion={requiredQuestionMessage}
      setCheckQuestion={setCheckQuestion}
      saveOnTimeOut={saveOnTimeOut}
    />
  );

  const renderPreview = () => (
    <Box>
      <PreviewLabel question={question.value} qNumber={componentIndex + 1} />
      <Box maxWidth={600} marginTop="-15px" marginLeft="45px">
        <HideAnswer marginLeft="-7px" marginTop={0}>
          <Grid container spacing={2}>
            {rows.map((row, index) => {
              if (row.visible) {
                if (row.type === 'Anonymously') {
                  return (
                    <Grid item xs={7} key={index}>
                      <Box display="flex" alignItems="center" marginBottom="10px" marginTop="10px">
                        <Radio
                          disabled
                          color="default"
                          name="radio-button-demo"
                          inputProps={{ 'aria-label': 'D' }}
                          style={{ padding: 0, marginRight: 7 }}
                        />
                        <Typography style={{ color: '#00000061' }}>Yes</Typography>
                      </Box>
                      <Box display="flex" alignItems="center">
                        <Radio
                          disabled
                          color="default"
                          checked
                          name="radio-button-demo"
                          inputProps={{ 'aria-label': 'D' }}
                          style={{ padding: 0, marginRight: 7 }}
                        />
                        <Typography style={{ color: '#00000061' }}>No</Typography>
                      </Box>
                    </Grid>
                  );
                }
                return (
                  <Grid item xs={6} key={index}>
                    <TextField
                      label={
                        <>
                          {row.value} {row.required && <span style={{ color: 'red', fontSize: 12 }}>*</span>}{' '}
                        </>
                      }
                      variant="standard"
                      disabled
                      style={{ width: '100%' }}
                      placeholder={row.type}
                    />
                  </Grid>
                );
              }
            })}
          </Grid>
        </HideAnswer>
      </Box>
    </Box>
  );

  const renderRows = (row, index) => (
    <>
      <td className={classes.td} style={{ width: 150 }}>
        <Box display="flex" alignItems="center" justifyContent="space-between" width={130}>
          <Typography className={classes.fieldLabel}>{row.type}</Typography>
          {row.type !== 'Anonymously' && <ArrowRightAltIcon style={{ fontSize: 14 }} />}
        </Box>
      </td>
      <td className={classes.td}>
        <Box width={250}>
          {row.type !== 'Anonymously' && (
            <Input
              placeholder={row.type}
              value={row.value}
              onChange={(e) => {
                onChangeValues(e, rows, setRows, index);
                // saveOnTimeOut();
              }}
            />
          )}
        </Box>
      </td>
      <td className={classes.td}>
        <Box display="flex" justifyContent="flex-end">
          <CustomCheckbox
            color="primary"
            checked={row.visible}
            onChange={(e) => {
              handleChangeCheckBox(e, index, 'visible', rows, setRows);
              saveOnTimeOut();
            }}
          />
        </Box>
      </td>
      <td className={classes.td}>
        <Box display="flex" justifyContent="flex-end" marginRight="6px">
          <CustomCheckbox
            color="primary"
            checked={row.required}
            onChange={(e) => {
              handleChangeCheckBox(e, index, 'required', rows, setRows);
              saveOnTimeOut();
            }}
          />
        </Box>
      </td>
    </>
  );

  return (
    <WrapperComponent
      onBlur={(!questionBankComponent && saveOnTimeOut) || function () {}}
      renderSettings={renderSettings}
      componentIndex={componentIndex}
      duplicateComponent={duplicateComponent}
      isQuestionBankComponent={questionBankComponent}
      includeRequired={false}
      renderPreview={renderPreview}
      onDeleteComponent={onDeleteComponent}
      id={id}
      componentId={componentId}
      path={path}
      loading={loading}
      dataSaved={data?.question}
      refActionModal={refActionModal}
      setModal={setModal}
    >
      <Box display="flex" alignItems="center">
        <PersonIcon htmlColor="#6D7385" />
        <Typography className={classes.title}>Contact Information</Typography>
      </Box>
      <Box display="flex" alignItems="center" marginTop="15px">
        <Typography className={classes.label}>Q{componentIndex + 1}</Typography>
        <ArrowRightAltIcon style={{ fontSize: 14, marginRight: 10 }} />
        <Input
          placeholder="Contact Information"
          value={question.value}
          onChange={(e) => {
            question.onChange(e);
            saveOnTimeOut();
          }}
        />
      </Box>
      <Box marginTop="38px">
        <OutLineTable columns={columns} rows={rows} renderRows={renderRows} />
      </Box>
    </WrapperComponent>
  );
}
