import { Box, Checkbox, makeStyles, TableCell, TableRow, Typography } from '@material-ui/core';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import { toast } from 'react-toastify';
import { getAllegationRange, getAllegations, editMultipleAllegations, updateViewsById } from '../../services/unarmed';
import { Context } from '../../Context';
import EnhancedTable from '../../components/Table';
import AllegationFilter from './AllegationFilter';
import useInput from '../../hooks/useInput';
import Flagged from '../../assets/flagged.png';
import UpgradeModal from '../../components/UpgradeModal';
import useOrganization from '../../hooks/useOrganization';
import { convertStatusToLowerCase } from '../../components/OfficerAdded';
import { exportCSVFile } from '../../utils/convertJsonCsv';
import { logEvent } from '../../services/firebase';
import useFiltersConfig from '../../hooks/useFiltersConfig';
import { useTabsUtil } from '../../hooks/useTabs';
import { TYPE_USER_TABS } from '../../utils/constants';
import TabsModules from '../../components/TabsModules';
import ArrayTooltip from '../../components/ArrayTooltip';
import { ViewTabAllegations } from './ViewTabAllegations';

const showStatus = (status) => {
  switch (status) {
    case 'RECEIVED':
      return 'Received';

    case 'IN_PROGRESS':
      return 'In Progress';

    case 'CLOSED':
      return 'Closed';

    default:
      return 'N/A';
  }
};

const useStyles = makeStyles(() => ({
  container: {
    padding: 20,
  },
  title: {
    marginTop: 50,
  },
}));

const Allegations = () => {
  const classes = useStyles();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(location?.state?.openFilter || false);
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState('createdAt');
  const [sort, setSort] = useState('desc');
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selected, setSelected] = React.useState([]);
  const [checked, setChecked] = useState(false);
  const { authUser, setActive } = useContext(Context);
  const [allegations, setAllegations] = useState(null);
  const allegation = useInput(location?.state?.allegation || '');
  const trackingNumber = useInput('');
  const badge = useInput(location?.state?.badge || '');
  const officerName = useInput('');
  const status = useInput('');
  const type = useInput('');
  const disposition = useInput(location?.state?.disposition || '');
  const discipline = useInput('');
  const flaggedOnly = useInput(false, false, false, true);
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [isFiltering, setIsFiltering] = useState(location?.state?.openFilter || false);
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const { organization } = useOrganization();
  const history = useHistory();

  const baseColumns = {
    flag: true,
    allegation: true,
    disposition: false,
    discipline: false,
    caseType: true,
    trackingNumber: true,
    createdDate: true,
    badgeSerialNumber: true,
    officerName: true,
    code: false,
    status: true,
  };
  const [loadingEditAllegations, setLoadingEditAllegations] = useState(false);
  const [view, setView] = useState(null);
  const [visibleColumns, setVisibleColumns] = useState(baseColumns);
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  const filteredColumns = Object.keys(visibleColumns).filter((column) =>
    column.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const showColumns = filteredColumns.filter((column) => visibleColumns[column]);
  const hideColumns = filteredColumns.filter((column) => !visibleColumns[column]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);
  const popoverId = open ? 'column-popover' : undefined;

  const {
    filtersConfig,
    filterCount,
    filtersLoaded,
    filtersApplied,
    resetFilterCount,
    setFiltersLoaded,
    fetchFiltersConfig,
  } = useFiltersConfig('allegations');

  useEffect(() => {
    setActive('/allegations');
  }, []);

  const {
    activeTab,
    setActiveTab,
    tabSelected,
    setTabSelected,
    onGetUserTabs,
    userTabs,
    updateNameTab,
    setUserTabs,
    handleSaveTab,
    handleTabSelection,
    nextPosition,
    setShowReportTable,
    handleTabsReorder,
    handleTabSelectionAction,
    deleteSelectedTab,
    activeTabName,
    setActiveTabName,
  } = useTabsUtil({ moduleName: 'ALLEGATIONS' });

  const onGetAllegationRange = async () => {
    let ids = '';
    for (const id of selected) {
      const index = selected.indexOf(id);
      if (index + 1 === selected.length) {
        ids += `allegationIds[]=${id}`;
      } else {
        ids += `allegationIds[]=${id}&`;
      }
    }
    try {
      const { data } = await getAllegationRange(authUser.tokenId, ids);

      const csvObj = data.map((dt) => ({
        'Allegation Type': dt.allegation?.allegation || 'N/A',
        'Tracking Number': dt.code,
        'Created Date': moment(dt?.allegation.createdAt).format(`MM-DD-YYYY`),
        'Badge/Serial Number': dt?.officer.badgeNumber,
        'Officer Name': `${dt?.officer?.firstName} ${dt?.officer?.lastName}`,
        Status: convertStatusToLowerCase(dt?.allegation?.status) || 'N/A',
      }));
      exportCSVFile(
        ['Allegation Type', 'Tracking Number', 'Created Date', 'Badge/Serial Number', 'Officer Name', 'Status'],
        csvObj,
        `Oversight-Allegations-${moment().format('MMMM-DD-YYYY')}`
      );
      const obj = document.getElementById('downloadLink');
      obj.click();
    } catch (error) {
      console.log(error);
      toast.error('Something went wron when exporting allegations...');
    }
  };

  const handleToggleColumn = async (viewId, columnName) => {
    try {
      const updatedColumns = {
        ...visibleColumns,
        [columnName]: !visibleColumns[columnName],
      };

      const response = await updateViewsById(viewId, { columns: updatedColumns });

      if (response && response.status === 200 && response.view) {
        setVisibleColumns(response.view.columns);
      } else {
        console.error('Failed to update columns:', response);
      }
    } catch (error) {
      console.error('Error updating column visibility:', error);
    }
  };

  const formatHeadersText = (str) => {
    const result = str.replace(/([A-Z])/g, ' $1');

    return result.replace(/^./, (firstChar) => firstChar.toUpperCase()).trim();
  };

  const onEditMultipleAllegations = async () => {
    const selectedAllegations = allegations.data.filter((allegation) => selected.includes(allegation._id));
    const allAreFlagged = selectedAllegations.every((allegation) => allegation.flagged);
    try {
      setLoadingEditAllegations(true);
      await editMultipleAllegations(selected);

      setAllegations((prevAllegations) => {
        const updatedData = prevAllegations.data.map((allegation) => {
          if (selected.includes(allegation.allegation._id)) {
            return {
              ...allegation,
              allegation: {
                ...allegation.allegation,
                flagged: !allegation.allegation.flagged,
              },
            };
          }
          return allegation;
        });

        return {
          ...prevAllegations,
          data: updatedData,
        };
      });

      setLoadingEditAllegations(false);
      setSelected([]);
      toast.success(allAreFlagged ? 'Successfully unflagged allegations' : 'Successfully flagged allegations');
    } catch (error) {
      setLoadingEditAllegations(false);
      toast.error(error.message);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(1);
  };

  const onClearFilters = useCallback(async () => {
    status.setValue('0');
    allegation.setValue('0');
    disposition.setValue('0');
    discipline.setValue('0');
    setFrom(null);
    setTo(null);
    trackingNumber.setValue(null);
    officerName.setValue(null);
    badge.setValue(null);
    type.setValue('0');
    flaggedOnly.setValue(false);
    setIsFiltering(false);
    resetFilterCount();
    try {
      setLoading(true);
      const { data, headers } = await getAllegations(
        filtersConfig ? filtersConfig._id : null,
        page,
        rowsPerPage,
        from,
        to,
        sort,
        sortBy,
        false
      );
      setAllegations({ data, headers });
      setLoading(false);
      fetchFiltersConfig();
      toast.success('Successfully cleared filters!');
    } catch (error) {
      setLoading(false);

      console.log(error.message);
    }
  }, [filtersConfig, page, rowsPerPage, from, to, sort, sortBy]);

  const gotToOfficerProfile = (e, row, handleClick) => {
    const go = handleClick(e, row.allegation?._id);
    if (go) {
      setActive('/cases');
      history.push(`/case/${row.code}`, { tab: 'officer' });
      logEvent('enter_allegation_details');
    }
  };

  const renderRow = (row, index, handleClick) => {
    const isItemSelected = isSelected(row.allegation?._id);
    const labelId = `enhanced-table-checkbox-${index}`;

    return (
      <TableRow
        hover
        onClick={(e) => gotToOfficerProfile(e, row, handleClick)}
        aria-checked={isItemSelected}
        selected={isItemSelected}
        role="checkbox"
        tabIndex={-1}
        key={index}
        className={classes.row}
        style={{ cursor: 'pointer', height: 50 }}
      >
        <TableCell padding="checkbox">
          <Checkbox checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} color="primary" />
        </TableCell>
        {visibleColumns?.flag && (
          <TableCell component="th" scope="row" align="left">
            {row?.allegation?.flagged && <img src={Flagged} alt="Flagged" style={{ width: '20px', height: '20px' }} />}
          </TableCell>
        )}
        {visibleColumns.allegation && (
          <TableCell component="th" scope="row" align="left">
            {row?.allegation?.allegation}
          </TableCell>
        )}
        {visibleColumns.disposition && <TableCell align="left">{row?.allegation?.disposition}</TableCell>}
        {visibleColumns.discipline && <TableCell align="left">{row?.allegation?.discipline}</TableCell>}
        {visibleColumns.caseType && (
          <TableCell
            component="th"
            scope="row"
            align="left"
            style={{
              color: row.type === 'COMPLAINT' ? '#FF4242' : row.type === 'COMPLIMENT' ? '#008858' : '#2E66FE',
              textTransform: 'capitalize',
              fontWeight: '500',
            }}
          >
            {row?.type?.toLowerCase()}
          </TableCell>
        )}
        {visibleColumns.trackingNumber && <TableCell align="left">{row?.code}</TableCell>}
        {visibleColumns.createdDate && (
          <TableCell align="left">
            {row?.allegation?.createdAt ? moment(row?.allegation?.createdAt).format('MMMM DD, YYYY') : null}
          </TableCell>
        )}
        {visibleColumns.badgeSerialNumber && (
          <TableCell component="th" scope="row" align="left">
            {row?.officer?.badgeNumber}
          </TableCell>
        )}
        {visibleColumns.officerName && (
          <TableCell component="th" scope="row" align="left" style={{ textTransform: 'capitalize' }}>
            {`${row?.officer?.firstName} ${row?.officer?.lastName}`}
          </TableCell>
        )}
        {visibleColumns.code && (
          <TableCell align="left" style={{ textTransform: 'capitalize' }}>
            {showStatus(row?.code)}
          </TableCell>
        )}
        {visibleColumns.status && (
          <TableCell align="left" style={{ textTransform: 'capitalize' }}>
            {showStatus(row?.allegation?.status)}
          </TableCell>
        )}
      </TableRow>
    );
  };

  const getVisibleColumns = () =>
    Object.keys(visibleColumns)
      .filter((column) => visibleColumns[column])
      .map((column) => formatHeadersText(column));

  return (
    <>
      {organization && <UpgradeModal open={!organization?.features?.dashboard?.allegations?.enabled} />}
      <Box
        className={classes.container}
        style={
          !organization?.features?.dashboard?.allegations?.enabled
            ? {
                filter: 'blur(6px)',
                height: '70vh',
                overflow: 'hidden',
              }
            : {}
        }
      >
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h5">Allegations </Typography>
        </Box>
        <TabsModules
          setTabs={setUserTabs}
          updateNameTab={updateNameTab}
          tabSelected={tabSelected}
          activeTabName={activeTabName}
          activeTab={activeTab}
          setTabSelected={setTabSelected}
          setActiveTab={setActiveTab}
          setActiveTabName={setActiveTabName}
          tabs={userTabs}
          navigateToDataPage={() => {}}
          saveTab={handleSaveTab}
          onTabSelect={handleTabSelection}
          nextPosition={nextPosition}
          saveTabPositions={handleTabsReorder}
          onTabAction={handleTabSelectionAction}
          deleteTab={deleteSelectedTab}
          moduleName="ALLEGATIONS"
        />

        {activeTab === TYPE_USER_TABS.MAIN && (
          <ViewTabAllegations
            isFiltering={isFiltering}
            open={open}
            classes={classes}
            handleClick={handleClick}
            openPopover={openPopover}
            anchorEl={anchorEl}
            handleClose={handleClose}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            showColumns={showColumns}
            handleToggleColumn={handleToggleColumn}
            formatHeadersText={formatHeadersText}
            onEditMultipleAllegations={onEditMultipleAllegations}
            visibleColumns={visibleColumns}
            hideColumns={hideColumns}
            from={from}
            to={to}
            setPage={setPage}
            flaggedOnly={flaggedOnly}
            setFrom={setFrom}
            setTo={setTo}
            page={page}
            rowsPerPage={rowsPerPage}
            sort={sort}
            sortBy={sortBy}
            onClearFilters={onClearFilters}
            setIsFiltering={setIsFiltering}
            selected={selected}
            setOpen={setOpen}
            type={type}
            fetchFiltersConfig={fetchFiltersConfig}
            setSort={setSort}
            filtersLoaded={filtersLoaded}
            setFiltersLoaded={setFiltersLoaded}
            getVisibleColumns={getVisibleColumns}
            setSortBy={setSortBy}
            renderRow={renderRow}
            setChecked={setChecked}
            checked={checked}
            setSelected={setSelected}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            tabSelected={tabSelected}
            setVisibleColumns={setVisibleColumns}
            loading={loading}
            allegations={allegations}
            setLoading={setLoading}
            onGetAllegationRange={onGetAllegationRange}
            allegation={allegation}
            trackingNumber={trackingNumber}
            badge={badge}
            officerName={officerName}
            disposition={disposition}
            discipline={discipline}
            setAllegations={setAllegations}
            status={status}
            location={location}
          />
        )}

        {activeTab === TYPE_USER_TABS.VIEW && (
          <ViewTabAllegations
            isFiltering={isFiltering}
            open={open}
            classes={classes}
            handleClick={handleClick}
            openPopover={openPopover}
            anchorEl={anchorEl}
            handleClose={handleClose}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            showColumns={showColumns}
            handleToggleColumn={handleToggleColumn}
            formatHeadersText={formatHeadersText}
            onEditMultipleAllegations={onEditMultipleAllegations}
            visibleColumns={visibleColumns}
            hideColumns={hideColumns}
            from={from}
            to={to}
            setPage={setPage}
            flaggedOnly={flaggedOnly}
            setFrom={setFrom}
            setTo={setTo}
            page={page}
            rowsPerPage={rowsPerPage}
            sort={sort}
            sortBy={sortBy}
            onClearFilters={onClearFilters}
            setIsFiltering={setIsFiltering}
            selected={selected}
            setOpen={setOpen}
            type={type}
            fetchFiltersConfig={fetchFiltersConfig}
            setSort={setSort}
            filtersLoaded={filtersLoaded}
            setFiltersLoaded={setFiltersLoaded}
            getVisibleColumns={getVisibleColumns}
            setSortBy={setSortBy}
            renderRow={renderRow}
            setChecked={setChecked}
            checked={checked}
            setSelected={setSelected}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            tabSelected={tabSelected}
            setVisibleColumns={setVisibleColumns}
            loading={loading}
            allegations={allegations}
            setLoading={setLoading}
            onGetAllegationRange={onGetAllegationRange}
            allegation={allegation}
            trackingNumber={trackingNumber}
            badge={badge}
            officerName={officerName}
            disposition={disposition}
            discipline={discipline}
            setAllegations={setAllegations}
            status={status}
            location={location}
          />
        )}
      </Box>
    </>
  );
};

export default Allegations;
