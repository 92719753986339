import { useContext, useEffect, useState } from 'react';

import { Context } from '../Context';
import { getMe } from '../services/unarmed';

const useMe = () => {
  const { authUser, removeAuthToLogin } = useContext(Context);
  const [me, setMe] = useState(null);

  useEffect(() => {
    const onGetMe = async () => {
      try {
        const { data } = await getMe(authUser.tokenId);

        setMe(data);
      } catch (error) {
        if (error?.response?.data?.message === 'Expired token') {
          removeAuthToLogin('Your password has expired. Please reset your password.');
        } else {
          removeAuthToLogin('');
        }
      }
    };

    if (authUser && authUser.tokenId) {
      onGetMe();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser]);

  return { me, setMe };
};

export default useMe;
