import {
  Box,
  FormControl,
  InputBase,
  makeStyles,
  NativeSelect,
  Typography,
  withStyles,
} from '@material-ui/core';
import React from 'react';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
    '& svg': {
      color: '#333 !important',
      marginRight: 5,
    },
    marginRight: 20,
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #fafafa',
    background: '#fafafa !important',
    color: '#333',
    fontSize: 13,
    height: 20,
    minWidth: 100,
    maxWidth: 100,
    padding: '10px 5px 10px 10px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '& option': {
      color: '#333',
    },
    '&:focus': {
      borderRadius: 4,
    },
  },
}))(InputBase);

const useStyles = makeStyles(() => ({
  label: {
    fontSize: 13,
    color: '#333',
    fontWeight: 500,
    marginBottom: 8,
  },
}));

const SelectOptions = ({
  optionsSelect = [],
  value,
  id,
  onChangeSelect,
  label,
  parseStatus,
  style,
}) => {
  const classes = useStyles();
  return (
    <Box style={style}>
      <Typography variant="body2" className={classes.label}>
        {label}
      </Typography>
      <FormControl>
        <NativeSelect
          id={id}
          value={value}
          onChange={onChangeSelect}
          input={<BootstrapInput />}
          IconComponent={KeyboardArrowDownIcon}
          defaultValue={`Select an Option`}
        >
          <option disabled style={{ textAlign: 'center' }}>
            Select an Option
          </option>
          {optionsSelect.map((optionSelect, i) => (
            <option
              value={optionSelect}
              key={i}
              style={{ textAlign: 'center' }}
            >
              {parseStatus(optionSelect)}
            </option>
          ))}
        </NativeSelect>
      </FormControl>
    </Box>
  );
};

export default SelectOptions;
