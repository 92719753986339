import { Box, Button, CircularProgress, Divider, makeStyles, Typography, FormControlLabel } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import DatePickerMUI from '../../components/DatePickerMUi';
import SelectBox from '../../components/SelectBox';
import { Context } from '../../Context';
import { getRoles, getUsers, getUsersInvitations, updateFilterConfigById } from '../../services/unarmed';
import { OutLineInput } from '../CaseDetails/UpdateCase';
import { activeColors } from '../../components/CaseFilters';
import { CustomSwitch } from '../../formEngine/components/WrapperComponent';

const useStyles = makeStyles(() => ({
  button: {
    background: ' #1F63FF',
    textTransform: 'capitalize',
    color: '#fff',
    '&:hover': {
      background: '#1F63FF',
    },
    '& .MuiButton-label': {
      fontSize: 13,
    },
  },
}));

const UserFilter = ({
  setOpen,
  page,
  flagged,
  setUsername,
  setEmail,
  setPhone,
  setRole,
  onClick,
  name,
  email,
  setFrom,
  from,
  to,
  setTo,
  role,
  setUsers,
  value,
  rowsPerPage,
  phoneNumber,
  flaggedOnly,
  sortBy,
  sort,
  setIsFiltering,
  onClearFilters,
  setPage,
  fetchFiltersConfig,
  filtersApplied,
  filtersConfig,
  setfiltersConfig,
  filterConfigId
}) => {
  const [roles, setRoles] = useState([]);
  const { authUser } = useContext(Context);
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const onGetRoles = async () => {
      try {
        const { data } = await getRoles();
        setRoles(data);
      } catch (error) {
        console.log(error);
      }
    };

    if (authUser) {
      onGetRoles();
    }
  }, [authUser]);
  const onFilter = async () => {
    try {
      setIsFiltering(true);
      setLoading(true);
      setPage(1);
      const filterSaved = {
        from: from ? moment(from).format('YYYY-MM-DD') : null,
        to: to ? moment(to).format('YYYY-MM-DD') : null,
        email,
        name,
        phoneNumber,
        role,
        sortOrder: sort,
        sortBy,
        flaggedOnly: flaggedOnly.value,
      };
      setfiltersConfig({ ...filtersConfig, ...filterSaved });
      localStorage.setItem('filters', JSON.stringify(filterSaved));
      await updateFilterConfigById(filterConfigId, filterSaved);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      console.log(error);
    }
  };

  const handleClearFilters = async () => {
    onClearFilters();
    const filterSaved = {
      from: from ? moment(from).format('YYYY-MM-DD') : null,
      to: to ? moment(to).format('YYYY-MM-DD') : null,
      email: '',
      name: '',
      phoneNumber: '',
      role: '',
      sortOrder: sort,
      sortBy,
      flaggedOnly: false,
    };
    await updateFilterConfigById(filterConfigId, filterSaved);
  }

  return (
    <Box
      boxShadow="0 4px 8px rgba(0,0,0,.1)"
      style={{ background: '#fff' }}
      padding="10px 20px"
      borderRadius="5px"
      marginTop="20px"
    >
      <Box display="flex" flexWrap="wrap">
        <Box minWidth="100px" maxWidth="200px" width="100%" marginRight="20px" marginTop="10px">
          <Typography
            style={{
              display: 'block',
              fontSize: 13,
              fontWeight: 500,
              marginBottom: 5,
            }}
          >
            Name
          </Typography>
          <OutLineInput
            bordered
            active={filtersApplied.name}
            placeholder="Name"
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              'aria-label': 'weight',
            }}
            value={name || ''}
            onChange={(e) => setUsername(e.target.value)}
            labelWidth={0}
          />
        </Box>
        <Box minWidth="100px" maxWidth="130px" width="100%" marginRight="20px" marginTop="10px">
          <Typography
            style={{
              display: 'block',
              fontSize: 13,
              fontWeight: 500,
              marginBottom: 5,
            }}
          >
            Phone
          </Typography>
          <OutLineInput
            bordered
            active={filtersApplied.phoneNumber}
            placeholder="Phone Number"
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              'aria-label': 'weight',
            }}
            value={phoneNumber || ''}
            onChange={(e) => setPhone(e.target.value)}
            labelWidth={0}
          />
        </Box>
        <Box minWidth="100px" maxWidth="200px" width="100%" marginRight="20px" marginTop="10px">
          <Typography
            style={{
              display: 'block',
              fontSize: 13,
              fontWeight: 500,
              marginBottom: 5,
            }}
          >
            Email
          </Typography>
          <OutLineInput
            bordered
            active={filtersApplied.email}
            placeholder="Email"
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              'aria-label': 'weight',
            }}
            value={email || ''}
            onChange={(e) => setEmail(e.target.value)}
            labelWidth={0}
          />
        </Box>

        <Box minWidth="100px" maxWidth="200px" width="100%" marginRight="20px" marginTop="10px">
          <Typography
            style={{
              display: 'block',
              fontSize: 13,
              fontWeight: 500,
              marginBottom: 3,
            }}
          >
            Role
          </Typography>

          <SelectBox
            withColors={filtersApplied.role ? activeColors : null}
            id="type"
            options={roles}
            value={role || null}
            onChange={(e) => setRole(e.target.value)}
            outlined
          />
        </Box>

        <Box width="350px" marginRight="20px" marginTop="10px">
          <Typography
            style={{
              display: 'block',
              fontSize: 13,
              fontWeight: 500,
              marginBottom: 5,
            }}
          >
            Date Added
          </Typography>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <DatePickerMUI
              active={filtersApplied.startDate}
              disableFuture
              label="From"
              propWidth="65%"
              date={from}
              setdate={setFrom}
              compareDate={to}
            />
            <span style={{ width: 15 }} />
            <DatePickerMUI
              active={filtersApplied.endDate}
              disableFuture
              label="To"
              propWidth="65%"
              date={to}
              compareDate={from}
              type="end"
              setdate={setTo}
            />
          </Box>
        </Box>
        {/*
        <Box minWidth="170px" maxWidth="180px" width="100%" display="flex" alignItems="center" marginTop="10px">
          <FormControlLabel
            style={{ margin: 0 }}
            control={<CustomSwitch checked={flaggedOnly.value} onChange={flaggedOnly.onChange} />}
            label="Flagged only"
            labelPlacement="start"
          />
        </Box> */}
      </Box>

      <Divider style={{ display: 'block', width: '100%', margin: '15px 0' }} />
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box color="#1F63FF">
          <Typography
            data-testid="users-filters-button-clear"
            style={{ fontSize: 13, fontWeight: '500', cursor: 'pointer' }}
            onClick={handleClearFilters}
          >
            Clear All Filters{' '}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Button data-testid="users-filters-button-cancel" onClick={() => setOpen(false)} style={{ textTransform: 'capitalize' }}>
            Cancel
          </Button>
          <Button data-testid="users-filters-button-submit" className={classes.button} onClick={onFilter}>
            {loading ? <CircularProgress color="#fff" size="25px" /> : 'Apply'}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default UserFilter;
