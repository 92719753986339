import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Modal, Fade, Paper, makeStyles, Typography, Box, Avatar } from '@material-ui/core';
import { toast } from 'react-toastify';
import { DropzoneArea } from 'material-ui-dropzone';

import useInput from '../../hooks/useInput';
import { updateContact, uploadFormImg } from '../../services/unarmed';
import ContactForm from './ContactForm';
import { ImageContainer } from '../../styles/GlobalStyles';
import useOrganization from '../../hooks/useOrganization';
import { axiosCatchError } from '../../utils';
import { getStatesOfCountry } from '../../services/countryStateService';
import { parseContactPhoneNumber } from './ModalCreateContact';
import { checkNullValues } from '../../utils/validateForm';

/**
 * Material-UI styling hook.
 */
const useStyles = makeStyles((theme) => ({
  paper: {
    boxShadow: theme.shadows[5],
    padding: 64,
    minWidth: 600,
    alignSelf: 'center',
    borderRadius: 4,
    maxHeight: '80vh',
    overflowY: 'scroll',
    maxWidth: 700,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    width: 120,
    height: 120,
  },
}));

/**
 * Modal for editing a contact profile.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.contact - The contact object to edit.
 * @param {boolean} props.open - Whether the modal is open.
 * @param {Function} props.onClose - Function to handle modal closure.
 * @param {Function} props.onUpdate - Function to handle updates after contact editing.
 */
const ModalEditContact = ({ contact, open, onClose, onUpdate }) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [avatar, setAvatar] = useState('');
  const [hashFile, setHashFile] = useState('');
  // const [loadingImage, setLoadingImage] = useState(false);
  // const [loadingPercentage, setLoadingPercentage] = useState(0);
  const [states, setStates] = useState([]);

  const { organization } = useOrganization();

  const firstName = useInput('');
  const lastName = useInput('');
  const type = useInput('');
  const companyName = useInput('');
  const phoneNumber = useInput('');
  const gender = useInput([]);
  const raceEthnicity = useInput([]);
  const birthdate = useInput('');
  const title = useInput('');
  const suffix = useInput('');
  const pronouns = useInput('');
  const salutation = useInput('');
  const description = useInput('');
  const department = useInput('');

  const mailingStreet = useInput('');
  const mailingApt = useInput('');
  const mailingState = useInput('');
  const mailingCity = useInput('');
  const mailingZipCode = useInput('');
  const otherStreet = useInput('');
  const otherApt = useInput('');
  const otherState = useInput('');
  const otherCity = useInput('');
  const otherZipCode = useInput('');

  /**
   * Clears input fields.
   */
  const onClearFields = () => {
    setHashFile('');
  };

  /**
   * Handles the save action for editing a contact profile.
   */
  const handleOnSave = useCallback(() => {
    const isoCodeState = states.find((st) => st.name === mailingState.value);
    const otherIsoCodeState = states.find((st) => st.name === otherState.value);

    const phoneNumberParsed = parseContactPhoneNumber(phoneNumber.value);

    if (phoneNumber.value && phoneNumberParsed.length < 10) {
      toast.error('The phone number must have 10 digits.');
      return;
    }

    if (mailingZipCode.value && mailingZipCode.value.length !== 5) {
      toast.error('The mailing zip code must have 5 digits.');
      return;
    }

    if (otherZipCode.value && otherZipCode.value.length !== 5) {
      toast.error('The other zip code must have 5 digits.');
      return;
    }

    if (type.value === 'Organization' && companyName.value === '') {
      toast.error('The company name is required.');
      return;
    }

    const data = {
      suffix: suffix.value,
      title: title.value,
      pronouns: pronouns.value,
      salutation: salutation.value,
      firstName: firstName.value,
      lastName: lastName.value,
      type: type.value,
      phoneNumber: phoneNumberParsed,
      description: description.value,
      department: department.value,
      ...(hashFile !== '' ? { photo: hashFile } : {}),
      ...(type.value === 'Organization' && companyName.value ? { companyName: companyName.value } : {}),
      demographic: {
        gender: gender.value,
        raceEthnicity: raceEthnicity.value,
        birthdate: birthdate.value === 'Invalid date' ? '' : birthdate.value,
      },
      mailingLocation: {
        address: mailingStreet.value,
        city: {
          name: mailingCity.value,
        },
        state: {
          isoCode: isoCodeState?.isoCode,
          name: mailingState.value,
        },
        zipCode: mailingZipCode.value,
        aptUnit: mailingApt.value,
      },
      otherLocation: {
        address: otherStreet.value,
        city: {
          name: otherCity.value,
        },
        state: {
          isoCode: otherIsoCodeState?.isoCode,
          name: otherState.value,
        },
        zipCode: otherZipCode.value,
        aptUnit: otherApt.value,
      },
    };

    setLoading(true);
    updateContact(contact?._id, checkNullValues(data))
      .then(() => {
        onClose();
        onUpdate();
        onClearFields();
        setLoading(false);
        toast.success('Contact updated successfully!');
      })
      .catch((error) => {
        setLoading(false);
        axiosCatchError(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    firstName.value,
    lastName.value,
    phoneNumber.value,
    gender.value,
    hashFile,
    mailingState.value,
    otherState.value,
    mailingZipCode.value,
    otherZipCode.value,
    type.value,
    companyName.value,
    suffix.value,
    title.value,
    pronouns.value,
    salutation.value,
    description.value,
    department.value,
    raceEthnicity.value,
    birthdate.value,
    mailingStreet.value,
    mailingCity.value,
    mailingApt.value,
    otherStreet.value,
    otherCity.value,
    otherApt.value,
    states,
  ]);

  /**
   * Handles file upload when an image is selected.
   *
   * @param {File} fl - The selected image file.
   */
  const uploadOnLoad = async (fl) => {
    const imgData = new FormData();

    imgData.append('file', fl);
    imgData.append('organizationId', organization?._id);

    // setLoadingImage(true);

    try {
      // const img = await uploadFormImg(imgData, setLoadingPercentage);
      const img = await uploadFormImg(imgData);

      setAvatar(img.publicUrl);
      setHashFile(img.hashId);
      // setLoadingImage(false);
    } catch (error) {
      axiosCatchError(error);
    }
  };

  useEffect(() => {
    if (contact) {
      firstName.setValue(contact.firstName);
      lastName.setValue(contact.lastName);
      type.setValue(contact.type);
      companyName.setValue(contact.companyName);
      phoneNumber.setValue(contact.phoneNumber);
      gender.setValue(contact.demographic?.gender);
      raceEthnicity.setValue(contact.demographic?.raceEthnicity);
      birthdate.setValue(contact.demographic?.birthdate);
      title.setValue(contact.title);
      suffix.setValue(contact.suffix);
      pronouns.setValue(contact.pronouns);
      salutation.setValue(contact.salutation);
      description.setValue(contact.description);
      department.setValue(contact.department);
      mailingStreet.setValue(contact.mailingLocation?.address);
      mailingApt.setValue(contact.mailingLocation?.aptUnit);
      mailingState.setValue(contact.mailingLocation?.state?.name);
      mailingCity.setValue(contact.mailingLocation?.city?.name);
      mailingZipCode.setValue(contact.mailingLocation?.zipCode);
      otherStreet.setValue(contact.otherLocation?.address);
      otherApt.setValue(contact.otherLocation?.aptUnit);
      otherState.setValue(contact.otherLocation?.state?.name);
      otherCity.setValue(contact.otherLocation?.city?.name);
      otherZipCode.setValue(contact.otherLocation?.zipCode);
      setAvatar(contact?.photo?.public_url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact]);

  useEffect(() => {
    getStatesOfCountry().then((res) => setStates(res));
  }, []);

  return (
    <Modal className={classes.modal} open={open} onClose={onClose}>
      <Fade in={open}>
        <Paper className={classes.paper}>
          <Box mb={2}>
            <h1>Edit Profile</h1>
            <Typography variant="body2">Edition of contact profile</Typography>
          </Box>
          <Box mt={2} mb={2} position="relative">
            <Avatar src={avatar} className={classes.image} />
            <ImageContainer>
              <DropzoneArea
                inputProps={{ multiple: false }}
                maxFileSize={500000000}
                onDropRejected={(files) => {
                  if (files[0].size > 1000000) {
                    toast.error(
                      'The file is too large, please upload to a file repository and share the link on incident External links section.'
                    );
                  }
                }}
                onChange={(fl) => {
                  if (fl.length > 0) {
                    uploadOnLoad(fl[fl.length - 1]);
                  }
                }}
                clearOnUnmount
                filesLimit={1}
                showPreviewsInDropzone={false}
              />
            </ImageContainer>
          </Box>
          <ContactForm
            firstName={firstName}
            lastName={lastName}
            type={type}
            companyName={companyName}
            phoneNumber={phoneNumber}
            gender={gender}
            raceEthnicity={raceEthnicity}
            birthdate={birthdate}
            pronouns={pronouns}
            salutation={salutation}
            title={title}
            suffix={suffix}
            description={description}
            department={department}
            mailingStreet={mailingStreet}
            mailingApt={mailingApt}
            mailingState={mailingState}
            mailingCity={mailingCity}
            mailingZipCode={mailingZipCode}
            otherStreet={otherStreet}
            otherApt={otherApt}
            otherState={otherState}
            otherCity={otherCity}
            otherZipCode={otherZipCode}
            states={states}
            loading={loading}
            onClickClose={() => {
              onClose();
              onClearFields();
            }}
            onClickSave={handleOnSave}
            isEdit
          />
        </Paper>
      </Fade>
    </Modal>
  );
};

ModalEditContact.propTypes = {
  contact: PropTypes.object.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onUpdate: PropTypes.func,
};

export default ModalEditContact;
