import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Page, Text, View, Image, Document, StyleSheet, PDFViewer, Font } from '@react-pdf/renderer';
import { Box } from '@material-ui/core';
import moment, { version } from 'moment';
import { useTranslation } from 'react-i18next';
import { useParams, useLocation } from 'react-router-dom';

import useTrackForm from '../../hooks/useTrackForm';
import LoadingScreen from '../../components/LoadingScreen';
import { getAboutUsValue } from './AditionalDetails';
import useOrganization from '../../hooks/useOrganization';
import formatTxt from '../../utils/formatText';
import { getDemographicsLabel, getTypeText } from './FormTypeJson';
import { formatPhoneNumber, checkValueType } from '../../utils';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

Font.register({
  family: 'Roboto',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf',
});
Font.register({
  family: 'Roboto-bold',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf',
});

// Create styles
const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    height: '100%',
    paddingHorizontal: 35,
    backgroundColor: '#fff',
  },
  title: {
    fontSize: 11,
    textAlign: 'left',
  },
  tag: {
    padding: 10,
    fontSize: 10,
    width: '100px',
    textAlign: 'center',
    backgroundColor: '#EDEEEF',
    textTransform: 'uppercase',
    borderRadius: 5,
  },
  author: {
    fontSize: 10,
    textAlign: 'left',
    color: '#585858',
  },
  subtitle: {
    fontSize: 18,
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: 'justify',
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: '#fff',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  box: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
  },
  section: {
    width: '100%',
    marginTop: 20,
    backgroundColor: '#fff',
    borderBottom: '1px solid #ccc',
    borderBottomWidth: 1,
    borderRight: '1px solid #ccc',
    borderRightWidth: 1,
    borderLeft: '1px solid #ccc',
    borderLeftWidth: 1,
  },
  boxSection: {
    borderBottom: '1px solid #ccc',
    borderBottomWidth: 1,
    borderRight: '1px solid #ccc',
    borderRightWidth: 1,
    borderLeft: '1px solid #ccc',
    borderLeftWidth: 1,
  },
  sectionName: {
    fontSize: 12,
    fontWeight: '500',
    color: '#4b7bff',
  },
  link: {
    color: '#4b7bff',
    marginBottom: 10,
    fontSize: 11,
  },
  label: {
    fontSize: 8,
    color: '#585858',
  },
  value: {
    fontSize: 10,
    marginTop: 5,
    fontFamily: 'Roboto',
  },
  description: {
    fontSize: 11,
    marginTop: 7,
    height: '100%',
  },
  viewValue: {
    flexGrow: 1,
  },
  logo: {
    width: 80,
    height: 50,
    objectFit: 'contain',
    marginBottom: 20,
  },
  sectionText: {
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: 11,
    backgroundColor: '#000',
    color: '#fff',
    padding: '5px 10px',
  },
  sectionInput: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1px solid #ccc',
    borderBottomWidth: 1,
  },
  subSection: {
    border: 1,
    borderColor: '#ccc',
    borderTopWidth: 0,
    backgroundColor: '#f2f2f2',
    padding: '5px 10px',
  },

  sectionItem: {
    width: '50%',
    padding: '5px 10px',

    // borderRight: '1px solid #ccc',
    // borderRightWidth: 1,
    // borderLeft: '1px solid #ccc',
    // borderLeftWidth: 1,
  },
  sectionItemRight: {
    width: '50%',
    padding: '5px 10px',

    // borderLeft: '1px solid #ccc',
    // borderLeftWidth: 1,
  },
  sectionItemFull: {
    width: '100%',
    paddingTop: 5,
    paddingBottom: 5,
    paddingHorizontal: 10,

    // borderRight: '1px solid #ccc',
    // borderRightWidth: 1,
    // borderLeft: '1px solid #ccc',
    // borderLeftWidth: 1,
  },
  checkBox: {
    width: 10,
    height: 10,
    border: 1,
    borderColor: '#585858',
    marginRight: 5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  filled: {
    width: 5,
    height: 5,
    backgroundColor: '#585858',
  },
  titles: {
    width: '100%',
    fontWeight: '500',
    fontSize: 14,
    textAlign: 'center',
    marginTop: 10,
  },
  subTitle: {
    width: '100%',
    fontWeight: '400',
    fontSize: 11.5,
    marginLeft: 10,
    marginTop: 10,
  },
  sectionTitle: {
    fontSize: 11.5,
    fontWeight: '700',
    color: '#222',
  },
  componentGeneral: {
    borderBottom: '1px solid #ccc',
    borderBottomWidth: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
});

// Create Document Component
const CasePdf = () => {
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const { form } = useTrackForm(id);
  const query = useQuery();
  const { organization } = useOrganization();
  const counter = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    counter.current = 0;
  }, []);

  setTimeout(function () {
    setLoading(false);
  }, 2000);

  // const onPrintNumber = (number, type, altNumber) => {
  //   if (form?.type === type || form?.originalType === type) {
  //     counter.current = number;
  //     return number;
  //   }
  //   counter.current = altNumber;
  //   return altNumber;
  // };

  const getParentLabel = (component, text) => (
    <View style={{ width: '100%', margin: '10px 0', marginLeft: 10 }}>
      <Text variant="body2" style={styles.sectionTitle}>
        {component?.data?.question ||
          getTypeText(text || (component.type === 'questionBank' ? component.data.type : component.type))}
      </Text>
    </View>
  );

  const formPages =
    (query.get('version') === 'original' && form?.originalPages?.length
      ? form?.originalPages
      : form?.formType?.pages) || [];

  console.log(query.get('version'), formPages);

  return loading ? (
    <LoadingScreen />
  ) : (
    <Box height="116.1vh">
      <PDFViewer style={{ width: '100%', height: '100%' }} fileName="dsa">
        <Document>
          <Page style={styles.body}>
            <View style={{ ...styles.box, justifyContent: 'flex-end' }}>
              <Image src={organization?.logo} style={styles.logo} />
            </View>
            <View style={{ ...styles.box, alignItems: 'flex-end' }}>
              <View>
                <Text style={{ ...styles.title, marginBottom: 4 }}>
                  {t('Tracking Number')} <Text style={styles.author}>{form?.code}</Text>
                </Text>
                <Text style={styles.author}>
                  {t('Created')}{' '}
                  {form?.civilian?.firstName
                    ? `${form?.civilian?.firstName} ${form?.civilian?.lastName} -`
                    : 'Not specified -'}{' '}
                  {moment(form?.createdAt).format('MMMM DD, YYYY')}
                </Text>
                <Text style={{ ...styles.author, marginTop: 4 }}>
                  {t(query.get('version') === 'original' ? 'Original version' : 'Edited version')}
                </Text>
              </View>
              <Text
                style={{
                  ...styles.tag,
                  textTransform: 'uppercase',
                  fontFamily: 'Roboto-bold',
                }}
                fixed
              >
                {t(form?.type?.toLowerCase())}
              </Text>
            </View>
            {formPages?.map((page, pageIndex) => (
              <View key={`page-${pageIndex}`}>
                {page.sections.map((section, sectionIndex) => (
                  <Fragment key={`section-${sectionIndex}`}>
                    <View style={styles.section}>
                      <Text style={styles.sectionText}>{section.name}</Text>

                      <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                        {form?.external && sectionIndex === 0 && (
                          <View>
                            <View
                              width="100%"
                              maxWidth="150px"
                              marginRight="20px"
                              marginBottom="10px"
                              style={styles.sectionItem}
                            >
                              <Text style={styles.label}> City</Text>
                              <Text style={styles.value}>
                                {form.external?.cityBySivil?.organization?.city || 'N/A'}
                              </Text>
                            </View>
                            <View
                              width="100%"
                              maxWidth="150px"
                              marginRight="20px"
                              marginBottom="10px"
                              style={styles.sectionItem}
                            >
                              <Text style={styles.label}>Department</Text>
                              <Text style={styles.value}>{form.external?.cityBySivil?.department?.name || 'N/A'}</Text>
                            </View>
                            <View
                              width="100%"
                              maxWidth="150px"
                              marginRight="20px"
                              marginBottom="10px"
                              style={styles.sectionItem}
                            >
                              <Text style={styles.label}>Location</Text>
                              <Text style={styles.value}>{form.external?.cityBySivil?.place?.name || 'N/A'}</Text>
                            </View>
                          </View>
                        )}
                        {section.components.map((component, componentIndex) => {
                          if (component.type === 'title') {
                            return (
                              <Text
                                style={{
                                  ...styles.titles,
                                  ...styles.sectionItemFull,
                                }}
                                key={componentIndex}
                              >
                                {component?.data?.text}
                              </Text>
                            );
                          }
                          if (component.type === 'subtitle') {
                            return (
                              <Text
                                style={{
                                  fontSize: 14,
                                  ...styles.sectionItemFull,
                                }}
                                key={componentIndex}
                              >
                                {component?.data?.text}
                              </Text>
                            );
                          }
                          if (component.value === null) {
                            return (
                              <View key={componentIndex}>
                                {getParentLabel(component)}
                                <View
                                  width="100%"
                                  maxWidth="150px"
                                  marginRight="20px"
                                  marginBottom="10px"
                                  key={`component-${componentIndex}`}
                                  style={styles.sectionItem}
                                >
                                  <Text style={styles.value}>Not Specified</Text>
                                </View>
                              </View>
                            );
                          }

                          if (Array.isArray(component.value)) {
                            if (
                              component.value &&
                              Array.isArray(component?.value[0]) &&
                              component?.value[0]?.every((file) => file?.hasOwnProperty('url'))
                            ) {
                              return (
                                <>
                                  {component?.value[0]?.map((file, fileIndex) => (
                                    <Fragment key={fileIndex}>
                                      {getParentLabel(component)}

                                      <View style={styles.sectionItem} key={`component-${fileIndex}`}>
                                        <Text style={styles.label}>File - {fileIndex + 1}</Text>
                                        <Text style={styles.value}>{formatTxt(file?.name, 14) || 'Not Specified'}</Text>
                                      </View>
                                    </Fragment>
                                  ))}
                                </>
                              );
                            }

                            if (!component.value[0] && component.value?.length === 1) {
                              return (
                                <View key={`component-${componentIndex}`}>
                                  {getParentLabel(component)}
                                  <View
                                    width="100%"
                                    maxWidth="150px"
                                    marginRight="20px"
                                    marginBottom="10px"
                                    style={styles.sectionItem}
                                  >
                                    <Text style={styles.value}>Not Specified</Text>
                                  </View>
                                </View>
                              );
                            }
                            return component.value.map((objkey, objIndex) => {
                              if (Array.isArray(objkey)) {
                                if (objkey[0]?.hasOwnProperty('firstName')) {
                                  return objkey.map((obKey, objIndex2) => {
                                    const keysObj = Object.keys(obKey);

                                    return (
                                      <View key={objIndex2} style={styles.componentGeneral}>
                                        {getParentLabel(component)}
                                        {keysObj.map((key, idx) => (
                                          <View style={styles.sectionItem} key={`component-${idx}`}>
                                            <Text style={styles.label}>{getTypeText(key)}</Text>
                                            <Text style={styles.value}>
                                              {' '}
                                              {formatPhoneNumber(obKey[key]) || 'Not Specified'}
                                            </Text>
                                          </View>
                                        ))}
                                      </View>
                                    );
                                  });
                                }
                                if (objkey[objIndex]?.hasOwnProperty('download_url')) {
                                  return objkey.map((obKey, objIndex2) => (
                                    <Fragment key={objIndex2}>
                                      {getParentLabel(component)}

                                      <View style={styles.sectionItem} key={`component-${objIndex2}`}>
                                        <Text style={styles.label}>File - {objIndex2 + 1}</Text>
                                        <Text style={styles.value}>
                                          {formatTxt(obKey?.name, 14) || 'Not Specified'}
                                        </Text>
                                      </View>
                                    </Fragment>
                                  ));
                                }
                                return (
                                  <View style={styles.componentGeneral} key={`component-${objIndex}`}>
                                    {getParentLabel(
                                      component,
                                      component?.data?.type === 'demographics' && getDemographicsLabel(objIndex)
                                    )}
                                    <View
                                      style={
                                        component.data.type === 'incidentExternalLinks'
                                          ? {
                                              ...styles.sectionItemFull,
                                              minHeight: 46,
                                            }
                                          : styles.sectionItem
                                      }

                                      // ...styles.sectionItemFull, minHeight: 86
                                    >
                                      {/* <Text style={styles.label}>
                                        {' '}
                                        {component?.data?.question ||
                                          component?.data?.type}
                                      </Text> */}
                                      {component.data.type === 'howDidYouHearAboutUs' ? (
                                        <Text style={styles.value}>
                                          {objkey.map((key) => getAboutUsValue(key)).join(', ') || 'Not Specified'}
                                        </Text>
                                      ) : Array.isArray(objkey) ? (
                                        <Text style={styles.value} wrap>
                                          {objkey.join(', ') || 'Not Specified'}
                                        </Text>
                                      ) : (
                                        <Text
                                          style={{
                                            ...styles.value,
                                            textOverflow: 'ellipsis',
                                          }}
                                        >
                                          {objkey || 'Not Specified'}
                                        </Text>
                                      )}
                                    </View>
                                  </View>
                                );
                              }

                              if (!objkey) {
                                return (
                                  <Fragment key={`component-${objIndex}`}>
                                    {getParentLabel(
                                      component,
                                      component?.data?.type === 'demographics' && getDemographicsLabel(objIndex)
                                    )}
                                    <View
                                      width="100%"
                                      maxWidth="150px"
                                      marginRight="20px"
                                      marginBottom="10px"
                                      style={styles.sectionItem}
                                    >
                                      <Text variant="body2" style={styles.value}>
                                        Not Specified
                                      </Text>
                                    </View>
                                  </Fragment>
                                );
                              }

                              if (component.type === 'fileUpload') {
                                return (
                                  <>
                                    {objIndex === 0 && getParentLabel(component)}
                                    <View style={{ padding: 10 }} key={`objIndex-${objIndex}`}>
                                      <Text variant="body2" style={styles.label}>
                                        File-{objIndex + 1}
                                      </Text>

                                      <Text variant="body2" style={styles.value}>
                                        {formatTxt(objkey?.name, 20)}
                                      </Text>
                                    </View>
                                  </>
                                );
                              }

                              if (component.type === 'multipleChoices' || component.type === 'dropdown') {
                                return (
                                  <>
                                    {objIndex === 0 && getParentLabel(component)}

                                    <View style={{ padding: 10 }} key={`objIndex-${objIndex}`}>
                                      <Text variant="body2" style={styles.value}>
                                        {(objIndex === 0 && component.value.join(', ')) || ''}
                                      </Text>
                                    </View>
                                  </>
                                );
                              }

                              if (typeof objkey === 'object') {
                                const objKeysFromArray = Object.keys(objkey);
                                return (
                                  <View style={styles.componentGeneral}>
                                    {getParentLabel(component)}
                                    {objKeysFromArray.map((keyFromArray, indexFromArray) => (
                                      <View
                                        width="100%"
                                        maxWidth="150px"
                                        marginRight="20px"
                                        marginBottom="10px"
                                        key={`component-${indexFromArray}`}
                                        style={styles.sectionItem}
                                      >
                                        <Text variant="body2" style={styles.label}>
                                          {getTypeText(keyFromArray)}
                                        </Text>
                                        {keyFromArray === 'fileAnonymously' ? (
                                          <Text variant="body2" style={styles.value}>
                                            {objkey[keyFromArray] ? 'Yes' : 'No'}
                                          </Text>
                                        ) : (
                                          <Text variant="body2" style={styles.value}>
                                            {formatPhoneNumber(objkey[keyFromArray]) || 'Not Specified'}
                                          </Text>
                                        )}
                                      </View>
                                    ))}
                                  </View>
                                );
                              }

                              return (
                                <View style={styles.componentGeneral}>
                                  {getParentLabel(component, component?.data?.type === 'demographics' && 'dateOfBirth')}
                                  <View
                                    style={
                                      component?.data?.type === 'statement'
                                        ? {
                                            ...styles.sectionItemFull,
                                            minHeight: 46,
                                          }
                                        : styles.sectionItem
                                    }
                                    key={`component-${objIndex}`}
                                  >
                                    {/* <Text style={styles.label}>
                                      {' '}
                                      {component?.data?.question ||
                                        component?.data?.type}
                                    </Text> */}
                                    <Text style={styles.value}>
                                      {component?.data?.type === 'isEnglishPrimaryLanguage'
                                        ? formatTxt(objkey !== 'yes' ? `No, ${objkey}` : 'Yes') || 'Not Specified'
                                        : checkValueType(objkey, component?.data?.type) || 'Not Specified'}
                                    </Text>
                                  </View>
                                </View>
                              );
                            });
                          }

                          if (typeof component.value === 'object') {
                            const objectData = component.data;
                            const objKeys = Object.keys(component.value).filter((key) => {
                              if (key === 'fileAnonymously') {
                                return objectData.showFileAnonymously;
                              }
                              return objectData[key]?.visible;
                            });
                            return (
                              <View style={styles.componentGeneral} key={componentIndex}>
                                {getParentLabel(component)}
                                {objKeys.map((objkey, objIndex) => (
                                  <View style={styles.sectionItem} key={`component-${objIndex}`}>
                                    <Text style={styles.label}>
                                      {objectData[objkey]?.question ||
                                        objectData[objkey]?.text ||
                                        getTypeText(objkey) ||
                                        objkey}
                                    </Text>
                                    {objkey === 'fileAnonymously' ? (
                                      <Text style={styles.value}>
                                        {component.value[objkey] === true ? 'Yes' : 'No' || 'Not Specified'}
                                      </Text>
                                    ) : (
                                      <Text style={styles.value}>
                                        {formatPhoneNumber(component.value[objkey]?.name) ||
                                          formatPhoneNumber(component.value[objkey], component.value[objkey]) ||
                                          'Not Specified'}
                                      </Text>
                                    )}
                                  </View>
                                ))}
                              </View>
                            );
                          }

                          return (
                            <View style={styles.componentGeneral}>
                              {getParentLabel(component)}
                              <View
                                style={
                                  component?.type === 'longText' || component?.type === 'shortText'
                                    ? { ...styles.sectionItem, width: '100%' }
                                    : styles.sectionItem
                                }
                              >
                                {/* <Text style={styles.label}>
                                  {' '}
                                  {component?.data?.question ||
                                    component?.data?.type}
                                </Text> */}
                                <Text style={styles.value}>
                                  {' '}
                                  {checkValueType(component?.value, component?.type) || 'Not Specified'}
                                </Text>
                              </View>
                            </View>
                          );
                        })}
                      </View>
                    </View>
                  </Fragment>
                ))}
              </View>
            ))}
            {/* <View style={styles.section}>
              <Text style={styles.sectionText}>
                I. {t('Personal Information')}
              </Text>
              <View style={styles.sectionInput}>
                <View style={styles.sectionItemRight}>
                  <Text style={styles.label}>
                    1. {t('Civilian First Name')}
                  </Text>
                  <Text style={{ ...styles.value }}>
                    {form?.civilian?.firstName || 'Not specified'}
                  </Text>
                  {form?.type === 'COMPLAINT' && (
                    <View
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                        marginTop: 4,
                      }}
                    >
                      <View style={styles.checkBox}>
                        {form?.anonymously && (
                          <View style={styles.filled}></View>
                        )}
                      </View>
                      <Text
                        style={{
                          ...styles.label,
                          fontSize: 7,
                          fontStyle: 'italic',
                        }}
                      >
                        {' '}
                        {t('anonymous')}
                      </Text>
                    </View>
                  )}
                </View>
                <View style={styles.sectionItem}>
                  <Text style={styles.label}>2. {t('Civilian Last Name')}</Text>
                  <Text style={styles.value}>
                    {form?.civilian?.lastName || 'Not specified'}
                  </Text>
                </View>
              </View>
              <View style={styles.sectionInput}>
                <View style={styles.sectionItemRight}>
                  <Text style={styles.label}>3. {t('Email Address')}</Text>
                  <Text style={{ ...styles.value }}>
                    {form?.civilian?.email || 'Not specified'}
                  </Text>
                </View>
                <View style={styles.sectionItem}>
                  <Text style={styles.label}>4. {t('Phone Number')}</Text>
                  <Text style={styles.value}>
                    {form?.civilian?.phone || 'Not specified'}
                  </Text>
                </View>
              </View>
              <View style={styles.sectionInput}>
                <View style={styles.sectionItemFull}>
                  <Text style={styles.label}>5. {t('Address')}</Text>
                  <Text style={styles.value}>
                    {`${
                      form?.civilian?.addressLine1
                        ? form?.civilian?.addressLine1 + ','
                        : ''
                    } ${
                      form?.civilian?.addressLine2
                        ? form?.civilian?.addressLine2 + ', '
                        : ''
                    }${
                      form?.civilian?.city?.name
                        ? form?.civilian?.city?.name + ','
                        : ''
                    } ${
                      form?.civilian?.state?.name
                        ? form?.civilian?.state?.name + ','
                        : ''
                    } ${
                      form?.civilian?.zipCode ? form?.civilian?.zipCode : ''
                    }`}

                    {!form?.civilian?.addressLine1 &&
                      !form?.civilian?.addressLine2 &&
                      !form?.civilian?.state?.name &&
                      !form?.civilian?.city?.name &&
                      !form?.civilian?.zipCode &&
                      'Not specified'}
                  </Text>
                </View>
              </View>
            </View>

            <View style={styles.section}>
              <Text style={styles.sectionText}>
                {' '}
                II. {t('Incident Details')}
              </Text>

              {form?.type === 'COMPLIMENT' && (
                <View style={styles.sectionInput}>
                  <View style={styles.sectionItemFull}>
                    <Text style={styles.label}>6. {t('whyWouldYouLike')}</Text>
                    <Text style={styles.value}>
                      {form?.whyYouWantToThank || 'Not specified'}
                    </Text>
                  </View>
                </View>
              )}

              <View style={styles.sectionInput}>
                <View
                  style={{
                    ...styles.sectionItemFull,
                    minHeight: form?.type === 'COMPLIMENT' ? 280 : 302,
                  }}
                >
                  <Text style={styles.label}>
                    {onPrintNumber(7, 'COMPLIMENT', 6)}. {t('Statement')}
                  </Text>
                  <Text style={styles.value}>{form?.statement}</Text>
                </View>
              </View>
              <View style={styles.sectionInput}>
                <View style={{ ...styles.sectionItemFull, minHeight: 86 }}>
                  <Text style={styles.label}>
                    {' '}
                    {onPrintNumber(8, 'COMPLIMENT', 7)}. {t('links')}
                  </Text>
                  {form?.links?.map((link, i) => (
                    <Text key={i} style={styles.value}>
                      {link}
                    </Text>
                  ))}
                </View>
              </View>

              {form?.type === 'COMPLAINT' && (
                <>
                  <View style={styles.subSection}>
                    <Text style={{ ...styles.value, marginTop: 0 }}>
                      {t('Incident Details')}
                    </Text>
                  </View>
                  <View style={styles.sectionInput}>
                    <View style={{ ...styles.sectionItemRight, width: '45%' }}>
                      <Text style={styles.label}>
                        {' '}
                        {onPrintNumber(8, 'COMPLAINT')}. {t('location')}
                      </Text>
                      <Text style={styles.value}>
                        {`${
                          form?.location?.address
                            ? form?.location?.address + ','
                            : ''
                        } ${
                          form?.location?.city?.name
                            ? form?.location?.city?.name + ','
                            : ''
                        } ${
                          form?.location?.state?.name
                            ? form?.location?.state?.name + ','
                            : ''
                        }`}
                        {!form?.location?.address &&
                          !form?.location?.city?.name &&
                          !form?.location?.state?.name &&
                          'Not specified'}
                      </Text>
                    </View>
                    <View style={{ ...styles.sectionItemRight, width: '20%' }}>
                      <Text style={styles.label}>
                        {' '}
                        {onPrintNumber(9, 'COMPLAINT')}. {t('date')}
                      </Text>
                      <Text style={styles.value}>
                        {form?.location?.datetime
                          ? moment(form?.location?.datetime).format(
                              'MM/DD/YYYY'
                            )
                          : 'Not specified'}
                      </Text>
                    </View>
                    <View style={{ ...styles.sectionItem, width: '35%' }}>
                      <Text style={styles.label}>
                        {' '}
                        {onPrintNumber(10, 'COMPLAINT')}. {t('time')}
                      </Text>
                      <Text style={styles.value}>
                        {getTimeRange(form) || 'Not specified'}
                      </Text>
                    </View>
                  </View>
                </>
              )}

              <View style={styles.subSection}>
                <Text style={{ ...styles.value, marginTop: 0 }}>
                  {t('officers')}
                </Text>
              </View>
              {form?.employees?.map((row, i) => (
                <View style={styles.sectionInput} key={i}>
                  <View style={{ ...styles.sectionItemRight, width: '33.33%' }}>
                    <Text style={styles.label}>
                      {onPrintNumber(
                        counter.current + 1,
                        'COMPLIMENT',
                        counter.current + 1
                      )}
                      . {t('officer name')} #{i + 1}
                    </Text>
                    <Text style={styles.value}>{row.firstName}</Text>
                  </View>
                  <View style={{ ...styles.sectionItemRight, width: '33.33%' }}>
                    <Text style={styles.label}>
                      {onPrintNumber(
                        counter.current + 1,
                        'COMPLIMENT',
                        counter.current + 1
                      )}
                      . {t('officer lastName')} #{i + 1}
                    </Text>
                    <Text style={styles.value}>{row.lastName}</Text>
                  </View>
                  <View style={{ ...styles.sectionItem, width: '33.33%' }}>
                    <Text style={styles.label}>
                      {onPrintNumber(
                        counter.current + 1,
                        'COMPLIMENT',
                        counter.current + 1
                      )}
                      . {t('officer badge')} #{i + 1}
                    </Text>
                    <Text style={styles.value}>{row.id}</Text>
                  </View>
                </View>
              ))}
              {form?.type === 'COMPLAINT' && (
                <>
                  <View style={styles.subSection}>
                    <Text style={{ ...styles.value, marginTop: 0 }}>
                      {t('witness')}
                    </Text>
                  </View>
                  {form?.others?.map((row, i) => (
                    <>
                      <View style={styles.sectionInput}>
                        <View style={{ ...styles.sectionItemRight }}>
                          <Text style={styles.label}>
                            {' '}
                            {onPrintNumber(
                              counter.current + 1,
                              'COMPLIMENT',
                              counter.current + 1
                            )}
                            . {t('witness name')}
                          </Text>
                          <Text style={styles.value}>{row.firstName}</Text>
                        </View>
                        <View style={{ ...styles.sectionItem }}>
                          <Text style={styles.label}>
                            {onPrintNumber(
                              counter.current + 1,
                              'COMPLIMENT',
                              counter.current + 1
                            )}
                            . {t('witness lastName')}
                          </Text>
                          <Text style={styles.value}>{row.lastName}</Text>
                        </View>
                      </View>
                      <View style={styles.sectionInput}>
                        <View style={{ ...styles.sectionItemRight }}>
                          <Text style={styles.label}>
                            {' '}
                            {onPrintNumber(
                              counter.current + 1,
                              'COMPLIMENT',
                              counter.current + 1
                            )}
                            . {t('Phone Number')}
                          </Text>
                          <Text style={styles.value}>{row.phone}</Text>
                        </View>
                        <View style={{ ...styles.sectionItem }}>
                          <Text style={styles.label}>
                            {' '}
                            {onPrintNumber(
                              counter.current + 1,
                              'COMPLIMENT',
                              counter.current + 1
                            )}
                            . {t('Email Address')}
                          </Text>
                          <Text style={styles.value}>{row.email}</Text>
                        </View>
                      </View>
                    </>
                  ))}
                </>
              )}

              {form?.type === 'COMPLIMENT' && (
                <>
                  <View style={styles.subSection}>
                    <Text style={{ ...styles.value, marginTop: 0 }}>
                      {t('EventInfo')}
                    </Text>
                  </View>
                  <View style={styles.sectionInput}>
                    <View
                      style={{ ...styles.sectionItemRight, width: '33.33%' }}
                    >
                      <Text style={styles.label}>
                        {' '}
                        {onPrintNumber(
                          counter.current + 1,
                          'COMPLIMENT',
                          counter.current + 1
                        )}
                        . {t('eventLocationL')}
                      </Text>
                      <Text style={styles.value}>
                        {form?.location?.address || 'Not specified'}
                      </Text>
                    </View>
                    <View
                      style={{ ...styles.sectionItemRight, width: '33.33%' }}
                    >
                      <Text style={styles.label}>
                        {' '}
                        {onPrintNumber(
                          counter.current + 1,
                          'COMPLIMENT',
                          counter.current + 1
                        )}
                        . {t('eventDate')}
                      </Text>
                      <Text style={styles.value}>
                        {form?.location?.datetime
                          ? moment(form?.location?.datetime).format(
                              'MM/DD/YYYY'
                            )
                          : 'Not specified'}
                      </Text>
                    </View>
                    <View style={{ ...styles.sectionItem, width: '33.33%' }}>
                      <Text style={styles.label}>
                        {' '}
                        {onPrintNumber(
                          counter.current + 1,
                          'COMPLIMENT',
                          counter.current + 1
                        )}
                        . {t('eventTime')}
                      </Text>
                      <Text style={styles.value}>
                        {getTimeRange(form) || 'Not specified'}
                      </Text>
                    </View>
                  </View>
                </>
              )}
            </View>

            <View style={styles.section}>
              <Text style={styles.sectionText}>
                {' '}
                III. {t('additional information')}
              </Text>
              <View style={styles.sectionInput}>
                <View style={{ ...styles.sectionItemRight }}>
                  <Text style={styles.label}>
                    {' '}
                    {onPrintNumber(
                      counter.current + 1,
                      'COMPLIMENT',
                      counter.current + 1
                    )}
                    . {t(`how`)}
                  </Text>

                  <Text style={styles.value}>
                    {' '}
                    {form?.howDidYouHearAboutUs?.map((how, i) => (
                      <Text>
                        {getAboutUsValue(how)}
                        {form?.howDidYouHearAboutUs?.length > 1 &&
                          form?.howDidYouHearAboutUs?.length !== i + 1 &&
                          ','}{' '}
                      </Text>
                    )) || 'Not specified'}
                  </Text>
                </View>
                <View style={{ ...styles.sectionItem }}>
                  <Text style={styles.label}>
                    {' '}
                    {onPrintNumber(
                      counter.current + 1,
                      'COMPLIMENT',
                      counter.current + 1
                    )}
                    . {t('whatDescribeYou')}
                  </Text>
                  <Text style={styles.value}>
                    {form?.whatBestDescribesYou || 'Not specified'}
                  </Text>
                </View>
              </View>
              <View style={styles.sectionInput}>
                <View style={{ ...styles.sectionItemRight, width: '20%' }}>
                  <Text style={styles.label}>
                    {' '}
                    {onPrintNumber(
                      counter.current + 1,
                      'COMPLIMENT',
                      counter.current + 1
                    )}
                    . {t('birth')}
                  </Text>
                  <Text style={styles.value}>
                    {form?.demographic?.birthdate
                      ? moment(form?.demographic?.birthdate).format(
                          'MM/DD/YYYY'
                        )
                      : 'Not specified'}
                  </Text>
                </View>
                <View style={{ ...styles.sectionItemRight, width: '30%' }}>
                  <Text style={styles.label}>
                    {' '}
                    {onPrintNumber(
                      counter.current + 1,
                      'COMPLIMENT',
                      counter.current + 1
                    )}
                    . {t('gender')}
                  </Text>
                  <Text style={styles.value}>
                    {form?.demographic?.gender.map((gd, i) => (
                      <Text>
                        {gd}
                        {form?.demographic?.gender?.length > 1 &&
                          form?.demographic?.gender?.length !== i + 1 &&
                          ','}{' '}
                      </Text>
                    ))}
                    {form?.demographic?.gender.length === 0 && (
                      <Text>Not specified</Text>
                    )}
                  </Text>
                </View>
                <View style={{ ...styles.sectionItem }}>
                  <Text style={styles.label}>
                    {' '}
                    {onPrintNumber(
                      counter.current + 1,
                      'COMPLIMENT',
                      counter.current + 1
                    )}
                    . {t('sOrientation')}
                  </Text>
                  <Text style={styles.value}>
                    {form?.demographic?.sexualOrientation.map((so, i) => (
                      <Text>
                        {so}
                        {form?.demographic?.sexualOrientation?.length > 1 &&
                          form?.demographic?.sexualOrientation?.length !==
                            i + 1 &&
                          ','}{' '}
                      </Text>
                    ))}
                    {form?.demographic?.sexualOrientation.length === 0 && (
                      <Text>Not specified</Text>
                    )}
                  </Text>
                </View>
              </View>
              <View style={styles.sectionInput}>
                <View style={{ ...styles.sectionItemRight }}>
                  <Text style={styles.label}>
                    {' '}
                    {onPrintNumber(
                      counter.current + 1,
                      'COMPLIMENT',
                      counter.current + 1
                    )}
                    . {t('race')}
                  </Text>
                  <Text style={styles.value}>
                    {form?.demographic?.raceEthnicity.map((rc, i) => (
                      <Text>
                        {rc}
                        {form?.demographic?.raceEthnicity?.length > 1 &&
                          form?.demographic?.raceEthnicity?.length !== i + 1 &&
                          ','}{' '}
                      </Text>
                    ))}
                    {form?.demographic?.raceEthnicity.length === 0 && (
                      <Text>Not specified</Text>
                    )}
                  </Text>
                </View>
                <View style={{ ...styles.sectionItem }}>
                  <Text style={styles.label}>
                    {' '}
                    {onPrintNumber(
                      counter.current + 1,
                      'COMPLIMENT',
                      counter.current + 1
                    )}
                    . {t('language')}
                  </Text>
                  <Text style={styles.value}>
                    {form?.demographic?.primaryLanguage || 'Not specified'}
                  </Text>
                </View>
              </View>
            </View> */}
          </Page>
        </Document>
      </PDFViewer>
    </Box>
  );
};

export default CasePdf;
