/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useState, useEffect, useRef } from 'react';
import { Box, Button, CircularProgress, Typography, makeStyles } from '@material-ui/core';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import { CustomSwitch } from '../formEngine/components/WrapperComponent';
import { createCubeBucket, fetchCubeBuckets } from '../services/unarmed';
import { componentsName } from '../pages/CaseDetails/UpdateCaseComponents/CaseUpdateRenderer';
import { useDataReportLinkProvider } from '../provider/DataReportLinkProvider';
import { getCubes } from '../services/cube';

const normalizeCubeName = (name) => name.trim();

/**
 * Styles for the DataReportLink component
 */
const useStyles = makeStyles(() => ({
  settingsQuestions: {
    fontSize: 14,
    minWidth: 160,
  },
  searchBox: {
    padding: '8px',
    width: 300,
    backgroundColor: '#fff',
    border: '1px solid rgb(184 184 184)',
    borderRadius: 4,
  },
  button: {
    color: '#2e66fe',
    fontSize: 14,
    fontWeight: '600',
  },
  input: {
    color: '#575a66',
    border: 0,
    fontWeight: '600',
    width: '100%',
    borderBottom: '1px solid rgb(184 184 184)',
    height: 30,
    fontSize: 14,

    '&::placeholder': {
      color: 'rgb(136 141 160)',
      fontWeight: 600,
    },
  },
  colorText: {
    color: 'rgb(136 141 160)',
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: 14,
  },
  boxContent: {
    borderBottom: '1px solid rgb(184 184 184)',
  },
  resultText: {
    color: 'rgb(184 184 184)',
    fontWeight: '600',
    fontSize: 14,
    marginBottom: 8,
    cursor: 'pointer',
  },
  selectedText: {
    color: '#575a66',
    fontWeight: '600',
    fontSize: 14,
  },
}));

/**
 * DataReportLink component allows users to search and create data report links.
 *
 * @component
 * @param {Object} props - The component props
 * @param {string} props.componentId - The unique identifier for the component
 * @param {string} props.componentType - The type of the component
 * @param {Function} props.onSelect - Callback function invoked when a data report link is selected
 * @param {Object} props.defaultDataReportLink - The default data report link
 * @returns {JSX.Element}
 */
const DataReportLink = ({
  componentId,
  componentType,
  onSelect,
  onChangeChecked,
  defaultDataReportLink,
  isMultipleChoice,
}) => {
  const [search, setSearch] = useState('');
  const [checked, setChecked] = useState(false);
  const [noDataFound, setNoDataFound] = useState(false);
  const [noDataFoundText, setNoDataFoundText] = useState('');
  const [showResults, setShowResults] = useState(false);
  const [selected, setSelected] = useState(undefined);
  const [addedDefault, setAddedDefault] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedMultipleChoice, setSelectedMultipleChoice] = useState(undefined);
  // const [fetchedDefaultCubes, setFetchedDefaultCubes] = useState(false);

  const { componentsChanged, setComponentsChanged } = useDataReportLinkProvider();

  const [results, setResults] = useState([]);

  const classes = useStyles();
  const intervalRef = useRef();

  const doneTypingInterval = 750;

  const handleOnFetchCubeBucketsByType = useCallback(() => {
    if (checked) {
      setLoading(true);
      fetchCubeBuckets({
        type: componentType,
        ...(componentType === 'dropdown'
          ? {
              dropdownMultipleChoice: isMultipleChoice,
            }
          : {}),
      })
        .then((res) => {
          setLoading(false);
          if (res.data.length > 0) {
            setResults(res.data);
            setShowResults(true);
            setNoDataFound(false);
            setNoDataFoundText('');
          } else {
            setNoDataFound(true);
            setShowResults(false);
            if (componentType === 'dropdown') {
              setNoDataFoundText(`${componentType} - ${isMultipleChoice ? 'multiple choice' : 'single choice'}`);
            } else {
              setNoDataFoundText(componentType);
            }
          }
        })
        .catch((error) => {
          toast.error(
            error?.response?.data?.error?.details?.[0]?.message || error?.response?.data?.message || error?.message
          );
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked, componentType, isMultipleChoice]);

  const handleOnFetchCubeBuckets = useCallback(() => {
    if (search !== '') {
      setLoading(true);
      fetchCubeBuckets({
        desc: normalizeCubeName(search),
        type: componentType,
        ...(componentType === 'dropdown'
          ? {
              dropdownMultipleChoice: isMultipleChoice,
            }
          : {}),
      })
        .then((res) => {
          setLoading(false);
          if (res.data.length === 0) {
            setNoDataFound(true);
            setShowResults(false);
            setNoDataFoundText(search);
          } else {
            setResults(res.data);
            setShowResults(true);
            setNoDataFound(false);
            setNoDataFoundText('');
          }
        })
        .catch((error) => {
          toast.error(
            error?.response?.data?.error?.details?.[0]?.message || error?.response?.data?.message || error?.message
          );
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, isMultipleChoice]);

  const handleOnCreateCubeBucket = useCallback(() => {
    createCubeBucket({
      type: componentType,
      description: normalizeCubeName(search),
      ...(componentType === 'dropdown'
        ? {
            dropdownMultipleChoice: isMultipleChoice,
          }
        : {}),
    })
      .then((res) => {
        toast.success(
          'Cube bucket created successfully! Note: This new cube bucket can take a few minutes to appear on the data module'
        );
        setSearch('');
        setAddedDefault(true);
        onSelect(res.data?._id);
        setSelected(res.data);
        setShowResults(false);
        setNoDataFound(false);
        setNoDataFoundText('');
        getCubes();
        setComponentsChanged((components) => ({
          ...components,
          [componentId]: {
            dataReportLink: res.data,
            checked: true,
          },
        }));
      })
      .catch((error) => {
        toast.error(
          error?.response?.data?.error?.details?.[0]?.message || error?.response?.data?.message || error?.message
        );
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentType, search, isMultipleChoice]);

  const handleOnClickBox = useCallback(
    (e) => {
      e.preventDefault();

      if (selected) {
        setSelected(undefined);
      }
    },
    [selected]
  );

  const handleOnCancel = useCallback(() => {
    const dataReportLink =
      typeof defaultDataReportLink === 'string' || defaultDataReportLink === undefined
        ? componentsChanged[componentId]?.dataReportLink
        : defaultDataReportLink;

    if (!selected && dataReportLink) {
      setSelected(dataReportLink);
    }

    setSearch('');
    setShowResults(false);
    setNoDataFound(false);
    setNoDataFoundText('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, defaultDataReportLink, componentId, componentsChanged]);

  useEffect(() => {
    if (defaultDataReportLink && !addedDefault) {
      const dataReportLink =
        typeof defaultDataReportLink === 'string'
          ? componentsChanged[componentId]?.dataReportLink
          : defaultDataReportLink;

      onSelect(dataReportLink?._id);
      setSelected(dataReportLink);

      setChecked(true);
      onChangeChecked(true);
      setAddedDefault(true);
      setComponentsChanged((components) => ({
        ...components,
        [componentId]: {
          dataReportLink,
          checked: true,
        },
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultDataReportLink, addedDefault, componentsChanged]);

  useEffect(() => {
    if (componentsChanged[componentId]?.checked) {
      setChecked(true);
      onChangeChecked(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentsChanged]);

  useEffect(() => {
    handleOnFetchCubeBucketsByType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked, isMultipleChoice]);

  useEffect(() => {
    if (
      componentType === 'dropdown' &&
      selectedMultipleChoice !== undefined &&
      isMultipleChoice !== selectedMultipleChoice
    ) {
      setSearch('');
      setShowResults(false);
      setNoDataFound(false);
      setNoDataFoundText('');
      onSelect(undefined);
      setSelected(undefined);
      setComponentsChanged((components) => ({
        ...components,
        [componentId]: {
          dataReportLink: undefined,
          checked,
        },
      }));
    } else {
      setSelectedMultipleChoice(isMultipleChoice);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMultipleChoice, checked, selectedMultipleChoice, componentType]);

  return (
    <Box padding="0 10px" marginBottom="10px">
      <Box display="flex" alignItems="center">
        <Typography className={classes.settingsQuestions}>Data Report Link</Typography>
        <CustomSwitch
          checked={checked}
          onChange={(e) => {
            const _checked = e.target.checked;

            if (!_checked && defaultDataReportLink) {
              onSelect(undefined);
            }

            setComponentsChanged((components) => ({
              ...components,
              [componentId]: {
                ...components[componentId],
                checked: _checked,
              },
            }));
            setChecked(_checked);
            onChangeChecked(_checked);
          }}
        />
      </Box>
      {checked && (
        <div className={classes.searchBox} style={selected ? { cursor: 'pointer' } : {}} onClick={handleOnClickBox}>
          {selected ? (
            <>
              <Typography className={classes.selectedText}>
                {selected.description} {selected.type ? `- ${componentsName?.[selected?.type]}` : ''}
              </Typography>
            </>
          ) : (
            <>
              <input
                value={search}
                className={classes.input}
                placeholder="Search or create data toast (max 40)"
                onChange={(e) => {
                  const { value } = e.target;
                  const valueNormalized = value.replace(/^ +/, '');

                  setSearch(valueNormalized.replace(/^\d+/, ''));
                }}
                onKeyUp={() => {
                  clearTimeout(intervalRef.current);

                  intervalRef.current = setTimeout(() => {
                    handleOnFetchCubeBuckets();
                  }, doneTypingInterval);
                }}
                onKeyDown={() => {
                  clearTimeout(intervalRef.current);
                }}
              />
              {loading && (
                <Box
                  className={classes.boxContent}
                  paddingTop={1}
                  paddingBottom={1}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <CircularProgress size="24px" />
                </Box>
              )}
              {!loading && showResults && (
                <Box className={classes.boxContent} paddingTop={1}>
                  {results.map((result, resultKey) => (
                    <Typography
                      className={classes.resultText}
                      key={resultKey}
                      onClick={() => {
                        setSelectedMultipleChoice(isMultipleChoice);
                        setAddedDefault(true);
                        setSelected(result);
                        setShowResults(false);
                        setResults([]);
                        setSearch('');
                        onSelect(result?._id);
                        setComponentsChanged((components) => ({
                          ...components,
                          [componentId]: {
                            dataReportLink: result,
                            checked: true,
                          },
                        }));
                      }}
                    >
                      {result.description} {result.type ? `- ${componentsName?.[result?.type]}` : ''}
                    </Typography>
                  ))}
                </Box>
              )}
              {!loading && noDataFound && (
                <Box
                  className={classes.boxContent}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  paddingY={1}
                >
                  <Typography className={classes.colorText}>
                    {componentType === 'dropdown' ? (
                      <>
                        No data with the component "{noDataFoundText}" <br /> were found
                      </>
                    ) : (
                      <>
                        No data with the name "{noDataFoundText}" <br /> were found
                      </>
                    )}
                  </Typography>
                </Box>
              )}
              <Box display="flex" justifyContent="flex-end" alignItems="center" marginTop={1}>
                <Button className={classes.button} color="primary" onClick={handleOnCancel}>
                  CANCEL
                </Button>
                <Button className={classes.button} color="primary" onClick={handleOnCreateCubeBucket}>
                  CREATE
                </Button>
              </Box>
            </>
          )}
        </div>
      )}
    </Box>
  );
};

DataReportLink.propTypes = {
  componentId: PropTypes.string.isRequired,
  componentType: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  defaultDataReportLink: PropTypes.object,
  isMultipleChoice: PropTypes.bool,
  onChangeChecked: PropTypes.func,
};

export default DataReportLink;
