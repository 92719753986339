const validateForm = (values) => {
  for (const value of values) {
    if (!value) {
      return true;
    }
  }

  return false;
};

export default validateForm;

export const checkValues = (savedValue, preValues) => {
  if (savedValue || savedValue?.length > 0) {
    return savedValue;
  }

  return preValues;
};

export const checkNullValues = (obj) => {
  const copyObj = {};
  const keys = Object.keys(obj);

  keys.forEach((key) => {
    if (obj[key] || obj[key] === '') {
      copyObj[key] = obj[key];
    }
  });

  return copyObj;
};

export const validateEmail = (email) => email?.toLowerCase()?.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/);
