import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { downloadImage } from '../../../utils/libs';
import { validateIsoDate } from '../../../utils';
import LogIcon from '../../../components/LogIcon';
import TimeLineItem from '../../Activity/TimeLineItem';
import HTMLContent from '../../../components/HTMLContent';
import CarouselModal from '../../../components/CarouselModal';
import Tag from '../../../components/Tag';
import ArchivedImage from '../../../components/ArchivedImage';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  imgModal: {
    objectFit: 'contain',
    maxWidth: 600,
    maxHeight: 400,
  },
  link: {
    display: 'flex',
    position: 'absolute',
    top: 10,
    right: 80,
    cursor: 'pointer',
    padding: 13,
    zIndex: 999999,
    borderRadius: '50%',
    background: '#494949',
  },
  emphasis: {
    color: '#4b7bff',
    textTransform: 'capitalize',
    fontWeight: '600',
  },
  lightEmphasis: { color: '#2E66FE', fontWeight: '500' },
}));

const getActionLabel = (key) => {
  if (key === 'startDateTime') {
    return 'Start Date Time';
  }
  if (key === 'endDateTime') {
    return 'End Date Time';
  }

  if (key === 'notes') {
    return 'Notes';
  }

  if (key === 'description') {
    return 'Description';
  }
  if (key === 'status') {
    return 'Status';
  }
};

const ProjectLog = ({ data, type, createdAt }) => {
  const classes = useStyles();
  const {
    user,
    project,
    changes,
    action,
    newStatus,
    oldStatus,
    assignedUser,
    newSubType,
    oldSubType,
    newType,
    oldType,
    unassignedUser,
    project1,
    project2,
    note,
    projectTemplate,
    files,
    removedProject,
  } = data;

  const [open, setOpen] = React.useState(false);

  const [filesSelected, setFilesSelected] = React.useState({ files: [], selectedFileIndex: 0 });
  const [loadingOpenFiles, setLoadingOpenFiles] = React.useState([]);

  const handleOpen = (filesOp) => {
    setOpen(true);
    const filesToOpen = filesOp.filter((flop) => flop.mimetype.includes('image') || flop.mimetype.includes('video'));
    setFilesSelected({ files: filesToOpen, selectedFileIndex: 0 });
    setLoadingOpenFiles(
      filesToOpen.map(() => ({
        loading: false,
      }))
    );
  };

  const stripHTML = (str) => {
    if (!str) return '';
    return str.replace(/<\/?[^>]+(>|$)/g, '');
  };

  const handleClickEvents = (fl) => ({
    onDownload: () => downloadImage(fl.download_url, fl.name),
    onClick: () => handleOpen(files),
  });

  const renderText = () => {
    if (type === 'PROJECT_CONTACT_ASSIGNED') {
      return (
        <span className={classes.texxt}>
          <strong>{user?.email}</strong> has assigned contact{' '}
          <Link to={`/contacts/${data?.contact?._id}`}>
            <span className={classes.emphasis}>{data?.contact?.email}</span>
          </Link>
        </span>
      );
    }

    if (type === 'PROJECT_CONTACT_DELETED') {
      return (
        <span className={classes.texxt}>
          <strong>{user?.email}</strong> has removed contact{' '}
          <span className={classes.emphasis}>{data?.contact?.email}</span>
        </span>
      );
    }

    if (type === 'PROJECT_FILE_UPLOADED_TAGS_ADDED') {
      return (
        <span className={classes.texxt}>
          <strong>{user?.email}</strong> has added {data?.tags?.length > 1 ? 'tags' : 'tag'}{' '}
          {data?.tags?.map((tag) => (
            <Tag bg={tag?.backgroundColor} color={tag?.textColor} key={tag?._id} text={tag?.name} />
          ))}{' '}
          to a new attachment: <span className={classes.emphasis}>{data?.file?.name}</span>{' '}
        </span>
      );
    }

    if (type === 'PROJECT_ACTION_ATTACHMENT_TAGS_ADDED') {
      return (
        <span className={classes.texxt}>
          <strong>{user?.email}</strong> has added {data?.tags?.length > 1 ? 'tags' : 'tag'}{' '}
          {data?.tags?.map((tag) => (
            <Tag bg={tag?.backgroundColor} color={tag?.textColor} key={tag?._id} text={tag?.name} />
          ))}{' '}
          to attachment: <span className={classes.emphasis}>{data?.file?.name}</span> to an action
        </span>
      );
    }

    if (type === 'PROJECT_NOTE_TAGS_ADDED') {
      return (
        <span className={classes.texxt}>
          <strong>{user?.email}</strong> has added {data?.tags?.length > 1 ? 'tags' : 'tag'}{' '}
          {data?.tags?.map((tag) => (
            <Tag bg={tag?.backgroundColor} color={tag?.textColor} key={tag?._id} text={tag?.name} />
          ))}{' '}
          to note
        </span>
      );
    }

    if (type === 'PROJECT_ATTACHMENT_TAGS_ADDED') {
      return (
        <span className={classes.texxt}>
          <strong>{user?.email}</strong> has added {data?.tags?.length > 1 ? 'tags' : 'tag'}{' '}
          {data?.tags?.map((tag) => (
            <Tag bg={tag?.backgroundColor} color={tag?.textColor} key={tag?._id} text={tag?.name} />
          ))}{' '}
          to attachment: <span className={classes.emphasis}>{data?.file?.name}</span>
        </span>
      );
    }

    if (type === 'PROJECT_ATTACHMENT_TAGS_DELETE') {
      return (
        <span className={classes.texxt}>
          <strong>{data?.user?.email}</strong> has removed {data?.tags?.length > 1 ? 'tags' : 'tag'}{' '}
          {data?.tags?.map((tag) => (
            <Tag bg={tag?.backgroundColor} color={tag?.textColor} key={tag?._id} text={tag?.name} />
          ))}{' '}
          from attachment: {data?.file && <span className={classes.emphasis}>file name</span>}
        </span>
      );
    }

    if (type === 'PROJECT_VIEWED') {
      return (
        <span className={classes.texxt}>
          <strong>{user?.email}</strong> viewed project.
        </span>
      );
    }

    if (type === 'PROJECT_NOTE_UNARCHIVED') {
      return (
        <span className={classes.texxt}>
          <strong>{user?.email}</strong> has unarchived the note{' '}
          <span style={{ color: '#2E66FE', fontWeight: '500' }}>{data?.note?.text}</span> on the project with tracking
          number{' '}
          <span style={{ color: '#2E66FE', fontWeight: '500' }}>{data?.project?.name}</span>

        </span>
      );
    }

    if (type === 'PROJECT_NOTE_ARCHIVED') {
      return (
        <span className={classes.texxt}>
          <strong>{user?.email}</strong> has archived the note{' '}
          <span style={{ color: '#2E66FE', fontWeight: '500' }}>{stripHTML(data?.comment?.text)}</span> on the project with tracking
          number{' '}
          <span style={{ color: '#2E66FE', fontWeight: '500' }}>{stripHTML(data?.project?.name)}</span>
        </span>
      );
    }
    if (type === 'PROJECT_ACTIONS_ADDED') {
      return (
        <span className={classes.texxt}>
          <strong>{user?.email}</strong> added new action
        </span>
      );
    }
    if (type === 'PROJECT_ACTIONS_ASSIGNED') {
      return (
        <span className={classes.texxt}>
          <strong>{user?.email}</strong> assigned new user:{' '}
          <span className={classes.lightEmphasis}>
            {assignedUser?.firstName} {assignedUser?.lastName}
          </span>{' '}
          to an action
        </span>
      );
    }
    if (type === 'PROJECT_ACTIONS_UNASSIGNED') {
      return (
        <span className={classes.texxt}>
          <strong>{user?.email}</strong> unassigned new user:{' '}
          <span className={classes.lightEmphasis}>
            {unassignedUser?.firstName} {unassignedUser?.lastName}
          </span>{' '}
          to action
        </span>
      );
    }
    if (type === 'PROJECT_ACTIONS_REMOVED') {
      return (
        <span className={classes.texxt}>
          <strong>{user?.email}</strong> removed an action
        </span>
      );
    }
    if (type === 'PROJECT_TEMPLATE_SAVED') {
      return (
        <span className={classes.texxt}>
          <strong>{user?.email}</strong> saved template with name.{' '}
          <span className={classes.lightEmphasis}>{projectTemplate?.name}</span>
        </span>
      );
    }
    if (type === 'PROJECT_FILE_UPLOADED') {
      return (
        <span className={classes.texxt}>
          <strong>{user?.email}</strong> has added attachment(s) to project:{' '}
          <span className={classes.lightEmphasis}>{project?.name}</span>
        </span>
      );
    }
    if (type === 'PROJECT_RELATED') {
      return (
        <span className={classes.texxt}>
          <strong>{user?.email}</strong> has related the project{' '}
          <span className={classes.lightEmphasis}>{project1?.name}</span> with project{' '}
          <span className={classes.lightEmphasis}>{project2?.name}</span>
        </span>
      );
    }
    if (type === 'PROJECT_REMOVED_RELATED') {
      return (
        <span className={classes.texxt}>
          <strong>{user?.email}</strong> has unrelated the project{' '}
          <span className={classes.lightEmphasis}>{removedProject?.name}</span>{' '}
        </span>
      );
    }
    if (type === 'PROJECT_RECOMMENDATIONS_DELETED') {
      return (
        <span className={classes.texxt}>
          <strong>{user?.email}</strong> has deleted a recommendation from project:{' '}
          <span className={classes.lightEmphasis}>{project?.name}</span>
        </span>
      );
    }

    if (type === 'PROJECT_NOTE_FILE') {
      const hasFiles = files.length > 0;
      const hasComment = !!note;
      const hasBoth = hasFiles && hasComment;
      return (
        <>
          <strong>{user.email}</strong>{' '}
          {hasComment && (
            <>
              made a <span className={classes.emphasis}>new comment</span>
            </>
          )}
          {hasBoth && ' and '}
          {hasFiles && (
            <>
              <span className={classes.emphasis}>uploaded {files.length}</span> file(s)
            </>
          )}{' '}
          to the project
          {hasComment && (
            <>
              <br />
              <HTMLContent content={note} />
            </>
          )}
          {hasFiles && (
            <>
              <Box display="flex" flexDirection="column">
                {files.map((file, index) => {
                  const showPreview = !file?.archivedDate;
                  return (
                    <>
                      <Box display="flex" alignItems="center" justifyContent="flex-start">
                        <PreviewImg
                          key={file._id}
                          style={
                            !file.name.includes('.pdf')
                              ? { height: 100, margin: 6 }
                              : { height: 35, width: 'fit-content' }
                          }
                        >
                          {showPreview ? (
                            <LogIcon fl={file} handleClickEvents={handleClickEvents(file)} index={index} />
                          ) : (
                            <ArchivedImage />
                          )}
                        </PreviewImg>
                        <Typography style={{ fontSize: 14 }}>{file.name}</Typography>
                      </Box>
                    </>
                  );
                })}
              </Box>
            </>
          )}
        </>
      );
    }
    if (type === 'PROJECT_ACTION_ATTACHMENT') {
      return (
        <span className={classes.texxt}>
          <strong>{user?.email}</strong> has added attachment(s) to action {action} in the project:{' '}
          <span className={classes.lightEmphasis}>{project?.name}</span>
        </span>
      );
    }
    if (type === 'PROJECT_ASSIGNED') {
      return (
        <span className={classes.texxt}>
          <strong>{user?.email}</strong> Assigned{' '}
          <span className={classes.lightEmphasis}>
            {assignedUser?.firstName} {assignedUser?.lastName}
          </span>{' '}
          to project.
        </span>
      );
    }
    if (type === 'PROJECT_UNASSIGNED') {
      return (
        <span className={classes.texxt}>
          <strong>{user?.email}</strong> Removed{' '}
          <span className={classes.lightEmphasis}>
            {unassignedUser?.firstName} {unassignedUser?.lastName}
          </span>{' '}
          to project.
        </span>
      );
    }
    if (type === 'PROJECT_CREATED') {
      return (
        <span className={classes.texxt}>
          <strong>{user?.email}</strong> created project: <span className={classes.lightEmphasis}>{project?.name}</span>
        </span>
      );
    }
    if (type === 'PROJECT_STATUS_CHANGED') {
      return (
        <span className={classes.texxt}>
          <strong>{user?.email}</strong> has changed the project status from{' '}
          <span className={classes.lightEmphasis}>{oldStatus}</span> to{' '}
          <span className={classes.lightEmphasis}>{newStatus}</span>
        </span>
      );
    }
    if (type === 'PROJECT_SUBTYPE_CHANGED') {
      return (
        <span className={classes.texxt}>
          <strong>{user?.email}</strong> has changed the project Subtype from{' '}
          <span className={classes.lightEmphasis}>{oldSubType}</span> to{' '}
          <span className={classes.lightEmphasis}>{newSubType}</span>
        </span>
      );
    }
    if (type === 'PROJECT_TYPE_CHANGED') {
      return (
        <span className={classes.texxt}>
          <strong>{user?.email}</strong> has changed the project Type from{' '}
          <span className={classes.lightEmphasis}>{oldType}</span> to{' '}
          <span className={classes.lightEmphasis}>{newType}</span>
        </span>
      );
    }
    if (type === 'PROJECT_RECOMMENDATIONS_ADDED') {
      return (
        <span className={classes.texxt}>
          <strong>{user?.email}</strong> created a new recommendation
        </span>
      );
    }
    if (type === 'PROJECT_RECOMMENDATIONS_EDITED') {
      return changes?.map((change, index) => {
        const isLongText = change.key === 'description' || change.key === 'notes';

        if (isLongText) {
          return (
            <Box key={index}>
              <strong>{user.email}</strong> has {!change?.oldValue ? 'added' : 'changed'} {getActionLabel(change?.key)}{' '}
              in recommendation
              {!change?.oldValue ? (
                <HTMLContent content={change.newValue} />
              ) : (
                <>
                  {' from: '}
                  <HTMLContent content={change.oldValue} />
                  {' to: '}
                  <HTMLContent content={change.newValue} />
                </>
              )}
            </Box>
          );
        }
        return (
          <Box key={index}>
            <strong>{user.email}</strong> has {!change?.oldValue ? 'added' : 'changed'}{' '}
            {!change?.oldValue ? (
              <>
                <span className={classes.emphasis}>
                  {validateIsoDate(change.newValue) ? moment(change.newValue).format('DD-MM-YYYY') : change.newValue}
                </span>{' '}
                to {getActionLabel(change?.key)} in recommendation
              </>
            ) : (
              <>
                {getActionLabel(change?.key)} to recommendations{' '}
                <span className={classes.emphasis}>
                  {validateIsoDate(change.oldValue) ? moment(change.oldValue).format('DD-MM-YYYY') : change.oldValue}
                </span>{' '}
                to{' '}
                <span className={classes.emphasis}>
                  {validateIsoDate(change.newValue) ? moment(change.newValue).format('DD-MM-YYYY') : change.newValue}
                </span>{' '}
              </>
            )}
          </Box>
        );
      });
    }
    if (type === 'PROJECT_ACTIONS_UPDATED') {
      return changes?.map((change, index) => {
        const isNotes = change.key === 'notes';
        if (isNotes) {
          return (
            <Box key={index}>
              {!change?.oldValue ? (
                <>
                  <strong>{user.email}</strong> has added a note to action {action} in the {getActionLabel(change?.key)}{' '}
                  to project <span className={classes.emphasis}>{project?.name}</span>{' '}
                  <HTMLContent content={change.newValue} />
                </>
              ) : (
                <>
                  <strong>{user.email}</strong> has changed action {action} {getActionLabel(change?.key)}
                  {' for project '}
                  <span className={classes.emphasis}>{project?.name}</span> {' from:'}
                  <HTMLContent content={change.oldValue} />
                  {' to:'}
                  <HTMLContent content={change.newValue} />
                </>
              )}
            </Box>
          );
        }
        return (
          <Box key={index}>
            {!change?.oldValue ? (
              <>
                <strong>{user.email}</strong> has added{' '}
                <span className={classes.emphasis}>
                  {validateIsoDate(change?.newValue?.toString())
                    ? moment(change?.newValue).format('DD-MM-YYYY')
                    : change?.newValue}
                </span>{' '}
                to action {action} in the {getActionLabel(change?.key)} to project{' '}
                <span className={classes.emphasis}>{project?.name}</span>{' '}
              </>
            ) : (
              <>
                <strong>{user.email}</strong> has changed to action {action} {getActionLabel(change?.key)}{' '}
                <span className={classes.emphasis}>{change.oldValue}</span> to{' '}
                <span className={classes.emphasis}>{change.newValue}</span> for project{' '}
                <span className={classes.emphasis}>{project?.name}</span>{' '}
              </>
            )}
          </Box>
        );
      });
    }
    if (type === 'PROJECT_ATTACHMENT_ARCHIVED') {
      return (
        <>
          <strong>{user.email}</strong> has archived the file{' '}
          <span className={classes.emphasis}>{data?.file?.name}</span> on the project with name{' '}
          <Link to={`/project/${data?.project?._id}`}>
            <span className={classes.emphasis}>{data?.project?.name}</span>
          </Link>
          .
        </>
      );
    }

    if (type === 'PROJECT_ATTACHMENT_UNARCHIVED') {
      return (
        <>
          <strong>{user.email}</strong> has unarchived the file{' '}
          <span className={classes.emphasis}>{data?.file?.name}</span> on the project with name{' '}
          <Link to={`/project/${data?.project?._id}`}>
            <span className={classes.emphasis}>{data?.project?.name}</span>
          </Link>
          .
        </>
      );
    }
  };

  return (
    <>
      <TimeLineItem
        createdAt={createdAt}
        profileImg={user?.photo?.public_url}
        avatar={`${user?.email[0]?.toUpperCase() || 'US'} ${user?.email[1]?.toUpperCase() || ''} `}
      >
        {renderText()}
      </TimeLineItem>
      <CarouselModal
        open={open}
        setOpen={setOpen}
        filesSelected={filesSelected}
        setLoadingOpenFiles={setLoadingOpenFiles}
        loadingOpenFiles={loadingOpenFiles}
      />
    </>
  );
};

ProjectLog.propTypes = {
  data: PropTypes.shape({
    tags: PropTypes.array,
    file: PropTypes.object,
    comment: PropTypes.object,
    user: PropTypes.string.isRequired,
    project: PropTypes.string.isRequired,
    changes: PropTypes.array.isRequired,
    action: PropTypes.string.isRequired,
    newStatus: PropTypes.string,
    oldStatus: PropTypes.string,
    assignedUser: PropTypes.string,
    newSubType: PropTypes.string,
    oldSubType: PropTypes.string,
    newType: PropTypes.string,
    oldType: PropTypes.string,
    unassignedUser: PropTypes.string,
    project1: PropTypes.string,
    project2: PropTypes.string,
    note: PropTypes.string,
    projectTemplate: PropTypes.string,
    files: PropTypes.array,
    removedProject: PropTypes.bool,
    contact: PropTypes.object,
  }).isRequired,
  type: PropTypes.string.isRequired,
  createdAt: PropTypes.instanceOf(Date).isRequired,
};

export const PreviewImg = styled.div`
  height: 100px;
  position: relative;
  border-radius: 7px;
  overflow: hidden;
  margin-right: 10px;
  & div:hover {
    opacity: 1;
  }
`;

export default ProjectLog;
