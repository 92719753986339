import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import { CircularProgress, TextField } from '@material-ui/core';

export default function ConfirmDialog({ open, onConfirm, title, description, onClose, loading, reconfirmValue }) {
  const [confirmValue, setConfirmValue] = useState('');
  const [error, setError] = useState(false);

  const onConfirmClick = () => {
    if (reconfirmValue) {
      if (confirmValue === reconfirmValue) {
        setError(false);
        onConfirm();
      } else {
        setError(true);
      }
    } else {
      setError(false);
      onConfirm();
    }
  };
  return (
    <Dialog open={open} onClose={onClose} PaperComponent={Paper} aria-labelledby="draggable-dialog-title">
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
        {reconfirmValue && (
          <TextField
            error={error}
            helperText={error ? 'Incorrect value' : ''}
            autoFocus
            margin="dense"
            id="name"
            label={`To confirm deletion, please type "${reconfirmValue}"`}
            type="text"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            value={confirmValue}
            autoComplete="off"
            onChange={(e) => {
              //To maintain cursor position after changing value to uppercase
              const input = e.target;
              const cursorPosition = input.selectionStart;
              setConfirmValue(e.target.value.toUpperCase());
              //Reset cursor position to the same position after changing value to uppercase
              setTimeout(() => {
                input.setSelectionRange(cursorPosition, cursorPosition);
              }, 0);
            }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={onConfirmClick} color="primary" variant="contained">
          {loading ? <CircularProgress color="#fff" size="25px" /> : 'Ok'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
