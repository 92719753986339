/* eslint-disable no-nested-ternary */
import { Backdrop, Box, Button, Fade, makeStyles, Modal, TableCell, TableRow, Typography } from '@material-ui/core';
import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import PersonIcon from '@material-ui/icons/Person';
import DescriptionIcon from '@material-ui/icons/Description';
import moment from 'moment';
import { toast } from 'react-toastify';

import DashboardCard from '../../components/DashboardCard';
import iconYellow from '../../assets/yelloCard.png';
import { getAvailableFormType, getCivilianCases, getForms } from '../../services/unarmed';
import { Context } from '../../Context';
import useOrganization from '../../hooks/useOrganization';
import UpgradeModal from '../../components/UpgradeModal';
import EnhancedTable from '../../components/Table';
import formatTxt from '../../utils/formatText';
import { sortOrderForms, transformStatus } from '../../utils';
import DashButton from '../../components/DashButton';
import FormCard from '../../components/FormCard';
import AssignedToTooltip from '../../components/AssignedToTooltip';

const getStatusTitle = (row) => {
  const currentStatus = row?.availableStatuses?.filter((status) => status.status === row?.status);
  return transformStatus(currentStatus[0]?.status);
};

const columns = ['Type', 'Tracking Number', 'Created Date', 'Name', 'Assigned To', 'Status'];

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 20,
  },
  title: {
    marginTop: 50,
  },
  paper: {
    backgroundColor: '#fff',
    boxShadow: theme.shadows[5],
    padding: 30,
    maxWidth: 480,
    outline: 0,
    height: 545,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const getFormColor = (form) => {
  switch (form) {
    case 'COMPLIMENT':
      return '#008858';
    case 'COMPLAINT':
      return '#e52828';
    default:
      return '#364F74';
  }
};

const Home = () => {
  const classes = useStyles();
  const [dashboardData, setDashboardData] = useState([]);
  const { organization } = useOrganization();
  const { authUser, myUser, setActive } = useContext(Context);
  const [forms, setForms] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [open, setOpenModal] = useState(false);
  const [formTypes, setFormTypes] = useState([]);
  const [filteredCaseCount, setFilteredCaseCount] = useState(0);

  useEffect(() => {
    setActive('/');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const onGetForms = async () => {
      try {
        const { data } = await getForms();
        setDashboardData(data[0]);
      } catch (error) {
        toast.error(error.message);
      }
    };
    const onGetFormType = async () => {
      try {
        const { data } = await getAvailableFormType(organization?._id);
        setFormTypes(sortOrderForms(data));
      } catch (error) {
        toast.error(error.message);
      }
    };
    if (authUser && organization?._id) {
      onGetFormType();
      onGetForms();
    }
  }, [authUser, organization?._id]);

  const getFormsCivilians = async () => {
    try {
      setLoading(true);

      const { data, headers } = await getCivilianCases({
        token: authUser.tokenId,
        page: 1,
        limit: 5,
        sortBy: 'createdAt',
        sort: 'desc',
      });

      setForms({ data, headers });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (authUser) {
      getFormsCivilians();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser]);

  const goToCaseDetails = (e, row) => {
    history.push(`/case/${row.code}`);
  };

  const renderRow = (row, index, handleClick) => (
    <TableRow
      hover
      role="checkbox"
      tabIndex={-1}
      key={index}
      className={classes.row}
      style={{ cursor: 'pointer', height: 55 }}
    >
      <TableCell
        component="th"
        scope="row"
        align="left"
        onClick={(e) => goToCaseDetails(e, row, handleClick)}
        style={{
          color: row.type === 'COMPLAINT' ? '#FF4242' : row.type === 'COMPLIMENT' ? '#008858' : '#2E66FE',
          textTransform: 'capitalize',
          fontWeight: '500',
        }}
      >
        {row?.typeDisplayText}
      </TableCell>

      <TableCell align="left" onClick={(e) => goToCaseDetails(e, row, handleClick)}>
        {row.code}
      </TableCell>
      <TableCell component="th" scope="row" align="left" onClick={(e) => goToCaseDetails(e, row, handleClick)}>
        {moment(row.createdAt).format('MMMM DD, YYYY')}
      </TableCell>
      <TableCell
        align="left"
        style={{ textTransform: 'capitalize' }}
        onClick={(e) => goToCaseDetails(e, row, handleClick)}
      >
        {row.civilian?.firstName
          ? `${formatTxt(row.civilian?.firstName, 10)} ${formatTxt(row.civilian?.lastName, 10)}`
          : ''}
      </TableCell>

      <TableCell align="left" onClick={(e) => goToCaseDetails(e, row, handleClick)}>
        <AssignedToTooltip users={row.assigned?.filter((assigned) => !!assigned)} />
      </TableCell>
      <TableCell align="left" onClick={(e) => goToCaseDetails(e, row, handleClick)}>
        <p style={{ fontWeight: '500' }}>{getStatusTitle(row)}</p>
        <p style={{ fontSize: 12, color: '#585858' }}>
          {row?.statusLog && moment(row?.statusLog?.[row?.status]?.datetime).format('MMMM DD, hh:mm a')}
        </p>
      </TableCell>
    </TableRow>
  );

  return (
    <>
      {organization && (
        <UpgradeModal
          open={!organization?.features?.dashboard?.dashboard?.enabled}
          callback={() => history.push('/permissions')}
        />
      )}
      <PageWrapper
        className={classes.container}
        style={
          !organization?.features?.dashboard?.dashboard?.enabled
            ? {
                filter: 'blur(6px)',
                height: '70vh',
                overflow: 'hidden',
              }
            : {}
        }
      >
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h5">Dashboard </Typography>
        </Box>
        <Box display="flex" alignItems="center" mt="30px" flexWrap="wrap">
          <DashboardCard
            dataTestId="home-card-my-cases"
            cardText="My Cases"
            color="#2E66FE26"
            IconComponent={PersonIcon}
            iconColor="#2E66FE"
            value={dashboardData?.MyCases || 0}
            styleContainer={{ marginBottom: 20 }}
            buttonText="View all cases assigned to me"
            onClickButton={() => {
              history.push('/cases', {
                unassigned: myUser?._id,
                openFilter: true,
                isFiltering: true,
                resetAllFilters: true,
              });
              setActive('/cases');
            }}
          />
          <DashboardCard
            dataTestId="home-card-total-cases"
            cardText="Total Cases"
            color="#007FFF26"
            IconComponent={DescriptionIcon}
            iconColor="#007FFF"
            styleContainer={{ marginBottom: 20 }}
            value={dashboardData?.TotalCases || 0}
            buttonText="View all cases"
            onClickButton={() => {
              history.push('/cases', {
                openFilter: true,
                resetAllFilters: true,
                isFiltering: true,
              });
              setActive('/cases');
            }}
          />
          <DashboardCard
            dataTestId="home-card-unassigned-cases"
            cardText="Unassigned Cases"
            color="#FFF7D8"
            icon={iconYellow}
            value={dashboardData?.Unassigned || 0}
            buttonText="View all unassigned cases"
            styleContainer={{ marginBottom: 20 }}
            onClickButton={() => {
              history.push('/cases', {
                unassigned: 'null',
                openFilter: true,
                isFiltering: true,
                resetAllFilters: true,
              });
              setActive('/cases');
            }}
          />
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="flex-end">
          <Typography variant="subtitle2" id="tableTitle" component="div" style={{ marginTop: 15 }}>
            Recent Cases
          </Typography>
          <DashButton dataTestId="home-button-complete-form" onClick={() => setOpenModal(true)} marginRight="10px">
            Complete Form
          </DashButton>
        </Box>
        <Box marginBottom="0">
          <EnhancedTable
            disableHeaderArrows
            pagination={false}
            columns={columns}
            rows={forms.data}
            rowRender={renderRow}
            loading={loading}
            count={filteredCaseCount}
          />
        </Box>
      </PageWrapper>
      <Modal
        aria-labelledby="transition-modal-title2"
        aria-describedby="transition-modal-description2"
        className={classes.modal}
        open={open}
        onClose={() => setOpenModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Typography style={{ fontSize: 20, fontWeight: '500' }}>Select Form</Typography>
            <Typography style={{ fontSize: 14, marginBottom: 50 }}>
              Please select a form to fill from dashboard.
            </Typography>
            <Box display="flex" flexDirection="column" style={{ flexGrow: 1, height: 365, overflowY: 'auto' }}>
              {formTypes?.map((form) => (
                <FormCard
                  key={form._id}
                  colorBg={getFormColor(form.type)}
                  typeForm={form.type}
                  logEventTrack="make_compliment"
                  routeUrl={`/form-dashboard/${form._id}`}
                  name={form.name}
                  description={form.description}
                  pages={form.pages}
                />
              ))}
            </Box>
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              <Button style={{ color: '#666666' }} onClick={() => setOpenModal(false)}>
                CANCEL
              </Button>
            </Box>
          </div>
        </Fade>
      </Modal>
    </>
  );
};

const PageWrapper = styled.div`
  width: 100%;
`;

export default Home;
