import { Box } from '@material-ui/core';
import React from 'react';
import SelectEngine from '../../../components/Select';
import DndLogicItem from './DndLogicItem';

export default function Date({ onChange, setLogicItems, index, onRemoveItem, logic, options }) {
  return (
    <DndLogicItem title="Date" onRemove={onRemoveItem}>
      <Box display="flex" style={{ gap: 20 }} alignItems="center">
        <SelectEngine
          options={options?.formats?.map((format) => ({ value: format, text: format })) || []}
          onChange={(e) => {
            onChange(e, setLogicItems, index, 'format');
          }}
          value={logic?.format}
          width={200}
        />

        <SelectEngine
          options={options?.separators?.map((sp) => ({ value: sp.value, text: sp.label })) || []}
          onChange={(e) => {
            onChange(e, setLogicItems, index, 'separator');
          }}
          value={logic?.separator}
          width={200}
        />
      </Box>
    </DndLogicItem>
  );
}
