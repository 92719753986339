import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import police from '../../assets/blueBadge.png';
import GroupIcon from '@material-ui/icons/Group';

const useStyles = makeStyles(() => ({
  module: {
    width: 291,
    height: 72,
    border: '1px solid #7070701A',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 20,
    background: '#fff',
    cursor: 'pointer',
    marginTop: 20,
  },
  icon: {
    width: 24,
    height: 24,
    objectFit: 'cover',
  },
}));

const Module = ({ module, onClick, isSelected }) => {
  const classes = useStyles();
  const onRenderModule = () => {
    switch (module) {
      case 'Cases':
        return <InsertDriveFileIcon htmlColor="#FEB535" />;
      case 'Allegations':
        return <RecordVoiceOverIcon htmlColor="#009A6D" />;
      case 'Officers':
        return <img src={police} className={classes.icon} />;
      case 'Users':
        return <GroupIcon htmlColor="#CC2D4B" />;

      default:
        return null;
    }
  };

  return (
    <Box
      className={classes.module}
      onClick={onClick}
      style={{
        border: isSelected ? '1px solid #2E66FE' : '1px solid #7070701A',
      }}
    >
      {onRenderModule()}
      <Typography style={{ color: '#000000DE', fontSize: 18, marginLeft: 10 }}>
        {module}
      </Typography>
    </Box>
  );
};

export default Module;
