/**
 *
 * @description  this function is used to reorder the items inside the DnD area.
 */
export const reorder = (list, startIndex, endIndex) => {
  const result = [...Array.from(list)];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex > startIndex ? endIndex - 1 : endIndex, 0, removed); // inserting task in new index

  return [...result];
};

/**
 * @description  this function is used to reorder the items of items inside the DnD area.
 * @param {*} children
 * @param {*} splitDropZonePath
 * @param {*} splitItemPath
 * @returns
 */
export const reorderChildren = (children, splitDropZonePath, splitItemPath) => {
  if (splitDropZonePath.length === 1) {
    const dropZoneIndex = Number(splitDropZonePath[0]);
    const itemIndex = Number(splitItemPath[0]);
    return reorder(children, itemIndex, dropZoneIndex);
  }

  const updatedChildren = [...children];

  // const curIndex = Number(splitDropZonePath.slice(0, 1));
  const curIndex = 0;

  // Update the specific node's children
  const splitDropZoneChildrenPath = splitDropZonePath.slice(1);
  const splitItemChildrenPath = splitItemPath.slice(1);

  // current section
  const nodeChildren = updatedChildren[curIndex];

  // update section
  updatedChildren[curIndex] = {
    ...nodeChildren,
    components: reorderChildren(nodeChildren.components, splitDropZoneChildrenPath, splitItemChildrenPath),
  };

  return updatedChildren;
};

/**
 * @description this function is used to move items in the same parent.
 */
export const handleMoveWithinParent = (layout, splitDropZonePath, splitItemPath) =>
  reorderChildren(layout, splitDropZonePath, splitItemPath);

export const reorderOnDrop = (children, splitDropZonePath, items) => {
  const updatedChildren = [...children];

  items.forEach((item) => {
    const curIndex = Number(splitDropZonePath.slice(0, 1));

    // Update the specific node's children
    const splitItemChildrenPath = splitDropZonePath.slice(1);
    const nodeChildren = updatedChildren[curIndex];
    updatedChildren[curIndex] = {
      ...nodeChildren,
      children: reorderOnDrop(nodeChildren.children, splitItemChildrenPath, item),
    };

    return updatedChildren;
  });
};

/**
 * @description this function is used to move items from sidebar to section.
 */
export const handleMoveSidebarComponentIntoSection = (layout, splitDropZonePath, items) => {
  const newLayoutStructure = items;

  return reorderOnDrop(layout, splitDropZonePath, newLayoutStructure);
};

export const saveOnTimeOut = (callback, setLoading) => {
  setLoading(true);
  setTimeout(() => {
    callback();
    setLoading(false);
  }, 500);
};
