import React, { createContext, useState, useContext, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

/**
 * Context for managing accordion state.
 * @type {React.Context}
 */
const AccordionContext = createContext();

/**
 * Provider component for managing accordion state.
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - Child components to render within the provider.
 * @returns {JSX.Element} A React component.
 */
const AccordionProvider = ({ children }) => {
  /**
   * State to manage accordion state.
   * @type {Object}
   */
  const [accordions, setAccordions] = useState({});

  const setAllCollapse = useCallback(() => {
    const keys = Object.keys(accordions);
    const _accordions = { ...accordions };

    keys.forEach((key) => {
      _accordions[key] = false;
    });

    setAccordions(_accordions);
  }, [accordions]);

  /**
   * Memoized value to optimize performance.
   * @type {Object}
   */
  const value = useMemo(
    () => ({
      accordions,
      setAccordions,
      setAllCollapse,
    }),
    [accordions, setAllCollapse]
  );

  return <AccordionContext.Provider value={value}>{children}</AccordionContext.Provider>;
};

// Prop type definitions
AccordionProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

/**
 * Custom hook for accessing accordion state and actions.
 * @returns {Object} Accordions state and setAccordions function.
 */
export const useAccordionProvider = () => {
  /**
   * Context to access accordion state and actions.
   * @type {React.Context}
   */
  const { accordions, setAccordions, setAllCollapse } = useContext(AccordionContext);

  return { accordions, setAccordions, setAllCollapse };
};

export default AccordionProvider;
