import React, { useState } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import PropTypes from 'prop-types';

import PopperPortal from './PopperPortal';
import schemaIcon from '../assets/schema.png';
import ReportSearchInput, { onFilterCubes } from './ReportSearchInput';

/**
 * Styles for the ReportFilterItem component.
 */
const useStyles = makeStyles({
  item: {
    height: 45,
    background: '#fff',
    borderRadius: 4,
    border: '1px solid #7070701F',
    padding: '15px 16px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
  },
  label: {
    fontSize: 14,
    color: '#00000061',
  },

  seletedItem: {
    color: '#000000DE',
    fontSize: 14,
    fontWeight: '400',
  },
  img: {
    width: 16,
    height: 16,
    marginRight: 5,
  },
  hoverItem: {
    cursor: 'pointer',
    borderRadius: 4,
    '& :hover': {
      transition: 'ease .3s all',
      background: '#f2f2f2',
    },
  },
});

const getFilterItems = (items) => {
  const filterItems = items.map(({ dimensions, measures, title }) => ({
    filterOptions: [dimensions, measures].flat(),
    title,
  }));
  return filterItems;
};

export const searchFilterItem = (filterItem, items) => {
  if (!filterItem) return null;

  const allItems = getFilterItems(items).map((item) => item.filterOptions.map((currentItem) => currentItem));

  const findedItem = allItems.flat().find((item) => item.name === filterItem);
  return findedItem;
};

/**
 * Component representing a filter item for a report.
 * @param {object} props - Component props.
 * @param {string} props.label - Label for the filter item.
 * @param {array} props.cubes - Array of cube objects.
 * @param {function} props.setItems - Function to set items.
 * @param {object} props.value - Currently selected value.
 * @param {array} props.operators - Array of operators.
 * @param {function} props.onResetFilter - Function to reset filter.
 * @param {boolean} props.showRemove - Flag to show remove icon.
 * @param {boolean} props.withSearchInput - Flag to enable search input.
 * @returns {JSX.Element} React component.
 */
export default function ReportFilterItem({
  label,
  cubes = [],
  setItems,
  value,
  operators = [],
  onResetFilter = () => {},
  showRemove = true,
  withSearchInput = true,
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');

  const handleClick = (item) => {
    setItems(item);
    setOpen(false);
  };

  const handleRemove = () => {
    onResetFilter();
  };

  const cubesFiltered = getFilterItems(onFilterCubes(search, cubes));
  const checkedEmptyCubes = cubesFiltered.filter((cube) => cube.filterOptions.length > 0);

  return (
    <Box display="flex" alignItems="center" marginBottom="10px" position="relative">
      <Box className={classes.item} onClick={() => setOpen(true)}>
        <Box display="flex" alignItems="center" width="90%">
          {open && withSearchInput ? (
            <ReportSearchInput search={search} setSearch={setSearch} />
          ) : (
            <>
              {value ? (
                <>
                  <Typography className={classes.seletedItem}>{value.title || value.label}</Typography>
                </>
              ) : (
                <Typography className={classes.label}>{`${label}`}</Typography>
              )}
            </>
          )}
        </Box>
      </Box>
      {value && showRemove && (
        <CancelIcon
          fontSize="small"
          htmlColor="#6D7385"
          style={{
            position: 'absolute',
            right: 15,
            zIndex: 8,
            cursor: 'pointer',
          }}
          onClick={handleRemove}
        />
      )}
      <PopperPortal open={open} setOpen={setOpen} left={0}>
        <Box
          width={333}
          height={checkedEmptyCubes.length > 0 ? 300 : 200}
          style={{ background: '#fff', zIndex: 99, overflowY: 'auto' }}
        >
          {withSearchInput ? (
            <>
              {checkedEmptyCubes.length > 0 ? (
                <>
                  {cubesFiltered.map((filters, index) =>
                    filters?.filterOptions?.length > 0 ? (
                      <Box padding="7px 12px" key={index}>
                        <Box display="flex" alignItems="center">
                          <img className={classes.img} src={schemaIcon} alt="filter icon" />
                          <Typography style={{ fontSize: 14 }}>{filters?.title}</Typography>
                        </Box>

                        {filters.filterOptions?.map((item, idx) => (
                          <Box className={classes.hoverItem} key={idx} onClick={() => handleClick(item)}>
                            <Box display="flex" alignItems="center" padding="19px 20px">
                              <Typography style={{ fontSize: 14 }}>{item?.title || item?.shortTitle}</Typography>
                            </Box>
                          </Box>
                        ))}
                      </Box>
                    ) : null
                  )}
                </>
              ) : (
                <Box display="flex" justifyContent="center" alignItems="center" marginTop={1} width="100%" height="90%">
                  <Typography>No results</Typography>
                </Box>
              )}
            </>
          ) : (
            <>
              {operators?.map((operator, index) => (
                <Box padding="7px 12px" key={index}>
                  <Box className={classes.hoverItem} onClick={() => handleClick(operator)}>
                    <Box display="flex" alignItems="center" padding="19px 20px">
                      <Typography style={{ fontSize: 14 }}>{operator.label}</Typography>
                    </Box>
                  </Box>
                </Box>
              ))}
            </>
          )}
        </Box>
      </PopperPortal>
    </Box>
  );
}

// PropTypes for type checking
ReportFilterItem.propTypes = {
  label: PropTypes.string.isRequired,
  cubes: PropTypes.array.isRequired,
  setItems: PropTypes.func.isRequired,
  value: PropTypes.object,
  operators: PropTypes.array,
  onResetFilter: PropTypes.func,
  showRemove: PropTypes.bool,
  withSearchInput: PropTypes.bool,
};
