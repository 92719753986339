import React, { useEffect, useRef, useState } from 'react';
import { Box, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';
import ModularComponents from '../../components/ModularComponents';
import { getDashboardReports, deleteDashboardReport } from '../../services/unarmed';

export default function DashboardReports({ dashboardId, refreshTrigger }) {
  const [dashboardReports, setDashboardReports] = useState([]);
  const [actionRefresh, setActionrefresh] = useState(false);
  const componentRef = useRef(null);

  useEffect(() => {
    const fetchDashboardReports = async () => {
      try {
        const dashboardReportsR = await getDashboardReports(dashboardId);
        const transformedReports = dashboardReportsR.data.reports.map((report) => ({
          ...report.size,
          i: report.dashboardReportId,
          key: report.dashboardReportId,
          content: (
            <div
              style={{
                position: 'relative',
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
              }}
              className="report-container"
            >
              <div
                style={{
                  position: 'relative',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  backgroundColor: 'rgba(255,255,255,0.7)',
                  padding: '4px 8px',
                  zIndex: 1,
                }}
              >
                <div
                  style={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                  }}
                >
                  {report.name}
                </div>

                <IconButton
                  className="no-drag close-button"
                  style={{
                    backgroundColor: 'rgba(255,255,255,0.7)',
                    position: 'absolute',
                    top: '4px',
                    right: '4px',
                    zIndex: 2,
                  }}
                  onClick={() => handleDeleteReport(report.dashboardReportId)}
                  size="small"
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flex: 1,
                  width: '100%',
                  overflow: 'hidden',
                  position: 'relative',
                }}
              >
                {report?.reportImage ? (
                  <img
                    src={report?.reportImage}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                    }}
                  />
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      backgroundColor: '#f0f4ff',
                      padding: '10px 15px',
                      borderLeft: '4px solid #3b82f6',
                      borderRadius: '4px',
                      boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
                      width: '100%',
                    }}
                  >
                    <InfoIcon style={{ color: '#3b82f6', marginRight: '10px' }} />
                    <span
                      style={{
                        fontSize: '14px',
                        color: '#333',
                      }}
                    >
                      Please save this report in Explore in order to view it on your Dashboard.
                    </span>
                  </div>
                )}
              </div>
            </div>
          ),
        }));
        setDashboardReports(transformedReports);
      } catch (error) {
        console.error('Error fetching dashboard reports:', error);
      }
    };

    fetchDashboardReports();
  }, [dashboardId, actionRefresh, refreshTrigger]);

  const handleDeleteReport = async (dashboardReportId) => {
    try {
      await deleteDashboardReport({ ids: [dashboardReportId] });

      dashboardReports.filter((report) => report.i !== dashboardReportId);
      setActionrefresh(!actionRefresh);
    } catch (error) {
      console.error('Error deleting the report:', error);
    }
  };

  const componentWidth = componentRef.current ? componentRef.current.offsetWidth : 0;

  return (
    <Box>
      <div ref={componentRef}>
        {dashboardReports.length > 0 ? (
          <ModularComponents layout={dashboardReports} width={componentWidth} />
        ) : (
          <p>No reports available</p>
        )}
      </div>
    </Box>
  );
}
