import { Box } from '@material-ui/core';
import React from 'react';
import SelectEngine from '../../../components/Select';
import DndLogicItem from './DndLogicItem';

export default function Number({ onChange, setLogicItems, index, onRemoveItem, logic }) {
  return (
    <DndLogicItem title="Number" onRemove={onRemoveItem}>
      <Box display="flex" style={{ gap: 20 }} alignItems="center">
        <SelectEngine
          options={[
            { text: 'Consecutive number', value: 'consecutive_number' },
            { text: 'Random number', value: 'random_number' },
          ]}
          onChange={(e) => {
            onChange(e, setLogicItems, index, 'value');
          }}
          value={logic?.value}
          width={200}
        />
        <SelectEngine
          options={[
            { text: '1', value: 1 },
            { text: '2', value: 2 },
            { text: '3', value: 3 },
            { text: '4', value: 4 },
            { text: '5', value: 5 },
            { text: '6', value: 6 },
            { text: '7', value: 7 },
            { text: '8', value: 8 },
            { text: '9', value: 9 },
            { text: '10', value: 10 },
          ]}
          onChange={(e) => {
            onChange(e, setLogicItems, index, 'length');
          }}
          value={logic?.length}
          width={200}
        />
      </Box>
    </DndLogicItem>
  );
}
