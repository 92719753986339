import { Box, Typography } from '@material-ui/core';
import React from 'react';

import DeleteIcon from '@material-ui/icons/Delete';

export default function DndLogicItem({ title, children, onRemove, onMouseLeave }) {
  return (
    <Box
      padding="15px 10px"
      borderRadius={4}
      border="1px solid #f2f2f2"
      bgcolor="#fff"
      marginBottom={2}
      width="100%"
      onMouseLeave={onMouseLeave}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center" style={{ gap: 10 }}>
          <Typography variant="body1" style={{ fontSize: 14, fontWeight: '600' }}>
            {title}
          </Typography>
        </Box>
        <DeleteIcon htmlColor="rgba(109, 115, 133)" onClick={onRemove} style={{ cursor: 'pointer' }} />
      </Box>

      <Box marginTop={2} marginLeft={4}>
        {children}
      </Box>
    </Box>
  );
}
