import React, { useState } from 'react';
import styled from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';

/**
 * Represents a tag component.
 * @param {string} text - The text content of the tag.
 * @param {string} [bg='#F2F8FF'] - The background color of the tag.
 * @param {string} [color='#408BEC'] - The text color of the tag.
 * @param {boolean} [canRemove=false] - Whether the tag can be removed.
 * @param {function} [onClickRemove] - The callback function when the remove button is clicked.
 * @param {function} [onClick] - The callback function when the tag is clicked.
 * @returns {JSX.Element} The tag component.
 */
const Tag = ({ text, bg = '#F2F8FF', color = '#408BEC', canRemove = false, onClickRemove, onClick }) => {
  const [onHover, setOnHover] = useState(false);

  return (
    <Container
      bg={bg}
      color={color}
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => setOnHover(false)}
      onClick={onClick}
      clickable={!!onClick}
    >
      {text}
      {onHover && canRemove && (
        <Close bg={bg} onClick={onClickRemove}>
          <CloseIcon style={{ fontSize: 12 }} />
        </Close>
      )}
    </Container>
  );
};

Tag.propTypes = {
  onClick: PropTypes.func.isRequired,
  canRemove: PropTypes.bool,
  text: PropTypes.string.isRequired,
  bg: PropTypes.string,
  color: PropTypes.string,
  onClickRemove: PropTypes.func.isRequired,
};

const Container = styled.span`
  height: 24px;
  background-color: ${(props) => props.bg};
  color: ${(props) => props.color};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  padding: 2px 8px;
  border-radius: 4px;
  margin-right: 4px;
  margin-bottom: 8px;
  position: relative;

  ${(props) =>
    props.clickable
      ? `
    cursor: pointer;
  `
      : ''}
`;

const Close = styled.span`
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: ${(props) => props.bg};
  border-radius: 4px;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 0px 4px;
  cursor: pointer;
`;

export default Tag;
