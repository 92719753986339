import { Box, CircularProgress } from '@material-ui/core';
import React, { useEffect, useState, useRef } from 'react';
import { Waypoint } from 'react-waypoint';

import { getActivity } from '../../../services/unarmed';
import ProjectLog from '../components/ProjectLog';
import useNearScreen from '../../../hooks/userNearScreen';
import { BoxLeftContainer } from '../../../styles/GlobalStyles';
import Card from '../../../components/Card';

export default function History({ id }) {
  const [loading, setLoading] = useState(false);
  const [log, setLog] = useState([]);

  const [lastId, setLastId] = useState('');
  const [hasNextPage, setHasNextPage] = useState(true);

  const externalRef = useRef();

  const { isNearScreen } = useNearScreen({
    externalRef: loading === 'pending' ? null : externalRef,
    once: false,
  });

  const onGetHistory = async () => {
    try {
      setLoading(true);
      const data = await getActivity({ project: id, limit: 15, before: lastId });
      if (data.length === 0) {
        setLoading(false);
        setHasNextPage(false);
        return;
      }
      setLastId(data[data.length - 1]?._id);
      setLog((items) => [...items, ...data]);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      onGetHistory();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <BoxLeftContainer>
      <Card title="History">
        <Box
          style={{ background: '#fff', borderRadius: 5 }}
          boxShadow="0 3px 6px #00000005"
          width="100%"
          maxWidth="800px"
          height="fit-content"
          padding="10px"
        >
          {log.length === 0 && loading && (
            <Box height="50px" justifyContent="center" alignItems="center" display="flex">
              <CircularProgress color="#364F74" size="25px" />
            </Box>
          )}
          {log?.map((logItem, i) => (
            <ProjectLog {...logItem} key={i} />
          ))}
        </Box>
        <div id="bottom" ref={externalRef} />
        {isNearScreen && hasNextPage && lastId && (
          <Waypoint onEnter={onGetHistory}>
            <Box display="flex" alignItems="center" justifyContent="center" maxWidth="800px" style={{ marginTop: 24 }}>
              <CircularProgress color="#333" size="30px" style={{ marginLeft: 10 }} />
            </Box>
          </Waypoint>
        )}
      </Card>
    </BoxLeftContainer>
  );
}
