import { Box } from '@material-ui/core';
import React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import { OutLineInputBox } from '../../styles/GlobalStyles';

export default function TypeItem({
  value,
  onChange,
  placeholder,
  onClick,
  marginBottom,
}) {
  return (
    <Box
      display="flex"
      alignItems="center"
      width="100%"
      marginBottom={marginBottom}
    >
      <OutLineInputBox
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        type="text"
        bordered
      />
      <DeleteIcon
        htmlColor="#6D7385"
        onClick={onClick}
        style={{ marginLeft: 15, cursor: 'pointer' }}
      />
    </Box>
  );
}
