import { Box, CircularProgress, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import useNearScreen from '../../hooks/userNearScreen';
import { getNotification } from '../../services/unarmed';
import { onGetDateLabels } from '../Activity/Activity';
import TimeLine from '../Activity/TImeLine';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 20,
    width: '100%',
  },
  title: {
    marginTop: 50,
  },
  label: {
    fontSize: 12,
    color: '#aaa',
  },
  button: {
    letterSpacing: '1px',
    fontSize: 13,
    background: '#2E66FE',
    fontWeight: '400',
    width: 'fit-content',
    alignSelf: 'flex-end',
    color: '#fff',
    '&:hover': {
      background: '#4b7bff',
    },
    '& .MuiButton-label': {
      fontSize: 13,
    },
  },

  buttonOutlined: {
    letterSpacing: '1px',
    fontSize: 13,
    background: '#fff',
    fontWeight: '500',
    marginRight: 10,
    width: 'fit-content',
    alignSelf: 'flex-end',
    border: '1px solid #4b7bff ',
    color: '#4b7bff',
    '&:hover': {
      background: '#fff',
      border: '1px solid #4b7bff ',
    },
    '& .MuiButton-label': {
      fontSize: 13,
    },
  },

  img: {
    width: 120,
    height: 120,
    borderRadius: '50%',
    objectFit: 'cover',
  },

  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: '#F9FAFF',
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: 20,
  },
}));

export default function Notifications() {
  const classes = useStyles();
  const [lastId, setLastId] = useState('');
  const [items, setItems] = useState({});
  const [hasNextPage, setHasNextPage] = useState(true);

  const [loading, setLoading] = useState('idle');
  const externalRef = useRef();
  const { isNearScreen } = useNearScreen({
    externalRef: loading === 'pending' ? null : externalRef,
    once: false,
  });

  useEffect(() => {
    setLoading('pending');
    getNotification({ limit: 10 })
      .then(({ data }) => {
        const dataFormattedByDate = {};
        data.forEach((itm) => {
          onGetDateLabels(itm, dataFormattedByDate);
        });
        setItems(dataFormattedByDate);

        setLastId(data[data.length - 1]?._id);
        setLoading('resolved');
      })
      .catch((err) => {
        setLoading('rejected');
      });
  }, []);

  const getMoreActivities = () => {
    setLoading('pending');
    getNotification({
      limit: 15,
      before: lastId,
    })
      .then(({ data }) => {
        if (data.length === 0) {
          setHasNextPage(false);
          return;
        }
        const dataFormattedByDate = {};
        data.forEach((itm) => {
          onGetDateLabels(itm, dataFormattedByDate);
        });
        console.log(lastId, dataFormattedByDate);
        if (typeof lastId === 'string' && lastId !== data[data.length - 1]?._id) {
          const dataKeys = Object.keys(dataFormattedByDate);
          const itemsCopy = { ...items };
          dataKeys.forEach((dataKey) => {
            if (itemsCopy[dataKey]) {
              itemsCopy[dataKey] = [...itemsCopy[dataKey], ...dataFormattedByDate[dataKey]];
            } else {
              itemsCopy[dataKey] = dataFormattedByDate[dataKey];
            }
          });
          setLastId(data[data.length - 1]?._id);
          // setItems((prevState) => [...prevState, ...res]);
          setItems(itemsCopy);
        } else {
          setLastId(false);
        }
        setLoading('resolved');
      })
      .catch((err) => {
        setLoading('rejected');
      });
  };

  return (
    <Box className={classes.container}>
      <h1>Notifications</h1>
      <Box display="flex" flexDirection="column" justifyContent="space-between" marginBottom="0" marginTop="10px">
        {Object?.keys(items)?.map((item, index) => (
          <TimeLine setLastId={setLastId} lastId={lastId} items={items[item]} key={index} title={item} />
        ))}
        <div id="bottom" ref={externalRef} />
        {isNearScreen && hasNextPage && lastId && (
          <Waypoint onEnter={getMoreActivities}>
            <Box display="flex" alignItems="center" justifyContent="center" style={{ marginTop: 24 }}>
              {loading === 'pending' && <CircularProgress color="#333" size="30px" style={{ marginLeft: 10 }} />}
            </Box>
          </Waypoint>
        )}
      </Box>
    </Box>
  );
}
