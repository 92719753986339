import { Box, Button, CircularProgress, Grid, Input, makeStyles, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import MultiSelectBox from '../../components/MultiSelectBox';
import useInput from '../../hooks/useInput';
import { assignOfficer, createOfficer } from '../../services/unarmed';
import { genderOptions, raceEthnicityOptions } from '../../utils/options';
import { TextMaskCustom } from '../Officers/OfficersTable';
import { OutLineInput } from './UpdateCase';
import Card from '../../components/Card';

const useStyles = makeStyles(() => ({
  formSection: {
    fontSize: 14,
    color: '#3f51b5',
    fontWeight: '500',
  },
  resaltText: {
    color: '#3B70FE',
    fontWeight: 600,
    fontSize: 13,
  },
  label: {
    fontWeight: '500',
    fontSize: 14,
    margin: '10px 0 10px',
  },
  button: {
    background: ' #3B70FE',
    textTransform: 'capitalize',
    color: '#fff',
    '&:hover': {
      background: '#3B70FE',
    },
    '& .MuiButton-label': {
      fontSize: 14,
    },
    marginTop: 10,
    marginBottom: 20,
  },
  labelError: {
    fontSize: 12,
    color: 'red',
  },
}));

const NewOfficer = ({ id, setIsRefetch, setIsRequestingData, caseArchived }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const badge = useInput('', isSubmitting);
  const firstName = useInput('', isSubmitting);
  const lastName = useInput('', isSubmitting);
  const email = useInput('');
  const phone = useInput('');
  const [gender, setGender] = useState([]);
  const [race, setRace] = useState([]);

  const onCreateOfficer = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setIsRequestingData(true);
    if (!firstName.value || !lastName.value || !badge.value) {
      return;
    }

    // const emailRgx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    // if (!emailRgx.test(email.value)) {
    //   email.setError('Please use a valid email!');
    //   return;
    // }

    // if (phone.value.trim().length !== 14) {
    //   phone.setError('Please enter a valid phone number');
    //   return;
    // }

    try {
      setLoading(true);
      const { data } = await createOfficer(
        badge.value,
        firstName.value,
        lastName.value,
        email.value,
        phone.value,
        gender,
        race
      );

      toast.success(`Officer created Successfully`);
      await assignOfficer(id, data?._id);
      toast.success(`Officer assigned Successfully`);

      setIsRefetch();
      setIsSubmitting(false);
      setLoading(false);
      badge.setValue('');
      firstName.setValue('');
      lastName.setValue('');
      email.setValue('');
      phone.setValue('');
      setGender([]);
      setRace([]);
      setIsRequestingData(false);
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.error?.details[0]?.message);
      toast.error(error?.response?.data?.message);
      if (error?.response?.data?.error?.details[0]?.message.includes('already exists')) {
        badge.setError(`An Officer with the Badge Number: ${badge.value} Already exist.`);
      }
    }
  };
  return (
    <Card title="New Officer">
      <form noValidate autoComplete="off" className={classes.form} onSubmit={onCreateOfficer}>
        <Typography variant="body2" className={classes.label} style={{ marginTop: 0 }}>
          Badge/Serial Number <span style={{ fontSize: 12, color: 'red' }}>*</span>
        </Typography>
        <OutLineInput
          data-testid="case-details-new-officer-input-badge-number"
          placeholder="Enter Officer Badge Number"
          value={badge.value}
          onChange={badge.onChange}
          error={badge.error}
          aria-describedby="outlined-weight-helper-text"
          inputProps={{
            'aria-label': 'weight',
          }}
          labelWidth={0}
        />
        <span className={classes.labelError}>{badge.error}</span>
        <Grid container spacing={2} style={{ marginTop: 10 }}>
          <Grid item xs={6}>
            <Typography variant="body2" className={classes.label}>
              Officer First Name
              <span style={{ fontSize: 12, color: 'red' }}>*</span>
            </Typography>
            <OutLineInput
              data-testid="case-details-new-officer-input-first-name"
              placeholder="Enter Officer First Name"
              value={firstName.value}
              onChange={firstName.onChange}
              error={firstName.error}
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                'aria-label': 'weight',
              }}
              labelWidth={0}
            />
            <span className={classes.labelError}>{firstName.error}</span>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" className={classes.label}>
              Officer Last Name
              <span style={{ fontSize: 12, color: 'red' }}>*</span>
            </Typography>
            <OutLineInput
              data-testid="case-details-new-officer-input-last-name"
              placeholder="Enter Officer Last Name"
              value={lastName.value}
              onChange={lastName.onChange}
              error={lastName.error}
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                'aria-label': 'weight',
              }}
              labelWidth={0}
            />
            <span className={classes.labelError}>{lastName.error}</span>
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginTop: 10 }}>
          <Grid item xs={6}>
            <Typography variant="body2" className={classes.label}>
              Officer Email Address
            </Typography>
            <OutLineInput
              data-testid="case-details-new-officer-input-email"
              placeholder="Enter Officer Email Address"
              value={email.value}
              onChange={email.onChange}
              error={email.error}
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                'aria-label': 'weight',
              }}
              labelWidth={0}
            />
            <span className={classes.labelError}>{email.error}</span>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" className={classes.label}>
              Officer Phone Number
            </Typography>
            {/* <OutLineInput
                placeholder="Enter Officer Phone Number"
                value={phone.value}
                onChange={(e) => {
                  if (e.target.value.length === 0) {
                    phone.setValue('');
                  }
                  if (e.target.value.match(/[0-9]$/g)) {
                    phone.onChange(e);
                  }
                }}
                inputMode="tel"
                error={phone.error}
                aria-describedby="outlined-weight-helper-text"
                maxLength={10}
                labelWidth={0}
              /> */}
            <Box style={{ display: 'flex', alignItems: 'flex-end' }}>
              <FillInputPhone
                value={phone.value}
                onChange={phone.onChange}
                autoComplete="off"
                name="textmask"
                style={{ width: '100%' }}
                error={phone.error}
                id="formatted-text-mask-input"
                inputComponent={TextMaskCustom}
                inputProps={{
                  'data-testid': 'case-details-new-officer-input-phone',
                }}
              />
            </Box>

            <span className={classes.labelError}>{phone.error}</span>
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginTop: 10 }}>
          <Grid item xs={6}>
            <Typography variant="body2" className={classes.label}>
              Gender
            </Typography>
            <MultiSelectBox
              dataTestId="case-details-new-officer-select-gender"
              value={gender}
              onChange={(e) => setGender(e.target.value)}
              id="discliplineMutliSelct1"
              options={genderOptions || []}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" className={classes.label}>
              Race/Ethnicity
            </Typography>
            <MultiSelectBox
              dataTestId="case-details-new-officer-select-race-ethnicity"
              value={race}
              onChange={(e) => setRace(e.target.value)}
              id="discliplineMutliSelct1"
              options={raceEthnicityOptions || []}
            />
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="flex-end" mt="20px">
          <Button
            data-testid="case-details-new-officer-button-submit"
            variant="contained"
            className={classes.button}
            type="submbit"
            disabled={caseArchived || loading}
          >
            {loading ? <CircularProgress color="#fff" size="25px" /> : 'Create Officer'}
          </Button>
        </Box>
      </form>
    </Card>
  );
};

export const FillInputPhone = styled(Input)`
  background-color: #f8f8f9;
  height: 35px;
  font-size: 13px !important;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 0;
  outline: 0;
  ${(props) =>
    props.bordered &&
    `
    border: 1px solid rgba(0, 0, 0, 0.23);
    background: transparent;
  `}
  ::before,
  ::after {
    border-bottom: 0 !important;
  }
`;

export default NewOfficer;
