import { Box, Button, CircularProgress, makeStyles, Typography } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Editor } from '@tinymce/tinymce-react';
import PropTypes from 'prop-types';

import DashButton from '../../../components/DashButton';
import DatePickerMUI from '../../../components/DatePickerMUi';
import { deleteRecommendation, updateRecommendation } from '../../../services/unarmed';
import OutlineSelect from './OutlineSelect';
import { TINYMCE_DEFAULT_CONFIG, TINYMCE_SCRIPT_SRC } from '../../../config/config';
import HTMLContent from '../../../components/HTMLContent';

const useStyles = makeStyles(() => ({
  button: {
    color: 'rgb(46, 102, 254)',
    textTransform: 'capitalize',
  },
  separator: {
    color: '#ccc',
    margin: '0 10px',
  },
  label: {
    fontSize: 14,
    color: '#000000AD',
  },
  labelDescription: {
    fontWeight: '500',
    fontSize: 14,
    margin: '10px 0 10px',
  },
  cancelButton: {
    marginRight: 20,
    color: '#D50000',
  },
}));

const statusOPtions = ['ACCEPTED', 'REJECTED'];

const capitalizeStatus = (status) => {
  if (status) {
    const firstCap = status.charAt(0).toUpperCase();
    return `${firstCap}${status.slice(1)}`;
  }

  return status;
};

/**
 * Renders a recommendation item.
 * @param {Object} props - The props of the component.
 * @param {string} props.id - The ID of the recommendation.
 * @param {Function} props.toggleEditRecommendation - Function to toggle editing mode.
 * @param {boolean} props.edit - Flag indicating whether in edit mode.
 * @param {string} props.description - The description of the recommendation.
 * @param {string} props.status - The status of the recommendation.
 * @param {string} props.notes - The notes of the recommendation.
 * @param {string} props.projectId - The ID of the project.
 * @param {Function} props.refetch - Function to refetch data.
 * @param {Date|null} props.responseDate - The response date of the recommendation.
 * @param {Date|null} props.submittedDate - The submitted date of the recommendation.
 */
export default function RecommendationItem({
  id,
  toggleEditRecommendation,
  edit,
  description,
  status,
  notes,
  projectId,
  refetch,
  responseDate = null,
  submittedDate = null,
}) {
  const classes = useStyles();
  const { register, handleSubmit, control, setValue, watch } = useForm();
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [submittedDateIn, setSubmittedDate] = useState(null);
  const [responseDateIn, setResponseDate] = useState(null);

  useEffect(() => {
    setValue('description', description);
    setValue('notes', notes);
    setValue('status', capitalizeStatus(status));
    setSubmittedDate(submittedDate);
    setResponseDate(responseDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [description, status]);

  const onDelete = async () => {
    try {
      setLoadingDelete(true);
      await deleteRecommendation(projectId, id);
      toast.success('Deleted recommendation successfully');
      refetch();
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoadingDelete(false);
    }
  };

  const saveRecommendation = async (data) => {
    try {
      setLoading(true);

      await updateRecommendation(projectId, id, {
        description: data.description,
        notes: data.notes,
        ...(submittedDateIn ? { submittedDate: submittedDateIn } : { submittedDate: null }),
        ...(responseDateIn ? { responseDate: responseDateIn } : { responseDate: null }),

        ...(data.status === 'Select an Option' ? {} : { status: data?.status?.toUpperCase() }),
      });
      toggleEditRecommendation();
      refetch();
      toast.success('Recommendation updated successfully');
    } catch (error) {
      toast.error(error.response?.data?.error?.details[0]?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      padding="15px 0"
      boxSizing="border-box"
      borderBottom="1px solid #e2e2e2"
    >
      {!edit ? (
        <>
          <Box display="flex" flexDirection="column" style={{ gap: 20 }} width="100%">
            <Box>
              <Typography className={classes.label}>Description</Typography>
              <HTMLContent content={description} />
            </Box>
            <Box>
              <Typography className={classes.label}>Status</Typography>
              <Typography style={{ textTransform: 'capitalize' }}>{status}</Typography>
            </Box>
            <Box>
              <Typography className={classes.label}>Submitted Date</Typography>
              <Typography style={{ textTransform: 'capitalize' }}>
                {submittedDate && moment(submittedDate).format('MM-DD-YYYY')}
              </Typography>
            </Box>
            <Box>
              <Typography className={classes.label}>Response Date</Typography>
              <Typography style={{ textTransform: 'capitalize' }}>
                {responseDate && moment(responseDate).format('MM-DD-YYYY')}
              </Typography>
            </Box>
            <Box>
              <Typography className={classes.label}>Notes</Typography>
              <HTMLContent content={notes} />
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <Button className={classes.button} onClick={toggleEditRecommendation}>
              Edit
            </Button>
            <span className={classes.separator}>|</span>
            <Button className={classes.button} onClick={onDelete} disabled={loadingDelete}>
              {loadingDelete ? <CircularProgress color="#fff" size="25px" /> : 'Delete'}
            </Button>
          </Box>
        </>
      ) : (
        <Box width="100%">
          <Typography variant="body2" className={classes.labelDescription}>
            Description:
          </Typography>
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <Editor
                tinymceScriptSrc={TINYMCE_SCRIPT_SRC}
                init={{
                  ...TINYMCE_DEFAULT_CONFIG,
                  placeholder: 'Add a description',
                  height: 200,
                }}
                value={field.value}
                onEditorChange={(newValue) => {
                  field.onChange({ target: { value: newValue } });
                }}
              />
            )}
          />
          <Box display="flex" marginTop={3} style={{ gap: 20 }}>
            <Box width={200} marginTop="28px">
              <OutlineSelect
                label="Status"
                register={register}
                registerKey="status"
                options={statusOPtions}
                required={false}
                hasValue={watch('status') !== 'Select an Option' || watch('status')}
              />
            </Box>
            <Box className={classes.featureItem} style={{ width: 190 }}>
              <Typography variant="body2" className={classes.labelDescription}>
                Submitted Date:
              </Typography>
              <DatePickerMUI
                propWidth={180}
                propPadding={10}
                label="Submitted Date"
                inputVariant="standard"
                date={submittedDateIn}
                setdate={setSubmittedDate}
              />
            </Box>
            <Box className={classes.featureItem} style={{ width: 190 }}>
              <Typography variant="body2" className={classes.labelDescription}>
                Response Date:
              </Typography>
              <DatePickerMUI
                propWidth={180}
                propPadding={10}
                inputVariant="standard"
                label="Response Date"
                date={responseDateIn}
                setdate={setResponseDate}
              />
            </Box>
          </Box>
          <Typography variant="body2" className={classes.labelDescription}>
            Notes:
          </Typography>
          <Controller
            name="notes"
            control={control}
            render={({ field }) => (
              <Editor
                tinymceScriptSrc={TINYMCE_SCRIPT_SRC}
                init={{
                  ...TINYMCE_DEFAULT_CONFIG,
                  placeholder: 'Add a note',
                  height: 200,
                }}
                value={field.value}
                onEditorChange={(newValue) => {
                  field.onChange({ target: { value: newValue } });
                }}
              />
            )}
          />
          <Box display="flex" alignItems="center" justifyContent="flex-end" marginTop={2}>
            <Button className={classes.cancelButton} onClick={toggleEditRecommendation}>
              Cancel
            </Button>
            <DashButton onClick={handleSubmit(saveRecommendation)} disabled={loading}>
              {loading ? <CircularProgress color="#fff" size="25px" /> : 'Save'}
            </DashButton>
          </Box>
        </Box>
      )}
    </Box>
  );
}

RecommendationItem.propTypes = {
  id: PropTypes.string.isRequired,
  toggleEditRecommendation: PropTypes.func.isRequired,
  edit: PropTypes.bool.isRequired,
  description: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  notes: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired,
  responseDate: PropTypes.instanceOf(Date),
  submittedDate: PropTypes.instanceOf(Date),
};
