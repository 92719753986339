import { Box } from '@material-ui/core';
import React, { useMemo } from 'react';
import { getConfig } from '../config/Config';
import { Renderer } from '../config/Renderer';
import WrapperComponent from './WrapperComponent';
import Settings from '../utils/Settings';

export default function Demographics({
  id,
  onDeleteComponent,
  componentId,
  path,
  data,
  components,
  refActionModal,
  setModal,
  onUpdateJson,
  onUpdateJsonTop,
  type,
}) {
  const config = useMemo(
    () =>
      getConfig({
        componentsFromServer: [
          components[0],
          components[2],
          components[4],
          components[3],
          components[1],
          components[5],
        ],
        onUpdateJson,
        isSubComponent: true,
        shouldRenderToggle: false,
      }),
    [components, onUpdateJson]
  );

  const renderSettings = () => (
    <Settings
      componentId={componentId}
      componentType={type}
      defaultDataReportLink={components?.[0]?.data?.dataReportLink}
      onSelectDataReportLink={(dataReportLink) => onUpdateJsonTop({ ...data, dataReportLink })}
    />
  );

  return (
    <WrapperComponent
      onDeleteComponent={onDeleteComponent}
      id={id}
      path={path}
      renderPreview={() => {}}
      renderSettings={renderSettings}
      containsQuestionBanks
      withDataReportLinkSettings
      refActionModal={refActionModal}
      setModal={setModal}
    >
      <Box padding="20px 0">
        <Renderer config={config} path={path} />
      </Box>
    </WrapperComponent>
  );
}
