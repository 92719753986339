import { useState, useEffect, useCallback } from 'react';
import useMe from './useMe';
import {
  getAllegationsFiltersConfig,
  getContactsFiltersConfig,
  getfiltersConfigById,
  getFormsFiltersConfig,
  getOfficersFiltersConfig,
  getUsersFiltersConfig,
} from '../services/unarmed';

const useFiltersConfig = (type, excludeCount = [], filterConfigId) => {
  const [filtersConfig, setFiltersConfig] = useState();
  const [filtersLoaded, setFiltersLoaded] = useState(false);
  const [filterCount, setFilterCount] = useState(0);
  const [filtersApplied, setFiltersApplied] = useState({});

  const { me } = useMe();

  const handleOnSetFiltersConfig = useCallback(
    ({ data }) => {
      if (me) {
        const filterConfig = data.find((item) => item.user?._id === me._id);
        const newFiltersApplied = {};

        if (filterConfig) {
          const configCopy = { ...filterConfig.config };

          delete configCopy.sortOrder;
          delete configCopy.sortBy;
          delete configCopy.page;
          delete configCopy.limit;

          Object.keys(configCopy).forEach((key) => {
            if (excludeCount.indexOf(key) !== -1) {
              delete configCopy[key];
            } else {
              const value = configCopy[key];
              let isApplied = false;

              isApplied = value !== null;

              if (typeof value === 'boolean') {
                isApplied = value === true;
              }

              newFiltersApplied[key] = isApplied;
            }
          });

          const newCount = Object.values(newFiltersApplied).filter((item) => item).length;

          setFilterCount(newCount);
          setFiltersConfig(filterConfig);
          setFiltersApplied(newFiltersApplied);
        } else {
          setFiltersConfig(null);
        }
      }
    },
    [me]
  );

  const handleOnFetchFiltersConfig = useCallback(() => {
    if (filterConfigId) {
      getfiltersConfigById(filterConfigId).then(handleOnSetFiltersConfig);
    }
  }, [filterConfigId, handleOnSetFiltersConfig]);

  const resetFilterCount = () => setFilterCount(0);

  useEffect(() => {
    if (me) {
      handleOnFetchFiltersConfig();
    }
  }, [type, me, filterConfigId]);

  return {
    filtersConfig,
    filterCount,
    filtersApplied,
    filtersLoaded,
    resetFilterCount,
    setFiltersLoaded,
    fetchFiltersConfig: handleOnFetchFiltersConfig,
  };
};

export default useFiltersConfig;
