import React, { useState, useContext, useCallback, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Box, Typography, Grid, Paper } from '@material-ui/core';

import DashButton from '../../components/DashButton';
import FormTaskBoxEdit from './components/FormTaskBoxEdit';
import { getUsers, onSaveFormType } from '../../services/unarmed';
import DnDArea from '../../components/DnDArea';
import DnDItem from '../../components/DnDItem';
import Alert from '../../components/Alert';
import { axiosCatchError } from '../../utils';
import { Context } from '../../Context';

/**
 * FormTasks
 *
 * Draggable list of tasks for the form type.
 *
 * @param {number} id form ID
 * @param {object} formInfo form information
 * @param {Function} setFormInfo function for set form information
 */
const FormTasks = ({ id, formInfo, setFormInfo, isDefaultForm }) => {
  const { authUser } = useContext(Context);

  const [tasks, setTasks] = useState([]);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const onGetUsers = async () => {
      try {
        const { data } = await getUsers({});

        setUsers(data);
      } catch (error) {
        toast.error(error.message);
      }
    };

    if (authUser) {
      onGetUsers();
    }
  }, [authUser]);

  /**
   * @description function to handle on save tasks.
   */
  const handleOnSave = useCallback(async () => {
    const tasksData = [
      ...tasks.map((task) => ({
        _id: task?._id,
        name: task.name,
        values: {
          type: task.values.type,
          subType: task.values.subType,
          dueDays: task.values.dueDays || 0,
          assignedTo: task.values.assignedTo.map((user) => user?._id || user) || [],
        },
      })),
    ];

    try {
      const res = await onSaveFormType(
        {
          tasks: tasksData,
        },
        id
      );

      if (res?.data?.tasks) {
        setTasks(res?.data?.tasks);
        setFormInfo((info) => ({
          ...info,
          tasks: res?.data?.tasks,
        }));
      }

      toast.success('Form Tasks Successfully updated!');
    } catch (error) {
      axiosCatchError(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasks, id]);

  /**
   * @description function to handle add task.
   */
  const handleAddTask = () => {
    setTasks((tasks) => [...tasks, { name: '' }]);
  };

  /**
   * @description function for render the tasks inside de DnD Provider.
   */
  const renderer = useMemo(
    () => (moveItem) =>
      tasks.map((task, taskIndex) => (
        <DnDItem key={taskIndex} moveItem={moveItem} index={taskIndex}>
          <FormTaskBoxEdit
            componentIndex={taskIndex}
            key={taskIndex}
            task={task}
            users={users}
            onClickDuplicate={() => {
              setTasks((tasks) => [...tasks, { ...task }]);
            }}
            onChange={(index, data) => {
              const newTasks = [...tasks];
              const taskData = { ...newTasks[index] };

              newTasks[index] = {
                ...taskData,
                ...data,
                values: {
                  ...taskData.values,
                  ...data.values,
                },
              };

              setTasks(newTasks);
            }}
            onClickDelete={(index) => {
              setTasks((tasks) => tasks.filter((task, taskIndex) => taskIndex !== index));
            }}
          />
        </DnDItem>
      )),
    [tasks, users]
  );

  /**
   * @description useEffect for set tasks from form information.
   */
  useEffect(() => {
    if (formInfo) {
      setTasks(formInfo?.tasks || []);
    }
  }, [formInfo]);

  return (
    <Grid container>
      <Grid item xs={12} md={10} lg={8} xl={6}>
        <Box sx={{ p: 4, width: '100%' }}>
          <Grid container alignItems="center">
            <Grid item xs={10}>
              <Typography variant="h5" sx={{ fontSize: 24 }}>
                Tasks
              </Typography>
              <Typography variant="subtitle1" sx={{ fontSize: 14, marginTop: 8 }}>
                Task listed here will appear on the task tab inside the case profile
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Grid container item justifyContent="flex-end">
                <DashButton onClick={handleOnSave} disabled={isDefaultForm}>
                  Save
                </DashButton>
              </Grid>
            </Grid>
          </Grid>
          {isDefaultForm && (
            <Alert status="info">
              <Typography style={{ fontSize: 14 }}>
                Default forms cannot be deleted or updated. Duplicate this form to edit.
              </Typography>
            </Alert>
          )}
          <Paper style={{ padding: 24, minHeight: '50vh', marginTop: 16 }}>
            <DndProvider backend={HTML5Backend}>
              <DnDArea selectedItems={tasks} setSelectedItems={setTasks} renderer={renderer} />
            </DndProvider>
            <Grid container item xs={12} justifyContent="center" style={{ marginTop: 8 }}>
              <DashButton onClick={handleAddTask} style={{ width: 160 }} disabled={isDefaultForm}>
                ADD TASK
              </DashButton>
            </Grid>
          </Paper>
        </Box>
      </Grid>
    </Grid>
  );
};

export default FormTasks;
