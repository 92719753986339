import React, { useCallback } from 'react';
import update from 'immutability-helper';
import { Box } from '@material-ui/core';

const DnDArea = ({ selectedItems = [], setSelectedItems, renderer }) => {
  const moveItem = useCallback(
    (dragIndex, hoverIndex) => {
      const dragItem = selectedItems[dragIndex];
      setSelectedItems(
        update(selectedItems, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragItem],
          ],
        })
      );
    },
    [selectedItems, setSelectedItems]
  );

  return <Box width="100%">{renderer(moveItem)}</Box>;
};

export default DnDArea;
