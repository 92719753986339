import React, { useState, useContext, useCallback, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';

import SignupForm from './SignupForm';
import { Context } from '../../../Context';
import { getInvitiation } from '../../../services/unarmed';
import InvitationConflict from '../../InvitationConflict';

const useStyles = makeStyles(() => ({
  signup: {
    display: 'flex',
    width: '100%',
    height: '116.1vh',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#FAFBFF',
  },
}));

const Signup = () => {
  const [showInvitationConflict, setShowInvitationConflict] = useState(false);
  const [invitation, setInvitation] = useState(null);
  const [loadingInvitation, setLoadingInvitation] = useState(true);
  const [invitationNotFound, setInvitationNotFound] = useState(false);

  const location = useLocation();
  const { setSignUpUser, removeAuthToLogin } = useContext(Context);

  const classes = useStyles();

  const onGetInvitedUser = useCallback(async () => {
    try {
      const { data } = await getInvitiation(location.search);
      const token = localStorage.getItem('authToken');

      setInvitation(data);
      setLoadingInvitation(false);

      if (data && token) {
        setShowInvitationConflict(true);
      } else {
        setSignUpUser(data);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
      setLoadingInvitation(false);
      setShowInvitationConflict(true);

      if (error?.response?.status === 404) {
        setInvitationNotFound(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const handleOnClickLogout = () => {
    removeAuthToLogin('');
    setSignUpUser(invitation);
    setShowInvitationConflict(false);
  };

  useEffect(() => {
    if (location.search) {
      onGetInvitedUser();
    } else {
      setLoadingInvitation(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  if (loadingInvitation) return null;

  if (showInvitationConflict) {
    if (invitationNotFound) {
      return (
        <InvitationConflict
          title="Looks like that the invitation was already used or it doesn't exist"
          text="Please use a different invitation link or request a new one"
        />
      );
    }

    return <InvitationConflict onClickLogout={handleOnClickLogout} />;
  }

  return (
    <div className={classes.signup}>
      <SignupForm invitation={invitation} />
    </div>
  );
};

export default Signup;
