import {
  Box,
  Button,
  CircularProgress,
  Divider,
  makeStyles,
  Typography,
  Switch,
  FormControlLabel,
} from '@material-ui/core';
import React, { useState } from 'react';
import moment from 'moment';
import DatePickerMUI from '../../components/DatePickerMUi';
import SelectBox from '../../components/SelectBox';
import { getAllegations, getFormOptionsType, getOfficerOptions, updateFilterConfigById } from '../../services/unarmed';
import { AllegationOptions, DisciplineOptions, DispositionOptions, OutLineInput } from '../CaseDetails/UpdateCase';
import { CustomSwitch } from '../../formEngine/components/WrapperComponent';
import { activeColors } from '../../components/CaseFilters';

const useStyles = makeStyles(() => ({
  button: {
    background: ' #1F63FF',
    textTransform: 'capitalize',
    color: '#fff',
    '&:hover': {
      background: '#1F63FF',
    },
    '& .MuiButton-label': {
      fontSize: 13,
    },
  },
}));

const AllegationFilter = ({
  onClick,
  allegation,
  trackingNumber,
  badge,
  officerName,
  status,
  disposition,
  discipline,
  from,
  to,
  setFrom,
  setTo,
  setOpen,
  type,
  setAllegations,
  setPage,
  rowsPerPage,
  sortBy,
  sort,
  setIsFiltering,
  onClearFilters,
  openShowAdvancedFilters,
  filtersConfig,
  setfiltersConfig,
  filterConfigId,
  fetchFiltersConfig,
  filtersApplied,
  flaggedOnly,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [showAdvancedFilter, setShowAdvancedFilers] = useState(openShowAdvancedFilters || false);
  const [officerOptions, setOfficerOptions] = useState([]);
  const [formOptions, setFormOptions] = useState([]);

  const getOfficersOptions = async () => {
    const { data } = await getOfficerOptions();
    setOfficerOptions(data);
  };

  const getFormOptionsTypes = async () => {
    const { data } = await getFormOptionsType();
    setFormOptions(data);
    console.log(data);
  };

  React.useEffect(() => {
    getOfficersOptions();
    getFormOptionsTypes();
  }, []);

  const onFilter = async () => {
    try {
      setIsFiltering(true);
      setLoading(true);
      setPage(1);
      const { data, headers } = await getAllegations({
        page: 1,
        limit: rowsPerPage,
        from: from ? moment(from).format('YYYY-MM-DD') : null,
        to: to ? moment(to).format('YYYY-MM-DD') : null,
        sort,
        sortBy,
        isFiltering: true,
        badge: badge.value,
        officerName: officerName.value,
        status: status.value,
        disposition: disposition.value,
        allegation: allegation.value,
        discipline: discipline.value,
        trackingNumber: trackingNumber.value,
        flaggedOnly: flaggedOnly.value,
        type: type.value,
      });
      // fetchFiltersConfig();
      const filterSaved = {
        from: from ? moment(from).format('YYYY-MM-DD') : null,
        to: to ? moment(to).format('YYYY-MM-DD') : null,
        sort,
        sortBy,
        badge: badge.value,
        officerName: officerName.value,
        status: status.value,
        disposition: disposition.value,
        allegation: allegation.value,
        discipline: discipline.value,
        trackingNumber: trackingNumber.value,
        type: type.value,
        flaggedOnly: flaggedOnly.value,
      };
      setfiltersConfig({ ...filtersConfig, ...filterSaved });

      await updateFilterConfigById(filterConfigId, filterSaved);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      console.log(error);
    }
  };

  const handleClearAllFilters = async () => {
    onClearFilters();
    try {
      setIsFiltering(true);
      setLoading(true);
      const filterSaved = {
        from: from ? moment(from).format('YYYY-MM-DD') : null,
        to: to ? moment(to).format('YYYY-MM-DD') : null,
        sort,
        sortBy,
        badge: '',
        officerName: '',
        status: '',
        disposition: '',
        allegation: '',
        discipline: '',
        trackingNumber: '',
        type: '',
        flaggedOnly: false,
      };
      setfiltersConfig({ ...filtersConfig, ...filterSaved });
      await updateFilterConfigById(filterConfigId, filterSaved);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      console.log(error);
    }
  };

  return (
    <Box
      boxShadow="0 4px 8px rgba(0,0,0,.1)"
      style={{ background: '#fff' }}
      padding="10px 20px"
      borderRadius="5px"
      marginTop="20px"
    >
      <Box display="flex" flexWrap="wrap">
        <Box minWidth="100px" maxWidth="170px" width="100%" marginRight="20px" marginTop="10px">
          <Typography style={{ display: 'block', fontSize: 13, fontWeight: 500 }}>Allegation</Typography>
          <SelectBox
            dataTestId="allegations-filter-select-allegation"
            id="type"
            options={officerOptions?.allegations || AllegationOptions}
            value={allegation.value || null}
            onChange={allegation.onChange}
            outlined
            withColors={filtersApplied.allegation ? activeColors : null}
          />
        </Box>
        <Box minWidth="100px" maxWidth="170px" width="100%" marginRight="20px" marginTop="10px">
          <Typography style={{ display: 'block', fontSize: 13, fontWeight: 500 }}>Case Type</Typography>
          <SelectBox
            id="type"
            dataTestId="allegations-filter-select-case-type"
            options={formOptions?.types?.map((type) => type.toLowerCase()) || []}
            value={type.value || null}
            onChange={type.onChange}
            outlined
            withColors={filtersApplied.type ? activeColors : null}
          />
        </Box>

        <Box minWidth="100px" maxWidth="200px" width="100%" marginRight="20px" marginTop="10px">
          <Typography
            style={{
              display: 'block',
              fontSize: 13,
              fontWeight: 500,
              marginBottom: 5,
            }}
          >
            Tracking Number
          </Typography>
          <OutLineInput
            bordered
            active={filtersApplied.code}
            placeholder="Tracking Number"
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              'aria-label': 'weight',
              'data-testid': 'allegations-filter-input-tracking-number',
            }}
            value={trackingNumber.value || ''}
            onChange={trackingNumber.onChange}
            labelWidth={0}
          />
        </Box>

        <Box width="350px" marginRight="20px" marginTop="10px">
          <Typography
            style={{
              display: 'block',
              fontSize: 13,
              fontWeight: 500,
              marginBottom: 5,
            }}
          >
            Created Date
          </Typography>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <DatePickerMUI
              active={filtersApplied.startDate}
              dataTestId="allegations-filter-datepicker-from"
              disableFuture
              label="From"
              propWidth="65%"
              date={from}
              compareDate={to}
              setdate={setFrom}
            />
            <span style={{ width: 15 }} />
            <DatePickerMUI
              active={filtersApplied.endDate}
              dataTestId="allegations-filter-datepicker-to"
              disableFuture
              label="To"
              propWidth="65%"
              date={to}
              compareDate={from}
              type="end"
              setdate={setTo}
            />
          </Box>
        </Box>
        <Box minWidth="100px" maxWidth="200px" width="100%" marginRight="20px" marginTop="10px">
          <Typography
            style={{
              display: 'block',
              fontSize: 13,
              fontWeight: 500,
              marginBottom: 5,
            }}
          >
            Badge/Serial Number
          </Typography>
          <OutLineInput
            bordered
            active={filtersApplied.badgeNumber}
            placeholder="Badge/Serial Number"
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              'aria-label': 'weight',
              'data-testid': 'allegations-filter-input-badge-serial-number',
            }}
            value={badge.value || ''}
            onChange={badge.onChange}
            labelWidth={0}
          />
        </Box>
      </Box>

      {showAdvancedFilter && (
        <Box width="100%" display="flex">
          <Box minWidth="100px" maxWidth="200px" width="100%" marginRight="20px" marginTop="10px">
            <Typography
              style={{
                display: 'block',
                fontSize: 13,
                fontWeight: 500,
                marginBottom: 5,
              }}
            >
              Officer Name
            </Typography>
            <OutLineInput
              bordered
              active={filtersApplied.name}
              placeholder="Officer Name"
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                'aria-label': 'weight',
                'data-testid': 'allegations-filter-input-officer-name',
              }}
              value={officerName.value || ''}
              onChange={officerName.onChange}
              labelWidth={0}
            />
          </Box>
          <Box minWidth="100px" maxWidth="200px" width="100%" marginRight="20px" marginTop="10px">
            <Typography style={{ display: 'block', fontSize: 13, fontWeight: 500 }}>Status</Typography>
            <SelectBox
              id="type"
              dataTestId="allegations-filter-select-status"
              options={officerOptions?.statuses || []}
              value={status.value || null}
              onChange={status.onChange}
              outlined
              withColors={filtersApplied.status ? activeColors : null}
            />
          </Box>
          <Box minWidth="100px" maxWidth="170px" width="100%" marginRight="20px" marginTop="10px">
            <Typography style={{ display: 'block', fontSize: 13, fontWeight: 500 }}>Disposition</Typography>
            <SelectBox
              id="type"
              dataTestId="allegations-filter-select-disposition"
              options={officerOptions?.dispositions || DispositionOptions}
              value={disposition.value || null}
              onChange={disposition.onChange}
              outlined
              withColors={filtersApplied.disposition ? activeColors : null}
            />
          </Box>
          <Box minWidth="100px" maxWidth="170px" width="100%" marginRight="20px" marginTop="10px">
            <Typography style={{ display: 'block', fontSize: 13, fontWeight: 500 }}>Discipline</Typography>
            <SelectBox
              id="type"
              dataTestId="allegations-filter-select-discipline"
              options={officerOptions?.disciplines || DisciplineOptions}
              value={discipline.value || null}
              onChange={discipline.onChange}
              outlined
              withColors={filtersApplied.discipline ? activeColors : null}
            />
          </Box>
          <Box minWidth="170px" maxWidth="180px" width="100%" display="flex" alignItems="center" marginTop="10px">
            <FormControlLabel
              style={{ margin: 0 }}
              control={<CustomSwitch checked={flaggedOnly.value} onChange={flaggedOnly.onChange} />}
              label="Flagged only"
              labelPlacement="start"
            />
          </Box>
        </Box>
      )}

      <Divider style={{ display: 'block', width: '100%', margin: '15px 0' }} />
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex">
          <Typography
            style={{
              fontSize: 13,
              fontWeight: '500',
              cursor: 'pointer',
              color: '#1F63FF',
            }}
            data-testid="allegations-filter-button-advance-filters"
            onClick={() => setShowAdvancedFilers(!showAdvancedFilter)}
          >
            Advance Filters
          </Typography>
          <Typography style={{ fontSize: 13, fontWeight: '500', margin: '0 10px' }}>|</Typography>
          <Typography
            style={{
              fontSize: 13,
              fontWeight: '500',
              cursor: 'pointer',
              color: '#1F63FF',
            }}
            data-testid="allegations-filter-button-clear-all-filters"
            onClick={handleClearAllFilters}
          >
            Clear All Filters{' '}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Button
            data-testid="allegations-filter-button-cancel"
            onClick={() => setOpen(false)}
            style={{ textTransform: 'capitalize' }}
          >
            Cancel
          </Button>
          <Button data-testid="allegations-filter-button-apply" className={classes.button} onClick={onFilter}>
            {loading ? <CircularProgress color="#fff" size="25px" /> : 'Apply'}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default AllegationFilter;
