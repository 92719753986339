import React, { useContext, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import DataUsageIcon from '@material-ui/icons/DataUsage';
import SubjectIcon from '@material-ui/icons/Subject';
import PersonIcon from '@material-ui/icons/Person';
import NotificationsIcon from '@material-ui/icons/Notifications';
import StorageIcon from '@material-ui/icons/Storage';
import { Avatar, Badge, Box, CircularProgress, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import SearchIcon from '@material-ui/icons/Search';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import SettingsIcon from '@material-ui/icons/Settings';
import HistoryIcon from '@material-ui/icons/History';
import SecurityIcon from '@material-ui/icons/Security';
import PeopleIcon from '@material-ui/icons/People';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import { Equalizer } from '@material-ui/icons';
import { OutLineInput } from '../pages/CaseDetails/UpdateCase';
import SearchedDropdown from './SearchedDropdown';
import useInput from '../hooks/useInput';
import { onSearchGeneral } from '../services/unarmed';
import oversignWhite from '../assets/oversignWhite.png';
import oversignBlack from '../assets/oversignBlack.png';
import { Context } from '../Context';
import { logEvent } from '../services/firebase';
import useNotifications from '../hooks/useNotifications';
import { getDocumentTitle } from '../utils';

const drawerWidth = 240;

const colors = {
  dev: {
    bg: '#364F74',
    text: '#fff',
    opacity: '#FFFFFF33',
    logo: oversignWhite,
  },
  test: {
    bg: '#D5D9DC ',
    text: '#000',
    opacity: 'rgba(0, 0, 0, .25)',
    logo: oversignBlack,
  },
  prod: {
    bg: '#364F74',
    text: '#fff',
    opacity: '#FFFFFF33',
    logo: oversignWhite,
  },
};

const getEnvColors = () => {
  if (process.env.REACT_APP_DEVELOPMENT_ENV && colors[process.env.REACT_APP_DEVELOPMENT_ENV]) {
    return colors[process.env.REACT_APP_DEVELOPMENT_ENV];
  }

  return {
    bg: '#364F74',
    text: '#fff',
    opacity: '#FFFFFF33',
    logo: oversignWhite,
  };
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24,
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: `calc(100% - 72px)`,
    background: '#fff',
    boxShadow: '0 1px 0px rgba(0,0,0, .2)',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxShadow: '0 1px 0px rgba(0,0,0, .2)',
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    background: getEnvColors().bg,
    width: drawerWidth,
    minHeight: '111vh',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  content: {
    flexGrow: 1,
    minHeight: '116.1vh',
    overflow: 'auto',
    background: '#F9FAFD',
  },
  container: {
    width: '100%',
    height: 'calc(116.1vh - 64px)',
  },
  logoOrganization: {
    width: '100%',
    maxHeight: '70px',
    objectFit: 'contain',
    padding: 10,
    objectPosition: 'center',
  },
}));

const validateRoutes = () => {
  for (const rute of ['/case-exported', '/mfa']) {
    if (window.location.pathname.includes(rute)) {
      return true;
    }
  }

  return false;
};

export default function Layout({ children, allowScroll = true }) {
  const { removeAuth, myUser, active, setActive, organization } = useContext(Context);
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [openSearch, setOpenSearch] = useState(false);
  const search = useInput('');
  const [searchResult, setSearchResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const anchorRef = useRef(null);
  const [openSettings, setOpenSettings] = useState(false);
  const searchTimeout = useRef(null);
  const { notificationCount, getNotificationsCount, resetNotificationsCount } = useNotifications();

  const [keys, setKeys] = useState([]);
  const [permissions, setPermissions] = useState(null);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (organization) {
      const pageTitle = getDocumentTitle(organization.name);
      document.title = `Sivil | ${pageTitle}`;

      logEvent('page_view', {
        page_title: pageTitle,
        page_location: window.location.href,
        page_path: window.location.pathname,
      });
    }
  }, [organization, location]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (organization && myUser) {
      getNotificationsCount();
      setKeys(() => {
        const keysCopy = [...(organization && myUser && Object.keys(organization?.permissions?.[myUser?.role]))];

        const keysSort = [
          'dashboard',
          'cases',
          'contacts',
          'allegations',
          'officers',
          'projects',
          'data',
          'insights',
          'users',
          'forms',
          'usage',
          'import',
          'activity',
        ];

        return keysCopy.sort((x, y) => keysSort.indexOf(x) - keysSort.indexOf(y));
      });
      const permissionsData = organization && myUser && organization?.permissions?.[myUser?.role];
      setPermissions(permissionsData);
    }
  }, [organization, myUser]);

  const onRenderModuleIcon = (module) => {
    const color = getEnvColors().text;

    const featureStatus = {
      insights: organization?.features?.dashboard?.insights?.enabled,
      data: organization?.features?.dashboard?.data?.enabled,
    };

    const icons = {
      dashboard: <DashboardIcon htmlColor={color} />,
      cases: <InsertDriveFileIcon htmlColor={color} />,
      allegations: <RecordVoiceOverIcon htmlColor={color} />,
      users: <PeopleIcon htmlColor={color} />,
      activity: <HistoryIcon htmlColor={color} />,
      officers: <SecurityIcon htmlColor={color} />,
      import: <SaveAltIcon htmlColor={color} />,
      data: featureStatus.data ? <DataUsageIcon htmlColor={color} /> : null,
      insights: featureStatus.insights ? <Equalizer htmlColor={color} /> : null,
      forms: <SubjectIcon htmlColor={color} />,
      projects: <StorageIcon htmlColor={color} />,
      contacts: <PersonIcon htmlColor={color} />,
    };

    return icons[module] || null;
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const onSearch = (e) => {
    const { value } = e.target;
    search.setValue(value);
    if (!value) {
      setSearchResult(null);
      return;
    }
    setLoading(true);
    clearTimeout(searchTimeout.current);
    searchTimeout.current = setTimeout(async () => {
      try {
        const { data } = await onSearchGeneral(encodeURIComponent(value));

        const results = {};

        for (const arr of data) {
          for (const category of arr.matchingFields) {
            if (!results[category.key.split('.')[0]]) {
              results[category.key.split('.')[0]] = {
                category: category.key.split('.')[0].toUpperCase(),
                search: [`${arr.code};${category.key.replace('.', ' ')}:;${category.value}`],
              };
            } else {
              results[category.key.split('.')[0]].search.push(
                `${arr.code};${category.key.replace('.', ' ')}:;${
                  typeof category.value === 'object' ? category.value?.name : category.value
                }`
              );
            }
          }
        }
        setSearchResult(results);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }, 500);
  };

  if (validateRoutes()) {
    return <>{children}</>;
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <Box display="flex" alignItems="center" position="relative" flex="1">
            <SearchIcon htmlColor="#000000ad" />

            <OutLineInput
              style={{
                background: 'transparent',
                width: 320,
                color: '#333',
              }}
              onClick={() => setOpenSearch(true)}
              value={search.value}
              onChange={onSearch}
              placeholder="Search cases"
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                'aria-label': 'weight',
              }}
              labelWidth={0}
            />
            {loading && <CircularProgress size="20px" />}
            {openSearch && search.value ? (
              <>
                <Box
                  onClick={() => setOpenSearch(false)}
                  position="absolute"
                  width="calc(100vw + 14%)"
                  height="116.1vh"
                  style={{ top: -20, left: -282, zIndex: 1 }}
                />
                <SearchedDropdown loading={loading} searchResult={searchResult} onClose={() => setOpenSearch(false)} />
              </>
            ) : null}
          </Box>
          <Box justifyContent="flex-end" display="flex" alignItems="center" position="relative">
            <IconButton
              color="inherit"
              style={{ marginRight: 10 }}
              onClick={() => {
                resetNotificationsCount();
                history.push('/notifications');
              }}
            >
              <Badge badgeContent={notificationCount} color="secondary">
                <NotificationsIcon htmlColor="#6D7385" />
              </Badge>
            </IconButton>
            {myUser && myUser.role === 'admin' && (
              <>
                <SettingsIcon
                  htmlColor="#6D7385"
                  style={{ marginRight: 30, cursor: 'pointer' }}
                  ef={anchorRef}
                  aria-controls={openSettings ? 'menu-list-grow' : undefined}
                  aria-haspopup="true"
                  onClick={() => {
                    setActive('/settings');
                    history.push('/settings');
                  }}
                />
              </>
            )}
            <Box
              justifyContent="flex-end"
              display="flex"
              alignItems="center"
              onClick={() => {
                history.push('/profile');
                setActive('/profile');
              }}
              style={{ cursor: 'pointer' }}
            >
              {myUser?.photo ? (
                <Avatar src={myUser?.photo?.url} style={{ marginRight: 10 }} />
              ) : (
                <AccountCircleIcon htmlColor="#364F74" fontSize="large" style={{ marginRight: 10 }} />
              )}
              <Typography variant="body2" color="inherit" noWrap className={classes.title} style={{ color: ' #333333' }}>
                {myUser?.firstName} {myUser?.lastName}
              </Typography>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <Box display="flex" alignItems="center" justifyContent="center" marginTop="30px">
          <img src={getEnvColors().logo} alt="404" className={classes.logoOrganization} />
        </Box>

        <List style={{ flex: 1, marginTop: 30 }}>
          {keys
            ?.filter((t) => !t.includes('usage' || 'permissions'))
            .filter((module) => {
              if (permissions[module] !== 0) {
                return module;
              }
            })
            .map((module, i) => {
              const icon = onRenderModuleIcon(module);
              if (!icon) return null;

              if (module === 'dashboard') {
                return (
                  <ListItem
                    key={i}
                    button
                    onClick={() => {
                      setActive('/');
                      history.push('/');
                      logEvent('/dashboard_clicked');
                      getNotificationsCount();
                    }}
                    selected={active === '/'}
                  >
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText primary="Dashboard" style={{ color: getEnvColors().text }} />
                  </ListItem>
                );
              }

              if (['usage', 'activity', 'import', 'users'].includes(module) && myUser?.role !== 'admin') {
                return <> </>;
              }

              return (
                <ListItem
                  key={i}
                  button
                  onClick={() => {
                    setActive(`/${module}`);
                    history.push(`/${module}`);
                    logEvent(`${module}_clicked`);
                    getNotificationsCount();
                  }}
                  selected={location.pathname.includes(module)}
                >
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText primary={module} style={{ color: getEnvColors().text, textTransform: 'capitalize' }} />
                </ListItem>
              );
            })}
        </List>
        <Divider style={{ background: getEnvColors().opacity }} />
        <Box>
          <Box display="flex" alignItems="center" justifyContent="left" style={{ cursor: 'pointer' }}>
            <Box display="flex" padding="10px 20px" onClick={() => removeAuth()}>
              <ExitToAppIcon htmlColor={getEnvColors().text} />
              {open && (
                <>
                  <Typography
                    style={{
                      marginRight: 10,
                      color: getEnvColors().text,
                      marginLeft: 10,
                    }}
                  >
                    Logout
                  </Typography>
                </>
              )}
            </Box>
          </Box>
        </Box>
        {open ? (
          <div className={classes.toolbarIcon}>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon htmlColor={getEnvColors().text} />
            </IconButton>
          </div>
        ) : (
          <IconButton onClick={handleDrawerOpen}>
            <ChevronRightIcon htmlColor={getEnvColors().text} />
          </IconButton>
        )}
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} style={{ height: 64 }} />
        <Box className={classes.container} style={allowScroll ? { overflowY: 'auto' } : { overflowY: 'unset' }}>
          {children}
        </Box>
      </main>
    </div>
  );
}
