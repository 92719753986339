import React from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import formatTxt from '../utils/formatText';

const CardWrapper = styled(Card)`
  background: #fff;
  box-shadow: none;
  border: 1px solid #e7e7e7;
  border-radius: 9px;
  padding: 0px 24px;
  margin: 8px 0px;
  width: 100%;
  cursor: pointer;
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
`;

const TypographyText = styled(Typography)`
  font-size: 14px !important;
  color: #00000099;
  height: 42px;
`;

const TypeFormTag = styled.span`
  height: 24px;
  font-size: 12px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  padding: 0px 6px;
  margin-left: 8px;
`;

const TagWrapper = styled.div`
  margin-top: 16px;
  display: inline-block;
  padding: 8px 16px;
  border-radius: 30px;
  font-size: 13px;
  font-weight: 500;
  color: #fff;
  text-transform: capitalize;
`;

/**
 * FormCard component to display a clickable card with a title, description, and type tag.
 * @param {Object} props - Component props.
 * @param {string} props.name - Name/title of the card.
 * @param {string} props.description - Description of the card.
 * @param {string} props.routeUrl - URL for the card action.
 * @param {string} props.colorBg - Background color for the type tag.
 * @param {string} props.typeForm - Type of the form.
 * @param {string} props.buttonName - Name to display in the type tag.
 * @returns {JSX.Element} FormCard component.
 */
const FormCard = ({ name, description, routeUrl, colorBg, typeForm, buttonName, pages }) => {
  const history = useHistory();

  const handleCardClick = () => {
    history.push(routeUrl, { pages });
  };

  return (
    <CardWrapper
      onClick={handleCardClick}
      style={{
        boxShadow: 'none',
        minHeight: 170,
      }}
    >
      <CardContent style={{ padding: '20px 10px' }}>
        <Typography variant="h6" style={{ fontWeight: 600, fontSize: 18 }}>
          {name}
        </Typography>
        <TypographyText>{description}</TypographyText>
        <TagWrapper style={{ backgroundColor: colorBg }}>
          {buttonName || typeForm}
        </TagWrapper>
      </CardContent>
    </CardWrapper>
  );
};

// PropTypes validation for FormCard
FormCard.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  routeUrl: PropTypes.string.isRequired,
  colorBg: PropTypes.string.isRequired,
  typeForm: PropTypes.string.isRequired,
  buttonName: PropTypes.string,
  pages: PropTypes.object,
};

/**
 * FormCardLite component to display a simplified version of FormCard.
 * @param {Object} props - Component props.
 * @param {string} props.name - Name/title of the card.
 * @param {string} props.colorBg - Background color for the button.
 * @param {string} props.typeForm - Type of the form.
 * @param {string} props.buttonName - Name of the button.
 * @returns {JSX.Element} FormCardLite component.
 */
export const FormCardLite = ({ name, colorBg, buttonName, typeForm }) => (
  <CardWrapper style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', minHeight: 70 }}>
    <CardContent style={{ padding: 0, display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
      <Typography variant="h4" style={{ fontSize: 18, fontWeight: 600 }}>
        {formatTxt(name, 30)}
      </Typography>
      <TypeFormTag
        style={{
          background: colorBg,
          color: '#fff',
          textTransform: 'capitalize',
        }}
      >
        {formatTxt(buttonName?.toLowerCase(), 20) || typeForm}
      </TypeFormTag>
    </CardContent>
  </CardWrapper>
);

FormCardLite.propTypes = {
  name: PropTypes.string.isRequired,
  colorBg: PropTypes.string.isRequired,
  buttonName: PropTypes.string,
  typeForm: PropTypes.string.isRequired,
};

export default FormCard;
