import { useContext, useEffect, useState, useCallback } from 'react';
import { toast } from 'react-toastify';

import { Context } from '../Context';
import { getFormInfo } from '../services/unarmed';

const useTrackForm = (trackingNumber) => {
  const [form, setForm] = useState(null);
  const { authUser } = useContext(Context);
  const [loading, setLoading] = useState(false);

  const onTrackForm = useCallback(async () => {
    try {
      setLoading(true);
      let res = null;

      if (authUser) {
        res = await getFormInfo(trackingNumber, authUser.tokenId);
      } else {
        res = await getFormInfo(trackingNumber);
      }
      setForm(res.data);
      setLoading(false);
    } catch (error) {
      toast.error(error);
      setForm(null);
      setLoading(false);
    }
  }, [authUser, trackingNumber]);

  useEffect(() => {
    if (trackingNumber) {
      onTrackForm();
    }
  }, [trackingNumber, authUser, onTrackForm]);

  return {
    loading,
    setForm,
    form,
    refetch: onTrackForm,
  };
};

export default useTrackForm;
