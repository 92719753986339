import React, { useCallback, useEffect, useState } from 'react';
import {
  Backdrop,
  Box,
  Checkbox,
  CircularProgress,
  Fade,
  Modal,
  Tooltip,
  Typography,
  Popover,
  List,
  ListItem,
  Divider,
  TextField,
  InputAdornment,
  Button,
  Tabs,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import moment from 'moment';
import EnhancedTable from '../../components/Table';
import AllegationFilter from './AllegationFilter';
import FilterButton from '../../components/FilterButton';
import DashButton from '../../components/DashButton';
import { getAllegations, getfiltersConfigById, getViewByIdv } from '../../services/unarmed';
import AddMore from '../../components/AddMore';
import { TYPE_USER_TABS } from '../../utils/constants';

export const ViewTabAllegations = ({
  open,
  classes,
  handleClick,
  openPopover,
  anchorEl,
  handleClose,
  searchTerm,
  setSearchTerm,
  showColumns,
  handleToggleColumn,
  formatHeadersText,
  onEditMultipleAllegations,
  visibleColumns,
  hideColumns,
  from,
  to,
  setPage,
  flaggedOnly,
  setFrom,
  setTo,
  page,
  rowsPerPage,
  sort,
  sortBy,
  onClearFilters,
  setIsFiltering,
  selected,
  setOpen,
  type,
  fetchFiltersConfig,
  setSort,
  filtersLoaded,
  setFiltersLoaded,
  getVisibleColumns,
  setSortBy,
  renderRow,
  setChecked,
  checked,
  setSelected,
  handleChangeRowsPerPage,
  tabSelected,
  setVisibleColumns,
  loading,
  allegations,
  onGetAllegationRange,
  allegation,
  trackingNumber,
  badge,
  officerName,
  disposition,
  discipline,
  setAllegations,
  status,
  setLoading,
  location,
  isFiltering,
}) => {
  const [view, setView] = useState();
  const [filtersConfig, setfiltersConfig] = useState({});
  const [filtersApplied, setFiltersApplied] = useState({});
  const [filterCount, setFilterCount] = useState(0);

  const onGetAllegation = async () => {
    try {
      setLoading(true);
      const { data, headers } = await getAllegations({
        page,
        limit: rowsPerPage,
        from,
        to,
        sort,
        sortBy,
        isFiltering,
        badge: badge.value,
        officerName: officerName.value,
        status: status.value,
        disposition: disposition.value,
        allegation: allegation.value,
        discipline: discipline.value,
        trackingNumber: trackingNumber.value,
        flaggedOnly: flaggedOnly.value,
        type: type.value,
      });
      setAllegations({ data, headers });
      console.log('DATA', data);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      console.log(error);
    }
  };

  const handleOnSetFiltersConfig = () => {
    const newFiltersApplied = {};
    if (filtersConfig) {
      const configCopy = { ...filtersConfig };

      delete configCopy.sortOrder;
      delete configCopy.sortBy;
      delete configCopy.page;
      delete configCopy.limit;
      delete configCopy.isFiltering;
      Object.keys(configCopy).forEach((key) => {
        const value = configCopy[key];
        let isApplied = false;

        if (value === null || !value || value === '0') {
          isApplied = false;
        } else if (Array.isArray(value)) {
          isApplied = value.length > 0;
        } else if (typeof value === 'boolean') {
          isApplied = value === true;
        } else {
          isApplied = true;
        }

        newFiltersApplied[key] = isApplied;
      });

      const newCount = Object.values(newFiltersApplied).filter((item) => item).length;
      setFilterCount(newCount);
      setFiltersApplied(newFiltersApplied);
    }
  };

  const onGetAllegation1 = async ({
    fromV,
    toV,
    sortV,
    sortByV,
    badgeV,
    officerNameV,
    statusV,
    dispositionV,
    allegationV,
    disciplineV,
    trackingNumberV,
    typeV,
    flaggedOnlyV,
  }) => {
    try {
      setLoading(true);
      const { data, headers } = await getAllegations({
        page,
        limit: rowsPerPage,
        from: fromV,
        to: toV,
        sort: sortV,
        sortBy: sortByV,
        isFiltering,
        badge: badgeV,
        officerName: officerNameV,
        status: statusV,
        disposition: dispositionV,
        allegation: allegationV,
        discipline: disciplineV,
        trackingNumber: trackingNumberV,
        flaggedOnly: flaggedOnlyV,
        type: typeV,
      });
      setAllegations({ data, headers });
      console.log('DATA', data);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      console.log(error);
    }
  };

  useEffect(() => {
    handleOnSetFiltersConfig();
    if (filtersConfig) {
      allegation.setValue(filtersConfig?.allegation);
      disposition.setValue(filtersConfig?.disposition);
      discipline.setValue(filtersConfig?.discipline);
      setFrom(filtersConfig?.startDate);
      setTo(filtersConfig?.endDate);
      officerName.setValue(filtersConfig?.name);
      badge.setValue(filtersConfig?.badgeNumber);
      status.setValue(filtersConfig?.status);
      setSort(sort || filtersConfig?.sortOrder);
      setSortBy(sortBy || filtersConfig?.sortBy);
      type.setValue(filtersConfig?.type?.toLowerCase() || '');
      trackingNumber.setValue(filtersConfig?.code);
      flaggedOnly.setValue(filtersConfig?.flaggedOnly);

      setIsFiltering(true);
      setFiltersLoaded(true);
      onGetAllegation1({
        fromV: filtersConfig?.startDate,
        toV: filtersConfig?.endDate,
        sortV: sort || filtersConfig?.sortOrder,
        sortByV: sortBy || filtersConfig?.sortBy,
        badgeV: filtersConfig?.badgeNumber,
        officerNameV: filtersConfig?.name,
        statusV: filtersConfig?.status,
        dispositionV: filtersConfig?.disposition,
        allegationV: filtersConfig?.allegation,
        disciplineV: filtersConfig?.discipline,
        trackingNumberV: filtersConfig?.code,
        typeV: filtersConfig?.type?.toLowerCase(),
        flaggedOnlyV: filtersConfig?.flaggedOnly,
      });
    } else if (filtersConfig === null) {
      setIsFiltering(false);
      setFiltersLoaded(true);
      onGetAllegation();
    }
  }, [filtersConfig, page, sort, sortBy]);

  useEffect(() => {
    const fetchData = async () => {
      if (tabSelected?.displayObjectId && tabSelected?.displayObjectType === TYPE_USER_TABS.VIEW) {
        const responseView = await getViewByIdv(tabSelected?.displayObjectId);
        if (responseView?.data?.view[0]) {
          setView(responseView.data.view[0]);
          setVisibleColumns(responseView.data.view[0].columns);
        }
      }
    };

    fetchData();
  }, [tabSelected]);

  useEffect(() => {
    const fetchData = async () => {
      if (view) {
        const responsefiltersConfig = await getfiltersConfigById(view?.filtersConfigId);
        setfiltersConfig(responsefiltersConfig?.data?.filterConfig[0]?.config);
      }
    };

    fetchData();
  }, [view]);

  return (
    <>
      <Box width="100%" marginTop="20px" marginBottom="0">
        <Box width="100%" display="flex" padding="10px 0" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Typography variant="subtitle2" id="tableTitle" component="div">
              All Allegations ({allegations?.headers?.['x-pagination-totalitems']})
            </Typography>

            <AddMore text="" marginLeft="0" marginTop="0" onClick={handleClick} />

            <Popover
              open={openPopover}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
              <Box p={2} width="240px" height="400px" style={{ overflow: 'auto' }}>
                <Box display="flex" justifyContent="center">
                  <Typography variant="h7" style={{ marginBottom: '2px', fontWeight: 'bold' }}>
                    Show / Hide Columns
                  </Typography>
                </Box>

                <Divider style={{ marginTop: '2px', marginBottom: '2px' }} />

                <TextField
                  placeholder="Search columns"
                  variant="standard"
                  fullWidth
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon style={{ fontSize: '12px' }} />
                      </InputAdornment>
                    ),
                    disableUnderline: true,
                    style: {
                      fontSize: '12px',
                      height: '28px',
                    },
                  }}
                  style={{
                    padding: '5px 0',
                    backgroundColor: 'transparent',
                    fontSize: '12px',
                    height: '28px',
                  }}
                />

                <Divider style={{ marginTop: '7px', marginBottom: '5px' }} />

                <Box display="flex" justifyContent="center">
                  <Typography variant="caption" style={{ marginBottom: '5px', fontSize: '14px', fontWeight: 'bold' }}>
                    Visible
                  </Typography>
                </Box>

                <List dense style={{ padding: 0 }}>
                  {showColumns.map((column) => (
                    <ListItem
                      key={column}
                      button
                      onClick={() => handleToggleColumn(view._id, column)}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '.1px',
                      }}
                    >
                      <Tooltip title={formatHeadersText(column)}>
                        <Typography variant="caption" style={{ lineHeight: '1.2' }}>
                          {formatHeadersText(column)}
                        </Typography>
                      </Tooltip>
                      <Checkbox
                        checked={visibleColumns[column]}
                        color="primary"
                        style={{ transform: 'scale(0.7)', paddingTop: '2px', paddingBottom: '2px' }}
                      />
                    </ListItem>
                  ))}
                </List>

                <Divider style={{ margin: '10px 0' }} />

                <Box display="flex" justifyContent="center">
                  <Typography
                    variant="caption"
                    style={{ marginBottom: '5px', marginTop: '5px', fontSize: '14px', fontWeight: 'bold' }}
                  >
                    Hidden
                  </Typography>
                </Box>

                <List dense style={{ padding: 0 }}>
                  {hideColumns.map((column) => (
                    <ListItem
                      key={column}
                      button
                      onClick={() => handleToggleColumn(view._id, column)}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '.1px',
                      }}
                    >
                      <Tooltip title={formatHeadersText(column)}>
                        <Typography variant="caption" style={{ lineHeight: '1.2' }}>
                          {formatHeadersText(column)}
                        </Typography>
                      </Tooltip>
                      <Checkbox
                        checked={visibleColumns[column]}
                        color="primary"
                        style={{ transform: 'scale(0.7)', paddingTop: '2px', paddingBottom: '2px' }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Popover>
          </Box>

          <Box display="flex" alignItems="center">
            <FilterButton
              count={filterCount}
              dataTestId="allegations-button-filter"
              openFilter={open}
              onClick={() => setOpen(!open)}
              marginRight="10px"
            />
            <DashButton
              dataTestId="allegations-button-download"
              onClick={onEditMultipleAllegations}
              disabled={selected.length === 0}
              marginRight="10px"
            >
              Flag
            </DashButton>
            <DashButton
              dataTestId="allegations-button-download"
              onClick={onGetAllegationRange}
              disabled={selected.length === 0}
              marginRight="10px"
            >
              Download
            </DashButton>
          </Box>
        </Box>
        {open && (
          <AllegationFilter
            setOpen={setOpen}
            setfiltersConfig={setfiltersConfig}
            filterConfigId={view?.filtersConfigId}
            filtersConfig={filtersConfig}
            filtersApplied={filtersApplied}
            fetchFiltersConfig={fetchFiltersConfig}
            page={page}
            setPage={setPage}
            onClearFilters={onClearFilters}
            rowsPerPage={rowsPerPage}
            sort={sort}
            sortBy={sortBy}
            setSortBy={setSortBy}
            allegation={allegation}
            trackingNumber={trackingNumber}
            badge={badge}
            officerName={officerName}
            status={status}
            disposition={disposition}
            discipline={discipline}
            from={from}
            to={to}
            type={type}
            setAllegations={setAllegations}
            setFrom={setFrom}
            setTo={setTo}
            setIsFiltering={setIsFiltering}
            openShowAdvancedFilters={location?.state?.advancedFilterOpen}
            flaggedOnly={flaggedOnly}
          />
        )}
        <a id="downloadLink" style={{ position: 'absolute' }} />
        <EnhancedTable
          sort={sort}
          containerStyles={{}}
          setSort={setSort}
          columns={getVisibleColumns()}
          rows={allegations?.data}
          showCheckBox
          setSortBy={setSortBy}
          rowRender={renderRow}
          setChecked={setChecked}
          checked={checked}
          path="allegation"
          loading={loading}
          page={page}
          setPage={setPage}
          selected={selected}
          setSelected={setSelected}
          count={allegations?.headers?.['x-pagination-totalitems']}
          rowsPerPage={rowsPerPage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Box>
    </>
  );
};
