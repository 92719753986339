import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  makeStyles,
  Typography,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import Input from '../../components/Input';
import SelectEngine from '../../components/Select';
import { CustomSwitch } from '../components/WrapperComponent';
import { handleOnChangeQuestion, rangeNumber100, rangeNumber1000 } from './utls';
import DataReportLink from '../../components/DataReportLink';

const responseOptions = [
  {
    value: 'AT_LEAST',
    text: 'At least',
  },
  {
    value: 'EXACT',
    text: 'Exact',
  },
  {
    value: 'AT_MOST',
    text: 'At most',
  },
];
const sizeOptions = [
  {
    value: 5,
    text: '5 MB',
  },
  {
    value: 50,
    text: '50 MB',
  },
  {
    value: 100,
    text: '100 MB',
  },
  {
    value: 250,
    text: '250 MB',
  },
  {
    value: 500,
    text: '500 MB',
  },
];

const useStyles = makeStyles(() => ({
  settingsQuestions: {
    fontSize: 14,
    minWidth: 160,
  },

  subLabel: {
    fontSize: 14,
    color: '#000000DE',
    fontWeight: '500',
    marginBottom: 10,
  },
}));

/**
 * @description question types that can be configured
 * ['description']
 * ['required]
 * ['response validation]
 * ['multiple choice']
 * ['max']
 * ['file type']
 * ['file size']
 */

/**
 * @typedef {Object} SettingsProps
 * @property {string} componentId - ID of the component.
 * @property {string} componentType - Type of the component.
 * @property {Function} onSelectDataReportLink - Function to handle data report link selection.
 * @property {string} defaultDataReportLink - Default data report link.
 * @property {string[]} questions - Array of questions.
 * @property {boolean[]} checkQuestion - Array of boolean values indicating if a question is checked.
 * @property {Object} descriptionQuestion - Object containing description question value and onChange function.
 * @property {Object} requiredQuestion - Object containing required question value and onChange function.
 * @property {Function} setCheckQuestion - Function to set checkQuestion state.
 * @property {Object} responseValidation - Object containing response validation value and onChange function.
 * @property {Object} responseTypeValidation - Object containing response type validation value and onChange function.
 * @property {Object} maxCharacter - Object containing max character value, onChange function, and type.
 * @property {string} fileType - Array of FileType objects representing file types.
 * @property {Function} onChangeFileType - Function to handle file type change.
 * @property {Object} sizeSelect - Object containing size select value and onChange function.
 * @property {Function} saveOnTimeOut - Function to save on timeout.
 */
export default function Settings({
  componentId,
  componentType,
  onSelectDataReportLink,
  defaultDataReportLink,
  questions = [],
  checkQuestion,
  descriptionQuestion,
  requiredQuestion,
  setCheckQuestion,
  responseValidation,
  responseTypeValidation,
  maxCharacter,
  maxCharacterType,
  fileType,
  onChangeFileType,
  sizeSelect,
  saveOnTimeOut,
}) {
  const classes = useStyles();
  const [dataReportLinkChecked, setDataReportLinkChecked] = useState(false);
  const [dateFormatValue, setDateFormatValue] = useState('MM/DD/YYYY');
  const handleChangeDateFormat = (event) => {
    saveOnTimeOut();
    setDateFormatValue(event.target.value);
  };

  const multipleChoiceQuestionIndex = questions.indexOf('multiple choice');
  const multipleChoiceValue = multipleChoiceQuestionIndex !== -1 ? checkQuestion[multipleChoiceQuestionIndex] : false;

  const renderQuestion = questions.map((question, index) => {
    if (question === 'description') {
      return (
        <Box key={index} padding="0 10px" marginBottom="10px">
          <Box display="flex" alignItems="center">
            <Typography className={classes.settingsQuestions}>Description Question</Typography>
            <CustomSwitch
              checked={checkQuestion[index]}
              onChange={(e) => handleOnChangeQuestion(e, index, setCheckQuestion)}
            />
          </Box>
          {checkQuestion[index] && (
            <Input
              placeholder="Enter description here"
              value={descriptionQuestion.value}
              onChange={(e) => {
                descriptionQuestion.onChange(e);
                saveOnTimeOut();
              }}
              style={{ width: '100%', maxWidth: 500 }}
            />
          )}
        </Box>
      );
    }

    if (question === 'required') {
      return (
        <Box key={index} padding="0 10px" marginBottom="10px">
          <Box display="flex" alignItems="center">
            <Typography className={classes.settingsQuestions}>Required Question Message</Typography>
            <CustomSwitch
              checked={checkQuestion[index]}
              onChange={(e) => handleOnChangeQuestion(e, index, setCheckQuestion)}
            />
          </Box>
          {checkQuestion[index] && (
            <>
              <Typography className={classes.subLabel}>
                This error message will be displayed by default when this question is not answered.
              </Typography>
              <Input
                placeholder="This field is required!"
                value={requiredQuestion.value}
                onChange={(e) => {
                  requiredQuestion.onChange(e);
                  saveOnTimeOut();
                }}
                style={{ width: 280 }}
              />
            </>
          )}
        </Box>
      );
    }

    if (question === 'checkboxQuestion') {
      return (
        <Box key={index} padding="0 10px" marginBottom="10px">
          <Box display="flex" alignItems="center">
            <Typography className={classes.settingsQuestions}>Checkbox</Typography>
            <CustomSwitch
              checked={checkQuestion[index]}
              onChange={(e) => handleOnChangeQuestion(e, index, setCheckQuestion)}
            />
          </Box>
        </Box>
      );
    }

    if (question === 'response validation') {
      return (
        <Box key={index} padding="0 10px" marginBottom="10px">
          <Box display="flex" alignItems="center">
            <Typography className={classes.settingsQuestions}>Response Validation</Typography>
            <CustomSwitch
              checked={checkQuestion[index]}
              onChange={(e) => handleOnChangeQuestion(e, index, setCheckQuestion)}
            />
          </Box>
          {checkQuestion[index] && (
            <>
              <Typography className={classes.subLabel}>Number of options that the user must answer.</Typography>
              <Box>
                <SelectEngine
                  options={responseOptions}
                  onChange={(e) => {
                    responseTypeValidation.onChange(e);
                    saveOnTimeOut();
                  }}
                  value={responseTypeValidation.value}
                />
                <Input
                  placeholder="0"
                  type="number"
                  value={responseValidation.value}
                  onChange={(e) => {
                    responseValidation.onChange(e);
                    saveOnTimeOut();
                  }}
                  style={{ width: 60, marginLeft: 10 }}
                />
              </Box>
            </>
          )}
        </Box>
      );
    }

    if (question === 'multiple choice') {
      return (
        <Box key={index} padding="0 10px" marginBottom="10px">
          <Box display="flex" alignItems="center">
            <Typography className={classes.settingsQuestions}>Multiple Choice</Typography>
            <CustomSwitch
              checked={checkQuestion[index]}
              onChange={(e) => {
                if (dataReportLinkChecked) {
                  toast.warn('Please disable data report link before enable/disable multiple choice field');
                } else {
                  handleOnChangeQuestion(e, index, setCheckQuestion);
                  saveOnTimeOut();
                }
              }}
            />
          </Box>
        </Box>
      );
    }

    if (question === 'max') {
      return (
        <Box key={index} padding="0 10px" marginBottom="10px">
          <Box display="flex" alignItems="center">
            <Typography className={classes.settingsQuestions}>Max Characters</Typography>
            <CustomSwitch
              checked={checkQuestion[index]}
              onChange={(e) => handleOnChangeQuestion(e, index, setCheckQuestion)}
            />
          </Box>
          {checkQuestion[index] && (
            <Input
              placeholder={maxCharacterType === 'short' ? '1-100' : '1-1000'}
              type="number"
              value={maxCharacter.value}
              onChange={(e) => {
                if (e.target.value.length === 0) {
                  maxCharacter.setValue('');
                  saveOnTimeOut();
                }
                if (maxCharacterType === 'short' && rangeNumber100(e.target.value)) {
                  maxCharacter.onChange(e);
                  saveOnTimeOut();
                }
                if (maxCharacterType === 'long' && rangeNumber1000(e.target.value)) {
                  maxCharacter.onChange(e);
                  saveOnTimeOut();
                }
              }}
              style={{ width: 120 }}
            />
          )}
        </Box>
      );
    }
    if (question === 'multiple links') {
      return (
        <Box key={index} padding="0 10px" marginBottom="10px">
          <Box display="flex" alignItems="center">
            <Typography className={classes.settingsQuestions}>Multiple Links</Typography>
            <CustomSwitch
              checked={checkQuestion[index]}
              onChange={(e) => {
                handleOnChangeQuestion(e, index, setCheckQuestion);
                saveOnTimeOut();
              }}
            />
          </Box>
        </Box>
      );
    }

    if (question === 'file') {
      return (
        <Box key={index}>
          <Box padding="0 10px" marginBottom="10px">
            <Box display="flex" alignItems="center">
              <Typography className={classes.settingsQuestions}>File Types</Typography>
              <CustomSwitch
                checked={checkQuestion[index]}
                onChange={(e) => {
                  handleOnChangeQuestion(e, index, setCheckQuestion);
                  saveOnTimeOut();
                }}
              />
            </Box>
            {checkQuestion[index] && (
              <Box marginTop="5px">
                <FormGroup aria-label="position" row>
                  {fileType.map((ft, idx) => (
                    <FormControlLabel
                      key={idx}
                      control={
                        <CustomCheckbox
                          checked={ft.value}
                          size="small"
                          onChange={(e) => {
                            onChangeFileType(e, idx);
                            saveOnTimeOut();
                          }}
                          color="primary"
                        />
                      }
                      label={ft.text}
                      labelPlacement="end"
                    />
                  ))}
                </FormGroup>
              </Box>
            )}
          </Box>
          <Box
            padding="0 10px"
            marginBottom="10px"
            display="flex"
            alignItems="center"
            width="218px"
            justifyContent="space-between"
          >
            <Typography className={classes.settingsQuestions} style={{ minWidth: 100 }}>
              File Size
            </Typography>
            <SelectEngine
              options={sizeOptions}
              onChange={(e) => {
                sizeSelect.onChange(e);
                saveOnTimeOut();
              }}
              value={sizeSelect.value}
            />
          </Box>
        </Box>
      );
    }

    if (question === 'date validation') {
      return (
        <Box key={index} padding="0 10px" marginBottom="10px">
          <Box display="flex" alignItems="center">
            <Typography className={classes.settingsQuestions}>Date Validation</Typography>
            <CustomSwitch
              checked={checkQuestion[index]}
              onChange={(e) => {
                handleOnChangeQuestion(e, index, setCheckQuestion);
                saveOnTimeOut();
              }}
            />
          </Box>
          {checkQuestion[index] && (
            <Box>
              <Box>
                <Input
                  placeholder="Please enter a valid date."
                  value={requiredQuestion.value}
                  onChange={(e) => {
                    requiredQuestion.onChange(e);
                    saveOnTimeOut();
                  }}
                  style={{ width: 280 }}
                />
              </Box>
              <Box paddingTop="20px">
                <Typography className={classes.settingsQuestions} style={{ minWidth: 100 }}>
                  Format
                </Typography>
              </Box>
              <Box>
                <RadioGroup
                  row
                  name="controlled-radio-buttons-group"
                  value={dateFormatValue}
                  onChange={handleChangeDateFormat}
                >
                  <FormControlLabel value="MM/DD/YYYY" control={<Radio color="primary" />} label="MM/DD/YYYY" />
                  <FormControlLabel value="DD/MM/YYYY" control={<Radio color="primary" />} label="DD/MM/YYYY" />
                </RadioGroup>
              </Box>
            </Box>
          )}
        </Box>
      );
    }

    return 'Component not supported';
  });

  return (
    <>
      {renderQuestion}
      {componentType && onSelectDataReportLink && (
        <DataReportLink
          componentId={componentId}
          isMultipleChoice={multipleChoiceValue}
          componentType={componentType}
          onSelect={onSelectDataReportLink}
          defaultDataReportLink={defaultDataReportLink}
          onChangeChecked={setDataReportLinkChecked}
        />
      )}
    </>
  );
}

Settings.propTypes = {
  componentId: PropTypes.string.isRequired,
  componentType: PropTypes.string.isRequired,
  onSelectDataReportLink: PropTypes.func,
  defaultDataReportLink: PropTypes.string,
  questions: PropTypes.arrayOf(PropTypes.string),
  checkQuestion: PropTypes.arrayOf(PropTypes.bool),
  descriptionQuestion: PropTypes.shape({
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }),
  requiredQuestion: PropTypes.shape({
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }),
  setCheckQuestion: PropTypes.func.isRequired,
  responseValidation: PropTypes.shape({
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }),
  responseTypeValidation: PropTypes.shape({
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }),
  maxCharacter: PropTypes.shape({
    setValue: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
  }),
  maxCharacterType: PropTypes.string.isRequired,
  fileType: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    })
  ),
  onChangeFileType: PropTypes.func.isRequired,
  sizeSelect: PropTypes.shape({
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
  }),
  saveOnTimeOut: PropTypes.func.isRequired,
};

export const CustomCheckbox = styled(Checkbox)`
  &.MuiCheckbox-colorPrimary.Mui-checked {
    color: #2e66fe;
  }
`;
