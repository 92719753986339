import React from 'react';
import { Grid, Box, Divider, Typography, CircularProgress, makeStyles, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

/**
 * Custom CSS styles for the SearchResults component.
 */
const useStyles = makeStyles(() => ({
  title: {
    fontSize: 16,
    color: 'rgba(87, 90, 102, 1)',
    fontWeight: 'bold',
  },
  itemColumn: {
    fontSize: 12,
    color: 'rgba(87, 90, 102, 1)',
  },
  itemValue: {
    fontSize: 14,
    color: '#000',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

/**
 * Component for displaying search results with customizable columns and actions.
 * @param {object} props - The props for the component.
 * @param {Array} props.data - The array of data representing search results.
 * @param {Array} props.defaultData - The array of default data.
 * @param {Array} props.columns - The array of column configurations.
 * @param {boolean} props.loading - Indicates if data is currently being loaded.
 * @param {Function} props.onClickAdd - The function to call when an item is clicked to add.
 * @param {boolean} props.searched - Indicates if a search has been performed.
 * @param {string} [props.noResultsText='No records found yet'] - The text to display when no results are found.
 * @returns {JSX.Element} - A JSX element representing the SearchResults component.
 */
const SearchResults = ({
  data,
  defaultData,
  columns,
  loading,
  onClickAdd,
  searched,
  noResultsText = 'No records found yet',
}) => {
  const classes = useStyles();

  return (
    <Grid container>
      {data.length > 0 ? (
        <Grid item xs={12}>
          <Box mt={1} mb={1}>
            <Typography className={classes.title}>Results</Typography>
          </Box>
          <Divider />
          <Box mt={1}>
            <Box mb={1}>
              <Grid container justifyContent="space-between" alignItems="center">
                {columns.map((column, columnKey) => (
                  <Grid item xs={column.xs} key={columnKey}>
                    <Typography className={classes.itemColumn}>{column.name}</Typography>
                  </Grid>
                ))}
                <Grid item xs={1} />
              </Grid>
            </Box>
            {data.map((item) => {
              const alreadyAdded = defaultData.find((defaultItem) => defaultItem?._id === item?._id);

              return (
                <Box key={item?._id} height="50px">
                  <Grid container justifyContent="space-between" alignItems="center">
                    {columns.map((column, columnKey) => (
                      <Grid item xs={column.xs} key={columnKey}>
                        {column.renderValue ? (
                          <Typography className={classes.itemValue}>
                            {column.renderValue(item[column.key]) || 'Not specified'}
                          </Typography>
                        ) : (
                          <Typography className={classes.itemValue}>{item[column.key] || 'Not specified'}</Typography>
                        )}
                      </Grid>
                    ))}
                    <Grid item xs={1}>
                      <Box display="flex" alignItems="center" justifyContent="center" height="50px">
                        {loading === item?._id ? (
                          <CircularProgress size="24px" />
                        ) : (
                          <IconButton disabled={!!alreadyAdded} onClick={() => onClickAdd(item)}>
                            <AddCircleOutlineIcon htmlColor={alreadyAdded ? '#ccc' : '#3B70FE'} />
                          </IconButton>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              );
            })}
          </Box>
        </Grid>
      ) : null}
      {data.length === 0 && searched && (
        <Grid item xs={12}>
          <Divider />
          <Box display="flex" justifyContent="center" alignItems="center" mt={2} width="100%">
            <Typography style={{ textAlign: 'center', fontWeight: 'bold' }}>{noResultsText}</Typography>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

SearchResults.propTypes = {
  /** The array of column configurations. */
  columns: PropTypes.array,
  /** The array of data representing search results. */
  data: PropTypes.array,
  /** The array of default data. */
  defaultData: PropTypes.array,
  /** Indicates if data is currently being loaded. */
  loading: PropTypes.object,
  /** The function to call when an item is clicked to add. */
  onClickAdd: PropTypes.func,
  /** Indicates if a search has been performed. */
  searched: PropTypes.bool,
  /** The text to display when no results are found. */
  noResultsText: PropTypes.string,
};

export default SearchResults;
