import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Box, CircularProgress } from '@material-ui/core';

import TabPanel from '../../components/TabPanel';
import useProject from '../../hooks/useProject';
import Header from './components/Header';
import useOrganization from '../../hooks/useOrganization';
import Actions from './tabs/Actions';
import { formatOverviewFeatureItemToRender } from './utils';
import ModalPopUp from '../../components/ModaPopupl';
import ConfirmationPopup from './ConfirmationPopup';
import ProjectNotes from './tabs/Notes';
import ProjectAttachments from './tabs/ProjectAttachments';
import History from './tabs/History';
import Recommendations from './tabs/Recommendations';
import ProjectContacts from './ProjectContacts';
import UpgradeTabPopup from '../../components/UpgradeTabPopup';
import { Context } from '../../Context';
import { updateUrlWithParams } from '../../utils/libs';

const tabsArray = [
  { label: 'Actions', value: 0 },
  { label: 'Recommendations', value: 1 },
  { label: 'Contacts', value: 2 },
  { label: 'Notes', value: 3 },
  { label: 'Attachments', value: 4 },
  { label: 'History', value: 5 },
];

export default function ProjectDetails() {
  const { id } = useParams();

  const { project, loading, refetch } = useProject(id);
  const { organization } = useOrganization();
  const [open, setOpen] = useState(false);
  const [tabToChange, setTabToChange] = useState(0);
  const { myUser } = useContext(Context);
  const [actionsSaved, setActionsSaved] = useState([]);
  const [value, setValue] = useState(0);
  const [items, setItems] = useState([]);

  const [actions, setActions] = useState([]);

  const getActionsFetched = () => {
    const fetchedActions = JSON.parse(JSON.stringify(project?.actions));

    return fetchedActions.map((action) => ({
      ...action?.settings,
      name: action?.name,
      values: action?.values,
      types: action?.validTypes,
      updatedAt: action?.updatedAt,
      _id: action?._id,
    }));
  };

  useEffect(() => {
    if (organization) {
      updateUrlWithParams(
        {
          organization_name: organization?.name,
          tab_name: 'actions',
        },
        {
          tab_name: 'actions',
        }
      );
    }
  }, [organization]);

  useEffect(() => {
    if (project?.actions?.length) {
      setActions(getActionsFetched());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project?.actions]);

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    // let hasChanges = null;
    // if (newValue > 0) {
    //   if (project?.overview?.length !== items.length) {
    //     setOpen(true);
    //     setTabToChange(newValue);
    //     return;
    //   }

    //   hasChanges = project.actions.map((overvew, index) => {
    //     if (
    //       getChangesDiff(overvew, {
    //         ...formatOverviewFeatureItemToSave(items)[index],
    //         _id: overvew?._id,
    //       })
    //     ) {
    //       return 'it has';
    //     }
    //     return null;
    //   });
    // }

    // if (hasChanges && hasChanges.includes('it has')) {
    //   setOpen(true);
    //   setTabToChange(newValue);
    //   return;
    // }
    // setOldTab(value);

    const tabData = tabsArray[newValue];
    const tabLabel = tabData.label?.toLowerCase()?.replace(/ /g, '-');

    updateUrlWithParams(
      {
        organization_name: organization.name,
        tab_name: tabLabel,
      },
      {
        tab_name: tabLabel,
      }
    );

    if (newValue !== 0) {
      setActions(getActionsFetched());
    }
    setValue(newValue);
  };

  const changeTabOnValidate = () => {
    const overViewFeatures = formatOverviewFeatureItemToRender(project?.overview);

    setItems(overViewFeatures);
    setValue(tabToChange);
    setOpen(false);
  };

  return (
    <div>
      <Header
        tabValue={value}
        handleChangeTab={handleChange}
        a11yProps={a11yProps}
        project={project}
        statusOptions={organization?.project?.availableStatuses}
        typesOptions={project?.validTypes}
      />
      <TabPanel value={value} index={0}>
        {loading ? (
          <Box height="50px" justifyContent="center" alignItems="center" display="flex">
            <CircularProgress color="#364F74" size="25px" />
          </Box>
        ) : (
          <Actions
            relatedProjects={project?.relatedProjects}
            id={id}
            setActionsSaved={setActionsSaved}
            refetch={refetch}
            organizationId={organization?._id}
            actionsArray={project?.actions}
            statusOptions={organization?.project?.availableStatuses}
            actions={actions}
            organization={organization}
            setActions={setActions}
          />
        )}
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Recommendations
          tabValue={value}
          projectId={id}
          refetch={refetch}
          projectRecommendations={project?.recommendations}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        {!organization?.permissions?.[myUser?.role]?.contacts && <UpgradeTabPopup />}
        <ProjectContacts
          projectId={id}
          defaultContacts={project?.contacts || []}
          onAddContact={refetch}
          onRemoveContact={refetch}
        />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <ProjectNotes id={id} organizationId={organization?._id} logs={project?.notes} refetch={refetch} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <ProjectAttachments
          id={id}
          organizationId={organization?._id}
          projectAttachments={project?.attachments}
          projectName={project?.name}
          refetch={refetch}
        />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <History id={id} />
      </TabPanel>
      <ModalPopUp setOpen={setOpen} open={open}>
        <ConfirmationPopup handleCancel={() => setOpen(false)} loading={false} handleSubmit={changeTabOnValidate} />
      </ModalPopUp>
    </div>
  );
}
