import { Box, Typography } from '@material-ui/core';
import React from 'react';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import CancelIcon from '@material-ui/icons/Cancel';

import SelectEngine from '../../../components/Select';
import DndLogicItem from './DndLogicItem';

import Input from '../../../components/Input';
import AddMore from '../../../components/AddMore';
import ConditionalSelect from './ConditionalSelect';

const getDependentOptions = (options = [], condition) => {
  if (options.length === 0) return [];
  const dependentOptions = options?.find((option) => option?.id === condition?.when);
  return dependentOptions?.values?.map((option) => ({ value: option, text: option }));
};

const getConditionalSelectValue = (options = [], condition) => {
  const value = options.find((option) => option?.id === condition?.when);
  return value?.question;
};

const CaseNumberConditional = ({ options = [], onChange, condition, onRemoveSubCondition }) => (
  <Box display="flex" alignItems="center" style={{ gap: 10, flexWrap: 'wrap', marginBottom: 20 }}>
    <CancelIcon style={{ fontSize: 14, cursor: 'pointer' }} onClick={onRemoveSubCondition} />
    <Typography variant="body1" style={{ fontSize: 14 }}>
      When
    </Typography>
    <ArrowRightAltIcon style={{ fontSize: 14 }} />
    <Box style={{ position: 'relative' }}>
      <ConditionalSelect options={options} onChange={onChange} value={getConditionalSelectValue(options, condition)} />
    </Box>
    <Typography variant="body1" style={{ fontSize: 14 }}>
      =
    </Typography>
    <SelectEngine
      options={getDependentOptions(options, condition) || []}
      onChange={(e) => {
        onChange(e.target.value, 'equals');
      }}
      width={180}
      value={condition?.equals}
    />
    <Typography variant="body1" style={{ fontSize: 14 }}>
      Value
    </Typography>
    <ArrowRightAltIcon style={{ fontSize: 14 }} />
    <Input
      placeholder="Add a value"
      style={{ width: 200 }}
      onChange={(e) => {
        onChange(e.target.value, 'value');
      }}
      value={condition?.value}
    />
  </Box>
);

export default function Condition({ setLogicItems, index, onRemoveItem, logic, options }) {
  const onChange = (subIndex, value, prop) => {
    setLogicItems((prev) => {
      const newLogic = [...prev];
      newLogic[index].conditions[subIndex][prop] = value;
      return newLogic;
    });
  };

  const onAddMore = () => {
    setLogicItems((prev) => {
      const newLogic = [...prev];
      newLogic[index].conditions.push({ when: '', equals: '', value: '' });
      return newLogic;
    });
  };

  const onRemoveSubCondition = (idx) => {
    setLogicItems((prev) => {
      const newLogic = [...prev];
      newLogic[index].conditions.splice(idx, 1);
      return newLogic;
    });
  };

  return (
    <DndLogicItem title="Condition" onRemove={onRemoveItem}>
      {logic?.conditions?.map((condition, idx) => (
        <CaseNumberConditional
          key={idx}
          options={options}
          onChange={(value, prop) => onChange(idx, value, prop)}
          condition={condition}
          onRemoveSubCondition={() => onRemoveSubCondition(idx)}
        />
      ))}
      <AddMore
        dataTestId="forms-button-add-new-condition"
        text="Add new condition"
        marginLeft="60px"
        onClick={onAddMore}
      />
    </DndLogicItem>
  );
}
