import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { Button, Divider, Typography, Box } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import PropTypes from 'prop-types';

import Tag from './Tag';
import { useTags } from '../hooks/useTags';
import Card from './Card';
import { CustomSwitch } from '../formEngine/components/WrapperComponent';

/**
 * Component for displaying and managing tags filters.
 * @component
 * @param {function} onSubmitFilters - Function to call when filters are submitted.
 */
const TagsFilters = ({ onSubmitFilters, maintainAlwaysOpen = true, archivedOnly, setArchivedOnly }) => {
  const [open, setOpen] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [search, setSearch] = useState('');
  const [tagsRecently, onChangeSearch, loading] = useTags(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [searching, setSearching] = useState(false);
  const [searchedText, setSearchedText] = useState('');

  /**
   * Handle submission of filters.
   * @function
   */
  const handleOnSubmit = useCallback(() => {
    onSubmitFilters(selectedTags.map((tag) => tag?._id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTags]);

  const doneTypingInterval = 750;
  let interval;

  const tagsRecentlyFiltered = tagsRecently.filter(
    (_tag) => !selectedTags.find((selectedTag) => selectedTag?._id === _tag?._id)
  );

  useEffect(() => {
    handleOnSubmit();
  }, [selectedTags, handleOnSubmit]);

  return (
    <div>
      {!maintainAlwaysOpen && (
        <ContainerButton>
          <ButtonFilter open={open} onClick={() => setOpen(!open)}>
            <FilterListIcon style={{ fontSize: 32, color: !open ? '#fff' : '#2E66FE' }} />
          </ButtonFilter>
        </ContainerButton>
      )}
      {(open || maintainAlwaysOpen) && (
        <Card title="Filters">
          <Box display="flex" justifyContent="space-between" alignItems={'center'}>
            <Box flexGrow={1}>
              <Typography>Tags</Typography>
              <Box marginBottom={2}>
                <Input
                  value={search}
                  placeholder="Search tags..."
                  onChange={({ target }) => {
                    const { value } = target;

                    setSearch(value);
                  }}
                  onKeyUp={() => {
                    clearTimeout(interval);

                    interval = setTimeout(() => {
                      onChangeSearch(search);
                      setSearchedText(search);
                      if (search !== '') {
                        setSearching(true);
                      } else {
                        setSearching(false);
                      }
                    }, doneTypingInterval);
                  }}
                  onKeyDown={() => {
                    clearTimeout(interval);
                  }}
                />
                {searching && !loading && (
                  <>
                    <Divider />
                    <Box marginTop={2} marginBottom={2}>
                      <TextRecently>Search result</TextRecently>
                    </Box>
                    {searching && tagsRecently.length === 0 ? (
                      <Box marginBottom={2}>
                        <Typography>No tags with the name "{searchedText}" were found</Typography>
                      </Box>
                    ) : (
                      <>
                        {searching && selectedTags.length > 0 && tagsRecentlyFiltered.length === 0 && (
                          <Box marginBottom={2}>
                            <Typography>All the tags found were selected</Typography>
                          </Box>
                        )}
                      </>
                    )}
                    <Box width="100%" display="flex" style={{ flexWrap: 'wrap' }}>
                      {tagsRecentlyFiltered.map((tag) => (
                        <Tag
                          key={tag?._id}
                          text={tag?.tagName || tag?.name}
                          bg={tag?.backgroundColor}
                          color={tag?.textColor}
                          onClick={() => {
                            setSelectedTags((_tags) => [..._tags, tag]);
                          }}
                        />
                      ))}
                    </Box>
                  </>
                )}
                {selectedTags.length > 0 && (
                  <>
                    <Divider />
                    <Box marginTop={2} marginBottom={2}>
                      <TextRecently>Selected {selectedTags.length === 1 ? 'tag' : 'tags'}</TextRecently>
                    </Box>
                    <Box width="100%" display="flex" style={{ flexWrap: 'wrap' }}>
                      {selectedTags.map((tag) => (
                        <Tag
                          key={tag._id}
                          text={tag?.tagName || tag?.name}
                          bg={tag?.backgroundColor}
                          color={tag?.textColor}
                          canRemove
                          onClickRemove={() => {
                            setSelectedTags((_tags) => _tags.filter((_tag) => _tag?._id !== tag?._id));
                          }}
                        />
                      ))}
                    </Box>
                  </>
                )}
                {showErrorMessage && (
                  <Typography variant="body2" style={{ color: '#FC3A3A', marginTop: 8 }}>
                    Please select at least one tag to filter the attachments
                  </Typography>
                )}
              </Box>
            </Box>
            <Box flexGrow={1} textAlign={'right'}>
              Archived Only{' '}
              <CustomSwitch
                checked={archivedOnly}
                onChange={(e) => {
                  setArchivedOnly(e.target.checked);
                  onSubmitFilters(
                    selectedTags.map((tag) => tag?._id),
                    e.target.checked
                  );
                }}
              />
            </Box>
          </Box>
          <Divider />
          <Box display="flex" justifyContent="space-between" marginTop={2}>
            <ButtonLink
              onClick={() => {
                onSubmitFilters([]);
                setSelectedTags([]);
                setSearch('');
                onChangeSearch('');
                setArchivedOnly(false);
                setSearching(false);
                setShowErrorMessage(false);
              }}
            >
              Clear Filters
            </ButtonLink>
            <Box display="flex" alignItems="center">
              {!maintainAlwaysOpen && (
                <Button
                  onClick={() => {
                    setOpen(false);
                    onSubmitFilters([]);
                    setShowErrorMessage(false);
                  }}
                  style={{ textTransform: 'none' }}
                  autoCapitalize="none"
                >
                  Cancel
                </Button>
              )}
              <span />
            </Box>
          </Box>
        </Card>
      )}
    </div>
  );
};

TagsFilters.propTypes = {
  maintainAlwaysOpen: PropTypes.bool,
  onSubmitFilters: PropTypes.func.isRequired,
};

const ContainerButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
`;

const ButtonFilter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 47px;
  height: 47px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.25s ease;

  ${(props) =>
    props.open
      ? `
    background-color: #fff;
    border: 1px solid #2e66fe;
  `
      : `
    background-color: #2e66fe;
  `}
`;

const ButtonLink = styled.button`
  color: #2e66fe;
  font-size: 14px;
  font-weight: 500;
  border: 0;
  background-color: transparent;
  cursor: pointer;
`;

const Input = styled.input`
  font-family: Roboto;
  font-size: 16px;
  color: rgba(87, 90, 102, 1);
  background-color: #f8f8f9;
  margin-bottom: 8px;
  border: 0;
  width: 100%;
  padding: 16px;

  &:focus-visible,
  &:focus,
  &:active {
    border: 0;
  }
`;

const TextRecently = styled.p`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 4px;
  color: rgba(136, 141, 160, 1);
`;

export default TagsFilters;
