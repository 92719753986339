import { FormControl, InputLabel, Select } from '@material-ui/core';
import React from 'react';

export default function OutlineSelect({
  options = [],
  label,
  register = () => {},
  registerKey,
  registerOptions,
  required = true,
  hasValue = false,
}) {
  return (
    <FormControl style={{ width: '100%', marginBottom: 20 }}>
      <InputLabel htmlFor="age-native-simple">
        {label}{' '}
        {required ? (
          <span style={{ color: 'red', fontSize: 12 }}>*</span>
        ) : null}
      </InputLabel>
      <Select
        native
        {...register(registerKey, registerOptions)}
        defaultValue="Select an Option"
      >
        <option aria-label="None" value={null} disabled={hasValue}>
          Select an Option
        </option>
        {options?.map((option, index) => (
          <option
            value={option}
            key={index}
            style={{ textTransform: 'capitalize' }}
          >
            {option}
          </option>
        ))}
      </Select>
      {/* <span style={{ color: 'red', fontSize: 12, marginTop: 4 }}>
        {option.error}
      </span> */}
    </FormControl>
  );
}
