import React from 'react';
import styled from 'styled-components';

const PageWrapper = ({ children }) => {
  return <Container>{children}</Container>;
};

const Container = styled.div`
  width: 100%;
  padding: 20px;
`;

export default PageWrapper;
