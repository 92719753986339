/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

export const componentsName = {
  demographics: 'Demographics',
  singleChoice: 'Single Choice',
  multipleChoices: 'Multiple Choices',
  dropdown: 'Dropdown',
  yesNo: 'Yes/No',
  shortText: 'Short Text',
  longText: 'Long Text',
  addressInformation: 'Address Information',
  contactInformation: 'Contact Information',
  date: 'Date',
  dateOfBirth: 'Date Of Birth',
  email: 'Email',
  fileUpload: 'File',
  gender: 'Gender',
  hour: 'Hour',
  phone: 'Phone',
  url: 'Url',
};

const mapPropsToConfig = (config) => {
  const configWithProps = [];
  config.forEach((item) => {
    if (item.component) {
      const { component, ...props } = item;

      configWithProps.push({
        ...props,
        Component: component,
      });
    }
  });

  return configWithProps;
};

export const CURenderer = ({
  id,
  config,
  version,
  setForm,
  onSubmitForm,
  customFieldsValues,
  setIsRequestingData,
  setPagesValues = () => {},
}) => {
  // const [editComponents, setEditComponents] = useState({});

  if (!config) {
    throw new Error('You are calling Renderer with no config.');
  }

  const configWithProps = mapPropsToConfig(config);
  const renderComponents = (items) =>
    items.map((item, itemIndex) => {
      const { Component, ...props } = item;

      return (
        <Component
          id={id}
          version={version}
          setPagesValues={setPagesValues}
          onSubmitForm={onSubmitForm}
          setForm={setForm}
          withSave={false}
          componentIndex={itemIndex}
          customFieldsValues={customFieldsValues}
          setIsRequestingData={setIsRequestingData}
          {...props}
        />
      );
    });

  return renderComponents(configWithProps);
};
