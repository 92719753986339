/* eslint-disable no-nested-ternary */
import { Box, CircularProgress, Typography, makeStyles } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { Bar, Line, Pie } from 'react-chartjs-2';
import { useDeepCompareMemo } from 'use-deep-compare';
import { Table } from 'antd';
import Papa from 'papaparse';
import { exportComponentAsPNG } from 'react-component-export-image';
import moment from 'moment';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import 'antd/dist/antd.css';

import ContentHeader from './ContentHeader';
import { exportCSVFile } from '../../utils/convertJsonCsv';
import { convertHMS } from '../Projects/utils';
import ContentCharts, { chartsItems } from './ContentCharts';
import HTMLContent from '../../components/HTMLContent';
import { containsHTML } from '../../utils';
import UpgradeTabPopup from '../../components/UpgradeTabPopup';
import useOrganization from '../../hooks/useOrganization';
import useBuildingSchemaStatus from '../../hooks/useBuildingSchemaStatus';

const useStyles = makeStyles(() => ({
  container: {
    // '&::-webkit-scrollbar': {
    //   display: 'none',
    // },
  },
}));

const COLORS_SERIES = [
  '#5b8ff9',
  '#5ad8a6',
  '#5e7092',
  '#f6bd18',
  '#6f5efa',
  '#6ec8ec',
  '#945fb9',
  '#ff9845',
  '#299796',
  '#fe99c3',
];

const COLORS_BG_SERIES = [
  'rgba(91, 143, 249, 0.5)',
  'rgba(90, 216, 166, 0.5)',
  'rgba(94, 112, 146, 0.5)',
  'rgba(246, 189, 24, 0.5)',
  'rgba(111, 94, 250, 0.5)',
  'rgba(110, 200, 236, 0.5)',
  'rgba(148, 95, 185, 0.5)',
  'rgba(255, 152, 69, 0.5)',
  'rgba(41, 151, 150, 0.5)',
  'rgba(254, 153, 195, 0.5)',
];

const commonOptions = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    intersect: false,
  },
  plugins: {
    legend: {
      position: 'bottom',
    },
  },
  scales: {
    x: {
      ticks: {
        // autoSkip: true,
        padding: 12,
      },
    },
    y: {
      ticks: {
        precision: 0,
      },
    },
  },
};

const AreaChartRenderer = ({ resultSet }) => {
  const datasets = useDeepCompareMemo(
    () =>
      resultSet.series().map((s, index) => ({
        label: s.title,
        data: s.series.map((r) => r.value),
        borderColor: COLORS_SERIES[index],
        backgroundColor: COLORS_BG_SERIES[index],
        pointRadius: 1,
        tension: 0.1,
        pointHoverRadius: 1,
        borderWidth: 2,
        tickWidth: 1,
        fill: true,
      })),
    [resultSet]
  );
  const data = {
    labels: resultSet.categories().map((c) => c.x),
    datasets,
  };
  return <Line data={data} options={commonOptions} />;
};

const LineChartRenderer = ({ resultSet }) => {
  const datasets = useDeepCompareMemo(
    () =>
      resultSet.series().map((s, index) => ({
        label: s.title,
        data: s.series.map((r) => r.value),
        borderColor: COLORS_SERIES[index],
        pointRadius: 1,
        tension: 0.1,
        pointHoverRadius: 1,
        borderWidth: 2,
        tickWidth: 1,
        fill: false,
      })),
    [resultSet]
  );
  const data = {
    labels: resultSet.categories().map((c) => c.x),
    datasets,
  };
  return <Line type="line" data={data} options={commonOptions} />;
};

const BarChartRenderer = ({ resultSet, pivotConfig }) => {
  const datasets = useDeepCompareMemo(
    () =>
      resultSet.series().map((s, index) => ({
        label: s.title,
        data: s.series.map((r) => r.value),
        backgroundColor: COLORS_SERIES[index],
        fill: false,
      })),
    [resultSet]
  );
  const data = {
    labels: resultSet.categories().map((c) => c.x),
    datasets,
  };
  const options = {
    ...commonOptions,
    scales: {
      x: {
        ...commonOptions.scales.x,
        stacked: !(pivotConfig.x || []).includes('measures'),
      },
    },
  };
  return <Bar type="bar" data={data} options={options} />;
};

const renderChart = ({ reports, resultSet, error, pivotConfig, chartType }) => {
  if (error) {
    return <div>{error.toString()}</div>;
  }

  if (!resultSet) {
    return <div />;
  }

  if (chartType === 'Number') {
    return (
      <Box>
        {resultSet.seriesNames().map((s) => (
          <Typography variant="h4">{resultSet.totalRow()[s.key]}</Typography>
        ))}
      </Box>
    );
  }

  if (chartType === 'Area') {
    return <AreaChartRenderer resultSet={resultSet} />;
  }

  if (chartType === 'Table') {
    return (
      <Table
        style={{ minWidth: chartType === 'Table' ? '100%' : undefined }}
        pagination={false}
        columns={
          reports?.columns.map((column) => ({
            ...column,
            render: (text) => {
              if (containsHTML(text)) {
                return <HTMLContent content={text} />;
              }

              if (column.type === 'boolean') {
                if (text === 1) return 'Yes';
                if (text === 0) return 'No';
              }

              return text;
            },
          })) || []
        }
        dataSource={formatDateOnTable(reports?.data) || []}
      />
    );
  }

  if (chartType === 'Pie') {
    const data = {
      labels: resultSet.categories().map((c) => c.x),
      datasets: resultSet.series().map((s) => ({
        label: s.title,
        data: s.series.map((r) => r.value),
        backgroundColor: COLORS_SERIES,
        hoverBackgroundColor: COLORS_SERIES,
      })),
    };

    const pieOptions = {
      maintainAspectRatio: false,
      // interaction: {
      //   intersect: false,
      // },
      plugins: {
        legend: {
          position: 'bottom',
        },
      },
      // scales: {
      //   x: {
      //     ticks: {
      //       autoSkip: true,
      //       maxRotation: 0,
      //       padding: 12,
      //       minRotation: 0,
      //     },
      //   },
      // },
    };

    return <Pie type="pie" data={data} options={pieOptions} />;
  }

  if (chartType === 'Bar') {
    return <BarChartRenderer resultSet={resultSet} pivotConfig={pivotConfig} />;
  }

  if (chartType === 'Line') {
    return <LineChartRenderer resultSet={resultSet} />;
  }
};

const ChartRenderer = ({ reports, dimensions, chartType, resultSet, timeDimensions }) =>
  renderChart({
    reports,
    chartType,
    resultSet,
    pivotConfig: {
      x: [
        ...(dimensions || []),
        ...(timeDimensions?.[0] ? [`${timeDimensions?.[0]?.dimension}.${timeDimensions?.[0]?.granularity}`] : []),
      ],
      y: ['measures'],
      fillMissingDates: true,
      joinDateRange: false,
    },
  });

const formatDateValue = (date, format = 'MMMM DD, YYYY') => moment(date).format(format);

const formatUtcDateValue = (date, format = 'MMMM DD, YYYY') =>
  moment(date?.endsWith('Z') ? date : `${date}Z`).format(format);

const formatDateOnTable = (array) => {
  if (!Array.isArray(array)) {
    return;
  }
  const data = array.map((item) => {
    const objKeys = Object.keys(item);

    const itemCopy = { ...item };

    objKeys.forEach((objectKeyMapped) => {
      const currentKey = objectKeyMapped.split('.');
      const byKey = currentKey[currentKey.length - 1];

      if (byKey === 'day' || objectKeyMapped.includes('demographicBirthdate')) {
        itemCopy[objectKeyMapped] =
          itemCopy[objectKeyMapped] && formatDateValue(itemCopy[objectKeyMapped], 'MM/DD/YYYY');
      } else if (byKey === 'totalTime' || byKey === 'actionsTimespent') {
        itemCopy[objectKeyMapped] = convertHMS(itemCopy[objectKeyMapped]) || '00:00:00';
      } else if (byKey === 'year') {
        itemCopy[objectKeyMapped] = itemCopy[objectKeyMapped] && formatDateValue(itemCopy[objectKeyMapped], 'YYYY');
      } else if (byKey === 'month') {
        console.log('itemCopy[objectKeyMapped]', itemCopy[objectKeyMapped]);

        itemCopy[objectKeyMapped] = itemCopy[objectKeyMapped] && formatDateValue(itemCopy[objectKeyMapped], 'YYYY-MM');
      } else if (byKey === 'week') {
        itemCopy[objectKeyMapped] =
          itemCopy[objectKeyMapped] && formatDateValue(itemCopy[objectKeyMapped], 'YYYY-MM [W]w');
      } else if (
        objectKeyMapped.includes('createdat') ||
        objectKeyMapped.includes('closedAt') ||
        objectKeyMapped.includes('lastStatusDate') ||
        objectKeyMapped.includes('updatedat') ||
        objectKeyMapped.includes('demographicBirthdate') ||
        objectKeyMapped.includes('locationDatetime') ||
        objectKeyMapped.includes('recommendationsSubmitteddate') ||
        objectKeyMapped.includes('ProjectsRecommendations.recommendationsResponsedate') ||
        objectKeyMapped.includes('taskDueDateTime')
      ) {
        itemCopy[objectKeyMapped] = itemCopy[objectKeyMapped] && formatUtcDateValue(itemCopy[objectKeyMapped]);
      } else if (byKey === 'bradyList' || byKey === 'civilianFileAnonymously' || byKey === 'transferred') {
        itemCopy[objectKeyMapped] = itemCopy[objectKeyMapped]
          ? 'Yes'
          : itemCopy[objectKeyMapped] === null || itemCopy[objectKeyMapped] === undefined
          ? 'Not specified'
          : 'No';
      } else if (objectKeyMapped && objectKeyMapped.includes('date')) {
        const date = formatUtcDateValue(itemCopy[objectKeyMapped], 'MM/DD/YYYY');

        if (date !== 'Invalid date') {
          itemCopy[objectKeyMapped] = date;
        }
      }
    });

    return itemCopy;
  });

  return data;
};

// const _buildingSchemaEndDate = '2024-04-08T18:08:00.000Z';

export default function ReportContent({
  reports,
  resultSet,
  report,
  cubeQuery,
  timeDimensions,
  onClearReport,
  loading,
  reportId,
  prepareReport,
  handleRefetch,
}) {
  const [selectedChart, setSelected] = useState(chartsItems[4]);
  const [showPopUp, setShowPopUp] = useState(false);

  const refChart = useRef();
  const classes = useStyles();
  const { organization } = useOrganization();
  const [cubesBuilded] = useBuildingSchemaStatus(organization);

  useEffect(() => {
    if (organization && organization.buildingSchemaEndDate) {
      setShowPopUp(true);
    }
  }, [organization]);

  const onSelectOption = (type, name) => {
    if (type === 'png') {
      exportComponentAsPNG(refChart, { fileName: `${name}-${moment().format()}.png` });
    } else if (type === 'csv') {
      const dataWithCorrectDate = formatDateOnTable(reports?.data);
      const exportData = dataWithCorrectDate.map((data) => {
        const body = {};
        reports.columns.forEach((c) => {
          const title = c.title?.trim();

          if (containsHTML(data[c.key])) {
            const tempElement = document.createElement('div');
            tempElement.innerHTML = data[c.key];
            body[title] =
              tempElement.textContent.replace(/[\n]+/g, ' ')?.trim() ||
              tempElement.innerText.replace(/[\n]+/g, ' ')?.trim();
          } else {
            body[title] = data[c.key];
          }
        });
        return body;
      });
      const csv = Papa.unparse(exportData);
      exportCSVFile(null, null, name, csv);
    }
  };

  if (!organization) return null;

  return (
    <Box padding="23px" width="100%" height="calc(116.1vh - 64px)" style={{ overflowY: 'auto', position: 'relative' }}>
      {showPopUp && (
        <UpgradeTabPopup
          title={
            cubesBuilded
              ? 'The new fields has being added, click on reload'
              : 'Please wait while the new fields are being added'
          }
          buttonText="RELOAD"
          withText={false}
          icon={cubesBuilded ? <CheckCircleIcon htmlColor="green" fontSize="large" /> : <CircularProgress />}
          useAnchor={false}
          disabledButtton={!cubesBuilded}
          onClickButton={() => {
            handleRefetch();
            setShowPopUp(false);
          }}
        />
      )}
      <ContentHeader
        report={report}
        handleRefetch={handleRefetch}
        cubeQuery={cubeQuery}
        reportId={reportId}
        onClearReport={onClearReport}
        onSelectOption={onSelectOption}
        prepareReport={prepareReport}
      />
      {loading && <h1>Loading</h1>}
      <a id="downloadLink" />
      <ContentCharts selectedChart={selectedChart} setSelected={setSelected}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent={selectedChart.text === 'Table' ? 'flex-start' : 'center'}
          position="relative"
          width="100%"
          maxHeight={selectedChart.text === 'Table' ? 600 : 400}
          height={selectedChart.text === 'Table' ? 600 : 400}
          marginBottom="20px"
          marginTop="20px"
          margin="auto"
          ref={refChart}
          style={{
            overflow: selectedChart.text === 'Table' ? 'scroll' : 'hidden',
          }}
          className={classes.container}
        >
          {loading && (
            <Box
              position="absolute"
              width="100%"
              height="100%"
              style={{ background: '#ffffffa9' }}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <CircularProgress size={30} />
            </Box>
          )}
          {resultSet && (
            <ChartRenderer
              reports={reports}
              chartType={selectedChart.text}
              resultSet={resultSet}
              dimensions={cubeQuery?.dimensions}
              timeDimensions={timeDimensions}
              loading={loading}
            />
          )}
        </Box>
      </ContentCharts>

      <Box marginBottom="70px" />
    </Box>
  );
}
