/* eslint-disable no-prototype-builtins */
/* eslint-disable no-nested-ternary */
import React, { Fragment, useEffect, useState } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import { Box, IconButton, makeStyles, Tooltip, Typography } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import PropTypes from 'prop-types';

import MenuTable from '../../components/MenuTable';
import { onTranslate } from '../../services/unarmed';
import { formatPhoneNumber, checkValueType, createFormTypePageId } from '../../utils';
import formatTxt from '../../utils/formatText';
import { getAboutUsValue } from './AditionalDetails';
import AccordionWrap from '../../components/AccordionWrap';

const useStyles = makeStyles(() => ({
  formSection: {
    fontSize: 14,
    color: '#3B70FE',
    fontWeight: '500',
    margin: '20px 0',
  },
  label: {
    fontSize: 12,
    color: '#585858',
  },
  title: {
    width: '100%',
    fontWeight: '500',
    fontSize: '16',
  },
  subTitle: {
    width: '100%',
    fontWeight: '400',
    fontSize: '15',
  },
}));

export const getTypeText = (type) => {
  switch (type) {
    case 'basicInformation':
      return 'Basic Information';
    case 'demographics':
      return 'Demographics';
    case 'incidentExternalLinks':
      return 'Incident External Links';
    case 'statement':
      return 'Statement';
    case 'uploadDocument':
      return 'Attachments';
    case 'policeOrEmployee':
      return 'Name of Police Officer (if Known)';
    case 'witnesses':
      return 'Name of Witness(es) or Others Involved';
    case 'isEnglishPrimaryLanguage':
      return 'Is English your primary language?';
    case 'whyYouWantToThank':
      return 'Why would you like to thank this employee?';
    case 'howDidYouHearAboutUs':
      return 'How did you hear about us?';
    case 'whatBestDescribesYou':
      return 'What best describes you?';
    case 'firstName':
      return 'First Name';
    case 'email':
      return 'Email';
    case 'fileAnonymously':
      return 'Anonymously';
    case 'lastName':
      return 'Last Name';
    case 'street':
      return 'Street';
    case 'aptUnit':
      return 'Apt Unit#';
    case 'state':
      return 'State';
    case 'city':
      return 'City';
    case 'zipCode':
      return 'Zip Code';
    case 'phone':
      return 'Home/Cell Telephone';
    case 'relationship':
      return 'Relationship';
    case 'badgeNumber':
      return 'Badge Number';
    case 'officerDescription':
      return 'Officer Description';
    case 'dateOfBirth':
      return 'Date Of Birth';
    case 'gender':
      return 'Gender';
    case 'raceEthnicity':
      return 'Race/Ethnicity';
    case 'race':
      return 'Race';
    case 'ethnicity':
      return 'Ethnicity';
    case 'sexualOrientation':
      return 'Sexual Orientation';
    case 'birthdate':
      return 'Birthday';
    case 'workTelephone':
      return 'Work Telephone';
    default:
      return 'Not support';
  }
};

export const getDemographicsLabel = (index) => {
  if (index === 0) {
    return 'dateOfBirth';
  }
  if (index === 1) {
    return 'gender';
  }
  if (index === 2) {
    return 'raceEthnicity';
  }
  if (index === 3) {
    return 'race';
  }
  if (index === 4) {
    return 'ethnicity';
  }

  return 'sexual';
};

const localeLan = ['English'];

export default function FormTypeJson({ formType, onSetupFiles, external, onClickEditPage }) {
  const classes = useStyles();
  const [textToTranslate, setTextToTranslate] = useState([]);

  useEffect(() => {
    if (formType) {
      const textTranslate = [];
      formType.pages.forEach((page) => {
        page.sections.forEach((section) => {
          section.components.forEach((component, index) => {
            if (component.type === 'longText' || component.data.type === 'statement') {
              textTranslate[index] = {
                text: '',
                translateState: '',
                options: localeLan,
              };
            }
          });
        });
      });
      setTextToTranslate(textTranslate);
    }
  }, [formType]);

  const onSelect = (index, valueToTranslate, value) => {
    if (value === 'Show Original') {
      const translation = Array.from(textToTranslate);
      translation[index] = { text: '', translateState: '', options: localeLan };
      setTextToTranslate(translation);
      return;
    }
    onTranslate(valueToTranslate).then(({ translations }) => {
      const translation = Array.from(textToTranslate);
      translation[index] = {
        text: translations?.[0],
        translateState: value,
        options: ['Show Original'],
      };
      setTextToTranslate(translation);
    });
  };

  const getParentLabel = (component, text = null) => (
    <Box style={{ width: '100%', margin: '10px 0' }}>
      <Typography variant="body2" style={{ fontSize: 14, fontWeight: '500', color: '#3B70FE' }}>
        {component?.data?.question ||
          getTypeText(text || (component.type === 'questionBank' ? component.data.type : component.type))}{' '}
      </Typography>
    </Box>
  );

  const renderTranslate = (componentIndex, componentValue) => (
    <MenuTable
      allowChildren
      options={textToTranslate?.[componentIndex]?.options}
      buttonPadding={false}
      onSelect={(value) => onSelect(componentIndex, componentValue, value)}
    >
      <Box
        display="flex"
        style={{ background: '#6D73850D' }}
        padding="10px 15px"
        minWidth="147px"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography
          style={{
            color: '#000000AD',
            fontSize: 14,
            textTransform: 'capitalize',
            marginRight: 15,
          }}
        >
          {textToTranslate?.[componentIndex]?.translateState === 'Show Original' ||
          !textToTranslate?.[componentIndex]?.translateState
            ? 'Translate To'
            : textToTranslate?.[componentIndex]?.translateState}
        </Typography>

        <ArrowDropDownIcon htmlColor="#000000AD" />
      </Box>
    </MenuTable>
  );

  const renderContent = () =>
    formType?.pages.map((page, pageIndex) => {
      const pageId = createFormTypePageId(page.name, pageIndex);

      return (
        <Box
          key={pageIndex}
          style={{
            background: '#fff',
            borderRadius: 5,
            marginBottom: 24,
          }}
          boxShadow="0 3px 6px #00000005"
        >
          <AccordionWrap
            id={pageId}
            dataTestId={`case-details-accordion-${pageId}`}
            header={
              <Box
                paddingX={3}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                borderBottom="1px solid #e2e2e2"
              >
                <Typography
                  variant="h5"
                  style={{
                    fontWeight: '500',
                    fontSize: 16,
                    color: '#575A66',
                    padding: '11px 0px',
                  }}
                >
                  {page.name}
                </Typography>
                {onClickEditPage && (
                  <IconButton
                    size="small"
                    dataTestId={`case-details-accordion-button-edit-${pageId}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (onClickEditPage) {
                        onClickEditPage(page, pageIndex);
                      }
                    }}
                  >
                    <EditIcon htmlColor="#6d7385" />
                  </IconButton>
                )}
              </Box>
            }
          >
            <Box>
              {page.sections.map((section, sectionIndex) => (
                <Box key={`sectionIndex-${sectionIndex}`}>
                  <Box className={classes.sectionDivider}>
                    <Typography
                      variant="body2"
                      style={{
                        fontWeight: '500',
                        marginBottom: 20,
                        marginTop: 20,
                        fontSize: 16,
                      }}
                    >
                      {section.name}
                    </Typography>
                  </Box>
                  {external?.cityBySivil && sectionIndex === 0 && (
                    <Box
                      style={{
                        background: '#fff',
                        borderRadius: 5,
                        marginBottom: 20,
                      }}
                    >
                      <Typography
                        variant="body2"
                        style={{ fontSize: 14, fontWeight: '500', color: '#3B70FE', marginBottom: 5 }}
                      >
                        City
                      </Typography>
                      <Typography variant="body1" style={{ marginBottom: 10, fontSize: 14 }}>
                        {external?.cityBySivil?.organization?.city || 'N/A'}
                      </Typography>
                      <Typography
                        variant="body2"
                        style={{ fontSize: 14, fontWeight: '500', color: '#3B70FE', marginBottom: 5 }}
                      >
                        Department
                      </Typography>
                      <Typography variant="body1" style={{ marginBottom: 10, fontSize: 14 }}>
                        {external?.cityBySivil?.department?.name || 'N/A'}
                      </Typography>
                      <Typography
                        variant="body2"
                        style={{ fontSize: 14, fontWeight: '500', color: '#3B70FE', marginBottom: 5 }}
                      >
                        Location
                      </Typography>
                      <Typography variant="body1" style={{ marginBottom: 10, fontSize: 14 }}>
                        {external?.cityBySivil?.place?.name || 'N/A'}
                      </Typography>
                    </Box>
                  )}
                  <Box display="flex" alignItems="center" flexWrap="wrap">
                    {section.components.map((component, componentIndex) => {
                      if (component.type === 'title') {
                        return (
                          <Typography variant="body2" className={classes.title} key={componentIndex}>
                            {component?.data?.text}
                          </Typography>
                        );
                      }
                      if (component.type === 'subtitle') {
                        return (
                          <Typography variant="body2" className={classes.subTitle} key={componentIndex}>
                            {component?.data?.text}
                          </Typography>
                        );
                      }
                      if (component.value === null) {
                        return (
                          <Fragment key={componentIndex}>
                            {getParentLabel(component)}
                            <Box
                              width="100%"
                              maxWidth="150px"
                              marginRight="20px"
                              marginBottom="10px"
                              key={`component-${componentIndex}`}
                            >
                              <Typography variant="subtitle">Not Specified</Typography>
                            </Box>
                          </Fragment>
                        );
                      }
                      if (Array.isArray(component.value)) {
                        if (
                          component.value &&
                          Array.isArray(component?.value[0]) &&
                          component?.value[0]?.every((file) => file?.hasOwnProperty('url'))
                        ) {
                          return (
                            <>
                              {component?.value[0]?.map((file, fileIndex) => (
                                <Fragment key={fileIndex}>
                                  {getParentLabel(component)}
                                  <Box
                                    width="100%"
                                    maxWidth="150px"
                                    marginRight="20px"
                                    marginBottom="10px"
                                    key={`component-${fileIndex}`}
                                  >
                                    <Typography variant="body2" className={classes.label}>
                                      File - {fileIndex + 1}
                                    </Typography>

                                    <Typography variant="subtitle">
                                      {formatTxt(file?.name, 14) || 'Not Specified'}
                                    </Typography>
                                  </Box>
                                </Fragment>
                              ))}
                            </>
                          );
                        }

                        if (!component.value[0] && component.value?.length === 1) {
                          return (
                            <Fragment key={`component-${componentIndex}`}>
                              {getParentLabel(component)}
                              <Box width="100%" maxWidth="150px" marginRight="20px" marginBottom="10px">
                                <Tooltip>
                                  <Typography variant="subtitle">Not Specified</Typography>
                                </Tooltip>
                              </Box>
                            </Fragment>
                          );
                        }

                        return component.value.map((objKey, objIndex) => {
                          if (Array.isArray(objKey)) {
                            if (objKey[0]?.hasOwnProperty('firstName')) {
                              return objKey.map((obKey, objIndex2) => {
                                const keysObj = Object.keys(obKey);

                                return (
                                  <Fragment key={objIndex2}>
                                    {getParentLabel(component)}
                                    {keysObj.map((key, idx) => (
                                      <Box
                                        width="100%"
                                        maxWidth="150px"
                                        marginRight="20px"
                                        marginBottom="10px"
                                        key={`component-${idx}`}
                                      >
                                        <Typography variant="body2" className={classes.label}>
                                          {getTypeText(key)}
                                        </Typography>
                                        <Tooltip title={obKey[key]}>
                                          <Typography variant="subtitle">
                                            {formatPhoneNumber(obKey[key]) || 'Not Specified'}
                                          </Typography>
                                        </Tooltip>
                                      </Box>
                                    ))}
                                  </Fragment>
                                );
                              });
                            }

                            if (objKey?.[objIndex]?.hasOwnProperty('download_url')) {
                              return objKey.map((obKey, objIndex2) => (
                                <Fragment key={objIndex2}>
                                  {getParentLabel(component)}
                                  <Box
                                    width="100%"
                                    maxWidth="150px"
                                    marginRight="20px"
                                    marginBottom="10px"
                                    key={`component-${objIndex2}`}
                                  >
                                    <Typography variant="body2" className={classes.label}>
                                      File - {objIndex2 + 1}
                                    </Typography>

                                    <Typography variant="subtitle">
                                      {formatTxt(obKey?.name, 14) || 'Not Specified'}
                                    </Typography>
                                  </Box>
                                </Fragment>
                              ));
                            }
                            return (
                              <>
                                {getParentLabel(
                                  component,
                                  component?.data?.type === 'demographics' && getDemographicsLabel(objIndex)
                                )}
                                <Box
                                  width="100%"
                                  maxWidth={component?.data?.type === 'incidentExternalLinks' ? '100%' : '150px'}
                                  marginRight="20px"
                                  marginBottom="10px"
                                  style={
                                    component?.data?.type === 'incidentExternalLinks'
                                      ? { overflowWrap: 'break-word' }
                                      : {}
                                  }
                                  key={`component-${objIndex}`}
                                >
                                  {component.data.type === 'howDidYouHearAboutUs' ? (
                                    <Tooltip title={objKey}>
                                      <Typography variant="subtitle">
                                        {objKey.map((key) => getAboutUsValue(key)).join(', ') || 'Not Specified'}
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <Tooltip title={objKey}>
                                      <Typography variant="subtitle">{objKey.join(', ') || 'Not Specified'}</Typography>
                                    </Tooltip>
                                  )}
                                </Box>
                              </>
                            );
                          }

                          if (!objKey) {
                            return (
                              <>
                                {getParentLabel(
                                  component,
                                  component?.data?.type === 'demographics' && getDemographicsLabel(objIndex)
                                )}
                                <Box
                                  width="100%"
                                  maxWidth="150px"
                                  marginRight="20px"
                                  marginBottom="10px"
                                  key={`component-${objIndex}`}
                                >
                                  <Tooltip title={objKey}>
                                    <Typography variant="subtitle">Not Specified</Typography>
                                  </Tooltip>
                                </Box>
                              </>
                            );
                          }

                          if (component.type === 'fileUpload') {
                            onSetupFiles(component.value);
                            return (
                              <>
                                {objIndex === 0 && getParentLabel(component)}
                                <Box
                                  width="100%"
                                  maxWidth="150px"
                                  marginRight="20px"
                                  marginBottom="10px"
                                  key={`objIndex-${objIndex}`}
                                >
                                  <Typography variant="body2" className={classes.label}>
                                    File-{objIndex + 1}
                                  </Typography>
                                  <Tooltip title={objKey?.name}>
                                    <Typography variant="subtitle">{formatTxt(objKey?.name, 14)}</Typography>
                                  </Tooltip>
                                </Box>
                              </>
                            );
                          }

                          if (component.type === 'multipleChoices' || component.type === 'dropdown') {
                            return (
                              <>
                                {objIndex === 0 && getParentLabel(component)}
                                <Box
                                  width="100%"
                                  maxWidth="150px"
                                  marginRight="20px"
                                  marginBottom="10px"
                                  key={`objIndex-${objIndex}`}
                                >
                                  <Tooltip title={objKey}>
                                    <Typography variant="subtitle">
                                      {(objIndex === 0 && component.value.join(', ')) || ''}
                                    </Typography>
                                  </Tooltip>
                                </Box>
                              </>
                            );
                          }

                          if (typeof objKey === 'object') {
                            const objKeysFromArray = Object.keys(objKey);

                            return (
                              <>
                                {getParentLabel(component)}
                                {objKeysFromArray.map((keyFromArray, indexFromArray) => {
                                  // Hide fileAnonymously if its null
                                  if (keyFromArray === 'fileAnonymously' && objKey[keyFromArray] === null) {
                                    return null;
                                  }

                                  let textValue = objKey[keyFromArray]
                                    ? formatTxt(objKey[keyFromArray], 25)
                                    : 'Not specified';

                                  if (keyFromArray?.toLowerCase()?.includes('phone')) {
                                    textValue = objKey[keyFromArray]
                                      ? formatPhoneNumber(objKey[keyFromArray])
                                      : 'Not specified';
                                  }

                                  if (keyFromArray === 'fileAnonymously') {
                                    textValue = objKey[keyFromArray] ? 'Yes' : 'No';
                                  }

                                  return (
                                    <Box
                                      width="100%"
                                      maxWidth="190px"
                                      marginRight="20px"
                                      marginBottom="10px"
                                      key={`component-${indexFromArray}`}
                                    >
                                      <Typography variant="body2" className={classes.label}>
                                        {getTypeText(keyFromArray)}
                                      </Typography>
                                      <Tooltip title={objKey[keyFromArray]}>
                                        <Typography variant="subtitle">{textValue}</Typography>
                                      </Tooltip>
                                    </Box>
                                  );
                                })}
                              </>
                            );
                          }

                          return (
                            <>
                              {component?.data?.type === 'statement' ? (
                                <Box
                                  display="flex"
                                  justifyContent="space-between"
                                  alignItems="center"
                                  width="100%"
                                  marginBottom="10px"
                                >
                                  {getParentLabel(component, component?.data?.type === 'demographics' && 'dateOfBirth')}
                                  {renderTranslate(componentIndex, objKey)}
                                </Box>
                              ) : (
                                getParentLabel(component, component?.data?.type === 'demographics' && 'dateOfBirth')
                              )}
                              <Box
                                width="100%"
                                maxWidth={
                                  component?.data?.type === 'statement' || component?.type === 'url' ? '100%' : '150px'
                                }
                                marginRight="20px"
                                marginBottom="10px"
                                key={`component-${objIndex}`}
                              >
                                {component?.data?.type === 'statement' ? (
                                  <Typography variant="subtitle">
                                    {textToTranslate?.[componentIndex]?.text
                                      ? textToTranslate?.[componentIndex]?.text
                                      : checkValueType(objKey, component?.data?.type) || 'Not Specified'}
                                  </Typography>
                                ) : (
                                  <Tooltip title={objKey}>
                                    {component?.data?.type === 'isEnglishPrimaryLanguage' ? (
                                      <Typography variant="subtitle">
                                        {formatTxt(objKey !== 'yes' ? `No, ${objKey}` : 'Yes') || 'Not Specified'}
                                      </Typography>
                                    ) : (
                                      <Typography variant="subtitle">
                                        {formatTxt(checkValueType(objKey, component?.data?.type)) || 'Not Specified'}
                                      </Typography>
                                    )}
                                  </Tooltip>
                                )}
                              </Box>
                            </>
                          );
                        });
                      }
                      if (typeof component.value === 'object') {
                        const objectData = component.data;
                        const objKeys = Object.keys(component.value).filter((key) => {
                          if (key === 'fileAnonymously') {
                            return objectData.showFileAnonymously;
                          }
                          return objectData[key]?.visible;
                        });

                        return (
                          <Fragment key={`objIndex-${componentIndex}`}>
                            {getParentLabel(component)}
                            {objKeys.map((objKey, objIndex) => (
                              <Box
                                width="100%"
                                maxWidth="150px"
                                marginRight="20px"
                                marginBottom="10px"
                                alignItems="center"
                                key={`objIndex-${objIndex}`}
                              >
                                <Typography variant="body2" className={classes.label}>
                                  {objectData[objKey]?.question ||
                                    objectData[objKey]?.text ||
                                    getTypeText(objKey) ||
                                    objKey}
                                </Typography>
                                {objKey === 'fileAnonymously' ? (
                                  <Typography variant="subtitle">
                                    {component.value[objKey] === true ? 'Yes' : 'No' || 'Not Specified'}
                                  </Typography>
                                ) : objKey === 'city' || objKey === 'state' ? (
                                  <Typography variant="subtitle">
                                    {formatPhoneNumber(formatTxt(component.value[objKey]?.name, 18)) || 'Not Specified'}
                                  </Typography>
                                ) : (
                                  <Tooltip title={component.value[objKey]}>
                                    <Typography variant="subtitle">
                                      {formatPhoneNumber(formatTxt(component.value[objKey]?.name, 18)) ||
                                        formatPhoneNumber(
                                          formatTxt(component.value[objKey], 18),
                                          component.value[objKey]
                                        ) ||
                                        'Not Specified'}
                                    </Typography>
                                  </Tooltip>
                                )}
                              </Box>
                            ))}
                          </Fragment>
                        );
                      }

                      return (
                        <>
                          {component?.type === 'longText' ? (
                            <Box
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                              width="100%"
                              marginBottom="10px"
                            >
                              {getParentLabel(component)}
                              {renderTranslate(componentIndex, component?.value)}
                            </Box>
                          ) : (
                            getParentLabel(component)
                          )}
                          <Box
                            width="100%"
                            maxWidth={
                              component?.type === 'longText' || component?.type === 'shortText' ? '100%' : '150px'
                            }
                            marginRight="20px"
                            marginBottom="10px"
                            alignItems="center"
                            key={`component-${componentIndex}`}
                          >
                            {component?.type === 'longText' ? (
                              <Typography variant="subtitle">
                                {textToTranslate?.[componentIndex]?.text
                                  ? textToTranslate?.[componentIndex]?.text
                                  : checkValueType(component?.value) || 'Not Specified'}
                              </Typography>
                            ) : (
                              <Tooltip title={component?.value}>
                                <Typography variant="subtitle">
                                  {checkValueType(component?.value, component?.type) || 'Not Specified'}
                                </Typography>
                              </Tooltip>
                            )}
                          </Box>
                        </>
                      );
                    })}
                  </Box>
                </Box>
              ))}
            </Box>
          </AccordionWrap>
        </Box>
      );
    });
  return <>{renderContent()} </>;
}

FormTypeJson.propTypes = {
  formType: PropTypes.object,
  onSetupFiles: PropTypes.func,
  external: PropTypes.object,
  onClickEditPage: PropTypes.func,
};
