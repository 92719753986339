import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import EnhancedTable from './Table';

const TableContainer = ({ data, columns, loading, onRenderRow, onChangeSort, showCheckBox }) => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selected, setSelected] = React.useState([]);
  const [checked, setChecked] = useState(false);
  const [sortBy, setSortBy] = useState('createdAt');
  const [sort, setSort] = useState('desc');

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(1);
  };

  useEffect(() => {
    if (onChangeSort) {
      onChangeSort(sort, sortBy);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, sortBy]);

  return (
    <EnhancedTable
      sort={sort}
      setSort={setSort}
      columns={columns}
      rows={data}
      showCheckBox={showCheckBox}
      setSortBy={setSortBy}
      rowRender={onRenderRow}
      setChecked={setChecked}
      checked={checked}
      loading={loading}
      page={page}
      setPage={setPage}
      selected={selected}
      setSelected={setSelected}
      count={data.length}
      rowsPerPage={rowsPerPage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      rowsPerPageOptions={[10, 15, 20, 50, 100]}
    />
  );
};

TableContainer.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  onRenderRow: PropTypes.func.isRequired,
  showCheckBox: PropTypes.bool,
  onChangeSort: PropTypes.func,
};

export default TableContainer;
