import React, { useEffect, useState, useCallback, useContext } from 'react';
import {
  Box,
  Checkbox,
  Modal,
  TableCell,
  TableRow,
  Typography,
  makeStyles,
  Backdrop,
  CircularProgress,
  Fade,
  Tooltip,
  Popover,
  IconButton,
  List,
  ListItem,
  Divider,
  TextField,
  InputAdornment
} from '@material-ui/core';
import { toast } from 'react-toastify';
import { Context } from '../../Context';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import PageWrapper from '../../components/PageWrapper';
import formatTxt from '../../utils/formatText';
import { deleteContacts, fetchContacts, editMultipleContacts, updateViewsById } from '../../services/unarmed';
import { StyledButtonOutlined } from '../../styles/GlobalStyles';
import ContactsFilters from '../../components/ContactsFilters';
import Flagged from '../../assets/flagged.png';
import useInput from '../../hooks/useInput';
import ModalCreateContact, { parseContactPhoneNumber } from './ModalCreateContact';
import UpgradeModal from '../../components/UpgradeModal';
import useOrganization from '../../hooks/useOrganization';
import { formatPhoneNumber } from '../../utils';
import useFiltersConfig from '../../hooks/useFiltersConfig';
import { useTabsUtil } from '../../hooks/useTabs';
import { TYPE_USER_TABS } from '../../utils/constants';
import TabsModules from '../../components/TabsModules';
import { ViewTabContacts } from './ViewTabContacts';

/**
 * Material-UI styling hook.
 */
const useStyles = makeStyles((theme) => ({
  row: {
    cursor: 'pointer',
    height: 50,
    maxHeight: 50,
    background: '#fff',
    '&:hover': {
      backgroundColor: '#f5f5f5',
      '& $externalIcon': {
        visibility: 'visible',
        color: '#1F63FF',
      },
    },
  },
  externalIcon: {
    visibility: 'hidden',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: '#fff',
    boxShadow: theme.shadows[5],
    padding: 30,
    maxWidth: 600,
    outline: 0,
  },
}));

/**
 * Functional component for managing and displaying contacts.
 *
 * @component
 */
const Contacts = () => {
  const classes = useStyles();

  const { authUser } = useContext(Context);
  const [openFilter, setOpenFilter] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selected, setSelected] = React.useState([]);
  const [checked, setChecked] = useState(false);
  const [sortBy, setSortBy] = useState('createdAt');
  const [sort, setSort] = useState('desc');
  const [loading, setLoading] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [loadingFilters, setLoadingFilters] = useState(false);
  const [open, setOpen] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const { organization } = useOrganization();
  const baseColumns = {
    flag: true,
    name: true,
    pronouns: false,
    type: false,
    companyName: true,
    phoneNumber: true,
    email: true,
    module: true,
    createdAt: true,
    demographic: false
  }
  const [loadingEditContacts, setLoadingEditContacts] = useState(false);
  const [visibleColumns, setVisibleColumns] = useState(baseColumns || {});
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  const filteredColumns = Object.keys(visibleColumns).filter((column) =>
    column.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const showColumns = filteredColumns.filter((column) => visibleColumns[column]);
  const hideColumns = filteredColumns.filter((column) => !visibleColumns[column]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);
  const popoverId = open ? 'column-popover' : undefined;


  const {
    filtersConfig,
    filterCount,
    filtersLoaded,
    filtersApplied,
    resetFilterCount,
    setFiltersLoaded,
    fetchFiltersConfig,
  } = useFiltersConfig('contacts');

  const {
    activeTab,
    setActiveTab,
    tabSelected,
    setTabSelected,
    onGetUserTabs,
    userTabs,
    updateNameTab,
    setUserTabs,
    handleSaveTab,
    handleTabSelection,
    nextPosition,
    handleTabsReorder,
    handleTabSelectionAction,
    deleteSelectedTab,
    activeTabName,
    setActiveTabName,
} = useTabsUtil({ moduleName: 'CONTACTS' });

  const history = useHistory();
  const name = useInput('');
  const phoneNumber = useInput('');
  const email = useInput('');
  const module = useInput('');
  const type = useInput('');
  const flaggedOnly = useInput(false, false, false, true);

  /**
   * Checks if a contact is selected.
   *
   * @param {string} _name - The name of the contact.
   * @returns {boolean} Whether the contact is selected.
   */
  const isSelected = (_name) => selected.indexOf(_name) !== -1;

  /**
   * Navigates to the contact profile page.
   *
   * @param {Event} e - The click event.
   * @param {Object} row - The contact data.
   * @param {Function} handleClick - The function to handle row click.
   */
  const goToContactProfile = (e, row, handleClick) => {
    const go = handleClick(e, row._id);

    if (go) {
      history.push(`/contacts/${row._id}`);
    }
  };

  /**
   * Renders a row for the contacts table.
   *
   * @param {Object} row - The contact data.
   * @param {number} index - The index of the row.
   * @param {Function} handleClick - The function to handle row click.
   * @returns {JSX.Element} The rendered row.
   */
  const renderRow = (row, index, handleClick) => {
    const isItemSelected = isSelected(row._id);
    const labelId = `enhanced-table-checkbox-${index}`;

    /**
     * Gets the module(s) of the contact.
     *
     * @returns {string} The contact module(s).
     */
    const getContactModule = () => {
      const modules = [...(row.modules.cases ? ['Cases'] : []), ...(row.modules.projects ? ['Projects'] : [])];

      return modules.length > 0 ? modules.join(', ') : 'Not specified';
    };

    return (
      <TableRow
        hover
        aria-checked={isItemSelected}
        selected={isItemSelected}
        role="checkbox"
        tabIndex={-1}
        key={index}
        className={classes.row}
        onClick={(e) => goToContactProfile(e, row, handleClick)}
      >
        <TableCell padding="checkbox">
          <Checkbox checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} color="primary" />
        </TableCell>
        {visibleColumns?.flag && (
          <TableCell component="th" scope="row" align="left">
            {row?.flagged && <img src={Flagged} alt="Flagged" style={{ width: '20px', height: '20px' }} />}
          </TableCell>
        )}
        {visibleColumns?.name && (
        <TableCell align="left" style={row.firstName ? { textTransform: 'capitalize' } : {}}>
          {row.firstName ? `${formatTxt(row?.firstName, 25)} ${formatTxt(row?.lastName, 25)}` : 'Not specified'}
        </TableCell>
        )}
        {visibleColumns?.pronouns && (
          <TableCell align="left">{row.pronouns ? row.pronouns : 'Not specified'}</TableCell>
        )}
        {visibleColumns?.type && (
          <TableCell align="left">{row.type ? row.type : 'Not specified'}</TableCell>
        )}
        {visibleColumns?.companyName && (
          <TableCell align="left">{row.companyName ? row.companyName : 'Not specified'}</TableCell>
        )}
        {visibleColumns?.phoneNumber && (
          <TableCell align="left">{formatPhoneNumber(row.phoneNumber) || 'Not specified'}</TableCell>
        )}
        {visibleColumns?.email && (
          <TableCell align="left">{row.email || 'Not specified'}</TableCell>
        )}
        {visibleColumns?.module && (
          <TableCell align="left">{getContactModule()}</TableCell>
        )}
        {visibleColumns?.createdAt && (
          <TableCell align="left">{moment(row.createdAt).format('MMMM DD, YYYY')}</TableCell>
        )}
      </TableRow>
    );
  };

  /**
   * Handles the change of rows per page.
   *
   * @param {Event} event - The change event.
   */
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(1);
  };

  const handleOnFetchAllContacts = useCallback(() => {
    setLoading(true);
    /*fetchContacts({
      page,
      limit: rowsPerPage,
      sortBy: 'createdAt',
      sortOrder: 'desc',
      ...(filtersConfig ? { filtersConfigId: filtersConfig?._id } : {}),
    }).then((res) => {
      setLoading(false);
      setContacts(res);
      fetchFiltersConfig();
    });*/
  }, [page, rowsPerPage, filtersConfig]);

  /**
   * Deletes selected contacts.
   */
  const onDeleteContact = async () => {
    let ids = '';

    for (const id of selected) {
      const index = selected.indexOf(id);
      if (index + 1 === selected.length) {
        ids += `ids[]=${id}`;
      } else {
        ids += `ids[]=${id}&`;
      }
    }

    try {
      setLoadingDelete(true);
      const response = await deleteContacts(ids);
      handleOnFetchAllContacts();
      setLoadingDelete(false);
      setOpen(false);
      setSelected([]);
      setChecked(false);
      toast.success('Contacts deleted Successfully');

      if (response && response.data && response.data.idsNotDeleted) {
        const { idsNotDeleted } = response.data;
        const contactNotDeleted = [];

        if (contacts && contacts.data) {
          contacts.data.forEach((contact) => {
            if (idsNotDeleted.indexOf(contact?._id) !== -1) {
              contactNotDeleted.push(contact);
            }
          });
        }

        if (contactNotDeleted.length > 0) {
          const contactsNames = contactNotDeleted.map((contact) => `${contact.firstName} ${contact.lastName}`);

          toast.error(
            `${contactsNames.join(', ')} cannot be deleted because there are assigned to a project or a case.`
          );
        }
      }
    } catch (error) {
      setLoadingDelete(false);
      toast.error(error?.response?.data?.message);
    }
  };

  const handleOnClickDownload = () => {
    setOpenCreate(true);
  };

  /**
   * Handles the click event for clearing filters.
   */
  const handleOnClickClearFilters = () => {
    name.setValue(null);
    email.setValue(null);
    phoneNumber.setValue(null);
    module.setValue(null);
    type.setValue('');
    flaggedOnly.setValue(false);
    resetFilterCount();
    //handleOnFetchAllContacts();
    toast.success('Successfully cleared filters!')
  };

  const handleToggleColumn = async (viewId, columnName) => {
    try {
      const updatedColumns = {
        ...visibleColumns,
        [columnName]: !visibleColumns[columnName],
      };

      const response = await updateViewsById(viewId, { columns: updatedColumns });

      if (response && response.status === 200 && response.view) {
        setVisibleColumns(response.view.columns);
      } else {
        console.error('Failed to update columns:', response);
      }
    } catch (error) {
      console.error('Error updating column visibility:', error);
    }
  };

  const formatHeadersText = (str) => {
    const result = str.replace(/([A-Z])/g, ' $1');

    return result
      .replace(/^./, (firstChar) => firstChar.toUpperCase())
      .trim();
  };

  const onEditMultipleContacts = async () => {
    let ids = '';
    const selectedContacts = contacts.data.filter((contact) => selected.includes(contact._id));
    const allAreFlagged = selectedContacts.every((contact) => contact.flagged);

    for (const id of selected) {
      const index = selected.indexOf(id);
      if (index + 1 === selected.length) {
        ids += `ids[]=${id}`;
      } else {
        ids += `ids[]=${id}&`;
      }
    }

    try {
      setLoadingEditContacts(true);
      await editMultipleContacts(ids);

      setContacts((prevContacts) => {
        const updatedData = prevContacts.data.map((contact) => {
          if (selected.includes(contact._id)) {
            return {
              ...contact,
              flagged: allAreFlagged ? false : true,
            };
          }
          return contact;
        });

        return {
          ...prevContacts,
          data: updatedData,
        };
      });

      setLoadingEditContacts(false);
      setSelected([]);
      toast.success(allAreFlagged ? 'Successfully unflagged contacts' : 'Successfully flagged contacts');
    } catch (error) {
      setLoadingEditContacts(false);
      toast.error(error.message);
    }
  };

  const getVisibleColumns = () => {
    return Object.keys(visibleColumns).filter((column) => visibleColumns[column]).map((column) => formatHeadersText(column));
  };

  return (
    <>
      {organization && <UpgradeModal open={!organization?.features?.dashboard?.contacts?.enabled} />}
      <PageWrapper>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h5">Contacts</Typography>
        </Box>

        <TabsModules
          setTabs={setUserTabs}
          updateNameTab={updateNameTab}
          tabSelected={tabSelected}
          activeTabName={activeTabName}
          activeTab={activeTab}
          setTabSelected={setTabSelected}
          setActiveTab={setActiveTab}
          setActiveTabName={setActiveTabName}
          tabs={userTabs}
          navigateToDataPage={() => {}}
          saveTab={handleSaveTab}
          onTabSelect={handleTabSelection}
          nextPosition={nextPosition}
          saveTabPositions={handleTabsReorder}
          onTabAction={handleTabSelectionAction}
          deleteTab={deleteSelectedTab}
          moduleName={'CONTACTS'}
        />

        {activeTab === TYPE_USER_TABS.MAIN && (
          <ViewTabContacts
            sort={sort}
            sortBy={sortBy}
            parseContactPhoneNumber={parseContactPhoneNumber}
            setContacts={setContacts}
            filtersLoaded={filtersLoaded}
            setFiltersLoaded={setFiltersLoaded}
            loadingFilters={loadingFilters}
            setOpenFilter={setOpenFilter}
            anchorEl={anchorEl}
            authUser={authUser}
            checked={checked}
            classes={classes}
            name={name}
            phoneNumber={phoneNumber}
            email={email}
            module={module}
            flaggedOnly={flaggedOnly}
            setLoadingFilters={setLoadingFilters}
            fetchFiltersConfig={fetchFiltersConfig}
            filterCount={filterCount}
            filtersApplied={filtersApplied}
            setOpenCreate={setOpenCreate}
            filtersConfig={filtersConfig}
            formatHeadersText={formatHeadersText}
            contacts={contacts}
            getVisibleColumns={getVisibleColumns}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleClick={handleClick}
            handleClose={handleClose}
            handleToggleColumn={handleToggleColumn}
            hideColumns={hideColumns}
            loading={loading}
            onClearFilters={handleOnClickClearFilters}
            onEditMultipleContacts={onEditMultipleContacts}
            openFilter={openFilter}
            openPopover={openPopover}
            page={page}
            renderRow={renderRow}
            rowsPerPage={rowsPerPage}
            searchTerm={searchTerm}
            selected={selected}
            setChecked={setChecked}
            setOpen={setOpen}
            setPage={setPage}
            setSearchTerm={setSearchTerm}
            setSelected={setSelected}
            setSort={setSort}
            setSortBy={setSortBy}
            handleOnClickDownload={handleOnClickDownload}
            handleOnFetchAllContacts={handleOnFetchAllContacts}
            showColumns={showColumns}
            tabSelected={tabSelected}
            visibleColumns={visibleColumns}
            setVisibleColumns={setVisibleColumns}
          />
        )}

        {activeTab === TYPE_USER_TABS.VIEW && (
          <ViewTabContacts
            sort={sort}
            sortBy={sortBy}
            filtersLoaded={filtersLoaded}
            loadingFilters={loadingFilters}
            setFiltersLoaded={setFiltersLoaded}
            setLoadingFilters={setLoadingFilters}
            setOpenCreate={setOpenCreate}
            tabSelected={tabSelected}
            classes={classes}
            name={name}
            phoneNumber={phoneNumber}
            email={email}
            module={module}
            flaggedOnly={flaggedOnly}
            contacts={contacts}
            handleClick={handleClick}
            openPopover={openPopover}
            handleOnClickDownload={handleOnClickDownload}
            anchorEl={anchorEl}
            fetchFiltersConfig={fetchFiltersConfig}
            filterCount={filterCount}
            filtersApplied={filtersApplied}
            filtersConfig={filtersConfig}
            handleClose={handleClose}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            showColumns={showColumns}
            setOpenFilter={setOpenFilter}
            handleToggleColumn={handleToggleColumn}
            formatHeadersText={formatHeadersText}
            hideColumns={hideColumns}
            openFilter={openFilter}
            onEditMultipleContacts={onEditMultipleContacts}
            selected={selected}
            setOpen={setOpen}
            setContacts={setContacts}
            onClearFilters={handleOnClickClearFilters}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            handleOnFetchAllContacts={handleOnFetchAllContacts}
            setSort={setSort}
            getVisibleColumns={getVisibleColumns}
            setSortBy={setSortBy}
            renderRow={renderRow}
            setChecked={setChecked}
            checked={checked}
            loading={loading}
            page={page}
            setSelected={setSelected}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            visibleColumns={visibleColumns}
            setVisibleColumns={setVisibleColumns}
          />
        )}

          <Modal
            aria-labelledby="transition-modal-title2"
            aria-describedby="transition-modal-description2"
            className={classes.modal}
            open={open}
            onClose={() => setOpen(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={open}>
              <div className={classes.paper}>
                <Typography style={{ fontSize: 20, fontWeight: '500' }}>Are you sure you want to delete?</Typography>
                <Typography variant="body2">Contacts will be deleted permanently.</Typography>
                <Box display="flex" alignItems="center" justifyContent="flex-end" marginTop="20px">
                  <StyledButtonOutlined variant="outlined" onClick={() => setOpen(false)}>
                    CANCEL
                  </StyledButtonOutlined>
                  <StyledButtonOutlined variant="outlined" onClick={onDeleteContact}>
                    {loadingDelete ? <CircularProgress color="#fff" size="25px" /> : 'DELETE'}
                  </StyledButtonOutlined>
                </Box>
              </div>
            </Fade>
          </Modal>

        <ModalCreateContact open={openCreate} onClose={() => setOpenCreate(false)} onUpdate={handleOnFetchAllContacts} />
      </PageWrapper>
    </>
  );
};

export default Contacts;
