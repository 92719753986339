/**
 * @file Provides a TagsProvider component that manages the state of tagsChanged.
 * @module TagsProvider
 * @requires React
 * @requires createContext
 * @requires useState
 * @requires useContext
 * @requires useMemo
 * @requires PropTypes
 */

import React, { createContext, useState, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

/**
 * @namespace TagsContext
 * @type {object}
 * @desc A context object to hold the state of tagsChanged.
 * @default { tagsChanged: [], setTagsChanged: function() }
 */
const TagsContext = createContext();

/**
 * @function TagsProvider
 * @param {object} props - The component props.
 * @param {React.ReactNode} props.children - The children components.
 * @desc A provider component that wraps the children components and provides the state of tagsChanged.
 * @returns {React.ReactNode} The renderable children components.
 */
const TagsProvider = ({ children }) => {
  const [tagsChanged, setTagsChanged] = useState([]);

  /**
   * @constant {object} value - The value object that holds the state of tagsChanged.
   * @desc The value object is memoized to optimize performance.
   * @default { tagsChanged: [], setTagsChanged: function() }
   */
  const value = useMemo(
    () => ({
      tagsChanged,
      setTagsChanged,
    }),
    [tagsChanged]
  );

  return <TagsContext.Provider value={value}>{children}</TagsContext.Provider>;
};

TagsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

/**
 * @function useTagsProvider
 * @desc A custom hook to consume the state of tagsChanged.
 * @returns {object} An object containing the current state of tagsChanged and a function to update it.
 */
export const useTagsProvider = () => {
  const { tagsChanged, setTagsChanged } = useContext(TagsContext);

  return { tagsChanged, setTagsChanged };
};

export default TagsProvider;
