import React, { useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import EnhancedTable from '../../components/Table';
import useInput from '../../hooks/useInput';
import { axiosCatchError } from '../../utils';
import { createContact, createLinkProjectContact, deleteLinkProjectContact } from '../../services/unarmed';
import CreateContactForm from '../../components/CreateContactForm';
import SearchContacts from '../../components/SearchContacts';
import ContactTableRow from '../../components/ContactTableRow';
import { parseContactPhoneNumber } from '../Contacts/ModalCreateContact';
import { BoxContainer } from '../../styles/GlobalStyles';
import Card from '../../components/Card';

const columns = ['Name', 'Company Name', 'Phone Number', 'Email', 'Actions'];

const ProjectContacts = ({ projectId, defaultContacts, onRemoveContact }) => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [sortBy, setSortBy] = useState('createdAt');
  const [sort, setSort] = useState('asc');
  const [loading, setLoading] = useState(false);

  const [contacts, setContacts] = useState([...defaultContacts]);

  const search = useInput('');
  const firstName = useInput('');
  const lastName = useInput('');
  const email = useInput('');
  const phoneNumber = useInput('');
  const type = useInput('');
  const companyName = useInput('');

  const renderRow = (row, index, handleClick) => (
    <ContactTableRow
      row={row}
      index={index}
      handleClick={handleClick}
      onClickRemove={() => {
        deleteLinkProjectContact(projectId, row?._id).then(() => {
          onRemoveContact();
          toast.success('Contact removed from the project successfully!');
        });
      }}
    />
  );

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(1);
  };

  const onClearFields = () => {
    firstName.setValue('');
    lastName.setValue('');
    phoneNumber.setValue('');
    email.setValue('');
    type.setValue('');
    companyName.setValue('');
  };

  const handleOnSubmit = () => {
    const phoneNumberParsed = parseContactPhoneNumber(phoneNumber.value);

    if (phoneNumber.value && phoneNumberParsed.length < 10) {
      toast.error('The phone number must have 10 digits.');
      return;
    }

    if (email.value === '') {
      toast.error('The email is required.');
      return;
    }

    if (type.value === 'Organization' && companyName.value === '') {
      toast.error('The company name is required.');
      return;
    }

    const data = {
      ...(firstName.value ? { firstName: firstName.value } : {}),
      ...(lastName.value ? { lastName: lastName.value } : {}),
      ...(phoneNumber.value ? { phoneNumber: phoneNumberParsed } : {}),
      ...(type.value ? { type: type.value } : {}),
      ...(type.value === 'Organization' && companyName.value ? { companyName: companyName.value } : {}),
      email: email.value,
    };

    setLoading(true);
    createContact(data)
      .then(async (res) => {
        onClearFields();
        setLoading(false);
        if (res?.data) {
          try {
            await createLinkProjectContact(projectId, res?.data?._id);
          } catch (error) {
            toast.error(error.message);
          }
          toast.success('Contact created successfully!');
          const _contacts = [...contacts, res.data];

          setContacts(_contacts);
        }
      })
      .catch((error) => {
        setLoading(false);
        axiosCatchError(error);
      });
  };

  useEffect(() => {
    setContacts([...defaultContacts]);
  }, [defaultContacts]);

  const startIndex = (page - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const contactsPaged = contacts.slice(startIndex, endIndex);

  return (
    <Box display="flex">
      <Card title="Contacts" paddingContent="0px">
        <EnhancedTable
          sort={sort}
          setSort={setSort}
          columns={columns}
          rows={contactsPaged}
          setSortBy={setSortBy}
          rowRender={renderRow}
          loading={false}
          page={page}
          setPage={setPage}
          count={contacts.length}
          rowsPerPage={rowsPerPage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 15, 20, 50, 100]}
          mt={0}
          paperMarginBottom="0px"
        />
      </Card>
      <BoxContainer marginLeft={2}>
        <SearchContacts
          defaultContacts={contacts}
          search={search}
          linkFunc={createLinkProjectContact}
          linkId={projectId}
          onAddedContact={(newContact) => {
            const _contacts = [...contacts, newContact];

            setContacts(_contacts);
          }}
        />
        <CreateContactForm
          dataTestId="project-details-new-contact"
          firstName={firstName}
          lastName={lastName}
          email={email}
          phoneNumber={phoneNumber}
          type={type}
          companyName={companyName}
          loading={loading}
          onSubmit={handleOnSubmit}
        />
      </BoxContainer>
    </Box>
  );
};

ProjectContacts.propTypes = {
  defaultContacts: PropTypes.array,
  projectId: PropTypes.number,
  onRemoveContact: PropTypes.func,
};

export default ProjectContacts;
