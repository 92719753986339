import React from 'react';
import { TableRow, TableCell, makeStyles, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';

import formatTxt from '../utils/formatText';
import { formatPhoneNumber } from '../utils';

const useStyles = makeStyles(() => ({
  row: {
    cursor: 'pointer',
    height: 50,
    maxHeight: 50,
    background: '#fff',
    '&:hover': {
      backgroundColor: '#f5f5f5',
      '& $externalIcon': {
        visibility: 'visible',
        color: '#1F63FF',
      },
    },
  },
}));

/**
 * Table row component representing a contact.
 *
 * @param {Object} props - The properties of the component.
 * @param {Object} props.row - The data for the contact.
 * @param {number} props.index - The index of the row.
 * @param {Function} props.handleClick - The click event handler for the row.
 * @returns {JSX.Element} React component.
 */
const ContactTableRow = ({ row, index, handleClick, onClickRemove }) => {
  const classes = useStyles();
  const history = useHistory();

  /**
   * Handles the click event on the table row.
   *
   * @param {React.MouseEvent} e - The mouse event.
   */
  const goToContactProfile = (e) => {
    const go = handleClick(e, row._id);

    if (go) {
      history.push(`/contacts/${row._id}`);
    }
  };

  return (
    <TableRow hover tabIndex={-1} key={index} className={classes.row}>
      <TableCell
        onClick={(e) => goToContactProfile(e, row, handleClick)}
        align="left"
        style={row.firstName ? { textTransform: 'capitalize' } : {}}
      >
        {row.firstName ? `${formatTxt(row?.firstName, 15)} ${formatTxt(row?.lastName, 15)}` : 'Not specified'}
      </TableCell>
      <TableCell align="left">{row.companyName ? row.companyName : 'Not specified'}</TableCell>
      <TableCell onClick={(e) => goToContactProfile(e, row, handleClick)} align="left">
        {formatPhoneNumber(row.phoneNumber) || 'Not specified'}
      </TableCell>
      <TableCell onClick={(e) => goToContactProfile(e, row, handleClick)} align="left">
        {row.email || 'Not specified'}
      </TableCell>
      <TableCell align="left">
        <IconButton onClick={onClickRemove}>
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

ContactTableRow.propTypes = {
  row: PropTypes.object,
  index: PropTypes.number,
  handleClick: PropTypes.func,
  onClickRemove: PropTypes.func,
};

export default ContactTableRow;
