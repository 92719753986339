import { useState, useEffect } from 'react';
import {
  getUserTabs,
  createUserTabs,
  updateUserTabs,
  deleteUserTab,
  updateUserTabName,
  updateDashboard,
  updateViewsById,
} from '../services/unarmed';
import { TYPE_USER_TABS } from '../utils/constants';

export const useTabsUtil = ({ moduleName }) => {
  const [userTabs, setUserTabs] = useState([]);
  const [nextPosition, setNextPosition] = useState(1);
  const storedTabSelected = localStorage.getItem('tabSelected');
  const isSelected = !storedTabSelected; // Cambia a null
  const initialTabSelected = isSelected ? JSON?.parse(storedTabSelected) : {};
  const [tabSelected, setTabSelected] = useState({ initialTabSelected });
  const [activeTab, setActiveTab] = useState(localStorage.getItem('activeTab') || TYPE_USER_TABS.DASHBOARDS_LIST);
  const [activeTabName, setActiveTabName] = useState(localStorage.getItem('activeTabName') || 'Dashboards');
  const [showReportTable, setShowReportTable] = useState(true);
  const [showDashboardsTable, setShowDashboardsTable] = useState(true);
  const [selectedRow, setSelectedRow] = useState(null);

  const onGetUserTabs = async () => {
    try {
      const { data } = await getUserTabs(moduleName);
      setUserTabs(data.tabs);

      const validTabs = data.tabs.filter((tab) => {
        const position = parseInt(tab.position, 10);
        if (isNaN(position)) {
          console.warn('Tab con posición inválida o faltante:', tab);
          return false;
        }
        return true;
      });

      if (validTabs.length === 0) {
        console.warn('No hay tabs con posiciones válidas');
        setNextPosition(1);
        return;
      }

      const sortedTabs = validTabs.sort((a, b) => parseInt(a.position, 10) - parseInt(b.position, 10));
      const lastPosition = parseInt(sortedTabs[sortedTabs.length - 1].position, 10);
      if (moduleName == 'INSIGHT') {
        localStorage.setItem('tabSelected', JSON.stringify(sortedTabs.find((tab) => tab.name === 'Reports')))
      } else {
        localStorage.setItem('tabSelected', JSON.stringify(sortedTabs.find((tab) => tab.name === 'Default')))
      }

      if (!isNaN(lastPosition)) {
        setNextPosition(lastPosition + 1);
      } else {
        console.error('La última posición es NaN, algo salió mal al obtener la posición');
        setNextPosition(1);
      }
      setUserTabs(sortedTabs);
    } catch (error) {
      console.error('Error al obtener los tabs:', error);
    }
  };

  const handleSaveTab = async (tabName, updatedTabs = userTabs) => {
    if (!updatedTabs || updatedTabs.length === 0 || !tabSelected.id) {
      const userTabsPayload = {
        userTabs: [
          {
            name: tabName || 'Nuevo Tab',
            type: moduleName === 'INSIGHT' ? TYPE_USER_TABS.DASHBOARD : TYPE_USER_TABS.VIEW,
            position: String(nextPosition),
            moduleName,
          },
        ],
      };

      try {
        const response = await createUserTabs(userTabsPayload);
        if (response.status === 200) {
          const savedTab = response.data;
          const updatedTabsWithNew = [...updatedTabs, savedTab];
          setUserTabs(updatedTabsWithNew);
        } else {
          console.error('Error al guardar el tab:', response);
        }
      } catch (error) {
        console.error('Error en la API al guardar el tab:', error.message || error);
      }
      return;
    }

    const existingTab = updatedTabs?.find((tab) => tab.id === tabSelected.id);

    if (existingTab) {
      const updatedTabList = updatedTabs?.map((tab) => (tab.id === tabSelected.id ? { ...tab, name: tabName } : tab));

      const updatedTabPayload = {
        userTabs: updatedTabList.map((tab, index) => ({
          id: tab.id,
          name: tab.name,
          type: moduleName==='INSIGHT' ? TYPE_USER_TABS.DASHBOARD : TYPE_USER_TABS.VIEW,
          position: String(index + 1),
        })),
        moduleName: moduleName,
      };

      try {
        const response = await updateUserTabs(updatedTabPayload);
        if (response.status === 200) {
          setUserTabs(updatedTabList);
        } else {
          console.error('Error al actualizar los tabs:', response);
        }
      } catch (error) {
        console.error('Error en la API al actualizar los tabs:', error.message || error);
      }
    }
  };

  const updateNameTab = async ({ newName, tabId, dashboardId, viewId }) => {
    try {
      const response = await updateUserTabName(tabId, newName);
      if (dashboardId) {
        const responseDashboard = await updateDashboard(dashboardId, { name: newName });
      } else {
        const responseView = await updateViewsById(viewId, { viewName: newName });
      }
      if (response.status === 200) {
        onGetUserTabs();
      } else {
        console.error('Error al actualizar los tabs:', response);
      }
    } catch (error) {
      console.error('Error en la API al actualizar los tabs:', error.message || error);
    }
  };

  const handleCreateOrEditTab = () => {
    const isEditMode = tabSelected.id !== null;
    if (typeof nextPosition !== 'number' || isNaN(nextPosition)) {
      return;
    }

    if (isEditMode) {
      handleSaveTab(nextPosition, tabSelected.id);
    } else {
      handleSaveTab(nextPosition); // Crear nuevo tab
    }
  };

  const handleTabSelection = (tab) => {
    setTabSelected(tab);
  };

  const saveTabPositions = async (updatedTabs, typeTab) => {
    const userTabsPayload = {
      userTabs: updatedTabs?.map((tab, index) => ({
        id: tab.id,
        name: tab.name,
        type: typeTab,
        pinned: tab.pinned,
        position: String(index + 1),
      })),
      moduleName,
    };

    try {
      const response = await updateUserTabs(userTabsPayload);
      if (response.status === 200) {
        setUserTabs(updatedTabs);
      } else {
        console.error('Error al actualizar las posiciones de los tabs:', response);
      }
    } catch (error) {
      console.error('Error en la API al actualizar las posiciones:', error.message || error);
    }
  };

  const handleTabsReorder = (updatedTabs) => {
    setUserTabs(updatedTabs);
    saveTabPositions(updatedTabs);
  };

  const handleTabSelectionAction = (tab) => {
    setTabSelected(tab);

    switch (tab.type) {
      case TYPE_USER_TABS.REPORTS:
        setActiveTab(TYPE_USER_TABS.REPORTS);
        setShowReportTable(true);
        break;
      case TYPE_USER_TABS.DASHBOARDS_LIST:
        setActiveTab(TYPE_USER_TABS.DASHBOARDS_LIST);
        setShowDashboardsTable(true);
        break;
      case TYPE_USER_TABS.DASHBOARD:
        setActiveTab(TYPE_USER_TABS.DASHBOARD);
        setActiveTabName(tab.name);
        break;
      case TYPE_USER_TABS.EXPLORE:
        setActiveTab(TYPE_USER_TABS.EXPLORE);
        break;
      case TYPE_USER_TABS.ADHOCREPORT:
        setActiveTab(TYPE_USER_TABS.ADHOCREPORT);
        break;
      case TYPE_USER_TABS.MAIN:
        setActiveTab(TYPE_USER_TABS.MAIN);
        break;
      case TYPE_USER_TABS.VIEW:
        setActiveTab(TYPE_USER_TABS.VIEW);
        break;
      default:
        break;
    }
    setSelectedRow(null);
  };

  const deleteSelectedTab = async () => {
    const tabToDelete = userTabs.find((tab) => tab.id === tabSelected.id);
    if (!tabToDelete?.deletable) {
      console.warn('This tab cannot be deleted.');
      return;
    }

    try {
      const response = await deleteUserTab(tabSelected.id, moduleName);
      setUserTabs(response.data.remainingTabs);
      if (response.status === 200) {
        console.log('Tab deleted successfully:', response);
      } else {
        console.error('Error deleting the tab:', response);

        setUserTabs((prevTabs) => [...prevTabs, tabToDelete]);
      }
    } catch (error) {
      console.error('Error in API while deleting the tab:', error.message || error);

      setUserTabs((prevTabs) => [...prevTabs, tabToDelete]);
    }
  };

  useEffect(() => {
    onGetUserTabs();
  }, [moduleName]);

  return {
    updateNameTab,
    activeTabName,
    setActiveTabName,
    tabSelected,
    onGetUserTabs,
    userTabs,
    setUserTabs,
    setTabSelected,
    activeTab,
    handleSaveTab,
    setActiveTab,
    handleTabSelection,
    nextPosition,
    setShowReportTable,
    handleTabsReorder,
    handleTabSelectionAction,
    deleteSelectedTab,
  };
};
