import { Box, Button, CircularProgress, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { DropzoneArea } from 'material-ui-dropzone';
import { toast } from 'react-toastify';
import MultiSelectBox from '../../../components/MultiSelectBox';
import { genderOptions, raceEthnicityOptions } from '../../../utils/options';
import { FillInputPhone } from '../../CaseDetails/NewOfficer';
import { OutLineInput } from '../../CaseDetails/UpdateCase';
import { TextMaskCustom } from '../../Officers/OfficersTable';
import officerProfile from '../../../assets/officer.png';
import CircularProgressWithLabel from '../../../components/CircularProgressWithLabel';
import { ImageContainer, useGlobalStyles } from '../../../styles/GlobalStyles';

const useStyles = makeStyles(() => ({
  label: {
    fontSize: 12,
    color: '#aaa',
  },
  img: {
    width: 120,
    height: 120,
    borderRadius: '50%',
    objectFit: 'cover',
  },
  button: {
    letterSpacing: '1px',
    fontSize: 13,
    background: '#2E66FE',
    fontWeight: '400',
    width: 'fit-content',
    alignSelf: 'flex-end',
    color: '#fff',
    '&:hover': {
      background: '#4b7bff',
    },
    '& .MuiButton-label': {
      fontSize: 13,
    },
    textTransform: 'capitalize',
  },
  buttonOutlined: {
    letterSpacing: '1px',
    fontSize: 13,
    background: '#fff',
    fontWeight: '500',
    marginRight: 10,
    width: 'fit-content',
    alignSelf: 'flex-end',
    border: '1px solid #4b7bff ',
    color: '#4b7bff',
    '&:hover': {
      background: '#fff',
      border: '1px solid #4b7bff ',
    },
    '& .MuiButton-label': {
      fontSize: 13,
    },
    textTransform: 'capitalize',
  },
}));

/**
 * EditOfficer component for editing an officer's profile.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.phone - Phone number object with value and onChange handler.
 * @param {Object} props.firstName - First name object with value, onChange handler, and error.
 * @param {Object} props.lastName - Last name object with value, onChange handler, and error.
 * @param {Object} props.gender - Gender value and setter function.
 * @param {Function} props.setGender - Function to set the gender.
 * @param {Object} props.race - Race value and setter function.
 * @param {Function} props.setRace - Function to set the race.
 * @param {Function} props.onSave - Function to handle save action.
 * @param {boolean} props.loading - Loading state for the save button.
 * @param {Object} props.image - Image object containing the public_url.
 * @param {Function} props.uploadOnLoad - Function to handle image upload.
 * @param {number} props.loadingPercentage - Percentage of image upload completion.
 * @param {Function} props.onCancel - Function to handle cancel action.
 * @param {Object} props.email - Email object with value, onChange handler, and error.
 * @returns {React.ReactElement} The EditOfficer component.
 */
export default function EditOfficer({
  phone,
  firstName,
  lastName,
  gender,
  setGender,
  race,
  setRace,
  onSave,
  loading,
  image,
  uploadOnLoad,
  loadingPercentage,
  onCancel,
  email,
}) {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();

  return (
    <Box minWidth={300}>
      <Typography style={{ fontSize: 20, fontWeight: '500' }}>Edit Profile</Typography>
      <Box mt={2} position="relative" display="flex" alignItems="center">
        <img
          src={image?.public_url || officerProfile}
          alt="profileImg"
          className={classes.img}
          style={{ cursor: 'pointer' }}
        />
        <ImageContainer>
          <DropzoneArea
            inputProps={{ multiple: false }}
            maxFileSize={500000000}
            onDropRejected={(files) => {
              if (files[0].size > 1000000) {
                toast.error(
                  'The file is too large, please upload to a file repository and share the link on incident External links section.'
                );
              }
            }}
            onChange={(fl) => {
              if (fl.length > 0) {
                uploadOnLoad(fl[fl.length - 1]);
              }
            }}
            clearOnUnmount
            filesLimit={1}
            showPreviewsInDropzone={false}
          />
        </ImageContainer>
        {loadingPercentage > 0 && loadingPercentage < 100 ? (
          <Box
            className={globalClasses.boxProgressWithLabel}
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="50%"
            style={{ backgroundColor: 'rgba(0, 0, 0, .1)' }}
          >
            <CircularProgressWithLabel value={loadingPercentage} />
          </Box>
        ) : null}
      </Box>
      <Box mt={2}>
        <Typography variant="body2" className={classes.label}>
          First Name <strong style={{ color: 'red' }}>*</strong>
        </Typography>
        <OutLineInput
          placeholder="First Name"
          value={firstName.value}
          onChange={firstName.onChange}
          error={firstName.error}
          aria-describedby="outlined-weight-helper-text"
          inputProps={{
            'aria-label': 'weight',
            'data-testid': 'officer-edit-input-first-name',
          }}
          labelWidth={0}
        />
        <span className={classes.labelError}>{firstName.error}</span>
      </Box>
      <Box mt={2}>
        <Typography variant="body2" className={classes.label}>
          Last Name <strong style={{ color: 'red' }}>*</strong>
        </Typography>
        <OutLineInput
          placeholder="Last Name"
          value={lastName.value}
          onChange={lastName.onChange}
          error={lastName.error}
          aria-describedby="outlined-weight-helper-text"
          inputProps={{
            'aria-label': 'weight',
            'data-testid': 'officer-edit-input-last-name',
          }}
          labelWidth={0}
        />
        <span className={classes.labelError}>{lastName.error}</span>
      </Box>
      <Box mt={2}>
        <Typography variant="body2" className={classes.label}>
          Email
        </Typography>
        <OutLineInput
          placeholder="Email"
          value={email.value}
          onChange={email.onChange}
          error={email.error}
          aria-describedby="outlined-weight-helper-text"
          inputProps={{
            'aria-label': 'weight',
            'data-testid': 'officer-edit-input-email',
          }}
          labelWidth={0}
        />
        <span className={classes.labelError}>{email.error}</span>
      </Box>
      <Box mt={2}>
        <Typography variant="body2" className={classes.label}>
          Phone
        </Typography>
        <FillInputPhone
          value={phone.value}
          onChange={phone.onChange}
          autoComplete="off"
          name="textmask"
          style={{
            width: '100%',
            color: '#000',
          }}
          error={phone.error}
          id="formatted-text-mask-input"
          inputComponent={TextMaskCustom}
          inputProps={{
            'data-testid': 'officer-edit-input-phone',
          }}
        />
        <span className={classes.labelError}>{phone.error}</span>
      </Box>
      <Box mt={2}>
        <Typography variant="body2" className={classes.label}>
          Gender{' '}
        </Typography>
        <MultiSelectBox
          dataTestId="officer-edit-select-gender"
          value={gender}
          onChange={(e) => setGender(e.target.value)}
          id="genderMultiSelect"
          options={genderOptions || []}
        />
      </Box>
      <Box mt={2}>
        <Typography variant="body2" className={classes.label}>
          Race/Ethnicity{' '}
        </Typography>
        <MultiSelectBox
          dataTestId="officer-edit-select-race-ethnicity"
          value={race}
          onChange={(e) => setRace(e.target.value)}
          id="raceEthnicityMultiSelect"
          options={raceEthnicityOptions || []}
        />
      </Box>
      <Box display="flex" alignItems="center" justifyContent="flex-end" mt={3}>
        <Button
          data-testid="officer-edit-button-cancel"
          onClick={onCancel}
          className={classes.buttonOutlined}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button data-testid="officer-edit-button-save" onClick={onSave} className={classes.button} disabled={loading}>
          {loading ? <CircularProgress color="#fff" size="25px" /> : 'Save'}
        </Button>
      </Box>
    </Box>
  );
}

EditOfficer.propTypes = {
  phone: PropTypes.shape({
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    error: PropTypes.string,
  }).isRequired,
  firstName: PropTypes.shape({
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    error: PropTypes.string,
  }).isRequired,
  lastName: PropTypes.shape({
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    error: PropTypes.string,
  }).isRequired,
  gender: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  setGender: PropTypes.func.isRequired,
  race: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  setRace: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  image: PropTypes.shape({
    public_url: PropTypes.string,
  }),
  uploadOnLoad: PropTypes.func.isRequired,
  loadingPercentage: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  email: PropTypes.shape({
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    error: PropTypes.string,
  }).isRequired,
};

EditOfficer.defaultProps = {
  image: officerProfile,
};
