const constants = {
  EIS: {
    RISK_LEVEL: {
      HIGH: 'HIGH',
      MEDIUM: 'MEDIUM',
      LOW: 'LOW',
      NONE: 'NONE',
    },
  },
  NOTIFICATIONS: {
    DAYS: ['5', '30', '60', '90', '120', '150', '180', '210', '240', '270', '300', '330', '360'],
  },
  AVAILABLE_STATUSES: ['RECEIVED', 'IN PROGRESS', 'CLOSED'],
};

module.exports = constants;
