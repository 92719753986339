import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import Input from '../../../components/Input';

const useStyles = makeStyles(() => ({
  title: {
    fontSize: 14,
    color: '#000000DE',
    marginLeft: 10,
  },
  label: {
    fontSize: 16,
    color: '#000000DE',
    fontWeight: '500',
  },
  dropzoneBox: {
    background: '#4762FA08',
    borderRadius: 4,
    border: '1px dashed #364F7431',
    padding: 34,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: 16,
  },

  dropzoneText: {
    display: 'block',
    fontSize: 12,
    marginTop: 10,
  },
}));

export default function QuestionComponent({
  placeholder = 'Enter question',
  value,
  onChange,
  label = 'Q',
  limit,
  styles,
}) {
  const classes = useStyles();
  return (
    <Box display="flex" marginBottom="15px">
      <Box display="flex" alignItems="center">
        <Typography className={classes.label}>{label}</Typography>
        <ArrowRightAltIcon style={{ fontSize: 14, marginRight: 10 }} />
      </Box>
      <Input
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        limit={limit}
        style={styles}
      />
    </Box>
  );
}
