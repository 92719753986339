import React from 'react';
import { Box, Input, InputLabel, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import { TextMaskCustom } from '../Officers/OfficersTable';

const MfaStep1 = ({ phone }) => (
  <>
    <Typography style={{ marginTop: 10, fontSize: 14, color: '#000000AD' }}>
      Protect your account by adding an extra layer of security. We need you to enter your phone number in the field
      below to send you a verification code.
    </Typography>
    <Box display="flex" alignItems="flex-end">
      <Typography style={{ position: 'relative', top: -4, left: -2 }}>+1</Typography>
      <Box marginTop="25px">
        <InputLabel htmlFor="formatted-text-mask-input" style={{ marginBottom: 3, fontSize: 12 }}>
          Phone Number
        </InputLabel>
        <Input
          inputProps={{
            'data-testid': 'mfa-step-1-phone-number',
          }}
          value={phone.value}
          onChange={phone.onChange}
          name="textmask"
          id="formatted-text-mask-input"
          inputComponent={TextMaskCustom}
        />
      </Box>
    </Box>
  </>
);

MfaStep1.propTypes = {
  phone: PropTypes.object,
};

export default MfaStep1;
