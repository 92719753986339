import { Box, makeStyles, Switch, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import DashboardCard from '../../components/DashboardCard';
import storageImg from '../../assets/storage.png';
import usersImg from '../../assets/users.png';
import usersCheckImg from '../../assets/userCheck.png';
import listImg from '../../assets/list.png';
import userProgress from '../../assets/usersProgress.png';
import { Context } from '../../Context';
import formatBytes from '../../utils/formatBytes';
import useOrganization from '../../hooks/useOrganization';
import { getUsage, onChangeAttachments } from '../../services/unarmed';
import UpgradeModal from '../../components/UpgradeModal';

const useStyles = makeStyles(() => ({
  container: {
    padding: 20,
  },
  title: {
    marginTop: 50,
  },
}));
const Usage = () => {
  const classes = useStyles();
  const { setActive, authUser } = useContext(Context);
  const { organization } = useOrganization();
  const [usage, setUsage] = useState(null);
  const [hasAttachments, setHasAttachments] = useState(null);
  const history = useHistory();

  useEffect(() => {
    setActive('/usage');
  }, []);
  useEffect(() => {
    const onGetData = async () => {
      const data = await getUsage(organization?._id);
      setUsage(data);
    };

    if (authUser && organization?._id) {
      onGetData();
      setHasAttachments(organization?.features?.general?.attachments);
    }
  }, [authUser, organization]);

  const handleChange = () => [
    onChangeAttachments(organization?._id, !hasAttachments)
      .then((res) => {
        toast.success('Attachments Updated!');
        setHasAttachments(!hasAttachments);
      })
      .catch((err) => toast.error(`Something went wrong ${err}`)),
  ];

  return (
    <>
      {organization && (
        <UpgradeModal
          open={!organization?.features?.dashboard?.usage?.enabled || false}
        />
      )}
      <Box
        className={classes.container}
        style={
          !organization?.features?.dashboard?.usage?.enabled
            ? {
                filter: 'blur(6px)',
                height: '70vh',
                overflow: 'hidden',
              }
            : {}
        }
      >
        <Box display="flex" justifyContent="space-between">
          {/* <Typography variant="h5">Usage </Typography> */}
        </Box>
        <Box display="flex" alignItems="center" mt="30px">
          {/* <DashboardCard
            cardText="Users"
            color="#01BEA826"
            icon={userProgress}
            showProgressBar
            barColor="secondary"
            progressBarValue={(
              (usage?.activeUsers * 100) /
              usage?.usersLimit
            ).toFixed(0)}
            value={`${usage?.activeUsers ? usage?.activeUsers : 0}/${
              usage?.usersLimit ? usage?.usersLimit : 0
            }`}
          /> */}
          <DashboardCard
            cardText="Storage"
            color="#2E66FE26"
            icon={storageImg}
            showProgressBar
            showChildren
            progressBarValue={(
              (formatBytes(usage?.spaceUsed, 0) * 100) /
              formatBytes(usage?.spaceAvailable, 0)
            ).toFixed(0)}
            value={`${
              usage?.spaceUsed ? formatBytes(usage?.spaceUsed, 2) : 0
            }/${
              usage?.spaceAvailable ? formatBytes(usage?.spaceAvailable, 0) : 0
            } GB`}
          >
            <Box display="flex" alignItems="center">
              <Typography
                style={{ fontSize: 14, color: '#000000DE', marginRight: 5 }}
              >
                Attachments
              </Typography>
              <Switch
                checked={hasAttachments}
                onChange={handleChange}
                size="small"
                color="primary"
                name="checkedB"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </Box>
          </DashboardCard>
          {/* <DashboardCard
            cardText="Total Users"
            color="#AE5DFF26"
            icon={usersImg}
            value={usage?.activeUsers + usage?.pendingUsers || 0}
            buttonText="View all users"
            onClickButton={() => {
              history.push('/users');
              setActive('/users');
            }}
          />
          <DashboardCard
            cardText="Active Users"
            color="#009A6D26"
            icon={usersCheckImg}
            value={usage?.activeUsers || 0}
            buttonText="View all active users"
            onClickButton={() => {
              history.push('/users');
              setActive('/users');
            }}
          />
          <DashboardCard
            cardText="Pending Users"
            color="#FF826A26"
            icon={listImg}
            value={usage?.pendingUsers || 0}
            buttonText="View all pending users"
            onClickButton={() => {
              history.push('/users', {
                value: 1,
              });
              setActive('/users');
            }}
          /> */}
        </Box>
      </Box>
    </>
  );
};

export default Usage;
