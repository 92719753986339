/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  FormControlLabel,
  makeStyles,
  Switch,
  Typography,
  Fade,
  Backdrop,
  Button,
  Modal,
  CircularProgress,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import { updateFormTypes } from '../../services/unarmed';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 20,
  },
  title: {
    marginTop: 50,
  },
  button: {
    background: ' #1F63FF',
    textTransform: 'capitalize',
    marginRight: 10,
    color: '#fff',
    height: 36,
    width: 30,
    '&:hover': {
      background: '#1F63FF',
    },
    '& .MuiButton-label': {
      fontSize: 13,
    },
    '&:disabled': {
      background: '#1f63ff61',
      color: '#fff',
    },
  },
  button2: {
    background: ' #1F63FF',
    textTransform: 'capitalize',
    color: '#fff',
    '&:hover': {
      background: '#1F63FF',
    },
    '& .MuiButton-label': {
      fontSize: 14,
    },
  },
  buttonOutlined: {
    letterSpacing: '1px',
    fontSize: 13,
    fontWeight: '500',
    marginRight: 10,
    width: 'fit-content',
    alignSelf: 'flex-end',
    border: '1px solid transparent',
    color: '#4b7bff',
    '&:hover': {
      background: 'transparent',
      border: '1px solid transparent',
    },
    '& .MuiButton-label': {
      fontSize: 13,
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: '#fff',
    boxShadow: theme.shadows[5],
    padding: 30,
    maxWidth: 600,
    minWidth: 400,
    outline: 0,
  },
}));

/**
 * UpdateStatusFormPopup component for updating the status of a form.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {number} props.index - The index of the form in the list.
 * @param {Object} props.row - The form data.
 * @param {Function} props.setForms - Function to update the list of forms.
 * @param {Function} props.setStatus - Function to update the status of the forms.
 * @param {boolean[]} props.status - Array of boolean values representing the status of each form.
 * @param {Object} props.forms - The current forms data.
 * @param {string} [props.statusKey='published'] - The key to update the status in the form object.
 * @param {Function} [props.callback] - Optional callback function to run after status update.
 * @returns {React.ReactElement} The UpdateStatusFormPopup component.
 */
export default function UpdateStatusFormPopup({
  index,
  row,
  setForms,
  setStatus,
  status,
  forms,
  statusKey = 'published',
  callback,
}) {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const handleUpdateStatus = async () => {
    setLoading(true);
    try {
      const { data } = await updateFormTypes(row?._id, {
        [statusKey]: !status[index],
      });

      const formsCopy = [...forms.data];
      formsCopy[index][statusKey] = data[statusKey];
      formsCopy[index].portalStatus = false;
      setForms((prevState) => ({ ...prevState, data: formsCopy }));
      const statusCopy = [...status];
      statusCopy[index] = !status[index];

      setStatus(statusCopy);
      if (callback) {
        callback(index, false);
      }
      setOpen(false);
      setLoading(false);
      toast.success('The form was updated successfully');
    } catch (err) {
      setLoading(false);
      toast.error(err.response?.data?.message);
    }
  };

  return (
    <>
      <FormControlLabel
        control={<Switch color="primary" checked={status[index] || row?.[statusKey]} onChange={() => setOpen(true)} />}
        label={status[index] || row?.[statusKey] ? 'Live' : 'Draft'}
        style={{ marginLeft: 0 }}
        labelPlacement="start"
      />
      <Modal
        aria-labelledby="transition-modal-title1"
        aria-describedby="transition-modal-description1"
        open={open}
        className={classes.modal}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Typography style={{ fontSize: 20, fontWeight: '500' }}>
              Are you sure you want to {status[index] ? 'unpublish' : 'publish'} this form?
            </Typography>
            {!status[index] && (
              <Typography variant="body2">
                There can only be one "<span style={{ textTransform: 'capitalize' }}>{row?.typeDisplayText || row?.type?.toLowerCase()}</span>{' '}
                type" public at the same time
              </Typography>
            )}
            <Box display="flex" alignItems="center" justifyContent="flex-end" marginTop="20px">
              <Button
                data-testid="forms-button-update-status-cancel"
                variant="outlined"
                className={classes.buttonOutlined}
                onClick={() => setOpen(false)}
              >
                CANCEL
              </Button>
              <Button
                data-testid="forms-button-update-status"
                variant="outlined"
                className={classes.buttonOutlined}
                onClick={handleUpdateStatus}
              >
                {loading ? <CircularProgress color="#fff" size="25px" /> : status[index] ? 'DRAFT' : 'PUBLISH'}
              </Button>
            </Box>
          </div>
        </Fade>
      </Modal>
    </>
  );
}

UpdateStatusFormPopup.propTypes = {
  index: PropTypes.number.isRequired,
  row: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    published: PropTypes.bool,
    type: PropTypes.string,
  }).isRequired,
  setForms: PropTypes.func.isRequired,
  setStatus: PropTypes.func.isRequired,
  status: PropTypes.arrayOf(PropTypes.bool).isRequired,
  forms: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  statusKey: PropTypes.string,
  callback: PropTypes.func,
};
