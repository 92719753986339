import { Box, Divider, FormControl, InputLabel, makeStyles, Select, Typography } from '@material-ui/core';
import React from 'react';

import { OutLineInputBox } from '../../../styles/GlobalStyles';

const useStyles = makeStyles(() => ({
  label: {
    fontWeight: '400',
    fontSize: 14,
    color: '#333',
  },
}));

const ColorLabel = ({ color, label }) => {
  const classes = useStyles();
  return (
    <Box display="flex" alignItems="center" minWidth={100}>
      <Box width={25} height={25} borderRadius={100} style={{ backgroundColor: color }} />
      <Typography className={classes.label} style={{ marginLeft: 10 }}>
        {label}
      </Typography>
    </Box>
  );
};

export default function EISItem({ onChange, value, color, priorityLabel, conditionNumber }) {
  const classes = useStyles();

  return (
    <>
      <Box display="flex" alignItems="center" marginBottom={2}>
        <ColorLabel color={color} label={priorityLabel} />
        <Typography className={classes.label}>>=</Typography>
        <OutLineInputBox
          value={value}
          onChange={onChange}
          placeholder="0"
          type="number"
          min={0}
          max={conditionNumber ? conditionNumber - 1 : conditionNumber}
          style={{ width: 40, textAlign: 'center', margin: '0 20px' }}
        />
        {conditionNumber ? (
          <Typography style={{ marginRight: 10 }} className={classes.label}>{`and < ${conditionNumber} `}</Typography>
        ) : null}
        <Typography className={classes.label}> Complaints</Typography>
      </Box>
      <Divider style={{ marginBottom: 20 }} />
    </>
  );
}
