import { Button, Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

/**
 * AddMore component renders a button with an icon and text.
 * It is used to trigger an action, typically adding a new item or option.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {function} props.onClick - The function to call when the button is clicked.
 * @param {string} props.dataTestId - The test ID for the button.
 * @param {string} [props.marginLeft='90px'] - The left margin of the button.
 * @param {string} [props.marginTop='20px'] - The top margin of the button.
 * @param {string} [props.text='Add new option'] - The text to display on the button.
 * @param {boolean} [props.disabled=false] - Whether the button is disabled.
 * @returns {React.ReactElement} The AddMore component.
 */
export default function AddMore({
  onClick,
  dataTestId,
  marginLeft = '90px',
  marginTop = '20px',
  text = 'Add new option',
  disabled = false,
}) {
  return (
    <Button
      data-testid={dataTestId}
      style={{
        display: 'flex',
        marginTop,
        marginLeft,
        textTransform: 'capitalize',
        alignItems: 'center',
      }}
      disabled={disabled}
      onClick={onClick}
    >
      <AddCircleOutlineIcon htmlColor="#2E66FE" fontSize="small" />
      <Typography style={{ fontSize: 12, color: '#2E66FE', marginLeft: 7 }}>{text}</Typography>
    </Button>
  );
}

AddMore.propTypes = {
  onClick: PropTypes.func.isRequired,
  dataTestId: PropTypes.string.isRequired,
  marginLeft: PropTypes.string,
  marginTop: PropTypes.string,
  text: PropTypes.string,
  disabled: PropTypes.bool,
};

AddMore.defaultProps = {
  marginLeft: '90px',
  marginTop: '20px',
  text: 'Add new option',
  disabled: false,
};
