import { Typography } from '@material-ui/core';
import React from 'react';
import ModalButtons from './components/ModalButtons';

export default function ConfirmationPopup({
  handleCancel,
  handleSubmit,
  loading,
}) {
  return (
    <>
      <Typography style={{ fontSize: 20, fontWeight: '500' }}>
        Are you sure you want to leave?
      </Typography>
      <Typography variant="body2">
        There are some changes in overview that may not being reflect it in
        actions
      </Typography>
      <ModalButtons
        handleCancel={handleCancel}
        handleSubmit={handleSubmit}
        isValid={() => false}
        loading={loading}
        buttonText="Leave current tab"
      />
    </>
  );
}
