/* eslint-disable react/prop-types */
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Fade,
  makeStyles,
  Modal,
  Tooltip,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import InfoIcon from '@material-ui/icons/Info';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { VariableSizeGrid as Grid } from 'react-window';
import PropTypes from 'prop-types';

import { onImport } from '../../../services/unarmed';
import formatTxt from '../../../utils/formatText';
import { getKeyImported } from './Step2';

const useStyles = makeStyles((theme) => ({
  button: {
    background: ' #4b7bff',
    textTransform: 'capitalize',
    color: '#fff',
    '&:hover': {
      background: '#4b7bff',
    },
    '& .MuiButton-label': {
      fontSize: 14,
    },
    marginTop: 10,
    marginBottom: 20,
  },
  outlined: {
    background: '#fff',
    color: '#4b7bff',
    border: '1px solid #4b7bff',
    '&:hover': {
      background: 'transparent',
    },
    marginTop: 10,
    marginBottom: 20,
    textTransform: 'capitalize',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: '#fff',
    boxShadow: theme.shadows[5],
    padding: 30,
    maxWidth: 445,
    outline: 0,
  },
  buttonOutlined: {
    letterSpacing: '1px',
    fontSize: 13,
    fontWeight: '500',
    marginRight: 10,
    width: 'fit-content',
    alignSelf: 'flex-end',
    border: '1px solid transparent',
    color: '#4b7bff',
    '&:hover': {
      background: 'transparent',
      border: '1px solid transparent',
    },
    '& .MuiButton-label': {
      fontSize: 13,
    },
  },
}));

const Step3 = ({ setStep, rows, csvErrors = [], orderedHeaders, forms, selectedModule, formTypeId }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState('idle');
  const [open, setOpen] = useState(false);
  const history = useHistory();

  const getRowsWithErrors = () => {
    if (csvErrors) {
      const rowsWithErrors = csvErrors.reduce((acc, curr) => [...acc, curr.row], []);

      return rowsWithErrors;
    }

    return [];
  };

  const onImportForm = () => {
    setLoading('pending');
    const jsonWithErrorsSkipped = forms.filter((json) => !getRowsWithErrors().includes(json.row));

    const data = {
      validateOnly: false,
      [getKeyImported(selectedModule)]: jsonWithErrorsSkipped,
      ...(selectedModule === 'Cases' ? { formTypeId } : {}),
    };

    onImport(data, selectedModule)
      .then(() => {
        history.push('/success-imported');
        setLoading('resolved');
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error?.details?.[0]?.message);
        setLoading('rejected');
      });
  };

  const joinPathKey = (path) => {
    if (path.length === 2) {
      if (Number.isNaN(parseInt(path[1]))) {
        return `${path[0]}.${path[1]}`;
      }
      return path[0];
    }
    if (path.length === 3) {
      if (!Number.isNaN(parseInt(path[1])) && typeof path[2] === 'string') {
        return `${path[0]}[${path[1]}].${path[2]}`;
      }
      if (Number.isNaN(parseInt(path[1]))) {
        return `${path[0]}.${path[1]}`;
      }
    }
    return path[0];
  };

  const errorsFormatted = () => {
    let mapErrors = [];
    if (csvErrors?.length > 0) {
      csvErrors.forEach((currentErr) => {
        if (currentErr) {
          const mapErrs = currentErr?.errors?.map((errs, errsKey) => ({
            beKey: errs.fullPath || joinPathKey(errs.path),
            message: errs.message,
            componentType: errs.componentType,
            path: errs.path,
            errsKey,
            row: currentErr.row,
          }));

          mapErrors = [...mapErrors, ...mapErrs];
        }
      });
    }

    mapErrors = mapErrors.map((error) => {
      if (error.beKey.split('.').length > 1) {
        return error;
      }
      const curlyBracketIndex = error.beKey.indexOf('[');
      if (curlyBracketIndex >= 0) {
        error.beKey = error.beKey.slice(0, curlyBracketIndex);
      }
      return error;
    });
    return mapErrors;
  };

  const getColumnErrorIndex = (row, currentIteration = 0, currentBeKey) => {
    const headers = [...orderedHeaders];

    const rowHasError = errorsFormatted()?.filter((rowErr) => rowErr.row === row + 1);

    if (rowHasError?.length > 0) {
      const columnsHasError = rowHasError.filter((rowErr) => rowErr.beKey === currentBeKey);
      if (columnsHasError.length > 0) {
        const index = headers.findIndex((header) => {
          if (header) {
            return header.beKey === columnsHasError[0]?.beKey;
          }

          return false;
        });

        return {
          index,
          message: columnsHasError[0]?.message,
          rowIndex: columnsHasError[0]?.row,
        };
      }

      if (rowHasError[currentIteration]?.path?.length > 0) {
        return {
          index: currentIteration,
          message: rowHasError[currentIteration]?.message,
          rowIndex: rowHasError[currentIteration]?.row,
        };
      }

      return {
        index: 0,
        message: rowHasError.map((_row) => `${_row?.componentType}:${_row?.message}`).join('. '),
        rowIndex: rowHasError[0]?.row,
      };
    }

    return {
      index: -1,
    };
  };

  const Cell = ({ columnIndex, rowIndex, style }) => {
    if (rowIndex === 0) {
      const header = orderedHeaders[columnIndex];
      return (
        <Box
          style={{
            ...style,
            background: '#6D738527',
            padding: 10,
          }}
          display="flex"
          height="41px"
          alignItems="center"
          border="1px solid #00000026"
        >
          {header?.text}
        </Box>
      );
    }

    const i = rowIndex - 1;
    const index = columnIndex;
    const row = rows[rowIndex - 1];
    const rowKeys = ['', ...Object.keys(row)];
    const rowKey = rowKeys[columnIndex];
    const headersKeys = [...orderedHeaders];
    headersKeys[0] = '';

    const { index: columnError, message } = getColumnErrorIndex(i, index, headersKeys[index]?.beKey);

    if (columnError !== -1 && columnError === columnIndex) {
      return (
        <Box
          key={index}
          style={{
            ...style,
            padding: 10,
            background: '#FCEFEF',
          }}
          position="relative"
          display="flex"
          alignItems="center"
          justifyContent={columnIndex === 0 ? 'center' : 'flex-start'}
          border="1px solid #00000026"
        >
          {columnIndex === 0 ? (
            <span>{i + 1}</span>
          ) : (
            <Typography style={{ color: '#FC0000', fontSize: 14 }}>{formatTxt(row[rowKey], 25)}</Typography>
          )}
          <Box position="absolute" style={{ right: 7, top: 11 }}>
            <Tooltip title={message} placement="left-start">
              <InfoIcon htmlColor="#FC0000" style={{ cursor: 'pointer' }} />
            </Tooltip>
          </Box>
        </Box>
      );
    }

    return (
      <Box
        style={{
          ...style,
          padding: 10,
          justifyContent: !rowKey ? 'center' : 'left',
        }}
        display="flex"
        alignItems="center"
        border="1px solid #00000026"
        position="relative"
      >
        {!rowKey ? i + 1 : formatTxt(row[rowKey], 25)}
        {!rowKey && getRowsWithErrors()?.includes(i + 1) && message && (
          <Box position="absolute" style={{ right: 7, top: 11 }}>
            <Tooltip title={message}>
              <InfoIcon htmlColor="#FC0000" style={{ cursor: 'pointer' }} />
            </Tooltip>
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Box width="100%">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        style={{ margin: '43px 0 18px' }}
      >
        <Typography
          style={{
            fontSize: 14,
            fontWeight: '500',
            paddingLeft: 20,
          }}
        >
          Importing {rows?.length || 0} rows
        </Typography>
        <Box display="flex" alignItems="center">
          <Button
            variant="outlined"
            className={classes.outlined}
            onClick={() => {
              setStep(1);
            }}
            style={{ marginRight: 20 }}
          >
            Previous Step
          </Button>
          <Button variant="contained" className={classes.button} onClick={() => setOpen(true)}>
            {loading === 'pending' ? <CircularProgress color="#fff" size="25px" /> : 'Continue'}
          </Button>
        </Box>
      </Box>

      <Box paddingLeft="20px">
        <Grid
          columnCount={orderedHeaders.length}
          columnWidth={(index) => (index === 0 ? 90 : 240)}
          height={500}
          rowCount={rows.length + 1}
          rowHeight={() => 50}
          width={1200}
        >
          {Cell}
        </Grid>
      </Box>

      <Modal
        aria-labelledby="transition-modal-title1"
        aria-describedby="transition-modal-description1"
        className={classes.modal}
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Typography style={{ fontSize: 20, fontWeight: '500' }}>Continue with import?</Typography>
            <Typography variant="body2">
              We found {errorsFormatted().length} errors.  <strong>Please note:</strong> Sivil cannot import rows with
              errors. Please select cancel to fix errors or import to proceed.
            </Typography>

            <Box display="flex" alignItems="center" justifyContent="flex-end" marginTop="20px">
              <Button variant="outlined" className={classes.buttonOutlined} onClick={() => setOpen(false)}>
                CANCEL
              </Button>
              <Button variant="outlined" className={classes.buttonOutlined} onClick={onImportForm}>
                {loading === 'pending' ? <CircularProgress color="#4b7bff" size="25px" /> : 'IMPORT'}
              </Button>
            </Box>
          </div>
        </Fade>
      </Modal>
      <Box marginBottom="75px" width="100%" />
    </Box>
  );
};

Step3.propTypes = {
  setStep: PropTypes.func.isRequired, // setStep prop must be a function and is required
  rows: PropTypes.array.isRequired, // rows prop must be an array and is required
  csvErrors: PropTypes.array, // csvErrors prop must be an array (optional, defaults to empty array)
  orderedHeaders: PropTypes.array.isRequired, // orderedHeaders prop must be an array and is required
  forms: PropTypes.array.isRequired, // forms prop must be an array and is required
  selectedModule: PropTypes.string.isRequired, // selectedModule prop must be a string and is required
  formTypeId: PropTypes.number.isRequired, // formTypeId prop must be a number and is required
};

export default Step3;
