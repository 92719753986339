import * as firebase from 'firebase';
import 'firebase/functions';
import 'firebase/firestore';
import 'firebase/analytics';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_PROJECT_ID,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

export const signInWithEmailAndPassword = (email, password) =>
  firebase.auth().signInWithEmailAndPassword(email, password);

export const signOut = () => firebase.auth().signOut();

export const logSessionWithToken = (token) => firebase.auth().signInWithCustomToken(token);

export const onAuthStateChanged = (callback) => firebase.auth().onAuthStateChanged(callback);

export const onResetUserPassword = (email) =>
  firebase.auth().sendPasswordResetEmail(email, {
    url: window.location.origin,
    handleCodeInApp: false,
  });

// SIGN IN WITH MICROSOFT

export const signInWithMicrosoft = async () => {
  try {
    const provider = await new firebase.auth.OAuthProvider('microsoft.com');
    provider.setCustomParameters({
      //prompt: 'consent',
      // tenant: '581a9dfe-ac7a-4aee-a4a0-37f07ca5b659',
    });
    const res = await firebase.auth().signInWithPopup(provider);
    return res;
  } catch (error) {
    //if (error.code === 'auth/account-exists-with-different-credential') {
    //  throw new Error('hola');
    //}
    console.log('El error es este', error);
    throw error;
  }
};

export const linkInWithMicrosoft = async () => {
  try {
    const provider = new firebase.auth.OAuthProvider('microsoft.com');
    const auth = firebase.auth();
    try {
      await auth.currentUser.linkWithPopup(provider);
    } catch (error) {
      console.log('Esta monda no funciona', error);
    }
  } catch (error) {
    //if (error.code === 'auth/account-exists-with-different-credential') {
    //  throw new Error('hola');
    //}
    console.log('El error es este', error);
    throw error;
  }
};

export const logEvent = (event, params) => firebase.analytics().logEvent(event, params);
