import { useContext, useState, useEffect } from 'react';

import { Context } from '../Context';
import { getOrganizationsByState } from '../services/unarmed';
import useCheckAppVersion from './useCheckAppVersion';

function getOrg(location) {
  const org = location.href.split('.')[0].split('//')[1];
  if (org.includes('localhost')) {
    return process.env.REACT_APP_SUBDOMAIN || 'dashboard-dev';
  }

  return org;
}

const useOrganization = () => {
  const [organization, setorganization] = useState(null);
  const { setRequireRefresh } = useContext(Context);
  const { onCheckAppVersion } = useCheckAppVersion(setRequireRefresh);
  const [refetch, setRefetch] = useState(false);
  const { location } = window;

  useEffect(() => {
    getOrganizationsByState(getOrg(location))
      .then((res) => {
        setorganization(res.data);
        onCheckAppVersion(res.headers['x-dashboard-version']);
      })
      .catch((err) => console.log(err));
  }, [refetch]);

  const getOrganizationCallback = () =>
    getOrganizationsByState(getOrg(location))
      .then(({ data }) => data)
      .catch((err) => console.log(err));

  return {
    organization,
    getOrganizationCallback,
    setRefetch,
  };
};

export default useOrganization;
