const es = {
  translations: {
    complaint: 'Reclamo',
    compliment: 'Cumplido',
    inquiry: 'Consulta',
    referral: 'Remisión',
    other: 'Otro',
    form: 'Formulario de',
    'Tracking Number': 'Número de Rastreo',
    Created: 'Creado por',
    'Personal Information': 'Información Personal',
    anonymous: 'Anónimo / No quiero compartir información personal',
    'Civilian First Name': 'Nombre',
    'Civilian Last Name': 'Apellido',
    'Email Address': 'Correo Electrónico',
    'Phone Number': 'Número de Teléfono',
    Address: 'Dirección',
    'Incident Details': 'Detalles del Incidente',
    whyWouldYouLike: '¿Por qué te gustaría agradecer a este empleado?',
    Statement: 'Declaración',
    links: 'Enlaces Externo del Incidente',
    location: 'Lugar del Incidente',
    date: 'Fecha del Incidente',
    time: 'Hora del Incidente',
    officers: 'Oficiales de Policia o Empleado(s)',
    'officer name': 'Nombre del Oficial',
    'officer lastName': 'Apellido del Oficial',
    'officer badge': 'Insignia / Número de Serie',
    witness: 'Testigo(s) u otros involucrado(s)',
    'witness name': 'Nombre del Testigo',
    'witness lastName': 'Apellido del Testigo',
    'additional information': 'Información Adicional',
    how: '¿Cómo te enteras de nosotros?',
    whatDescribeYou: '¿Qué te describe mejor?',
    birth: 'Fecha de Nacimiento',
    gender: 'Género',
    sOrientation: 'Orientación Sexual',
    race: 'Raza/Etnia',
    language: '¿Es el Inglés su idioma principal?',
    EventInfo: 'Información del Incidente',
    eventLocationL: 'Lugar del Incidente',
    eventDate: 'Fecha del Incidente',
    eventTime: 'Hora del Incidente',
  },
};

export default es;
