import { Box, Button, CircularProgress, Divider, makeStyles, Typography, FormControlLabel } from '@material-ui/core';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CustomSwitch } from '../../formEngine/components/WrapperComponent';
import { getOfficers, updateFilterConfigById } from '../../services/unarmed';
import { OutLineInput } from '../CaseDetails/UpdateCase';

const useStyles = makeStyles(() => ({
  button: {
    background: ' #1F63FF',
    textTransform: 'capitalize',
    color: '#fff',
    '&:hover': {
      background: '#1F63FF',
    },
    '& .MuiButton-label': {
      fontSize: 13,
    },
  },
}));

/**
 * OfficerFilter component for filtering officers based on criteria.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.onClick - Function to handle the cancel button click.
 * @param {Object} props.name - The name filter object.
 * @param {Object} props.badge - The badge filter object.
 * @param {number} props.page - The current page number.
 * @param {number} props.rowsPerPage - The number of rows per page.
 * @param {string} props.sortBy - The field to sort by.
 * @param {string} props.sort - The sort order ('asc' or 'desc').
 * @param {Function} props.setPage - Function to set the current page.
 * @param {Function} props.setIsFiltering - Function to set the filtering state.
 * @param {Function} props.setOfficers - Function to set the officers data.
 * @param {Function} props.onClearFilters - Function to clear all filters.
 * @returns {React.ReactElement} The OfficerFilter component.
 */
const OfficerFilter = ({
  onClick,
  name,
  badge,
  page,
  rowsPerPage,
  sortBy,
  sort,
  setPage,
  setIsFiltering,
  setOfficers,
  onClearFilters,
  filtersConfig,
  fetchFiltersConfig,
  filtersApplied,
  flaggedOnly,
  setfiltersConfig,
  filterConfigId,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const onFilter = async () => {
    try {
      setIsFiltering(true);
      setLoading(true);
      setPage(1);
      const filterSaved = {
        includeTotalCases: true,
        sortBy,
        sortOrder: sort,
        name: name.value,
        badgeNumber: badge.value,
        flaggedOnly: flaggedOnly.value,
      };
      setfiltersConfig({ ...filtersConfig, ...filterSaved });
      await updateFilterConfigById(filterConfigId, filterSaved);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleClearAll = async () => {
    onClearFilters();
    const filterSaved = {
      name: '',
      badgeNumber: '',
      flaggedOnly: false,
    };
    setfiltersConfig(null);
    localStorage.setItem('filters', JSON.stringify(filterSaved));
    await updateFilterConfigById(filterConfigId, filterSaved);
  };

  return (
    <Box
      boxShadow="0 4px 8px rgba(0,0,0,.1)"
      style={{ background: '#fff' }}
      padding="10px 20px"
      borderRadius="5px"
      marginTop="20px"
      onKeyPress={(e) => {
        const keycode = e.keyCode ? e.keyCode : e.which;
        if (parseInt(keycode) === 13) {
          onFilter();
        }
      }}
    >
      <Box display="flex" flexWrap="wrap" alignItems="center">
        <Box minWidth="100px" maxWidth="200px" width="100%" marginRight="20px" marginTop="10px">
          <Typography
            style={{
              display: 'block',
              fontSize: 13,
              fontWeight: 500,
              marginBottom: 5,
            }}
          >
            Badge/Serial Number
          </Typography>
          <OutLineInput
            bordered
            active={filtersApplied.badgeNumber}
            placeholder="Badge/Serial Number"
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              'aria-label': 'weight',
              'data-testid': 'officers-filters-input-badge-serial-number',
            }}
            value={badge.value || ''}
            onChange={badge.onChange}
            labelWidth={0}
          />
        </Box>

        <Box minWidth="100px" maxWidth="200px" width="100%" marginRight="20px" marginTop="10px">
          <Typography
            style={{
              display: 'block',
              fontSize: 13,
              fontWeight: 500,
              marginBottom: 5,
            }}
          >
            Officer Name
          </Typography>
          <OutLineInput
            bordered
            active={filtersApplied.name}
            placeholder="Name"
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              'aria-label': 'weight',
              'data-testid': 'officers-filters-input-officer-name',
            }}
            value={name.value || ''}
            onChange={name.onChange}
            labelWidth={0}
          />
        </Box>

        <Box minWidth="170px" maxWidth="180px" width="100%" display="flex" alignItems="center" marginTop="10px">
          <FormControlLabel
            style={{ margin: 0 }}
            control={<CustomSwitch checked={flaggedOnly.value} onChange={flaggedOnly.onChange} />}
            labelPlacement="start"
            label="Flagged only"
          />
        </Box>
      </Box>

      <Divider style={{ display: 'block', width: '100%', margin: '15px 0' }} />
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          <Typography
            data-testid="officers-filters-button-clear-all-filters"
            style={{
              fontSize: 13,
              fontWeight: '500',
              cursor: 'pointer',
              color: '#1F63FF',
            }}
            onClick={handleClearAll}
          >
            Clear All Filters
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Button
            data-testid="officers-filters-button-cancel"
            onClick={onClick}
            style={{ textTransform: 'capitalize' }}
          >
            Cancel
          </Button>
          <Button data-testid="officers-filters-button-apply" className={classes.button} onClick={onFilter}>
            {loading ? <CircularProgress color="#fff" size="25px" /> : 'Apply'}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

OfficerFilter.propTypes = {
  onClick: PropTypes.func.isRequired,
  name: PropTypes.shape({
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  badge: PropTypes.shape({
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  flaggedOnly: PropTypes.bool,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  sortBy: PropTypes.string.isRequired,
  sort: PropTypes.string.isRequired,
  setPage: PropTypes.func.isRequired,
  setIsFiltering: PropTypes.func.isRequired,
  setOfficers: PropTypes.func.isRequired,
  onClearFilters: PropTypes.func.isRequired,
  filtersConfig: PropTypes.object,
  fetchFiltersConfig: PropTypes.func,
  filtersApplied: PropTypes.object,
};

export default OfficerFilter;
