/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
/* eslint-disable no-nested-ternary */
import {
  Box,
  Typography,
  Button,
  Divider,
  Switch,
  RadioGroup,
  FormControlLabel,
  Radio,
  Popover,
  TextField,
  Select,
  MenuItem,
  IconButton,
  Checkbox,
  ListItemText,
} from '@material-ui/core';
import React, { useState, useEffect, useRef, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { ResultSet } from '@cubejs-client/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { toast } from 'react-toastify';
import moment from 'moment';
import PublicIcon from '@material-ui/icons/Public';
import CreateIcon from '@material-ui/icons/Create';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Cancel, ClearAll } from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import CancelIcon from '@material-ui/icons/Cancel';
import FixedTags from '../../../components/FixedTags';
import ReportItem from '../../../components/ReportItem';
import DropdownSelectableItem from '../../../components/DropdownItem';
import ReportFilterItem, { searchFilterItem } from '../../../components/ReportFilterItem';
import DashButton from '../../../components/DashButton';
import ExploreContent from './ExploreContent';
import { formatCubeJsTableColumns } from '../../../utils';
import { saveCube } from '../../../services/cube';
import { OutLineInput } from '../../CaseDetails/UpdateCase';
import { createReport, deleteReportbyId, scheduleAdHocReports } from '../../../services/unarmed';
import DatePickerMUI from '../../../components/DatePickerMUi';
import SelectBox from '../../../components/SelectBox';
import { Context } from '../../../Context';

export const dataRangeOptions = [
  'Custom',
  'All time',
  'Today',
  'Yesterday',
  'This week',
  'This month',
  'This quarter',
  'This year',
  'Last 7 days',
  'Last 30 days',
  'Last week',
  'Last month',
  'Last quarter',
  'Last year',
];

export const dayOption = ['w/o grouping', 'hour', 'day', 'week', 'month', 'year'];

const generalOperators = [
  { label: 'equals', value: 'equals' },
  { label: 'is not set', value: 'notSet' },
  { label: 'is set', value: 'set' },
  { label: 'does not equal', value: 'notEquals' },
];

export const operators = {
  number: [
    ...generalOperators,
    { label: '<', value: 'lt' },
    { label: '<=', value: 'lte' },
    { label: '>=', value: 'gte' },
    { label: '>', value: 'gt' },
  ],
  string: [
    { label: 'does not contain', value: 'notContains' },
    { label: 'contains', value: 'contains' },
    ...generalOperators,
  ],
  boolean: [...generalOperators],
  time: [
    { label: 'does not contain', value: 'notContains' },
    { label: 'contains', value: 'contains' },
    { label: 'In date range', value: 'inDateRange' },
    { label: 'Not in date range', value: 'notInDateRange' },
    { label: 'Before date', value: 'beforeDate' },
    { label: 'After date', value: 'afterDate' },
    ...generalOperators,
  ],
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '100%',
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: '500',
  },
  selectLabel: {
    fontSize: '12px',
    fontWeight: '500',
    color: '#5f6368',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
  },
  selectStyle: {
    width: '100%',
    fontSize: '14px',
    fontWeight: '500',
    color: '#3c4043',
  },
  labelText: {
    fontSize: '12px',
    color: '#5A5A5A',
  },
  timePicker: {
    fontSize: '12px',
    fontWeight: '500',
    color: '#3c4043',
    marginTop: theme.spacing(2),
  },
  cancelButton: {
    color: '#000000',
    fontSize: '12px',
    cursor: 'pointer',
    textTransform: 'none',
  },
  saveButton: {
    color: '#007bff',
    fontSize: '12px',
    cursor: 'pointer',
    textTransform: 'none',
  },
  popoverContent: {
    padding: theme.spacing(2),
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: '14px',
  },
  radioButton: {
    color: '#2A55FF',
    '&.Mui-checked': {
      color: '#2A55FF',
    },
  },
  buttonText: {
    fontSize: '14px',
    fontWeight: '500',
    color: '#1a73e8',
  },
  customRoot: {
    width: '250px',
    backgroundColor: '#f0f0f0',
    padding: '15px 16px',
  },
  prueba: {
    height: '250px',
  },
}));

const getFilters = (filters) =>
  filters
    .filter((filter) => filter.member && filter.operator && filter.values && filter.values.length > 0)
    .map((filter) => ({
      member: filter.member?.name || filter.member,
      operator: filter.operator?.value || filter.operator,
      values: Array.isArray(filter.values) ? filter.values : [filter.values],
    }));

export const searchFilterOperator = (operator, type) => {
  if (!type || !operator) return null;
  const operatorsByType = operators[type];
  const finedOperator = operatorsByType.find((opt) => opt.value === operator);
  return finedOperator;
};

export default function ExplorePanel({
  setDimensions,
  report,
  reportId,
  measures,
  dimensions,
  setMeasures,
  dimensionsTime,
  setDimensionsTime,
  daterange,
  setDaterange,
  granularity,
  setGranularity,
  from,
  setFrom,
  to,
  setTo,
  cubes,
  filters,
  setFilters,
  onResetFilter,
  onGetCube,
  onGetCubes,
  id,
  orgSubdomainDashboard,
  onClearReport,
  handleRefetch,
  addFilter,
}) {
  const { authUser } = useContext(Context);
  const classes = useStyles();
  const [isExpandedAll, setIsExpandedAll] = useState(false);
  const [isExpandedAdvancedFeatures, setIsExpandedAdvancedFeatures] = useState(false);
  const [isExpandedDropdown, setIsExpandedDropdown] = useState(false);
  const [cubeQuery, setCubeQuery] = useState({
    measures: [],
    timeDimensions: [],
    order: [],
    dimensions: [],
    filters: [],
    limit: 2500,
  });
  const [optionsmeausures, setOptionsmeausures] = useState([]);
  const [optionsDimension, setOptionsDimension] = useState([]);
  const [reportName, setReportName] = useState({ value: 'Unnamed Report' });
  const [reportDescription, setReportDescription] = useState({ value: 'No description provided' });
  const [isEdit, setIsEdit] = useState(false);
  const [isEditName, setIsEditName] = useState(false);
  const [isEditDescription, setIsEditDescription] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const [loading, setLoading] = useState(false);
  const [reports, setReports] = useState({
    data: [],
    columns: [],
    resultSet: null,
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id1 = open ? 'simple-popover' : undefined;
  const userEmailConfig = report?.emailConfiguration?.find(config => config.email === authUser?.email);
  const [enableNotifications, setEnableNotifications] = useState(false);
  const [time, setTime] = useState('00:00');
  const [frequency, setFrequency] = useState('daily');
  const [selectedDay, setSelectedDay] = useState([]);
  const [selectedDate, setSelectedDate] = useState([]);
  const [showTable, setShowTable] = useState(false);

  const [dimensionsv2, setDimensionsv2] = useState([]);
  const [Measurev2, setMeasurev2] = useState([]);
  const [checked, setChecked] = useState(true);

  const [currentReportId, setCurrentReportId] = useState(id || '');
  const [isSaveClicked, setIsSaveClicked] = useState(false);
  const timeoutDuration = 50000;
  const editBoxRef = useRef(null);
  const [showFilterBox, setShowFilterBox] = useState(false);
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    if (userEmailConfig?.sendEmail !== undefined) {
      setEnableNotifications(userEmailConfig.sendEmail);
    }
  }, [userEmailConfig]);

  const cancelFilter = () => {
    setShowFilterBox(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (editBoxRef.current && !editBoxRef.current.contains(event.target)) {
        setIsEdit(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [editBoxRef]);

  const exploreContentRef = useRef();

  const handleImageUpload = async () => {
    if (exploreContentRef.current) {
      try {
        const imageUrl = await exploreContentRef.current.handleImageUpload(reportName);
      } catch (error) {
        console.error('Error while uploading image:', error);
      }
    } else {
      console.error('ExploreContent ref is not available');
    }
  };

  const EditableReport = () => {
    const handleKeyDown = (event, setEditingState) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        setEditingState(false);
      }
    };

    return (
      <Box display="flex" flexDirection="column">
        <Box display="flex" alignItems="center">
          {isEditName ? (
            <Box width={250}>
              <TextField
                autoFocus
                placeholder="Report Name"
                value={reportName.value || ''}
                onChange={(e) => setReportName({ value: e.target.value })}
                onBlur={() => setIsEditName(false)}
                onKeyDown={(e) => handleKeyDown(e, setIsEditName)}
                fullWidth
                variant="outlined"
              />
            </Box>
          ) : (
            <Typography
              style={{ fontSize: 15, fontWeight: '500', cursor: 'pointer' }}
              onClick={() => setIsEditName(true)}
            >
              {reportName.value || 'Unnamed Report'}
            </Typography>
          )}
        </Box>

        <Box display="flex" alignItems="center" width="100%">
          {isEditDescription ? (
            <Box width={500}>
              <TextField
                autoFocus
                placeholder="Report Description"
                value={reportDescription.value || ''}
                onChange={(e) => setReportDescription({ value: e.target.value })}
                onBlur={() => setIsEditDescription(false)}
                onKeyDown={(e) => handleKeyDown(e, setIsEditDescription)}
                fullWidth
                variant="outlined"
              />
            </Box>
          ) : (
            <Box display="flex" alignItems="center" width="100%">
              <Typography
                style={{
                  fontSize: 13,
                  maxWidth: '50ch',
                  whiteSpace: 'normal',
                  wordBreak: 'break-word',
                }}
              >
                {reportDescription.value || 'No description provided'}
              </Typography>

              <CreateIcon
                style={{ marginLeft: 8, cursor: 'pointer', fontSize: 13 }}
                htmlColor="#6D7385"
                onClick={() => setIsEditDescription(true)}
              />
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  useEffect(() => {
    if (Array.isArray(cubes) && cubes.length > 0) {
      setDimensionsv2([]);
      const loadedDimensions = cubes.flatMap((cubo) =>
        cubo.dimensions.map((dimension) => {
          const [category, field] = dimension.name.split('.');
          const concatenatedField = `${category || ''}.${field}`;

          const isSelected =
            report?.query?.dimensions?.some((savedDimension) => {
              const [savedCategory, savedField] = savedDimension.split('.');
              if (!savedCategory) {
                return savedField === field;
              }
              return savedDimension === dimension.name;
            }) || false;

          return {
            category,
            field,
            label: `${category}.${field}`,
            name: dimension.name,
            selected: isSelected,
          };
        })
      );

      setDimensionsv2((prevDimensions) => {
        const newDimensions = JSON.stringify(loadedDimensions);
        const prevDimensionsString = JSON.stringify(prevDimensions);

        if (prevDimensionsString !== newDimensions) {
          return loadedDimensions;
        }
        return prevDimensions;
      });

      setDimensionsv2((prevDimensions) => {
        const newDimensions = JSON.stringify(loadedDimensions);
        const prevDimensionsString = JSON.stringify(prevDimensions);

        if (prevDimensionsString !== newDimensions) {
          return loadedDimensions;
        }
        return prevDimensions;
      });

      const loadedMeasures = cubes.flatMap((cubo) =>
        cubo.measures.map((measure) => {
          const [category] = measure.title.split(' - ');
          const field = measure.name;
          const isSelected = report?.query?.measures?.includes(measure.name) || false;
          return { category, field, selected: isSelected, label: `${field}` };
        })
      );

      setMeasurev2((prevMeasures) => {
        const newMeasures = JSON.stringify(loadedMeasures);
        const prevMeasuresString = JSON.stringify(prevMeasures);

        if (prevMeasuresString !== newMeasures) {
          return loadedMeasures;
        }
        return prevMeasures;
      });

      setShowTable(false);
    }
  }, [cubes, report, refreshKey]);

  useEffect(() => {
    if (report) {
      const userEmailConfig = report?.emailConfiguration?.find(
        (config) => config?.email === authUser?.email
      );

      setReportName({ value: report?.name });
      setReportDescription({ value: report?.description });
      setTime(userEmailConfig?.specificTime || '00:00');
      setFrequency(userEmailConfig?.frequency ? userEmailConfig.frequency.toLowerCase() : 'daily');
      setSelectedDate(userEmailConfig?.daysOfMonth);
      setSelectedDay(userEmailConfig?.daysOfWeek);
    }
    if (cubes.length && report) {
      const query = report?.query;

      if (!query) {
        return;
      }

      const QueryDimensions = query?.dimensions;
      const Querymeasures = query?.measures;
      const QueryTimeDimensions = query?.timeDimensions?.[0];

      const getFetchQuery = (arrQuery, key) => {
        const dd = arrQuery?.map((qd) => {
          const splited = qd.split('.')[0];

          const colected = [];
          cubes.forEach((cb) => {
            if (cb.name === splited) {
              cb[key].forEach((itemSh) => {
                if (itemSh.name === qd) {
                  colected.push(itemSh);
                }
              });
            }
          });

          return colected[0];
        });

        return dd;
      };

      const getFetchTimeQuery = () => {
        const splited = QueryTimeDimensions?.dimension?.split('.')[0];

        const colected = [];
        cubes.forEach((cb) => {
          if (cb.name === splited) {
            cb.dimensions.forEach((itemSh) => {
              if (itemSh.name === QueryTimeDimensions?.dimension) {
                colected.push(itemSh);
              }
            });
          }
        });

        return colected[0];
      };

      const dimensionsFetched = getFetchQuery(QueryDimensions, 'dimensions');
      const measuresFetched = getFetchQuery(Querymeasures, 'measures');
      const timeDimensionFetched = getFetchTimeQuery();

      const filtersFetched = query?.filters?.map((filter) => {
        const member = searchFilterItem(filter?.member, cubes);
        const operator = searchFilterOperator(filter?.operator, member?.type);

        return {
          member,
          operator,
          values: filter?.values?.length > 1 ? filter?.values : filter?.values?.[0] || null,
        };
      });

      let dimensionsItems = [];
      let measuresItems = [];
      let timeDimensionsItems = null;

      if (dimensionsFetched?.length) {
        const dimensionsFetchedMapped = dimensionsFetched.map((dimension, index) => ({
          label: 'Add dimension',
          id: `dimension${index + 1}`,
          order: index + 1,
          value: dimension,
        }));
        dimensionsItems = dimensionsFetchedMapped.filter((dimension) => dimension.value);
        setDimensions(dimensionsFetchedMapped);
      }

      if (filtersFetched?.length) {
        setFilters(filtersFetched);
      }
      if (measuresFetched?.length) {
        const measuresFetchedMapped = measuresFetched.map((measure, index) => ({
          label: 'Add measure',
          value: measure,
          id: `measure${index + 1}`,
          order: index + 1,
        }));
        measuresItems = measuresFetchedMapped.filter((measure) => measure.value);
        setMeasures(measuresFetchedMapped);
      }

      if (timeDimensionFetched) {
        const dimensionTimeObj = {
          ...dimensionsTime,
          value: timeDimensionFetched,
        };

        setDimensionsTime(dimensionTimeObj);
        timeDimensionsItems = dimensionTimeObj.value;
        setGranularity(QueryTimeDimensions?.granularity);
        if (typeof QueryTimeDimensions?.dateRange === 'string') {
          setDaterange(QueryTimeDimensions?.dateRange);
        } else {
          setFrom(QueryTimeDimensions?.dateRange?.[0]);
          setTo(QueryTimeDimensions?.dateRange?.[1]);
        }
      }

      setReports({
        data: report?.resultSet ? ResultSet.deserialize(report?.resultSet)?.tablePivot() : null,
        columns: report?.resultSet
          ? ResultSet.deserialize(report?.resultSet)
              ?.tableColumns()
              ?.map((column) => ({
                ...column,
                title: formatCubeJsTableColumns(column.title, column.key),
              }))
          : null,

        resultSet: report?.resultSet ? ResultSet.deserialize(report?.resultSet) : null,
      });
      const currentDimensions = dimensionsItems.map((dimItem) => dimItem.value.name);
      const currentMeasures = measuresItems.map((meaItem) => meaItem.value.name);

      const currentTimeDimensions = [];

      if (timeDimensionsItems) {
        currentTimeDimensions.push({
          ...(timeDimensionsItems && { dimension: timeDimensionsItems?.name }),
          ...(QueryTimeDimensions?.granularity === 'w/o grouping'
            ? {}
            : { granularity: QueryTimeDimensions?.granularity?.toLowerCase() }),
          ...(QueryTimeDimensions?.dateRange === 'All time'
            ? {}
            : QueryTimeDimensions?.dateRange === 'Custom'
            ? from && to
              ? {
                  dateRange: [moment(from).format('YYYY-MM-DD'), moment(to).format('YYYY-MM-DD')],
                }
              : {}
            : {
                dateRange: QueryTimeDimensions?.dateRange,
              }),
        });
      }

      if (currentDimensions.length > 0 || currentMeasures.length > 0 || timeDimensionsItems) {
        setCubeQuery({
          ...cubeQuery,
          dimensions: currentDimensions,
          measures: currentMeasures,
          timeDimensions: currentTimeDimensions,
          filters: getFilters(filtersFetched),
        });
      }
    }
  }, [cubes, report]);

  const deleteReportIfNotSaved = async (reportId) => {
    if (!isSaveClicked && reportId) {
      try {
        await deleteReportbyId(reportId);
        toast.error('The report was deleted because it was not saved.');
      } catch (error) {
        console.error('Error deleting the report:', error);
      }
    }
  };

  useEffect(() => {
    let deleteTimeout;
    if (!isSaveClicked && currentReportId) {
      deleteTimeout = setTimeout(() => {
        deleteReportIfNotSaved(currentReportId);
      }, timeoutDuration);
    }

    return () => {
      if (deleteTimeout) {
        clearTimeout(deleteTimeout);
      }
    };
  }, [isSaveClicked, currentReportId]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFrequencyChange = (event) => {
    setFrequency(event.target.value);
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const currentReportIdsave = id || '';

  const handleEmailScheduleSave = async () => {
    if (frequency=='daily') {
      setSelectedDate([])
      setSelectedDay([])
    } else if (frequency=='weekly') {
      setSelectedDate([]);
    } else if (frequency=='monthly') {
      setSelectedDay([]);
    }

    const emailConfiguration = {
      email: authUser?.email,
      sendEmail: enableNotifications,
      frequency: frequency.toUpperCase() || '',
      specificTime: time || '',
      daysOfWeek: selectedDay || [],
      daysOfMonth: selectedDate || []
    };

    try {
      const response = await scheduleAdHocReports(reportId, emailConfiguration);

      if (response.status === 200) {
        toast.success('Schedule set!');
        handleClose();
      }
    } catch (error) {
      toast.error('Failed to set email schedule.');
      handleClose();
      console.error('Failed to save settings:', error);
    }
  };

  const handleEmailScheduleOnOffToggle = async (isEnabled) => {
    if (frequency=='daily') {
      setSelectedDate([])
      setSelectedDay([])
    } else if (frequency=='weekly') {
      setSelectedDate([]);
    } else if (frequency=='monthly') {
      setSelectedDay([]);
    }

    const emailConfiguration = {
      email: authUser?.email,
      sendEmail: isEnabled,
      frequency: frequency.toUpperCase() || '',
      specificTime: time || '',
      daysOfWeek: selectedDay || [],
      daysOfMonth: selectedDate || []
    };

    try {
      const response = await scheduleAdHocReports(reportId, emailConfiguration);
    } catch (error) {
      toast.error('Failed to set email schedule.');
      handleClose();
      console.error('Failed to save settings:', error);
    }
  };

  const handleSave = async () => {
    try {
      setIsSaveClicked(true);
      setLoading(true);

      let currentReportIdsave = id || '';

      if (reportId && reportId.trim() !== '') {
        currentReportIdsave = reportId;
      } else {
        currentReportIdsave = currentReportId;
      }

      if (!currentReportIdsave) {
        const newReportData = {
          name: reportName?.value || 'Unnamed report',
          description: reportDescription?.value || 'No description provided',
          type: 'GLOBAL',
        };

        const { data } = await createReport(newReportData);
        currentReportIdsave = data.id;

        await saveCube(currentReportIdsave, {
          query: { ...cubeQuery, ...prepareReport() },
          ...(reportName?.value ? { name: reportName.value } : {}),
          ...(reportDescription?.value ? { description: reportDescription.value } : {}),
        });

        handleImageUpload();

        toast.success('Report Created Successfully');
      } else {
        if (!currentReportIdsave) {
          throw new Error('Report ID is undefined or null.');
        }

        await saveCube(currentReportIdsave, {
          query: { ...cubeQuery, ...prepareReport() },
          ...(reportName?.value ? { name: reportName.value } : {}),
          ...(reportDescription?.value ? { description: reportDescription.value } : {}),
        });

        handleImageUpload();
        toast.success('Report Updated Successfully');
        setIsEdit(false);

        if (typeof handleRefetch === 'function') {
          handleRefetch();
        } else {
          console.warn('handleRefetch is not defined or not a function');
        }
      }
    } catch (error) {
      console.error('Detailed error:', error);
      console.error('Error response data:', error.response?.data);

      const errorMessage = error.response?.data?.message || error.message || 'An unknown error occurred';
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const toggleShowAll = () => {
    setIsExpandedAll(!isExpandedAll);
    setShowAll((prevShowAll) => !prevShowAll);
  };

  const toggleDropDown = () => {
    setIsExpandedDropdown(!isExpandedDropdown);
  };

  const toggleAdvancedFilters = () => {
    setIsExpandedAdvancedFeatures(!isExpandedAdvancedFeatures);
  };

  const handleDayChange = (event) => {
    const { value } = event.target;
    setSelectedDay(typeof value === 'string' ? value.split(',') : value);
  };

  const handleDateChange = (event) => {
    const { value } = event.target;
    setSelectedDate(typeof value === 'number' ? value.split(',') : value);
  };

  const handleTimeChange = (event) => {
    setTime(event.target.value);
  };

  const handleMeasureSelection = (selectedMeasures) => {
    const selectedFields = selectedMeasures.map((measure) => measure.field);
    setMeasurev2((prevMeasures) =>
      prevMeasures.map((measure) => ({
        ...measure,
        selected: selectedFields.includes(measure.field),
      }))
    );
  };

  useEffect(() => {
    const values = measures.map((option) => ({
      category: option?.value?.title,
      field: option?.value?.name,
      label: option?.value?.name,
      selected: true,
    }));
    setOptionsmeausures(values);
  }, [measures]);

  useEffect(() => {
    const values = dimensions.map((option) => {
      const name = option?.value?.name || '';
      const [category, field] = name.split('.');
      return {
        category: category || '',
        field: field || '',
        label: `${category}.${field}`,
        selected: true,
      };
    });
    setOptionsDimension(values);
  }, [dimensions]);

  const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

  const handleDimensionSelection = (selectedDimensions) => {
    const selectedFields = selectedDimensions
      .filter((dimension) => dimension.field && dimension.category)
      .map((dimension) => {
        const capitalizedCategory = capitalizeFirstLetter(dimension.category);
        // Aquí ajustamos la lógica para evitar la duplicación
        const fullField = dimension.field.includes(`${capitalizedCategory}.`)
          ? dimension.field
          : `${capitalizedCategory}.${dimension.field}`;
        return {
          category: capitalizedCategory,
          field: fullField,
        };
      });

    const updatedDimensions = dimensionsv2.map((dimension) => {
      const capitalizedCategory = capitalizeFirstLetter(dimension.category);
      const formattedField = dimension.field.includes(`${capitalizedCategory}.`)
        ? dimension.field
        : `${capitalizedCategory}.${dimension.field}`;

      const isSelected = selectedFields.some((selected) => selected.field === formattedField);

      return {
        ...dimension,
        field: formattedField,
        selected: isSelected,
      };
    });

    setDimensionsv2(updatedDimensions);
  };

  const buildQuery = async () => {
    let currentReportId = id || '';

    if (reportId && reportId.trim() !== '') {
      currentReportId = reportId;
    }

    if (!currentReportId) {
      const newReportData = {
        name: reportName?.value || 'Unnamed report',
        description: reportDescription?.value || 'No description provided',
        type: 'GLOBAL',
      };
      const { data } = await createReport(newReportData);
      currentReportId = data.id;
      handleImageUpload();

      setCurrentReportId(currentReportId);

      await saveCube(currentReportId, {
        query: { ...cubeQuery, ...prepareReport() },
        ...(reportName?.value ? { name: reportName.value } : {}),
        ...(reportDescription?.value ? { description: reportDescription.value } : {}),
      });
    }

    const selectedDimensions = optionsDimension.filter(
      (dimension) => dimension.selected && dimension.label !== undefined
    );
    const dimensionItems = selectedDimensions.map((dim) => dim.label);
    const dimensionItemsnue = selectedDimensions.map((dim) => dim.label);

    const selectedMeasures = optionsmeausures.filter((measure) => measure.selected && measure.label !== undefined);
    const measureItems = selectedMeasures.map((mea) => mea.label);
    const measureItemsnue = selectedMeasures.map((mea) => mea.label);

    const dimensionsItems = dimensionsv2.filter((dim) => dim.selected);
    const dimensionit = dimensionsItems.map((dim) => dim.label);
    const filteredDimensions = dimensionsItems
      .filter((dim) => dimensionItems.includes(dim.label))
      .map((dim) => dim.label);

    const measuresItems = Measurev2.filter((measure) => measure.selected);
    const measuresinit = measuresItems.map((measure) => measure.label);

    const filteredMeausures = measuresItems.filter((mea) => measureItems.includes(mea.label)).map((mea) => mea.label);

    const timeDimensionsItems = dimensionsTime?.value;
    const currentDimensions = [
      ...new Set([...filteredDimensions, ...dimensionItemsnue]),
    ].filter(item => !item.includes('undefined'));

    const currentMeasures = [...new Set([...filteredMeausures, ...measureItemsnue])];

    const currentTimeDimensions = [];
    if (timeDimensionsItems) {
      currentTimeDimensions.push({
        ...(timeDimensionsItems && { dimension: timeDimensionsItems?.name }),
        ...(granularity === 'w/o grouping' ? {} : { granularity: granularity?.toLowerCase() }),
        ...(daterange === 'All time'
          ? {}
          : daterange === 'Custom'
          ? from && to
            ? {
                dateRange: [moment(from).format('YYYY-MM-DD'), moment(to).format('YYYY-MM-DD')],
              }
            : {}
          : {
              dateRange: daterange,
            }),
      });
    }

    if (currentDimensions.length > 0 || currentMeasures.length > 0 || timeDimensionsItems) {
      setCubeQuery({
        ...cubeQuery,
        dimensions: currentDimensions,
        measures: currentMeasures,
        timeDimensions: currentTimeDimensions,
        filters: getFilters(filters),
      });

      setLoading(true);

      const queryData = {
        ...cubeQuery,
        dimensions: currentDimensions,
        measures: currentMeasures,
        timeDimensions: currentTimeDimensions,
        filters: getFilters(filters),
      };

      await saveCube(currentReportId, {
        query: {
          ...cubeQuery,
          dimensions: currentDimensions,
          measures: currentMeasures,
          timeDimensions: currentTimeDimensions,
          filters: getFilters(filters),
        },
        preview: true,
      })
        .then(({ data }) => {
          setReports({
            data: ResultSet.deserialize(data?.resultSet)?.tablePivot(),
            columns: ResultSet.deserialize(data?.resultSet)
              ?.tableColumns()
              ?.map((column) => ({
                ...column,
                title: formatCubeJsTableColumns(column.title, column.key),
              })),
            resultSet: ResultSet.deserialize(data?.resultSet),
          });

          setIsSaveClicked(false);
          toast.success('Preview report');
        })
        .catch((err) => {
          console.error('Error saving cube query:', err);

          if (err.message === 'Network Error') {
            toast.error('Something went wrong with the Report, please try again later');
          } else if (err.response && err.response.data && err.response.data.message) {
            toast.error(`API Error: ${err.response.data.message}`);
            console.error('API Error Details:', err.response.data);
          } else {
            toast.error('An unexpected error occurred. Please try again.');
          }
        })
        .finally(() => {
          setLoading(false);
        });

      setIsSaveClicked(false);
    }
  };

  const prepareReport = () => {
    const selectedDimensions = optionsDimension.filter(
      (dimension) => dimension.selected && dimension.label !== undefined
    );
    const dimensionItems = selectedDimensions.map((dim) => dim.label);
    const dimensionItemsnue = selectedDimensions.map((dim) => dim.label);

    const selectedMeasures = optionsmeausures.filter((measure) => measure.selected && measure.label !== undefined);
    const measureItems = selectedMeasures.map((mea) => mea.label);

    const measureItemsnue = selectedMeasures.map((mea) => mea.label);

    const dimensionsItems = dimensionsv2.filter((dim) => dim.selected);
    const dimensionit = dimensionsItems.map((dim) => dim.label);

    const filteredDimensions = dimensionsItems
      .filter((dim) => dimensionItems.includes(dim.label))
      .map((dim) => dim.label);

    const measuresItems = Measurev2.filter((measure) => measure.selected);
    const measuresinit = measuresItems.map((measure) => measure.label);

    const filteredMeausures = measuresItems.filter((mea) => measureItems.includes(mea.label)).map((mea) => mea.label);

    const timeDimensionsItems = dimensionsTime?.value;

    const currentDimensions = [
      ...new Set([
        ...filteredDimensions, // Dimensiones antiguas
        ...dimensionItemsnue,
      ]),
    ];

    // Combinar nuevas y antiguas medidas sin duplicados
    const currentMeasures = [
      ...new Set([
        ...filteredMeausures, // Medidas antiguas
        ...measureItemsnue,
      ]),
    ];

    const currentTimeDimensions = [];
    if (timeDimensionsItems) {
      currentTimeDimensions.push({
        ...(timeDimensionsItems && { dimension: timeDimensionsItems?.name }),
        ...(granularity === 'w/o grouping' ? {} : { granularity: granularity?.toLowerCase() }),
        ...(daterange === 'All time'
          ? {}
          : daterange === 'Custom'
          ? from && to
            ? { dateRange: [from, to] }
            : {}
          : { dateRange: daterange }),
      });
    }

    return {
      dimensions: currentDimensions,
      measures: currentMeasures,
      timeDimensions: currentTimeDimensions,
      filters: getFilters(filters),
    };
  };

  return (
    <Box className={classes.container}>
      <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="20px" width="100%">
        <Box display="flex" alignItems="flex-start" flexGrow={1}>
          <Box display="flex" flexDirection="column">
            <EditableReport />
            {!report && (
              <Box display="flex" alignItems="center" marginTop="10px">
                <RadioGroup
                  value={checked ? 'global' : 'private'}
                  onChange={(event) => {
                    const { value } = event.target;
                    setChecked(value === 'global');
                  }}
                  row
                >
                  <FormControlLabel
                    value="global"
                    control={<Radio color="primary" />}
                    label={
                      <Box display="flex" alignItems="center">
                        <PublicIcon htmlColor="#6D7385" style={{ marginRight: 6, fontSize: 18 }} />
                        <Typography
                          style={{
                            color: '#6D7385',
                            fontSize: 12,
                            textTransform: 'capitalize',
                          }}
                        >
                          Global
                        </Typography>
                      </Box>
                    }
                  />
                  <FormControlLabel
                    value="private"
                    control={<Radio color="primary" />}
                    label={
                      <Box display="flex" alignItems="center">
                        <LockOutlinedIcon htmlColor="#6D7385" style={{ marginRight: 6, fontSize: 18 }} />
                        <Typography
                          style={{
                            color: '#6D7385',
                            fontSize: 12,
                            textTransform: 'capitalize',
                          }}
                        >
                          Private
                        </Typography>
                      </Box>
                    }
                  />
                </RadioGroup>
              </Box>
            )}
            {report?.type === 'GLOBAL' && (
              <Box display="flex" alignItems="center" style={{ marginTop: 6 }}>
                <PublicIcon htmlColor="#6D7385" style={{ marginRight: 6, fontSize: 18 }} />
                <Typography
                  style={{
                    color: '#6D7385',
                    fontSize: 12,
                    textTransform: 'capitalize',
                  }}
                >
                  Global
                </Typography>
              </Box>
            )}
            {report?.type === 'PRIVATE' && (
              <Box display="flex" alignItems="center" style={{ marginTop: 6 }}>
                <LockOutlinedIcon htmlColor="#6D7385" style={{ marginRight: 6, fontSize: 18 }} />
                <Typography
                  style={{
                    color: '#6D7385',
                    fontSize: 12,
                    textTransform: 'capitalize',
                  }}
                >
                  Private
                </Typography>
              </Box>
            )}
          </Box>

          <Box display="flex" alignItems="flex-start" style={{ marginLeft: 'auto' }}>
            {reportId && (
              <Button
                variant="contained"
                color="primary"
                style={{
                  marginRight: '10px',
                  fontSize: '25px',
                  textTransform: 'none',
                  color: '#ffffff',
                  backgroundColor: '#1f63ff',
                  padding: '6px 16px',
                  borderRadius: '6px',
                  boxShadow: 'none',
                  minWidth: '120px',
                }}
                endIcon={<ArrowDropDownIcon />}
                onClick={handleClick}
              >
                Schedule
              </Button>
            )}

            <DashButton
              dataTestId="report-details-button-run-report"
              onClick={buildQuery}
              disabled={loading}
              variant="outlined"
              style={{
                marginRight: '10px',
                fontSize: '25px',
                borderColor: '#007bff',
                color: '#007bff',
                backgroundColor: '#ffffff',
                minWidth: '120px',
                padding: '6px 16px',
              }}
            >
              Run Report
            </DashButton>
            <DashButton
              variant="outlined"
              style={{
                marginRight: '10px',
                fontSize: '25px',
                borderColor: '#007bff',
                color: '#007bff',
                backgroundColor: '#ffffff',
                minWidth: '120px',
                padding: '6px 16px',
              }}
              onClick={onClearReport}
            >
              Clear Report
            </DashButton>

            <Button
              variant="contained"
              color="primary"
              style={{
                marginRight: '10px',
                fontSize: '25px',
                textTransform: 'none',
                color: '#ffffff',
                backgroundColor: '#1f63ff',
                borderRadius: '6px',
                boxShadow: 'none',
                minWidth: '80px',
                padding: '6px 16px',
              }}
              onClick={handleSave}
            >
              Save
            </Button>
          </Box>
        </Box>

        <Popover
          id={id1}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Box
            padding="16px"
            className={classes.popoverContent}
            style={{ border: '1px solid #ccc', borderRadius: '8px' }}
          >
            <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="12px">
            <Typography className={classes.labelText} style={{ fontSize: '14px' }}>
              Enable email notifications
            </Typography>
            <Switch
              checked={enableNotifications}
              onChange={(e) => {
                const isEnabled = e.target.checked;
                setEnableNotifications(isEnabled);
                handleEmailScheduleOnOffToggle(isEnabled)
              }}
              name="enableNotifications"
              color="primary"
            />
            </Box>

            <RadioGroup aria-label="frequency" name="frequency" value={frequency} onChange={handleFrequencyChange}>
              <FormControlLabel
                value="daily"
                control={<Radio className={classes.radioButton} />}
                label={<Typography className={classes.labelText}>Every day</Typography>}
                disabled={!enableNotifications}
              />
              {frequency === 'daily' && (
                <>
                  <Typography className={classes.selectLabel}>Select time</Typography>
                  <TextField
                    type="time"
                    value={time}
                    onChange={(e) => setTime(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300,
                      style: { fontSize: '14px' },
                      maxLength: 5,
                    }}
                    disabled={!enableNotifications}
                    style={{ marginBottom: '12px' }}
                    fullWidth
                  />
                  <Box className={classes.buttonContainer}>
                    <Button onClick={handleClose} className={classes.cancelButton}>
                      cancel
                    </Button>
                    <Button onClick={handleEmailScheduleSave} className={classes.saveButton}>
                      save
                    </Button>
                  </Box>
                </>
              )}

              {/* Every week option */}
              <FormControlLabel
                value="weekly"
                control={<Radio className={classes.radioButton} />}
                label={<Typography className={classes.labelText}>Every Week</Typography>}
                disabled={!enableNotifications}
              />
              {frequency === 'weekly' && (
                <Box marginLeft="24px">
                  <Typography className={classes.selectLabel}>Select day(s)</Typography>
                  <Select
                    multiple
                    value={selectedDay}
                    onChange={handleDayChange}
                    fullWidth={false}
                    renderValue={(selected) => {
                      if (selected.length > 2) {
                        return `${selected.slice(0, 2).join(', ')}...`;
                      }
                      return selected.join(', ');
                    }}
                    style={{ width: '150px' }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 300,
                          width: '150px',
                        },
                      },
                    }}
                  >
                    {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day) => (
                      <MenuItem key={day} value={day} style={{ padding: '2px 8px' }}>
                        <Checkbox
                          checked={selectedDay?.indexOf(day) > -1}
                          onChange={handleDayChange}
                          style={{ padding: '2px', transform: 'scale(0.5)' }}
                          color="primary"
                        />
                        <ListItemText primary={day} style={{ margin: '0', padding: '0', fontSize: '14px' }} />
                      </MenuItem>
                    ))}
                  </Select>
                  <Box marginTop="12px">
                    <Typography className={classes.selectLabel}>Select time</Typography>
                    <TextField
                      type="time"
                      value={time}
                      onChange={(e) => setTime(e.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 300,
                        style: { fontSize: '14px' },
                        maxLength: 5,
                      }}
                      disabled={!enableNotifications}
                      fullWidth
                    />
                  </Box>
                  <Box className={classes.buttonContainer}>
                    <Button onClick={handleClose} className={classes.cancelButton}>
                      cancel
                    </Button>
                    <Button onClick={handleEmailScheduleSave} className={classes.saveButton}>
                      save
                    </Button>
                  </Box>
                </Box>
              )}

              {/* Every Month option */}
              <FormControlLabel
                value="monthly"
                control={<Radio className={classes.radioButton} />}
                label={<Typography className={classes.labelText}>Every Month</Typography>}
                disabled={!enableNotifications}
              />
              {frequency === 'monthly' && (
                <Box marginLeft="24px">
                  <Typography className={classes.selectLabel}>Select day of the month</Typography>
                  <Select
                    value={selectedDate}
                    onChange={handleDateChange}
                    fullWidth={false}
                    multiple
                    renderValue={(selected) => selected.join(', ')}
                    style={{ width: '150px' }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 300,
                          width: '150px',
                        },
                      },
                    }}
                  >
                    {Array.from({ length: 31 }, (_, i) => (
                      <MenuItem key={i + 1} value={i + 1} style={{ padding: '2px 8px' }}>
                        <Checkbox
                          checked={selectedDate?.indexOf(i + 1) > -1}
                          style={{ padding: '2px', transform: 'scale(0.5)' }}
                          color="primary"
                        />
                        <ListItemText primary={i + 1} style={{ margin: '0', padding: '0', fontSize: '14px' }} />
                      </MenuItem>
                    ))}
                  </Select>

                  <Box marginTop="12px">
                    <Typography className={classes.selectLabel}>Select time</Typography>
                    <TextField
                      type="time"
                      value={time}
                      onChange={(e) => setTime(e.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 300,
                        style: { fontSize: '14px' },
                        maxLength: 5,
                      }}
                      disabled={!enableNotifications}
                      fullWidth
                    />
                  </Box>
                  <Box className={classes.buttonContainer}>
                    <Button onClick={handleClose} className={classes.cancelButton}>
                      cancel
                    </Button>
                    <Button onClick={handleEmailScheduleSave} className={classes.saveButton}>
                      save
                    </Button>
                  </Box>
                </Box>
              )}
            </RadioGroup>
          </Box>
        </Popover>
      </Box>

      <Divider />

      {!isExpandedAll ? (
        <Box display="flex" flexDirection="row" alignItems="center" padding="10px 0" overflow="visible">
          {/* Dimensions Box */}
          <Box display="flex" flexDirection="column" width="30%" paddingRight="20px" marginTop="5px">
            <Typography className={classes.sectionTitle} style={{ marginBottom: '4px', fontSize: '0.875rem' }}>
              Dimensions
              <Tooltip
                title="Dimensions is used to provide discrete categorical information. These values can be of any type - string, date time, or numeric."
                placement="top"
                arrow
              >
                <InfoIcon style={{ fontSize: '12px', color: 'blue', marginLeft: '8px', cursor: 'pointer' }} />
              </Tooltip>
            </Typography>
            <FixedTags
              type="Dimension"
              defaultTags={optionsDimension}
              options={dimensionsv2}
              limitTags
              withAddBtn
              onChange={(newValue) => setOptionsDimension(newValue)}
              getOptionLabel={(option) => option.field}
              isOptionSelected={(newValue) => setOptionsDimension(newValue)}
              style={{ height: '20px', width: '100%' }}
              toggleShowAll={toggleDropDown}
            />
          </Box>

          {/* Measures Box */}
          <Box display="flex" flexDirection="column" width="30%" paddingRight="20px" marginTop="5px">
            <Typography className={classes.sectionTitle} style={{ marginBottom: '4px', fontSize: '0.875rem' }}>
              Measures
              <Tooltip
                title="Measure is used to identify the measure using the specified data member and summary type."
                placement="top"
                arrow
              >
                <InfoIcon style={{ fontSize: '12px', color: 'blue', marginLeft: '8px', cursor: 'pointer' }} />
              </Tooltip>
            </Typography>
            <FixedTags
              type="Measure"
              toggleShowAll={toggleDropDown}
              defaultTags={optionsmeausures}
              options={Measurev2}
              getOptionLabel={(option) => option.field}
              isOptionSelected={(newValue) => setOptionsmeausures(newValue)}
              withAddBtn
              onChange={(newValue) => setOptionsmeausures(newValue)}
              style={{ height: '20px', width: '100%' }}
            />
          </Box>

          {/* Show More Button */}
          <Box flex="1" display="flex" justifyContent="flex-end">
            <Button
              variant="outlined"
              style={{
                fontSize: '25px',
                textTransform: 'none',
                color: '#ffffff',
                backgroundColor: '#1f63ff',
                padding: '6px 16px',
                borderRadius: '6px',
                boxShadow: 'none',
                minWidth: '140px',
              }}
              endIcon={<ArrowDropDownIcon />}
              onClick={toggleShowAll}
            >
              Show More
            </Button>
          </Box>
        </Box>
      ) : (
        <>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            paddingRight="20px"
            marginTop="10px"
            position="relative"
          >
            <Box
              display="flex"
              flexDirection="column"
              width="71%"
              paddingRight="20px"
              marginTop="9px"
              style={{ overflowX: 'auto', overflowY: 'hidden' }}
            >
              <Typography className={classes.sectionTitle} style={{ marginBottom: '4px', fontSize: '0.875rem' }}>
                Dimensions
                <Tooltip
                  title="Dimensions is used to provide discrete categorical information. These values can be of any type - string, date time, or numeric."
                  placement="top"
                  arrow
                >
                  <InfoIcon style={{ fontSize: '12px', color: 'blue', marginLeft: '8px', cursor: 'pointer' }} />
                </Tooltip>
              </Typography>

              <FixedTags
                type="Dimension"
                showAll={showAll}
                defaultTags={optionsDimension}
                options={dimensionsv2}
                limitTags
                withAddBtn
                onChange={(newValue) => setOptionsDimension(newValue)}
                getOptionLabel={(option) => option.field}
                isOptionSelected={(newValue) => setOptionsDimension(newValue)}
                style={{ height: '20px', width: '100%' }}
                toggleShowAll={toggleDropDown}
              />
            </Box>

            <Box position="absolute" right="0" top="15">
              <Button
                variant="outlined"
                style={{
                  fontSize: '25px',
                  textTransform: 'none',
                  color: '#ffffff',
                  backgroundColor: '#1f63ff',
                  padding: '6px 16px',
                  borderRadius: '6px',
                  boxShadow: 'none',
                  minWidth: '140px',
                }}
                endIcon={<ArrowDropUpIcon />}
                onClick={toggleShowAll}
              >
                Show Less
              </Button>
            </Box>
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            width="70%"
            paddingRight="20px"
            marginTop="10px"
            style={{ overflowX: 'auto', overflowY: 'hidden' }}
          >
            <Typography className={classes.sectionTitle} style={{ marginBottom: '4px', fontSize: '0.875rem' }}>
              Measures
              <Tooltip
                title="Measure is used to identify the measure using the specified data member and summary type."
                placement="top"
                arrow
              >
                <InfoIcon style={{ fontSize: '12px', color: 'blue', marginLeft: '8px', cursor: 'pointer' }} />
              </Tooltip>
            </Typography>

            <FixedTags
              type="Measure"
              defaultTags={optionsmeausures}
              showAll={toggleDropDown}
              options={Measurev2}
              getOptionLabel={(option) => option.field}
              isOptionSelected={handleMeasureSelection}
              withAddBtn
              onChange={(newValue) => setOptionsmeausures(newValue)}
              style={{ height: '20px', width: '100%' }}
              toggleShowAll={toggleDropDown}
            />
          </Box>
          <Box>
            <Box>
              <Box display="flex" flexDirection="column" justifyContent="space-between" margin="0" gap="10px">
                <Box display="flex" flexDirection="column" alignItems="flex-start" width="100%">
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'end',
                    }}
                  >
                    <Box display="flex" flexDirection="column" alignItems="flex-start" width="100%">
                      <Typography
                        className={classes.sectionTitle}
                        style={{ fontSize: '0.875rem', marginBottom: '4px', marginTop: '10px' }}
                      >
                        Time
                      </Typography>
                      <Box
                        sx={{
                          width: '250px',
                          minWidth: '250px',
                        }}
                      >
                        <ReportItem
                          label={dimensionsTime.label}
                          cubes={cubes}
                          value={dimensionsTime.value}
                          setItems={setDimensionsTime}
                          showTodayIcon={false}
                        />
                      </Box>
                    </Box>

                    {dimensionsTime.value && (
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                        }}
                      >
                        <Box
                          sx={{
                            p: 1,
                          }}
                        >
                          <Typography
                            justifyContent="center"
                            alignItems="center"
                            style={{
                              fontSize: 16,
                              fontWeight: 600,
                            }}
                          >
                            For
                          </Typography>
                        </Box>
                        <Box sx={{ minWidth: '200px', width: '200px' }}>
                          <DropdownSelectableItem
                            value={daterange}
                            options={dataRangeOptions}
                            setItems={setDaterange}
                          />
                        </Box>
                      </Box>
                    )}

                    {dimensionsTime.value && daterange === 'Custom' && (
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                        }}
                      >
                        <Box
                          sx={{
                            m: 1,
                          }}
                        >
                          <DatePickerMUI
                            label="From"
                            date={from}
                            propWidth={120}
                            type="start"
                            setdate={setFrom}
                            compareDate={to}
                            setCompareDate={setTo}
                            allowEndBeforeStart
                            propHeight={45}
                          />
                        </Box>
                        <Box
                          sx={{
                            m: 1,
                          }}
                        >
                          <DatePickerMUI
                            label="To"
                            date={to}
                            propWidth={120}
                            type="end"
                            setdate={setTo}
                            compareDate={from}
                            setCompareDate={setFrom}
                            allowEndBeforeStart
                            propHeight={45}
                            TextFieldComponent={(props) => (
                              <TextField
                                {...props}
                                InputProps={{
                                  style: {
                                    height: '100px',
                                  },
                                }}
                              />
                            )}
                          />
                        </Box>
                      </Box>
                    )}

                    {dimensionsTime.value && (
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                        }}
                      >
                        <Box
                          sx={{
                            p: 1,
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: 16,
                              fontWeight: 600,
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            By
                          </Typography>
                        </Box>
                        <Box>
                          <DropdownSelectableItem
                            value={granularity}
                            options={dayOption}
                            setItems={setGranularity}
                            widthItem="150%"
                          />
                        </Box>
                      </Box>
                    )}
                  </Box>

                  <Box display="flex" flexDirection="column" alignItems="flex-start" width="100%">
                    <Typography className={classes.sectionTitle} style={{ fontSize: '0.875rem', marginBottom: '4px' }}>
                      Filters
                    </Typography>
                    {filters.map((filter, index) => (
                      <Box key={index}>
                        <Box display="flex" flexDirection="column" alignItems="flex-start" width="100%">
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'end',
                            }}
                          >
                            <Box
                              sx={{
                                marginLeft: 1,
                                width: '250px',
                                minWidth: '250px',
                              }}
                            >
                              <ReportFilterItem
                                label="Filter by"
                                cubes={cubes}
                                value={filter.member}
                                setItems={(value) => {
                                  setFilters(index, 'member', value);
                                }}
                                onResetFilter={() => {
                                  onResetFilter(index);
                                }}
                              />
                            </Box>

                            {filter?.member && (
                              <Box style={{ display: 'flex', flexDirection: 'row' }}>
                                <Box
                                  sx={{
                                    marginLeft: '10px',
                                    width: '250px',
                                    minWidth: '250px',
                                  }}
                                >
                                  <ReportFilterItem
                                    label="Operator"
                                    value={filter.operator}
                                    operators={operators[filter?.member?.type]}
                                    setItems={(value) => {
                                      setFilters(index, 'operator', value);
                                      setFilters(index, 'values', null);
                                    }}
                                    showRemove={false}
                                    withSearchInput={false}
                                  />
                                </Box>
                                {filter?.operator &&
                                  !filter?.operator?.label?.includes('date') &&
                                  filter?.member?.type !== 'boolean' && (
                                    <Box
                                      sx={{
                                        marginLeft: '10px',
                                        width: '250px',
                                        minWidth: '250px',
                                      }}
                                    >
                                      <OutLineInput
                                        placeholder="Value"
                                        aria-describedby="outlined-weight-helper-text"
                                        inputProps={{
                                          'aria-label': 'weight',
                                        }}
                                        style={{
                                          height: 45,
                                          background: '#fff',
                                          padding: '15px 16px',
                                          border: '1px solid #7070701F',
                                          fontSize: 16,
                                        }}
                                        value={filter.values || ''}
                                        onChange={(e) => setFilters(index, 'values', e.target.value)}
                                        labelWidth={0}
                                      />
                                    </Box>
                                  )}
                              </Box>
                            )}
                          </Box>
                        </Box>

                        {filter?.operator?.label?.includes('date') && (
                          <Box display="flex" alignItems="center" justifyContent="space-between" gap="15px">
                            {filter?.operator?.value === 'afterDate' && (
                              <DatePickerMUI
                                disableFuture
                                label="Date"
                                date={filter.values || null}
                                propWidth="100%"
                                setdate={(date) => setFilters(index, 'values', date)}
                                propPadding={5}
                                sx={{
                                  height: '45px',
                                }}
                              />
                            )}

                            {filter?.operator?.value === 'beforeDate' && (
                              <DatePickerMUI
                                disableFuture
                                label="Date"
                                date={filter.values || null}
                                propWidth="100%"
                                setdate={(date) => setFilters(index, 'values', date)}
                                propPadding={5}
                                sx={{
                                  height: '45px',
                                }}
                              />
                            )}

                            {filter?.operator?.value === 'inDateRange' && (
                              <>
                                <DatePickerMUI
                                  label="From"
                                  date={Array.isArray(filter.values) ? filter.values?.[0] : filter.values || null}
                                  propWidth={100}
                                  setdate={(date) => {
                                    const arrayValues = [...(filter.values || [])];
                                    arrayValues[0] = date;
                                    setFilters(index, 'values', arrayValues);
                                  }}
                                  sx={{
                                    width: '100%',
                                    height: '50px',
                                    '& .MuiInputBase-root': {
                                      height: 'inherit',
                                    },
                                  }}
                                  compareDate={to}
                                  setCompareDate={setTo}
                                  allowEndBeforeStart
                                />
                                <span style={{ width: 15 }} />
                                <DatePickerMUI
                                  label="To"
                                  date={Array.isArray(filter.values) ? filter.values?.[1] : null}
                                  propWidth={100}
                                  type="end"
                                  compareDate={from}
                                  setCompareDate={setFrom}
                                  setdate={(date) => {
                                    const arrayValues = [...(filter.values || [])];
                                    arrayValues[1] = date;
                                    setFilters(index, 'values', arrayValues);
                                  }}
                                  allowEndBeforeStart
                                />
                              </>
                            )}

                            {filter?.operator?.value === 'notInDateRange' && (
                              <>
                                <DatePickerMUI
                                  label="From"
                                  date={filter.values?.[0]}
                                  propWidth={100}
                                  setdate={(date) => {
                                    const arrayValues = [...(filter.values || [])];
                                    arrayValues[0] = date;
                                    setFilters(index, 'values', arrayValues);
                                  }}
                                  compareDate={to}
                                  setCompareDate={setTo}
                                  allowEndBeforeStart
                                />
                                <span style={{ width: 15 }} />
                                <DatePickerMUI
                                  label="To"
                                  date={filter.values?.[1]}
                                  propWidth={100}
                                  type="end"
                                  compareDate={from}
                                  setCompareDate={setFrom}
                                  setdate={(date) => {
                                    const arrayValues = [...(filter.values || [])];
                                    arrayValues[1] = date;
                                    setFilters(index, 'values', arrayValues);
                                  }}
                                  allowEndBeforeStart
                                />
                              </>
                            )}
                          </Box>
                        )}

                        {filter?.operator && filter?.member?.type === 'boolean' && (
                          <Box bgcolor="#fff" border="1px solid #7070701F" borderRadius="5px">
                            <SelectBox
                              options={{
                                obj: [
                                  { text: 'Select an Option', value: '' },
                                  { text: 'Yes', value: 1 },
                                  { text: 'No', value: 0 },
                                ],
                              }}
                              outlined="string"
                              value={filter.values || ''}
                              onChange={(e) => setFilters(index, 'values', e.target.value)}
                              allowPlaceHolder={false}
                            />
                          </Box>
                        )}
                      </Box>
                    ))}
                  </Box>

                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Box display="flex" alignItems="center" color="#1F63FF" sx={{ m: 1 }}>
                      <Typography
                        data-testid="report-details-button-add-new-filter"
                        style={{ fontSize: 13, fontWeight: '500', cursor: 'pointer' }}
                        onClick={addFilter}
                      >
                        + Add new filter
                      </Typography>
                    </Box>
                    {showFilterBox && (
                      <Box display="flex" alignItems="center" sx={{ m: 1 }}>
                        <Typography style={{ fontSize: 13 }}>New Filter Content</Typography>
                        <IconButton size="small" onClick={cancelFilter} sx={{ marginLeft: 1 }}>
                          <CancelIcon />
                        </IconButton>
                      </Box>
                    )}
                  </Box>
                </Box>
                <Divider />
              </Box>
            </Box>
          </Box>
        </>
      )}

      <ExploreContent
        ref={exploreContentRef}
        resultSet={reports?.resultSet ? reports?.resultSet : null}
        reports={reports}
        report={report}
        reportName={report?.name}
        reportImageUrl={report?.reportImage}
        handleRefetch={() => {
          onGetCube();
          onGetCubes();
        }}
        reportId={reportId}
        orgSubdomainDashboard={orgSubdomainDashboard}
        cubeQuery={cubeQuery}
        prepareReport={prepareReport}
        onClearReport={onClearReport}
        loading={loading}
        style={{
          fontSize: '0.75rem',
          width: '100%',
          height: '500px',
          maxHeight: '500px',
          overflow: 'hidden',
        }}
      />
    </Box>
  );
}

ExplorePanel.propTypes = {
  setDimensions: PropTypes.func.isRequired,
  setMeasures: PropTypes.func.isRequired,
  dimensionsTime: PropTypes.array.isRequired,
  setDimensionsTime: PropTypes.func.isRequired,
  daterange: PropTypes.object.isRequired,
  setDaterange: PropTypes.func.isRequired,
  granularity: PropTypes.string.isRequired,
  setGranularity: PropTypes.func.isRequired,
  from: PropTypes.string.isRequired,
  setFrom: PropTypes.func.isRequired,
  to: PropTypes.string.isRequired,
  setTo: PropTypes.func.isRequired,
  cubes: PropTypes.array.isRequired,
  filters: PropTypes.array.isRequired,
  setFilters: PropTypes.func.isRequired,
  onResetFilter: PropTypes.func.isRequired,
  optionsmeausures: PropTypes.bool.isRequired,
  optionsDimension: PropTypes.bool.isRequired,
  onGetCube: PropTypes.func.isRequired,
  onGetCubes: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  onClearReport: PropTypes.func.isRequired,
  report: PropTypes.object.isRequired,
};
