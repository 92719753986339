import React from 'react';
import { Box, makeStyles, useMediaQuery } from '@material-ui/core';

import Logo from '../../../components/Logo';

const useStyles = makeStyles(() => ({
  topBar: {
    width: '100%',
    height: 104,
    background: '#364F74',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
}));

export default function FormDashbaordBar() {
  const classes = useStyles();

  const isMobile = useMediaQuery('(max-width: 999px)');

  return (
    <div className={classes.topBar}>
      <Box style={{ padding: isMobile ? '0px 21px' : '0px 120px' }}>
        <Logo logo="white" />
      </Box>
    </div>
  );
}
