/**
 * @description this function is used to change values inside a DnD area
 */
export const onChangeDnDValue = (e, setDnDValue, index, prop = 'value', caseSensitive = false, callback = null) => {
  const { value } = e.target;

  setDnDValue((prevState) => {
    const stateCopy = prevState.slice();
    stateCopy[index][prop] = caseSensitive ? value.toUpperCase() : value;
    if (callback) {
      callback(stateCopy);
    }
    return stateCopy;
  });
};

/**
 * @description this function is used to add values inside a DnD area
 */
export const onAddDnDItem = (items, setItems, id, inicialValue = null, othersProps) => {
  setItems([
    ...items,
    {
      label: items[0]?.label,
      value: inicialValue,
      order: items.length + 1,
      id: `${id}${items.length + 1}`,
      ...othersProps,
    },
  ]);
};

/**
 * @description this function is used to remove values inside a DnD area
 */
export const onRemoveDnDItem = (items, setItems, index, callback = null) => {
  // const itemsCopy = items.slice();
  // itemsCopy.splice(index, 1);
  // setItems(itemsCopy);
  setItems((prevState) => {
    const stateCopy = [...prevState];
    stateCopy.splice(index, 1);
    if (callback) {
      callback(stateCopy);
    }
    return stateCopy;
  });
};

/**
 * @description this function is used to change values in questions
 */
export const handleOnChangeQuestion = (e, index, setCheckQuestion) => {
  setCheckQuestion((prevState) => {
    const checkQuestionCopy = [...prevState];
    checkQuestionCopy[index] = e.target.checked;

    return checkQuestionCopy;
  });
};

/**
 * @description this function is used to limit the number of characters in a text field to 100
 */
export const rangeNumber100 = (number) => {
  if (number.match(/\b([1-9]|[1-9][0-9]|100)\b/)) {
    return true;
  }

  return false;
};

/**
 * @description this function is used to limit the number of characters in a text field to 1000
 */
export const rangeNumber1000 = (number) => {
  if (number.match(/\b([1-9]|[1-9][0-9]|[1-9][0-9][0-9]|1000)\b/)) {
    return true;
  }

  return false;
};

/**
 * @description this function is used to change checkbox values in question
 */
export const handleChangeCheckBox = (e, index, prop, items, setItems) => {
  const { checked } = e.target;

  const itemsCopy = items.slice();
  itemsCopy[index][prop] = checked;
  setItems(itemsCopy);
};

/**
 * @description this function is used to add a component to the json
 */
export const addComponentToJson = ({ setPages, page, components, component, currentSection }) => {
  setPages((prevState) => {
    const pagesCopy = [...prevState];
    const newComponents = [...components, component];
    pagesCopy[page.pageIndex].sections[currentSection].components = newComponents;

    return pagesCopy;
  });
};

/**
 * @description this function is used to remove a component to the json
 */
export const DeleteComponentFromJson = ({ pages, setPages, page, components, componentIndex, currentSection }) => {
  const pagesCopy = Array.from(pages);
  const componentsCopy = [...components];
  componentsCopy.splice(componentIndex, 1);
  pagesCopy[page.pageIndex].sections[currentSection].components = componentsCopy;

  setPages(pagesCopy);
};

/**
 * @description this function is used to update a component in the json
 */
export const onUpdateJson = ({ setPages, page, componentIndex, data, currentSection, subProp = null }) => {
  const currentSectionComponents = page?.sections?.[currentSection]?.components || [];

  setPages((prevState) => {
    const pagesCopy = [...prevState];
    const componentsCopy = [...currentSectionComponents];
    if (componentsCopy[componentIndex]?.data) {
      componentsCopy[componentIndex].data = data;
    }

    if (subProp) {
      const parentComponent = componentsCopy[componentIndex];
      const componentFinded = parentComponent?.components?.find((component) => component.type === subProp);
      const compIndex = parentComponent?.components?.findIndex((component) => component.type === subProp);

      if (componentFinded) {
        parentComponent.components[compIndex].data = data;
      }
      // componentFinded.data = data;
      // componentsCopy[componentIndex][subProp] = data;
    }

    if (pagesCopy[page.pageIndex].sections[currentSection]) {
      pagesCopy[page.pageIndex].sections[currentSection].components = componentsCopy;
    }
    return pagesCopy;
  });
};
