import React, { useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import moment from 'moment';
import { parseISO } from 'date-fns';
import PropTypes from 'prop-types';

function DatePickerMUI({
  dataTestId,
  date,
  setdate,
  label,
  disableFuture,
  propWidth = 75,
  propHeight = 25,
  minDate,
  maxDate,
  disablePast = false,
  compareDate,
  setCompareDate,
  type = 'start',
  propPadding = 0,
  propPaddingInput = 10,
  propFontSize = 13,
  inputVariant = 'outlined',
  allowEndBeforeStart = false,
  active = false,
}) {
  const [invalidDate, setInvalidDate] = useState();

  const onChangeStartDate = (dt) => {
    if (dt) {
      const d1 = new Date(dt);
      const d2 = new Date(compareDate);

      if (d1.getTime() > d2.getTime() && compareDate && !allowEndBeforeStart) {
        setInvalidDate(dt);
        return;
      }

      if (d1.getTime() > d2.getTime() && compareDate && allowEndBeforeStart && setCompareDate) {
        setCompareDate(null);
      }

      setdate(moment(dt).startOf('day').format());
    } else {
      setdate('');
    }
  };

  const onChangeEnd = (dt) => {
    if (dt) {
      const d1 = new Date(dt);
      const d2 = new Date(compareDate);

      if (d1.getTime() < d2.getTime() && compareDate && !allowEndBeforeStart) {
        setInvalidDate(dt);
        return;
      }

      if (d1.getTime() < d2.getTime() && compareDate && allowEndBeforeStart && setCompareDate) {
        setCompareDate(null);
      }

      setdate(moment(dt).endOf('day').format());
    } else {
      setdate('');
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        clearable
        style={{ width: '100%' }}
        value={date ? parseISO(date) : null}
        minDate={minDate}
        maxDate={maxDate}
        onChange={type === 'start' ? onChangeStartDate : onChangeEnd}
        inputVariant={inputVariant}
        className={active ? 'DatePickerMUI-active' : 'DatePickerMUI'}
        inputProps={{
          'data-testid': dataTestId,
          style: {
            padding: '0px 10px',
            fontSize: propFontSize,
            width: propWidth,
            background: '#fff',
            height: propHeight,
          },
        }}
        placeholder={label}
        id="date-picker-dialog"
        disableFuture={disableFuture}
        disablePast={disablePast}
        invalidDateMessage={
          <>
            {new Date(invalidDate).getTime() < new Date(compareDate).getTime() && !allowEndBeforeStart
              ? `Date cannot be less than ${moment(compareDate).endOf('day').format('MM/DD/YYYY')}.`
              : ''}{' '}
            {new Date(invalidDate).getTime() > new Date(compareDate).getTime()
              ? `Date cannot be greater than ${moment(compareDate).endOf('day').format('MM/DD/YYYY')}.`
              : ''}{' '}
            {'Invalid date format'}
          </>
        }
        format="MM/dd/yyyy"
        icon={<CalendarTodayIcon fontSize="small" />}
        KeyboardButtonProps={{
          'aria-label': 'change date',
          style: { padding: 20 },
        }}
      />
    </MuiPickersUtilsProvider>
  );
}

DatePickerMUI.propTypes = {
  dataTestId: PropTypes.string,
  allowEndBeforeStart: PropTypes.bool,
  date: PropTypes.instanceOf(Date).isRequired,
  setdate: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  disableFuture: PropTypes.bool,
  disablePast: PropTypes.bool,
  propWidth: PropTypes.number,
  propHeight: PropTypes.number,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  compareDate: PropTypes.instanceOf(Date),
  setCompareDate: PropTypes.func,
  type: PropTypes.oneOf(['start', 'end']),
  propPadding: PropTypes.number,
  inputVariant: PropTypes.oneOf(['standard', 'outlined', 'filled']),
  propPaddingInput: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  propFontSize: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
};

export default DatePickerMUI;
