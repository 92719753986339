import { useEffect, useState } from 'react';
import getProjectSubTypes from '../pages/Projects/utils';

export default function useProjectTypes(typeOptions, currentType) {
  const [types, setTypes] = useState([]);
  const [subTypes, setSubTypes] = useState([]);

  useEffect(() => {
    if (typeOptions?.length) {
      setTypes(typeOptions?.map((x) => ({ text: x.type, value: x.type })));
      const subTypesOptions = getProjectSubTypes(typeOptions, currentType);
      setSubTypes(subTypesOptions);
    }
  }, [typeOptions, currentType]);

  return [types, subTypes];
}
