import React, { useCallback, useEffect, useState } from 'react';
import { Box, Checkbox, makeStyles, TableCell, TableRow, Typography } from '@material-ui/core';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import DashButton from '../../components/DashButton';
import ModalPopUp from '../../components/ModaPopupl';
import EnhancedTable from '../../components/Table';
import { onArchiveProject, onGetProjects } from '../../services/unarmed';
import CreateProject from './CreateProject';
import DeletePopup from './DeletePopup';
import useOrganization from '../../hooks/useOrganization';
import UpgradeModal from '../../components/UpgradeModal';
import Alert from '../../components/Alert';

const useStyles = makeStyles(() => ({
  container: {
    padding: 40,
    width: '100%',
  },
}));

const tableColumns = ['Type', 'Subtype', 'Number', 'Name', 'Created Date', 'Created By'];

export const ProjectRenderRow = ({ isItemSelected, index, row, labelId, handleClick, withCheckbox }) => {
  const classes = useStyles();
  const history = useHistory();

  const goToProjectDetails = (e) => {
    const go = handleClick(e, row._id);
    if (go) {
      history.push(`/project/${row._id}`);
    }
  };

  return (
    <TableRow
      hover
      aria-checked={isItemSelected}
      selected={isItemSelected}
      role="checkbox"
      tabIndex={-1}
      key={index}
      className={classes.row}
      style={{
        cursor: 'pointer',
        height: 50,
        maxHeight: 50,
        background: '#fff',
      }}
      onClick={(e) => goToProjectDetails(e, row, handleClick)}
    >
      {withCheckbox && (
        <TableCell padding="checkbox">
          <Checkbox checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} color="primary" />
        </TableCell>
      )}

      <TableCell component="th" scope="row" align="left">
        {row?.projectType}
      </TableCell>
      <TableCell component="th" scope="row" align="left">
        {row?.subType}
      </TableCell>
      <TableCell component="th" scope="row" align="left">
        {row?.number}
      </TableCell>
      <TableCell component="th" scope="row" align="left">
        {row?.name}
      </TableCell>
      <TableCell component="th" scope="row" align="left">
        {moment(row.createdAt).format('MMMM DD, YYYY')}
      </TableCell>
      <TableCell component="th" scope="row" align="left">
        {row?.createdBy?.firstName} {row?.createdBy?.lastName}
      </TableCell>
    </TableRow>
  );
};

ProjectRenderRow.propTypes = {
  isItemSelected: PropTypes.bool,
  index: PropTypes.number,
  row: PropTypes.object,
  labelId: PropTypes.string,
  handleClick: PropTypes.func,
  withCheckbox: PropTypes.bool,
};

export default function Projects() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [checked, setChecked] = useState(false);

  const [projects, setProjects] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = useState(1);
  const [selected, setSelected] = React.useState([]);
  const [openDelete, setOpenDelete] = React.useState(false);

  const [refetch, setRefetch] = useState(false);
  const [sortBy, setSortBy] = useState('createdAt');
  const [sort, setSort] = useState('desc');
  const { organization } = useOrganization();
  const location = useLocation();

  const [isFilteringByContact, setIsFilteringByContact] = useState(location?.state?.isFilteringByContact || false);
  const [showAlert, setShowAlert] = useState(location.state?.showAlert || false);
  const isSelected = (name) => selected.indexOf(name) !== -1;

  const getProjects = useCallback(async () => {
    setLoading(true);
    try {
      const { data, headers } = await onGetProjects(
        page,
        rowsPerPage,
        sortBy,
        sort,
        isFilteringByContact ? location?.state?.id : undefined
      );
      setProjects({ data, headers });
    } catch (error) {
      toast.error('Cannot get projects');
    } finally {
      setLoading(false);
    }
  }, [page, rowsPerPage, sortBy, sort, isFilteringByContact, location]);

  useEffect(() => {
    getProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch, sortBy, sort, page, rowsPerPage, isFilteringByContact, location]);

  useEffect(() => {
    if (!location.state?.showAlert && showAlert) {
      setShowAlert(false);
    }
  }, [location, showAlert]);

  const onClearContactsProjects = async () => {
    setIsFilteringByContact(false);
    setShowAlert(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdateProjectList = (newProject) => {
    const finaleProjects = [...projects.data, newProject];
    setProjects({ ...projects, data: finaleProjects });
    setRefetch(!refetch);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(1);
  };

  const handleDeleteProjects = async () => {
    let ids = '';
    for (const id of selected) {
      const index = selected.indexOf(id);
      if (index + 1 === selected.length) {
        ids += `ids[]=${id}`;
      } else {
        ids += `ids[]=${id}&`;
      }
    }
    try {
      setLoadingDelete(true);
      const { data } = await onArchiveProject(ids);
      getProjects();
      setOpenDelete(false);
      if (data?.deleted?.length) {
        toast.success('Project was delete it successfully');
        setRefetch(!refetch);
      }
      if (data?.error) {
        toast.error(data.error);
      }
    } catch (error) {
      toast.error('Project, can not be deleted please un-link related projects to be able to delete the projects');
    } finally {
      setLoadingDelete(false);
    }
  };

  const renderRow = (row, index, handleClick) => {
    const isItemSelected = isSelected(row._id);
    const labelId = `enhanced-table-checkbox-${index}`;

    return (
      <ProjectRenderRow
        isItemSelected={isItemSelected}
        index={index}
        row={row}
        labelId={labelId}
        handleClick={handleClick}
        withCheckbox
      />
    );
  };

  return (
    <>
      {organization && <UpgradeModal open={!organization?.features?.dashboard?.projects?.enabled} />}
      <div
        className={classes.container}
        style={
          !organization?.features?.dashboard?.projects?.enabled
            ? {
                filter: 'blur(6px)',
                height: '70vh',
                overflow: 'hidden',
              }
            : {}
        }
      >
        <Typography variant="h5">Projects </Typography>
        <Box display="flex" justifyContent="space-between" alignItems="flex-end" marginTop={2}>
          <Typography variant="subtitle2" id="tableTitle" component="div">
            All Projects ({projects?.headers?.['x-pagination-totalitems'] || 0})
          </Typography>
          <Box display="flex" alignItems="center">
            <DashButton
              dataTestId="projects-button-delete-projects"
              marginRight="10px"
              disabled={!selected.length || loadingDelete}
              onClick={() => setOpenDelete(true)}
            >
              Delete
            </DashButton>
            <DashButton dataTestId="projects-button-create-project" marginRight="10px" onClick={() => setOpen(!open)}>
              Create Project
            </DashButton>
          </Box>
        </Box>
        {showAlert && (
          <Alert onClick={onClearContactsProjects}>
            <Typography style={{ fontSize: 14 }}>
              You are only seeing projects from Contact: <strong>{location?.state?.name} </strong>email:{' '}
              <strong>{location?.state?.email}</strong>
            </Typography>
          </Alert>
        )}
        <Box marginBottom="0">
          <EnhancedTable
            sort={sort}
            setSort={setSort}
            columns={tableColumns}
            rows={projects?.data}
            showCheckBox
            setSortBy={setSortBy}
            rowRender={renderRow}
            setChecked={setChecked}
            tab="projectType"
            checked={checked}
            loading={loading}
            page={page}
            setPage={setPage}
            selected={selected}
            setSelected={setSelected}
            count={projects?.headers?.['x-pagination-totalitems']}
            rowsPerPage={rowsPerPage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Box>
        <ModalPopUp setOpen={setOpen} open={open}>
          <CreateProject handleCancel={handleClose} handleUpdateProjects={handleUpdateProjectList} />
        </ModalPopUp>
        <ModalPopUp setOpen={setOpenDelete} open={openDelete}>
          <DeletePopup
            handleCancel={() => setOpenDelete(false)}
            loading={loadingDelete}
            handleSubmit={handleDeleteProjects}
          />
        </ModalPopUp>
      </div>
    </>
  );
}
