/* eslint-disable jsx-a11y/no-autofocus */
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const applyFilters = (search, cubes) =>
  cubes.filter((cube) => {
    const titleName = cube?.title?.toLowerCase() || cube?.shortTitle?.toLowerCase() || '';
    const searchText = search.toLowerCase().replace(/ /g, '');
    const match = titleName.replace(/ /g, '').includes(searchText);

    return match;
  });

/**
 * Filter an array of cubes based on a search string.
 * @param {string} search - The search string.
 * @param {Object[]} _cubes - Array of cube objects to filter.
 * @returns {Object[]} - Filtered array of cube objects.
 */
export const onFilterCubes = (search, _cubes = []) => {
  console.log(_cubes);

  if (search.length >= 3 && search !== '') {
    return [..._cubes].map((cube) => {
      const mappedCube = { ...cube };

      if (cube.measures?.length > 0) {
        mappedCube.measures = applyFilters(search, cube.measures);
      }

      if (cube.dimensions?.length > 0) {
        mappedCube.dimensions = applyFilters(search, cube.dimensions);
      }

      if (cube.filterOptions?.length > 0) {
        mappedCube.filterOptions = applyFilters(search, cube.filterOptions);
      }

      return mappedCube;
    });
  }

  return _cubes;
};

const useStyles = makeStyles({
  input: {
    width: '100%',
    height: '40px',
    border: 0,
  },
});

/**
 * A component for searching reports.
 * @param {Object} props - Component props.
 * @param {string} props.search - Current search string.
 * @param {Function} props.setSearch - Function to set the search string.
 */
const ReportSearchInput = ({ search, setSearch }) => {
  const classes = useStyles();

  useEffect(() => {
    setSearch('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <input
      autoFocus
      value={search}
      className={classes.input}
      onChange={(e) => setSearch(e.target.value.replace(/^ +/, ''))}
      placeholder="Type to search..."
    />
  );
};

ReportSearchInput.propTypes = {
  search: PropTypes.string,
  setSearch: PropTypes.func,
};

export default ReportSearchInput;
