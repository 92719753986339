import React, { useState, useContext, useEffect } from 'react';
import { Context } from '../../Context';
import { Box, Typography, FormControlLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import { CustomSwitch } from '../../formEngine/components/WrapperComponent';
import { updateNotesSortOrder } from '../../services/unarmed';
import useInput from '../../hooks/useInput';
import { toast } from 'react-toastify';
import OfficerLogs from './components/OfficerLogs';
import NoteUpdate from './components/NoteUpdate';
import Card from '../../components/Card';

export default function InterveneTab({ organizationId, interveneOptions, officerId }) {
  const { myUser } = useContext(Context);
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [archivedOnly, setArchivedOnly] = useState(false);
  const handleArchiveOnly = () => {
    setArchivedOnly(!archivedOnly)
  }
  // Load sort order from localStorage or fallback to user's backend setting
  const initialSortOrder = localStorage.getItem('interveneOfficerLogsSortOrder') || (myUser?.notesSortOrderDesc?.officers ? 'desc' : 'asc');
  const [sortOrder, setSortOrder] = useState(initialSortOrder || 'desc');

  const intervene = useInput('');

  const toggleSortOrder = () => {
    const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    const updatedSortOrder = newSortOrder === 'desc';

    updateNotesSortOrder(myUser?._id, 'officers', updatedSortOrder)
      .then(() => {
        setSortOrder(newSortOrder);
        localStorage.setItem('interveneOfficerLogsSortOrder', newSortOrder);
      })
      .catch((error) => {
        toast.error('Failed to update sort order');
      });
  };

  return (
    <Box display="flex" marginTop={2}>
      <Card
        title={
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box>
              <Typography variant="h6">Notes</Typography>
            </Box>
            <Box style={{ display: 'flex'}}>
              <FormControlLabel
                control={
                  <CustomSwitch
                    checked={sortOrder === 'desc'}
                    onChange={toggleSortOrder}
                    color="primary"
                  />
                }
                label={sortOrder === 'desc' ? 'Most Recent' : 'Oldest'}
                labelPlacement="end"
                style={{ marginLeft: '500px' }}
              />
              <FormControlLabel
                      style={{ margin: 0 }}
                      control={<CustomSwitch checked={archivedOnly} onChange={handleArchiveOnly} />}
                      labelPlacement="end"
                      label="Archived only"
                    />
            </Box>
          </Box>
        }
      >
        <OfficerLogs officerId={officerId} type="intervene" shouldRefetch={shouldRefetch} sortOrder={sortOrder} archivedOnly={archivedOnly} />
      </Card>

      <Box width="70%">
        <NoteUpdate
          organizationId={organizationId}
          label="Checklist"
          options={interveneOptions}
          marginTop={2}
          value={intervene.value}
          onChange={intervene.onChange}
          onResetSelect={() => {
            intervene.setValue('');
          }}
          type="INTERVENE"
          officerId={officerId}
          setShouldRefetch={setShouldRefetch}
        />
      </Box>
    </Box>
  );
}

InterveneTab.propTypes = {
  organizationId: PropTypes.string.isRequired,
  interveneOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  officerId: PropTypes.string.isRequired,
};
