import { Box } from '@material-ui/core';
import React from 'react';

const ArchivedImage = ({ width = 100, height = 100, text = '' }) => {
  return (
    <Box
      display={'flex'}
      flex={'1 1 auto'}
      alignItems={'center'}
      justifyContent={'center'}
      color={'#fff'}
      style={{ backgroundColor: 'rgba(255,0,0,0.5)', width, height, marginRight: 6, textAlign: 'center' }}
    >
      {text ? (
        text
      ) : (
        <>
          Archived
          <br />
          image
        </>
      )}
    </Box>
  );
};

export default ArchivedImage;
