import React, { useEffect, useState } from 'react';
import { Grid, Tabs, Divider } from '@material-ui/core';
import styled from 'styled-components';
import ShareIcon from '@material-ui/icons/Share';

import { TabComp } from '../CaseDetails/CaseDetails';
import TabPanel from '../../components/TabPanel';

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const Social = () => {
  const [tabsAccounts, setTabsAccounts] = useState(0);

  useEffect(() => {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: process.env.REACT_APP_FACEBOOK_ID,
        cookie: true,
        xfbml: true,
        version: 'v10.0',
      });

      window.FB.AppEvents.logPageView();
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  }, []);

  const onChangeTabsAccounts = (event, newValue) => {
    setTabsAccounts(newValue);
  };

  const handleFacebookLogin = () => {
    window.FB.login(
      (response) => {
        if (response.authResponse) {
          console.log('Welcome! Fetching your information.... ');
          window.FB.api('/me', function (response) {
            console.log('Good to see you, ' + response.name + '.');
          });
        } else {
          console.log('User cancelled login or did not fully authorize.');
        }
      },
      { scope: 'public_profile,email' }
    );
  };

  return (
    <Grid container style={{ height: 'calc(116.1vh - 64px)' }}>
      <Grid item xs={3}>
        <Tabs
          value={tabsAccounts}
          onChange={onChangeTabsAccounts}
          aria-label="social accounts tabs"
          indicatorColor="primary"
          textColor="primary"
        >
          <TabComp data-testid="social-tab-accounts" label="Accounts" {...a11yProps(0)} />
          {/* <TabComp data-testid="social-tab-settings" label="Settings" {...a11yProps(1)} /> */}
        </Tabs>
        <Divider />
        <TabPanel value={tabsAccounts} index={0}>
          <ConnectAccountButton onClick={handleFacebookLogin}>
            <ShareIcon htmlColor="rgba(0, 0, 0, 0.5)" />
            <span>Connect your Facebook Account</span>
          </ConnectAccountButton>
        </TabPanel>
      </Grid>
      <Divider orientation="vertical" flexItem />
      <Grid item xs={3} />
      <Divider orientation="vertical" flexItem />
    </Grid>
  );
};

const ConnectAccountButton = styled.button`
  width: 100%;
  border: 0;
  display: flex;
  align-items: center;
  border: 1px dashed rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 0, 0, 0.05);
  height: 45px;
  padding: 0px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.25s ease;
  &:hover {
    opacity: 0.7;
  }
  span {
    margin-left: 12px;
    color: rgba(0, 0, 0, 0.5);
    font-size: 14px;
  }
`;

export default Social;
